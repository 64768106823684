export const getSpills = (obj) => ({
  type: "GET_SPILLS",
  obj,
});

export const getSpillsSuccess = (data) => ({
  type: "GET_SPILLS_SUCCESS",
  data,
});

export const getSpillsFail = (error) => ({
  type: "GET_SPILLS_FAIL",
  error,
});

export const createNewSpill = (obj) => ({
  type: "CREATE_NEW_SPILL",
  obj,
});

export const currentSpillReset = (obj) => ({
  type: "CURRENT_SPILL_RESET",
  obj
})

export const createNewSpillSuccess = (data) => ({
  type: "CREATE_NEW_SPILL_SUCCESS",
  data,
});

export const createNewSpillFail = (error) => ({
  type: "CREATE_NEW_SPILL_FAIL",
  error,
});

export const updateSpillForAddingArrivedOnService = (obj) => ({
  type: "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE",
  obj,
});

export const updateSpillForAddingArrivedOnServiceSuccess = (data) => ({
  type: "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE_SUCCESS",
  data,
});

export const updateSpillForAddingArrivedOnServiceFail = (error) => ({
  type: "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE_FAIL",
  error,
});

export const editSpill = (obj) => ({
  type: "EDIT_SPILL",
  obj,
});

export const editSpillSuccess = (data) => ({
  type: "EDIT_SPILL_SUCCESS",
  data,
});

export const resetFetchCertainApiForEditSpill = (data) => ({
  type: "RESET_FETCH_CERTAIN_API_FOR_EDIT_SPILL",
  data,
});

export const editSpillFail = (error) => ({
  type: "EDIT_SPILL_FAIL",
  error,
});

export const updateStatus = (obj) => ({
  type: "UPDATE_STATUS",
  obj,
});

export const updateStatusSuccess = (data) => ({
  type: "UPDATE_STATUS_SUCCESS",
  data,
});

export const updateStatusFail = (error) => ({
  type: "UPDATE_STATUS_FAIL",
  error,
});

export const createSingleNote = (obj) => ({
  type: "CREATE_SINGLE_NOTE",
  obj,
});

export const createSingleNoteSuccess = (data) => ({
  type: "CREATE_SINGLE_NOTE_SUCCESS",
  data,
});

export const createSingleNoteFail = (error) => ({
  type: "CREATE_SINGLE_NOTE_FAIL",
  error,
});

export const getCompleteSpill = (obj) => ({
  type: "GET_COMPLETE_SPILL",
  obj,
});

export const getCompleteSpillSuccess = (data) => ({
  type: "GET_COMPLETE_SPILL_SUCCESS",
  data,
});

export const getCompleteSpillFail = (error) => ({
  type: "GET_COMPLETE_SPILL_FAIL",
  error,
});

export const searchSpills = (obj) => ({
  type: "SEARCH_SPILLS",
  obj,
});

export const searchSpillsSuccess = (data) => ({
  type: "SEARCH_SPILLS_SUCCESS",
  data,
});

export const searchSpillsFail = (error) => ({
  type: "SEARCH_SPILLS_FAIL",
  error,
});

export const setFinalSpillsSearchData = (data) => ({
  type: "SET_FINAL_SPILLS_SEARCH_DATA",
  data,
});

export const setSpillFilters = (data) => ({
  type: "SET_SPILL_FILTERS",
  data,
});

export const setSpillDataForFilter = (data) => ({
  type: "SET_SPILL_DATA_FOR_FILTER",
  data,
});

export const setSelectedSpills = (data) => ({
  type: "SET_SELECTED_SPILLS",
  data,
});

export const clearSpillsSearch = () => ({
  type: "CLEAR_SPILLS_SEARCH",
});

export const uploadNoteVideos = (obj) => ({
  type: "UPLOAD_NOTE_VIDEOS",
  obj,
});

export const uploadNoteVideosSuccess = (data) => ({
  type: "UPLOAD_NOTE_VIDEOS_SUCCESS",
  data,
});

export const uploadNoteVideosFail = (error) => ({
  type: "UPLOAD_NOTE_VIDEOS_FAIL",
  error,
});

export const editNotes = (obj) => ({
  type: "EDIT_NOTES",
  obj,
});

export const editNotesSuccess = (data) => ({
  type: "EDIT_NOTES_SUCCESS",
  data,
});

export const editNotesFail = (error) => ({
  type: "EDIT_NOTES_FAIL",
  error,
});

export const getSpillStatusHistory = (obj) => ({
  type: "GET_SPILL_STATUS_HISTORY",
  obj,
});

export const getSpillStatusHistorySuccess = (data) => ({
  type: "GET_SPILL_STATUS_HISTORY_SUCCESS",
  data,
});

export const getSpillStatusHistoryFail = (error) => ({
  type: "GET_SPILL_STATUS_HISTORY_FAIL",
  error,
});

export const getNoteHistory = (obj) => ({
  type: "GET_NOTE_HISTORY",
  obj,
});

export const getNoteHistorySuccess = (data) => ({
  type: "GET_NOTE_HISTORY_SUCCESS",
  data,
});

export const getNoteHistoryFail = (error) => ({
  type: "GET_NOTE_HISTORY_FAIL",
  error,
});

export const getTotalNotesForPrintSpill = (obj) => ({
  type: "GET_TOTAL_NOTES_FOR_PRINT_SPILL",
  obj,
});

export const getTotalNotesForPrintSpillSuccess = (data) => ({
  type: "GET_TOTAL_NOTES_FOR_PRINT_SPILL_SUCCESS",
  data,
});

export const getTotalNotesForPrintSpillFail = (error) => ({
  type: "GET_TOTAL_NOTES_FOR_PRINT_SPILL_FAIL",
  error,
});

export const getPaginatedNotes = (obj) => ({
  type: "GET_PAGINATED_NOTES",
  obj,
});

export const getPaginatedNotesSuccess = (data) => ({
  type: "GET_PAGINATED_NOTES_SUCCESS",
  data,
});

export const getPaginatedNotesFail = (error) => ({
  type: "GET_PAGINATED_NOTES_FAIL",
  error,
});

export const getContractorHistory = (obj) => ({
  type: "GET_CONTRACTOR_HISTORY",
  obj,
});

export const getContractorHistorySuccess = (data) => ({
  type: "GET_CONTRACTOR_HISTORY_SUCCESS",
  data,
});

export const getContractorHistoryFail = (error) => ({
  type: "GET_CONTRACTOR_HISTORY_FAIL",
  error,
});

export const clearSpillData = (obj) => ({
  type: "CLEAR_SPILL_DATA",
  obj,
});

export const clearSpillDataSuccess = (data) => ({
  type: "CLEAR_SPILL_DATA_SUCCESS",
  data,
});

export const clearSpillDataFail = (error) => ({
  type: "CLEAR_SPILL_DATA_FAIL",
  error,
});

export const getMySpills = (obj) => ({
  type: "GET_MY_SPILLS",
  obj,
});

export const getMySpillsSuccess = (data) => ({
  type: "GET_MY_SPILLS_SUCCESS",
  data,
});

export const getMySpillsFail = (error) => ({
  type: "GET_MY_SPILLS_FAIL",
  error,
});

export const createReserves = (obj) => ({
  type: "CREATE_RESERVES",
  obj,
});

export const createReservesSuccess = (data) => ({
  type: "CREATE_RESERVES_SUCCESS",
  data,
});

export const createReservesFail = (error) => ({
  type: "CREATE_RESERVES_FAIL",
  error,
});

export const editReserves = (obj) => ({
  type: "EDIT_RESERVES",
  obj,
});

export const editReservesSuccess = (data) => ({
  type: "EDIT_RESERVES_SUCCESS",
  data,
});

export const editReservesFail = (error) => ({
  type: "EDIT_RESERVES_FAIL",
  error,
});

export const closeSpill = (obj) => ({
  type: "CLOSE_SPILL",
  obj,
});

export const closeSpillSuccess = (data) => ({
  type: "CLOSE_SPILL_SUCCESS",
  data,
});

export const closeSpillFail = (error) => ({
  type: "CLOSE_SPILL_FAIL",
  error,
});

export const addAttachments = (obj) => ({
  type: "ADD_ATTACHMENTS",
  obj,
});

export const addAttachmentsSuccess = (data) => ({
  type: "ADD_ATTACHMENTS_SUCCESS",
  data,
});

export const addAttachmentsFail = (error) => ({
  type: "ADD_ATTACHMENTS_FAIL",
  error,
});

export const getClientAssociatedNotesServices = (obj) => ({
  type: "ASSOCIATED_NOTES_SERVICES",
  obj,
});

export const getClientAssociatedNotesServicesSuccess = (data) => ({
  type: "ASSOCIATED_NOTES_SERVICES_SUCCESS",
  data,
});

export const getClientAssociatedNotesServicesFail = (error) => ({
  type: "ASSOCIATED_NOTES_SERVICES_FAIL",
  error,
});

export const getMaterial = (obj) => ({
  type: "GET_MATERIAL",
  obj,
});

export const getMaterialSuccess = (data) => ({
  type: "GET_MATERIAL_SUCCESS",
  data,
});

export const getMaterialFail = (error) => ({
  type: "GET_MATERIAL_FAIL",
  error,
});

export const getMaterialClass = (obj) => ({
  type: "GET_MATERIAL_CLASS",
  obj
})

export const getMaterialClassSuccess = (data) => ({
  type: "GET_MATERIAL_CLASS_SUCCESS",
  data
})

export const getMaterialClassFail = (error) => ({
  type: "GET_MATERIAL_CLASS_FAIL",
  error
})

export const getMaterialClassReset = (obj) => ({
  type: "GET_MATERIAL_CLASS_RESET",
  obj
})

export const deleteMaterialClass = (obj) => ({
  type: "DELETE_MATERIAL_CLASS",
  obj
})

export const deleteMaterialClassSuccess = (obj) => ({
  type: "DELETE_MATERIAL_CLASS_SUCCESS",
  obj
})
export const deleteMaterialClassFail = (error) => ({
  type: "DELETE_MATERIAL_CLASS_FAIL",
  error
})

export const deleteMaterialClassReset = (obj) => ({
  type: "DELETE_MATERIAL_CLASS_RESET",
  obj
})

export const updateMaterialClassOnDeleteSuccess = (obj) => ({
  type: "UPDATE_MATERIAL_CLASS_ON_DELETE_SUCCESS",
  obj
})

export const getStatuses = () => ({
  type: "GET_STATUSES",
});

export const getStatusesSuccess = (data) => ({
  type: "GET_STATUSES_SUCCESS",
  data,
});

export const getStatusesFail = (error) => ({
  type: "GET_STATUSES_FAIL",
  error,
});

export const getSpillSummary = (obj) => ({
  type: "GET_SPILL_SUMMARY",
  obj,
});

export const getSpillSummarySuccess = (data) => ({
  type: "GET_SPILL_SUMMARY_SUCCESS",
  data,
});

export const getSpillSummaryFail = (error) => ({
  type: "GET_SPILL_SUMMARY_FAIL",
  error,
});

export const getClosureSpills = (obj) => ({
  type: "GET_CLOSURE_SPILLS",
  obj,
});

export const getClosureSpillsSuccess = (data) => ({
  type: "GET_CLOSURE_SPILLS_SUCCESS",
  data,
});

export const getClosureSpillsFail = (error) => ({
  type: "GET_CLOSURE_SPILLS_SUCCESS_FAIL",
  error,
});

export const getSpillAdminAttachmentHistory = (obj) => ({
  type: "GET_SPILL_ADMIN_ATTACHMENT_HISTORY",
  obj,
});

export const getSpillAdminAttachmentHistorySuccess = (data) => ({
  type: "GET_SPILL_ADMIN_ATTACHMENT_HISTORY_SUCCESS",
  data,
});

export const getSpillAdminAttachmentHistoryFail = (error) => ({
  type: "GET_SPILL_ADMIN_ATTACHMENT_HISTORY_FAIL",
  error,
});
// (update-attachment-expiry)
// export const updateContractorAttachmentTypes = () => ({
//   type: 'UPDATE_CONTRACTOR_ATTACHMENT_TYPES',
// });

export const getSpillAdminAttachmentTypes = () => ({
  type: "GET_SPILL_ADMIN_ATTACHMENT_TYPES",
});

export const getSpillAdminAttachmentTypesSuccess = (data) => ({
  type: "GET_SPILL_ADMIN_ATTACHMENT_TYPES_SUCCESS",
  data,
});

export const getSpillAdminAttachmentTypesFail = (error) => ({
  type: "GET_SPILL_ADMIN_ATTACHMENT_TYPES_FAIL",
  error,
});

export const getScript = (obj) => ({
  type: "GET_SCRIPT",
  obj,
});

export const getScriptSuccess = (data) => ({
  type: "GET_SCRIPT_SUCCESS",
  data,
});

export const getScriptFail = (err) => ({
  type: "GET_SCRIPT_FAIL",
  err,
});

export const getDocumentationInReviewSpills = (obj) => ({
  type: "GET_DOCUMENTATION_IN_REVIEW_SPILLS",
  obj,
});

export const getDocumentationInReviewSpillsSuccess = (data) => ({
  type: "GET_DOCUMENTATION_IN_REVIEW_SPILLS_SUCCESS",
  data,
});

export const getDocumentationInReviewSpillsFail = (err) => ({
  type: "GET_DOCUMENTATION_IN_REVIEW_SPILLS_FAIL",
  err,
});

export const assignPacketReviewer = (obj) => ({
  type: "ASSIGN_PACKET_REVIEWER",
  obj,
});

export const assignPacketReviewerSuccess = (data) => ({
  type: "ASSIGN_PACKET_REVIEWER_SUCCESS",
  data,
});

export const assignPacketReviewerFail = (err) => ({
  type: "ASSIGN_PACKET_REVIEWER_FAIL",
  err,
});

export const requestDocumentation = (obj) => ({
  type: "REQUEST_DOCUMENTATION",
  obj,
});

export const requestDocumentationSuccess = (data) => ({
  type: "REQUEST_DOCUMENTATION_SUCCESS",
  data,
});

export const requestDocumentationFail = (err) => ({
  type: "REQUEST_DOCUMENTATION_FAIL",
  err,
});

export const requestDocumentationReset = (obj) => ({
  type: "REQUEST_DOCUMENTATION_RESET",
  obj
})

export const cancelRequestedDocumentation = (obj) => ({
  type: "CANCEL_REQUESTED_DOCUMENTATION",
  obj
})

export const cancelRequestedDocumentationSuccess = (data) => ({
  type: "CANCEL_REQUESTED_DOCUMENTATION_SUCCESS",
  data,
});

export const cancelRequestedDocumentationFail = (err) => ({
  type: "CANCEL_REQUESTED_DOCUMENTATION_FAIL",
  err,
});

export const cancelRequestedDocumentationReset = (obj) => ({
  type: "CANCEL_REQUESTED_DOCUMENTATION_RESET",
  obj
})

export const getSpillAdminRequestedDocumentation = (obj) => ({
  type: "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION",
  obj,
});

export const getSpillAdminRequestedDocumentationSuccess = (data) => ({
  type: "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION_SUCCESS",
  data,
});

export const getSpillAdminRequestedDocumentationFail = (err) => ({
  type: "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION_FAIL",
  err,
});

export const getSpillPacketAssignmentHistory = (obj) => ({
  type: "GET_SPILL_PACKET_ASSIGNMENT_HISTORY",
  obj,
});

export const getSpillPacketAssignmentHistorySuccess = (data) => ({
  type: "GET_SPILL_PACKET_ASSIGNMENT_HISTORY_SUCCESS",
  data,
});

export const getSpillPacketAssignmentHistoryFail = (error) => ({
  type: "GET_SPILL_PACKET_ASSIGNMENT_HISTORY_FAIL",
  error,
});

export const getReminder = (obj) => ({
  type: "GET_REMINDER",
  obj,
});

export const getReminderSuccess = (data) => ({
  type: "GET_REMINDER_SUCCESS",
  data,
});

export const getReminderFail = (error) => ({
  type: "GET_REMINDER_FAIL",
  error,
});

export const noteWorkTimeValidation = (obj) => ({
  type: "NOTE_WORK_TIME_VALIDATION",
  obj,
});

export const noteWorkTimeValidationSuccess = (data) => ({
  type: "NOTE_WORK_TIME_VALIDATION_SUCCESS",
  data,
});

export const noteWorkTimeValidationFail = (error) => ({
  type: "NOTE_WORK_TIME_VALIDATION_FAIL",
  error,
});

export const noteWorkTimeValidationReset = () => ({
  type: "NOTE_WORK_TIME_VALIDATION_RESET"
});

export const partialBillingDownload = (obj) => ({
  type: "PARTIAL_BILLING_DOWNLOAD",
  obj
})

export const partialBillingDownloadSuccess = (data) => ({
  type: "PARTIAL_BILLING_DOWNLOAD_SUCCESS",
  data
})

export const partialBillingDownloadFail = (error) => ({
  type: "PARTIAL_BILLING_DOWNLOAD_FAIL",
  error
})

export const partialBillingDownloadReset = () => ({
  type: "PARTIAL_BILLING_DOWNLOAD_RESET"
})

export const refreshMaterialClassReset = () => ({
  type: "REFRESH_MATERIAL_CLASS_RESET"
})

export const updateInvoiceReportDownloadState = (obj) => ({
  type: "UPDATE_INVOICE_REPORT_DOWNLOAD_STATE",
  obj
})

export const updateContractorRejectionReportDownloadState = (obj) => ({
  type: "UPDATE_CONTRACTOR_REJECTION_REPORT_DOWNLOAD_STATE",
  obj
})

export const updateInvoiceSubmittedToClientReportDownloadState = (obj) => ({
  type: "UPDATE_INVOICE_SUBMITTED_TO_CLIENT_REPORT_DOWNLOAD_STATE",
  obj
})