import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import * as spillActions from "../../../../../actionCreators/Spill";
import {
  isCorporateUser,
  orderAlphabaticallyByKey,
  RegxList,
  ROLES,
} from "../../../../../utils";
import CurrencyFieldInput from "../../../../../Components/CurrencyFieldInput";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const CreateReserve = ({
  closeReserveForm,
  currentUser,
  admins,
  createReserves,
  currentSpillId,
}) => {
  const classes = useStyles();
  const [reserve, setReserve] = useState({
    currentSpillId,
    userId: isCorporateUser(currentUser?.role.role) ? "" : currentUser.id,
    amount: "",
  });
  const [selectedAdmins, setSelectedAdmin] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (reserve.userId) {
      const [admin] = admins.filter((admin) => admin.id === reserve.userId);

      setSelectedAdmin(admin);
    }
  }, [reserve.userId]);

  useEffect(() => {
    if (currentUser?.role?.role === ROLES.PES_ADMIN) {
      setReserve((val) => {
        return {
          ...val,
          userId: currentUser.id || null,
        };
      });
    }
  }, []);

  const checkValidation = (key, newReserve) => {
    if (["amount"].includes(key)) {
      if (newReserve.amount && key === "amount") {
        if (
          key === "amount" &&
          newReserve.amount.includes(".") &&
          RegxList.NUMERIC_DECIMAL_DIGIT.test(newReserve.amount)
        ) {
          setReserve({ ...newReserve, amount: newReserve.amount });
          setValidationErrors({
            ...validationErrors,
            amount: null,
          });

        } else if (
          (key === "amount" &&
            !RegxList.NUMERIC_DECIMAL_DIGIT.test(newReserve.amount)) ||
          !newReserve.amount.includes(".")
        ) {
          setValidationErrors({
            ...validationErrors,
            amount: {
              msg: "This Field Only Recognizes Decimal values. Please Enter a Valid Decimal Amount",
            },
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            amount: null,
          });
        }
      }
    }
  };

  // const handleReserveChange = (key) => (event) => {
  //   setReserve((val) => {
  //     const newReserve = { ...val, [key]: event.target.value };
  //     checkValidation(key, newReserve);
  //     return newReserve;
  //   });
  //   const newReserve = { [key]: event.target.value };
  //   if (newReserve.length <= maxLength) {
  //     setInputValue(newReserve);
  //   }
  // };
  const handleReserveChange = (key) => (event) => {
    const inputValue = event.target.value;
    const maxLength = 8; 
    if (inputValue.length <= maxLength) {
      setReserve((val) => {
        const newReserve = { ...val, [key]: inputValue };
        checkValidation(key, newReserve);
        return newReserve;
      });
    }
  };
  

  const handleSubmit = () => {
    if (reserve.userId === "" || reserve.amount === "") {
      setErrorText(
        (reserve.userId === "" ? "Approved by" : "Amount") + " cannot be empty."
      );
      return;
    }
    // else if (reserve.amount.valueOf.length >= 8){
    //   setErrorText(" not more than 8 char");
    // }

    const reserveData = {
      spill_id: reserve.currentSpillId,
      user_id: reserve.userId,
      amount: Number(reserve.amount),
    };

    createReserves(reserveData);
    closeReserveForm();
  };

 
  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <form style={{ width: "100%" }}>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <InputLabel id="manager_select_label">Approved by</InputLabel>
              <Select
                labelId="manager_select_label"
                id="managers_select"
                name="managers"
                style={{ width: "100%" }}
                inputProps={{
                  value: reserve.userId,
                }}
                onChange={handleReserveChange("userId")}
              >
                {admins &&
                  admins?.length > 0 &&
                  orderAlphabaticallyByKey(admins, "full_name").map(
                    (e, index) => {
                      return (
                        <MenuItem value={e.id} key={index}>
                          {e.full_name}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <CurrencyFieldInput
                name="amount"
                label="Amount $"
                decimalScale={2}
                allowNegative={false}
                value={reserve?.amount}
                onChange={handleReserveChange("amount")}
                helperText={validationErrors.latitude?.msg || ""}
                error={validationErrors.latitude?.showError}
                maxLength="8"
                />

            </Grid>
            <Grid item xs={12}>
              {errorText && <FormHelperText error>{errorText}</FormHelperText>}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Create
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => closeReserveForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  // currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createReserves: bindActionCreators(spillActions.createReserves, dispatch),
});

CreateReserve.proptype = {
  closeReserveForm: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateReserve)
);
