import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { bindActionCreators } from 'redux';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { fileSizeString } from '../utils';

import * as downloadActions from '../actionCreators/Download';

const useStyles = makeStyles(() => ({
  imageGrid: {
    marginTop: '2rem',
  },
  attachmentImage: {
    border: '0.5px solid black',
    position: 'relative',
    margin: '2px',
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
  tableHead: {
    backgroundColor: '#eaeaea',
  },
  tableHeadCell: {
    color: '#6ea16c',
    fontWeight: '800',
  },
  inlineLink: {
    cursor: 'pointer',
    '&:hover': {
      color: '#6ea16c',
    },
    fontSize: '14px',
    color: '#424242',
    margin: '10px',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    msUserSelect: 'none',
  },
  inlineButton: {
    cursor: 'pointer',
    '&:hover': {
      color: '#6ea16c',
    },
    fontSize: '14px',
    color: '#424242',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    msUserSelect: 'none',
  },
  clickableIcon: {
    cursor: 'pointer',
  },
  labelWrapper: {
    display: 'flex',
  },
  noPaddingCell: {
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
  },
  labelText: {
    fontSize: '14px',
    fontWeight: '800',
  },
  sectionHeading: {
    backgroundColor: '#EEEEEE',
  },
  alignCenter: {
    textAlign: 'center',
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

const AttachmentGrid = ({
  preview,
  handleCancel,
  files,
  downloadFile,
  canDelete,
}) => {
  const classes = useStyles();
  const [localFiles, setLocalFiles] = React.useState([]);

  const handleDownloadPreview = (file, name) => {
    let link = document.createElement('a');
    let url = URL.createObjectURL(file);
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const handleDownloadFile = (key) => {
    downloadFile({ key });
  };

  const cancelHandleMapper = (index, flag, elementId) => {
    const isFind = (element) => {
      return element?.id === elementId;
    };
    const mapIndex = files.findIndex(isFind);
    if (mapIndex >= 0) {
      handleCancel(mapIndex, flag);
    }
  };

  React.useEffect(() => {
    let toSort = [...files];
    toSort = toSort.sort((x, y) => x.id - y.id);
    setLocalFiles(toSort);
  }, [files]);

  return (
    <div>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>File Name</TableCell>
              <TableCell className={classes.tableHeadCell}>Date</TableCell>
              <TableCell className={classes.tableHeadCell}>Size</TableCell>
              {canDelete && (
                <TableCell className={classes.tableHeadCell}></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!preview.length && !localFiles.length ? (
              <TableCell colSpan={8}>
                <div className={classes.alignCenter}>
                  No Attachments Added Yet
                </div>
              </TableCell>
            ) : (
              <>
                {preview?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <span
                          onClick={() => handleDownloadPreview(row, row.name)}
                          className={classes.inlineLink}
                        >
                          {row.name}
                        </span>
                      </TableCell>
                      <TableCell>
                        {Moment(new Date())?.format('MM-DD-YYYY h:mm a')}
                      </TableCell>
                      <TableCell>{fileSizeString(row.size)}</TableCell>
                      <TableCell>
                        <CloseIcon
                          className={classes.clickableIcon}
                          onClick={() => handleCancel(index, false)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {localFiles?.map((row, index) => {
                  return (
                    row.isDelete || (
                      <TableRow key={index}>
                        <TableCell>
                          <span
                            onClick={() => handleDownloadFile(row.key)}
                            className={classes.inlineLink}
                          >
                            {row.name}
                          </span>
                        </TableCell>
                        <TableCell>
                          {Moment(row.updated_at)?.format('MM-DD-YYYY h:mm a')}
                        </TableCell>
                        <TableCell>{fileSizeString(row.size)}</TableCell>
                        {canDelete && (
                          <TableCell>
                            <CloseIcon
                              className={classes.clickableIcon}
                              onClick={() =>
                                cancelHandleMapper(index, true, row.id)
                              }
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
});

AttachmentGrid.propTypes = {
  preview: PropTypes.array,
  handleCancel: PropTypes.func,
  files: PropTypes.array,
  downloadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentGrid);
