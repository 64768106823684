import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DataTable from '../../../../../Components/SpillDataTable';
import EditSPill from '../Spills/EditSpill';
import ViewSpill from '../Spills/ViewSpill';

import * as spillActions from '../../../../../actionCreators/Spill';
import * as userActions from '../../../../../actionCreators/User';
import { ROLES, USER_TYPE } from '../../../../../utils/keyMappers';
import { statusVisibility } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%',
    borderRadius: '10px',
  },
  headerCard: {
    backgroundColor: '#f7f7f7',
  },
  noteText: {
    marginRight: '20px',
    lineHeight: '3',
    color: '#969696',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  marginTop: {
    marginTop: '10px',
  },
  loading: {
    width: '80px',
    height: '80px',
  },
  sectionMarginTop: {
    marginTop: '20px',
  },
}));

const SubmittedPackets = ({
  user,
  spills,
  success,
  material,
  getClosureSpills,
  getAdmins,
  getMaterial,
  setShowSummary,
  history,
}) => {
  const classes = useStyles();
  const location = history?.location;

  const { currentUser, admins } = user;
  const [currentPage, setCurrentPage] = useState(0);
  const [tableData, setTableData] = React.useState(null);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [viewSpill, setViewSpill] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(0);
  const [totalSpills, setTotalSpills] = React.useState(0);
  const [paginationProps, setPaginationProps] = React.useState({
    page: 0,
    limit: 10,
  });

  const refresh = () => {
    getClosureSpills({
      page: paginationProps.page,
      paginationProps,
      id: currentUser?.data?.id,
      permission: currentUser?.data?.role?.permission?.view_related_spills,
      role: currentUser?.data?.role?.role,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    });
  };

  useEffect(() => {
    const splitPathname = location.pathname.split('/');
    if (currentUser.data) {
      if (
        splitPathname[splitPathname.length - 1] === 'home' ||
        splitPathname[splitPathname.length - 1] === 'dashboard'
      ) {
        refresh();
      }
      if (splitPathname[splitPathname.length - 1] !== 'dashboard') {
        // getAdmins({ filter: 'none' });
        // getMaterial();
      }
    }
    setShowEditForm(false);
    setViewSpill(false);
    setShowSummary(true);
  }, [currentUser, location.key, paginationProps.page, paginationProps.limit]);
  useEffect(() => {
    if (success && spills.data) {
      if (
        currentUser.data?.role.role === ROLES.CONTRACTOR_ADMIN ||
        currentUser.data?.role.role === ROLES.CONTRACTOR_USER
      ) {
        // Is the contractor active in these Spills?
        let data = [...spills.data];
        let contractorObjs = [];
        let filter = null;
        if (currentUser.data?.role.role === ROLES.CONTRACTOR_USER) {
          filter = (o) => {
            return (
              o.contractor_id === currentUser.data.contractor_id &&
              ((!o.address_id && !currentUser.data.contractor_address_id) ||
                o.address_id === currentUser.data.contractor_address_id)
            );
          };
        } else {
          filter = (o) => o.contractor_id === currentUser.data.contractor_id;
        }
        for (const index in data) {
          let spillConnections = data[index].connections;
          if (spillConnections?.length) {
            contractorObjs =
              spillConnections[0].spill_contractors.filter(filter);
          }
          if (
            contractorObjs.length &&
            contractorObjs.filter((o) => o.is_inactive).length ===
              contractorObjs.length
          ) {
            data[index].isInactive = true;
          }
        }
        setTableData(data);
        setTotalSpills(spills.pagination?.total);
      } else {
        setTableData(spills.data);
        setTotalSpills(spills.pagination?.total);
      }
    }
  }, [success, spills.data]);

  const handleViewSpill = (id) => {
    showViewForm(id);
    setShowSummary(false);
  };
  const handleMoveToEditSpill = (id) => {
    setSelectedId(id);
    setShowEditForm(true);
    setViewSpill(false);
    setShowSummary(false);
  };
  const handleCloseEditSpill = () => {
    refresh();
    setShowEditForm(false);
    setShowSummary(true);
  };

  const handleCloseViewSpill = () => {
    setShowEditForm(false);
    setViewSpill(false);
    setShowSummary(true);
  };

  const handleOpenEditFormWithDataTable = (data) => {
    handleMoveToEditSpill(data.job_no);
  };

  const handleTablePage = (data, limit) => {
    setPaginationProps({ page: data, limit });
  };

  const showViewForm = (id) => {
    setViewSpill(true);
    setSelectedId(id);
    setShowEditForm(false);
  };

  const tableHeaders = [
    { title: 'Job No', key: 'job_no' },
    { title: 'Client Org', key: 'clientOrgName' },
    { title: 'Opened', key: 'opened_on' },
    { title: 'Status', key: 'status' },
    { title: 'Closed', key: 'closed_on' },
    { title: 'Claim No', key: 'claim_no' },
  ];

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={classes.sectionMarginTop}>
            {!spills.loading &&
              tableData &&
              tableData.length > 0 &&
              !showEditForm &&
              !viewSpill && (
                <DataTable
                  tableHeader={tableHeaders}
                  tableBody={tableData.map((row) => ({
                    ...row,
                    status: statusVisibility(
                      row?.status,
                      currentUser.data?.role?.role
                    ),
                  }))}
                  editTable={currentUser.data?.role?.permission?.edit_spills}
                  onRowSelect={handleOpenEditFormWithDataTable}
                  pageChange={handleTablePage}
                  handleView={(data) => handleViewSpill(data.job_no)}
                  total={totalSpills}
                  showViewButton
                  showCounter
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  paginationProps={paginationProps}
                  setPageRows={() => {}}
                />
              )}
          </div>

          {success &&
            !spills.loading &&
            tableData &&
            tableData.length === 0 && (
              <Typography variant='h5'>No spills to show</Typography>
            )}

          {spills.loading && (
            <div className={classes.root}>
              <CircularProgress />
            </div>
          )}

          <div className={classes.marginTop}>
            {showEditForm && (
              <EditSPill
                materials={material}
                id={selectedId}
                closeEditSpill={handleCloseEditSpill}
                viewSpill={(id) => handleViewSpill(id)}
              />
            )}
          </div>
          <div className={classes.marginTop}>
            {viewSpill && (
              <ViewSpill
                adminsData={admins}
                id={selectedId}
                currentUser={currentUser}
                closeViewSpill={() => handleCloseViewSpill()}
                updateSpill={(id) => handleMoveToEditSpill(id)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ spill: { success, spills, material }, user }) => ({
  success,
  material,
  spills,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: bindActionCreators(userActions.getAdmins, dispatch),
  getClosureSpills: bindActionCreators(spillActions.getClosureSpills, dispatch),
  getMaterial: bindActionCreators(spillActions.getMaterial, dispatch),
});

SubmittedPackets.propTypes = {
  user: PropTypes.object.isRequired,
  getAdmins: PropTypes.func.isRequired,
  spills: PropTypes.object.isRequired,
  success: PropTypes.bool.isRequired,
  getClosureSpills: PropTypes.func.isRequired,
  getMaterial: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubmittedPackets)
);
