export const EditNoteText = {
  emailPromptHeading: "Email Confirmation",
  emailPromptBody: "Do you want to send email to the Manager?",
  emailPromptAcceptBtn: "Yes",
  emailPromptRejectBtn: "No",
  rateLabel: "Rate",
  hourLabel: "Hour",
  amountLabel: "Amount",
  descriptionLabel: "Description",
  reportNoLabel: "Report #",
  nationalIncidentNoLabel: "National Incident No",
  stateIncidentNoLabel: "State Incident No",
  ipAddressLabel: "IP Addres Identifier",
  ownerLabel: "Owner",
  noSamplesLabel: "No of Samples",
  projectCostLabel: "Project Cost",
  estimatedCostLabel: "Estimated Cost",
  establishLaneClosureLabel: "Lane Closure Established",
  establishLaneOption1: "Yes",
  establishLaneOption2: "No",
  excavationBegunLabel: "Excavation Begun",
  excavationBegunOption1: "Yes",
  excavationBegunOption2: "No",
  excavationCompleted: "Excavation Completed",
  excavationCompletedOption1: "Yes",
  excavationCompletedOption2: "No",
  taskAssociatedRelevanceLabel: "Task Associated Relevance",
  timeLabel: "Time",
  clearBtnLabel: "Clear",
  scheduleDateLabel: "Scheduled Date",
  dateLabel: "Date",
  attachmentTypeLabel: "Attachment Type",
  photoTypeLabel: "Photo Type",
  selectFilesBtn: "+ Select Files",
  updateBtn: "Update Note",
  cancelBtn: "Cancel",
  batchUpdate: "Spill count is ",
  batchUpdatePromptHeading: " Batch Update Confirmation",
  batchUpdatePromptAcceptBtn: "Yes",
  batchUpdatePromptRejectBtn: "No",
  packetAssignment: "Spill count is ",
  packetAssignmentPromptHeading: " Packet Assignment Confirmation",
  packetAssignmentPromptAcceptBtn: "Yes",
  packetAssignmentPromptRejectBtn: "No",
};

export const HistoryText = {
  noHistoryFound: "No History Found",
  heading: "Change History",
  contractorHeading: "Contractor Rejection History",
  spillStatusHistory: "Spill Status History",
  // spillPacketAssignmentHistory: "Spill Packet Assignment History",
  fileNameToDownload: "NotesHistory.pdf",
  pdfLoadingText: "Loading document...",
  downloadPdfText: "Download PDF",
  noteIdText: "Note Id :",
  updatedByText: "Updated by :",
  updatedAtText: "Updated at :",
  fieldNameText: "Field name:",
  contractor: "Contractor:",
  rejectedBy: "Rejected By:",
  rejectReason: "Rejection Reason:",
  fromText: "From:",
  toText: "To:",
  noValueText: "N/A",
};

export const ClientOrganizationText = {
  clientOrganizationUpdatePromptHeading: "Client Organization Update",
  ClientOrganizationText:
    "Are you sure that you want to update client organization?",
  ClientOrganizationTextNote:
    "It will change the job id and default users to email for this incident.",
  clientOrganizationUpdatePromptAcceptBtn: "Yes",
  clientOrganizationUpdatePromptRejectBtn: "No",
};

export const voidSpillText = {
  voidSpillPromptHeading: "Clear Spill Data",
  voidSpillPromptText: "Are you sure that you want to void the current spill?",
  voidSpillPromptTextNote:
    "It will clear all spill data except Project Manager, Client Organization, Users for Email, Opened on Date.",
  voidSpillPromptPromptAcceptBtn: "Yes",
  voidSpillPromptPromptRejectBtn: "No",
};

export const spillContractorModalText = {
  acceptSpillContractorModalHeading: "Accept Contractors Response",
  addSpillContractorModalHeading: "Add Contractors",
  spillContractorModalText:
    "In order to add this service you are required to accept at least one contractor's response.",
  spillAddContractorModalText:
    "In order to add this service you will be required to add at least one contractor and accept their response.",
  spillContractorModalTextNote:
    "This will allow the following service to be added after.",
  spillContractorModalUpdateBtn: "Update",
  spillContractorModalCancelBtn: "Cancel",
};

export const requestDocumentationModalText = {
  rejectText: "No",
  acceptText: "Yes",
  cancelText: "Cancel",
  headingText: "Request Documentation",
  generateRequestText: "Generate Request",
  question:
    "Are you sure you want to request above documents from this contractor?",
  noDocumentsSelectedText: "No documents selected for request",
  modalDefaultText:
    "Select the additional documents that need to be requested from the contractor",
  selectedDocumentsText: "You have following documents ready for this request",
};

export const cancelRequestedDocumentationModalText = {
  rejectText: "No",
  acceptText: "Yes",
  cancelText: "Cancel",
  headingText: "Cancel Document Request",
  generateRequestText: "Generate Request",
  question:
    "Are you sure you want to cancel requested above documents from this contractor?",
  noDocumentsSelectedText: "No documents selected for request",
  modalDefaultText:
    "Select the additional documents that need to be requested from the contractor",
  selectedDocumentsText: "Remove the document from the following requested documents list to cancel the request",
}

export const progressiveBillingModalText = {
  headingText: "Progressive Billing",
  startDateText: "Report Start Date",
  endDateText: "Report End Date",
  downloadedByText: "Downloaded By",
  actionText: "Action",
  submitText: "Submit",
  downloadText: "Download"
}