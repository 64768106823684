export const genericKeyMapper = (key) => {
  return key
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};
export const noteFieldsMapper = {
  actual_eta: 'Actual ETA',
  amount: 'Amount',
  created_at: 'Created At',
  date: 'Date',
  description: 'Description',
  established_lane_closure: 'Established Lane Closure',
  estimated_cost: 'Estimated Cost',
  excavation_begun: 'Excavation Begun',
  excavation_completed: 'Excavation Completed',
  hour: 'Hour',
  ip_address_identifier: 'Ip Address Identifier',
  no_of_salvage_container: 'No Of Salvage Container',
  no_of_samples: 'No Of Samples',
  note_attachments: 'Note Attachments',
  owner: 'Owner',
  projected_eta: 'Projected ETA',
  rate: 'Rate',
  report_no: 'Report No',
  salvage_containers: 'Salvage Containers',
  service_type: 'Service Type',
  task_associated_relevance: 'Task Associated Relevance',
  time: 'Time',
  type: 'Type',
  excavation_time: 'Total Excavation Time',
  state_incident_no: 'State Incident No',
  incident_no: 'National Incident No',
  contractor_arrivedon_site: 'Contractor Arrived Onsite',
  response_authorization: 'Response Authorization'
};

export const salvageContainersVisibleFields = [
  'content',
  'created_at',
  'drum_weight',
  'is_hazmat',
  'sc_no',
  'type',
  'disposal_handled_by',
  'container_disposition',
];

export const ROLES = {
  SUPER_USER: 'Super User',
  PES_ACCOUNTING_ADMIN: 'PES Accounting Admin',
  PES_ADMIN: 'PES Admin',
  PES_USER: 'PES User',
  DEMO: 'Demo',
  CORPORATE_ADMIN: 'Corporate Admin',
  CORPORATE_LOGGER: 'Corporate Logger',
  CORPORATE_ACCOUNTING: 'Corporate Accounting',
  CORPORATE_USER: 'Corporate User',
  CONTRACTOR_ADMIN: 'Contractor Admin',
  CONTRACTOR_USER: 'Contractor User',
  PROB_PM: 'Probationary PM',
};

export const USER_TYPE = {
  TEST: 'TEST',
  GENERAL: 'GENERAL',
};

export const STATUSES = {
  OPEN_READY_TO_CLOSE: "Open: Ready to Close",
  OPEN_DOCUMENTATION_IN_REVIEW:"Open: Documentation In Review",
  OPEN_DOCUMENTATION_SENT_BACK_TO_CONTRACTOR_FOR_REVISION: "Open: Documentation Sent Back to Contractor for Revision"
}

export const REPORTING_REQUIREMENTS_LABEL = ["petroleum_release_amount", "hazardous_material_amount", "water_impact_amount", "soil_impact_amount"]


export const COUNTRIES = {
  USA: "United States of America",
  CANADA: "Canada"
}

// Number format to apply for date
export const XLSX_FORMAT = {
  DATE_FORMAT: "MM/DD/YYYY hh:mm:ss",
  STRING_FORMAT: '@',
  NUMBER_FORMAT: '0'
}

export const XLSX_DATATYPE = {
  NUMBER: "number",
  STRING: "string",
  DATE: "date"
}