import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import Main from "./components/Main";
import Banner from "./components/Banner";
import Sidebar from "./components/Sidebar";
import Services from "./components/Services";
import GeneralEula from "./components/GeneralEula";
import ContractorEula from "./components/ContractorEula";

import { isContractorUser, isCorporateUser } from "../../utils";

import * as userActions from "../../actionCreators/User";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
    width: "60%",
    margin: "auto",
    padding: "20px",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  },
  modalActionsWrapper: {
    float: "right",
    display: "flex",
  },
  acceptButton: {
    backgroundColor: green[700],
    color: "white",
  },
  rejectButton: {
    margin: "0px 5px",
  },
}));

const Homepage = ({ destinationPath, user, updateEula, location, logOut }) => {
  const classes = useStyles();
  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);

  const handleClose = async () => {
    await logOut(history);
    localStorage.removeItem('note_data');
    setShowModal(false);
  };

  const handleEulaAccepted = async () => {
    const updatedUserObject = { ...user.currentUser };
    updatedUserObject.data.eula_accepted = true;
    await updateEula(updatedUserObject);

    history.push("/dashboard");
    setShowModal(false);
  };

  const mainFeaturedPost = {
    title: "Spill Incidents",
    description: "Online incident-management by PES",
    image: "https://source.unsplash.com/random",
    imgText: "main image description",
    linkText: "Continue reading…",
  };

  const sidebar = {
    title: "Welcome",
    description:
      "In order to use the resources available here, please log in below.",
    archives: [
      { title: "March 2020", url: "#" },
      { title: "February 2020", url: "#" },
      { title: "January 2020", url: "#" },
      { title: "November 1999", url: "#" },
      { title: "October 1999", url: "#" },
      { title: "September 1999", url: "#" },
      { title: "August 1999", url: "#" },
      { title: "July 1999", url: "#" },
      { title: "June 1999", url: "#" },
      { title: "May 1999", url: "#" },
      { title: "April 1999", url: "#" },
    ],
  };

  React.useEffect(() => {
    const params = new URLSearchParams();
    params.append("redirectAfterAuth", destinationPath);
    history.push({ search: params.toString() });
    localStorage.removeItem('note_data');
  }, [destinationPath]);

  React.useEffect(() => {
    if (user?.currentUser?.data?.eula_accepted === false) {
      setShowModal(true);
    }
  }, [user?.currentUser?.data]);

  return (
    <>
      <Container maxWidth="lg">
        <main>
          <Banner post={mainFeaturedPost} />
          <Grid container spacing={5} className={classes.mainGrid}>
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
            />
            <Main
              title="Log in to see your Managed Spills"
              subtitle="A service of Premium Environmental Services"
            />
            <Services />
          </Grid>
        </main>
      </Container>

      {isContractorUser(user?.currentUser?.data?.role?.role) ||
      isCorporateUser(user?.currentUser?.data?.role?.role) ? (
        <Modal
          open={showModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onBackdropClick="false"
        >
          <Box className={classes.modalContent}>
            {isContractorUser(user?.currentUser?.data?.role?.role) ? (
              <ContractorEula />
            ) : null}
            {isCorporateUser(user?.currentUser?.data?.role?.role) ? (
              <GeneralEula />
            ) : null}
            <div className={classes.modalActionsWrapper}>
              <Button className={classes.rejectButton} onClick={handleClose}>
                Decline
              </Button>
              <Button
                className={classes.acceptButton}
                variant="contained"
                onClick={handleEulaAccepted}
              >
                Accept
              </Button>
            </div>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  updateEula: bindActionCreators(userActions.updateEula, dispatch),
  logOut: bindActionCreators(userActions.logOut, dispatch),
});

Homepage.propTypes = {
  user: PropTypes.object.isRequired,
  updateEula: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectUrl: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Homepage)
);
