import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import HistoryPdf from '../HistoryPdf';
import { refactor } from '../../../../../../utils/noteHistory';
import { HistoryText as Text } from '../../../../../../utils/language/english';

const useStyles = makeStyles({
  root: {
    maxHeight: '90vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(47 125 50 / 36%)',
      borderRadius: 30,
    },
  },
  mainHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  noteContainer: {
    borderTop: '1px solid grey',
    padding: '10px',
    boxShadow: '0px -5px 5px 0px rgb(0 0 0 / 6%)',
  },
  noteHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  noteHeaderItem: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '5px',
    margin: '10px',
    marginRight: '5px',
    alignItems: 'center',
  },
  noteHeaderItemValue: {
    maxWidth: '82%',
  },
  editContainer: {
    border: '1px solid #8080801f',
    borderRadius: 5,
    margin: '5px 10px',
  },
  changesContainer: {
    borderLeft: '1px solid #80808066',
    margin: '10px 0px',
  },
  changeContainer: {
    padding: '5px 0px',
    margin: '10px',
    width: 'calc(100% - 20px)',
    transition: '0.4s',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 13px -6px rgba(0,0,0,0.15)',
      borderRadius: '10px',
    },
  },
  bottomBorder: {
    borderBottom: '1px solid #80808047',
  },
  fieldName: {
    fontStyle: 'italic',
  },
  changeHeader: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '5px',
    marginBottom: '10px',
  },
  changeValue: {
    fontWeight: 'bolder',
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '5px',
    alignItems: 'center',
    transition: 'all 0.4s',
    borderRadius: 4,
    padding: 7,
    '&:hover': {
      transform: 'scale(1.04)',
      backgroundColor: '#80808030',
    },
  },
});

const NoteHistoryTabPanel = ({ currentNoteHistory, notes }) => {
  const [changes, setChanges] = React.useState();
  const [noHistoryFound, setNoHistoryFound] = React.useState(false);
  const ref = React.createRef();
  React.useEffect(() => {
    !currentNoteHistory.loading &&
      currentNoteHistory.data &&
      notes &&
      setChanges(refactor(currentNoteHistory.data, notes));
    !currentNoteHistory.loading &&
      (!currentNoteHistory.data || currentNoteHistory.data.length === 0) &&
      setNoHistoryFound(true);
  }, [currentNoteHistory, notes]);
  const classes = useStyles();

  const [pdfInfo, setPdfInfo] = React.useState({
    href: null,
    name: null,
  });

  React.useEffect(() => {
    if (!noHistoryFound) {
      generatePdfDocument();
    }
  }, [changes]);

  const generatePdfDocument = async () => {
    const blob = await pdf(<HistoryPdf data={changes} />).toBlob();
    var blobUrl = URL.createObjectURL(blob);
    setPdfInfo({
      href: blobUrl,
      name: Text.fileNameToDownload,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainHeader}>
        <Typography variant='h5' color='primary' align='center'>
          {!changes?.length && noHistoryFound
            ? Text.noHistoryFound
            : Text.heading}
        </Typography>

        {!noHistoryFound &&
          (pdfInfo.href ? (
            <a href={pdfInfo.href} download={pdfInfo.name}>
              <div className={classes.downloadContainer}>
                <Typography color={'primary'} variant={'body2'}>
                  {Text.downloadPdfText}
                </Typography>
                <CloudDownloadIcon
                  color='primary'
                  style={{ color: '#2F7D32' }}
                />
              </div>
            </a>
          ) : (
            <>Loading</>
          ))}
      </div>
      <div ref={ref} id='content-1'>
        {changes &&
          changes?.map((note, key) => {
            return (
              <Grid container className={classes.noteContainer} key={key}>
                <Grid item xs={12} className={classes.noteHeader}>
                  <div className={classes.noteHeaderItem}>
                    <Typography color={'primary'} variant='subtitle1'>
                      {Text.noteIdText}
                    </Typography>
                    <Typography className={classes.noteHeaderItemValue}>
                      {note.noteId}
                    </Typography>
                  </div>
                </Grid>
                {note.edits.map((edit, key) => {
                  return (
                    <Grid container className={classes.editContainer} key={key}>
                      <Grid item xs={4} className={classes.editHeader}>
                        <div className={classes.noteHeaderItem}>
                          <Typography color={'primary'} variant='subtitle2'>
                            {Text.updatedByText}
                          </Typography>
                          <Typography variant='body2'>
                            {edit.updatedBy}
                          </Typography>
                        </div>
                        <div className={classes.noteHeaderItem}>
                          <Typography color={'primary'} variant='subtitle2'>
                            {Text.updatedAtText}
                          </Typography>
                          <Typography variant='body2'>
                            {edit.updatedAt}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={8} className={classes.changesContainer}>
                        {edit.changes.map((change, key) => {
                          return (
                            <Grid
                              container
                              key={key}
                              className={[
                                classes.changeContainer,
                                edit.changes.length > 1 &&
                                  key !== edit.changes.length - 1 &&
                                  classes.bottomBorder,
                              ]}
                            >
                              <Grid
                                item
                                xs={12}
                                className={classes.changeHeader}
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    color='primary'
                                    variant={'subtitle2'}
                                    align='right'
                                  >
                                    {Text.fieldNameText}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    variant={'body2'}
                                    align='left'
                                    className={classes.fieldName}
                                  >
                                    {change.fieldChanged}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography
                                  variant={'subtitle2'}
                                  color='primary'
                                >
                                  {Text.fromText}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  variant={'body2'}
                                  className={classes.changeValue}
                                  align='center'
                                >
                                  {change.prevVal ?? 'N/A'}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography
                                  variant={'subtitle2'}
                                  color='primary'
                                >
                                  {Text.toText}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  variant={'body2'}
                                  className={classes.changeValue}
                                  align='center'
                                >
                                  {change.newVal ?? Text.noValueText}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
      </div>
    </div>
  );
};

export default NoteHistoryTabPanel;
