import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  CardHeader,
  Modal,
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemText,
  Divider,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import EditProfilePopup from './EditProfilePopup';
import EditEmail from './EditEmail';
import EditPassword from './EditPassword';

import * as usersActions from '../../../../actionCreators/User';
import * as servicesActions from '../../../../actionCreators/Service';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

import { CustomProgressLoader } from '../../../../Components';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'left',
    paddingBottom: 20,
  },
  cardDetails: {
    // flex: 1,
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: 0,
  },
  cardMedia: {
    width: 160,
  },
  typography: {
    marginBottom: 0,
  },
  avatar: {
    backgroundColor: green[500],
    alignSelf: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%',
    borderRadius: '10px',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'right',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSpace: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: 0,
  },
  boxTitle: {
    textAlign: 'left',
    marginTop: '10px',
    fontWeight: 800,
    marginBottom: 0,
  },
  transformText: {
    textTransform: 'capitalize',
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ProfileSection = ({
  user,
  getAssociatedServices,
  setNotificationPreference,
  loading,
  editUserPasswordResponse,
}) => {
  const classes = useStyles();
  document.title = 'Profile';

  const { success, currentUser } = user;

  const [open, setOpen] = React.useState(false);
  const [openEmailChange, setOpenEmailChange] = React.useState(false);
  const [openPasswordChange, setOpenPasswordChange] = React.useState(false);

  const [userRole, setUserRole] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [userPacketReviewer, setUserPacketReviewer] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [userPhone, setUserPhone] = React.useState('');
  const [userActive, setUserActive] = React.useState('');
  const [userOrganization, setUserOrganization] = React.useState('');
  const [userSecondaryEmail, setUserSecondaryEmail] = React.useState('');

  const [associatedServices, setAssociatedServices] = React.useState([]);

  //edit password alert
  const handleCloseEditPasswordAlert = () => setEditPasswordAlert(false);
  const [editPasswordAlert, setEditPasswordAlert] = React.useState(false); //use for the alert toggle
  const [editPasswordAlertMsg, setEditPasswordAlertMsg] = React.useState(false);

  React.useEffect(() => {
    if (success && currentUser?.data?.user_services_notifications) {
      getAssociatedServices({
        clientOrganizationServices:
          currentUser?.data?.client_organization?.client_organization_services,
        userServicesNotifications:
          currentUser?.data?.user_services_notifications,
      });
      setUserName(currentUser?.data?.full_name);
      setUserEmail(currentUser?.data?.email);
      setUserSecondaryEmail(currentUser?.data?.secondary_email);
      setUserActive(currentUser?.data?.active);
      setUserRole(currentUser?.data?.role?.role);
      setUserPhone(currentUser?.data?.phone);
      setUserOrganization(currentUser?.data?.client_organization?.name);
      setUserPacketReviewer(currentUser?.data?.is_packet_reviewer);
    }
  
  }, [success]);

  React.useEffect(() => {
    setUserEmail(currentUser?.data?.email);
    setUserSecondaryEmail(currentUser?.data?.secondary_email);
  }, [currentUser]);

  // React.useEffect(() => {
  //   if (currentUser?.data?.user_services_notifications) {
  //     getAssociatedServices({
  //       clientOrganizationServices:
  //         currentUser?.data?.client_organization?.client_organization_services,
  //       userServicesNotifications:
  //         currentUser?.data?.user_services_notifications,
  //     });
  //   }
  // }, [currentUser?.data?.user_services_notifications]);

  React.useEffect(() => {
    if (currentUser?.data?.user_associated_services) {
      setAssociatedServices(currentUser?.data?.user_associated_services);
    }
  }, [currentUser?.data?.user_associated_services]);

  React.useEffect(() => {
    setUserName(user?.currentUser?.data?.full_name);
    setUserPhone(user?.currentUser?.data?.phone);
    setUserPacketReviewer(user?.currentUser?.data?.is_packet_reviewer);

    //edit password alert control room
    if (user?.editPasswordResponse) {
      user?.editPasswordResponse?.success
        ? setEditPasswordAlertMsg(true)
        : setEditPasswordAlertMsg(false);
      setEditPasswordAlert(true);

      editUserPasswordResponse();
    }
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPasswordModal = () => {
    setOpenPasswordChange(true);
  };

  const handleClosePasswordModal = () => {
    setOpenPasswordChange(false);
  };

  const handleOpenEmailModal = () => {
    setOpenEmailChange(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailChange(false);
  };

  const handleServiceNotificationChange = (id, key) => (event) => {
    let newAssociatedServices = [...associatedServices];

    let data = newAssociatedServices.findIndex((obj) => obj.value === id);

    newAssociatedServices[data][key] = event.target.checked;

    setAssociatedServices(newAssociatedServices);
  };

  const saveNotificationPreference = (associatedServices) => {
    const serviceNotificationPreference = associatedServices.map(
      (serviceNotification) => {
        const obj = {
          id: serviceNotification.id,
          user_id: currentUser?.data?.id,
          service_id: serviceNotification.value,
          notification: serviceNotification.check,
        };
        return obj;
      }
    );

    setNotificationPreference(serviceNotificationPreference);
  };

  const userData = {
    userName,
    userEmail,
    userSecondaryEmail,
    userPhone,
    userActive,
    userRole,
    userOrganization,
    userPacketReviewer,
  };

  return (
    <div>
      <Snackbar
        open={editPasswordAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleCloseEditPasswordAlert}
      >
        <Alert
          severity={editPasswordAlertMsg ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {editPasswordAlertMsg
            ? 'Password has been updated'
            : 'Incorrent Old Password'}
        </Alert>
      </Snackbar>
      <Typography className={classes.heading} variant='h5' color='textPrimary'>
        Profile Settings
      </Typography>
      {loading ? (
        <CustomProgressLoader show={true} />
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <CardActionArea>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      <div className={classes.flexBox}>
                        <Avatar className={classes.avatar}>
                          <AssignmentIcon />
                        </Avatar>
                        <Typography
                          variant='subtitle1'
                          paragraph
                          className={classes.leftSpace}
                        >
                          Basic Information
                        </Typography>
                      </div>
                    }
                  />
                  <div className={classes.cardDetails}>
                    <CardContent style={{ textAlign: 'left' }}>
                      <Typography component='subtitle1'>
                        Name:{' '}
                        <span style={{ fontWeight: 'bold' }}>{userName}</span>
                      </Typography>
                      <Typography variant='subtitle1'>
                        Phone:{' '}
                        <span style={{ fontWeight: 'bold' }}>{userPhone}</span>
                      </Typography>
                      <Typography variant='subtitle1' paragraph>
                        Organization:{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {userOrganization}
                        </span>
                      </Typography>
                      <Typography variant='subtitle1'>
                        Role:{' '}
                        <span style={{ fontWeight: 'bold' }}>{userRole}</span>
                      </Typography>
                      <Typography variant='subtitle1'>
                        Active:{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {userActive ? 'Yes' : 'No'}
                        </span>
                      </Typography>
                      <Typography variant='subtitle1'>
                        Packet Reviewer:{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {userPacketReviewer ? 'Yes' : 'No'}
                        </span>
                      </Typography>

                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{ textAlign: 'center', paddingTop: 35 }}
                        onClick={handleOpen}
                      >
                        Edit Profile
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </CardActionArea>
            </Grid>

            <Grid item xs={8} md={6}>
              <CardActionArea>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      <div className={classes.flexBox}>
                        <Avatar className={classes.avatar}>
                          <VpnKeyIcon />
                        </Avatar>
                        <Typography
                          variant='subtitle1'
                          paragraph
                          className={classes.leftSpace}
                        >
                          Password
                        </Typography>
                      </div>
                    }
                    style={{ paddingBottom: 0 }}
                  />
                  <div className={classes.cardDetails}>
                    <CardContent style={{ textAlign: 'left' }}>
                      <Typography
                        variant='subtitle1'
                        paragraph
                        style={{ fontWeight: 'bold' }}
                        className={classes.typography}
                      >
                        ********
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{ textAlign: 'center', paddingTop: 5 }}
                        onClick={handleOpenPasswordModal}
                      >
                        Edit Password
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </CardActionArea>

              <div style={{ paddingBottom: 20 }} />

              <CardActionArea>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      <div className={classes.flexBox}>
                        <Avatar className={classes.avatar}>
                          <AlternateEmailIcon />
                        </Avatar>
                        <Typography
                          variant='subtitle1'
                          paragraph
                          className={classes.leftSpace}
                        >
                          Email
                        </Typography>
                      </div>
                    }
                    style={{ paddingBottom: 0 }}
                  />
                  <div className={classes.cardDetails}>
                    <CardContent style={{ textAlign: 'left' }}>
                      <Typography
                        variant='subtitle1'
                        paragraph
                        style={{ fontWeight: 'bold' }}
                        className={classes.typography}
                      >
                        {userEmail}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{ textAlign: 'center', paddingTop: 5 }}
                        onClick={handleOpenEmailModal}
                      >
                        Edit Email
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>

          <div style={{ paddingBottom: 20 }} />

          {/* {userRole?.includes('Contractor') ? (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <CardActionArea>
                  <Card className={classes.card}>
                    <CardHeader
                      avatar={
                        <div className={classes.flexBox}>
                          <Avatar className={classes.avatar}>
                            <NewReleasesIcon />
                          </Avatar>
                          <Typography
                            variant='subtitle1'
                            paragraph
                            className={classes.leftSpace}
                          >
                            License
                          </Typography>
                        </div>
                      }
                      style={{ paddingBottom: 0 }}
                    />
                    <div className={classes.cardDetails}>
                      <CardContent style={{ textAlign: 'left' }}>
                        <Typography variant='subtitle1'>
                          License Number:{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {userLicenseNumber}
                          </span>
                        </Typography>
                        <Typography variant='subtitle1'>
                          License Number Expiry:{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {userLicenseNumberExpiry &&
                              Moment(userLicenseNumberExpiry)?.format(
                                'MM-DD-YYYY h:mm a'
                              )}
                          </span>
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          style={{ textAlign: 'center', paddingTop: 5 }}
                          onClick={handleOpenHazardousModal}
                        >
                          Edit License
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </CardActionArea>
              </Grid>
            </Grid>
          ) : null} */}

          <Grid item xs={12} md={12}>
            {associatedServices.length > 0 && (
              <Typography
                variant='subtitle1'
                paragraph
                className={classes.boxTitle}
              >
                Email Notification Settings
              </Typography>
            )}
            <div>
              {associatedServices?.length > 0 ? (
                <div>
                  <div>
                    <Card
                      className={classes.headerCard}
                      style={{ margin: '15px 0px', width: '100%' }}
                    >
                      <CardContent
                        style={{
                          padding: '10px',
                          backgroundColor: '#e6e6e6d6',
                          fontWeight: 600,
                          color: '#6aa16c',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        ></div>
                        <Grid container spacing={3}>
                          <Grid item xs={8}>
                            <div className={classes.leftAlign}>
                              Service Name
                            </div>
                          </Grid>

                          <Grid item xs={4}>
                            <div className={classes.rightAlign}>
                              Email Notifications
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    {associatedServices &&
                      associatedServices.map((service, i) => (
                        <List
                          component='nav'
                          className={classes.root}
                          aria-label='mailbox folders'
                        >
                          <ListItem>
                            <ListItemText primary={service.label} />
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                    name='check'
                                    style={{ padding: '0' }}
                                    defaultChecked={service.check}
                                    onChange={handleServiceNotificationChange(
                                      service.value,
                                      'check'
                                    )}
                                    disabled={!service.email_notifications}
                                  />
                                }
                              />
                            </div>
                          </ListItem>
                          <Divider style={{ backgroundColor: '#437d334d' }} />
                        </List>
                      ))}
                  </div>
                  <div style={{ textAlign: 'right', padding: '20px' }}>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() =>
                        saveNotificationPreference(associatedServices)
                      }
                    >
                      Save Notification Preference
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </Grid>
        </>
      )}
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <EditProfilePopup
              userInformation={userData}
              closeModal={handleClose}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openPasswordChange}
        onClose={handleClosePasswordModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPasswordChange}>
          <div className={classes.paper}>
            <EditPassword closeModal={handleClosePasswordModal} />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openEmailChange}
        onClose={handleCloseEmailModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEmailChange}>
          <div className={classes.paper}>
            <EditEmail
              userInformation={userData}
              closeModal={handleCloseEmailModal}
            />
          </div>
        </Fade>
      </Modal>
      <Snackbar
        open={!!user.error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert elevation={6} variant='filled' severity={'error'}>
          {user.error}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = ({ user, service }) => ({
  user,
  service,
  loading: user.loading || service.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationPreference: bindActionCreators(
    usersActions.setNotificationPreference,
    dispatch
  ),
  getServices: bindActionCreators(servicesActions.getServices, dispatch),
  getCurrentUser: bindActionCreators(usersActions.getCurrentUser, dispatch),
  getAssociatedServices: bindActionCreators(
    usersActions.getAssociatedServices,
    dispatch
  ),
  editUserPasswordResponse: bindActionCreators(
    usersActions.editUserPasswordResponse,
    dispatch
  ),
});

ProfileSection.propTypes = {
  user: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  getServices: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileSection)
);
