import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';

import { CustomProgressLoader } from '../../../../../../Components';

import { HistoryText as Text } from '../../../../../../utils/language/english';
import { getFormattedTime } from '../../../../../../utils';

const useStylesDataTable = makeStyles({
  tableHeading: {
    color: 'white',
    fontWeight: 900,
    backgroundColor: '#2f7d32',
  },
  automationIcon: {
    color: '#2f7d32',
    fontWeight: 20,
  },
  automationCol: {
    display: 'table-cell',
    border: 'none',
  },
});

const columns = [
  {
    id: 'is_status_automated',
    label: '',
    minWidth: 20,
    align: 'left',
  },
  { id: 'status', label: 'Status', minWidth: 200, align: 'left' },
  { id: 'started_at', label: 'Start Date', minWidth: 100, align: 'left' },
  { id: 'ended_at', label: 'End Date', minWidth: 100, align: 'left' },
  { id: 'updated_by', label: 'Updated By', minWidth: 100, align: 'left' },
];

const StatusHistory = ({ spillStatusHistory }) => {
  const classes = useStylesDataTable();

  const [statusHistoryState, setStatusHistoryState] = useState({
    rows: [],
    success: false,
    loading: false,
  });
  const [noHistoryFound, setNoHistoryFound] = useState(false);

  useEffect(() => {
    if (spillStatusHistory?.data?.data?.length === 0 && spillStatusHistory?.data?.latestStatus === 0 ) {
      setNoHistoryFound(true);
    }

    if (spillStatusHistory?.data?.data?.length ||  spillStatusHistory?.data?.latestStatus ) {
      setStatusHistoryState({
        rows:[ ...spillStatusHistory?.data?.data  ,spillStatusHistory?.data?.latestStatus ],
        success: spillStatusHistory?.success,
        loading: spillStatusHistory?.loading,
      });
    }
  }, [spillStatusHistory]);

  return (
    <div>
      <Typography variant='h5' color='primary' align='center'>
        {noHistoryFound ? Text.noHistoryFound : Text.spillStatusHistory}
      </Typography>
      {!noHistoryFound && (
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          {statusHistoryState?.loading ? (
            <CustomProgressLoader show={statusHistoryState?.loading} />
          ) : (
            <TableContainer style={{ maxHeight: 500, overflowY: 'auto' }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeading}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statusHistoryState?.rows?.map((row, index) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                        <TableCell className={classes.automationCol}>
                          {row?.is_status_automated === 1 ? (
                            <BrightnessAutoIcon
                              className={classes.automationIcon}
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell>{row?.status || 'N/A'}</TableCell>
                        <TableCell>
                          {row?.started_at
                            ? getFormattedTime(row?.started_at)
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {row?.ended_at
                            ? getFormattedTime(row?.ended_at)
                            : 'N/A'}
                        </TableCell>
                        <TableCell>{row?.updated_by || 'N/A'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}
    </div>
  );
};

export default StatusHistory;
