// escape special characters
export default function escapeRegExp(string) {
  return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const formatCurrencyToUSD = (amount) => {
  amount = amount?.replace(/,/g, '');
  return Number(amount)?.toLocaleString('en-US', {
    minimumFractionDigits: 2
  });
};