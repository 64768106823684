import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { containerTypes, orderAlphabatically } from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: "right",
  },
  bottomSpacing: {
    marginBottom: "1rem",
  },
  containersLabel: {
    margin: "10px 0 20px 0",
    textAlign: "center",
  },
  clickableIcon: {
    cursor: "pointer",
  },
  centerWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  containersLabelGray: {
    color: "grey",
  },
  formControl: {
    margin: theme.spacing(1.5),
    width: "45%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: "#eaeaea",
  },
  tableHeadText: {
    color: "#6ea16c",
    fontWeight: 800,
  },
}));

const SalvageContainer = ({ containers, setContainers }) => {
  const classes = useStyles();
  const [type, setType] = React.useState("");
  const [content, setContent] = React.useState("");
  const [haz, setHaz] = React.useState("");
  const [disposalHandledBy, setDisposalHandledBy] = React.useState("");
  const [containerDisposition, SetContainerDisposition]  = React.useState("");
  const [unNo, setUnNo] = React.useState("");
  const [drumWeight, setDrumWeight] = React.useState("");
  const [showForm, setShowForm] = React.useState(false);

  const handleClickAddNew = () => {
    setShowForm(true);
  };

  const handleAddClick = () => {
    if (!type || !content || !drumWeight || !haz || !disposalHandledBy || !containerDisposition) {
      return;
    }
    if (haz === "Haz" && !unNo) {
      return;
    }
    const tempContainers = [
      ...containers,
      {
        sc_no: containers.length + 1,
        type,
        content,
        is_hazmat: haz,
        un_no: unNo,
        drum_weight: drumWeight,
        disposal_handled_by: disposalHandledBy,
        container_disposition: containerDisposition,
      },
    ];
    setContainers(tempContainers);
    clearState();
  };

  const handleRemove = (index) => {
    let tempContainers = [...containers];
    tempContainers.splice(index, 1);
    for (index in tempContainers) {
      tempContainers[index].sc_no = parseInt(index) + 1;
    }
    setContainers(tempContainers);
  };

  const clearState = () => {
    setType("");
    setContent("");
    setHaz("");
    setDisposalHandledBy("");
    setUnNo("");
    setDrumWeight("");
    SetContainerDisposition("");
  };

  return (
    <React.Fragment>
      {containers.length ? (
        <Typography className={classes.containersLabel}>
          Number of Salvage Containers: {containers.length}
        </Typography>
      ) : (
        <Typography
          className={`${classes.containersLabelGray} ${classes.containersLabel}`}
        >
          No Salvage Containers have been added
        </Typography>
      )}
      {showForm && (
        <Grid container spacing={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-type">Type</InputLabel>
            <Select
              labelId="select-type-label"
              id="type-select"
              value={type}
              onChange={(event) => setType(event.target.value)}
            >
              {orderAlphabatically(containerTypes).map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid item xs={6}>
            <TextField
              id="content"
              name="content"
              label="Content"
              fullWidth
              autoComplete="content"
              value={content}
              onChange={(event) => setContent(event.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="weight"
              name="weight"
              label="Weight (lbs)"
              autoComplete="weight"
              fullWidth
              value={drumWeight}
              onChange={(event) =>
                !isNaN(event.target.value) && setDrumWeight(event.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Grid container>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="hazselect"
                    name="hazselect1"
                    value={haz}
                    onChange={(event) => setHaz(event.target.value)}
                  >
                    <FormControlLabel
                      value="Haz"
                      control={<Radio color='primary' />}
                      label="Haz"
                    />
                    <FormControlLabel
                      value="Non-Haz"
                      control={<Radio color='primary' />}
                      label="Non-Haz"
                    />
                    <FormControlLabel
                      value="Pending Analysis"
                      control={<Radio color='primary' />}
                      label="Pending Analysis"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {haz === "Haz" && (
                <Grid item xs={6}>
                  <TextField
                    id="unNo"
                    name="unNo"
                    label="UN #"
                    fullWidth
                    autoComplete="unNo"
                    required
                    value={unNo}
                    onChange={(event) =>
                      !isNaN(event.target.value) && setUnNo(event.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Grid container>
              <Grid xs={6}>
                <InputLabel
                  id='disposal_type_label'
                >
                  Disposal Will Be Handled By:
                </InputLabel>
                <RadioGroup
                  aria-label='disposal_type_radio_group'
                  value={disposalHandledBy}
                  name='disposalHandledBy'
                  onChange={(event) => setDisposalHandledBy(event.target.value)}
                 >
                  <FormControlLabel
                    value='Contractor'
                    control={<Radio color='primary' />}
                    label='Contractor'
                  />
                  <FormControlLabel
                    value='Client'
                    control={<Radio color='primary' />}
                    label='Client'
                  />
                </RadioGroup>
              </Grid>
              <Grid xs={6}>
                <InputLabel
                  id='disposal_type_label'
                >
                  Container Disposition:
                </InputLabel>
                <RadioGroup
                  aria-label='ontainer_disposition_type_radio_group'
                  name='containerDisposition'
                  value={containerDisposition}
                  onChange={(event) => SetContainerDisposition(event.target.value)}
                   >
                  <FormControlLabel
                    value='Taken by Contractor'
                    control={<Radio color='primary' />}
                    label='Taken by Contractor'
                  />
                  <FormControlLabel
                    value='Left at Facility'
                    control={<Radio color='primary' />}
                    label='Left at Facility'
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={`${classes.alignRight} ${classes.bottomSpacing}`}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddClick}
              >
                + Add Container
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
      {containers.length > 0 && (
        <TableContainer className={classes.bottomSpacing} component={Paper}>
          <Table aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeadText}>SC#</TableCell>
                <TableCell className={classes.tableHeadText}>Type</TableCell>
                <TableCell className={classes.tableHeadText}>Content</TableCell>
                <TableCell className={classes.tableHeadText}>
                  Weight(lbs)
                </TableCell>
                <TableCell className={classes.tableHeadText}>Haz</TableCell>
                <TableCell className={classes.tableHeadText}>UN#</TableCell>
                <TableCell className={classes.tableHeadText}>Disposal Handled By</TableCell>
                <TableCell className={classes.tableHeadText}>Container Disposition</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {containers.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.sc_no}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.content}</TableCell>
                  <TableCell>{row.drum_weight}</TableCell>
                  <TableCell>{row.is_hazmat}</TableCell>
                  <TableCell>{row.un_no}</TableCell>
                  <TableCell>{row.disposal_handled_by}</TableCell>
                  <TableCell>{row.container_disposition}</TableCell>
                  <TableCell>
                    <CloseIcon
                      className={classes.clickableIcon}
                      onClick={() => handleRemove(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!showForm && (
        <div className={classes.centerWrapper}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickAddNew}
          >
            Add Salvage Containers
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

SalvageContainer.propTypes = {
  containers: PropTypes.array.isRequired,
  setContainers: PropTypes.func.isRequired,
};

export default SalvageContainer;
