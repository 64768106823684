import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

import Header from './components/Header';
import Sidebar from './components/SideBar';
import TabPanel from './components/TabPanel';
import UsersMenu from './components/content/Users/UsersMenu';
import SpillsMenu from './components/content/Spills/SpillsMenu';
import ProfileSection from './components/content/ProfileSection';
import AdminNavigationTabs from './components/content/AdminNavigationTabs';
import Landing from './components/content/Home/Landing';
import SearchSpill from './components/content/Spills/SearchSpills';
import ClientsMenu from './components/content/Clients/ClientsMenu';
import AgenciesMenu from './components/content/Agencies/AgenciesMenu';
import ContractorsMenu from './components/content/Contractors/ContractorsMenu';
import ServicesMenu from './components/content/Services/ServicesMenu';
import ActionsMenu from './components/content/Actions/PendingActions';
import SearchSpillsResults from './components/content/Spills/SearchSpillResults';
import { setCurrentSideBarValue } from '../../actionCreators/User';

import { pathNames, validPaths } from '../../utils/pathMapping';
import PacketAssignment from './components/content/Spills/PacketAssignment';
import Reports from './components/content/Spills/Reports';

const SpillAnalytics = React.lazy(() =>
  import('./components/content/Analytics/Analytics')
);

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#2F7D32',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#0f1c17',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        width: '100%',
        opacity: '0.1',
        backgroundColor: '#424242',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    padding: '16px 16px 0 16px',
    background: '#fff',
  },
  footer: {
    background: '#eaeff1',
  },
  mainGrid: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  sideBarWrapper: {
    backgroundColor: '#F5F5F5',
  },
};

function Dashboard(props) {
  const {
    classes,
    history,
    location,
    currentUser,
    error,
    isView,
    isEdit,
    setCurrentSideBarValue,
  } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [tabsAccess, setTabsAccess] = React.useState([]);
  // manage this state variables in the dashboard so that all spills and spills from side bar click works instead of managing the state in the SpillsMenu component
  const [showSpills, setShowSpills] = React.useState(false);
  const [showNewForm, setShowNewForm] = React.useState(false); 

   // make this function common for SpillsMenu as well as Spills button for SideBar component
   const handleCloseNewSpill = () => {
    setShowSpills(false);
    setShowNewForm(false);
  };
  React.useEffect(() => {
    // Highlight Tabs In Sidebar:

    //Super User
    if (currentUser?.role_id === 1) {
      setTabsAccess([
        'home',
        'spills',
        'packet-assignment',
        'search',
        'batch-status',
        'reports',
        'analytics',
        'profile',
        'admin',
        'agencies',
        'contractors',
        'clients',
        'users',
        'services',
        'actions',
      ]);
    }
    //PES Admin
    if (currentUser?.role_id === 2) {
      setTabsAccess([
        'home',
        'spills',
        'packet-assignment',
        'search',
        'reports',
        'profile',
        'admin',
        'agencies',
        'contractors',
        'clients',
        'users',
        'services',
        'actions',
      ]);
    }
    //PES User
    if (currentUser?.role_id === 3) {
      setTabsAccess([
        'home',
        'spills',
        'packet-assignment',
        'search',
        'reports',
        'profile',
        'actions',
      ]);
    }
    //Corporate admin
    if (currentUser?.role_id === 5) {
      setTabsAccess(['home', 'spills', 'search', 'profile', 'users']);
    }
    //Corporate logger
    if (currentUser?.role_id === 6) {
      setTabsAccess(['home', 'spills', 'search', 'profile']);
    }
    //Corporate Accounting
    if (currentUser?.role_id === 7) {
      setTabsAccess(['home', 'spills', 'search', 'profile']);
    }

    //Corporate user
    if (currentUser?.role_id === 8) {
      setTabsAccess(['home', 'spills', 'search', 'profile']);
    }

    //Contractor Admin
    if (currentUser?.role_id === 9) {
      setTabsAccess(['home', 'spills', 'search', 'profile', 'users']);
    }
    //Contractor User
    if (currentUser?.role_id === 10) {
      setTabsAccess(['home', 'spills', 'search', 'profile']);
    }
    //Prob PM
    if (currentUser?.role_id === 11) {
      setTabsAccess([
        'spills',
        'packet-assignment',
        'search',
        'reports',
        'profile',
        'admin',
        'agencies',
        'contractors',
        'clients',
        'users',
        'services',
        'actions',
      ]);
    }
    //PES Acc Admin
    if (currentUser?.role_id === 12) {
      setTabsAccess([
        'home',
        'spills',
        'packet-assignment',
        'search',
        'batch-status',
        'reports',
        'profile',
        'admin',
        'agencies',
        'contractors',
        'clients',
        'users',
        'services',
        'actions',
      ]);
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (error) {
      history.push('/');
    }
    if (location?.pathname) {
      const tab = location.pathname.split('/')[2];
      if (tab === 'home' || !tab) {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'spills') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'search') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'batch-status') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'packet-assignment') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'reports') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'analytics') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'profile') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'admin') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'agencies') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'contractors') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'clients') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'users') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'services') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      } else if (tab === 'actions') {
        setCurrentSideBarValue(tabsAccess.indexOf(tab));
      }
      const tab2 = tab + '/' + location.pathname.split('/')[3];
      if (location.pathname.split('/')[3] === 'view') {
        setValue(pathNames.indexOf(tab2));
      } else if (location.pathname.split('/')[3] === 'edit') {
        setValue(pathNames.indexOf(tab2));
      } else if (tab && validPaths(currentUser?.role).includes(tab)) {
        setValue(pathNames.indexOf(tab));
      } else {
        setValue(0);
      }
    }
  }, [props]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNewValue = (value) => {
    history.push({
      pathname: `/dashboard/${pathNames[value]}`,
    });
    setValue(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.app}>
          <Header
            onDrawerToggle={handleDrawerToggle}
            valueReturner={handleNewValue}
          />

          <Container maxWidth='lg' className={classes.mainGrid}>
            <Grid container>
              <Grid item xs={3} className={classes.sideBarWrapper}>
                <Sidebar valueReturner={handleNewValue} handleCloseNewSpill={handleCloseNewSpill}/>
              </Grid>
              <Grid item xs={9}>
                <div className={classes.main}>
                  <TabPanel value={value} index={0}>
                    <Landing />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <SpillsMenu gotoSearch={() => handleNewValue(3)}
                      handleCloseNewSpill={handleCloseNewSpill}
                      setShowSpills={setShowSpills}
                      showSpills={showSpills}
                      setShowNewForm={setShowNewForm}
                      showNewForm={showNewForm}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={14}>
                    <SpillsMenu
                      isView={isView}
                      gotoSearch={() => handleNewValue(3)}
                      handleCloseNewSpill={handleCloseNewSpill}
                      setShowSpills={setShowSpills}
                      showSpills={showSpills}
                      setShowNewForm={setShowNewForm}
                      showNewForm={showNewForm}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={15}>
                    <SpillsMenu
                      isEdit={isEdit}
                      gotoSearch={() => handleNewValue(3)}
                      handleCloseNewSpill={handleCloseNewSpill} 
                      setShowSpills={setShowSpills}
                      showSpills={showSpills}
                      setShowNewForm={setShowNewForm}
                      showNewForm={showNewForm}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <SearchSpill
                      goToSpills={() => handleNewValue(2)}
                      defaultSearch={true}
                      batchUpdateAllow={false}
                      subrogationAllow={true}
                      reportsAllow={false}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={13}>
                    <SearchSpill
                      goToSpills={() => handleNewValue(2)}
                      defaultSearch={false}
                      batchUpdateAllow={true}
                      subrogationAllow={false}
                      reportsAllow={false}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={18}>
                    <PacketAssignment />
                  </TabPanel>
                  <TabPanel value={value} index={17}>
                    <Reports />
                    {/*  */}
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <ProfileSection />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <AdminNavigationTabs />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <AgenciesMenu />
                  </TabPanel>
                  <TabPanel value={value} index={7}>
                    <ContractorsMenu />
                  </TabPanel>
                  <TabPanel value={value} index={8}>
                    <ClientsMenu />
                  </TabPanel>
                  <TabPanel value={value} index={9}>
                    <UsersMenu />
                  </TabPanel>
                  <TabPanel value={value} index={10}>
                    <ServicesMenu />
                  </TabPanel>
                  <TabPanel value={value} index={11}>
                    <ActionsMenu />
                  </TabPanel>
                  <TabPanel value={value} index={12}>
                    <SearchSpillsResults />
                  </TabPanel>
                  <TabPanel value={value} index={16}>
                    <Suspense fallback={<div></div>}>
                      <SpillAnalytics />
                    </Suspense>
                  </TabPanel>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ user }) => {
  return {
    currentUser: user.currentUser.data,
    error: user.currentUser.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentSideBarValue: bindActionCreators(setCurrentSideBarValue, dispatch),
});

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setCurrentSideBarValue: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Dashboard)));
