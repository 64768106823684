import React from 'react';

import * as downloadActions from '../actionCreators/Download';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Typography } from '@material-ui/core';
import WholeScreenModal from './WholeScreenModal';
import { AttachmentThumnail, CustomProgressLoader } from '.';
import { getAttachmentKey } from '../utils';

const AttachmentsGallery = ({
  note,
  attachmentsList,
  close,
  downloadFile,
  download,
  getSignedUrls,
}) => {
  const [signedUrlsList, setSignedUrlList] = React.useState(null);
  React.useEffect(() => {
    if (attachmentsList) {
      getSignedUrls({
        urlList: attachmentsList.map((attachment) =>
          getAttachmentKey(attachment)
        ),
      });
      setSignedUrlList(null);
    }
  }, [attachmentsList, downloadFile]);

  React.useEffect(() => {
    attachmentsList && setSignedUrlList(download.signedUrlsList.data ?? []);
  }, [download.signedUrlsList.data]);

  React.useEffect(() => {
    setSignedUrlList(null);
  }, []);

  return (
    <WholeScreenModal
      show={!!attachmentsList}
      hide={close}
      minWidth={'80vw'}
      minHeight={'80vh'}
    >
      <Typography
        variant={'h6'}
        style={{ marginBottom: '20px', marginLeft: '20px', color: '#0A5014' }}
      >
        Preview Attachments
      </Typography>
      <Grid container>
        {download.signedUrlsList.loading ? (
          <CustomProgressLoader show={true} />
        ) : (
          attachmentsList &&
          signedUrlsList &&
          signedUrlsList.map((signedUrl) => {
            const attachment = attachmentsList?.find(
              (x) => getAttachmentKey(x) === signedUrl.key
            );
            return (
              <Grid item xs={12} md={6} lg={3}>
                <AttachmentThumnail
                  note={note}
                  attachment={{
                    ...attachment,
                    key: getAttachmentKey(attachment),
                    signedUrl: signedUrl.url,
                  }}
                />
              </Grid>
            );
          })
        )}
      </Grid>
    </WholeScreenModal>
  );
};

const mapStateToProps = ({ download }) => ({ download });

const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
  getSignedUrls: bindActionCreators(downloadActions.getSignedUrls, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsGallery);
