import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  makeStyles,
  Fade,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {
  searchFieldOptions,
  activeOptions,
} from '../../../../../utils/DropdownData';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import * as userActions from '../../../../../actionCreators/User';
import * as searchActions from '../../../../../actionCreators/Search';
import * as contractorAction from '../../../../../actionCreators/Contractor';
import * as clientActions from '../../../../../actionCreators/Client';

import DataTable from '../../../../../Components/DataTable';
import NewUser from './NewUser';
import EditUser from './EditUser';
import Search from '../../../../../Components/Search';
import DownloadFullCSV from '../../../../../Components/DownloadFullCSV';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
  card: {
    overflow: 'visible',
  },
  cardContent: {
    paddingBottom: 10,
  },
  topBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButtons: {
    fontSize: '16px',
    width: 95,
  },
  accountIcon: {
    color: '#619a63',
  },
  secondaryBarContainer: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
  },
  accountIcon: {
    color: '#619a63',
  },
  secondaryBarContainer: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
  },
  listAllButton: {
    fontSize: '16px',
    padding: '5px 5px 7px 5px',
  },
  selectDropDown: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  searchContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  searchByLbl: {
    minWidth: 130,
  },
  searchByDropDown: {
    width: '200px',
    height: '40px',
  },
  activeOptionsDropDown: {
    width: 100,
    height: 40,
  },
  resultFoundContainer: {
    width: '100%',
    height: '25px',
    textAlign: 'right',
    paddingRight: '15px',
  },
  resultsFound: {
    paddingTop: '5px',
  },
  tableContainer: {
    marginTop: 10,
  },
}));

const UsersMenu = ({
  user,
  getUsers,
  searching,
  searchType,
  search,
  searchPagination,
  contractorsWithAddress,
  searchText,
  history,
  searchedData,
  getContractorsWithAddress,
  clearSearch,
  loading,
  clientOrganizationNames,
  getOrganizationNames,
}) => {
  document.title = 'Users';
  const classes = useStyles();

  const location = history.location;
  const searchParam = location.search;
  const urlParams = new URLSearchParams(searchParam);

  const { currentUser } = user;

  const [rowData, setRowData] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [searchedTableData, setSearchTableData] = useState([]);
  const [searchedTableDataToDownload, setSearchTableDataToDownload] =
    useState();
  const [searchPage, setSearchPage] = useState(0);
  let [dataForSearch, setDataForSearch] = useState({});
  const [openAddNewUser, setOpenAddNewUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [selectedSearchOptions, setSelectedSearchOptions] = useState(null);

  const [activeFilter, setActiveFilter] = useState('N/A');
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    let page = pagination.page + 1;

    if (page) {
      urlParams.set('page', parseInt(pagination.page) + 1);

      history.push({
        pathname: `/dashboard/users`,
        search: urlParams.toString(),
      });
    }
  }, [pagination]);
  const setDataAndGetUsers = () => {
    const pageNumber = urlParams.get('page');
    const text = urlParams.get('search');

    if (text) {
      const dataWithParamsObject = {
        role: currentUser.data?.role?.role,
        searchText: text,
        type: 'User',
      };

      setDataForSearch(dataWithParamsObject);

      const data = {
        page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
        searchText: text,
        type: 'User',
        role: currentUser.data?.role?.role,
        searchFields: selectedSearchOptions,
        activeFilter,
      };

      search(data);
    } else {
      clearSearch();
      // getUsers({
      //   page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
      // });
    }

    getContractorsWithAddress();
  };

  useEffect(() => {
    setDataAndGetUsers();
    getOrganizationNames();
  }, []);

  useEffect(() => {
    if (dataForSearch.searchText) {
      urlParams.set('search', dataForSearch.searchText);

      history.replace({
        pathname: `/dashboard/users`,
        search: urlParams.toString(),
      });
    }
  }, [dataForSearch]);

  useEffect(() => {
    if (user && user.type === 'CREATE_NEW_USER_SUCCESS') {
      getUsers({ page: 0 });
    }
    if (user && user.users) {
      if (!searching && Array.isArray(user.users.data)) {
        const usersData = user.users.data.map((item) => {
          item.roleName = item['role']?.role;
          item.organizationName = item['client_organization']?.name;
          return item;
        });
        setPagination(user.users.pagination);

        setTableData(
          usersData.sort((a, b) => {
            if (!a.active) return 1;
            else return -1;
          })
        );
      }
    }
  }, [getUsers, searching, user, user.users.loading, user.loading]);

  useEffect(() => {
    if (searchedData) {
      if (searchType === 'User') {
        const searchedUsersData = searchedData.data.map((item) => {
          item.roleName = item['role']?.role;
          item.organizationName = item['client_organization']?.name;
          return item;
        });
        setPagination(searchPagination);
        setSearchTableData(searchedUsersData);
      } else {
        setPagination(user.users.pagination);
        setSearchTableData([]);
      }
    }
  }, [searchedData, searchType]);

  useEffect(() => {
    if (searchedTableData) {
      const searchedUsersData = searchedTableData.map((item) => {
        const tableItems = [
          item?.full_name,
          item?.email,
          item?.client_organization?.name,
          item?.role?.role,
          item?.active,
        ];
        return tableItems;
      });

      setSearchTableDataToDownload(searchedUsersData);
    }
  }, [searchedTableData]);

  useEffect(() => {
    setShowForm(false);
    setOpenAddNewUser(false);
    setOpenEditUser(false);
  }, [location.key]);

  const tableHeaders = [
    { title: 'Name', key: 'full_name' },
    { title: 'E-Mail', key: 'email' },
    {
      title: ['Contractor Admin']?.includes(user?.currentUser?.data?.role?.role)
        ? ''
        : 'Organization',
      key: ['Contractor Admin']?.includes(user?.currentUser?.data?.role?.role)
        ? ''
        : 'organizationName',
    },
    { title: 'Role', key: 'roleName' },
    { title: 'Active', key: 'active' },
  ];

  const handleAllUsersClick = () => {
    setShowForm(false);
    setOpenAddNewUser(false);
    setOpenEditUser(false);
  };

  const handleOpenEditModalWithDataTable = async (data) => {
    setRowData(data);
    setOpenEditUser(true);
    handleOpenEditUserModal(true);
  };

  const handleOpenNewUserModal = () => {
    setShowForm(true);
    setOpenAddNewUser(true);
    setOpenEditUser(false);
  };

  const handleCloseNewUserModal = () => {
    setShowForm(false);
    setOpenAddNewUser(false);
  };

  const handleOpenEditUserModal = () => {
    setShowForm(true);
    setOpenEditUser(true);
    setOpenAddNewUser(false);
  };

  const handleCloseEditUserModal = () => {
    setShowForm(false);
    setOpenEditUser(false);
    setDataAndGetUsers();
  };

  const handleOpenEditForm = () => {
    setOpenEditUser(true);
    setOpenAddNewUser(false);
  };

  const handleTablePage = (data) => {
    if (data.type === 'search') {
      setSearchPage(data.newPage);

      dataForSearch = {
        ...dataForSearch,
        page: data.newPage,
        searchFields: selectedSearchOptions,
        activeFilter,
      };

      search(dataForSearch);
    } else {
      getUsers({ page: data.newPage });
    }
  };

  const handleUserSearch = (data) => {
    setDataForSearch(data);
    data = {
      ...data,
      searchFields: selectedSearchOptions,
      page: searchPage,
      activeFilter,
    };

    search(data);
  };

  const handlePageReset = () => {
    setSearchPage(0);
    getUsers({ page: 0 });

    history.push({
      pathname: `/dashboard/users`,
    });
  };

  useEffect(() => {
    if (location?.search) {
      const urlPage = location?.search.split('=');
      const urlPageNumber = parseInt(urlPage.pop(), 10);
      if (urlPageNumber) setCurrentPage(urlPageNumber);
    }
  }, [location]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={24} md={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.topBarContainer}>
                <div>
                  <Typography variant='h5' color='primary'>
                    Users
                  </Typography>
                </div>
                <div>
                  <AccountCircleIcon
                    fontSize='large'
                    className={classes.accountIcon}
                  />
                </div>
              </div>
              <hr />
              <div className={classes.secondaryBarContainer}>
                <div className={classes.buttonsContainer}>
                  {showForm ? (
                    <Button
                      className={classes.listAllButton}
                      color='primary'
                      onClick={handleAllUsersClick}
                    >
                      All Users
                    </Button>
                  ) : currentUser &&
                    currentUser &&
                    currentUser.data?.role?.permission?.create_users ? (
                    <Button
                      className={classes.menuButtons}
                      color='primary'
                      onClick={handleOpenNewUserModal}
                    >
                      Add New
                    </Button>
                  ) : null}

                  <DownloadFullCSV
                    type={'User'}
                    dataForSearch={
                      searchedTableData.length > 0 ? dataForSearch : null
                    }
                    searchFilters={{
                      searchFields: selectedSearchOptions,
                      activeFilter,
                    }}
                  />
                </div>
                <div className={classes.searchContainer}>
                  <InputLabel
                    id='search_field_option_label'
                    className={classes.searchByLbl}
                  >
                    Search By
                  </InputLabel>
                  <Select
                    labelId='search_field_option_label'
                    id='search_field_option'
                    name='search_field_option'
                    className={classes.searchByDropDown}
                    onChange={(event) =>
                      setSelectedSearchOptions(event.target.value)
                    }
                    variant='outlined'
                    classes={{
                      select: classes.selectDropDown,
                    }}
                  >
                    <MenuItem value=''>
                      <em>--Select--</em>
                    </MenuItem>
                    {searchFieldOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.label}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    labelId='active_option_label'
                    id='active_option'
                    name='active_option'
                    className={classes.activeOptionsDropDown}
                    onChange={(event) => setActiveFilter(event.target.value)}
                    variant='outlined'
                    classes={{
                      select: classes.selectDropDown,
                    }}
                    defaultValue={'N/A'}
                  >
                    {activeOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.label}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <Search
                    type={'User'}
                    userRole={currentUser.data?.role?.role}
                    searchData={searchType === 'User' ? searchText : ''}
                    isSearching={
                      searching && searchType === 'User' ? true : false
                    }
                    handleSearch={(data) => handleUserSearch(data)}
                    resetPage={() => handlePageReset()}
                    showDropDown={[
                      'Parent Organization',
                      'Child Organization',
                      'Client',
                    ].includes(selectedSearchOptions)}
                    options={clientOrganizationNames}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          <div className={classes.resultFoundContainer}>
            {searchedTableData.length > 0 ? (
              <div className={classes.resultsFound}>
                Results found ({pagination.count})
              </div>
            ) : null}
          </div>
          <div className={classes.tableContainer}>
            {searching && searchType === 'User'
              ? !openAddNewUser &&
                !openEditUser && (
                  <div>
                    {searchedTableData.length > 0 ? (
                      <DataTable
                        tableHeader={tableHeaders}
                        tableBody={searchedTableData}
                        editTable={true}
                        pagination={pagination}
                        onRowSelect={handleOpenEditModalWithDataTable}
                        pageChange={handleTablePage}
                        type={'search'}
                        openEditForm={handleOpenEditForm}
                        loading={loading}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPageRows={() => {}}
                      />
                    ) : (
                      <p>No user found.</p>
                    )}
                  </div>
                )
              : !openAddNewUser &&
                !openEditUser && (
                  <div>
                    {user ? (
                      <DataTable
                        tableHeader={tableHeaders}
                        tableBody={tableData}
                        editTable={true}
                        pagination={pagination}
                        onRowSelect={handleOpenEditModalWithDataTable}
                        pageChange={handleTablePage}
                        openEditForm={handleOpenEditForm}
                        loading={loading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setPageRows={() => {}}
                      />
                    ) : (
                      <p>No data found.</p>
                    )}
                  </div>
                )}
          </div>
        </Grid>
      </Grid>
      {openAddNewUser && (
        <Fade in={openAddNewUser}>
          <div className={classes.paper}>
            <NewUser
              closeAddUserModal={handleCloseNewUserModal}
              contractorsDataWithAddresses={contractorsWithAddress}
            />
          </div>
        </Fade>
      )}

      {openEditUser && (
        <Fade in={openEditUser}>
          <div className={classes.paper}>
            <EditUser
              closeEditModal={handleCloseEditUserModal}
              userData={rowData}
              contractorsDataWithAddresses={contractorsWithAddress}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, client, search, contractor }) => ({
  user,
  searching: search.searching,
  searchType: search.searchType,
  searchPagination: search.searchPagination,
  contractorsWithAddress: contractor.contractorsWithAddress,
  searchText: search.searchText,
  searchedData: search.searchedData,
  clientOrganizationNames: client.clientOrganizationNames,
  loading:
    user.loading || search.loading || client.loading || contractor.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: bindActionCreators(userActions.getUsers, dispatch),
  getContractorsWithAddress: bindActionCreators(
    contractorAction.getContractorsWithAddress,
    dispatch
  ),
  search: bindActionCreators(searchActions.search, dispatch),
  clearSearch: bindActionCreators(searchActions.clearSearch, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
});

UsersMenu.prototype = {
  user: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersMenu)
);
