import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import * as userActions from "./../../actionCreators/User";
import { CustomProgressLoader } from "../../Components";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1453747063559-36695c8771bd?auto=format&amp;fit=crop&amp;w=400&amp;q=80&quot)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  signInText: {
    flexGrow: 1,
    textAlign: "left",
    display: "flex",
    paddingTop: 30,
    justifyContent: "center",
  },
  headerTextSection: {
    paddingLeft: "3%",
  },
  signInSpan: {
    fontWeight: "bolder",
    color: green[800],
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  checkbox: {
    display: "flex",
  },
  forgetPassword: {
    display: "flex",
  },
  textColor: {
    color: green[800],
  },
  submit: {
    margin: theme.spacing(1),
  },
}));

const colorTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[800],
    },
  },
});

const LoginPage = ({ history, user, logIn, location }) => {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorToShow, setErrorToShow] = React.useState("");

  const { search } = location;

  const urlParams = new URLSearchParams(search);

  let externalUrl = urlParams.get("redirectUrl");
  externalUrl = externalUrl ? `/rfs.htm${externalUrl}` : null;
  const redirectUrl =
    location.state && location.state.redirectUrl
      ? location.state && location.state.redirectUrl
      : externalUrl;

  const handleSubmit = (email, password) => {
    const obj = {
      email,
      password,
    };

    logIn(obj, history, redirectUrl);
  };
  React.useEffect(() => {
    user.error && setErrorToShow(user.error);
  }, [user.error]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.signInText}
          >
            Sign <span className={classes.signInSpan}>in</span>
          </Typography>

          {user.loading ? (
            <CustomProgressLoader show={true} />
          ) : (
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(email, password);
              }}
            >
              <ThemeProvider theme={colorTheme}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  id="email"
                  name="email"
                  color="primary"
                  margin="normal"
                  variant="outlined"
                  autoComplete="email"
                  label="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  required
                  fullWidth
                  id="password"
                  color="primary"
                  name="password"
                  type="password"
                  margin="normal"
                  label="Password"
                  variant="outlined"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </ThemeProvider>

              {<p style={{ color: "red" }}>{errorToShow}</p>}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <ThemeProvider theme={colorTheme}>
                    <Checkbox value="remember" color="primary" />
                  </ThemeProvider>
                }
                label="Remember me"
              />

              <ThemeProvider theme={colorTheme}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
              </ThemeProvider>

              <Grid container>
                <Grid item xs>
                  <Link
                    className={classes.forgetPassword}
                    href="#"
                    variant="body2"
                  >
                    <p className={classes.textColor}>Forgot password?</p>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    <p className={classes.textColor}>
                      Don't have an account? Sign Up
                    </p>
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  logIn: bindActionCreators(userActions.logIn, dispatch),
});

LoginPage.propTypes = {
  user: PropTypes.object.isRequired,
  logIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectUrl: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
