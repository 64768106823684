import React from "react";
import { withStyles } from "@material-ui/core/styles";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import {
  formatTimeInNote,
  fileSizeString,
  noteFieldsMapper,
  genericKeyMapper,
  salvageContainersVisibleFields,
  getPublicIp,
  getAttachmentKey,
  timeConvertMinsToHHMM,
  fileTypes,
  getFormattedTime,
} from "../../../../../utils";
import { AttachmentsGallery } from "../../../../../Components";

import PropTypes from "prop-types";
import Moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as downloadActions from "../../../../../actionCreators/Download";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import EditIcon from "@material-ui/icons/Edit";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRight: "none",
    borderLeft: "none",
    boxShadow: "none",
    borderRadius: "10px",
    transition: "all 0.5s",
    transform: "scale(0.98)",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "&:hover": {
      boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.25)",
      cursor: "default",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    flexDirection: "column",
    width: "100%",
    "&$expanded": {
      margin: "12px 0",
    },
    "&:hover": {
      cursor: "default",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  smallText: {
    fontSize: "15px",
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "10px",
    userSelect: "text",
  },
  headerCard: {
    margin: "5px 0",
  },
  upperText: {
    marginBottom: "1rem",
    color: "#295115",
    fontSize: "13px",
    userSelect: "text",
  },
  bold: {
    fontWeight: "800",
  },
  inlineButton: {
    maxWidth: "10px",
  },
  rightAlign: {
    textAlign: "right",
  },
  attachmentsContainer: {
    display: "flex",
  },
  fileTile: {
    color: "#295115",
    backgroundColor: "#E0E0E0",
    padding: "5px 10px",
    margin: theme.spacing(0.5),
    fontWeight: "800",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    display: "inline-flex",
    borderRadius: "5px",
  },
  fileNameLabel: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "200px",
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row",
  },
  downloadAllContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
  },
  downloadAllBtn: {
    border: "1px solid #E0E0E0",
    backgroundColor: "white",
    borderRadius: "7px",
    color: "#2F7D32",
    transition: "all 0.4s",
    height: "32px",
    marginTop: "-3px",
    paddingTop: "7px",
    padding: "10px",
    marginLeft: "6px",
    "&:hover": {
      backgroundColor: "#2F7D32",
      color: "white",
      boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.75)",
      borderColor: "#2F7D32",
      cursor: "pointer",
    },
  },
  toolTipLabel: {
    fontSize: "15px",
    background: "white",
    boxShadow: " 0px 0px 3px 0px rgb(0 0 0 / 50%)",
    color: "#497c4b",
    padding: "10px",
  },
  detailsContainer: {
    rowGap: "20px",
  },
  heading: {
    fontWeight: "bold",
    borderBottom: "0.5px solid #0000002e",
    marginBottom: "10px",
    paddingBottom: "4px",
  },
  value: {
    transition: "all 0.5s",
  },
  detailsRecord: {
    marginLeft: 5,
    border: "1px solid #80808024",
    borderRadius: 7,
    transition: "all 0.5s",
    minHeight: 36,
    alignItems: "center",
    padding: 10,
    "&:hover": {
      boxShadow: "0px 0px 3px 0px rgb(31 113 35)",
    },
  },
  salvageContainer: {
    borderBottom: "1px dashed #8eb513d6",
    margin: "10px 0px",
    padding: "10px 0px",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  expandedIcon: {
    transition: "all 0.5s",
    transform: "rotateX(180deg)",
  },
  closedIcon: {
    transition: "all 0.5s",
  },
  description: {
    whiteSpace: "pre-wrap",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: 0,
    userSelect: "text",
  },
}));

const NoteCardAccordion = ({
  note,
  onEdit,
  isEditable,
  downloadFile,
  showTimeStamp,
  showMonetaryAmounts,
  downloadAll,
  toolTipText,
  isDisabled,
  currentSpill,
  currentUser,
  showTimeStamps,
}) => {
  const [summaryFields, setSummaryFields] = React.useState({});
  const [detailsFields, setDetailsFields] = React.useState({});
  const [noteAttachmentsToView, setNoteAttachmentsToView] =
    React.useState(null);
  const [expanded, setExpanded] = React.useState(true);
  const classes = useStyles();
  const getTime = (createdAt, checkTimeStamp = true) => {
    const format =
      showTimeStamp || !checkTimeStamp ? "MM-DD-YYYY h:mm a" : "MM-DD-YYYY";
    return createdAt
      ? Moment(new Date(createdAt))?.format(format)
      : Moment(new Date())?.format(format);
  };
  const checkTime = (value, key) => {
    return ["updated_at", "projected_eta", "actual_eta", "created_at"].includes(
      key
    )
      ? getTime(value, false)
      : typeof value === "boolean"
      ? value
        ? "Yes"
        : "No"
      : value ?? "N/A";
  };

  React.useEffect(() => {
    const {
      id,
      created_at,
      service_type,
      user,
      type,
      hour,
      rate,
      amount,
      description,
      note_attachments,
      ...details
    } = note;

    setSummaryFields({
      id,
      created_at,
      service_type,
      user,
      type,
      hour,
      rate,
      amount,
      description,
      note_attachments,
    });

    const detailsObject = {};
    Object.keys(details).forEach((key) => {
      if (
        (details[key] || details[key] === false) &&
        key in noteFieldsMapper &&
        (key !== "salvage_containers" || details[key].length > 0)
      )
        detailsObject[key] = details[key];

      if (key === "date" && details[key]) {
        detailsObject[key] = Moment(new Date(details[key]))?.format(
          "MM-DD-YYYY"
        );
      }
    });
    setDetailsFields(detailsObject);
  }, [note]);

  const verifyAttachmentType = (fileName = "", summaryFields) => {
    const { service_type } = summaryFields;
    if(!fileName) {
      return false;
    }
    const attachmentTypeWithExt = fileName?.substring(fileName?.indexOf(" ") + 1);
    const attachmentType = attachmentTypeWithExt
      ?.split(".")
      ?.slice(0, -1)
      ?.join(".");
    let foundedFileType = fileTypes?.find((fileType) =>
      attachmentType?.includes(fileType)
    );
    // 26 service_id
    return (
      foundedFileType === "Invoice & Report Package" &&
      service_type === "Closing Report To Client -- Administrative Support"
    );
  };

  return (
    <Accordion square expanded={expanded}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container justify="space-between">
          <Grid item xs={2} className={classes.rowFlex}>
            <Typography color={"primary"} variant={"body2"}>
              Note Id :
            </Typography>
            <Typography variant={"body2"}>{summaryFields?.id}</Typography>
          </Grid>

          <Grid item xs={3}>
            {showTimeStamps && (
              <div className={`${classes.upperText}`}>
                {Moment(new Date(summaryFields?.created_at))?.format(
                  "MM-DD-YYYY h:mm a"
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={5}>
            <div className={`${classes.upperText}`}>
              {summaryFields?.service_type}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${classes.upperText} ${classes.rightAlign}`}>
              {summaryFields?.user?.full_name}
            </div>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            {showMonetaryAmounts && (
              <div className={classes.infoWrapper}>
                <div>
                  {summaryFields?.type === "fixed"
                    ? "Fixed"
                    : summaryFields?.hour
                    ? `${summaryFields?.hour} h`
                    : "0.00 h"}
                  {"@" + summaryFields?.rate
                    ? ` $${summaryFields?.rate}`
                    : "$0.00"}
                </div>

                <div className={classes.bold}>
                  ${summaryFields?.amount ? summaryFields?.amount : "0.00"}
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <pre className={classes.description}>
              {formatTimeInNote(summaryFields?.description)}
            </pre>
          </Grid>

          <Grid item xs={1}>
            {isEditable &&
              (isDisabled ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={toolTipText}
                  classes={{
                    tooltip: classes.toolTipLabel,
                  }}
                  placement={"left"}
                >
                  <span>
                    <Button
                      className={classes.inlineButton}
                      color="primary"
                      onClick={onEdit}
                      disabled={isDisabled}
                    >
                      Edit
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  className={classes.inlineButton}
                  color="primary"
                  onClick={onEdit}
                  disabled={isDisabled}
                  startIcon={<EditIcon style={{ height: "50%" }} />}
                >
                  Edit
                </Button>
              ))}
          </Grid>
        </Grid>
        <div>
          {summaryFields?.note_attachments?.map((attachment, index) => {
            let isAttachmentEmpty = false;

            Object.values(attachment).every((value) => {
              if (value === null) {
                isAttachmentEmpty = true;
              } else {
                isAttachmentEmpty = false;
              }
            });

            return isAttachmentEmpty ? null : (
              <div
                key={index}
                className={classes.fileTile}
                onClick={async () => {
                  downloadFile({
                    key: getAttachmentKey(
                      isAttachmentEmpty ? null : attachment
                    ),
                    note_service_id: note.service_id,
                    attachment_id: attachment.id,
                    attachment_created_at: attachment.created_at,
                    spillId: currentSpill.data.spill.id,
                    ipAddress: await getPublicIp(),
                    user_id: currentUser?.data?.id,
                    username: currentUser?.data?.full_name,
                    email: currentUser?.data?.email,
                    note_created_at: summaryFields.created_at,
                    shouldLog: verifyAttachmentType(
                      attachment?.key,
                      summaryFields
                    ),
                  });
                }}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title={
                    <div>
                      {attachment.name}({fileSizeString(attachment.size)})
                    </div>
                  }
                  classes={{
                    tooltip: classes.toolTipLabel,
                  }}
                  placement={"top"}
                >
                  <div className={classes.fileNameLabel}>
                    <span>{attachment.name}</span>
                    <span>({fileSizeString(attachment.size)})</span>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
        <Grid container style={{ margin: "10px" }}>
          <Grid item xs={7}>
            {summaryFields?.note_attachments?.length > 0 && (
              <Button
                color="primary"
                onClick={() =>
                  setNoteAttachmentsToView(summaryFields.note_attachments)
                }
                startIcon={<PhotoLibraryIcon />}
              >
                View Attachments
              </Button>
            )}
            {summaryFields?.note_attachments?.length > 1 && (
              <Button
                color="primary"
                onClick={async () =>
                  downloadAll({
                    id: summaryFields?.id,
                    type: "note_all",
                    name: `${summaryFields?.id}-all-attachments.zip`,
                    spillId: currentSpill?.data?.spill?.id,
                    ipAddress: await getPublicIp(),
                  })
                }
                startIcon={<CloudDownloadIcon />}
              >
                Download attachments
              </Button>
            )}
          </Grid>
          {/* see more button hide  */}
          {/* 
          {detailsFields && Object.keys(detailsFields).length > 0 && (
            <Grid item xs={5} align={'right'}>
              <Button
                onClick={() => setExpanded(!expanded)}
                color={'primary'}
                align={'right'}
                startIcon={
                  <ExpandMoreIcon
                    className={`${
                      expanded ? classes.expandedIcon : classes.closedIcon
                    }`}
                  />
                }
                style={{ textDecoration: 'underline' }}
              >
                See more
              </Button>
            </Grid>
          )} */}
        </Grid>

        <AttachmentsGallery
          note={note}
          attachmentsList={noteAttachmentsToView}
          close={() => setNoteAttachmentsToView(null)}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          justify="space-between"
          className={classes.detailsContainer}
        >
          {detailsFields &&
            Object.keys(detailsFields).map((key) => {
              const isSalvage = key === "salvage_containers";
              return (
                (!isSalvage || detailsFields[key].length > 0) &&
                key in noteFieldsMapper && (
                  <Grid item xs={6} className={`${classes.detailsRecord}`}>
                    <Grid item xs={12}>
                      <Typography
                        color={"primary"}
                        variant={"body2"}
                        className={classes.heading}
                        align={"center"}
                      >
                        {noteFieldsMapper[key]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} justify={"center"}>
                      {isSalvage ? (
                        <>
                          {detailsFields[key].map((salvageContainer) => {
                            return (
                              <div className={classes.salvageContainer}>
                                {Object.keys(salvageContainer).map((key2) => {
                                  return (
                                    salvageContainersVisibleFields.includes(
                                      key2
                                    ) && (
                                      <Grid container>
                                        <Grid item xs={6}>
                                          <Typography
                                            color={"primary"}
                                            variant={"body2"}
                                            align={"left"}
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {genericKeyMapper(key2)} :
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant={"body2"}
                                            className={classes.value}
                                          >
                                            {checkTime(
                                              salvageContainer[key2],
                                              key2
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )
                                  );
                                })}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <Typography
                          variant={"body2"}
                          className={classes.value}
                          align={"center"}
                        >
                          {key === "projected_eta"
                            ? `${timeConvertMinsToHHMM(
                                detailsFields[key]
                              )} at ${getTime(
                                summaryFields?.created_at,
                                false
                              )}`
                            : key === "actual_eta"
                            ? `${timeConvertMinsToHHMM(
                                detailsFields[key]
                              )} at ${getTime(
                                summaryFields?.created_at,
                                false
                              )}`
                            : key === "established_lane_closure"
                            ? detailsFields[key]
                              ? "Yes"
                              : "No"
                            : detailsFields[key]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )
              );
            })}

          {note.release_coverages?.length ? (
            <Grid item xs={12} >
              <div className="coverage-card">
                {note.release_coverages.map((coverage) => (
                  <div key={coverage.id} className="release-coverage-card">
                    <p
                      style={{
                        textAlign: "center",
                        color: "#2F7D32",
                        fontWeight: "bold",
                        margin: "0",
                        fontSize: "14px",
                        borderBottom:"2px solid #80808024",
                        paddingBottom:"6px",
                      }}
                    >
                      Release Coverage
                    </p>
                    <p>
                      <strong>footprint type:</strong>{" "}
                      {coverage?.footprint_type}
                    </p>
                    <p>
                      <strong>Surface:</strong> {coverage.surface}
                    </p>
                    <p>
                      <strong>Area:</strong> {coverage.covered_area}
                    </p>
                  </div>
                ))}
              </div>
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = ({ spill, user }) => ({
  currentSpill: spill.currentSpill,
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
  downloadAll: bindActionCreators(downloadActions.downloadAll, dispatch),
});

NoteCardAccordion.propTypes = {
  note: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  downloadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteCardAccordion);
