import React, { useState } from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm, FormProvider } from "react-hook-form";
import ReactSelect from "react-select";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import * as userActions from "../../../../../actionCreators/User";
import * as spillActions from "../../../../../actionCreators/Spill";
import * as clientActions from "../../../../../actionCreators/Client";
import ClearIcon from '@material-ui/icons/Clear';
import {
  compare,
  orderAlphabaticallyByKey,
  ROLES,
  removeProbPMFromOptions,
  forceToCST,
} from "../../../../../utils";
import { CustomProgressLoader, CustomSnackBar } from "../../../../../Components";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import escapeRegExp from '../../../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  checkBoxMisc: {
    display: "inline",
  },
  checkBoxAlignment: {
    paddingLeft: "0px",
  },
}));

const NewSpill = ({
  users,
  currentUser,
  admins,
  getAdmins,
  closeAddSpill,
  getOrganizationNames,
  clientOrganizationNames,
  getUsersByOrgId,
  createNewSpill,
  editSpill,
  currentSpill,
  loading,
  isTestSpill,
  getFacility,
  facilities,
  client,
  getIcsTerminal,
  currentSpillReset
}) => {
  const classes = useStyles();
  const year = Moment(new Date())?.format("YY");
  const month = Moment(new Date())?.format("MM");

  const code = `${year}-???-${month}`;

  let orgs = [];
  const organizationNames = clientOrganizationNames.data;
  const role = currentUser.data.role.role;

  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [selectedAdmins, setSelectedAdmins] = React.useState([]);
  const [adminOptions, setAdminOptions] = React.useState([]);
  const [organizationValue, setOrganizationValue] = React.useState("");
  const [attachment, setAttachment] = React.useState(false);
  const [defaultCode, setDefaultCode] = React.useState(code);
  const [selectState, setSelectState] = useState({
    organizationSelect: false,
  });
  const [isComplete, setIsComplete] = useState(false);
  const [currentManager, setCurrentManager] = useState([]);
  const [openedOn, setOpenedOn] = useState(new Date());
  const [openedErrorFlag, setOpenedErrorFlag] = useState(false);
  const [sendEmails, setSendEmails] = useState(true);
  const [isSpillEmergency, setIsSpillEmergency] = useState(false);
  const [facilityOption, setFacilityOption] = React.useState([]);
  const [selectedFacility, setSelectedFacility] = useState({});
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [isFacilityReset, setIsFacilityReset] = useState(true);
  const [showTerminalTeamDropdown, setShowTerminalTeamDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const { success } = currentSpill;
  const [terminalOptions, setTerminalOptions] = useState([]);
  const [selectedTerminalId, setSelectedTerminalId] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [userOptions, setUserOptions] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [ filteredTerminalTeams, setFilteredTerminalTeams] = useState([]);
  const [terminalTeamsValue, setTerminalTeamsValue] = useState("");
  const [isIcsClient, setIsIcsClient] = useState(false)
  const [selectedTerminalTeam, setSelectedTerminalTeam] = useState(null)

  const TeamData = useSelector((state) => state.client.clientTerminal?.data);

  React.useEffect(() => {
    if (success && isComplete) {
      editSpill(currentSpill?.data.spill.job_no);
    }
  }, [success, isComplete]);

  React.useEffect(() => {
    if (admins.data) {
      const adminOptions = removeProbPMFromOptions(admins.data).map((admin) => {
        const value = { value: admin.id, label: admin.full_name };
        const allOptions = { ...value };
        return allOptions;
      });
      setAdminOptions(adminOptions.sort(compare));
    }
  }, [admins]);

  const updateOpenedOn = async (value) => {
    const currentTime = value?._d?.getTime();
    if (currentTime <= new Date().getTime()) {
      setOpenedOn(value);
      setOpenedErrorFlag(false);
    } else setOpenedErrorFlag(true);
  };

  React.useEffect(() => {
    setFacilityOption([]);
    getOrganizationNames();
    getAdmins({ filter: "none" });
    currentSpillReset();
  }, []);

  React.useEffect(() => {
    if(Array.isArray(TeamData)) {
      setTeams(TeamData);
      setFilteredTerminalTeams(TeamData);
    }
  }, [TeamData]);

  organizationNames.filter((organization) => {
    organization.active &&
      (role !== ROLES.DEMO
        ? organization.id !== 9 && orgs.push(organization)
        : orgs.push(organization));
  });

  React.useEffect(() => {
    if (Array.isArray(users.data) && organizationValue) {
      const usersForEmailOptions = users.data
        .map((user) => {
          const value = {
            value: user.id,
            label: user.full_name,
            email: user.email,
            active: user.active,
            is_default: user.is_default,
          };
          const allOptions = { ...value };

          return allOptions;
        })
        .filter((user) => user.active);
      setUserOptions(usersForEmailOptions);

      const defaultUsers = usersForEmailOptions.filter(
        (user) => user.is_default
      );
      defaultUsers.length && setSelectedUsers(defaultUsers);
    }
  }, [users.data]);

  React.useEffect(() => {
    if (
      facilities?.success &&
      Array.isArray(facilities?.data) &&
      !isFacilityReset
    ) {
      setFacilityOption([]);
      setSelectedFacility({});
      const facilityOptions = facilities?.data.map((facility) => {
        const value = {
          value: facility.id,
          label: facility.name,
        };
        const allOptions = { ...value };
        return allOptions;
      });
      if (facilityOptions?.length) {
        setFacilityOption(facilityOptions);
      }
    }
  }, [facilities?.data, facilities?.success]);

  React.useEffect(() => {
    if (client?.type === "GET_ICS_TERMINAL_FAILURE" && client?.error) {
      setErrorMessage(client?.error);
    }
  }, [client?.error, client?.type]);

  const handleSelectChange = (key) => {
    let tempObject = selectState;
    tempObject[key] = true;
    setSelectState(tempObject);
  };

  const selectValidator = () => {

  
    let flag = false;
    Object.values(selectState).forEach((val) => {
      if (val === false) {
        flag = true;
      }
    });

    if (role === ROLES.PROB_PM && Array.isArray(currentManager) && !currentManager?.length) {
      setErrorMessage("Please Select Current Project Manager.");
      return true;
    }
    if (Array.isArray(selectedAdmins) && !selectedAdmins.length) {
      setErrorMessage("Please Select Project Manager.");
      return true;
    }
    if (!organizationValue) {
      setErrorMessage("Please Select Organization.");
      return true;
    }

    return flag;
  };

  const { handleSubmit, register } = useForm();
  const methods = useForm({
    defaultValues: {
      job_no: defaultCode,
    },
  });

  const sendEmailsForSpillHandler = (isToSendEmail) => {
    setSendEmails(isToSendEmail);
  };

  const handleTerminalTeamSelect = (terminalTeam) => {
    setTerminalTeamsValue(terminalTeam?.terminalName || '');
    if(!terminalTeam?.terminalName) {
      setFilteredTerminalTeams(teams);
    }
    setSelectedTerminalTeam(terminalTeam);
    setSelectedTeamId(terminalTeam?.teamId);
    setSelectedTerminalId(terminalTeam?.terminalId);
  }

    const handleTerminalTeamFieldChange = (event) => {
      const newValue = event?.target?.value;
      if (!newValue) {
        setFilteredTerminalTeams(teams);
        setTerminalTeamsValue(newValue);
        return;
      }
      const trimmedValue = escapeRegExp(newValue?.trim()?.toLowerCase());
      const regexPattern = new RegExp(`\\b${trimmedValue}\\w*\\b`, 'i');
      const filteredTerminalTeamsData = teams?.filter((terminalTeam) => {
        return (
          regexPattern?.test(terminalTeam?.terminalName?.toLowerCase()) ||
          (terminalTeam?.team && regexPattern?.test(terminalTeam?.team?.toLowerCase()))
        );
      });
    
      setFilteredTerminalTeams(filteredTerminalTeamsData);
      setTerminalTeamsValue(newValue);
    };

  const handleOrganizationSelect = async (org_id, event) => {
    event.preventDefault();
    setTeams([]);
    setTerminalOptions([]);
    setSelectedTerminalId(null);
    setSelectedTeamId(null);
    setSelectedFacility({});
    setSelectedOrganization(org_id);
    setSelectedUsers([]);
    handleSelectChange("organizationSelect");
    const id = { id: org_id };
    getUsersByOrgId(id);
    setIsFacilityReset(false);
    const selectedOrg = orgs.find((org) => org.id === org_id);
    setIsIcsClient(selectedOrg?.is_ics_client ?? false)
    if (selectedOrg && selectedOrg.is_ics_client === true) {
      setShowTerminalTeamDropdown(true);
      getIcsTerminal({ client_name: selectedOrg?.name, terminal_list: true })
    } else {
      setShowTerminalTeamDropdown(false);
      getFacility({ org_id });
    }
  };

  const onFinish = (newSpill) => {
    if (selectValidator()) {
      return;
    }
    if (organizationValue !== "" && attachment !== "") {
      const selectedFacilityData = facilities?.data.find(
        (facility) => facility.id === selectedFacility?.value
      );
      
      newSpill = {
        ...newSpill,
        user_id: selectedAdmins.value,
        org_id: organizationValue,
        send_attachment: attachment,
        status: "Open: Work In Progress",
        manager_id: currentManager.value,
        opened_on: forceToCST(openedOn),
        facility_id: selectedFacility ? selectedFacility?.value : null,
        terminal_id: selectedTerminalId || null,
        team_id: selectedTeamId || null,
        facility_address: selectedFacilityData
        ? {
            address: selectedFacilityData.address,
            city: selectedFacilityData.city,
            state: selectedFacilityData.state,
            country: selectedFacilityData.country,
            zip_code: selectedFacilityData.zip_code,
          }
        : null,
        terminal_addresss: selectedTerminalTeam
        ? {
            address: selectedTerminalTeam.address,
            city: selectedTerminalTeam.city,
            state: selectedTerminalTeam.state,
            zip_code: selectedTerminalTeam.zip,
          }
        : null,
      };
    }

    newSpill = {
      ...newSpill,
      users: selectedUsers,
      is_test_spill: isTestSpill,
      send_email: sendEmails,
      is_emergency: isSpillEmergency,
    };
    createNewSpill(newSpill);
    setIsComplete(true);
  };

  return (
    <React.Fragment>
      <CustomSnackBar
        open={!!errorMessage}
        severity='error'
        snackBarMessage={errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <Container maxWidth="sm">
        <div style={{ paddingTop: 10 }} />
        <Typography variant="subtitle1" gutterBottom>
          <div style={{ textAlign: "center" }}>
            <span>{isTestSpill ? "New Test Spill" : "New Spill"}</span>
          </div>
        </Typography>
        {loading ? (
          <CustomProgressLoader show={true} />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onFinish(data))}>
              <Grid container spacing={3}>
                {role === ROLES.PROB_PM && (
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <InputLabel id="manager-select-label">
                      Current Project Manager *
                    </InputLabel>
                    <ReactSelect
                      value={currentManager}
                      isMulti={false}
                      onChange={setCurrentManager}
                      options={orderAlphabaticallyByKey(adminOptions)}
                    />
                  </Grid>
                )}
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <InputLabel id="manager-select-label">
                    Project Manager *
                  </InputLabel>
                  <ReactSelect
                    value={selectedAdmins}
                    isMulti={false}
                    onChange={setSelectedAdmins}
                    options={orderAlphabaticallyByKey(adminOptions)}
                  />
                </Grid>

                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <InputLabel id="organization-select-label">
                    Organization *
                  </InputLabel>
                  <Select
                    labelId="organization-select-label"
                    id="organization-select"
                    name="organization"
                    value={selectedOrganization}
                    inputProps={{
                      inputRef: (ref) => {
                        if (!ref) return;
                        setOrganizationValue(ref.value);
                      },
                    }}
                    fullWidth
                    onChange={(event) =>
                      handleOrganizationSelect(event.target.value, event)
                    }
                  >
                    {orgs &&
                      orderAlphabaticallyByKey(orgs, "name").map((e, id) => {
                        return <MenuItem value={e.id}>{e.name}</MenuItem>;
                      })}
                  </Select>
                </Grid>

                {showTerminalTeamDropdown == true ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "left", paddingRight: "22px" }}
                    >
                          <Autocomplete
                                    options={filteredTerminalTeams}
                                    getOptionLabel={(option) => `${option?.terminalName}${option?.team ? ` (${option?.team})` : ''}`}
                                    value={selectedTerminalTeam}
                                    onChange={(event, newValue) => handleTerminalTeamSelect(newValue)}
                                    onBlur={() => setFilteredTerminalTeams(teams)}
                                    getOptionSelected={(option, value) => option?.terminalId === value?.terminalId && option?.teamId === value?.teamId}                                    
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Terminal (Team)"
                                        value={terminalTeamsValue}
                                        onChange={handleTerminalTeamFieldChange}
                                      />
                                    )}
                                    className="customAutocomplete"
                                  />
                    </Grid>
                  </>
                ) : (
                  <>
                    {facilityOption.length > 0 ? (
                      <Grid item xs={12} style={{ textAlign: "left" }}>
                        <InputLabel id="users-for-email-label">
                          Facility
                        </InputLabel>
                        <ReactSelect
                          value={selectedFacility}
                          required
                          isMulti={false}
                          onChange={setSelectedFacility}
                          options={orderAlphabaticallyByKey(facilityOption)}
                          isClearable
                        />
                      </Grid>
                    ) : null}
                  </>
                )}

                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <InputLabel id="users-for-email-label">
                    Users for Email
                  </InputLabel>
                  <ReactSelect
                    value={selectedUsers}
                    required
                    isMulti
                    onChange={setSelectedUsers}
                    options={orderAlphabaticallyByKey(userOptions)}
                  />
                </Grid>

                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      maxDate={new Date()}
                      value={openedOn}
                      onChange={updateOpenedOn}
                      error={openedErrorFlag}
                      label={"Opened"}
                      showTodayButton
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <InputLabel id="attachment-select-label">
                    Send Attachment *
                  </InputLabel>
                  <Select
                    labelId="attachment-select-label"
                    id="attachment-select"
                    name="attachment"
                    defaultValue={false}
                    fullWidth
                    onChange={(e) => setAttachment(e.target.value)}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </Grid>

                {isTestSpill ? (
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sendEmails}
                          onChange={(event) =>
                            sendEmailsForSpillHandler(event.target.checked)
                          }
                          name="sendEmails"
                          color="primary"
                        />
                      }
                      label="Send Email"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Checkbox
                    color="primary"
                    className={classes.checkBoxAlignment}
                    checked={isSpillEmergency}
                    onChange={(event) =>
                      setIsSpillEmergency(event.target.checked)
                    }
                  />
                  <InputLabel
                    id="non_emergency"
                    className={classes.checkBoxMisc}
                  >
                    Mark incident as Non-Emergency
                  </InputLabel>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Create and Edit
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => closeAddSpill()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        )}
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { users, admins, currentUser },
  client: { clientOrganizationNames, facilities, clientTerminal },
  spill: { success, currentSpill },
  spill,
  client,
  user,
}) => ({
  users,
  admins,
  clientOrganizationNames,
  currentSpill,
  success,
  currentUser,
  loading: spill.loading || client.loading,
  facilities,
  client
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: bindActionCreators(userActions.getAdmins, dispatch),
  createNewSpill: bindActionCreators(spillActions.createNewSpill, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  getUsersByOrgId: bindActionCreators(userActions.getUsersByOrgId, dispatch),
  getFacility: bindActionCreators(clientActions.getFacility, dispatch),
  getIcsTerminal: bindActionCreators(clientActions.getIcsTerminal, dispatch),
  currentSpillReset: bindActionCreators(spillActions.currentSpillReset, dispatch)
});

NewSpill.propTypes = {
  users: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  getAdmins: PropTypes.func.isRequired,
  closeAddSpill: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
  clientOrganizationNames: PropTypes.object.isRequired,
  getUsersByOrgId: PropTypes.func.isRequired,
  editSpill: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  facilities: PropTypes.object.isRequired,
  getIcsTerminal: PropTypes.object.isRequired,
  currentSpillReset: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSpill);
