export const getServices = (obj) => ({
  type: "GET_SERVICES",
  obj,
});

export const getServicesSuccess = (data) => ({
  type: "GET_SERVICES_SUCCESS",
  data,
});

export const getServicesFail = (error) => ({
  type: "GET_SERVICES_FAIL",
  error,
});

export const createNewService = (obj) => ({
  type: "CREATE_NEW_SERVICE",
  obj,
});

export const createNewServiceSuccess = (data) => ({
  type: "CREATE_NEW_SERVICE_SUCCESS",
  data,
});

export const createNewServiceFail = (error) => ({
  type: "CREATE_NEW_SERVICE_FAIL",
  error,
});

export const editService = (obj) => ({
  type: "EDIT_SERVICE",
  obj,
});

export const editServiceSuccess = (data) => ({
  type: "EDIT_SERVICE_SUCCESS",
  data,
});

export const editServiceFail = (error) => ({
  type: "EDIT_SERVICE_FAIL",
  error,
});

export const reorderServices = (obj) => ({
  type: "RE_ORDER_SERVICES",
  obj,
});

export const reorderServicesSuccess = (data) => ({
  type: "RE_ORDER_SERVICES_SUCCESS",
  data,
});

export const reorderServicesFail = (error) => ({
  type: "RE_ORDER_SERVICES_FAIL",
  error,
});

export const changeServiceStatus = (obj) => ({
  type: "CHANGE_SERVICE_STATUS",
  obj,
});

export const changeServiceStatusSuccess = (obj) => ({
  type: "CHANGE_SERVICE_STATUS_SUCCESS",
  obj,
});

export const changeServiceStatusFail = (error) => ({
  type: "CHANGE_SERVICE_STATUS_FAIL",
  error,
});

export const getCategories = (obj) => ({
  type: "GET_CATEGORIES",
  obj,
});

export const getCategoriesSuccess = (obj) => ({
  type: "GET_CATEGORIES_SUCCESS",
  obj,
});

export const getCategoriesFail = (error) => ({
  type: "GET_CATEGORIES_FAIL",
  error,
});
