import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {
  Grid,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ReactSelect from "react-select";

import * as searchActions from "../actionCreators/Search";
const useStyles = makeStyles(() => ({
  root: {
    justifyContent: "flex-end",
    paddingRight: "20px",
    alignItems: "center",
  },
  searchContainer: {
    maxWidth: "200px",
  },
  searchBtn: {
    paddingLeft: "5px",
  },
}));
const reactSelectStyles = () => {
  return {
    container: (styles) => {
      return {
        ...styles,
        width: 200,
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 10,
      };
    },
  };
};
const Search = ({
  type,
  userRole,
  search,
  clearSearch,
  handleSearch,
  resetPage,
  isSearching,
  searchData,
  showDropDown,
  options,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const [showClearButton, setShowClearButton] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false);

  React.useEffect(() => {
    setSearchText(searchData);
  }, [searchData]);

  React.useEffect(() => {
    handleClickClearSearchText();
  }, [showDropDown]);

  React.useEffect(() => {
    setShowClearButton(isSearching);
  }, [isSearching]);

  const handleClickClearSearchText = () => {
    setSearchText("");
    clearSearch();
    resetPage();
    setShowClearButton(false);
  };

  const handleSubmit = async (e, text) => {
    e.preventDefault();

    if (
      !text ||
      text === "" ||
      text.length === 0 ||
      (!showDropDown && !text.trim())
    ) {
      setIsEmpty(true);
      return;
    }

    const searchText = showDropDown
      ? text
      : await text.trim().split(/ +/).join(" ");

    let dataForSearch = {
      searchText,
      role: userRole,
      type,
    };

    setIsEmpty(false);
    handleSearch(dataForSearch);
  };

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event, searchText);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={10} className={classes.searchContainer}>
        {showDropDown ? (
          <ReactSelect
            value={searchText}
            required
            isMulti
            onChange={setSearchText}
            options={options.data.map((x) => {
              return { value: x.id, label: x.name };
            })}
            styles={reactSelectStyles()}
          />
        ) : (
          <TextField
            fullWidth
            error={isEmpty}
            id="search_field"
            size="small"
            variant="outlined"
            value={searchText}
            name="search_field"
            autoComplete="search_field"
            onKeyDown={keyPress}
            onChange={(e) => {
              setShowClearButton(true);
              setSearchText(e.target.value);
              if (e.target.value === "") {
                setSearchText("");
                clearSearch();
                resetPage();
                setShowClearButton(false);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle close visibility"
                    onClick={handleClickClearSearchText}
                  >
                    {showClearButton ? <ClearIcon /> : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
      <Grid item xs={2} className={classes.searchBtn}>
        <Button
          color="primary"
          variant="text"
          onClick={(e) => handleSubmit(e, searchText)}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => ({
  search: bindActionCreators(searchActions.search, dispatch),
  clearSearch: bindActionCreators(searchActions.clearSearch, dispatch),
});

Search.prototype = {
  user: PropTypes.object.isRequired,
  searchUser: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
