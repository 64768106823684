import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { getTotalCount, RESPONSE_COUNT_TYPE } from '../../utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const ResponseCount = ({ contractor, addresses, type, hide }) => {
  return !hide ? (
    type === RESPONSE_COUNT_TYPE.INDV_WHOLE_COUNT ? (
      <Chip
        label={`Contacted : ${getTotalCount(contractor) || 0}`}
        variant='outlined'
        size='small'
      />
    ) : type === RESPONSE_COUNT_TYPE.INDV_SEPARATE_COUNT ? (
      <>
        <Chip
          label={`Response Accepted: ${contractor.respond_count || 0}`}
          variant='outlined'
          color='primary'
          size='small'
        />
        <Chip
          label={`Response Rejected: ${contractor.reject_count || 0}`}
          variant='outlined'
          color='secondary'
          size='small'
        />
        <Chip
          label={`Rejected (%): ${
            (
              (contractor?.reject_count / getTotalCount(contractor)) *
              100
            )?.toFixed(2) || 0
          }`}
          variant='outlined'
          size='small'
        />
      </>
    ) : type === RESPONSE_COUNT_TYPE.WHOLE_COUNT ? (
      <Chip
        label={`All Contacted : ${
          getTotalCount(contractor) +
          addresses.reduce(
            (sum, { respond_count, reject_count }) =>
              sum + (respond_count || 0) + (reject_count || 0),
            0
          )
        }`}
        icon={
          <Tooltip title='Includes all the contractors.' arrow placement='top'>
            <InfoOutlinedIcon color='info' />
          </Tooltip>
        }
        variant='outlined'
        size='small'
      />
    ) : (
      <>
        <Chip
          label={`Response Accepted: ${
            getTotalCount(contractor) -
            addresses.reduce(
              (sum, { reject_count, respond_count }) =>
                respond_count || sum + reject_count || 0,
              0
            )
          }`}
          variant='outlined'
        />
        <Chip
          label={`Response Rejected:${
            getTotalCount(contractor) +
            addresses.reduce(
              (sum, { reject_count }) => sum + reject_count || 0,
              0
            )
          }`}
          variant='outlined'
        />
        <Chip
          label={`Rejected (%):${
            (contractor.respond_count / getTotalCount(contractor)) * 100
          }`}
          variant='outlined'
        />
      </>
    )
  ) : (
    <></>
  );
};

export default ResponseCount;
