import React, { useState, useEffect } from "react";
import { Typography, Chip, makeStyles, Button, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    marginBottom: 10,
  },
  titleAlignment: {
    textAlign: "left",
  },
  button: {
    transition: "all .4s",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: "grey",
      color: "black",
      opacity: "0.5",
    },
    padding: "5px 30px",
  },
  requestPopup: {
    display: "flex",
    flexDirection: "column",
    width: "590px",
    height: "350px",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    gap: "5px",
    padding: theme.spacing(0.5),
    margin: "20px 0",
  },
  requestPopupFooter: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "flex-end",
    gap: 10,
  },
  noDataText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30vh",
    letterSpacing: "1px"
  }
}));

const NextPrompt = ({ response, acceptText, rejectText, headingText, getLabelName, cancelRequestedDocuments, setShowNextPrompt, setCancelRequestedDocuments }) => {
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(false)

  // if submit then close both the popup and continue the flow
  const handleAccept = () => {
    setIsDisabled(true)
    response(false, cancelRequestedDocuments)
  };

  // close this prompt and reset the state
  const handleReject = () => {
    setShowNextPrompt(false)
    setCancelRequestedDocuments([])
  };

  return (
    <div className={classes.requestPopup}>
      <div
        className={`${classes.headingContainer} ${classes.titleAlignment}`}
      >
        <Typography variant="h6">
          <b>{headingText}</b>
        </Typography>
      </div>
      <div
        className={`${classes.headingContainer} ${classes.titleAlignment}`}
      >
        Are you sure you want to cancel the following requested documents?
      </div>

      <div component="ul" className={classes.root}>
        {cancelRequestedDocuments?.map((option) => (
          <li>
            <Chip
              label={getLabelName(option.requested_type)}
              variant="outlined"
              color="primary"
            />
          </li>
        ))}
      </div>

      <div className={classes.requestPopupFooter}>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={() => handleAccept()}
          className={classes.button}
          disabled={isDisabled}
        >
          {acceptText}
        </Button>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={() => handleReject()}
          className={classes.button}
        >
          {rejectText}
        </Button>
      </div>
    </div>
  );
};

const CancelRequestedDocumentationPrompt = ({
  attachmentTypes,
  response,
  acceptText,
  rejectText,
  headingText,
  docsReadyForRequestText,
  requestedDocuments,
  spillAdminId,
  spillId
}) => {
  const classes = useStyles();
  const [requestedDocumentsOption, setRequestedDocumentsOption] = useState([]);
  const [cancelRequestedDocuments, setCancelRequestedDocuments] = useState([]);
  const [showNextPrompt, setShowNextPrompt] = useState(false);

  // removes the duplicate and create an array of object that is required for the cancel request document api
  const removeDuplicates = (arr, prop) => {
    const uniqueArray = arr?.filter((obj, index, self) => {
      obj["spill_id"] = spillId;
      obj["spill_admin_id"] = spillAdminId
      delete obj["id"]
      delete obj["status"]
      return obj[prop] && index === self?.findIndex((o) => o[prop] === obj[prop]);
    });
    return uniqueArray;
  };

  useEffect(() => {
    if (requestedDocuments?.data?.length) {
      const requestedDocumentsOptionWithoutDuplicate = removeDuplicates(
        requestedDocuments?.data,
        "requested_type"
      );
      setRequestedDocumentsOption(requestedDocumentsOptionWithoutDuplicate);
    }
  }, [requestedDocuments, showNextPrompt]);

  const handleDeleteChip = (documentToBeCancelled) => {
    const updatedDocuments = requestedDocumentsOption?.filter(
      (document) => document.requested_type !== documentToBeCancelled.requested_type
    );
    // for UI show this request documents only
    setRequestedDocumentsOption(updatedDocuments);
    // update the cancel requested document state
    setCancelRequestedDocuments((prev) => {
      return [...prev, documentToBeCancelled]
    })
  };

  const getLabelName = (value) => {
    for (const i in attachmentTypes || {}) {
      if (attachmentTypes[i]?.length) {
        for (const j of attachmentTypes[i]) {
          if (j?.value === value) {
            return j?.label;
          }
        }
      }
    }
    return value;
  }

  const handleNextPrompt = () => {
    response(true);
    setShowNextPrompt(true)
  }

  return (
    <div>
      {showNextPrompt ? (<NextPrompt
        response={response}
        acceptText={acceptText}
        rejectText={rejectText}
        headingText={headingText}
        getLabelName={getLabelName}
        cancelRequestedDocuments={cancelRequestedDocuments}
        setShowNextPrompt={setShowNextPrompt}
        setCancelRequestedDocuments={setCancelRequestedDocuments} />) : (<div className={classes.requestPopup}>
        <div
          className={`${classes.headingContainer} ${classes.titleAlignment}`}
        >
          <Typography variant="h6">
            <b>{headingText}</b>
          </Typography>
        </div>
        <div
          className={`${classes.headingContainer} ${classes.titleAlignment}`}
        >
          {docsReadyForRequestText}
        </div>
        {requestedDocumentsOption?.length ? (
          <div component="ul" className={classes.root}>
            {requestedDocumentsOption?.map((option) => (
              <li>
                <Chip
                  label={getLabelName(option.requested_type)}
                  variant="outlined"
                  color="primary"
                  onDelete={() => handleDeleteChip(option)}
                  deleteIcon={<CloseIcon style={{ color: "#3F3F41" }} />}
                />
              </li>
            ))}
          </div>
        ) :
          <div className={classes.noDataText}>
            All document are removed from the requested document list.
            <br />
            Are you sure you want to confirm?
          </div>
        }
        <div className={classes.requestPopupFooter}>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => handleNextPrompt()}
            className={classes.button}
            disabled={cancelRequestedDocuments?.length > 0 ? false : true}
          >
            Next
          </Button>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => response(false)}
            className={classes.button}
          >
            {rejectText}
          </Button>
        </div>
      </div>)}
    </div>
  );
};

export default CancelRequestedDocumentationPrompt;
