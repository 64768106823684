import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PaginationButtons from './PaginationButtons';
import { CustomProgressLoader } from '.';
import { Checkbox } from '@material-ui/core';

const useStylesTablePagination = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStylesTablePagination();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={count && page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!count || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStylesDataTable = makeStyles({
  table: {
    minWidth: 500,
  },
  greyOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#ffffff7a',
    zIndex: 2,
  },
});

export default function DataTable({
  editTable,
  pagination,
  tableHeader,
  tableBody,
  onRowSelect,
  openEditForm,
  pageChange,
  showViewButton,
  handleView,
  type,
  loading,
  currentPage,
  setCurrentPage,
  paginationProps,
}) {
  const classes = useStylesDataTable();
  const [page, setPage] = useState(paginationProps?.page || currentPage || 0);
  const [total, setTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(paginationProps?.limit || 10);
  const [isAppendNoteLast, setIsAppendNoteLast] = useState(false);

  useEffect(() => {
    if (pagination) {
      const pageNumber = parseInt(pagination.page);
      const totalRows = parseInt(pagination.count);

      setTotal(totalRows);
      setPage(pageNumber);
    }
  }, [pagination]);

  useEffect(() => {
    if (
      typeof total === 'number' &&
      isFinite(total) &&
      currentPage > Math.ceil(total / (paginationProps?.limit || 10))
    ) {
      //for the button change
      const maxRows = Math.ceil(total / (paginationProps?.limit || 10));

      setCurrentPage(maxRows - 1);
      pageChange({ newPage: maxRows - 1, type });
      setPage(maxRows - 1);
    }
  }, [currentPage, total]);
  const rows = tableBody;

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    pageChange({ newPage, type });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (row) => {
    onRowSelect(row);
  };
  const renderComponent = (cmp, val, row) => {
    if (cmp?.type) {
      if (cmp?.type === 'checkbox') {
        return (
          <Checkbox
            checked={cmp?.checked(row)}
            onChange={(e) => { cmp?.onChange(e?.target?.checked, row)}}
          />
        );
      }
    }
    return val;
  };

  return loading ? (
    <CustomProgressLoader show={true} />
  ) : (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='custom pagination table'>
        <TableHead style={{ backgroundColor: '#2f7d32c2' }}>
          <TableRow>
            <TableCell />
            <TableCell />
            {tableHeader.map((header) =>
              header.title === 'Client Org' ? (
                <TableCell
                  style={{ color: 'white', width: '12%', fontWeight: 900 }}
                >
                  {header.title}
                </TableCell>
              ) : (
                <TableCell style={{ color: 'white', fontWeight: 900 }}>
                  {header.title}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} style={{ position: 'relative' }}>
              <div
                className={
                  row?.active !== undefined &&
                  !row?.active &&
                  classes.greyOverlay
                }
              />
              <TableCell>
                <div className='flex'>
                  {showViewButton && (
                    <Button
                      color='primary'
                      onClick={() => handleView(row)}
                      style={{ zIndex: 3 }}
                    >
                      Display
                    </Button>
                  )}
                  {editTable && (
                    <Button
                      color='primary'
                      onClick={() => handleEditClick(row)}
                      style={{ zIndex: 3 }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </TableCell>
              {tableHeader.map((column) => {
                const key = column.key;
                let record = row[key];
                
                if (column?.component) {
                  record = renderComponent(column?.component, row[key], row)
                } else if (typeof row[key] === 'boolean') {
                  record = JSON.stringify(row[key])
                }

                return (
                  <TableCell>
                    {record}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={false}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={(labels) => {
                const { from, to, count, page } = labels;
                if (from && to && count) {
                  return (
                    <PaginationButtons
                      to={to}
                      from={from}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      page={page}
                      setIsAppendNoteLast={setIsAppendNoteLast}
                    />
                  );
                }
                return '';
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
