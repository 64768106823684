import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    gap: 10,
  },
  feedback: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  accepted: {
    color: '#2f7d32',
  },
  rejected: {
    color: 'orange',
  },
  dialogContainer: {
    marginLeft: '20vw',
    width: '700px',
  },
});

export const Reject_Reasons = [
  'No personnel available',
  'Do Not have Necessary/Proper Equipment to Perform the Response',
  'Does Not Respond for the Particular Client',
  'Not Licensed to Work in the Particular Location',
];

const RejectDialog = ({ contractor, open, onClose }) => {
  const classes = useStyles();
  const [reject_reason, setRejectReason] = React.useState(null);
  const [rejected_by, setRejectedBy] = React.useState(null);

  const handleCancel = () => {
    onClose(contractor);
  };

  const handleSubmit = () => {
    onClose({ ...contractor, accepted: false, reject_reason, rejected_by });
  };

  return (
    <Dialog open={open} className={classes.dialogContainer}>
      <DialogTitle>Response Accept/Reject</DialogTitle>
      <DialogContent style={{ width: '40vw', maxWidth: '600px' }}>
        <form onSubmit={() => handleSubmit()} className={classes.dialogContent}>
          <InputLabel id='reject_reason_label'>
            Please specify reason
          </InputLabel>
          <Select
            labelId='reject_reason_label'
            id='reject_reason'
            value={reject_reason}
            label={'Please specify reason'}
            onChange={(event) => setRejectReason(event.target.value)}
          >
            {Reject_Reasons.map((reason) => (
              <MenuItem value={reason}>{reason}</MenuItem>
            ))}
          </Select>
          <TextField
            autoFocus
            id='rejected_by'
            label='Rejected By'
            required
            type='text'
            onChange={(event) => setRejectedBy(event.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Reject</Button>
      </DialogActions>
    </Dialog>
  );
};

const ContractorAcceptance = ({ contractors, onChange, rejected }) => {
  const classes = useStyles();

  const [openReject, setOpenReject] = React.useState(false);
  const [contractorRejected, setContractorRejected] = React.useState(null);

  const startRejection = (contractor) => {
    setContractorRejected(contractor);
    setOpenReject(true);
  };

  const updateContractors = (updatedContractor) => {
    const newContractors = contractors.map((contractor) => {
      if (contractor.value === updatedContractor.value) {
        return updatedContractor;
      }
      return contractor;
    });
    onChange(newContractors);
  };

  const reject = (contractor) => {
    setOpenReject(false);
    updateContractors(contractor);
  };

  const accept = (contractor) => {
    updateContractors({
      ...contractor,
      accepted: true,
      reject_reason: null,
      rejected_by: null,
      previouslyInactive: false,
    });
  };

  return (
    <div className={classes.container}>
      {contractors.map(
        (contractor) =>
          (contractor.accepted === null ||
            contractor.previouslyInactive === true) &&
          !contractor.is_inactive && (
            <div className={classes.row}>
              <Typography varaint='h5'>{contractor.label}</Typography>
              <span>
                <Button onClick={() => accept(contractor)}>
                  Accept Response
                </Button>
                <Button onClick={() => startRejection(contractor)}>
                  Reject Response
                </Button>
              </span>
            </div>
          )
      )}
      {[...rejected, ...contractors].length ? (
        <Card>
          <CardContent>
            {contractors
              .filter((x) => x.accepted)
              .map((acceptedContractor) => (
                <div className={classes.feedback}>
                  <Typography>
                    {acceptedContractor.label ||
                      acceptedContractor.contractor_label}
                  </Typography>
                  <Typography>Response Accepted</Typography>
                </div>
              ))}
            {rejected.map((rejectedContractor) => (
              <div className={classes.feedback}>
                <Typography>
                  {rejectedContractor.label ||
                    rejectedContractor.contractor_label}
                </Typography>
                <Typography className={classes.rejected}>
                  Response Rejected
                </Typography>
              </div>
            ))}
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
      <RejectDialog
        open={openReject}
        onClose={reject}
        contractor={contractorRejected}
      />
    </div>
  );
};

export default ContractorAcceptance;
