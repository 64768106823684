/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Checkbox } from '@material-ui/core';
import { CustomProgressLoader } from '.';
import { getFormattedTime } from '../utils';
import PaginationButtons from './PaginationButtons';

const useStylesTablePagination = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStylesDataTable = makeStyles({
  table: {
    minWidth: 500,
  },
  seperator: {
    '& > *': {
      borderBottom: '0.7px solid #616161',
    },
  },
  unset: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableInfoText: {
    fontSize: '13px',
    color: '#377733',
  },
  bolder: {
    fontWeight: '800',
    color: '#616161',
  },
  statusLabel: {
    width: '115px',
    color: '#B90E0E',
    fontWeight: '100',
    fontSize: '15px',
  },
  jobNoLabel: {
    width: '100px',
    color: '#A6B117',
  },
  highlight: {
    backgroundColor: '#FFF9C4',
  },
  rowContainer: {
    display: 'flex',
  },
  tableHeading: {
    color: 'white',
    fontWeight: 900,
  },
  paginationContainer: {
    display: 'flex',
  },
  paginationToolBar: {
    display: 'flex',
    alignItems: 'center',
  },
  paginationSelect: {
    padding: 0,
  },
  loaderWrapper: {
    width: '100%',
  },
});

function TablePaginationActions(props) {
  const classes = useStylesTablePagination();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={count && page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!count || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const SpillTableRow = ({
  row,
  showViewButton,
  handleView,
  tableHeader,
  editTable,
  handleEditClick,
  showCounter,
  showCheckbox,
  selectSpill,
  selected,
  selectAll,
}) => {
  const classes = useStylesDataTable();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(
      selectAll ? !selected?.includes(row.id) : selected?.includes(row.id)
    );
  }, [row, selected, selectAll]);
  return (
    <React.Fragment>
      <TableRow
        className={showCounter && row?.isOverdue ? classes?.highlight : ''}
      >
        {showCheckbox && (
          <TableCell>
            <Checkbox
              onChange={(event) => {
                selectSpill(
                  row.id,
                  selectAll ? !event.target.checked : event.target.checked
                );
                setChecked(event.target.checked);
              }}
              checked={checked}
              value={row.id}
              id={row.id}
            />
          </TableCell>
        )}
        <TableCell>
          <div className='flex'>
            {showViewButton && (
              <Button color='primary' onClick={() => handleView(row)}>
                <a
                  className='display-spill-link'
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  href={`${window?.location?.origin}/dashboard/spills/view/${row?.job_no}`}
                >
                  Display
                </a>
              </Button>
            )}
          </div>
        </TableCell>
        <TableCell className={`${classes.bolder} ${classes.jobNoLabel}`}>
          {row.job_no}
        </TableCell>
        <TableCell className={classes.bolder}>
          {row.client_organization?.name}
        </TableCell>
        <TableCell className={classes.bolder}>
          {getFormattedTime(row.opened_on)}
        </TableCell>
        <TableCell className={`${classes.bolder} ${classes.statusLabel}`}>
          {row.status}
        </TableCell>
        <TableCell className={classes.bolder}>
          {getFormattedTime(
            row?.closed_on ? row?.closed_on : row?.dataValues?.closed_on
          )}
        </TableCell>
        <TableCell className={classes.bolder}>{row.claim_no}</TableCell>
        {showCounter && (
          <TableCell className={classes.bolder}>{row.daysCounter}</TableCell>
        )}
      </TableRow>
      <TableRow
        className={
          showCounter && row.isOverdue
            ? `${classes.highlight} ${classes.seperator}`
            : classes.seperator
        }
      >
        {showCheckbox && <TableCell />}
        <TableCell>
          <div>
            {editTable && !row.isInactive && (
              <Button color='primary' onClick={() => handleEditClick(row)}>
                <a
                  className='edit-spill-link'
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  href={`${window?.location?.origin}/dashboard/spills/edit/${row?.job_no}`}
                >
                  Edit
                </a>
              </Button>
            )}
          </div>
        </TableCell>
        <TableCell colSpan={7}>
          <div>
            {row.address && `${row.address}, `}
            {row.city && `${row.city}, `}
            {row.state && `${row.state}, `}
            {row.country && `${row.country}`}
          </div>
          <div>
            <span className={classes.tableInfoText}>{row.conditions}</span>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function DataTable({
  editTable,
  tableHeader,
  tableBody,
  onRowSelect,
  openEditForm,
  pageChange,
  showViewButton,
  handleView,
  total,
  showCounter,
  canBatchUpdate,
  selected,
  selectSpill,
  loading,
  selectAll,
  setCurrentPage,
  currentPage,
  paginationProps,
  setPageRows,
}) {
  const classes = useStylesDataTable();
  const [page, setPage] = useState(paginationProps?.page || currentPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationProps?.limit || 10);
  const [isAppendNoteLast, setIsAppendNoteLast] = useState(false);
  const [LocalLoaderFlag, setLocalLoaderFlag] = useState(false);

  const rows = tableBody;

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    pageChange(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageRows(parseInt(event.target.value, 10));
    pageChange(0, parseInt(event.target.value, 10));
  };

  const handleEditClick = (row) => {
    onRowSelect(row);
  };

  useEffect(() => {
    setPageRows(rowsPerPage);
  }, [rowsPerPage]);

  // useEffect(() => {
  //   if (currentPage >= 0 && total > 0) {
  //     const maxPage = Math.ceil(total / rowsPerPage);
  //     const scaledCurrentPage =
  //       currentPage + 1 > maxPage ? maxPage - 1 : currentPage;
  //     setPage(scaledCurrentPage);
  //     pageChange(scaledCurrentPage);
  //     // handleChangePage(currentPage);
  //     setLocalLoaderFlag(false);
  //   } else {
  //     setLocalLoaderFlag(true);
  //   }
  // }, [currentPage, total]);

  return loading || LocalLoaderFlag ? (
    <CustomProgressLoader show={true} />
  ) : (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='custom pagination table'>
        <TableHead style={{ backgroundColor: '#2f7d32c2' }}>
          <TableRow>
            {canBatchUpdate && (
              <TableCell className={classes.tableHeading}>Select</TableCell>
            )}
            <TableCell></TableCell>
            {tableHeader.map((header) =>
              header.title === 'Client Org' ? (
                <TableCell className={classes.tableHeading}>
                  {header.title}
                </TableCell>
              ) : (
                <TableCell className={classes.tableHeading}>
                  {header.title}
                </TableCell>
              )
            )}
            {showCounter && (
              <TableCell className={classes.tableHeading}>
                Days Counter
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <SpillTableRow
              key={index}
              row={row}
              showViewButton={showViewButton}
              handleView={handleView}
              editTable={editTable}
              tableHeader={tableHeader}
              handleEditClick={handleEditClick}
              showCounter={showCounter}
              showCheckbox={canBatchUpdate}
              selectSpill={selectSpill}
              selected={selected}
              selectAll={selectAll}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{
                toolbar: classes.paginationToolBar,
                select: classes.paginationSelect,
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={(labels) => {
                const { from, to, count, page } = labels;
                if (from && to && count) {
                  return (
                    <PaginationButtons
                      to={to}
                      from={from}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      page={page}
                      setIsAppendNoteLast={setIsAppendNoteLast}
                    />
                  );
                }
                return '';
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

DataTable.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
