import React, { useState } from 'react';

import { green } from '@material-ui/core/colors';
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'inherit',
  },
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
  header: {
    color: 'green',
    textAlign: 'left',
  },
  nameFieldWrapper: {
    marginBottom: '1rem',
  },
  accordian: {
    marginBottom: '7px',
    borderRadius: '10px',
  },
  classIRailRoadAccordion: {
    backgroundColor: '#dedcdc',
    borderRadius: '10px',
  },
  classIRailRoadAccordionHeader: {
    display: 'flex',
    width: '100%',
    padding: '0px 15px 0px 0px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  classIRailRoadAccordionLocation: {
    display: 'flex',
    paddingRight: '5px',
    alignItems: 'center',
    gap: 5,
  },
  addressIcon: {
    marginRight: '5px',
    color: 'rgb(199 169 13)',
  },
  classIRailRoadDetailsSection: {
    display: 'block',
  },
}));

const ClassIRailRoadsAccordion = ({
  certificationClasses,
  setCertificationClasses,
  classIERailCertifications,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  const handleExpandChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);
  };

  const certificationCheckboxHandler = (certificateId) => {
    let certificationsList = [...certificationClasses];
    const idInList = certificationsList.includes(certificateId);
    if (!idInList) {
      certificationsList.push(certificateId);
    }
    if (idInList) {
      const indexOfId = certificationsList.indexOf(certificateId);
      if (indexOfId > -1) {
        certificationsList.splice(indexOfId, 1);
      }
    }
    setCertificationClasses(certificationsList);
  };

  return (
    <div className={classes.wrapper}>
      <ExpansionPanel
        expanded={expanded === 0}
        className={classes.accordian}
        onChange={handleExpandChange(0)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.classIRailRoadAccordion}
        >
          <div className={classes.classIRailRoadAccordionHeader}>
            <div className={classes.classIRailRoadAccordionLocation}>
              <LocalMoviesIcon
                className={classes.addressIcon}
                fontSize='small'
              />
              <Typography className={classes.heading}>
                Class I Railroads
              </Typography>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.classIRailRoadDetailsSection}>
          <Card
            className={classes.headerCard}
            style={{ margin: '15px 0px', width: '100%' }}
          >
            <CardContent
              style={{
                padding: '10px',
                backgroundColor: '#e6e6e6d6',
                fontWeight: 600,
                color: '#6aa16c',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '0px 5px',
                }}
              >
                <div className={classes.leftAlign}>Certification</div>
                <div className={classes.rightAlign}>Status</div>
              </div>
            </CardContent>
          </Card>
          <List component='nav' aria-label='mailbox folders'>
            {classIERailCertifications &&
              classIERailCertifications?.map((certificate, index) => {
                return (
                  <>
                    <ListItem>
                      <ListItemText primary={certificate?.certificate_name} />
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              name='check'
                              style={{ padding: '0' }}
                              defaultChecked={certificationClasses?.includes(
                                certificate.id
                              )}
                              onChange={() =>
                                certificationCheckboxHandler(certificate.id)
                              }
                            />
                          }
                        />
                      </div>
                    </ListItem>
                    {classIERailCertifications?.length - 1 === index ? null : (
                      <Divider style={{ backgroundColor: '#437d334d' }} />
                    )}
                  </>
                );
              })}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default ClassIRailRoadsAccordion;
