import React from 'react';
import Moment from 'moment';
import { AppBar, Tabs, Tab, Grid, Typography, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TabPanel from './TabPanel';
import AttachmentGrid from '../../../../../Components/AttachmentGrid';
import { getContractorLabel } from '../../../../../utils/contractorLabel';
import { isPESUser } from '../../../../../utils';

const useStyles = makeStyles(() => ({
  innerAppBar: {
    margin: '5px 0 1rem 0',
  },
  innerTabPanel: {
    padding: '1rem 3rem',
  },

  leftAlign: {
    textAlign: 'left',
  },
  detailHeading: {
    color: '#295115',
    fontSize: '15px',
    fontWeight: 800,
  },

  dateText: {
    fontSize: '0.875rem',
  },
  downloadBanner: {
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#f5f5f5',
    color: '#295115',
    fontSize: '13px',
    lineHeight: '1.75',
  },
  downloadBannerLink: {
    color: '#295115',
    fontSize: '13px',
    fontWeight: 800,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  rightAlign: {
    textAlign: 'right',
  },
  inlineBlock: {
    display: 'inline-block',
  },

}));

const AdminViewTab = ({
  spillData,
  showAdmin,
  currentUser,
  downloadLoader,
  contractorOptions,
  attachmentHistory,
  downloadAdminFiles,
  viewAdminAttachmentHistory,
}) => {
  const classes = useStyles();
  const [innerTabValue, setInnerTabValue] = React.useState(0);

  React.useEffect(() => {
    if (spillData?.admin) {
      let tempAdmin = spillData.admin;
      for (let i = 0, length = tempAdmin?.length; i < length; i++) {
        if (
          !currentUser?.data?.role?.role.includes('Contractor') ||
          (!tempAdmin[i].isRemoved && showAdmin(tempAdmin[i], currentUser.data))
        ) {
          setInnerTabValue(i);
          return;
        }
      }
    }
  }, [spillData]);

  function a11yProps(index) {
    return {
      id: `edit-tab-${index}`,
      'aria-controls': `edit-tabpanel-${index}`,
    };
  }
 

  return (
    <div>
      <AppBar position='static' color='default' className={classes.innerAppBar}>
        <Tabs
          value={innerTabValue}
          onChange={(e, newVal) => setInnerTabValue(newVal)}
          aria-label='edit admin tabs'
          variant='scrollable'
          className='customTab'
        >
          {currentUser.data &&
            spillData?.admin &&
            spillData?.admin.map((data, index) => {
              if (
                !currentUser?.data?.role?.role.includes('Contractor') ||
                showAdmin(data, currentUser.data)
              ) {
                return (
                  <Tab
                    key={index}
                    label={`${getContractorLabel(data, contractorOptions)}`}
                    {...a11yProps(index)}
                    value={index}
                  ></Tab>
                );
              }
              return null;
            })}
        </Tabs>
      </AppBar>

      {spillData?.admin &&
        spillData?.admin.map((data, index) => {
          if (
            !currentUser?.data?.role?.role.includes('Contractor') ||
            showAdmin(data, currentUser.data)
          ) {
            return (
              <TabPanel value={innerTabValue} index={index}>
                <div className={classes.innerTabPanel}>
                  <div className={classes.leftAlign}>
                    <Typography
                      variant={'subtitle1'}
                      className={classes.detailHeading}
                    >
                      Info:
                    </Typography>
                    <Typography variant={'subtitle2'}>
                      <div>{data.info}</div>
                    </Typography>

                    <Typography
                      variant={'subtitle1'}
                      className={classes.detailHeading}
                    >
                      Contractor Invoice $:
                    </Typography>
                    <Typography variant={'subtitle2'}>
                      {data.contractor_invoice}
                    </Typography>

                    <Typography
                      variant={'subtitle1'}
                      className={classes.detailHeading}
                    >
                      Inv No:
                    </Typography>
                    <Typography variant={'subtitle2'}>{data.inv_no}</Typography>

                    {!currentUser?.data?.role?.role.includes('Contractor') && (
                      <>
                        <Typography
                          variant={'subtitle1'}
                          className={classes.detailHeading}
                        >
                          Final Contractor Invoice:
                        </Typography>
                        <Typography variant={'subtitle2'}>
                          {data.final_contractor_invoice}
                        </Typography>
                        <Typography
                          variant={'subtitle1'}
                          className={classes.detailHeading}
                        >
                          Savings:
                        </Typography>
                        <Typography variant={'subtitle2'}>
                          {data.savings}
                        </Typography>
                        <Typography
                          variant={'subtitle1'}
                          className={classes.detailHeading}
                        >
                          PES Inv No:
                        </Typography>
                        <Typography variant={'subtitle2'}>
                          {data.pes_inv_no}
                        </Typography>
                        <Typography
                          variant={'subtitle1'}
                          className={classes.detailHeading}
                        >
                          PES Inv Amount:
                        </Typography>
                        <Typography variant={'subtitle2'}>
                          {data.pes_inv_amount}
                        </Typography>
                        <Typography
                          variant={'subtitle1'}
                          className={classes.detailHeading}
                        >
                          Response Time:
                        </Typography>
                        <Typography variant={'subtitle2'}>
                          {data.response_time}
                        </Typography>
                      </>
                    )}
                    {!currentUser?.data?.role?.role.includes('Contractor') && (
                      <>
                        <Typography
                          variant='subtitle1'
                          className={classes.detailHeading}
                        >
                          Dates:{' '}
                        </Typography>
                        <div className={classes.dateText}>
                          <span>PES Paid: </span>
                          {data.pes_paid
                            ? Moment(new Date(data.pes_paid))?.format(
                                'MM-DD-YYYY'
                              )
                            : data.pes_paid}
                        </div>
                        <div className={classes.dateText}>
                          <span>Contractor Paid: </span>
                          {data.contractor_paid
                            ? Moment(new Date(data.contractor_paid))?.format(
                                'MM-DD-YYYY'
                              )
                            : data.contractor_paid}
                        </div>
                        <div className={classes.dateText}>
                          <span>Trans to CT: </span>
                          {data.trans_to_ct
                            ? Moment(new Date(data.trans_to_ct))?.format(
                                'MM-DD-YYYY'
                              )
                            : data.trans_to_ct}
                        </div>
                        <div className={classes.dateText}>
                          <span>Pay By: </span>
                          {data.pay_by
                            ? Moment(new Date(data.pay_by))?.format('MM-DD-YYYY')
                            : data.pay_by}
                        </div>
                        {data.pix && (
                          <div className={classes.dateText}>
                            <span>Pix: </span>
                            {Moment(new Date(data.pix))?.format('MM-DD-YYYY')}
                          </div>
                        )}

                        {data.spill_summary && (
                          <div className={classes.dateText}>
                            <span>Spill Summary: </span>
                            {Moment(new Date(data.spill_summary))?.format(
                              'MM-DD-YYYY'
                            )}
                          </div>
                        )}

                        {data.contractor_inv && (
                          <div className={classes.dateText}>
                            <span>Contractor Inv: </span>
                            {Moment(new Date(data.contractor_inv))?.format(
                              'MM-DD-YYYY'
                            )}
                          </div>
                        )}

                        {data.waste_doc && (
                          <div className={classes.dateText}>
                            <span>Waste Doc: </span>
                            {Moment(new Date(data.waste_doc))?.format(
                              'MM-DD-YYYY'
                            )}
                          </div>
                        )}
                      </>
                    )}
                    <Typography
                      variant={'subtitle1'}
                      className={classes.detailHeading}
                    >
                      Status:
                    </Typography>
                    <Typography variant={'subtitle2'}>
                      {!data.is_complete ? 'Open for Submission' : 'Complete'}
                    </Typography>

                    <Typography
                      variant={'subtitle1'}
                      className={classes.detailHeading}
                    >
                      Attachments:
                    </Typography>
                    <div className={classes.rightAlign}>
                      <div className={classes.inlineBlock}>
                        <Card className={classes.downloadBanner}>
                          <div>
                            Attachments for this admin:&nbsp;
                            {data.spill_attachments.length}
                          </div>
                          {downloadLoader?.loading ? (
                            <div>Downloading files....</div>
                          ) : (
                            data.spill_attachments.length > 0 && (
                              <div
                                className={classes.downloadBannerLink}
                                onClick={() =>
                                  downloadAdminFiles({
                                    id: data.id,
                                    name: `${
                                      spillData?.spill?.job_no
                                    }${getContractorLabel(
                                      data,
                                      contractorOptions
                                    )}.zip`.replace(' ', ''),
                                  })
                                }
                              >
                                Download all as &nbsp;
                                {`${
                                  spillData.spill?.job_no
                                }${getContractorLabel(
                                  data,
                                  contractorOptions
                                )}.zip`.replace(' ', '')}
                              </div>
                            )
                          )}
                        </Card>
                      </div>
                    </div>
                    <Grid item xs={12}>
                      <AttachmentGrid
                        files={data.spill_attachments}
                        adminIndex={index}
                        isInactive={data?.is_removed}
                        handleCancel={() => {
                          return;
                        }}
                        isUserAdmin={isPESUser(currentUser?.data?.role?.role)}
                        updateRejectedAttachment={() => {}}
                        updatedFilesState={{}}
                        attachmentHistory={attachmentHistory}
                        viewAdminAttachmentHistory={viewAdminAttachmentHistory}
                      ></AttachmentGrid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
            );
          }
          return null;
        })}
    </div>
  );
};

export default AdminViewTab;
