import moment from 'moment';
import { forceToCST } from '.';

const DATE_FORMAT = 'D MMM';
const DATE_YEAR_FORMAT = 'D MMM YY';
const TIME_FORMAT = 'HH:mm';

export const getStandardDateTime = (date = new Date(), options = {}) => {
  const { part = 'datetime', amount = 0, format, unit = 'days' } = options;

  let result;

  if (amount > 0) {
    result = moment(date).add(amount, unit);
  } else if (amount < 0) {
    result = moment(date).subtract(-1 * amount, unit);
  } else {
    result = moment(date);
  }

  if (part === 'date') {
    return String(
      result?.format(
        result.year() === new Date().getFullYear()
          ? DATE_FORMAT
          : DATE_YEAR_FORMAT
      )
    );
  }

  if (part === 'time') {
    return String(result?.format(TIME_FORMAT));
  }

  return String(result?.format(format || `${DATE_YEAR_FORMAT} ${TIME_FORMAT}`));
  // return String(
  //   result.format(
  //     `${
  //       result.year() === new Date().getFullYear() ? DATE_FORMAT : DATE_YEAR_FORMAT
  //     } ${TIME_FORMAT}`
  //   )
  // );
};

export const getElapsedTime = (date) => {
  const elapsedDuration = moment.duration(moment().diff(date));
  const seconds = elapsedDuration.asSeconds();

  if (seconds > 59) {
    const minutes = elapsedDuration.asMinutes();

    if (minutes > 59) {
      const hours = elapsedDuration.asHours();

      if (hours > 23) {
        const days = elapsedDuration.asDays();

        if (days > 30) {
          const months = elapsedDuration.asMonths();

          if (months > 11) {
            const years = elapsedDuration.asYears();

            return `${Math.round(years)} y ago`;
          }

          return `${Math.round(months)} mo ago`;
        }

        return `${Math.round(days)} d ago`;
      }

      return `${Math.round(hours)} h ago`;
    }

    return `${Math.round(minutes)} m ago`;
  }

  return `${Math.round(seconds)} s ago`;
};

export const convert = (str) => {
  const date = new Date(str);
  const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return (
    [date.getFullYear(), mnth, day].join('-') +
    ' ' +
    (hours % 12) +
    ':' +
    minutes +
    ' ' +
    (hours >= 12 ? 'pm' : 'am')
  );
};

export const formatTimeInNote = (desc) => {
  if (desc?.includes('was removed from assigned contractors')) {
    let tempDate = new Date(desc.slice(desc.indexOf(' at ')));

    tempDate = tempDate.toLocaleString();
    return desc?.replace(desc.slice(desc.indexOf(' at ')), ' ' + tempDate);
  } else return desc;
};

export const getCurrentTime = () => JSON.stringify(forceToCST(new Date()));

export const timeConvertMinsToHHMM = (n) => {
  const minutes = n % 60;
  const hours = (n - minutes) / 60;
  const stringTime = hours + ':' + Math.abs(minutes);
  return stringTime;
};


export const timeConvertMinsToHHMMForETA = (n) => {
  const minutes = n % 60;
  const hours = (n - minutes) / 60;
  const stringTime = ` ${minutes< 0 ? '-': ''}  ${hours}:${Math.abs(minutes)}`;
  return stringTime;
};

export const timeConvertHHMMtoMins = (time) => {
  let hours = 0;
  let mins = 0;
  let convertedHours = 0;
  let totalTime = 0;
  if (time !== null || time !== undefined) {
    const splittedTime = time?.split(':');
    if (splittedTime) {
      hours = +splittedTime[0];
      mins = +splittedTime[1];
      convertedHours = hours * 60;
      totalTime = convertedHours + mins;
    }
    return totalTime;
  } else {
    return 0;
  }
};
