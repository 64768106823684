import React, { useState, useMemo } from "react";
import { Typography, Button, makeStyles, Chip } from "@material-ui/core";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    marginBottom: 10,
  },
  questionContainer: {
    marginBottom: 10,
  },
  button: {
    transition: "all .4s",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: "grey",
      color: "black",
      opacity: "0.5",
    },
    padding: "5px 30px",
  },
  titleAllignment: {
    "text-align": "left",
  },
  requestPopup: {
    display: "flex",
    flexDirection: "column",
    width: "590px",
    height: "350px",
  },
  requestPopupFooter: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "flex-end",
    gap: 10,
  },
  selectSize: {
    maxHeight: "200px",
  },
  detailsParagraph: {
    fontSize: "12px",
    color: "gray",
  },
  noMarginParagraph: {
    margin: "0px",
    fontSize: "12px",
  },
}));

const RequestDocumentationPrompt = ({
  response,
  question,
  acceptText,
  rejectText,
  cancelText,
  headingText,
  attachmentTypes,
  modalDefaultText,
  generateRequestText,
  noDocumentsSelectedText,
  docsReadyForRequestText,
}) => {
  const classes = useStyles();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [confirmationCheck, setConfirmationCheck] = useState(false);

  const handleChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
  };

  const getSortedAttachmentTypes = useMemo(() => {
    const flatenArray = [];

    for (const key in attachmentTypes) {
      flatenArray.push(attachmentTypes[key]);
    }

    return flatenArray.flat();
  }, [attachmentTypes]);

  const hasDocumentsSelected = selectedOptions?.length > 0;

  return (
    <div className={classes.requestPopup}>
      <div
        item
        xs={12}
        className={`${classes.headingContainer} ${classes.titleAllignment}`}
      >
        <Typography variant={"h6"}>
          <b>{headingText}</b>
        </Typography>
      </div>

      <div>
        {!confirmationCheck && (
          <Select
            styles={{
              menuList: (provided) => ({ ...provided, maxHeight: 200 }),
            }}
            isMulti
            name="requested_documents"
            value={selectedOptions}
            classNamePrefix="select"
            onChange={handleChange}
            closeMenuOnSelect={false}
            options={getSortedAttachmentTypes}
          />
        )}
        {confirmationCheck && selectedOptions?.length ? (
          <>
            <Typography variant={"subtitle1"}>
              <b>{docsReadyForRequestText}</b>
            </Typography>
            <br />
          </>
        ) : null}
        {confirmationCheck && selectedOptions?.length
          ? selectedOptions?.map((option) => {
              return (
                <>
                  <Chip
                    label={option?.label}
                    color="primary"
                    variant="outlined"
                  />{" "}
                </>
              );
            })
          : null}
      </div>

      {!confirmationCheck && (
        <p className={classes.detailsParagraph}>{modalDefaultText}</p>
      )}

      {confirmationCheck && (
        <>
          <br />
          <p className={classes.noMarginParagraph}>{question}</p>
        </>
      )}

      {!hasDocumentsSelected && (
        <p className={classes.noMarginParagraph}>{noDocumentsSelectedText}</p>
      )}

      <div className={classes.requestPopupFooter}>
        {confirmationCheck ? (
          <>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => response(false, selectedOptions)}
              className={classes.button}
            >
              {acceptText}
            </Button>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => setConfirmationCheck(false)}
              className={classes.button}
            >
              {rejectText}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => response(false)}
              className={classes.button}
            >
              {cancelText}
            </Button>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => setConfirmationCheck(true)}
              className={classes.button}
              disabled={!hasDocumentsSelected}
            >
              {generateRequestText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestDocumentationPrompt;
