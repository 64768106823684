import { makeStyles, CircularProgress } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: "45%",
  },
}));
const ModalLoader = (props) => {
  const classes = useStyles();
  return props.show ? <CircularProgress className={classes.root} /> : <></>;
};

ModalLoader.prototype = {
  show: PropTypes.bool.isRequired,
};
export default ModalLoader;
