import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarIcon from "@material-ui/icons/Star";

import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";

import Styles from "./HomepageStyle";

export default function Main(props) {
  const classes = Styles();
  const { title, subtitle } = props;

  return (
    <React.Fragment>
      <Grid item xs={6} md={8}>
        <Typography variant="h6" gutterBottom align="left">
          {title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="left">
          {subtitle}
        </Typography>
        <Divider className={classes.homePageDivider} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" paragraph align="left">
              Premium Environmental Services can help you manage your spills.
              Our personnel provide extensive experience in the environmental
              field ensuring your risk management projects are completed in a
              cost effective and timely manner. Our spill management programs
              ensure contractor oversight and documented cost savings on
              projects. On-going communication with our clients provides updates
              on projects, and information projecting true costs and closure
              timeframes.
            </Typography>
            <Typography variant="subtitle2" paragraph align="left">
              Premium Environmental Services staff includes:
              <List component="ul">
                <ListItem>
                  {" "}
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Professional geologists
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Certified Hazardous Materials Manager (CHMM)
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Chemist
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Environmental Scientists
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Project Managers.
                </ListItem>
              </List>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" paragraph align="left">
              At PES, we provide effective solutions to your risk management
              needs.
              <List component="ul">
                <ListItem>
                  Spill response - including spill management for
                  Transportation, Insurance and Industrial industries.
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Environmental consulting
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Waste disposal
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Waste management
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Facility compliance
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Risk management
                </ListItem>
                <ListItem>
                  {" "}
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  Safety
                </ListItem>
              </List>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

Main.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
