import { all, put, takeLatest } from 'redux-saga/effects';

import {
  getMostLargestSpillsSuccess,
  getMostLargestSpillsFail,
  getBusinessHoursSpillsSuccess,
  getBusinessHoursSpillsFail,
  getCurrentDailyTotalSuccess,
  getCurrentDailyTotalFail,
  getCurrentYearSpillsSuccess,
  getCurrentYearSpillsFail,
  getPreviousYearSpillsSuccess,
  getPreviousYearSpillsFail,
  getCurrentVsPreviousYearSpillsSuccess,
  getCurrentVsPreviousYearSpillsFail,
  getDaysToPaySpillsSuccess,
  getDaysToPaySpillsFail,
  getSpillStatusCountSuccess,
  getSpillStatusCountFail,
  getAllMonthSpillsCountInYearSuccess,
  getAllMonthSpillsCountInYearFail,
  getAllMonthSpillsCountInCurrentVSPreviousYearSuccess,
  getAllMonthSpillsCountInCurrentVSPreviousYearFail,
  getSpillsOnEachWorkdaySuccess,
  getSpillsOnEachWorkdayFail,
  getSpillsOnEachWeekSuccess,
  getSpillsOnEachWeekFail,
  getCurrentMonthSpillsProjectionSuccess,
  getCurrentMonthSpillsProjectionFail,
  getAveragePerDayPerWeekFail,
  getAveragePerDayPerWeekSuccess,
  getFilteredSpillsStatusCountSuccess,
  getFilteredSpillsStatusCountFail,
  getCurrentVsPreviousMonthSpillAtMonthFail,
  getCurrentVsPreviousMonthSpillAtMonthSuccess,
  getNonHazardousIncidentsSuccess,
  getNonHazardousIncidentsFail,
  getHazardousIncidentsSuccess,
  getHazardousIncidentsFail,
  getEmergencyIncidentsSuccess,
  getEmergencyIncidentsFail,
  getNonEmergencyIncidentsSuccess,
  getNonEmergencyIncidentsFail,
  getIncidentsAtLocationTypesSuccess,
  getIncidentsAtLocationTypesFail,
  getEvacuationIncidentsNumberSuccess,
  getEvacuationIncidentsNumberFail,
  getDisposalIncidentsNumberSuccess,
  getDisposalIncidentsNumberFail,
  getAvgTimeForExcavationSuccess,
  getAvgTimeForExcavationFail,
  get5800FormsDetailsSuccess,
  get5800FormsDetailsFail,
  getFetchSpillsStatusesCountRoleBasedSuccess,
  getFetchSpillsStatusesCountRoleBasedFail,
  getTurnedUsDownForContractorSuccess,
  getTurnedUsDownForContractorFail,
  getHazIncidentsClientSuccess,
  getHazIncidentsClientFail,
  getIncidentsReportedClientSuccess,
  getIncidentsReportedClientFail,
  getVehicleIncidentsSuccess,
  getVehicleIncidentsFail,
  get5800CompletedClientSuccess,
  get5800CompletedClientFail,
  getAverageInvoicesClientSuccess,
  getAverageInvoicesClientFail,
  getSavingsClientSuccess,
  getSavingsClientFail,
  getIncidentsCompletionTypeStatusesSuccess,
  getIncidentsCompletionTypeStatusesFail,
  getAverageEtaSuccess,
  getAverageEtaFail,
  getAverageIncidentSameContractorSuccess,
  getAverageIncidentSameContractorFail,
  getAllContractorTurnUsDownSuccess,
  getAllContractorTurnUsDownFail,
  getResponsePercentageContractorsSuccess,
  getResponsePercentageContractorsFail,
  getParticularContractorTurnedUsDownSuccess,
  getParticularContractorTurnedUsDownFail,
  getCurrentlyOpenSpillsSuccess,
  getCurrentlyOpenSpillsFail,
  getCurrentlyOpenedSpillsInCurrentYearSuccess,
  getCurrentlyOpenedSpillsInCurrentYearFail,
  getCurrentlyOpenedSpillsInPreviousYearSuccess,
  getCurrentlyOpenedSpillsInPreviousYearFail,
  getSpillsClosedYTDSuccess,
  getSpillsClosedYTDFail,
  getCurrentlyClosedSpillsCurrentYearSuccess,
  getCurrentlyClosedSpillsCurrentYearFail,
  getAvgDaysToCloseSpillSuccess,
  getAvgDaysToCloseSpillFail,
  getWeeklySpillCountForAMonthSuccess,
  getWeeklySpillCountForAMonthFail,
  getAfterHoursSpillsSuccess,
  getAfterHoursSpillsFail,
  getWorkInProgressSuccess,
  getWorkInProgressFail,
  getCSIncidentsSince8Success,
  getCSIncidentsSince8Fail,
  getCSDailyAfterHoursSuccess,
  getCSDailyAfterHoursFail,
  getCSDailyBusinessHoursSuccess,
  getCSDailyBusinessHoursFail,
  getCurrentProjectCountSuccess,
  getCurrentProjectCountFail,
  getPMListSuccess,
  getPMListFail,
  getTrackingFileIncidentsSuccess,
  getTrackingFileIncidentsFail,
  getSpillsBroughtIntoOfficeSuccess,
  getSpillsBroughtIntoOfficeFail,
  getDigsScheduledTodaySuccess,
  getDigsScheduledTodayFail,
  getNonTrackingWithWorkInProgressSuccess,
  getNonTrackingWithWorkInProgressFail,
  getNonTrackingFilesWIPSuccess,
  getNonTrackingFilesWIPFail,
  getDailyWeeklyGisMapSuccess,
  getDailyWeeklyGisMapFail,
  getPMOpenIncidentsSuccess,
  getPMOpenIncidentsFail,
  getCSCurrentWeekSpillsSuccess,
  getCSCurrentWeekSpillsFail,
  getCSCurrentWeekBusinessHoursSuccess,
  getCSCurrentWeekBusinessHoursFail,
  getCSCurrentWeekAfterHoursSuccess,
  getCSCurrentWeekAfterHoursFail,
  getCSCurrentWeekBroughtIntoOfficeSuccess,
  getCSCurrentWeekBroughtIntoOfficeFail,
  getCSCurrentWeekExcavationSuccess,
  getCSCurrentWeekExcavationFail,
  getCSCurrentWeekIncidentsTakenHomeSuccess,
  getCSCurrentWeekIncidentsTakenHomeFail,
  getCSIncidentsTakenHomeSuccess,
  getCSIncidentsTakenHomeFail,
} from '../actionCreators/Analytics';
import { doGet, doPost, doGetAll } from '../utils/request';
import { REQUEST_TYPE } from '../utils/index';


function* getMostLargestSpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/most-largest-spills?year=${obj?.year || ""}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getMostLargestSpillsSuccess(response?.data));
  } catch (err) {
    yield put(getMostLargestSpillsFail(err?.response?.data?.message || err?.message));
  }
}

function* getCSCurrentWeekIncidentsTakenHome({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-week-incidents-taken-home?startDate=${
        obj?.startDate ? `"${obj.startDate}"` : '""'
      }&endDate=${obj?.endDate ? `"${obj.endDate}"` : '""'}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSCurrentWeekIncidentsTakenHomeSuccess(response?.data));
  } catch (err) {
    yield put(
      getCSCurrentWeekIncidentsTakenHomeFail(
        err.response.data.message || err.message
      )
    );
  }
}

function* getCSCurrentWeekBroughtIntoOffice({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-week-brought-into-office-spills?startDate=${
        obj?.startDate ? `"${obj.startDate}"` : '""'
      }&endDate=${obj?.endDate ? `"${obj.endDate}"` : '""'}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSCurrentWeekBroughtIntoOfficeSuccess(response?.data));
  } catch (err) {
    yield put(
      getCSCurrentWeekBroughtIntoOfficeFail(
        err.response.data.message || err.message
      )
    );
  }
}

function* getCSCurrentWeekExcavation({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-week-excavations?startDate=${
        obj?.startDate ? `"${obj.startDate}"` : '""'
      }&endDate=${obj?.endDate ? `"${obj.endDate}"` : '""'}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSCurrentWeekExcavationSuccess(response?.data));
  } catch (err) {
    yield put(
      getCSCurrentWeekExcavationFail(err.response.data.message || err.message)
    );
  }
}

function* getCSCurrentWeekSpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-week-spills?startDate=${
        obj?.startDate ? `"${obj.startDate}"` : '""'
      }&endDate=${obj?.endDate ? `"${obj.endDate}"` : '""'}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSCurrentWeekSpillsSuccess(response?.data));
  } catch (err) {
    yield put(
      getCSCurrentWeekSpillsFail(err.response.data.message || err.message)
    );
  }
}

function* getCSCurrentWeekBusinessHours({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-week-spills-business-hours?startDate=${
        obj?.startDate ? `"${obj.startDate}"` : '""'
      }&endDate=${obj?.endDate ? `"${obj.endDate}"` : '""'}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSCurrentWeekBusinessHoursSuccess(response?.data));
  } catch (err) {
    yield put(
      getCSCurrentWeekBusinessHoursFail(
        err.response.data.message || err.message
      )
    );
  }
}

function* getCSCurrentWeekAfterHours({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-week-spills-after-hours?startDate=${
        obj?.startDate ? `"${obj.startDate}"` : '""'
      }&endDate=${obj?.endDate ? `"${obj.endDate}"` : '""'}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSCurrentWeekAfterHoursSuccess(response?.data));
  } catch (err) {
    yield put(
      getCSCurrentWeekAfterHoursFail(err.response.data.message || err.message)
    );
  }
}

function* getNonTrackingFilesWIP({}) {
  try {
    const response = yield doGet(
      `/spill/non-tracking-work-in-progress-incidents`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getNonTrackingFilesWIPSuccess(response.data));
  } catch (err) {
    yield put(
      getNonTrackingFilesWIPFail(err.response.data.message || err.message)
    );
  }
}

function* getDailyWeeklyGisMap({ obj }) {
  try {
    const response = yield doGet(
      `/spill/gis-map-completed-spills?startDate="${obj.startDate}"&endDate=${
        obj.endDate ? `"${obj.endDate}"` : `""`
      }`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getDailyWeeklyGisMapSuccess(response.data));
  } catch (err) {
    yield put(
      getDailyWeeklyGisMapFail(err.response.data.message || err.message)
    );
  }
}

function* getBusinessHoursSpills({}) {
  try {
    const response = yield doGet(
      `/spill/business-hours-spills`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getBusinessHoursSpillsSuccess(response.data));
  } catch (err) {
    yield put(
      getBusinessHoursSpillsFail(err.response.data.message || err.message)
    );
    yield put(getBusinessHoursSpillsFail());
  }
}

function* getCSIncidentsTakenHome({}) {
  try {
    const response = yield doGet(
      `/spill/spills-taken-home`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCSIncidentsTakenHomeSuccess(response.data));
  } catch (err) {
    yield put(
      getCSIncidentsTakenHomeFail(err.response.data.message || err.message)
    );
    yield put(getCSIncidentsTakenHomeFail());
  }
}

function* getWorkInProgress({}) {
  try {
    const response = yield doGet(
      `/spill/spill-status-work-in-progress`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getWorkInProgressSuccess(response.data));
  } catch (err) {
    yield put(getWorkInProgressFail(err.response.data.message || err.message));
    yield put(getWorkInProgressFail());
  }
}

function* getCSIncidentsSince8({}) {
  try {
    const response = yield doGet(
      `/spill/current-daily-total`, //current-daily-total==incidents since 8
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSIncidentsSince8Success(response.data));
  } catch (err) {
    yield put(
      getCSIncidentsSince8Fail(err.response.data.message || err.message)
    );
    // yield put(getWorkInProgressFail());
  }
}

function* getCSDailyBusinessHours({}) {
  try {
    const response = yield doGet(
      `/spill/business-hours-spills`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSDailyBusinessHoursSuccess(response.data));
  } catch (err) {
    yield put(
      getCSDailyBusinessHoursFail(err.response.data.message || err.message)
    );
    // yield put(getWorkInProgressFail());
  }
}

function* getCSDailyAfterHours({}) {
  try {
    const response = yield doGet(
      `/spill/after-hours-spills`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getCSDailyAfterHoursSuccess(response.data));
  } catch (err) {
    yield put(
      getCSDailyAfterHoursFail(err.response.data.message || err.message)
    );
    // yield put(getWorkInProgressFail());
  }
}

function* getCurrentDailyTotal({}) {
  try {
    const response = yield doGet(
      `/spill/current-daily-total`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentDailyTotalSuccess(response.data));
  } catch (err) {
    yield put(
      getCurrentDailyTotalFail(err.response.data.message || err.message)
    );
    yield put(getCurrentDailyTotalFail());
  }
}

function* getCurrentYearSpills({}) {
  try {
    const response = yield doGet(
      `/spill/current-year-spill`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentYearSpillsSuccess(response.data));
  } catch (err) {
    yield put(
      getCurrentYearSpillsFail(err.response.data.message || err.message)
    );
    yield put(getCurrentYearSpillsFail());
  }
}

function* getPreviousYearSpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/find-previous-year-spills?date=${obj.date}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getPreviousYearSpillsSuccess(response.data));
  } catch (err) {
    yield put(
      getPreviousYearSpillsFail(err.response.data.message || err.message)
    );
    yield put(getPreviousYearSpillsFail());
  }
}

function* getCurrentVsPreviousYearSpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/current-vs-previous-year-spill-at-date?date=${obj.date}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentVsPreviousYearSpillsSuccess(response.data));
  } catch (err) {
    yield put(
      getCurrentVsPreviousYearSpillsFail(
        err.response.data.message || err.message
      )
    );
    yield put(getCurrentVsPreviousYearSpillsFail());
  }
}

function* getDaysToPaySpills({obj}) {
  try {
    const response = yield doPost(
      `/spill/days-to-pay-spills?year=${obj?.year}`,
      {organization: obj?.organizations},
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getDaysToPaySpillsSuccess(response?.data?.data));
  } catch (err) {
    yield put(
      getDaysToPaySpillsFail(
        err.response.data.message || err.message
      )
    );
    yield put(getDaysToPaySpillsFail());
  }
}

function* getSpillStatusCount({ obj }) {
  try {
    const response = yield doGet(
      `/spill/fetch-spills-statuses-count?year=${obj.year}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getSpillStatusCountSuccess(response.data));
  } catch (err) {
    yield put(
      getSpillStatusCountFail(err.response.data.message || err.message)
    );
    yield put(getSpillStatusCountFail());
  }
}

function* getAllMonthSpillsCountInYear({ obj }) {
  try {
    const response = yield doGet(
      `spill/monthly-spills-in-year?year=${obj.date}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getAllMonthSpillsCountInYearSuccess(response.data));
  } catch (err) {
    yield put(
      getAllMonthSpillsCountInYearFail(err.response.data.message || err.message)
    );
    yield put(getAllMonthSpillsCountInYearFail());
  }
}

function* getAllMonthSpillsCountInCurrentVSPreviousYear() {
  try {
    const response = yield doGet(
      `spill/monthly-spills-in-current-previous-year`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(
      getAllMonthSpillsCountInCurrentVSPreviousYearSuccess({
        data: response?.data,
      })
    );
  } catch (err) {
    yield put(
      getAllMonthSpillsCountInCurrentVSPreviousYearFail(
        err.response.data.message || err.message
      )
    );
    // yield put(getAllMonthSpillsCountInYearFail());
  }
}

function* getSpillsOnEachWorkday({ obj }) {
  try {
    const response = yield doGet(
      `spill/fetch-spills-on-each-workday?date1=${obj.startDate}&date2=${obj.endDate}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    if (response.message) {
      yield put(getSpillsOnEachWorkdayFail(response.message));
    }
    yield put(getSpillsOnEachWorkdaySuccess(response.data));
  } catch (err) {
    yield put(
      getSpillsOnEachWorkdayFail(err?.response?.data?.message || err?.message)
    );
    yield put(getSpillsOnEachWorkdayFail());
  }
}
function* getSpillsOnEachWeek({ obj }) {
  try {
    const response = yield doGet(
      `spill/fetch-spills-on-each-workweek?date1=${obj?.startDate}&date2=${obj?.endDate}`,
      null,

      REQUEST_TYPE.ANALYTICS
    );
    if (response.message) {
      yield put(getSpillsOnEachWeekFail(response.message));
    }
    yield put(getSpillsOnEachWeekSuccess(response?.data));
  } catch (err) {
    yield put(
      getSpillsOnEachWeekFail(err?.response?.data?.message || err?.message)
    );
    yield put(getSpillsOnEachWeekFail());
  }
}
function* getCurrentMonthSpillsProjection() {
  try {
    const response = yield doGet(
      `spill/get-current-month-spills-projection`,
      null,

      REQUEST_TYPE.ANALYTICS
    );
    if (response.message) {
      yield put(getCurrentMonthSpillsProjectionFail(response.message));
    }
    yield put(getCurrentMonthSpillsProjectionSuccess(response?.data));
  } catch (err) {
    yield put(
      getCurrentMonthSpillsProjectionFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getCurrentMonthSpillsProjectionFail());
  }
}

function* getAveragePerDayPerWeek({ obj }) {
  try {
    const response = yield doGet(
      `spill/get-avg-perday-perweek?date=${obj?.currentDate}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    if (response.message) {
      yield put(getAveragePerDayPerWeekFail(response.message));
    }
    yield put(getAveragePerDayPerWeekSuccess(response?.data));
  } catch (err) {
    yield put(
      getAveragePerDayPerWeekFail(err?.response?.data?.message || err?.message)
    );
    yield put(getAveragePerDayPerWeekFail());
  }
}

function* getFilteredSpillsStatusCount({ obj }) {
  try {
    let response;
    if (obj?.type === 'yearly') {
      response = yield doGet(
        `spill/fetch-filtered-spills-statuses-count?year=${
          obj?.year
        }&clientId="${obj?.clientId ? obj?.clientId : ''}"&contractorId="${
          obj?.contractorId ? obj?.contractorId : ''
        }"&subContractorId="${
          obj?.subContractorId ? obj?.subContractorId : ''
        }"`,
        null,
        REQUEST_TYPE.ANALYTICS
      );
    }
    if (obj?.type === 'monthly') {
      response = yield doGet(
        `spill/fetch-filtered-spills-statuses-count?month=${
          obj?.month
        }&clientId="${obj?.clientId ? obj?.clientId : ''}"&contractorId="${
          obj?.contractorId ? obj?.contractorId : ''
        }"&subContractorId="${
          obj?.subContractorId ? obj?.subContractorId : ''
        }"`,
        null,
        REQUEST_TYPE.ANALYTICS
      );
    }
    if (obj?.type === 'weekly') {
      response = yield doGet(
        `spill/fetch-filtered-spills-statuses-count?date1=${
          obj?.startDate
        }&date2=${obj?.endDate}&clientId="${
          obj?.clientId ? obj?.clientId : ''
        }"&contractorId="${
          obj?.contractorId ? obj?.contractorId : ''
        }"&subContractorId="${
          obj?.subContractorId ? obj?.subContractorId : ''
        }"`,
        null,
        REQUEST_TYPE.ANALYTICS
      );
    }
    yield put(getFilteredSpillsStatusCountSuccess(response?.data));
  } catch (err) {
    yield put(
      getFilteredSpillsStatusCountFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getFilteredSpillsStatusCountFail());
  }
}

function* getCurrentVsPreviousMonthSpillAtMonth({ obj }) {
  try {
    const response = yield doGet(
      `spill/current-vs-previous-month-spill-at-month?month=${obj?.month}&year=${obj?.year}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    if (response.message) {
      yield put(getCurrentVsPreviousMonthSpillAtMonthFail(response.message));
    }
    yield put(getCurrentVsPreviousMonthSpillAtMonthSuccess(response?.data));
  } catch (err) {
    yield put(
      getCurrentVsPreviousMonthSpillAtMonthFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getCurrentVsPreviousMonthSpillAtMonthFail());
  }
}

function* getNonHazardousIncidents({ obj }) {
  try {
    const response = yield doGet(
      `incidents/non-hazardous`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    if (response.message) {
      yield put(getNonHazardousIncidentsSuccess(response.message));
    }
    yield put(getNonHazardousIncidentsSuccess(response?.data));
  } catch (err) {
    yield put(
      getNonHazardousIncidentsFail(err?.response?.data?.message || err?.message)
    );
    yield put(getNonHazardousIncidentsFail());
  }
}

function* getHazardousIncidents({ obj }) {
  try {
    const response = yield doGet(
      `incidents/hazardous`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    if (response.message) {
      yield put(getHazardousIncidentsSuccess(response.message));
    }
    yield put(getHazardousIncidentsSuccess(response?.data));
  } catch (err) {
    yield put(
      getHazardousIncidentsFail(err?.response?.data?.message || err?.message)
    );
    yield put(getHazardousIncidentsFail());
  }
}

function* getNonEmergencyIncidents({ obj }) {
  try {
    const response = yield doGet(
      `incidents/need/non-emergency`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    if (response.message) {
      yield put(getNonEmergencyIncidentsSuccess(response.message));
    }
    yield put(getNonEmergencyIncidentsSuccess(response?.data));
  } catch (err) {
    yield put(
      getNonEmergencyIncidentsFail(err?.response?.data?.message || err?.message)
    );
    yield put(getNonEmergencyIncidentsFail());
  }
}

function* getEmergencyIncidents({ obj }) {
  try {
    const response = yield doGet(
      `incidents/need/emergency`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    if (response.message) {
      yield put(getEmergencyIncidentsSuccess(response.message));
    }
    yield put(getEmergencyIncidentsSuccess(response?.data));
  } catch (err) {
    yield put(
      getEmergencyIncidentsFail(err?.response?.data?.message || err?.message)
    );
    yield put(getEmergencyIncidentsFail());
  }
}

function* getIncidentsAtLocationTypes({ obj }) {
  try {
    let requestUrl = obj?.date
      ? [
          `/incidents?loc_type=Client Facility&year=${obj.date}`,
          `/incidents?loc_type=Customers Location&year=${obj.date}`,
          `/incidents?loc_type=Roadway&year=${obj.date}`,
          `/incidents?loc_type=Unspecified&year=${obj.date}`,
        ]
      : [
          '/incidents?loc_type=Client Facility',
          '/incidents?loc_type=Customers Location',
          '/incidents?loc_type=Roadway',
          '/incidents?loc_type=Unspecified',
        ];
    let responses = yield doGetAll(requestUrl, null, REQUEST_TYPE.ANALYTICS);
    responses = responses.map((item) => item.data.data);

    yield put(getIncidentsAtLocationTypesSuccess(responses));
  } catch (err) {
    yield put(
      getIncidentsAtLocationTypesFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getIncidentsAtLocationTypesFail());
  }
}
function* getEvacuationIncidentsNumber({ obj }) {
  try {
    const response = yield doGet(
      'incidents/excavation',
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getEvacuationIncidentsNumberSuccess(response?.data?.data));
  } catch (err) {
    yield put(
      getEvacuationIncidentsNumberFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getEvacuationIncidentsNumberFail());
  }
}

function* getDisposalIncidentsNumber({ obj }) {
  try {
    const response = yield doGet(
      'incidents/disposal',
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getDisposalIncidentsNumberSuccess(response?.data?.data));
  } catch (err) {
    yield put(
      getDisposalIncidentsNumberFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getDisposalIncidentsNumberFail());
  }
}

function* getAvgTimeForExcavation({ obj }) {
  try {
    const response = yield doGet(
      'incidents/avg/complete/exacavation',
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getAvgTimeForExcavationSuccess(response?.data?.data));
  } catch (err) {
    yield put(
      getAvgTimeForExcavationFail(err?.response?.data?.message || err?.message)
    );
    yield put(getAvgTimeForExcavationFail());
  }
}

function* get5800FormsDetails({ obj }) {
  try {
    let requestUrl = obj?.date
      ? [
          `incidents/5800?year=${obj.date}`,
          `incidents/completed/5800?year=${obj.date}`,
          `incidents/5800/range?year=${obj.date}`,
          `incidents/opened/5800?year=${obj.date}`,
        ]
      : [
          'incidents/5800',
          'incidents/completed/5800',
          'incidents/5800/range',
          'incidents/opened/5800',
        ];

    let responses = yield doGetAll(requestUrl, null, REQUEST_TYPE.ANALYTICS);
    responses = responses.map((item) => item.data.data);

    yield put(get5800FormsDetailsSuccess(responses));
  } catch (err) {
    yield put(
      get5800FormsDetailsFail(err?.response?.data?.message || err?.message)
    );
    yield put(get5800FormsDetailsFail());
  }
}

function* getFetchSpillsStatusesCountRoleBased({ obj }) {
  try {
    let response = yield doGet(
      'spill/fetch-spills-statuses-count-role-based?year=""&month=""&date1=""&date2=""&contractorId=""&subContractorId=""&clientId=""',
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getFetchSpillsStatusesCountRoleBasedSuccess(response?.data));
  } catch (err) {
    yield put(
      getFetchSpillsStatusesCountRoleBasedFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getFetchSpillsStatusesCountRoleBasedFail());
  }
}

function* getTurnedUsDownForContractor({ obj }) {
  try {
    let response = yield doGet(
      'incidents/count/contractor/down',
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getTurnedUsDownForContractorSuccess(response?.data));
  } catch (err) {
    yield put(
      getTurnedUsDownForContractorFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getTurnedUsDownForContractorFail());
  }
}

function* getHazIncidentsClient({ obj }) {
  try {
    let response = yield doGet(
      'incidents/count/events/hazardous',
      null,
      REQUEST_TYPE.ANALYTICS
    );
    yield put(getHazIncidentsClientSuccess(response?.data?.data));
  } catch (err) {
    yield put(
      getHazIncidentsClientFail(err?.response?.data?.message || err?.message)
    );
    yield put(getHazIncidentsClientFail());
  }
}
function* getIncidentsReportedClient({ obj }) {
  try {
    let responses = yield doGetAll(
      ['incidents/reports/statagency', 'incidents/reports/nrc'],
      null,
      REQUEST_TYPE.ANALYTICS
    );
    responses = responses?.map((item) => item?.data);
    yield put(getIncidentsReportedClientSuccess(responses));
  } catch (err) {
    yield put(
      getHazIncidentsClientFail(err?.response?.data?.message || err?.message)
    );
    yield put(getIncidentsReportedClientFail());
  }
}
function* getVehicleIncidents({ obj }) {
  try {
    const response = yield doGet(
      `incidents/vehicle_accidents`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getVehicleIncidentsSuccess(response?.data));
  } catch (err) {
    yield put(
      getVehicleIncidentsFail(err?.response?.data?.message || err?.message)
    );
    yield put(getVehicleIncidentsFail());
  }
}
function* get5800CompletedClient({ obj }) {
  try {
    const response = yield doGet(
      `incidents/completed/5800`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(get5800CompletedClientSuccess(response?.data));
  } catch (err) {
    yield put(
      get5800CompletedClientFail(err?.response?.data?.message || err?.message)
    );
    yield put(get5800CompletedClientFail());
  }
}
function* getAverageInvoiceClient({ obj }) {
  try {
    let responses = yield doGetAll(
      ['incidents/avg/contractor/invoice', 'incidents/avg/pes/invoice'],
      null,
      REQUEST_TYPE.ANALYTICS
    );
    responses = responses?.map((item) => item?.data);
    yield put(getAverageInvoicesClientSuccess(responses));
  } catch (err) {
    yield put(
      getAverageInvoicesClientFail(err?.response?.data?.message || err?.message)
    );
    yield put(getAverageInvoicesClientFail());
  }
}
function* getSavingsClient({ obj }) {
  try {
    let responses = yield doGetAll(
      [
        `incidents/pesmanagement/savings${
          obj?.date ? `?year=${obj?.date}` : ''
        }`,
        `incidents/pesmanagement/savings`,
      ],
      null,
      REQUEST_TYPE.ANALYTICS
    );
    responses = responses?.map((item) => item?.data);
    yield put(getSavingsClientSuccess(responses));
  } catch (err) {
    yield put(
      getSavingsClientFail(err?.response?.data?.message || err?.message)
    );
    yield put(getSavingsClientFail());
  }
}
function* getIncidentsCompletionTypeStatuses({ obj }) {
  try {
    let responses = yield doGetAll(
      [
        'incidents/avg/schedule/exacavation',
        'incidents/avg/complete/exacavation',
        'incidents/avg/schedule/disposal',
        'incidents/avg/schedule/hazardous/disposal',
        'incidents/avg/schedule/nonhazardous/disposal',
        'incidents/avg/complete/disposal_time',
        'incidents/avg/complete/hazardous/disposal',
        'incidents/avg/complete/nonhazardous/disposal',
      ],
      null,
      REQUEST_TYPE.ANALYTICS
    );
    responses = responses?.map((item) => item?.data);
    yield put(getIncidentsCompletionTypeStatusesSuccess(responses));
  } catch (err) {
    yield put(
      getSavingsClientFail(err?.response?.data?.message || err?.message)
    );
    yield put(getIncidentsCompletionTypeStatusesFail());
  }
}
function* getAverageEta({ obj }) {
  try {
    const response = yield doGet(
      `incidents/avg/eta`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getAverageEtaSuccess(response?.data));
  } catch (err) {
    yield put(getAverageEtaFail(err?.response?.data?.message || err?.message));
    yield put(getAverageEtaFail());
  }
}
function* getAverageIncidentSameContractor({ obj }) {
  try {
    const response = yield doGet(
      `incidents/contractor/listing`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getAverageIncidentSameContractorSuccess(response?.data));
  } catch (err) {
    yield put(
      getAverageIncidentSameContractorFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getAverageIncidentSameContractorFail());
  }
}
function* getAllContractorTurnUsDown({ obj }) {
  try {
    const response = yield doGet(
      `contractors/rejected`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getAllContractorTurnUsDownSuccess(response?.data));
  } catch (err) {
    yield put(
      getAllContractorTurnUsDownFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getAllContractorTurnUsDownFail());
  }
}
function* getResponsePercentageContractors({ obj }) {
  try {
    const responseSpecificContractor = yield doGet(
      `contractors/response/percentage?contractorId=${
        obj?.contractorId ? obj?.contractorId : ''
      }&subContractorId="${obj?.contractorId ? obj?.subContractorId : ''}"`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    const response = yield doGet(
      `contractors/response/percentage`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    const preparedResponse = {
      data: {
        percentage: response?.data?.data?.percentage,
        specificContractorPercentage:
          responseSpecificContractor?.data?.data?.percentage,
      },
    };
    yield put(getResponsePercentageContractorsSuccess(preparedResponse));
  } catch (err) {
    yield put(
      getResponsePercentageContractorsFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getResponsePercentageContractorsFail());
  }
}
function* getParticularContractorTurnedUsDown({ obj }) {
  try {
    const response = yield doGet(
      `contractors/rejected?contractorId=${
        obj?.contractorId ? obj?.contractorId : ''
      }&subContractorId="${obj?.subContractorId ? obj?.subContractorId : ''}"`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getParticularContractorTurnedUsDownSuccess(response?.data));
  } catch (err) {
    yield put(
      getParticularContractorTurnedUsDownFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getParticularContractorTurnedUsDownFail());
  }
}

function* getCurrentlyOpenSpills() {
  try {
    const response = yield doGet(
      `/spill/currently-open-spills`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentlyOpenSpillsSuccess(response?.data));
  } catch (err) {
    yield put(
      getCurrentlyOpenSpillsFail(err?.response?.data?.message || err?.message)
    );
    yield put(getCurrentlyOpenSpillsFail());
  }
}

function* getCurrentlyOpenedSpillsInCurrentYear() {
  try {
    const response = yield doGet(
      `/spill/currently-open-spills-current-year`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentlyOpenedSpillsInCurrentYearSuccess(response?.data));
  } catch (err) {
    yield put(
      getCurrentlyOpenedSpillsInCurrentYearFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getCurrentlyOpenedSpillsInCurrentYearFail());
  }
}

function* getCurrentlyOpenedSpillsInPreviousYear() {
  try {
    const response = yield doGet(
      `/spill/currently-open-spills-previous-year`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentlyOpenedSpillsInPreviousYearSuccess(response?.data));
  } catch (err) {
    yield put(
      getCurrentlyOpenedSpillsInPreviousYearFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getCurrentlyOpenedSpillsInPreviousYearFail());
  }
}

function* getSpillsClosedYTD() {
  try {
    const response = yield doGet(
      `/spill/spills-closed-year-to-date`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getSpillsClosedYTDSuccess(response?.data));
  } catch (err) {
    yield put(
      getSpillsClosedYTDFail(err?.response?.data?.message || err?.message)
    );
    yield put(getSpillsClosedYTDFail());
  }
}

function* getCurrentlyClosedSpillsCurrentYear() {
  try {
    const response = yield doGet(
      `/spill/closed-spills-opened-this-year`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getCurrentlyClosedSpillsCurrentYearSuccess(response?.data));
  } catch (err) {
    yield put(
      getCurrentlyClosedSpillsCurrentYearFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getCurrentlyClosedSpillsCurrentYearFail());
  }
}

function* getAvgDaysToCloseSpill() {
  try {
    const response = yield doGet(
      `/spill/average-days-to-close-spill`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getAvgDaysToCloseSpillSuccess(response?.data));
  } catch (err) {
    yield put(
      getAvgDaysToCloseSpillFail(err?.response?.data?.message || err?.message)
    );
    yield put(getAvgDaysToCloseSpillFail());
  }
}

function* getWeeklySpillCountForAMonth({ obj }) {
  try {
    const response = yield doGet(
      `/spill/weekly-spills-count-for-month?monthlyDate=${obj.month}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getWeeklySpillCountForAMonthSuccess(response?.data));
  } catch (err) {
    yield put(
      getWeeklySpillCountForAMonthFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getWeeklySpillCountForAMonthFail());
  }
}

function* getAfterHoursSpills({}) {
  try {
    const response = yield doGet(
      `/spill/after-hours-spills`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getAfterHoursSpillsSuccess(response.data));
  } catch (err) {
    yield put(
      getAfterHoursSpillsFail(err.response.data.message || err.message)
    );
    yield put(getAfterHoursSpillsFail());
  }
}

function* getCurrentProjectCount({ obj }) {
  try {
    const response = yield doGet(
      `spill/pm-nontracking-open-incidents?pm_id=${obj}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    if (response?.data) {
      yield put(getCurrentProjectCountSuccess(response?.data));
    } else {
      yield put(getCurrentProjectCountFail('client error'));
    }
  } catch (err) {
    yield put(
      put(
        getCurrentProjectCountFail(err?.response?.data?.message || err?.message)
      )
    );
  }
}

function* getPMOpenIncidents({ obj }) {
  try {
    const response = yield doGet(
      `spill/pm-open-incidents?pm_id=${obj}`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    if (response?.data) {
      yield put(getPMOpenIncidentsSuccess(response?.data));
    } else {
      yield put(getPMOpenIncidentsFail('client error'));
    }
  } catch (err) {
    yield put(
      put(getPMOpenIncidentsFail(err?.response?.data?.message || err?.message))
    );
  }
}

function* getPMList({ obj }) {
  try {
    const response = yield doGet(
      `spill/list-of-pms`,
      null,
      REQUEST_TYPE.ANALYTICS
    );
    if (response?.data?.data?.array) {
      yield put(getPMListSuccess(response?.data?.data?.array));
    } else {
      yield put(getPMListSuccess('client error'));
    }
  } catch (err) {
    yield put(put(getPMListFail(err?.response?.data?.message || err?.message)));
  }
}

function* getTrackingFileIncidents({}) {
  try {
    const response = yield doGet(
      `/spill/tracking-file-incidents`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getTrackingFileIncidentsSuccess(response.data));
  } catch (err) {
    yield put(
      getTrackingFileIncidentsFail(err.response.data.message || err.message)
    );
  }
}

function* getSpillsBroughtIntoOffice({}) {
  try {
    const response = yield doGet(
      `/spill/spills-brought-into-office`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getSpillsBroughtIntoOfficeSuccess(response.data));
  } catch (err) {
    yield put(
      getSpillsBroughtIntoOfficeFail(err.response.data.message || err.message)
    );
  }
}

function* getDigsScheduledToday({}) {
  try {
    const response = yield doGet(
      `/spill/digs-scheduled-for-today`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getDigsScheduledTodaySuccess(response.data));
  } catch (err) {
    yield put(
      getDigsScheduledTodayFail(err.response.data.message || err.message)
    );
  }
}

function* getNonTrackingWithWorkInProgress({}) {
  try {
    const response = yield doGet(
      `/spill/non-tracking-work-in-progress-spills`,
      null,
      REQUEST_TYPE.ANALYTICS
    );

    yield put(getNonTrackingWithWorkInProgressSuccess(response.data));
  } catch (err) {
    yield put(
      getNonTrackingWithWorkInProgressFail(
        err.response.data.message || err.message
      )
    );
  }
}

export default function* watcher() {
  yield all([
    takeLatest('GET_MOST_LARGEST_SPILLS', getMostLargestSpills),
    takeLatest('GET_CURRENT_PROJECT_COUNT', getCurrentProjectCount),
    takeLatest('GET_TRACKING_FILES_INCIDENTS', getTrackingFileIncidents),
    takeLatest('GET_SPILLS_BROUGHT_INTO_OFFICE', getSpillsBroughtIntoOffice),
    takeLatest('GET_DIGS_SCHEDULED_TODAY', getDigsScheduledToday),
    takeLatest(
      'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS',
      getNonTrackingWithWorkInProgress
    ),
    takeLatest('GET_WORK_IN_PROGRESS', getWorkInProgress),
    takeLatest('GET_BUSINESS_HOURS_SPILLS', getBusinessHoursSpills),
    takeLatest('GET_CURRENT_DAILY_TOTAL', getCurrentDailyTotal),
    takeLatest('GET_CURRENT_YEAR_SPILLS', getCurrentYearSpills),
    takeLatest('GET_PREVIOUS_YEAR_SPILLS', getPreviousYearSpills),
    takeLatest(
      'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS',
      getCurrentVsPreviousYearSpills
    ),
    takeLatest('GET_DAYS_TO_PAY_SPILLS', getDaysToPaySpills),
    takeLatest('GET_SPILL_STATUS_COUNT', getSpillStatusCount),
    takeLatest(
      'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR',
      getAllMonthSpillsCountInYear
    ),
    takeLatest('GET_SPILLS_ON_EACH_WORKDAY', getSpillsOnEachWorkday),

    takeLatest('GET_SPILLS_ON_EACH_WEEK', getSpillsOnEachWeek),
    takeLatest(
      'GET_CURRENT_MONTH_SPILLS_PROJECTION',
      getCurrentMonthSpillsProjection
    ),
    takeLatest('GET_AVERAGE_PER_DAY_PER_WEEK', getAveragePerDayPerWeek),
    takeLatest(
      'GET_FILTERED_SPILLS_STATUS_COUNT',
      getFilteredSpillsStatusCount
    ),
    takeLatest(
      'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH',
      getCurrentVsPreviousMonthSpillAtMonth
    ),
    takeLatest('GET_NON_HAZARDOUS_INCIDENTS', getNonHazardousIncidents),
    takeLatest('GET_HAZARDOUS_INCIDENTS', getHazardousIncidents),

    takeLatest('GET_NON_EMERGENCY_INCIDENTS', getNonEmergencyIncidents),
    takeLatest('GET_EMERGENCY_INCIDENTS', getEmergencyIncidents),

    takeLatest('GET_INCIDENTS_AT_LOCATION_TYPES', getIncidentsAtLocationTypes),
    takeLatest('GET_EVACUATION_INCIDENTS_NUMBER', getEvacuationIncidentsNumber),
    takeLatest('GET_DISPOSAL_INCIDENTS_NUMBER', getDisposalIncidentsNumber),
    takeLatest('GET_AVG_TIME_FOR_EXCAVATION', getAvgTimeForExcavation),
    takeLatest('GET_5800_FORMS_DETAILS', get5800FormsDetails),
    takeLatest(
      'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED',
      getFetchSpillsStatusesCountRoleBased
    ),
    takeLatest(
      'GET_TURNED_US_DOWN_FOR_CONTRACTOR',
      getTurnedUsDownForContractor
    ),
    takeLatest('GET_HAZ_INCIDENTS_CLIENT', getHazIncidentsClient),
    takeLatest('GET_INCIDENTS_REPORTS_CLIENT', getIncidentsReportedClient),
    takeLatest('GET_VEHICLE_INCIDENTS', getVehicleIncidents),
    takeLatest('GET_5800_COMPLETED_CLIENT', get5800CompletedClient),
    takeLatest('GET_AVERAGE_INVOICE_CLIENT', getAverageInvoiceClient),
    takeLatest('GET_SAVINGS_CLIENT', getSavingsClient),
    takeLatest(
      'GET_INCIDENTS_COMPLETION_TYPE_STATUSES',
      getIncidentsCompletionTypeStatuses
    ),
    takeLatest('GET_AVERAGE_ETA', getAverageEta),
    takeLatest(
      'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR',
      getAverageIncidentSameContractor
    ),
    takeLatest('GET_ALL_CONTRACTOR_TURN_US_DOWN', getAllContractorTurnUsDown),
    takeLatest(
      'GET_RESPONSE_PERCENTAGE_CONTRACTORS',
      getResponsePercentageContractors
    ),
    takeLatest(
      'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN',
      getParticularContractorTurnedUsDown
    ),
    takeLatest('GET_CURRENTLY_OPEN_SPILLS', getCurrentlyOpenSpills),
    takeLatest(
      'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR',
      getCurrentlyOpenedSpillsInCurrentYear
    ),
    takeLatest(
      'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR',
      getCurrentlyOpenedSpillsInPreviousYear
    ),
    takeLatest('GET_SPILLS_CLOSED_YTD', getSpillsClosedYTD),
    takeLatest(
      'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR',
      getCurrentlyClosedSpillsCurrentYear
    ),
    takeLatest('GET_AVG_DAYS_TO_CLOSE_SPILL', getAvgDaysToCloseSpill),
    takeLatest(
      'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH',
      getWeeklySpillCountForAMonth
    ),
    takeLatest('GET_AFTER_HOURS_SPILLS', getAfterHoursSpills),
    takeLatest(
      'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR',
      getAllMonthSpillsCountInCurrentVSPreviousYear
    ),
    takeLatest('GET_CS_INCIDENTS_TAKEN_HOME', getCSIncidentsTakenHome),
    takeLatest('GET_CS_INCIDENTS_SINCE_8', getCSIncidentsSince8),
    takeLatest('GET_CS_DAILY_AFTER_HOURS', getCSDailyAfterHours),
    takeLatest('GET_CS_DAILY_BUSINESS_HOURS', getCSDailyBusinessHours),
    takeLatest('GET_PM_LIST', getPMList),
    takeLatest('GET_NON_TRACKING_FILES_WIP', getNonTrackingFilesWIP),
    takeLatest('GET_DAILY_WEEKLY_GIS_MAP', getDailyWeeklyGisMap),
    takeLatest('GET_PM_OPEN_INCIDENTS', getPMOpenIncidents),
    takeLatest('GET_CS_CURRENT_WEEK_SPILLS', getCSCurrentWeekSpills),
    takeLatest(
      'GET_CS_CURRENT_WEEK_BUSINESS_HOURS',
      getCSCurrentWeekBusinessHours
    ),
    takeLatest('GET_CS_CURRENT_WEEK_AFTER_HOURS', getCSCurrentWeekAfterHours),
    takeLatest(
      'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE',
      getCSCurrentWeekBroughtIntoOffice
    ),
    takeLatest('GET_CS_CURRENT_WEEK_EXCAVATION', getCSCurrentWeekExcavation),
    takeLatest(
      'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME',
      getCSCurrentWeekIncidentsTakenHome
    ),
  ]);
}
