import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Warning';

import { makeStyles } from '@material-ui/core/styles';

import EditSpill from '../Spills/EditSpill';
import ViewSpill from '../Spills/ViewSpill';

import DataTable from '../../../../../Components/ActionDataTable';
import * as pmActions from '../../../../../actionCreators/Action';
import * as spillActions from '../../../../../actionCreators/Spill';
import * as servicesActions from '../../../../../actionCreators/Service';
import RejectDialog from './RejectDialog';
import { isPESUser } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    borderRadius: '10px',
  },
  headerCard: {
    backgroundColor: '#f7f7f7',
  },
  menuButtons: {
    fontSize: '16px',
    padding: '5px 5px 0px 5px',
  },
  listAllButton: {
    fontSize: '16px',
    padding: '5px 5px 7px 5px',
  },
  headingCard: {
    marginBottom: '25px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    borderRadius: '10px',
  },
  textField: {
    minWidth: 500,
    margin: 10,
  },
}));

const ActionsMenu = ({
  actions,
  success,
  getActions,
  editAction,
  currentUser,
  getMaterial,
  getServices,
  material,
  service,
  loading,
  history,
}) => {
  document.title = 'Actions';
  const classes = useStyles();
  const location = history.location;

  const { data: rows } = actions;
  const isAdmin = isPESUser(currentUser.data?.role.role);

  const [showEditForm, setShowEditForm] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [selectedAction, setSelectedAction] = useState({});
  const [showReject, setShowReject] = useState(false);
  const [rejectedId, setRejectedId] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getActions();
    getServices();
    getMaterial();
  }, []);

  useEffect(() => {
    const splitPathname = location.pathname.split('/');
    if (splitPathname.length > 3) {
      showViewForm(splitPathname[3]);
    } else {
      // showAllSpills();
    }
  }, [location.key]);

  const tableHeaders = [
    { title: 'Action', key: 'type' },
    { title: 'Manager', key: 'manager_id' },
    { title: 'Last Updated', key: 'updated_at' },
    { title: 'Status', key: 'status' },
  ];

  const handleOpenEditForm = (row) => {
    setShowEditForm(true);
    setShowMenu(false);
    setSelectedAction(row);
    if (row.type === 'CREATE_SPILL') {
      setSelectedId(row.data.jobNo);
    }
  };

  const handleOpenView = (row) => {
    setShowView(true);
    setShowMenu(false);
    setSelectedAction(row);

    setSelectedId(row.data.jobNo);
  };

  const showViewForm = (jobNo) => {
    setShowView(true);
    setShowMenu(false);
    setSelectedId(jobNo);
  };

  const handleAccept = (row) => {
    editAction({
      id: row.id,
      status: 'Accepted',
    });
  };

  const handleCancel = () => {
    setShowEditForm(false);
    setShowView(false);
    setShowMenu(true);
  };

  const handleEdit = (action) => {
    editAction({ id: action.id, data: action.data });
  };

  const handleReject = (row) => {
    setRejectedId(row.id);
    setShowReject(true);
  };

  const handleDialogAccept = (message) => {
    editAction({
      id: rejectedId,
      status: 'Rejected',
      notes: message,
    });
    setShowReject(false);
  };

  const handleDialogCancel = () => {
    setShowReject(false);
  };

  const handleEditAccept = () => {
    editAction({ id: selectedAction.id, status: 'Pending' });
  };

  return (
    <div>
      <Card className={classes.headingCard}>
        <CardContent style={{ paddingBottom: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant='h5' style={{ color: '#619a63' }}>
                Pending Actions
              </Typography>
            </div>
            <div>
              <BusinessIcon fontSize='large' style={{ color: '#619a63' }} />
            </div>
          </div>
          <hr />
          <div
            style={{
              display: 'flex',
              direction: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <div>
                <Button
                  className={classes.listAllButton}
                  color='primary'
                  onClick={() => handleCancel()}
                >
                  All Actions
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {loading && showMenu ? (
        <CircularProgress />
      ) : (
        <>
          {showMenu && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {rows?.length > 0 ? (
                  <DataTable
                    tableHeader={tableHeaders}
                    tableBody={rows}
                    editTable={true}
                    openEditForm={handleOpenEditForm}
                    openView={handleOpenView}
                    onAccept={handleAccept}
                    onReject={handleReject}
                    isAdmin={isAdmin}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setPageRows={() => {}}
                  />
                ) : (
                  <Typography variant='h5'>No actions found</Typography>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {showView && (
        <div>
          <ViewSpill
            servicesData={service}
            materials={material}
            id={selectedId}
            closeEditSpill={() => {
              setShowEditForm(false);
              setShowMenu(true);
            }}
            currentUser={currentUser}
            updateSpill={() => {}}
          ></ViewSpill>
        </div>
      )}

      {showEditForm && (
        <div>
          <EditSpill
            servicesData={service}
            materials={material}
            id={selectedId}
            closeEditSpill={() => {
              setShowEditForm(false);
              setShowMenu(true);
            }}
            onAccept={handleEditAccept}
            viewSpill={() => {}}
          ></EditSpill>
        </div>
      )}

      {showReject && (
        <RejectDialog
          showReject={showReject}
          onDialogAccept={handleDialogAccept}
          onDialogCancel={handleDialogCancel}
        ></RejectDialog>
      )}
    </div>
  );
};

const mapStateToProps = ({
  action: { success, actions, loading },
  user: { currentUser },
  spill: { material },
  service,
}) => ({
  actions,
  success,
  currentUser,
  material,
  service,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  getActions: bindActionCreators(pmActions.getActions, dispatch),
  editAction: bindActionCreators(pmActions.editAction, dispatch),
  getMaterial: bindActionCreators(spillActions.getMaterial, dispatch),
  getServices: bindActionCreators(servicesActions.getServices, dispatch),
});

ActionsMenu.propTypes = {
  actions: PropTypes.object.isRequired,
  success: PropTypes.bool.isRequired,
  getActions: PropTypes.func.isRequired,
  editAction: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsMenu)
);
