// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routeAccessConfig from './Config/routeAccessConfig';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomProgressLoader } from './Components';

const ProtectedRoute = ({ component: Component, currentUser, ...rest }) => {
  const [isReady, setIsReady] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  
  useEffect(() => {
    if (currentUser?.data) {
      const route = rest?.path
      const role = currentUser?.data?.role?.role;
      const access = routeAccessConfig[route] ? routeAccessConfig[route](role) : false;
      setHasAccess(access);
      setIsReady(true);
    }
  }, [currentUser?.data, rest?.path]);

  if (!isReady) {
    // return <CustomProgressLoader show={true} />; // uncomment this to load a custom loader
    return null
  }

  return (
    <Route
      {...rest}
      render={(props) =>
         hasAccess ? (
          <Component {...props} />
        ) : (
          <Redirect to="/unauthorized" />
        )
      }
    />
  );
};

const mapStateToProps = ({ user }) => ({ currentUser: user?.currentUser });

export default withRouter(
  connect(mapStateToProps)(ProtectedRoute)
);
