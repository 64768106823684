import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const AreaReminderPopup = ({
  reminder,
  open,
  handleClose
}) => {
  const handleRemindMeClick = () => {
    handleClose();
  };
  
  const handleEnterFootPrintClick = () => {
    const job_no = reminder?.job_no;
    const note_id = reminder?.note_id;
    localStorage.setItem("note_id", note_id);
    const url = `/dashboard/spills/view/${job_no}?note_id=${note_id}`;
    window.open(url, "_blank");
    handleClose();
     };
  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{display:"flex" , gap:"20px", justifyContent:"space-between"}}>
                <h3 style={{ margin: "10px 0", fontSize: "15px" }}>
                <span style={{ color: "#2F7D32" }}>Job No: </span>
                  {reminder?.job_no}
                </h3>
                <h3 style={{ margin: "10px 0", fontSize: "15px" }}>
                <span style={{ color: "#2F7D32" }}>Note No: </span>
                  {reminder?.note_id}
                </h3>
              </div>
              <p>Please Enter the Area Covered By Release.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", padding: "0 20px 20px 20px" }}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              onClick={handleRemindMeClick}
              style={{ backgroundColor: "#2F7D32" }}
            >
              Remind me
            </Button>
            <Button
              variant="contained"
              component="label"
              color="primary"
              onClick={handleEnterFootPrintClick}
              autoFocus
              style={{ backgroundColor: "#2F7D32" }}
            >
              Enter Release FootPrint
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AreaReminderPopup;

