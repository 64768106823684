import React from 'react';

import {
  Page,
  Font,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import Times from '../../../../../assets/fonts/Times-New-Roman.ttf';
import TimesBold from '../../../../../assets/fonts/Times-New-Roman-Bold.ttf';

Font.register({
  family: 'Times-Roman',
  format: 'truetype',
  src: Times,
});
Font.register({
  family: 'Times-Bold',
  format: 'truetype',
  src: TimesBold,
});
const styles = StyleSheet.create({
  textGreen: {
    color: 'green',
  },
  row: {
    content: '',
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    float: 'left',
    width: '100%',
    padding: '5px',
    height: 'auto',
  },
  colMd: {
    width: '70%',
    float: 'left',
    height: 'auto',
    display: 'flex',
  },
  colSm: {
    width: '30%',
    float: 'right',
    height: 'auto',
  },
  page: {
    backgroundColor: '#ffffff',
    padding: '10px 0px',
  },

  mainHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 9,
    // alignContent: 'flex-end',
    justifyContent: 'center',
    height: 'auto',
    marginTop: '70px',
  },
  logo: {
    color: 'green',
    fontSize: '48px',
    fontWeight: 900,
    marginBottom: '10px',
  },

  topImageWrapper: {
    width: '50%',
    margin: '0 auto',
  },

  name: {
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  name2: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    color: 'rgb(2, 118, 2)',
  },

  subject: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },
  space1: {
    marginTop: '50px',
  },
  space2: {
    marginTop: '250px',
  },
  paddingBody: {
    padding: '1% 10% 0',
  },
});
const header = (
  <View style={styles.mainHeaderContainer}>
    <Image src='/pes-logo.png' style={styles.topImageWrapper} />
  </View>
);

export default function PesCoverLetter({ data }) {
  let date = new Date();

  const month = date.getMonth() + 1; // Returns the month (0-11)
  const day = date.getDate(); // Returns the day of the month (1-31)
  const year = date.getFullYear();
  let dateForPDF = `${month}/${day}/${year}`;
  return (
    <Document>
      <Page style={styles.page}>
        {header}
        <View style={styles.row}>
          <View style={styles.col}>
            <View style={styles.paddingBody}>
              <View style={styles.space1}></View>
              <Text style={styles.name}> {dateForPDF}</Text>
              <View style={styles.space1}></View>
              <Text style={styles.subject}>
                {' '}
                Subject: {'                   '}Closure Packet
              </Text>

              <View style={styles.space1}></View>
              <Text style={styles.name}>
                {' '}
                Please find enclosed your Premium Environmental Services (PES)
                invoice and the contractor invoice for payment. Thank you for
                allowing PES the opportunity to work with you and your
                organization. We value your business and look forward to a
                successful business relationship in the future. If you have any
                questions or need additional information, please contact our
                office at your convenience.
              </Text>
              <View style={styles.space1}></View>
              <Text style={styles.name}> Sincerely,</Text>
              <View style={styles.space1}></View>
              <Text style={styles.name}> Premium Environmental Services</Text>
              <Text style={styles.name}> 812-853-2400</Text>
              <View style={styles.space2}></View>
              <Text style={styles.name2}>
                {' '}
                P.O. BOX 370 ~ Newburgh, IN 47629 ~ PHONE: (812) 853-2400 ~ FAX:
                (812) 853-9400
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
