import React from "react";
import {
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: "left",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  customLable: {
    color: "#444444",
    fontSize: "15px",
    marginTop: "16px",
    marginBottom: "0px",
    display: "inline-block",
  },
  ptb: {
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
  },
  header: {
    color: "green",
    textAlign: "left",
  },
}));
const SpecialInstruction = ({
  formData,
  onChange,
  onDateChange,
  lastRevisionDate,
  editMode,
  selectedDate,
}) => {
  const classes = useStyles();
  const handleDateChange = (date) => {
    const dateOnly = moment(date)?.format("MM/DD/YYYY");
    onDateChange(dateOnly);
    onChange((prevFormData) => ({
      ...prevFormData,
      revision_date: dateOnly,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

   return (
    <>
      <Grid
        container
        spacing={2}
        className={`${classes.mainContainer} CustomScrollbar`}
      >
        {/* Waste Handling */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Waste Handling:</label>
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <TextField
            id="hazardous_waste"
            name="hazardous_waste"
            label="Hazardous Waste"
            fullWidth
            size="small"
            defaultValue={formData?.hazardous_waste}
            value={formData?.hazardous_waste}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <TextField
            id="non_hazardous_waste"
            name="non_hazardous_waste"
            label="Non-Haz Waste"
            fullWidth
            size="small"
            value={formData?.non_hazardous_waste}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        <Grid item md={6}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              label="Date"
              fullWidth
              size="small"
              className={classes.customFormcontrol}
              format="MM-DD-YYYY"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </MuiPickersUtilsProvider>
          {editMode && (
            <p className={lastRevisionDate == null ? "hidden" : "show"}>
              Last Revision Date is:<b> {lastRevisionDate}</b>
            </p>
          )}
        </Grid>
        {/* Reporting */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Reporting:</label>
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <RadioGroup
            aria-label="reporting"
            name="reporting"
            value={formData.reporting}
            onChange={handleChange}
            className="custom-radio"
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <TextField
            id="reporting_instruction"
            name="reporting_instruction"
            label="Instructions"
            multiline
            rowsMax={3}
            fullWidth
            size="small"
            value={formData?.reporting_instruction}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        {/* 5800 Reports */}
        <Grid item xs={12}>
          <label className={classes.customLable}>5800 Reports:</label>
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <RadioGroup
            aria-label="report_5800"
            name="report_5800"
            value={formData.report_5800}
            onChange={handleChange}
            className="custom-radio"
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <TextField
            id="instruction_5800"
            name="instruction_5800"
            label="Instructions"
            fullWidth
            multiline
            rowsMax={3}
            size="small"
            value={formData?.instruction_5800}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        {/* Miscellaneous */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Miscellaneous:</label>
        </Grid>
        <Grid item md={6} className={classes.ptb}>
          <TextField
            id="miscellaneous"
            name="miscellaneous"
            label="Miscellaneous"
            fullWidth
            size="small"
            multiline
            rowsMax={3}
            value={formData?.miscellaneous}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SpecialInstruction;
