import DocIcon from "../assets/file-icons/doc.png";
import PdfIcon from "../assets/file-icons/pdf.png";
import TifIcon from "../assets/file-icons/tif.png";
import XlsIcon from "../assets/file-icons/xls.png";
import { isPESUser } from ".";

export const timeout = (ms = 1000) =>
  new Promise((resolve) => window.setTimeout(resolve, ms));

export const encodeURL = (url) =>
  String(url)
    .split(" ")
    .join("%20")
    .split("#")
    .join("%23")
    .split("$")
    .join("%24")
    .split("%")
    .join("%25")
    .split("&")
    .join("%26")
    .split("@")
    .join("%40")
    .split("`")
    .join("%60")
    .split("/")
    .join("%2F")
    .split(":")
    .join("%3A")
    .split(";")
    .join("%3B")
    .split("<")
    .join("%3C")
    .split("=")
    .join("%3D")
    .split(">")
    .join("%3E")
    .split("?")
    .join("%3F")
    .split("[")
    .join("%5B")
    .split("\\")
    .join("%5C")
    .split("]")
    .join("%5D")
    .split("^")
    .join("%5E")
    .split("{")
    .join("%7B")
    .split("|")
    .join("%7C")
    .split("}")
    .join("%7D")
    .split("~")
    .join("%7E")
    .split('"')
    .join("%22")
    .split("'")
    .join("%27")
    .split("+")
    .join("%2B")
    .split(",")
    .join("%2C");

export const getRandomNumber = (min = 0, max = 100) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const sanitizeText = (str) =>
  String(str).replace(/\W/g, "_").toLowerCase();

export const printListener = (src) => {
  if (document.querySelector(".quant-viewpage-loader")) {
    document.querySelector(".quant-viewpage-loader").style.display = "block";
  }
  const element = document.getElementById("print-frame");
  if (element) {
    element.parentNode.removeChild(element);
  }
  if (src) {
    const url = src; // || `${envConfig.baseUrl}${envConfig.apiEndPoints.assets}?url=${src}`;
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    /** check the browser is IE */
    if (
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv:11\./) ||
      ua.search("Firefox") > -1
    ) {
      const embed = document.createElement("embed");
      embed.id = "print-frame";
      embed.style.opacity = 0;
      embed.style.width = "2px";
      embed.style.height = "2px";
      embed.type = "application/pdf";
      embed.src = url;
      document.body.appendChild(embed);
      embed.onload = async () => {
        await timeout(3000);

        if (document.querySelector(".quant-viewpage-loader")) {
          document.querySelector(".quant-viewpage-loader").style.display =
            "none";
        }

        const frameEle = document.getElementById("print-frame");
        frameEle.setActive();
        frameEle.focus();
        frameEle.print();
      };
    } else {
      const printFrame = document.createElement("iframe");
      printFrame.id = "print-frame";
      printFrame.style.display = "none";
      printFrame.src = url;
      printFrame.type = "application/pdf";

      document.body.appendChild(printFrame);

      printFrame.onload = async () => {
        await timeout(3000);

        if (document.querySelector(".quant-viewpage-loader")) {
          document.querySelector(".quant-viewpage-loader").style.display =
            "none";
        }

        printFrame.contentWindow.print();
      };
    }
  }
};

export const uptoDecimalPlaces = (str, val) => {
  if (str && val) {
    str = str?.toString();
    if (str?.indexOf(".") !== -1) {
      str = str?.slice(0, str?.indexOf(".") + val + 1);
    }
    return Number(str);
  }
};

export const generatePreview = (file) => {
  const extensionLists = {
    image: ["jpg", "gif", "bmp", "png"],
    doc: ["doc", "docx"],
    spreadsheet: ["xls", "xlsx"],
    tif: ["TIF"],
    pdf: ["pdf"],
  };
  const fileExt = file.name.includes(".") ? file.name.split(".")[1] : "";

  for (const [key, value] of Object.entries(extensionLists)) {
    if (value.includes(fileExt)) {
      switch (key) {
        case "image":
          return URL.createObjectURL(file);
        case "doc":
          return DocIcon;
        case "spreadsheet":
          return XlsIcon;
        case "tif":
          return TifIcon;
        case "pdf":
          return PdfIcon;
        default:
          return DocIcon;
      }
    }
  }
};

export const getPreview = (attachment) => {
  let fileExt = "";
  const extensionLists = {
    image: ["jpg", "gif", "bmp", "png"],
    doc: ["doc", "docx"],
    spreadsheet: ["xls", "xlsx"],
    tif: ["TIF"],
    pdf: ["pdf"],
  };
  if (attachment.name) {
    fileExt = attachment.name.includes(".")
      ? attachment.name.split(".")[1]
      : "";
  } else {
    return attachment.url_link;
  }

  for (const [key, value] of Object.entries(extensionLists)) {
    if (value.includes(fileExt)) {
      switch (key) {
        case "image":
          return attachment.url_link;
        case "doc":
          return DocIcon;
        case "spreadsheet":
          return XlsIcon;
        case "tif":
          return TifIcon;
        case "pdf":
          return PdfIcon;
        default:
          return DocIcon;
      }
    }
  }
};

export const specificAttachmentTypes = [
  "rateSheet",
  "contractorW9",
  "hasWasteHauler",
  "contractorWorkers",
  "contractorLiability",
];

export const uploadTypes = [
  { label: "Spill Summary", value: "spillSummary" },
  { label: "Contractor Invoice", value: "contractorInv" },
  { label: "Waste Doc", value: "wasteDoc" },
  { label: "NRC Report", value: "nrcReport" },
  { label: "State Report", value: "stateReport" },
  { label: "Initial State Report", value: "initialStateReport" },
  { label: "Scope of Work", value: "scopeOfWork" },
  { label: "Estimate", value: "estimate" },
  { label: "Scale Ticket", value: "scaleTicket" },
  { label: "Email Conversation", value: "emailConversation" },
  { label: "Pictures", value: "pictures" },
];

export const pesRestrictedTypes = [
  { label: "PES Cover Letter", value: "coverLetter" },
  { label: "PES Invoice", value: "pesInvoice" },
  {
    label: "Contractor Liability Certificate of Insurance",
    value: "contractorLiability",
  },
  {
    label: "Rate Sheet",
    value: "rateSheet",
  },
  {
    label: "Contractor Workers Comp Certificate of Insurance",
    value: "contractorWorkers",
  },
  { label: "Contractor W-9", value: "contractorW9" },
  { label: "Misc/Other", value: "miscOther" },
  { label: "Haz Waste Haulers License", value: "hasWasteHauler" },
];

export const completedAttachmentTypes = [
  { label: "Misc/Other", value: "miscOther" },
];

const [, , ...rest] = pesRestrictedTypes;

export const getAllUploadTypes = () => [...uploadTypes, ...pesRestrictedTypes];

export const getUploadTypes = (role) =>
  isPESUser(role)
    ? [...uploadTypes, ...pesRestrictedTypes]
    : [...uploadTypes, ...rest];

export const getCompletedAttachmentUploadTypes = () => [
  ...completedAttachmentTypes,
];

export const getUploadTypesConditionally = (role, adminAttachmentTypes) =>
  isPESUser(role)
    ? [
        ...adminAttachmentTypes?.general,
        ...adminAttachmentTypes?.restricted,
        ...adminAttachmentTypes?.completed,
      ]
    : [
        ...adminAttachmentTypes?.general,
        ...adminAttachmentTypes?.completed,
        ...adminAttachmentTypes?.restricted?.filter(
          (attachment) => !attachment?.label?.includes("PES")
        ),
      ];

export const getRequestedDocTypes = (requestedTypes) => {
  if (requestedTypes?.length) {
    const adminAttachmentTypeList = getAllUploadTypes();

    const requestedValues = requestedTypes?.map((item) => item?.requested_type);

    const requestedAttachments = adminAttachmentTypeList
      ?.filter((item) => requestedValues?.includes(item?.value))
      ?.map((item) => {
        return { label: item?.label, value: item?.value };
      });
    return requestedAttachments;
  } else {
    return [];
  }
};

export const getStatusLabel = (status, isComplete, count) => {
  if (!status) {
    if (isComplete) {
      return "Pending Review";
    }
    return "";
  } else if (status === "approve") {
    return "Approved";
  } else if (status === "reject") {
    return `Rejected ${count >= 0 ? `(${count + 1})` : ""}`;
  } else if (status === "resubmitted") {
    return `Pending Approval`;
  } else if (status === "uploaded") {
    return "Uploaded";
  } else {
    return "";
  }
};

export const fileSizeString = (bytes, si = true, dp = 1) => {
  if (!bytes) return;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const downloadHelper = (file, path, isPathExact = false) => {
  let filePath = "";
  if (isPathExact) {
    filePath = path;
  } else {
    filePath = path.split("/").pop().split("#")[1];
  }
  let blob = file;
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filePath);
  document.body.appendChild(link);

  link.click();
  link.parentNode.removeChild(link);
};

export const attachmentToCreateOnContractorRoles = [
  {
    attachment_id: 1,
    expiry_date: null,
    disabled: 0,
    label: "Contractor Liability Certificate of Insurance",
    value: "contractorLiability",
  },
  {
    attachment_id: 2,
    expiry_date: null,
    disabled: 0,
    label: "Contractor Workers Comp Certificate of Insurance",
    value: "contractorWorkers",
  },
  {
    attachment_id: 3,
    expiry_date: null,
    disabled: 0,
    label: "Contractor W-9",
    value: "contractorW9",
  },
  {
    attachment_id: 4,
    expiry_date: null,
    disabled: 0,
    label: "Haz Waste Haulers License",
    value: "hasWasteHauler",
  },
  {
    attachment_id: 5,
    expiry_date: null,
    disabled: 0,
    label: "Rate Sheet",
    value: "rateSheet",
  },
];

export const padZero = (n, width, z = "0") => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const fileTypes = [
  "5800.1 Form",
  "Analytical Results",
  "BOL",
  "SDS",
  "GIS Map",
  "Disposal Profile",
  "Manifest",
  "Response Authorization Email",
  "Response Authorization",
  "Situational Analysis",
  "Invoice & Report Package",
  "Photo",
  // 'Video',
  "NRC Report",
  "State Report",
  "Initial State Report",
  "Scope of Work",
  "Estimate",
  "Scale Ticket",
  "Email Conversation",
  "Closure Report to State Agency",
  "Misc",
];

export const REQUEST_TYPE = {
  NORMAL: "NORMAL",
  DOWNLOAD: "DOWNLOAD",
  UPLOAD: "UPLOAD",
  ANALYTICS: "ANALYTICS",
  ICS: "ICS",
};

export const RESPONSE_COUNT_TYPE = {
  WHOLE_COUNT: "whole_count",
  SEPARATE_COUNT: "separate_count",
  INDV_WHOLE_COUNT: "individual_count",
  INDV_SEPARATE_COUNT: "individual_separate_count",
};

export const CONTRACTOR_PROMPT_MESSAGE_TEXT = {
  ADD_CONTACTOR: "Add Contractor",
  ACCEPT_CONTRACTOR_RESPONSE: "Accept Contractor Response",
};

export const getAttachmentTypes = (serviceName) =>
  serviceName === Constants.COMPLETED_5800_FORM_SERVICE_NAME
    ? [fileTypes[0], ...fileTypes.slice(2)]
    : serviceName === Constants.ANALYTICAL_RESULTS_SERVICE_NAME
    ? fileTypes.slice(1)
    : fileTypes.slice(2);

export const getTotalCount = (obj) =>
  obj?.respond_count || 0 + obj?.reject_count || 0;

export const photoTypes = [
  "Initial",
  "Remedial Action",
  "Container Label",
  "PRO",
  "Final",
  "Waste",
  "Damage",
  "Custom",
];

export const Constants = {
  DELETION_SERVICE_NAME: "Deletion Of File Attachment",
  DOWNLOAD_ATTACHMENT_SERVICE_NAME: "File/Attachment Download",
  COMPLETED_5800_FORM_SERVICE_NAME: "Completed & Submitted 5800.1 Form",
  ANALYTICAL_RESULTS_SERVICE_NAME: "Analytical Results",
  EXCAVATION_BEGUN_SERVICE_NAME: "Excavation Begun",
  EXCAVATION_COMPLETED_SERVICE_NAME: "Excavation Completed",
  ORG_ID: 70, // Set the note time validation for R+L Carriers client
};

export const SPILL_STATUSES = {
  CLOSED_CLOSED_STATUS: "Closed: Closed",
};

export const RegxList = {
  HAVE_NON_DIGIT: /\D/g,
  HAVE_ALPHANUMERIC_DIGIT: /^[A-Za-z]+$/,
  NUMERIC_FOUR_DIGIT: /^[\d]{4}$/,
  NUMERIC_NINE_DIGIT: /^[\d]{1,9}$/,
  NUMERIC_DECIMAL_DIGIT: /^[-+]?([\d]{1,3})(((\.)(\d+)))$/,
  NUMERIC_DECIMAL_DIGIT_RATE: /^[-+]?([\d]{1,4})(((\.)(\d+)))$/,
  NUMERIC_DECIMAL_DIGIT_AMOUNT_RELEASED: /^[-+]?([\d]{1,9})(((\.)(\d+)))$/,
  NUMERIC_NINE_DIGIT_BEFORE_AND_AFTER_POINT: /^[0-9]{0,9}.?[0-9]{0,9}$/,
};

export const KEYS_TO_CHECK = {
  AGENCY: ['hazardous_material', 'hazardous_material_amount', 'petroleum_release', 'petroleum_release_amount', 'soil_impact', 'soil_impact_amount', 'water_impact', 'water_impact_amount'],
  CLIENT: ['hazardous_waste', 'non_hazardous_waste']
}

export const objectsHaveSameKeysAndValues = (obj1, obj2, keysToCheck) => {
  // Iterate through the keys to check and compare their values
  for (let key of keysToCheck) {
    const obj1Key = obj1[key]?.toString()?.trim() || null;
    const obj2Key = obj2[key]?.toString()?.trim() || null;
    if (obj1Key !== obj2Key) {
      return false;
    }
  }
  // If all keys and values match, return true
  return true;
}

export * from "./contractorLabel";
export * from "./csvDownloader";
export * from "./dataProcessors";
export * from "./dateTime";
export * from "./DropdownData";
export * from "./keyMappers";
export * from "./noteHistory";
export * from "./pathMapping";
export * from "./reactSelectSorter";
export * from "./request";
export * from "./csvDownloader";
