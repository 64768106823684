import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({

    button: {
        transition: 'all .4s',
        backgroundColor: theme.palette.primary.main,
        color: 'white',

        '&:hover': {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },
    },
    dialogHeader: {
        paddingTop: '10px',
        paddingBottom: '0'
    }

}));


const Popup = ({ open, handleClose, title, desc, onResponse }) => {

    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
                <Typography variant='h6'>
                    <b>{title}</b>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'}>{desc}</Typography>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => onResponse(false)}
                    variant={'outlined'}
                    color={'primary'}
                    className={classes.button}
                >
                    Cancel
                </Button>
                <Button onClick={() => onResponse(true)} variant={'outlined'}
                    color={'primary'}
                    className={classes.button}>
                    Keep Uploading
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Popup