export const search = (obj) => ({
  type: "SEARCH",
  obj,
});

export const searchSuccess = (data) => ({
  type: "SEARCH_SUCCESS",
  data,
});

export const searchFailure = (err) => ({
  type: "SEARCH_FAILURE",
  err,
});

export const clearSearch = () => ({
  type: "CLEAR_SEARCH",
});
