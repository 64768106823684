import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { WholeScreenModal, Prompt } from '../../../../../Components';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as spillActions from "../../../../../actionCreators/Spill";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  list: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    maxHeight: '300px',
    marginBottom: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
      background: '#f0f0f0', // Grey background color
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Grey thumb color
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.secondary.dark,
    },
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItemText: {
    fontSize: "16px"
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    marginBottom: theme.spacing(3),
  },
  notFound: {
    textAlign: "center",
    padding: "10px 0"
  },
  header: {
    display: "flex",
    justifyContent:"space-between",
  },
  closeIcon: {
    cursor: "pointer",
  },
  deleteButton: {
    transition: 'color 0.5s ease',
    '&:hover': {
      color: 'red',
    },
  },
}));

const MaterialClassPopup = ({ 
  response, 
  headingText,
  materialClass,
  deleteMaterialClass,
  deleteMaterialClassAction,
  deleteMaterialClassReset,
  updateMaterialClassOnDeleteSuccess,
  setSnackBarSeverity,
  setSnackBarMessage,
  setSnackBarOpen,
  setMaterialClassValue,
  materialClassValue,
  currentSpill
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMaterialClassData, setFilteredMaterialClassData] = useState(materialClass?.data ?? []);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [materialClassToBeDeleted, setMaterialClassToBeDeleted] = useState(null);

  useEffect(() => {
    const filteredMaterialClass = materialClass?.data?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );

    setFilteredMaterialClassData(filteredMaterialClass ?? [])
  }, [searchTerm])

  useEffect(() => {
    if(deleteMaterialClass?.success) {

      const updatedMaterialClass = materialClass?.data?.filter(materialClass => {
        return materialClass?.id !== materialClassToBeDeleted?.id
      })

      // If the current material class value is the deleted one, then reset the value
      if(materialClassValue === materialClassToBeDeleted?.name) {
        setMaterialClassValue("")
      }

      updateMaterialClassOnDeleteSuccess(updatedMaterialClass)
      setMaterialClassToBeDeleted(null)
      setSnackBarSeverity("success");
      setSnackBarMessage(
        "Material class deleted successfully!"
      );
      setSnackBarOpen(true);
      setConfirmationPopup(false)
      response(true);
    }

    if(deleteMaterialClass?.error) {
      setSnackBarSeverity("error");
      setSnackBarMessage(
        deleteMaterialClass?.error
      );
      setSnackBarOpen(true);
      setConfirmationPopup(false)
      setMaterialClassToBeDeleted(null)
    }

    deleteMaterialClassReset();

  }, [deleteMaterialClass?.success, deleteMaterialClass?.error])

  const handleDeleteConfirmation = () => {
    // Hit an API to delete the material class
    deleteMaterialClassAction({ id: materialClassToBeDeleted?.id, name: materialClassToBeDeleted?.name, spillId: currentSpill?.data?.spill?.id });
  }

  const handleDelete = (materialClass) => {
    // Open the new open for the confirmation if the deletion
    setMaterialClassToBeDeleted(materialClass)
    setConfirmationPopup(true)
  };

  return (
    <>
    <Box p={1}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.heading}>
        {headingText}
      </Typography>
      <CloseIcon fontSize='large' className={classes.closeIcon} onClick={() => response(true)} />
      </div>
      <TextField
        label="Search Material Classes"
        variant="outlined"
        className={classes.searchInput}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target?.value)}
      />
      <List className={classes.list}>
        {filteredMaterialClassData?.length ? filteredMaterialClassData?.map((item) => (
          <ListItem key={item.id} button className={classes.listItem}>
            <ListItemText>
              <Typography variant="body1" className={classes.listItemText}>{item.name}</Typography>
            </ ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(item)} className={classes.deleteButton}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )) : <Typography className={classes.notFound}>No material class found</Typography>}
      </List>
    </Box>

    <WholeScreenModal
        show={confirmationPopup}
        hide={() => setConfirmationPopup(false)}
      >
     <Prompt
          response={(response) => {
            response ? handleDeleteConfirmation() : setConfirmationPopup(false);
          }}
          headingText={"Delete Material Class"}
          question={"Are you sure you want to delete this material class?"}
          acceptText={"Yes"}
          rejectText={"No"}
        />
    </WholeScreenModal>
    </>
  );
};

const mapStateToProps = ({
  spill: { materialClass, deleteMaterialClass, currentSpill }
}) => {
  return {
    materialClass,
    deleteMaterialClass,
    currentSpill
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteMaterialClassAction: bindActionCreators(spillActions.deleteMaterialClass, dispatch),
  deleteMaterialClassReset: bindActionCreators(spillActions.deleteMaterialClassReset, dispatch),
  updateMaterialClassOnDeleteSuccess: bindActionCreators(spillActions.updateMaterialClassOnDeleteSuccess, dispatch)
})

MaterialClassPopup.propTypes = {
  materialClass: PropTypes.object.isRequired,
  deleteMaterialClass: PropTypes.object.isRequired,
  deleteMaterialClassAction: PropTypes.func.isRequired,
  deleteMaterialClassReset: PropTypes.func.isRequired,
  updateMaterialClassOnDeleteSuccess: PropTypes.func.isRequired,
  currentSpill: PropTypes.object.isRequired,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaterialClassPopup)
);
