import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { REPORTING_REQUIREMENTS_LABEL } from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: "left",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  customLable: {
    color: "#444444",
    fontSize: "15px",
    marginTop: "16px",
    marginBottom: "0px",
    display: "inline-block",
  },
  ptb: {
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
  },
  header: {
    color: "green",
    textAlign: "left",
  },
}));
const ReportingRequirements = ({
  onSubmit,
  onChange,
  formData,
  onDateChange,
  editMode,
  lastRevisionDate,
  selectedDate,
  setSelectedDate
}) => {
  const classes = useStyles();
  const handleDateChange = (date) => {
    const dateOnly = date ? moment(date)?.format('YYYY-MM-DD') : moment()?.format('YYYY-MM-DD');
    onDateChange(dateOnly);
    onChange((prevFormData) => ({
      ...prevFormData,
      revision_date: dateOnly,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (REPORTING_REQUIREMENTS_LABEL.includes(name)) {
      if (value < 0) {
        return;
      }
    }
    
    onChange((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={2} className={classes.mainContainer}>
        {/* petroleum releases */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Petroleum Releases:</label>
        </Grid>
        <Grid item md={3} className={classes.ptb}>
          <TextField
            id="petroleum_release_amount"
            name="petroleum_release_amount"
            label="Amount"
            type="number"
            fullWidth
            size="small"
            value={formData?.petroleum_release_amount}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        <Grid item className={classes.ptb} md={9}>
          <TextField
            id="petroleum_release"
            name="petroleum_release"
            label="Requirements"
            multiline
            rowsMax={4}
            fullWidth
            size="small"
            value={formData?.petroleum_release}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        {/* Hazardous Material Release */}
        <Grid item xs={12}>
          <label className={classes.customLable}>
            Hazardous Material Release:
          </label>
        </Grid>
        <Grid item md={3} className={classes.ptb}>
          <TextField
            id="hazardous_material_amount"
            name="hazardous_material_amount"
            label="Amount"
            type="number"
            fullWidth
            size="small"
            value={formData?.hazardous_material_amount}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        <Grid item className={classes.ptb} md={9}>
          <TextField
            id="hazardous_material"
            name="hazardous_material"
            label="Requirements"
            multiline
            rowsMax={4}
            fullWidth
            size="small"
            value={formData?.hazardous_material}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        {/* Water Impact */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Water Impact:</label>
        </Grid>
        <Grid item md={3} className={classes.ptb}>
          <TextField
            id="water_impact_amount"
            name="water_impact_amount"
            label="Amount"
            type="number"
            fullWidth
            size="small"
            value={formData?.water_impact_amount}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        <Grid item className={classes.ptb} md={9}>
          <TextField
            id="water_impact"
            name="water_impact"
            label="Requirements"
            multiline
            rowsMax={4}
            fullWidth
            size="small"
            value={formData?.water_impact}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        {/* Soil Impact */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Soil Impact:</label>
        </Grid>
        <Grid item md={3} className={classes.ptb}>
          <TextField
            id="soil_impact_amount"
            name="soil_impact_amount"
            label="Amount"
            type="number"
            fullWidth
            size="small"
            value={formData?.soil_impact_amount}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        <Grid item className={classes.ptb} md={9}>
          <TextField
            id="soil_impact"
            name="soil_impact"
            label="Requirements"
            multiline
            rowsMax={4}
            fullWidth
            size="small"
            value={formData?.soil_impact}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customFormcontrol}
          />
        </Grid>
        {/* Revision Date Field: */}
        <Grid item xs={12}>
          <label className={classes.customLable}>Revision Date Field:</label>
        </Grid>
        <Grid item className={classes.ptb} md={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              label="Date"
              fullWidth
              size="small"
              className={classes.customFormcontrol}
              format="MM-DD-YYYY"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </MuiPickersUtilsProvider>
          {editMode && (
            <p className={lastRevisionDate == null ? "hidden" : "show"}>
              Last Revision Date is:<b> {lastRevisionDate}</b>
            </p>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ReportingRequirements;
