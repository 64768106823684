import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  createNewClientSuccess,
  createNewClientFailure,
  editClientSuccess,
  editClientFailure,
  getClientsSuccess,
  getClientsFailure,
  getOrganizationNamesSuccess,
  getOrganizationNamesFailure,
  getParentsSuccess,
  getParentsFailure,
  getOrganization,
  getOrganizationSuccess,
  getOrganizationFailure,
  getChildrenSuccess,
  getChildrenFailure,
  getFacilitySuccess,
  getFacilityFailure,
  getDisableContaractorSuccess,
  getDisableContaractorFailure,
  editFacilitySuccess,
  editFacilityFailure,
  deleteFacilitySuccess,
  deleteFacilityFailure,
  getIcsTerminalSuccess,
  getIcsTerminalFailure,
  getIcsTeamTerminalSuccess,
  getIcsTeamTerminalFailure,
} from "../actionCreators/Client";

import { doDelete, doGet, doPatch, doPost } from "../utils/request";
import { REQUEST_TYPE } from '../utils/index';

function* getClients({ obj }) {
  try {
    const response = yield doGet(`/client/organizations?page=${obj.page}`);
    yield put(getClientsSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getClientsFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getClientsFailure());
  }
}

function* getParents({ obj }) {
  try {
    const response = yield doGet(`/client/parents?id=${obj.id}`);
    yield put(getParentsSuccess(response.data));
  } catch (err) {
    yield put(getParentsFailure(err.response.data.message || err.message));
    yield put(getParentsFailure());
  }
}

function* getClientChildren({ obj }) {
  try {
    const response = yield doGet(`/client/children?id=${obj.id}`);
    yield put(getChildrenSuccess(response.data));
  } catch (err) {
    yield put(getChildrenFailure(err.response.data.message || err.message));
    yield put(getChildrenFailure());
  }
}

function* createNewClient({ obj }) {
  try {
    const response = yield doPost("/client/createNew", obj);
    yield put(createNewClientSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(createNewClientFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(createNewClientFailure());
  }
}

function* editClient({ obj }) {
  try {
    const response = yield doPost("/client/editClientOrganizations", obj);
    yield put(editClientSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editClientFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editClientFailure());
  }
}

function* getOrganizationNames({ obj }) {
  try {
    const response = yield doGet("/client/allOrganizationNames");

    yield put(getOrganizationNamesSuccess(response.data.organizations));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getOrganizationNamesFailure(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
    yield put(getOrganizationNamesFailure());
  }
}

function* getClient({ obj }) {
  try {
    const response = yield doGet(`/client/${obj.id}`);
    yield put(getOrganizationSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getOrganizationFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getOrganizationFailure());
  }
}

function* getFacility({ obj }) {
  try {
    const response = yield doGet(`/client/facilities/${obj?.org_id}${obj?.type ? `?type=${obj?.type}`: '' }`);
    yield put(getFacilitySuccess(response?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getFacilityFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getFacilityFailure());
  }
}

function* editFacility({ obj }) {
  try {
    const response = yield doPatch("/client/update-facility", obj);
    yield put(editFacilitySuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editFacilityFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editFacilityFailure());
  }
}
function* deleteFacility({ obj }) {
  try {
    const response = yield doDelete("/client/facility",obj);
    yield put(deleteFacilitySuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(deleteFacilityFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(deleteFacilityFailure());
  }
}
function* getDisableContaractor({obj}) {
  try {
    const response = yield doGet(`/client/contractor/${obj?.org_id}`);
    yield put(getDisableContaractorSuccess(response.data?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getDisableContaractorFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getDisableContaractorFailure());
  }
}

function* getIcsTerminal({obj}) {
  try {
    const response = yield doPost(
      "client-terminal",
      obj,
      null,
      REQUEST_TYPE.ICS
    );
    yield put(getIcsTerminalSuccess(response?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getIcsTerminalFailure(err?.response?.data?.message || "There is no client with this name in ICS." ));
    yield put(getIcsTerminalSuccess({}));
    yield call(delay, 5000);
    yield put(getIcsTerminalFailure());
  }
}


function* getIcsTeamTerminal({obj}) {
  try {
    const response = yield doPost(
      "team-terminal",
      obj,
      null,
      REQUEST_TYPE.ICS
    );
    yield put(getIcsTeamTerminalSuccess(response?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getIcsTeamTerminalFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getIcsTeamTerminalFailure());
  }
}
export default function* watcher() {
  yield all([takeLatest("GET_CLIENTS", getClients)]);
  yield all([takeLatest("GET_PARENTS", getParents)]);
  yield all([takeLatest("GET_CHILDREN", getClientChildren)]);
  yield all([takeLatest("CREATE_NEW_CLIENT", createNewClient)]);
  yield all([takeLatest("EDIT_CLIENT", editClient)]);
  yield all([takeLatest("GET_ORGANIZATION_NAMES", getOrganizationNames)]);
  yield all([takeLatest("GET_ORGANIZATION", getClient)]);
  yield all([takeLatest("GET_FACILITY", getFacility)]);
  yield all([takeLatest("GET_DISABLE_CONTRACTOR", getDisableContaractor)]);
  yield all([takeLatest("EDIT_FACILITY", editFacility)]);
  yield all([takeLatest("DELETE_FACILITY", deleteFacility)]);
  yield all([takeLatest("GET_ICS_TERMINAL", getIcsTerminal)]);
  yield all([takeLatest("GET_ICS_TEAM_TERMINAL", getIcsTeamTerminal)]);

}
