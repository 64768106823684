import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

const CurrencyFieldInput = ({
  decimalScale,
  allowNegative,
  currencySymbol,
  decimalSeparator,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState();

  return (
    <NumberFormat
      {...props}
      variant='standard'
      isNumericString={true}
      customInput={TextField}
      style={{ width: '100%' }}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      decimalSeparator={decimalSeparator}
      onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
    />
  );
};

CurrencyFieldInput.defaultProps = {};

export default CurrencyFieldInput;
