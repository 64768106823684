import { ROLES } from './keyMappers';
import { isPESUser, isPESUser_2 } from '.';
export const pathNames = [
  'home',
  'top',
  'spills',
  'search',
  'profile',
  'admin',
  'agencies',
  'contractors',
  'clients',
  'users',
  'services',
  'actions',
  'search-results',
  'batch-status',
  'spills/view',
  'spills/edit',
  'analytics',
  'reports',
  'packet-assignment',
  'reports/custom',
  'reports/subrogation',
  'reports/invoice-download',
  'reports/shipper',
  'reports/contractor-rejection',
];

export const validPaths = (role) => {
  if (role?.permission) {
    let permissions = role.permission;
    let paths = ['profile'];
    if (permissions.view_spills) {
      paths.push('spills');
      paths.push('search');
      paths.push('search-results');
      paths.push('batch-status');
      paths.push('packet-assignment');
      paths.push('reports');
      paths.push('reports/custom');
      paths.push('reports/subrogation');
      paths.push('reports/invoice-download');
      paths.push('reports/shipper');
      paths.push('reports/contractor-rejection');

      paths.push('spills/view');
      paths.push('analytics');
    }
    if (permissions.view_packet_reviewer_spills) {
      paths.push('packet-assignment');
    }
    if (permissions.edit_spills) {
      paths.push('spills/edit');
    }
    if (permissions.view_contractors) {
      paths.push('contractors');
    }
    if (permissions.view_agencies) {
      paths.push('agencies');
    }
    if (permissions.view_client) {
      paths.push('clients');
    }
    if (permissions.view_users) {
      paths.push('users');
    }
    if (permissions.view_services) {
      paths.push('services');
    }
    if (isPESUser_2(role.role)) {
      paths.push('admin');
    }
    if (isPESUser(role.role)) {
      paths.push('actions');
    }
    if (![ROLES.SUPER_USER, ROLES.PROB_PM].includes(role.role)) {
      paths.push('home');
    }
    return paths;
  } else {
    return [];
  }
};
