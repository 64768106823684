import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Paper, Tab, Tabs, AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import AdminForm from "./AdminForm";
import {
  getContractorLabel,
  isCorporateUser,
  isPESUser,
} from "../../../../../utils";

const useStyles = makeStyles(() => ({
  innerAppBar: {
    margin: "5px 0 1rem 0",
  },
  innerTabPanel: {
    padding: "1rem 3rem",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Paper>{children}</Paper>}
    </div>
  );
}

const AdminTab = ({
  admin,
  showAdmin,
  setAdmin,
  currentUser,
  selectedFiles,
  contractorInv,
  attachmentTypes,
  setContractorInv,
  contractorOptions,
  updatedFilesState,
  attachmentHistory,
  onFileCancelHandler,
  onFileChangeHandler,
  handleCompleteClick,
  missingInvoiceAlert,
  setSnackbarMessageCb,
  setMissingInvoiceAlert,
  updateRejectedAttachment,
  viewAdminAttachmentHistory,
  partialBillingDownloadData
}) => {
  const classes = useStyles();
  const [innerTabValue, setInnerTabValue] = useState(0);

  function a11yProps(index) {
    return {
      id: `edit-tab-${index}`,
      "aria-controls": `edit-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    for (let i = 0, length = admin.length; i < length; i++) {
      if (
        !currentUser?.data?.role?.role.includes("Contractor") ||
        (!admin[i].isRemoved && showAdmin(admin[i], currentUser.data))
      ) {
        setInnerTabValue(i);
        return;
      }
    }
  }, []);

  return (
    <div>
      <AppBar position="static" color="default" className={classes.innerAppBar}>
        <Tabs
          value={innerTabValue}
          onChange={(e, newVal) => setInnerTabValue(newVal)}
          aria-label="edit admin tabs"
          variant="scrollable"
          className="customTab"
        >
          {currentUser?.data &&
            admin.map((data, index) => {
              if (
                !currentUser?.data?.role?.role.includes("Contractor") ||
                (!data.isRemoved && showAdmin(data, currentUser.data))
              ) {
                return (
                  <Tab
                    key={index}
                    label={`${getContractorLabel(
                      data,
                      contractorOptions,
                      data?.isNotRequired
                    )}`}
                    {...a11yProps(index)}
                    value={index}
                  ></Tab>
                );
              }
              return null;
            })}
        </Tabs>
      </AppBar>
      {currentUser?.data &&
        admin.map((data, index) => {
          if (
            !currentUser?.data?.role?.role.includes("Contractor") ||
            (!data.isRemoved && showAdmin(data, currentUser.data))
          ) {
            return (
              <TabPanel value={innerTabValue} index={index}>
                <div className={classes.innerTabPanel}>
                  <AdminForm
                    setSnackbarMessageCb={setSnackbarMessageCb}
                    index={index}
                    admin={admin}
                    adminId={admin[index]["adminId"]}
                    setAdmin={setAdmin}
                    selectedFiles={selectedFiles}
                    contractorInv={contractorInv}
                    attachmentTypes={attachmentTypes}
                    setContractorInv={setContractorInv}
                    isInactive={admin[index]?.isInactive}
                    attachmentHistory={attachmentHistory}
                    userName={currentUser?.data?.full_name}
                    onFileCancelHandler={onFileCancelHandler}
                    onFileChangeHandler={onFileChangeHandler}
                    handleCompleteClick={handleCompleteClick}
                    missingInvoiceAlert={missingInvoiceAlert}
                    setMissingInvoiceAlert={setMissingInvoiceAlert}
                    updateRejectedAttachment={updateRejectedAttachment}
                    isUserAdmin={isPESUser(currentUser?.data?.role?.role)}
                    isCorporateUser={isCorporateUser(
                      currentUser?.data?.role?.role
                    )}
                    viewAdminAttachmentHistory={viewAdminAttachmentHistory}
                    partialBillingDownloadData={partialBillingDownloadData}
                  />
                </div>
              </TabPanel>
            );
          }
          return null;
        })}
    </div>
  );
};

const mapStateToProps = ({ user, spill }) => ({
  currentUser: user.currentUser,
  currentSpill: spill.currentSpill,
});

AdminForm.propTypes = {
  admin: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  showAdmin: PropTypes.bool.isRequired,
  setAdmin: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onFileCancelHandler: PropTypes.func.isRequired,
  onFileChangeHandler: PropTypes.func.isRequired,
  handleCompleteClick: PropTypes.func.isRequired,
  contractorOptions: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(AdminTab);
