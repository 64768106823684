import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  mainHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-end",
  },
  mainHeaderHeading: {
    fontSize: "24px",
    color: "black",
    marginTop: "15px",
    marginRight: "15px",
  },

  mainHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: 9,
    alignContent: "flex-end",
  },
  logo: {
    color: "green",
    fontSize: "48px",
    fontWeight: 900,
    marginBottom: "10px",
  },
  mainHeaderSubContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    marginTop: 12,
  },
  subHeading: {
    fontSize: "19px",
    color: "black",
  },
  subHeadingDesc: {
    fontSize: "12px",
    fontWeight: 300,
  },
  title: {
    backgroundColor: "#f6f6f5",
  },
  noteContainer: {
    borderTop: "1px solid grey",
    display: "flex",
    marginTop: "30px",
    paddingTop: "20px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerHeading: {
    marginRight: "20px",
    fontSize: 16,
    marginLeft: "20px",
  },
  headerValue: {
    color: "green",
    fontWeight: 800,
    fontSize: 14,
    maxWidth: "60%",
    marginRight: "20px",
  },
  editContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid grey",
    margin: 5,
  },
  editSubContainer: {
    display: "flex",
    width: "45%",
    flexDirection: "column",
    borderRight: "1px solid grey",
    marginTop: 5,
    marginBottom: 5,
  },
  editContainerHeader: {
    display: "flex",
    flexDirection: "row",
    margin: 5,
  },
  editHeaderHeading: {
    fontSize: 12,
  },
  editHeaderValue: {
    fontSize: 13,
    height: 20,
  },
  fieldNameContainer: {
    display: "flex",
    flexDirection: "row",
    margin: 10,
  },
  fieldNameHeading: {
    width: "40%",
    marginRight: 5,
    fontSize: 13,
  },
  fieldNameValue: {
    textAlign: "left",
    marginLeft: 5,
    color: "green",
    fontSize: 12,
  },
  changesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginLeft: 10,
  },
  changeValuePair: {
    display: "flex",
    width: "50%",
    flexDirection: "row",
    columnGap: "5px",
  },
  changeHeading: {
    color: "black",
    fontSize: 13,
  },
  changeValue: {
    color: "green",
    fontSize: 12,
    maxWidth: "40%",
  },
});

export default function HistoryPdf({ data }) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.mainHeader}>
          <View style={styles.mainHeaderContainer}>
            <Text style={styles.logo}>PES</Text>
            <View style={styles.mainHeaderSubContainer}>
              <Text style={styles.subHeading}>Spills</Text>
              <Text style={styles.subHeadingDesc}>
                Managed by Premium Environmental Services
              </Text>
            </View>
          </View>
          <View style={styles.mainHeaderContainer}>
            <Text style={styles.mainHeaderHeading}>Notes History</Text>
          </View>
        </View>
        <View>
          {data
            ? data.map((note, key) => {
                return (
                  <View key={key}>
                    <View key={key} style={styles.noteContainer}>
                      <Text style={styles.headerHeading}>Note Id : </Text>
                      <Text style={styles.headerValue}>{note.noteId}</Text>
                    </View>

                    <View>
                      {note.edits.map((edit, key1) => {
                        return (
                          <View key={key1} style={styles.editContainer}>
                            <View style={styles.editSubContainer}>
                              <View style={styles.editContainerHeader}>
                                <Text style={styles.editHeaderHeading}>
                                  Time :
                                </Text>
                                <Text style={styles.editHeaderValue}>
                                  {edit.updatedAt}
                                </Text>
                              </View>
                              <View style={styles.editContainerHeader}>
                                <Text style={styles.editHeaderHeading}>
                                  Updated by:
                                </Text>
                                <Text style={styles.editHeaderValue}>
                                  {edit.updatedBy}
                                </Text>
                              </View>
                            </View>
                            <View>
                              {edit.changes.map((change, key2) => {
                                return (
                                  <View key={key2}>
                                    <View style={styles.fieldNameContainer}>
                                      <Text style={styles.fieldNameHeading}>
                                        Attribute Changed :
                                      </Text>
                                      <Text style={styles.fieldNameValue}>
                                        {change.fieldChanged}
                                      </Text>
                                    </View>
                                    <View style={styles.changesContainer}>
                                      <View style={styles.changeValuePair}>
                                        <Text style={styles.changeHeading}>
                                          From :
                                        </Text>
                                        <Text style={styles.changeValue}>
                                          {change.prevVal ?? "N/A"}
                                        </Text>
                                      </View>
                                      <View style={styles.changeValuePair}>
                                        <Text style={styles.changeHeading}>
                                          To :
                                        </Text>
                                        <Text style={styles.changeValue}>
                                          {change.newVal ?? "N/A"}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                );
                              })}
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })
            : ""}
        </View>
      </Page>
    </Document>
  );
}
