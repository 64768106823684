export const getActions = () => ({
  type: "GET_ACTIONS",
});

export const getActionsSuccess = (data) => ({
  type: "GET_ACTIONS_SUCCESS",
  data,
});

export const getActionsFail = (error) => ({
  type: "GET_ACTIONS_FAIL",
  error,
});

export const createNewAction = (obj) => ({
  type: "CREATE_NEW_ACTION",
  obj,
});

export const createNewActionSuccess = (data) => ({
  type: "CREATE_NEW_ACTION_SUCCESS",
  data,
});

export const createNewActionFail = (error) => ({
  type: "CREATE_NEW_ACTION_FAIL",
  error,
});

export const editAction = (obj) => ({
  type: "EDIT_ACTION",
  obj,
});

export const editActionSuccess = (data) => ({
  type: "EDIT_ACTION_SUCCESS",
  data,
});

export const editActionFail = (error) => ({
  type: "EDIT_ACTION_FAIL",
  error,
});
