import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SpillSummary from './Summary';
import SubmittedPackets from './SubmittedPackets';
import { ROLES } from '../../../../../utils/keyMappers';

const Landing = ({ user, history }) => {
  const [showSummary, setShowSummary] = React.useState(true);
  document.title = 'Home';

  const getUserType = (user) => {
    if (!user.data?.role) {
      return '';
    } else if ([ROLES.PROB_PM].includes(user.data.role.role)) {
      return 'super';
    } else if (
      [ROLES.CONTRACTOR_ADMIN, ROLES.CORPORATE_ADMIN, ROLES.PES_ADMIN].includes(
        user.data.role.role
      )
    ) {
      return 'admin';
    } else if ([ROLES.SUPER_USER].includes(user.data.role.role)) {
      return 'super';
    } else {
      return 'user';
    }
  };
  const userType = getUserType(user);
  return (
    <div>
      {showSummary &&
        (userType === 'user' ||
          userType === 'admin' ||
          userType === 'super') && <SpillSummary></SpillSummary>}
      {['admin', 'super', 'user'].includes(userType) && (
        <>
          <SubmittedPackets
            showSummary={showSummary}
            setShowSummary={setShowSummary}
          ></SubmittedPackets>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.currentUser,
});

export default withRouter(connect(mapStateToProps)(Landing));
