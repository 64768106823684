import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import FileUpload from "./FileUpload";
import Eye from "../../../../../assets/view.png";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const TeamTerminal = ({ file, setFile, TeamData, fileUpload ,editMode, isNotICS, totalTerminals,setTotalTerminals}) => {
  const classes = useStyles();
  const [expandedAccordions, setExpandedAccordions] = React.useState([true]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);

  const handleClickOpen = (rowData) => {
    setSelectedRowData(rowData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const handleAccordionChange = (index) => {
    const newExpandedAccordions = [...expandedAccordions];
    newExpandedAccordions[index] = !expandedAccordions[index];
    setExpandedAccordions(newExpandedAccordions);
  };
  return (
    <>
      <div>
        {fileUpload == true ? (
        <FileUpload file={file} setFile={setFile} editMode={editMode} TeamData={TeamData} isNotICS={isNotICS} totalTerminals={totalTerminals} setTotalTerminals={setTotalTerminals}></FileUpload>
        ) : null}
        {loading ? (
          <p>
            <CircularProgress />
          </p>
        ) : Object.keys(TeamData).length > 0 ? (
          Object.keys(TeamData).map((key, index) => (
            <Accordion
              className="Team_Acoordion"
              key={key}
              expanded={expandedAccordions[index]}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{key}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <div className="terminal-table">
                    <Table aria-label="simple table" className="team-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Terminal Name</TableCell>
                          <TableCell align="left">EPA</TableCell>
                          <TableCell align="left">Phone Number</TableCell>
                          <TableCell align="left">State</TableCell>
                          <TableCell align="left">City</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {TeamData[key] && TeamData[key].length > 0 ? (
                          TeamData[key].map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {" "}
                                {item?.terminalName ? item?.terminalName : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {item?.epa ? item?.epa : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {item?.phone ? item?.phone : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {item?.state ? item?.state : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {item?.city ? item?.city : "-"}
                              </TableCell>
                              <TableCell>
                                <img
                                  src={Eye}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClickOpen(item)}
                                ></img>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8}>
                              No data available for
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <p>No data available</p>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="team-terminal-dialog"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              textAlign: "center",
              color: "#2a5014",
              fontWeight: "700",
            }}
          >
            {"Terminal Information"}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                {selectedRowData && (
                  <>
                    <table>
                      <tr>
                        <th>Team Name :</th>
                        <td>
                          {selectedRowData?.team ? selectedRowData?.team : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th> Terminal Name :</th>
                        <td>
                          {selectedRowData?.terminalName
                            ? selectedRowData?.terminalName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Abbreviation :</th>
                        <td>
                          {selectedRowData?.abbreviation
                            ? selectedRowData?.abbreviation
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>EPA :</th>
                        <td>
                          {selectedRowData?.epa ? selectedRowData?.epa : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Phone Number :</th>
                        <td>
                          {selectedRowData?.phone
                            ? selectedRowData?.phone
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>State :</th>
                        <td>
                          {selectedRowData?.state
                            ? selectedRowData?.state
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Address :</th>
                        <td>
                          {selectedRowData?.address
                            ? selectedRowData?.address
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>City :</th>
                        <td>
                          {selectedRowData?.city ? selectedRowData?.city : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Zipcode :</th>
                        <td>
                          {selectedRowData?.zip ? selectedRowData?.zip : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Country :</th>
                        <td>
                          {selectedRowData?.country ? selectedRowData?.country : "-"}
                        </td>
                      </tr>
                    </table>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default TeamTerminal;
