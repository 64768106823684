import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import HomeIcon from "@material-ui/icons/Home";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import FolderSharedRoundedIcon from "@material-ui/icons/FolderSharedRounded";
import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";
import PollIcon from '@material-ui/icons/Poll';
import { getCurrentUser } from "../../../actionCreators/User";
import { isSUPERUSER, isCorporateAdmin, isPESUser_2 } from "../../../utils";
import { ROLES } from "../../../utils/keyMappers";

const useStyles = makeStyles((theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
}));

function Header(props) {
  const classes = useStyles();
  const { valueReturner } = props;
  const { currentUser } = props.user;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMoveSection = (newValue) => {
    valueReturner(newValue);
  };

  return (
    <React.Fragment>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      ></AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={value} textColor="inherit" onChange={handleChange}>
          <Tab
            textColor="inherit"
            label={
              <div style={{ display: "flex" }}>
                <HomeIcon style={{ marginRight: 10 }} /> Home
              </div>
            }
            onClick={() => handleMoveSection(0)}
          />

          {/* <Tab
            textColor="inherit"
            label={
              <div style={{ display: "flex" }}>
                <ArrowUpwardRoundedIcon style={{ marginRight: 10 }} /> Top
              </div>
            }
            onClick={() => handleMoveSection(1)}
          /> */}
          <Tab
            textColor="inherit"
            label={
              <div style={{ display: "flex" }}>
                <WarningIcon style={{ marginRight: 10 }} /> Spills
              </div>
            }
            onClick={() => handleMoveSection(2)}
          />
          <Tab
            textColor="inherit"
            label={
              <div style={{ display: "flex" }}>
                <FolderSharedRoundedIcon style={{ marginRight: 10 }} /> My
                Profile
              </div>
            }
            onClick={() => {
              props.getCurrentUser();
              handleMoveSection(4);
            }}
          />
          {currentUser && isPESUser_2(currentUser.data?.role?.role) ? (
            <Tab
              textColor="inherit"
              label={
                <div style={{ display: "flex" }}>
                  <SettingsApplicationsRoundedIcon
                    style={{ marginRight: 10 }}
                  />{" "}
                  Admin
                </div>
              }
              onClick={() => handleMoveSection(5)}
            />
          ) : null}
          {(isSUPERUSER(currentUser?.data?.role?.role) || isCorporateAdmin(currentUser?.data?.role?.role)) && (
            <Tab
              textColor="inherit"
              label={
                <div style={{ display: "flex" }}>
                  <PollIcon
                    style={{ marginRight: 10 }}
                  />
                  Analytics
                </div>
              }
              onClick={() => handleMoveSection(16)}
            />)}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
});

Header.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
