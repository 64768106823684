import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import * as spillActions from "../../../../../actionCreators/Spill";
import { orderAlphabaticallyByKey } from "../../../../../utils/contractorLabel";
import CurrencyFieldInput from "../../../../../Components/CurrencyFieldInput";
import { RegxList } from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  attachmentImage: {
    margin: "1rem",
    border: "1px solid black",
    display: "flex",
  },
  alignRight: {
    textAlign: "right",
    paddingRight: "20px",
  },
}));

const EditReserve = ({
  closeReserveForm,
  admins,
  currentSpillId,
  currentReserve,
  editReserves,
  disabled,
}) => {
  const classes = useStyles();

  const [reserve, setReserve] = useState({
    id: currentReserve.id,
    spillId: currentSpillId,
    userId: "",
    amount: "",
  });
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (reserve.userId) {
      const [admin] = admins.filter((admin) => admin.id === reserve.userId);

      setSelectedAdmin(admin);
    }
  }, [reserve.userId]);

  useEffect(() => {
    setReserve((val) => {
      return {
        ...val,
        userId: currentReserve.user_id || null,
        amount: currentReserve.amount || null,
      };
    });
  }, [currentReserve]);

  const checkValidation = (key, newReserve) => {
    if (["amount"].includes(key)) {
      if (newReserve.amount && key === "amount") {
        if (
          key === "amount" &&
          newReserve.amount.includes(".") &&
          RegxList.NUMERIC_DECIMAL_DIGIT.test(newReserve.amount)
        ) {
          setReserve({ ...newReserve, amount: newReserve.amount });
          setValidationErrors({
            ...validationErrors,
            amount: null,
          });
        } else if (
          (key === "amount" &&
            !RegxList.NUMERIC_DECIMAL_DIGIT.test(newReserve.amount)) ||
          !newReserve.amount.includes(".")
        ) {
          setValidationErrors({
            ...validationErrors,
            amount: {
              msg: "This Field Only Recognizes Decimal values. Please Enter a Valid Decimal Amount",
            },
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            amount: null,
          });
        }
      }
    }
  };

  const handleNoteChange = (key) => (event) => {
    setReserve((val) => {
      const newReserve = { ...val, [key]: event.target.value };
      checkValidation(key, newReserve);
      return newReserve;
    });
  };

  const handleSubmit = () => {
    if (reserve.userId === "" || reserve.amount === "") {
      return;
    }

    const reserveData = {
      id: reserve.id,
      user_id: reserve.userId,
      spill_id: reserve.spillId,
      amount: Number(reserve.amount),
    };

    editReserves(reserveData);
    closeReserveForm();
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <form style={{ width: "100%" }}>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <InputLabel id="manager_select_label">Approved by</InputLabel>
              <Select
                labelId="manager_select_label"
                id="manager_select"
                name="manager"
                style={{ width: "100%" }}
                inputProps={{
                  value: reserve.userId,
                }}
                onChange={handleNoteChange("userId")}
                disabled={disabled}
              >
                {admins &&
                  orderAlphabaticallyByKey(admins, "full_name").map((e) => {
                    return <MenuItem value={e.id}>{e.full_name}</MenuItem>;
                  })}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <CurrencyFieldInput
                name="amount"
                label="Amount $"
                decimalScale={2}
                disabled={disabled}
                allowNegative={false}
                value={reserve?.amount}
                onChange={handleNoteChange("amount")}
                helperText={validationErrors.latitude?.msg || ""}
                error={validationErrors.latitude?.showError}
                inputProps={{
                  maxLength: 8, 
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Update
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => closeReserveForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ spill: { currentSpill }, user }) => {
  return {
    currentSpill,
    disabled: !user.currentUser?.data?.role?.permission?.edit_complete_note,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editReserves: bindActionCreators(spillActions.editReserves, dispatch),
});

EditReserve.proptype = {
  closeReserveForm: PropTypes.func.isRequired,
  editReserves: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditReserve)
);
