const initialState = {
  success: false,
  loading: false,
  error: null,
  currentClient: {
    data: null,
    loading: false,
    error: null,
  },
  clients: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  clientParent: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  clientChildren: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  clientOrganizationNames: {
    data: [],
    loading: false,
    error: null,
  },
  facilities: {
    data: [],
    loading: false,
    error: null,
  },
  deleteFacility: {
    data: {},
    loading: false,
    error: null,
  },
  disableClientContractors: {
    data: [],
    loading: false,
    error: null,
  },
  clientTerminal: {
    data: {},
    loading: false,
    error: null,
  },
  clientTeamTerminal: {
    data: [],
    loading: false,
    error: null,
  },
};

const clientReducer = (
  state = initialState,
  { obj, data, type, error, payload, pagination }
) => {
  switch (type) {
    case "RESENT_CLIENT":
      return initialState;

    case "GET_CLIENTS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_CLIENTS_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        clients: {
          ...state.clients,
          data: data.data,
          pagination: { ...data.pagination },
        },
      };

    case "GET_CLIENTS_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_PARENTS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_PARENTS_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        clientParent: {
          ...state.clientParent,
          data: data,
        },
      };

    case "GET_PARENTS_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_CHILDREN":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_CHILDREN_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        clientChildren: {
          data: data.organizations,
        },
      };

    case "GET_CHILDREN_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CREATE_NEW_CLIENT":
      return {
        ...state,
        type,
        loading: true,
      };

    case "CREATE_NEW_CLIENT_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        clients: {
          ...state.clients,
          data: [data, ...state.clients.data],
        },
      };

    case "CREATE_NEW_CLIENT_FAILURE":
      return {
        ...state,
        type,
        success: false,
        loading: false,
        error,
      };

    case "EDIT_CLIENT":
      return {
        ...state,
        type,
        loading: true,
        error: null,
      };

    case "EDIT_CLIENT_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        type,
        payload,
        clients: {
          ...state.clients,
          data: state.clients.data.map((clientToUpdate) => {
            if (clientToUpdate.id === data.id) {
              clientToUpdate = data;
            }
            let updatedClient = { ...clientToUpdate };
            return updatedClient;
          }),
        },
      };

    case "EDIT_CLIENT_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    default:
      return state;

    case "GET_ORGANIZATION_NAMES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_ORGANIZATION_NAMES_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        clientOrganizationNames: {
          ...state.clientOrganizationNames,
          data: data,
        },
      };

    case "GET_ORGANIZATION_NAMES_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_ORGANIZATION":
      return {
        ...state,
        type,
        loading: true,
        currentClient: {
          data: null,
          loading: true,
          error: null,
        },
      };

    case "GET_ORGANIZATION_SUCCESS":
      return {
        ...state,
        type,
        loading: false,
        success: true,
        currentClient: {
          ...state.currentClient,
          data: data,
          loading: false,
        },
      };

    case "GET_ORGANIZATION_FAILURE":
      return {
        ...state,
        type,
        loading: false,
        success: false,
      };

    case "GET_FACILITY":
      return {
        ...state,
        type,
        loading: true,
        success: false,
      };

    case "GET_FACILITY_SUCCESS":
      return {
        ...state,
        type,
        loading: false,
        success: true,
        facilities: data,
      };

    case "GET_FACILITY_FAILURE":
      return {
        ...state,
        type,
        loading: false,
        success: false,
      };

    case "GET_DISABLE_CONTRACTOR":
      return {
        ...state,
        type,
        loading: true,
        currentClient: {
          data: null,
          loading: true,
          error: null,
        },
      };
   
    case "GET_DISABLE_CONTRACTOR_SUCCESS":
      return {
        ...state,
        type,
        loading: false,
        success: true,
        disableClientContractors: data,
      };

    case "GET_DISABLE_CONTRACTOR_FAILURE":
      return {
        ...state,
        type,
        loading: false,
        success: false,
      };

    case "EDIT_FACILITY":
      return {
        ...state,
        type,
        loading: true,
        error: null,
      };

    case "EDIT_FACILITY_SUCCESS":
      return {
        ...state,
        facilityUpdate: data,
        type,
        loading: false,
        success: true,
      };

    case "EDIT_FACILITY_FAILURE":
      return {
        ...state,
        facilityUpdate: data,
        type,
        loading: false,
        success: true,
      };
    case "DELETE_FACILITY":
      return {
        ...state,
        type,
        loading: true,
      };

    case "DELETE_FACILITY_SUCCESS":
      return {
        ...state,
        deleteFacility: data,
        type,
        loading: false,
        success: true,
      };

    case "DELETE_FACILITY_FAILURE":
      return {
        ...state,
        type,
        loading: true,
        payload,
        data,
      };

    case "GET_ICS_TERMINAL":
      return {
        ...state,
        type,
        loading: true,
        payload,
      };

    case "GET_ICS_TERMINAL_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        clientTerminal: data,
      };

    case "GET_ICS_TERMINAL_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_ICS_TEAM_TERMINAL":
      return {
        ...state,
        type,
        payload,
      };

    case "GET_ICS_TEAM_TERMINAL_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        clientTeamTerminal: data,
      };

    case "GET_ICS_TEAM_TERMINAL_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
      };
  }
};

export default clientReducer;
