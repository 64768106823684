import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Paper,
} from '@material-ui/core';

import { USER_TYPE } from '../../../../../utils/keyMappers';

import * as spillActions from '../../../../../actionCreators/Spill';

const Styles = makeStyles({
  tableHead: {
    backgroundColor: '#2f7d32c2',
  },
  tableHeadCell: {
    color: 'white',
    fontWeight: 800,
  },
  tableNumCell: {
    textAlign: 'center',
  },
  tableContainer: {
    width: '400px',
  },
  tableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  cardsWrapper: {
    display: 'flex',
  },
  cardContentWrapper: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#212121',
  },
  paper: {
    padding: '15px',
  },
  summaryHeading: {
    fontSize: '25px',
    fontWeight: '800',
    color: '#212121',
  },
});

const SpillSummary = ({
  type,
  loading,
  success,
  history,
  currentUser,
  spillSummary,
  getSpillSummary,
}) => {
  const classes = Styles();
  const location = history?.location;

  React.useEffect(() => {
    const splitPathName = location.pathname.split('/');

    let userType = currentUser?.data?.test_user
      ? USER_TYPE.TEST
      : USER_TYPE.GENERAL;

    if (
      splitPathName[splitPathName.length - 1] === 'home' ||
      splitPathName[splitPathName.length - 1] === 'dashboard'
    ) {
      getSpillSummary({ userType });
    }
  }, [location.key]);

  const statuses = [
    { label: 'Work In Progress', key: 'open: work in progress' },
    { label: 'Pending Disposal', key: 'open: pending disposal' },
    { label: 'Pending Excavation', key: 'open: pending excavation' },
    {
      label: 'Documentation Sent Back To Contractor For Revision',
      key: 'open: documentation sent back to contractor for revision',
    },
    { label: 'Site Work Complete', key: 'open: site work complete' },
    { label: 'Documentation In Review', key: 'open: documentation in review' },
  ];

  return (
    <div>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && type === 'GET_SPILL_SUMMARY' ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.summaryHeading}>Summary</span>
              </Grid>
              {success &&
                statuses.map((row, index) => (
                  <Grid item xs={6} key={index}>
                    <Card>
                      <CardContent>
                        <div className={classes.cardContentWrapper}>
                          <Typography variant='subtitle1'>
                            {row.label}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {spillSummary[row.key] || 0}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ spill, user }) => ({
  currentUser: user.currentUser,
  spillSummary: spill.spillSummary,
  loading: spill.spillSummary.loading,
  success: spill.success,
  type: spill.type,
});

const mapDispatchToProps = (dispatch) => ({
  getSpillSummary: bindActionCreators(spillActions.getSpillSummary, dispatch),
});

SpillSummary.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SpillSummary)
);
