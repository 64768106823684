import React from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";

import logo from "../assets/pes-logo-169x40.png";
import * as userActions from "../actionCreators/User";
import CustomProgressLoader from "./CustomProgressLoader";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  topBar: {
    textAlign: "right",
    padding: "0px 3%",
  },
  logo: {
    height: 42,
    margin: "2%",
  },
  headerTextSection: {
    margin: "1%",
    marginTop: 11,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
    textAlign: "left",
    display: "flex",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: 10,
  },
}));

const Header = ({ user, logOut, history }) => {
  const { location } = history;

  const classes = useStyles();
  const now = Moment(new Date())?.format("dddd, MMMM Do YYYY, h:mm:ss a");

  const [anchorEl, setAnchorEl] = React.useState(null);

  let isAuthenticated = null;

  const { success, currentUser } = user;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    logOut(history);
    setAnchorEl(null);
    localStorage.removeItem('note_data');
  };
  if (success && currentUser.data) {
    isAuthenticated = user.logoutLoading  ? (
      <CustomProgressLoader show={true} />
    ) : (
      <div>
        <Avatar
          src="/broken-image.jpg"
          className={classes.avatar}
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>{currentUser?.data?.full_name}</MenuItem>
          <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }

  const notAuthenticated = (
    <Button
      href="/login"
      color="primary"
      variant="outlined"
      className={classes.link}
    >
      Login
    </Button>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="sticky"
        color="inherit"
        elevation={0}
        className={classes.topBar}
      >
        {now}
      </AppBar>
      <AppBar
        position="sticky"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            <Link
              variant="button"
              color="textPrimary"
              href="/"
              className={classes.link}
            >
              <img src={logo} alt="logo" className={classes.logo} />
            </Link>

            <div className={classes.headerTextSection}>
              <Typography variant="h5" color="inherit" noWrap>
                Spills
              </Typography>
              <Typography variant="subtitle2" color="inherit" noWrap>
                Managed by Premium Environmental Services
              </Typography>
            </div>
          </Typography>
          <nav>
            {currentUser && currentUser.data ? (
              <Link
                variant="button"
                color="textPrimary"
                href={
                  location && location.pathname === "/dashboard"
                    ? "/"
                    : "/dashboard"
                }
                className={classes.link}
              >
                {location && location.pathname === "/dashboard"
                  ? "Home"
                  : "Dashboard"}
              </Link>
            ) : (
              <Link
                variant="button"
                color="textPrimary"
                href="#"
                className={classes.link}
              >
                Home
              </Link>
            )}
            {/* <Link
              variant="button"
              color="textPrimary"
              href="/"
              className={classes.link}
            >
              Top
            </Link> */}
            <Link
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              About
            </Link>
            <Link
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              Contact
            </Link>
            <Link
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              ICS
            </Link>
          </nav>
          {success && currentUser.data ? isAuthenticated : notAuthenticated}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  logOut: bindActionCreators(userActions.logOut, dispatch),
});

Header.propTypes = {
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
