import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { CustomProgressLoader, DataTable } from '../../../../../Components';
import NewSpill from './NewSpill';
import EditSPill from './EditSpill';
import * as spillActions from '../../../../../actionCreators/Spill';
import * as userActions from '../../../../../actionCreators/User';
import ViewSpill from './ViewSpill';
import { ROLES, USER_TYPE } from '../../../../../utils/keyMappers';
import {
  statusVisibility,
  updatePageNumberBasedOnURL,
} from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%',
    borderRadius: '10px',
  },
  headerCard: {
    backgroundColor: '#f7f7f7',
  },
  noteText: {
    marginRight: '20px',
    lineHeight: '3',
    color: '#969696',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const SpillsMenu = ({
  user,
  type,
  isEdit,
  isView,
  spills,
  loading,
  success,
  history,
  material,
  getSpills,
  getAdmins,
  gotoSearch,
  getMaterial,
  getMySpills,
  getScript,
  handleCloseNewSpill,
  showNewForm,
  setShowNewForm,
  setShowSpills,
  showSpills,

}) => {
  const classes = useStyles();

  const location = history?.location;
  const searchParam = location?.search;
  const urlParams = new URLSearchParams(searchParam);
  const pageNumber = urlParams?.get('page');
  const isPageRoute = window?.location?.search;

  const { currentUser, admins } = user;
  document.title = 'Spills';

  const [currentPage, setCurrentPage] = useState(0);
  const [isPageChanged, setIsPageChanged] = useState(false);

  useEffect(() => {
    if (
      (isView === undefined || isView === false) &&
      (isEdit === undefined || isEdit === false)
    ) {
      if (currentUser.data && isPageChanged === false) {
        getSpills({
          page: 0,
          id: currentUser?.data?.id,
          permission: currentUser?.data?.role?.permission?.view_related_spills,
          role: currentUser?.data?.role?.role,
          userType: currentUser?.data?.test_user
            ? USER_TYPE.TEST
            : USER_TYPE.GENERAL,
        });
        getAdmins({ filter: 'none' });
        getMaterial();
      }
    }
  }, [currentUser, isPageChanged, isView]);

  const [isTestSpill, setIsTestSpill] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [viewSpill, setViewSpill] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [showNewSpillOption, setShowNewSpillOption] = useState(true);
  const [totalSpills, setTotalSpills] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let page = currentPage + 1;
    if (page) {
      urlParams.set('page', currentPage + 1);
      history.push({
        pathname: `/dashboard/spills`,
        search: urlParams.toString(),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (isPageRoute) {
      if (!pageNumber) {
        history.push({
          pathname: `/dashboard/spills`,
          search: `?page=1`,
        });
      } else {
        const updatedPageNumber = updatePageNumberBasedOnURL(
          pageNumber,
          Math.ceil(totalSpills / rowsPerPage)
        );

        history.push({
          pathname: `/dashboard/spills`,
          search: `?page=${updatedPageNumber}`,
        });

        setCurrentPage(parseInt(updatedPageNumber) - 1);
      }
    }
  }, [pageNumber, isPageRoute]);

  useEffect(() => {
    if (success && spills?.data) {
      if (
        currentUser.data?.role.role === ROLES.CONTRACTOR_ADMIN ||
        currentUser.data?.role.role === ROLES.CONTRACTOR_USER
      ) {
        // Is the contractor active in these Spills?
        let data = [...spills.data];
        let contractorObjs = [];
        let filter = null;
        let filteredSpills = [];
        if (currentUser.data?.role.role === ROLES.CONTRACTOR_USER) {
          filter = (o) => {
            return (
              o.contractor_id === currentUser.data.contractor_id &&
              ((!o.address_id && !currentUser.data.contractor_address_id) ||
                o.address_id === currentUser.data.contractor_address_id)
            );
          };
        } else {
          filter = (o) => o.contractor_id === currentUser.data.contractor_id;
        }
        for (const index in data) {
          let spillConnections = data[index].connections;
          if (spillConnections?.length) {
            contractorObjs =
              spillConnections[0]?.spill_contractors?.filter(filter);
          }

          if (
            contractorObjs.length &&
            contractorObjs.filter((o) => o.is_inactive).length ===
              contractorObjs.length
          ) {
            data[index].isInactive = true;
          }
        }

        // filter spills if the contractor is selected but is not accepted
        data?.map((item, index) => {
          if (
            item?.connections?.length &&
            (item?.connections[0]?.spill_contractors?.filter(filter)[index]
              ?.accepted !== null ||
              item?.connections[0]?.spill_contractors?.filter(filter)[index]
                ?.accepted === null)
          ) {
            filteredSpills?.push(item);
          }
        });

        setTableData(filteredSpills);
        setTotalSpills(spills.pagination?.total);
      } else {
        setTableData(spills.data);
        setTotalSpills(spills.pagination?.total);
      }
    }
  }, [success, spills?.data]);

  useEffect(() => {
    const splitPathname = location.pathname.split('/');
    if (isView) {
      showViewForm(splitPathname[4]);
    } else if (isEdit) {
      handleMoveToEditSpill(splitPathname[4]);
    } else {
      showAllSpills();
    }
  }, [isView, isEdit]);

  useEffect(() => {
    const splitPathname = location.pathname.split('/');
    if (isView) {
      history.push(`/dashboard/spills/view/${splitPathname[4]}`);
    }
    if (isEdit) {
      history.push(`/dashboard/spills/edit/${splitPathname[4]}`);
    }
  }, []);

  const handleOpenNewSpillForm = (isTest) => {
    setIsTestSpill(isTest);
    setShowSpills(true);
    setShowNewForm(true);
    setShowEditForm(false);
    setViewSpill(false);
  };

  const handleAPIScriptRunner = () => {
    getScript();
  };


  const handleViewSpill = (id) => {
    history.push(`/dashboard/spills/view/${id}`);
  };

  const handleEditSpill = (id) => {
    try {
      if (history.location.pathname.includes('/dashboard/spills/edit')) {
        handleMoveToEditSpill(id);
      }
    } catch (err) {}
    history.push(`/dashboard/spills/edit/${id}`);
  };

  const handleMoveToEditSpill = (id) => {
    setSelectedId(id);
    setShowSpills(true);
    setShowNewSpillOption(true);
    setShowNewForm(false);
    setShowEditForm(true);
    setViewSpill(false);
  };

  const handleCloseEditSpill = () => {
    // getSpills({
    //   page: 0,
    //   id: currentUser?.data?.id,
    //   permission: currentUser?.data?.role?.permission?.view_related_spills,
    //   role: currentUser?.data?.role?.role,
    //   userType: currentUser?.data?.test_user
    //     ? USER_TYPE.TEST
    //     : USER_TYPE.GENERAL,
    // });
    // setShowNewSpillOption(true);
    // setShowSpills(false);
    // setShowNewForm(false);
    // setShowEditForm(false);
    try {
      const splitPathname = location.pathname.split('/');
      history.push(`/dashboard/spills/view/${splitPathname[4]}`);
    } catch (err) {}
  };

  const handleCloseViewSpill = () => {
    setShowNewSpillOption(true);
    setShowNewForm(false);
    setShowEditForm(false);
    setViewSpill(false);
    setShowSpills(false);
  };

  const handleOpenEditFormWithDataTable = (data) => {
    handleEditSpill(data.job_no);
  };

  const handleAllSpillsClick = () => {
    setViewSpill(false);
    handleCloseNewSpill();
    history.push('/dashboard/spills');
  };

  const handleFilterMySpills = () => {
    getMySpills({
      page: 0,
      id: currentUser?.data?.id,
      permission: currentUser?.data?.role?.permission?.view_related_spills,
      role: currentUser?.data?.role?.role,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    });
    setShowSpills(true);
  };

  const handleTablePage = (data, limit) => {
    setIsPageChanged(true);
    limit &&
      getSpills({
        id: currentUser?.data?.id,
        permission: currentUser?.data?.role?.permission?.view_related_spills,
        role: currentUser?.data?.role?.role,
        page: data,
        userType: currentUser?.data?.test_user
          ? USER_TYPE.TEST
          : USER_TYPE.GENERAL,
        limit,
      });
  };

  const showViewForm = (id) => {
    setViewSpill(true);
    setSelectedId(id);
    setShowSpills(true);
    setShowNewForm(false);
    setShowEditForm(false);
  };

  const showAllSpills = () => {
    if (isPageChanged === false) {
      getSpills({
        page: currentPage || 0,
        id: currentUser?.data?.id,
        permission: currentUser?.data?.role?.permission?.view_related_spills,
        role: currentUser?.data?.role?.role,
        userType: currentUser?.data?.test_user
          ? USER_TYPE.TEST
          : USER_TYPE.GENERAL,
      });
    }
    setShowNewSpillOption(true);
    setShowNewForm(false);
    setShowEditForm(false);
    setViewSpill(false);
    setShowSpills(false);
  };

  const tableHeaders = [
    { title: 'Job No', key: 'job_no' },
    { title: 'Client Org', key: 'clientOrgName' },
    { title: 'Opened', key: 'opened_on' },
    { title: 'Status', key: 'status' },
    { title: 'Closed', key: 'closed_on' },
    { title: 'Claim No', key: 'claim_no' },
  ];

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={24} md={12}>
          <Card className={classes.headerCard}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant='h5' style={{ color: '#619a63' }}>
                    Spills
                  </Typography>
                </div>
                <div className='flex'>
                  <Typography variant='subtitle2' className={classes.noteText}>
                    These are ALL of the spill incidents
                  </Typography>
                  <WarningIcon
                    fontSize='large'
                    style={{ color: 'rgb(199 169 13)' }}
                  />
                </div>
              </div>
              <hr />

              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <div>
                  {currentUser &&
                  currentUser?.data?.role.role === ROLES.CORPORATE_USER ? (
                    <Button color='primary' onClick={handleFilterMySpills}>
                      My Spills
                    </Button>
                  ) : (
                    <Typography variant='h5'></Typography>
                  )}
                </div>
                <div>
                  {showSpills ? (
                    <Button color='primary' onClick={handleAllSpillsClick}>
                      All Spills
                    </Button>
                  ) : (
                    <Typography variant='h5'></Typography>
                  )}
                </div>
                <div>
                  {showNewSpillOption ? (
                    <>
                      <Button color='primary' onClick={() => gotoSearch()}>
                        Search Spill
                      </Button>
                      {currentUser?.data?.role?.permission?.create_spills ? (
                        <Button
                          color='primary'
                          onClick={() => handleOpenNewSpillForm(false)}
                        >
                          Create New Spill
                        </Button>
                      ) : null}
                      {currentUser?.data?.role?.permission?.create_spills &&
                      currentUser?.data?.test_user ? (
                        <Button
                          color='primary'
                          onClick={() => handleOpenNewSpillForm(true)}
                        >
                          Create Test Spill
                        </Button>
                      ) : null}
                      {/* Generic script runner (do not use): */}
                      {/* {currentUser?.data?.test_user ? (
                        <Button
                          color='primary'
                          onClick={() => handleAPIScriptRunner()}
                        >
                          Script Runner using API Call
                        </Button>
                      ) : null} */}
                    </>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
          <div style={{ marginTop: 10 }}>
            {tableData && tableData.length >= 0 ? (
              showNewForm === false &&
              showEditForm === false &&
              viewSpill === false && (
                <DataTable
                  tableHeader={tableHeaders}
                  tableBody={tableData.map((row) => ({
                    ...row,
                    status: statusVisibility(
                      row?.status,
                      currentUser.data?.role?.role
                    ),
                  }))}
                  editTable={currentUser.data?.role?.permission?.edit_spills}
                  onRowSelect={handleOpenEditFormWithDataTable}
                  pageChange={handleTablePage}
                  openEditForm={(data) => console.log(data)}
                  showViewButton
                  handleView={(data) => handleViewSpill(data.job_no)}
                  total={totalSpills}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setPageRows={setRowsPerPage}
                  loading={loading}
                />
              )
            ) : loading && loading ? null : (
              <Typography variant='h5'>No spills found</Typography>
            )}
          </div>

          <div style={{ marginTop: 10 }}>
            {currentUser && currentUser.data?.role?.permission?.create_spills
              ? showNewForm && (
                  <NewSpill
                    isTestSpill={isTestSpill}
                    currentUser={currentUser}
                    closeAddSpill={handleCloseNewSpill}
                    editSpill={(id) => handleEditSpill(id)}
                  />
                )
              : null}
          </div>
          <div style={{ marginTop: 10 }}>
            {showEditForm && (
              <EditSPill
                id={selectedId}
                materials={material}
                closeEditSpill={handleCloseEditSpill}
                viewSpill={(id) => handleViewSpill(id)}
              />
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {viewSpill && (
              <ViewSpill
                id={selectedId}
                adminsData={admins}
                currentUser={currentUser}
                closeViewSpill={() => handleCloseViewSpill()}
                updateSpill={(id) => handleEditSpill(id)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  spill: { success, spills, loading, type, material },
  user,
}) => ({
  success,
  loading,
  type,
  material,
  spills,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: bindActionCreators(userActions.getAdmins, dispatch),
  getSpills: bindActionCreators(spillActions.getSpills, dispatch),
  getScript: bindActionCreators(spillActions.getScript, dispatch),
  getMySpills: bindActionCreators(spillActions.getMySpills, dispatch),
  getMaterial: bindActionCreators(spillActions.getMaterial, dispatch),
});

SpillsMenu.propTypes = {
  user: PropTypes.object.isRequired,
  getAdmins: PropTypes.func.isRequired,
  spills: PropTypes.object.isRequired,
  success: PropTypes.bool.isRequired,
  getSpills: PropTypes.func.isRequired,
  getMaterial: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SpillsMenu)
);
