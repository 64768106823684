import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import {
  Grid,
  Button,
  Select,
  Checkbox,
  MenuItem,
  Snackbar,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import {
  isPESUser,
  orderStatuses,
  statusVisibility,
  isPESUser_2,
  isBatchUpdatingUser,
  USER_TYPE,
} from "../../../../../utils";
import MuiAlert from "@material-ui/lab/Alert";

import * as spillActions from "../../../../../actionCreators/Spill";

import { EditSpill, ViewSpill } from ".";

import DataTable from "../../../../../Components/SpillDataTable";
import { Prompt, WholeScreenModal } from "../../../../../Components";
import { EditNoteText as Text } from "../../../../../utils/language/english";
import {
  CustomProgressLoader,
  DownloadFullCSV,
} from "../../../../../Components";

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  checked: {},
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: "none",
  },
}));

const SearchSpillResults = ({
  admins,
  service,
  history,
  loading,
  success,
  statuses,
  currentUser,
  updateStatus,
  searchSpills,
  spillsLoading,
  searchedSpills,
  selectedSpills,
  setSelectedSpills,
  finalSpillsSearchData,
  spillDataForFilterState,
}) => {
  const value = queryString?.parse(history?.location?.search);
  const batchSearch = value?.token === "true";
  const reports = value?.report === "true";
  const subrogation = value?.subrogation === "true";
  const pendingdisposal = value?.pendingdisposal === "true";
  const isShipperReport = value?.shipper === "true";
  const isClientInvoiceReport = value?.["invoice-download"] === "true";
  const isContractorRejectionReport = value?.["contractor-rejection"] === "true";
  const isSubrogationReport = new URLSearchParams(window?.location?.search)?.get('subrogation') === 'true';
  const classes = useStyles();
  const [filters, setFilters] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [totalSpills, setTotalSpills] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [viewSpill, setViewSpill] = useState(false);
  const [batchStatus, setBatchStatus] = useState(null);
  const [showNewForm, setShowNewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [searchedSpillsData, setSearchedSpillsData] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarSeverity, setSnackBarSeverity] = React.useState("");

  const [checkedSpills, setCheckedSpills] = useState(selectedSpills?.data);
  const [showSpinnerDownloading, setShowSpinnerDownloading] = useState(false);
  const [showBatchUpdateModal, setShowBatchUpdateModal] = React.useState(false);
  const [paginationProps, setPaginationProps] = useState({
    page: 0,
    limit: 10,
  });
  const [isSubrogationActive, setIsSubrogationActive] = React.useState(false);
  const [isPendingDisposalActive, setPendingDisposalActive] = React.useState(false);
  const [downloadSubType, setDownloadSubtype] = useState(null); //Shipper, Client invoice and contractor rejection report
  const [navigating, setNavigating] = useState(false); //Stop rendering current component when history.goBack is called

  // Set subtype to download expected report for admin report
  useEffect(() => {
    const subType = isShipperReport ? "shipper_report" :
      isClientInvoiceReport ? "invoice_download_report" :
      isContractorRejectionReport ? "contractor_rejections_report" : null;
    setDownloadSubtype(subType);
  }, [isShipperReport, isClientInvoiceReport, isContractorRejectionReport]);

  // const [closeSelectedSpills, setCloseSelectedSpills] = useState([]);

  const userRole = currentUser?.data?.role.role;
  let finalSearchData = finalSpillsSearchData?.data;

  const tableHeaders = [
    { title: "Job No", key: "job_no" },
    { title: "Client Org", key: "clientOrgName" },
    { title: "Opened", key: "opened_on" },
    { title: "Status", key: "status" },
    { title: "Closed", key: "closed_on" },
    { title: "Claim No", key: "claim_no" },
  ];

  const getFilterCaptions = (filters) => {
    try {
      return filters
        .split(`\\n`)
        .join(` `)
        .split(`"`)
        .join(``)
        .split(`\\`)
        .join(` `)
        .split(`, ,`)
        .join(`,`);
    } catch (e) {
      return "No applied Filters,Error occured";
    }
  };

  const handleGoBackSearch = () => {
    if (batchSearch) {
      history.push("/dashboard/batch-status");
    } else if (reports) {
      setNavigating(true);
      history.goBack()
    } else if (subrogation) {
      history.push("/dashboard/reports/subrogation");
    } else if (pendingdisposal) {
      history.push("/dashboard/reports/pendingdisposal");
    } else if(isShipperReport) {
      history.push("/dashboard/reports/shipper");
    } else {
      history.push("/dashboard/search");
    }
  };

  const handleEditSpill = (id) => {
    setSelectedId(id);
    setShowNewForm(false);
    setShowEditForm(true);
    setViewSpill(false);
    // history.push(`/dashboard/spills/edit/${id}`);
  };

  const handleBatchStatusChange = (val) => {
    setBatchStatus(val);
  };
  const handleSubmit = async (batchUpdateCheck) => {
    batchUpdateCheck && batchUpdate();
  };

  const batchUpdateHandler = () => {
    if (!batchStatus) {
      setSnackBarMessage("No Status Selected");
      setSnackBarOpen(true);
      setSnackBarSeverity("warning");
      return;
    } else if (!checkedSpills.length && !selectAll) {
      setSnackBarMessage("No incidents selected.");
      setSnackBarOpen(true);
      setSnackBarSeverity("warning");
      return;
    }
    setShowBatchUpdateModal(true);
  };

  const batchUpdate = () => {
    if (!batchStatus) {
      alert("No Status selected.");
      return;
    } else if (!checkedSpills.length && !selectAll) {
      alert("No incidents selected.");
      return;
    }

    setSelectedSpills(checkedSpills);

    finalSearchData = {
      ...finalSearchData,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    };

    updateStatus({
      searchQuery: finalSearchData,
      selected: checkedSpills,
      selectAll,
      status: batchStatus,
      batchUpdate: true,
    });

    if (success) {
      setSnackBarSeverity("success");
      setSnackBarMessage(
        `${selectAll ? totalSpills - checkedSpills.length : checkedSpills.length
        } Status has been updated successfully`
      );
      setSnackBarOpen(true);
      setCheckedSpills([]);
    } else if (!success) {
      setSnackBarSeverity("error");
      setSnackBarMessage(
        "There was some problem updating the status.Please try again later"
      );
      setSnackBarOpen(true);
    }
  };

  const handleMoveToEditSpill = (id) => {
    setSelectedId(id);
    setShowNewForm(false);
    setShowEditForm(true);
    setViewSpill(false);
    history.push(`/dashboard/spills/edit/${id}`);
  };

  const handleViewSpill = (id) => {
    setViewSpill(true);
    setSelectedId(id);
    setShowNewForm(false);
    setShowEditForm(false);
    window.history.replaceState({}, null, `/dashboard/spills/view/${id}`);
  };

  const handleCloseEditSpill = (jobNo) => {
    // setShowNewForm(false);
    // setShowEditForm(false);
    handleViewSpill(jobNo);
  };

  const handleCloseViewSpill = () => {
    setShowNewForm(false);
    setViewSpill(false);
    setShowEditForm(false);
    finalSearchData = {
      ...finalSearchData,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    };
    searchSpills({ ...finalSearchData, paginationProps });
    // if (batchSearch) {
    //   history.push('/dashboard/batch-status');
    // } else if (reports) {
    //   history.push('/dashboard/reports');
    // } else {
    //   history.push('/dashboard/search');

    // }
  };

  const handleOpenEditFormWithDataTable = (data) => {
    handleEditSpill(data.job_no);
  };

  const handlePageChange = (data, limit) => {
    setPaginationProps({ page: data, limit });
    finalSearchData = {
      ...finalSearchData,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    };
    searchSpills({
      ...finalSearchData,
      paginationProps: { page: data, limit },
    });
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    setSearchedSpillsData(
      searchedSpills?.data.map((row) => ({
        ...row,
        status: statusVisibility(row.status, userRole),
      }))
    );

    setTotalSpills(searchedSpills.count);
  }, [searchedSpills]);

  useEffect(() => {
    let definedFiltersToShow = [];
    const updatedFiltersState = spillDataForFilterState?.data?.filter(
      (filter) => {
        return filter !== null;
      }
    );

    let nonNullFilters = updatedFiltersState?.map((filters) =>
      filters?.replaceAll("null,", "")
    );

    nonNullFilters = nonNullFilters?.map((filter) =>
      filter?.replaceAll("  ", "")
    );

    nonNullFilters?.map(
      (filterItem) => {
        return filterItem?.split(":")[1]?.length >= 2 && definedFiltersToShow.push(filterItem)
      }
    );

    setFilters(JSON.stringify(definedFiltersToShow));
    const isSubrogationActive = getFilterCaptions(
      JSON.stringify(definedFiltersToShow)
    )?.includes("subrogation :1");

    const isPendingDisposalActive = getFilterCaptions(
      JSON.stringify(definedFiltersToShow)
    )?.includes("pendingdisposal :1");
    setPendingDisposalActive(isPendingDisposalActive);
    setIsSubrogationActive(isSubrogationActive);
  }, [spillDataForFilterState?.data]);


  // Only render the component if not navigating
  if (navigating) return null; 

  return (
    <div>
      {
        <WholeScreenModal
          show={showBatchUpdateModal}
          hide={() => setShowBatchUpdateModal(false)}
        >
          <Prompt
            response={(response) => {
              setShowBatchUpdateModal(false);
              handleSubmit(response);
            }}
            headingText={Text.batchUpdatePromptHeading}
            question={
              selectAll
                ? Text.batchUpdate + "  " + (totalSpills - checkedSpills.length)
                : Text.batchUpdate + "  " + checkedSpills.length
            }
            acceptText={Text.batchUpdatePromptAcceptBtn}
            rejectText={Text.batchUpdatePromptRejectBtn}
          />
        </WholeScreenModal>
      }
      {searchedSpillsData?.length === 0 ? (
        <div>
          <div style={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" gutterBottom>
              Search results
            </Typography>
          </div>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "left" }}
          >
            {filters?.length > 2 ? (
              <Typography variant="subtitle1" gutterBottom>
                Filters:{" "}
                <Typography variant="caption" gutterBottom>
                  {getFilterCaptions(filters)}
                </Typography>
              </Typography>
            ) : (
              <Typography variant="caption" gutterBottom>
                <p>No filters are applied yet</p>
              </Typography>
            )}
          </Grid>
          {filters?.length == 0 || loading ? (
            <div>
              <div className={classes.rootLoader}>
                <CircularProgress
                  style={{ width: '80px', height: '80px' }}
                />
              </div>
            </div>
          )
            : (
              <div>0 results found.</div>
            )}

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => handleGoBackSearch()}
          >
            Back
          </Button>
        </div>
      ) : (
        <div>
          <div style={{ marginTop: 10 }}>
            {showNewForm === false &&
              showEditForm === false &&
              viewSpill === false && (
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={4} style={{ textAlign: "left" }}>
                      <Typography variant="h5" gutterBottom>
                        Search results
                        {totalSpills > 0 && ` (${totalSpills})`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: !isSubrogationActive ? "flex" : "" }}
                    >
                      {showSpinnerDownloading && (
                        <CustomProgressLoader show={showSpinnerDownloading} />
                      )}
                    
                    {/* Show this downlaod option for other reports other than Shipper, Client Invoice and Contractor Rejection Report */}
                    {!isShipperReport && !isClientInvoiceReport && !isContractorRejectionReport ?
                      <>
                        <DownloadFullCSV
                          className={`${classes.button} ${showSpinnerDownloading && classes.hide
                            }`}
                          dataForSearch={searchedSpillsData}
                          type={"SearchResults"}
                          searchQueryData={finalSearchData}
                          subType={"Download"}
                          showSpinner={setShowSpinnerDownloading}
                          isSubrogationActive={isSubrogationActive}
                          isPendingDisposalActive={isPendingDisposalActive}
                          isSubrogationReport={isSubrogationReport}
                        />
                        {(!isSubrogationActive && !isPendingDisposalActive) &&
                          isPESUser_2(currentUser?.data?.role?.role) && (
                            <DownloadFullCSV
                              className={`${classes.button} ${showSpinnerDownloading && classes.hide
                                }`}
                              dataForSearch={searchedSpillsData}
                              type={"SearchResults"}
                              searchQueryData={finalSearchData}
                              subType={"Admin Download"}
                              showSpinner={setShowSpinnerDownloading}
                              isSubrogationActive={isSubrogationActive}
                              isPendingDisposalActive={false}
                            />
                          )}
                      </> : null }
                      
                      {/* This is for Shipper Report, Client Invoice Report and Contractor Rejection Report */}
                      {downloadSubType && (
                         <DownloadFullCSV
                         className={`${classes.button} ${showSpinnerDownloading && classes.hide
                           }`}
                         dataForSearch={searchedSpillsData}
                         type={"SearchResults"}
                         searchQueryData={finalSearchData}
                         subType={downloadSubType}
                         showSpinner={setShowSpinnerDownloading}
                         isSubrogationActive={isSubrogationActive}
                         isPendingDisposalActive={isPendingDisposalActive}
                       />
                      )}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleGoBackSearch()}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    xl={12}
                    style={{ textAlign: "left" }}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Filters:{" "}
                      <Typography variant="caption" gutterBottom>
                        {getFilterCaptions(filters)}
                      </Typography>
                    </Typography>
                  </Grid>
                  {batchSearch && isPESUser(currentUser?.data?.role?.role) && (
                    <Grid container>
                      <Grid item xs={2} style={{ display: "flex" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) => {
                                setCheckedSpills([]);
                                setSelectAll(event.target.checked);
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Select All"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ width: "100%" }}
                          control={
                            <Select
                              labelId="status_label"
                              id="status_select"
                              name="status"
                              style={{ minWidth: "100px", marginLeft: "10px" }}
                              inputProps={{
                                value: batchStatus || "Select Status",
                              }}
                              onChange={(event) => {
                                handleBatchStatusChange(event?.target?.value);
                              }}
                            >
                              {(statuses
                                ? orderStatuses(statuses).map(
                                  (x) => `${x?.status}: ${x?.name}`
                                )
                                : []
                              ).map((e) => {
                                return <MenuItem value={e}>{e}</MenuItem>;
                              })}
                            </Select>
                          }
                          labelPlacement="start"
                          label="Select Status "
                        />
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={batchUpdateHandler}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  <div>
                    <DataTable
                      tableHeader={tableHeaders}
                      tableBody={searchedSpillsData.map((row) => ({
                        ...row,
                        status: statusVisibility(
                          row?.status,
                          currentUser?.data?.role?.role
                        ),
                      }))}
                      editTable={
                        currentUser.data?.role?.permission?.edit_spills
                      }
                      loading={spillsLoading}
                      onRowSelect={handleOpenEditFormWithDataTable}
                      pageChange={handlePageChange}
                      currentPage={paginationProps?.page}
                      setCurrentPage={(val) =>
                        setPaginationProps({ ...paginationProps, page: val })
                      }
                      openEditForm={(data) => console.log(data)}
                      showViewButton
                      handleView={(data) => handleViewSpill(data.job_no)}
                      total={totalSpills}
                      canBatchUpdate={
                        batchSearch &&
                        isBatchUpdatingUser(currentUser?.data?.role.role)
                      }
                      selectSpill={(id, checked) => {
                        let tempCheckedSpills = [...checkedSpills];
                        if (checked) {
                          tempCheckedSpills.push(id);
                        } else {
                          tempCheckedSpills.splice(
                            tempCheckedSpills?.indexOf(id),
                            1
                          );
                        }
                        setCheckedSpills(tempCheckedSpills);
                      }}
                      selected={checkedSpills}
                      selectAll={selectAll}
                      setPageRows={() => { }}
                    />
                  </div>
                </div>
              )}
          </div>

          <div style={{ marginTop: 10 }}>
            {showEditForm && (
              <EditSpill
                servicesData={service}
                id={selectedId}
                closeEditSpill={handleCloseEditSpill}
                viewSpill={(id) => handleViewSpill(id)}
              />
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {viewSpill && (
              <ViewSpill
                id={selectedId}
                onSearch={true}
                adminsData={admins}
                servicesData={service}
                currentUser={currentUser}
                closeViewSpill={() => handleCloseViewSpill()}
                updateSpill={(data) => handleMoveToEditSpill(data)}
              />
            )}
          </div>
        </div>
      )}
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = ({
  user,
  client,
  agency,
  service,
  contractor,
  spill: {
    success,
    loading,
    searchedSpills,
    selectedSpills,
    status: { statuses },
    finalSpillsSearchData,
    spillDataForFilterState,
  },
}) => ({
  service,
  success: success,
  statuses: statuses,
  admins: user.admins,
  finalSpillsSearchData,
  spillsLoading: loading,
  spillDataForFilterState,
  currentUser: user.currentUser,
  selectedSpills: selectedSpills,
  searchedSpills: searchedSpills,
  loading:
    user.loading ||
    loading ||
    client.loading ||
    agency.loading ||
    contractor.loading,
});

const mapDispatchToProps = (dispatch) => ({
  searchSpills: bindActionCreators(spillActions.searchSpills, dispatch),
  updateStatus: bindActionCreators(spillActions.updateStatus, dispatch),
  setSelectedSpills: bindActionCreators(
    spillActions.setSelectedSpills,
    dispatch
  ),
});

SearchSpillResults.propTypes = {
  user: PropTypes.object.isRequired,
  success: PropTypes.bool.isRequired,
  spills: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  searchSpills: PropTypes.func.isRequired,
  contractors: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchSpillResults)
);