import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

import * as userActions from '../../../../actionCreators/User';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function EditPassword({ editUserPassword, closeModal }) {
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      oldPassword: e.target['old-password'].value,
      newPassword: e.target['new-password'].value,
    };

    await editUserPassword(obj);

    await closeModal();
  };

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword1, setNewPassword1] = React.useState('');
  const [newPassword2, setNewPassword2] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [emptyFilterFlag, setEmptyFilterFlag] = React.useState(false);

  const handlePassword1 = (value) => {
    if (value.indexOf(' ') >= 0) return;
    setNewPassword1(value);
  };

  const handlePassword2 = (value) => {
    if (value.indexOf(' ') >= 0) return;
    setNewPassword2(value);
  };

  const handleOldPassword = (value) => {
    if (value.indexOf(' ') >= 0) return;
    setOldPassword(value);
  };

  React.useEffect(() => {
    if (newPassword1 !== newPassword2) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [newPassword1, newPassword2]);

  React.useEffect(() => {
    if (newPassword1.length <= 0 || oldPassword.length <= 0) {
      setEmptyFilterFlag(true);
    } else {
      setEmptyFilterFlag(false);
    }
  }, [newPassword1, oldPassword]);

  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom>
        <div style={{ display: 'flex' }}>
          <Avatar className={classes.avatar}>
            <VpnKeyIcon />
          </Avatar>
          <span style={{ paddingLeft: '20px' }}>My Password</span>
        </div>
      </Typography>

      <div style={{ paddingTop: 30 }} />

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              value={oldPassword}
              onChange={(x) => handleOldPassword(x.target.value)}
              fullWidth
              type='password'
              id='old-password'
              name='old-password'
              label='Old password'
              autoComplete='old-password'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockOpenOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={newPassword1}
              onChange={(x) => handlePassword1(x.target.value)}
              fullWidth
              type='password'
              id='new-password'
              name='new-password'
              label='New Password'
              autoComplete='new-password'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={passwordError}
              helperText={passwordError ? 'Both Password should match' : ''}
              value={newPassword2}
              onChange={(x) => handlePassword2(x.target.value)}
              fullWidth
              type='password'
              id='re-enter-new-password'
              name='re-enter-new-password'
              label='Re-enter new password'
              autoComplete='re-enter-new-password'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              disabled={emptyFilterFlag || passwordError}
              className={classes.button}
              variant='contained'
              color='primary'
              type='submit'
            >
              Update
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  editUserPassword: bindActionCreators(userActions.editUserPassword, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPassword)
);
