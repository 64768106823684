import { COUNTRIES } from './keyMappers';

export const containerTypes = [
  "55 Gal. Steel",
  "85 Gal. Steel Overpack",
  "95 Gal. Steel Overpack",
  "5 Gal. Poly Overpack",
  "15 Gal. Poly Overpack",
  "30 Gal. Poly Overpack",
  "55 Gal. Poly Overpack",
  "95 Gal. Poly Overpack",
  "110 Gal. Poly Overpack",
  "Cubic Yard/Gaylord Box",
  "Super Sack Overpack",
  "Total Poly 275 Gal.",
  "Total Poly 330 Gal.",
  "12 Cu. Yd. Roll off Box",
  "20 Cu. Yd. Roll off Box",
];

export const states = [
  {
    country: "USA",
    key: "AL",
    name: "Alabama",
  },
  {
    country: "USA",
    key: "AK",
    name: "Alaska",
  },
  {
    country: "CAN",
    key: "AB",
    name: "Alberta",
  },
  {
    country: "USA",
    key: "AZ",
    name: "Arizona",
  },
  {
    country: "USA",
    key: "AR",
    name: "Arkansas",
  },
  {
    country: "CAN",
    key: "BC",
    name: "British Columbia",
  },
  {
    country: "USA",
    key: "CA",
    name: "California",
  },
  {
    country: "USA",
    key: "CO",
    name: "Colorado",
  },
  {
    country: "USA",
    key: "CT",
    name: "Connecticut",
  },
  {
    country: "USA",
    key: "DC",
    name: "DC",
  },
  {
    country: "USA",
    key: "DE",
    name: "Delaware",
  },
  {
    country: "USA",
    key: "FL",
    name: "Florida",
  },
  {
    country: "USA",
    key: "GA",
    name: "Georgia",
  },
  {
    country: "USA",
    key: "HI",
    name: "Hawaii",
  },
  {
    country: "USA",
    key: "ID",
    name: "Idaho",
  },
  {
    country: "USA",
    key: "IL",
    name: "Illinois",
  },
  {
    country: "USA",
    key: "IN",
    name: "Indiana",
  },
  {
    country: "USA",
    key: "IA",
    name: "Iowa",
  },
  {
    country: "USA",
    key: "KS",
    name: "Kansas",
  },
  {
    country: "USA",
    key: "KY",
    name: "Kentucky",
  },
  {
    country: "USA",
    key: "LA",
    name: "Louisiana",
  },
  {
    country: "USA",
    key: "ME",
    name: "Maine",
  },
  {
    country: "CAN",
    key: "MB",
    name: "Manitoba",
  },
  {
    country: "USA",
    key: "MD",
    name: "Maryland",
  },
  {
    country: "USA",
    key: "MA",
    name: "Massachusetts",
  },
  {
    country: "USA",
    key: "MI",
    name: "Michigan",
  },
  {
    country: "USA",
    key: "MN",
    name: "Minnesota",
  },
  {
    country: "USA",
    key: "MS",
    name: "Mississippi",
  },
  {
    country: "USA",
    key: "MO",
    name: "Missouri",
  },
  {
    country: "USA",
    key: "MT",
    name: "Montana",
  },
  {
    country: "USA",
    key: "NE",
    name: "Nebraska",
  },
  {
    country: "USA",
    key: "NV",
    name: "Nevada",
  },
  {
    country: "CAN",
    key: "NB",
    name: "New Brunswick",
  },
  {
    country: "USA",
    key: "NH",
    name: "New Hampshire",
  },
  {
    country: "USA",
    key: "NJ",
    name: "New Jersey",
  },
  {
    country: "USA",
    key: "NM",
    name: "New Mexico",
  },
  {
    country: "USA",
    key: "NY",
    name: "New York",
  },
  {
    country: "CAN",
    key: "NL",
    name: "Newfoundland",
  },
  {
    country: "USA",
    key: "NC",
    name: "North Carolina",
  },
  {
    country: "USA",
    key: "ND",
    name: "North Dakota",
  },
  {
    country: "CAN",
    key: "NT",
    name: "Northwest Territories",
  },
  {
    country: "CAN",
    key: "NS",
    name: "Nova Scotia",
  },
  {
    country: "CAN",
    key: "NU",
    name: "Nunavut",
  },
  {
    country: "USA",
    key: "OH",
    name: "Ohio",
  },
  {
    country: "USA",
    key: "OK",
    name: "Oklahoma",
  },
  {
    country: "CAN",
    key: "ON",
    name: "Ontario",
  },
  {
    country: "USA",
    key: "OR",
    name: "Oregon",
  },
  {
    country: "USA",
    key: "PA",
    name: "Pennsylvania",
  },
  {
    country: "CAN",
    key: "PE",
    name: "Prince Edward Island",
  },
  {
    country: "CAN",
    key: "QC",
    name: "Quebec",
  },
  {
    country: "USA",
    key: "RI",
    name: "Rhode Island",
  },
  {
    country: "CAN",
    key: "SK",
    name: "Saskatchewan",
  },
  {
    country: "USA",
    key: "SC",
    name: "South Carolina",
  },
  {
    country: "USA",
    key: "SD",
    name: "South Dakota",
  },
  {
    country: "USA",
    key: "TN",
    name: "Tennessee",
  },
  {
    country: "USA",
    key: "TX",
    name: "Texas",
  },
  {
    country: "USA",
    key: "UT",
    name: "Utah",
  },
  {
    country: "USA",
    key: "VT",
    name: "Vermont",
  },
  {
    country: "USA",
    key: "VA",
    name: "Virginia",
  },
  {
    country: "USA",
    key: "WA",
    name: "Washington",
  },
  {
    country: "USA",
    key: "WV",
    name: "West Virginia",
  },
  {
    country: "USA",
    key: "WI",
    name: "Wisconsin",
  },
  {
    country: "USA",
    key: "WY",
    name: "Wyoming",
  },
  {
    country: "CAN",
    key: "YT",
    name: "Yukon",
  },
];

export const americaStates = [
  {
    country: "USA",
    key: "AL",
    name: "Alabama",
  },
  {
    country: "USA",
    key: "AK",
    name: "Alaska",
  },
  {
    country: "USA",
    key: "AZ",
    name: "Arizona",
  },
  {
    country: "USA",
    key: "AR",
    name: "Arkansas",
  },
  {
    country: "USA",
    key: "CA",
    name: "California",
  },
  {
    country: "USA",
    key: "CO",
    name: "Colorado",
  },
  {
    country: "USA",
    key: "CT",
    name: "Connecticut",
  },
  {
    country: "USA",
    key: "DC",
    name: "DC",
  },
  {
    country: "USA",
    key: "DE",
    name: "Delaware",
  },
  {
    country: "USA",
    key: "FL",
    name: "Florida",
  },
  {
    country: "USA",
    key: "GA",
    name: "Georgia",
  },
  {
    country: "USA",
    key: "HI",
    name: "Hawaii",
  },
  {
    country: "USA",
    key: "ID",
    name: "Idaho",
  },
  {
    country: "USA",
    key: "IL",
    name: "Illinois",
  },
  {
    country: "USA",
    key: "IN",
    name: "Indiana",
  },
  {
    country: "USA",
    key: "IA",
    name: "Iowa",
  },
  {
    country: "USA",
    key: "KS",
    name: "Kansas",
  },
  {
    country: "USA",
    key: "KY",
    name: "Kentucky",
  },
  {
    country: "USA",
    key: "LA",
    name: "Louisiana",
  },
  {
    country: "USA",
    key: "ME",
    name: "Maine",
  },
  {
    country: "USA",
    key: "MD",
    name: "Maryland",
  },
  {
    country: "USA",
    key: "MA",
    name: "Massachusetts",
  },
  {
    country: "USA",
    key: "MI",
    name: "Michigan",
  },
  {
    country: "USA",
    key: "MN",
    name: "Minnesota",
  },
  {
    country: "USA",
    key: "MS",
    name: "Mississippi",
  },
  {
    country: "USA",
    key: "MO",
    name: "Missouri",
  },
  {
    country: "USA",
    key: "MT",
    name: "Montana",
  },
  {
    country: "USA",
    key: "NE",
    name: "Nebraska",
  },
  {
    country: "USA",
    key: "NV",
    name: "Nevada",
  },
  {
    country: "USA",
    key: "NH",
    name: "New Hampshire",
  },
  {
    country: "USA",
    key: "NJ",
    name: "New Jersey",
  },
  {
    country: "USA",
    key: "NM",
    name: "New Mexico",
  },
  {
    country: "USA",
    key: "NY",
    name: "New York",
  },
  {
    country: "USA",
    key: "NC",
    name: "North Carolina",
  },
  {
    country: "USA",
    key: "ND",
    name: "North Dakota",
  },
  {
    country: "USA",
    key: "OH",
    name: "Ohio",
  },
  {
    country: "USA",
    key: "OK",
    name: "Oklahoma",
  },
  {
    country: "USA",
    key: "OR",
    name: "Oregon",
  },
  {
    country: "USA",
    key: "PA",
    name: "Pennsylvania",
  },
  {
    country: "USA",
    key: "RI",
    name: "Rhode Island",
  },
  {
    country: "USA",
    key: "SC",
    name: "South Carolina",
  },
  {
    country: "USA",
    key: "SD",
    name: "South Dakota",
  },
  {
    country: "USA",
    key: "TN",
    name: "Tennessee",
  },
  {
    country: "USA",
    key: "TX",
    name: "Texas",
  },
  {
    country: "USA",
    key: "UT",
    name: "Utah",
  },
  {
    country: "USA",
    key: "VT",
    name: "Vermont",
  },
  {
    country: "USA",
    key: "VA",
    name: "Virginia",
  },
  {
    country: "USA",
    key: "WA",
    name: "Washington",
  },
  {
    country: "USA",
    key: "WV",
    name: "West Virginia",
  },
  {
    country: "USA",
    key: "WI",
    name: "Wisconsin",
  },
  {
    country: "USA",
    key: "WY",
    name: "Wyoming",
  },
];

export const canadaStates = [
  {
    country: "CAN",
    key: "AB",
    name: "Alberta",
  },
  {
    country: "CAN",
    key: "BC",
    name: "British Columbia",
  },
  {
    country: "CAN",
    key: "MB",
    name: "Manitoba",
  },
  {
    country: "CAN",
    key: "NB",
    name: "New Brunswick",
  },
  {
    country: "CAN",
    key: "NL",
    name: "Newfoundland",
  },
  {
    country: "CAN",
    key: "NT",
    name: "Northwest Territories",
  },
  {
    country: "CAN",
    key: "NS",
    name: "Nova Scotia",
  },
  {
    country: "CAN",
    key: "NU",
    name: "Nunavut",
  },
  {
    country: "CAN",
    key: "ON",
    name: "Ontario",
  },
  {
    country: "CAN",
    key: "PE",
    name: "Prince Edward Island",
  },
  {
    country: "CAN",
    key: "QC",
    name: "Quebec",
  },
  {
    country: "CAN",
    key: "SK",
    name: "Saskatchewan",
  },
  {
    country: "CAN",
    key: "YT",
    name: "Yukon",
  },
];

export const countries = [COUNTRIES?.USA, COUNTRIES?.CANADA];

export const addressTiers = [
  { level: "Tier I" },
  { level: "Tier II" },
  { level: "Tier III" },
  { level: "Preferred" },
  { level: "Do Not Use" },
  { level: "Specialty Contractor" },
];

export const quantityTypes = ["Gallons", "Cups", "Ounces", "Pounds"];

export const damagedContainerTypes = [
  "Bag",
  "Battery",
  "Bottle",
  "Box",
  "Can",
  "Drum",
  "Gaylord/Cubic Yd Box",
  "Hydraulic Line",
  "Oil Pan",
  "Pail",
  "Radiator",
  "Refrigerated Trailer Fuel Tank",
  "Saddle Tank/Fuel Tank",
  "Super Sack",
  "Tank",
  "Tote",
  "Other"
];

export const damageTypes = [
  "Animal Strike",
  "Burst Hose",
  "Cap Failure",
  "Fork Truck Strike",
  "Freight Stacked on Top",
  "Hard Braking",
  "Improper Blocking & Bracing",
  "Load Shift",
  "No Bracing",
  "Other Fell",
  "Overfill",
  "Overstack",
  "Packaging Failure",
  "Pallet Failure",
  "Pedestrian Strike",
  "Radiator Failure",
  "Road Debris",
  "Unsecured Freight",
  "Vehicle Accident (Single Vehicle)",
  "Vehicle Accident (Multiple Vehicle)",
  "Vehicle Rollover",
  "Valve Damage/Failure",
];

export const searchFieldOptions = [
  { value: 0, label: "Client" },
  { value: 1, label: "Email" },
  { value: 2, label: "Full Name" },
  { value: 3, label: "Parent Organization" },
  { value: 4, label: "Child Organization" },
];

export const activeOptions = [
  { label: "Active" },
  { label: "Inactive" },
  { label: "N/A" },
];
