export const getMostLargestSpills = (obj) => ({
  type: 'GET_MOST_LARGEST_SPILLS',
  obj,
});

export const getMostLargestSpillsSuccess = (data) => ({
  type: 'GET_MOST_LARGEST_SPILLS_SUCCESS',
  data,
});

export const getMostLargestSpillsFail = (err) => ({
  type: 'GET_MOST_LARGEST_SPILLS_FAIL',
  err,
});

export const getWorkInProgress = () => ({
  type: 'GET_WORK_IN_PROGRESS',
});

export const getWorkInProgressSuccess = (data) => ({
  type: 'GET_WORK_IN_PROGRESS_SUCCESS',
  data,
});

export const getWorkInProgressFail = (err) => ({
  type: 'GET_WORK_IN_PROGRESS_FAIL',
  err,
});

export const getCSDailyAfterHours = () => ({
  type: 'GET_CS_DAILY_AFTER_HOURS',
});

export const getCSDailyAfterHoursSuccess = (data) => ({
  type: 'GET_CS_DAILY_AFTER_HOURS_SUCCESS',
  data,
});

export const getCSDailyAfterHoursFail = (err) => ({
  type: 'GET_CS_DAILY_AFTER_HOURS_FAIL',
  err,
});

export const getCSDailyBusinessHours = () => ({
  type: 'GET_CS_DAILY_BUSINESS_HOURS',
});

export const getCSDailyBusinessHoursSuccess = (data) => ({
  type: 'GET_CS_DAILY_BUSINESS_HOURS_SUCCESS',
  data,
});

export const getCSDailyBusinessHoursFail = (err) => ({
  type: 'GET_CS_DAILY_BUSINESS_HOURS_FAIL',
  err,
});

export const getCSIncidentsSince8 = () => ({
  type: 'GET_CS_INCIDENTS_SINCE_8',
});

export const getCSIncidentsSince8Success = (data) => ({
  type: 'GET_CS_INCIDENTS_SINCE_8_SUCCESS',
  data,
});

export const getCSIncidentsSince8Fail = (err) => ({
  type: 'GET_CS_INCIDENTS_SINCE_8_FAIL',
  err,
});

export const getBusinessHoursSpills = () => ({
  type: 'GET_BUSINESS_HOURS_SPILLS',
});

export const getBusinessHoursSpillsSuccess = (data) => ({
  type: 'GET_BUSINESS_HOURS_SPILLS_SUCCESS',
  data,
});

export const getBusinessHoursSpillsFail = (err) => ({
  type: 'GET_BUSINESS_HOURS_SPILLS_FAIL',
  err,
});

export const getCurrentDailyTotal = () => ({
  type: 'GET_CURRENT_DAILY_TOTAL',
});

export const getCurrentDailyTotalSuccess = (data) => ({
  type: 'GET_CURRENT_DAILY_TOTAL_SUCCESS',
  data,
});

export const getCurrentDailyTotalFail = (err) => ({
  type: 'GET_CURRENT_DAILY_TOTAL_FAIL',
  err,
});

export const getCurrentYearSpills = () => ({
  type: 'GET_CURRENT_YEAR_SPILLS',
});

export const getCurrentYearSpillsSuccess = (data) => ({
  type: 'GET_CURRENT_YEAR_SPILLS_SUCCESS',
  data,
});

export const getCurrentYearSpillsFail = (err) => ({
  type: 'GET_CURRENT_YEAR_SPILLS_FAIL',
  err,
});

export const getPreviousYearSpills = (obj) => ({
  type: 'GET_PREVIOUS_YEAR_SPILLS',
  obj,
});

export const getPreviousYearSpillsSuccess = (data) => ({
  type: 'GET_PREVIOUS_YEAR_SPILLS_SUCCESS',
  data,
});

export const getPreviousYearSpillsFail = (err) => ({
  type: 'GET_PREVIOUS_YEAR_SPILLS_FAIL',
  err,
});

export const getCurrentVsPreviousYearSpills = (obj) => ({
  type: 'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS',
  obj,
});

export const getCurrentVsPreviousYearSpillsSuccess = (data) => ({
  type: 'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS_SUCCESS',
  data,
});

export const getCurrentVsPreviousYearSpillsFail = (err) => ({
  type: 'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS_FAIL',
  err,
});

export const getDaysToPaySpills = (obj) => ({
  type: 'GET_DAYS_TO_PAY_SPILLS',
  obj
});

export const getDaysToPaySpillsSuccess = (data) => ({
  type: 'GET_DAYS_TO_PAY_SPILLS_SUCCESS',
  data,
});

export const getDaysToPaySpillsFail = (err) => ({
  type: 'GET_DAYS_TO_PAY_SPILLS_FAIL',
  err,
});

export const getSpillStatusCount = (obj) => ({
  type: 'GET_SPILL_STATUS_COUNT',
  obj,
});

export const getSpillStatusCountSuccess = (data) => ({
  type: 'GET_SPILL_STATUS_COUNT_SUCCESS',
  data,
});

export const getSpillStatusCountFail = (err) => ({
  type: 'GET_SPILL_STATUS_COUNT_FAIL',
  err,
});

export const getAllMonthSpillsCountInYear = (obj) => ({
  type: 'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR',
  obj,
});
export const getAllMonthSpillsCountInYearSuccess = (data) => ({
  type: 'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR_SUCCESS',
  data,
});
export const getAllMonthSpillsCountInYearFail = (err) => ({
  type: 'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR_FAIL',
  err,
});

export const getAllMonthSpillsCountInCurrentVSPreviousYear = () => ({
  type: 'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR',
});
export const getAllMonthSpillsCountInCurrentVSPreviousYearSuccess = (data) => ({
  type: 'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR_SUCCESS',
  data,
});
export const getAllMonthSpillsCountInCurrentVSPreviousYearFail = (err) => ({
  type: 'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR_FAIL',
  err,
});

export const getSpillsOnEachWorkday = (obj) => ({
  type: 'GET_SPILLS_ON_EACH_WORKDAY',
  obj,
});
export const getSpillsOnEachWorkdaySuccess = (data) => ({
  type: 'GET_SPILLS_ON_EACH_WORKDAY_SUCCESS',
  data,
});

export const getSpillsOnEachWorkdayFail = (err) => ({
  type: 'GET_SPILLS_ON_EACH_WORKDAY_FAIL',
  err,
});

export const getSpillsOnEachWeek = (obj) => ({
  type: 'GET_SPILLS_ON_EACH_WEEK',
  obj,
});

export const getSpillsOnEachWeekSuccess = (data) => ({
  type: 'GET_SPILLS_ON_EACH_WEEK_SUCCESS',
  data,
});

export const getSpillsOnEachWeekFail = (err) => ({
  type: 'GET_SPILLS_ON_EACH_WEEK_FAIL',
  err,
});

export const getCurrentMonthSpillsProjection = () => ({
  type: 'GET_CURRENT_MONTH_SPILLS_PROJECTION',
});
export const getCurrentMonthSpillsProjectionSuccess = (data) => ({
  type: 'GET_CURRENT_MONTH_SPILLS_PROJECTION_SUCCESS',
  data,
});

export const getCurrentMonthSpillsProjectionFail = (err) => ({
  type: 'GET_CURRENT_MONTH_SPILLS_PROJECTION_FAIL',
  err,
});

export const getAveragePerDayPerWeek = (obj) => ({
  type: 'GET_AVERAGE_PER_DAY_PER_WEEK',
  obj,
});
export const getAveragePerDayPerWeekSuccess = (data) => ({
  type: 'GET_AVERAGE_PER_DAY_PER_WEEK_SUCCESS',
  data,
});

export const getAveragePerDayPerWeekFail = (err) => ({
  type: 'GET_AVERAGE_PER_DAY_PER_WEEK_FAIL',
  err,
});

export const getFilteredSpillsStatusCount = (obj) => ({
  type: 'GET_FILTERED_SPILLS_STATUS_COUNT',
  obj,
});

export const getFilteredSpillsStatusCountSuccess = (data) => ({
  type: 'GET_FILTERED_SPILLS_STATUS_COUNT_SUCCESS',
  data,
});

export const getFilteredSpillsStatusCountFail = (err) => ({
  type: 'GET_FILTERED_SPILLS_STATUS_COUNT_FAIL',
  err,
});

export const getCurrentVsPreviousMonthSpillAtMonth = (obj) => ({
  type: 'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH',
  obj,
});

export const getCurrentVsPreviousMonthSpillAtMonthSuccess = (data) => ({
  type: 'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH_SUCCESS',
  data,
});

export const getCurrentVsPreviousMonthSpillAtMonthFail = (err) => ({
  type: 'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH_FAIL',
  err,
});

export const getNonHazardousIncidents = (obj) => ({
  type: 'GET_NON_HAZARDOUS_INCIDENTS',
  obj,
});

export const getNonHazardousIncidentsSuccess = (data) => ({
  type: 'GET_NON_HAZARDOUS_INCIDENTS_SUCCESS',
  data,
});

export const getNonHazardousIncidentsFail = (err) => ({
  type: 'GET_NON_HAZARDOUS_INCIDENTS_FAIL',
  err,
});
export const getHazardousIncidents = (obj) => ({
  type: 'GET_HAZARDOUS_INCIDENTS',
  obj,
});

export const getHazardousIncidentsSuccess = (data) => ({
  type: 'GET_HAZARDOUS_INCIDENTS_SUCCESS',
  data,
});

export const getHazardousIncidentsFail = (err) => ({
  type: 'GET_HAZARDOUS_INCIDENTS_FAIL',
  err,
});

export const getNonEmergencyIncidents = (obj) => ({
  type: 'GET_NON_EMERGENCY_INCIDENTS',
  obj,
});

export const getNonEmergencyIncidentsSuccess = (data) => ({
  type: 'GET_NON_EMERGENCY_INCIDENTS_SUCCESS',
  data,
});

export const getNonEmergencyIncidentsFail = (err) => ({
  type: 'GET_NON_EMERGENCY_INCIDENTS_FAIL',
  err,
});

export const getEmergencyIncidents = (obj) => ({
  type: 'GET_EMERGENCY_INCIDENTS',
  obj,
});

export const getEmergencyIncidentsSuccess = (data) => ({
  type: 'GET_EMERGENCY_INCIDENTS_SUCCESS',
  data,
});

export const getEmergencyIncidentsFail = (err) => ({
  type: 'GET_EMERGENCY_INCIDENTS_FAIL',
  err,
});

export const getIncidentsAtLocationTypes = (obj) => ({
  type: 'GET_INCIDENTS_AT_LOCATION_TYPES',
  obj,
});
export const getIncidentsAtLocationTypesSuccess = (data) => ({
  type: 'GET_INCIDENTS_AT_LOCATION_TYPES_SUCCESS',
  data,
});
export const getIncidentsAtLocationTypesFail = (err) => ({
  type: 'GET_INCIDENTS_AT_LOCATION_TYPES_FAIL',
  err,
});

export const getEvacuationIncidentsNumber = (obj) => ({
  type: 'GET_EVACUATION_INCIDENTS_NUMBER',
  obj,
});

export const getEvacuationIncidentsNumberSuccess = (data) => ({
  type: 'GET_EVACUATION_INCIDENTS_NUMBER_SUCCESS',
  data,
});

export const getEvacuationIncidentsNumberFail = (err) => ({
  type: 'GET_EVACUATION_INCIDENTS_NUMBER_FAIL',
  err,
});

export const getDisposalIncidentsNumber = (obj) => ({
  type: 'GET_DISPOSAL_INCIDENTS_NUMBER',
  obj,
});

export const getDisposalIncidentsNumberSuccess = (data) => ({
  type: 'GET_DISPOSAL_INCIDENTS_NUMBER_SUCCESS',
  data,
});

export const getDisposalIncidentsNumberFail = (err) => ({
  type: 'GET_DISPOSAL_INCIDENTS_NUMBER_FAIL',
  err,
});

export const getAvgTimeForExcavation = (obj) => ({
  type: 'GET_AVG_TIME_FOR_EXCAVATION',
  obj,
});

export const getAvgTimeForExcavationSuccess = (data) => ({
  type: 'GET_AVG_TIME_FOR_EXCAVATION_SUCCESS',
  data,
});

export const getAvgTimeForExcavationFail = (err) => ({
  type: 'GET_AVG_TIME_FOR_EXCAVATION_FAIL',
  err,
});

export const get5800FormsDetails = (obj) => ({
  type: 'GET_5800_FORMS_DETAILS',
  obj,
});
export const get5800FormsDetailsSuccess = (data) => ({
  type: 'GET_5800_FORMS_DETAILS_SUCCESS',
  data,
});
export const get5800FormsDetailsFail = (err) => ({
  type: 'GET_5800_FORMS_DETAILS_FAIL',
  err,
});

export const getFetchSpillsStatusesCountRoleBased = (obj) => ({
  type: 'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED',
  obj,
});
export const getFetchSpillsStatusesCountRoleBasedSuccess = (data) => ({
  type: 'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED_SUCCESS',
  data,
});
export const getFetchSpillsStatusesCountRoleBasedFail = (err) => ({
  type: 'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED_FAIL',
  err,
});

export const getTurnedUsDownForContractor = (obj) => ({
  type: 'GET_TURNED_US_DOWN_FOR_CONTRACTOR',
  obj,
});
export const getTurnedUsDownForContractorSuccess = (data) => ({
  type: 'GET_TURNED_US_DOWN_FOR_CONTRACTOR_SUCCESS',
  data,
});
export const getTurnedUsDownForContractorFail = (err) => ({
  type: 'GET_TURNED_US_DOWN_FOR_CONTRACTOR_FAIL',
  err,
});

export const getHazIncidentsClient = (obj) => ({
  type: 'GET_HAZ_INCIDENTS_CLIENT',
  obj,
});
export const getHazIncidentsClientSuccess = (data) => ({
  type: 'GET_HAZ_INCIDENTS_CLIENT_SUCCESS',
  data,
});
export const getHazIncidentsClientFail = (err) => ({
  type: 'GET_HAZ_INCIDENTS_CLIENT_FAIL',
  err,
});

export const getIncidentsReportedClient = (obj) => ({
  type: 'GET_INCIDENTS_REPORTS_CLIENT',
  obj,
});
export const getIncidentsReportedClientSuccess = (data) => ({
  type: 'GET_INCIDENTS_REPORTS_CLIENT_SUCCESS',
  data,
});

export const getIncidentsReportedClientFail = (err) => ({
  type: 'GET_INCIDENTS_REPORTS_CLIENT_Fail',
  err,
});

export const getVehicleIncidents = (obj) => ({
  type: 'GET_VEHICLE_INCIDENTS',
  obj,
});
export const getVehicleIncidentsSuccess = (data) => ({
  type: 'GET_VEHICLE_INCIDENTS_SUCCESS',
  data,
});
export const getVehicleIncidentsFail = (err) => ({
  type: 'GET_VEHICLE_INCIDENTS_FAIL',
  err,
});
export const get5800CompletedClient = (obj) => ({
  type: 'GET_5800_COMPLETED_CLIENT',
  obj,
});

export const get5800CompletedClientSuccess = (data) => ({
  type: 'GET_5800_COMPLETED_CLIENT_SUCCESS',
  data,
});

export const get5800CompletedClientFail = (err) => ({
  type: 'GET_5800_COMPLETED_CLIENT_FAIL',
  err,
});

export const getAverageInvoicesClient = (obj) => ({
  type: 'GET_AVERAGE_INVOICE_CLIENT',
  obj,
});

export const getAverageInvoicesClientSuccess = (data) => ({
  type: 'GET_AVERAGE_INVOICE_CLIENT_SUCCESS',
  data,
});

export const getAverageInvoicesClientFail = (err) => ({
  type: 'GET_AVERAGE_INVOICE_CLIENT_FAIL',
  err,
});

export const getSavingsClient = (obj) => ({
  type: 'GET_SAVINGS_CLIENT',
  obj,
});
export const getSavingsClientSuccess = (data) => ({
  type: 'GET_SAVINGS_CLIENT_SUCCESS',
  data,
});
export const getSavingsClientFail = (err) => ({
  type: 'GET_SAVINGS_CLIENT_FAIL',
  err,
});

export const getIncidentsCompletionTypeStatuses = (obj) => ({
  type: 'GET_INCIDENTS_COMPLETION_TYPE_STATUSES',
  obj,
});

export const getIncidentsCompletionTypeStatusesSuccess = (data) => ({
  type: 'GET_INCIDENTS_COMPLETION_TYPE_STATUSES_SUCCESS',
  data,
});

export const getIncidentsCompletionTypeStatusesFail = (err) => ({
  type: 'GET_INCIDENTS_COMPLETION_TYPE_STATUSES_FAIL',
  err,
});

export const getAverageEta = (obj) => ({
  type: 'GET_AVERAGE_ETA',
  obj,
});
export const getAverageEtaSuccess = (data) => ({
  type: 'GET_AVERAGE_ETA_SUCCESS',
  data,
});
export const getAverageEtaFail = (err) => ({
  type: 'GET_AVERAGE_ETA_FAIL',
  err,
});
export const getAverageIncidentSameContractor = (obj) => ({
  type: 'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR',
  obj,
});
export const getAverageIncidentSameContractorSuccess = (data) => ({
  type: 'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR_SUCCESS',
  data,
});
export const getAverageIncidentSameContractorFail = (err) => ({
  type: 'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR_FAIL',
  err,
});

export const getAllContractorTurnUsDown = (obj) => ({
  type: 'GET_ALL_CONTRACTOR_TURN_US_DOWN',
  obj,
});

export const getAllContractorTurnUsDownSuccess = (data) => ({
  type: 'GET_ALL_CONTRACTOR_TURN_US_DOWN_SUCCESS',
  data,
});

export const getAllContractorTurnUsDownFail = (err) => ({
  type: 'GET_ALL_CONTRACTOR_TURN_US_DOWN_FAIL',
  err,
});

export const getResponsePercentageContractors = (obj) => ({
  type: 'GET_RESPONSE_PERCENTAGE_CONTRACTORS',
  obj,
});
export const getResponsePercentageContractorsSuccess = (data) => ({
  type: 'GET_RESPONSE_PERCENTAGE_CONTRACTORS_SUCCESS',
  data,
});
export const getResponsePercentageContractorsFail = (err) => ({
  type: 'GET_RESPONSE_PERCENTAGE_CONTRACTORS_FAIL',
  err,
});
export const getParticularContractorTurnedUsDown = (obj) => ({
  type: 'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN',
  obj,
});
export const getParticularContractorTurnedUsDownSuccess = (data) => ({
  type: 'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN_SUCCESS',
  data,
});
export const getParticularContractorTurnedUsDownFail = (err) => ({
  type: 'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN_FAIL',
  err,
});

export const getCurrentlyOpenSpills = (obj) => ({
  type: 'GET_CURRENTLY_OPEN_SPILLS',
  obj,
});

export const getCurrentlyOpenSpillsSuccess = (data) => ({
  type: 'GET_CURRENTLY_OPEN_SPILLS_SUCCESS',
  data,
});

export const getCurrentlyOpenSpillsFail = (error) => ({
  type: 'GET_CURRENTLY_OPEN_SPILLS_FAIL',
  error,
});

export const getCurrentlyOpenedSpillsInCurrentYear = (obj) => ({
  type: 'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR',
  obj,
});

export const getCurrentlyOpenedSpillsInCurrentYearSuccess = (data) => ({
  type: 'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR_SUCCESS',
  data,
});

export const getCurrentlyOpenedSpillsInCurrentYearFail = (error) => ({
  type: 'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR_FAIL',
  error,
});

export const getCurrentlyOpenedSpillsInPreviousYear = (obj) => ({
  type: 'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR',
  obj,
});

export const getCurrentlyOpenedSpillsInPreviousYearSuccess = (data) => ({
  type: 'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR_SUCCESS',
  data,
});

export const getCurrentlyOpenedSpillsInPreviousYearFail = (error) => ({
  type: 'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR_FAIL',
  error,
});

export const getSpillsClosedYTD = (obj) => ({
  type: 'GET_SPILLS_CLOSED_YTD',
  obj,
});

export const getSpillsClosedYTDSuccess = (data) => ({
  type: 'GET_SPILLS_CLOSED_YTD_SUCCESS',
  data,
});

export const getSpillsClosedYTDFail = (error) => ({
  type: 'GET_SPILLS_CLOSED_YTD_FAIL',
  error,
});

export const getCurrentlyClosedSpillsCurrentYear = (obj) => ({
  type: 'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR',
  obj,
});

export const getCurrentlyClosedSpillsCurrentYearSuccess = (data) => ({
  type: 'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR_SUCCESS',
  data,
});

export const getCurrentlyClosedSpillsCurrentYearFail = (error) => ({
  type: 'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR_FAIL',
  error,
});

export const getAvgDaysToCloseSpill = (obj) => ({
  type: 'GET_AVG_DAYS_TO_CLOSE_SPILL',
  obj,
});

export const getAvgDaysToCloseSpillSuccess = (data) => ({
  type: 'GET_AVG_DAYS_TO_CLOSE_SPILL_SUCCESS',
  data,
});

export const getAvgDaysToCloseSpillFail = (error) => ({
  type: 'GET_AVG_DAYS_TO_CLOSE_SPILL_FAIL',
  error,
});

export const getWeeklySpillCountForAMonth = (obj) => ({
  type: 'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH',
  obj,
});

export const getWeeklySpillCountForAMonthSuccess = (data) => ({
  type: 'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH_SUCCESS',
  data,
});

export const getWeeklySpillCountForAMonthFail = (error) => ({
  type: 'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH_FAIL',
  error,
});

export const getAfterHoursSpills = () => ({
  type: 'GET_AFTER_HOURS_SPILLS',
});

export const getAfterHoursSpillsSuccess = (data) => ({
  type: 'GET_AFTER_HOURS_SPILLS_SUCCESS',
  data,
});

export const getAfterHoursSpillsFail = (err) => ({
  type: 'GET_AFTER_HOURS_SPILLS_FAIL',
  err,
});

export const getTrackingFileIncidents = () => ({
  type: 'GET_TRACKING_FILES_INCIDENTS',
});

export const getTrackingFileIncidentsSuccess = (data) => ({
  type: 'GET_TRACKING_FILES_INCIDENTS_SUCCESS',
  data,
});

export const getTrackingFileIncidentsFail = (err) => ({
  type: 'GET_TRACKING_FILES_INCIDENTS_FAIL',
  err,
});

export const getPMList = () => ({
  type: 'GET_PM_LIST',
});

export const getPMListSuccess = (data) => ({
  type: 'GET_PM_LIST_SUCCESS',
  data,
});

export const getPMListFail = (err) => ({
  type: 'GET_PM_LIST_FAIL',
  err,
});
export const getCurrentProjectCount = (obj) => ({
  type: 'GET_CURRENT_PROJECT_COUNT',
  obj,
});

export const getCurrentProjectCountSuccess = (data) => ({
  type: 'GET_CURRENT_PROJECT_COUNT_SUCCESS',
  data,
});

export const getCurrentProjectCountFail = (error) => ({
  type: 'GET_CURRENT_PROJECT_COUNT_FAIL',
  error,
});
export const getSpillsBroughtIntoOffice = () => ({
  type: 'GET_SPILLS_BROUGHT_INTO_OFFICE',
});

export const getSpillsBroughtIntoOfficeSuccess = (data) => ({
  type: 'GET_SPILLS_BROUGHT_INTO_OFFICE_SUCCESS',
  data,
});

export const getSpillsBroughtIntoOfficeFail = (err) => ({
  type: 'GET_SPILLS_BROUGHT_INTO_OFFICE_FAIL',
  err,
});

export const getDigsScheduledToday = () => ({
  type: 'GET_DIGS_SCHEDULED_TODAY',
});

export const getDigsScheduledTodaySuccess = (data) => ({
  type: 'GET_DIGS_SCHEDULED_TODAY_SUCCESS',
  data,
});

export const getDigsScheduledTodayFail = (err) => ({
  type: 'GET_DIGS_SCHEDULED_TODAY_FAIL',
  err,
});

export const getNonTrackingWithWorkInProgress = () => ({
  type: 'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS',
});

export const getNonTrackingWithWorkInProgressSuccess = (data) => ({
  type: 'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS_SUCCESS',
  data,
});

export const getNonTrackingWithWorkInProgressFail = (err) => ({
  type: 'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS_FAIL',
  err,
});

export const getPMOpenIncidents = (obj) => ({
  type: 'GET_PM_OPEN_INCIDENTS',
  obj,
});

export const getPMOpenIncidentsSuccess = (data) => ({
  type: 'GET_PM_OPEN_INCIDENTS_SUCCESS',
  data,
});

export const getPMOpenIncidentsFail = (error) => ({
  type: 'GET_PM_OPEN_INCIDENTS_FAIL',
  error,
});

export const getNonTrackingFilesWIP = () => ({
  type: 'GET_NON_TRACKING_FILES_WIP',
});

export const getNonTrackingFilesWIPSuccess = (data) => ({
  type: 'GET_NON_TRACKING_FILES_WIP_SUCCESS',
  data,
});

export const getNonTrackingFilesWIPFail = (err) => ({
  type: 'GET_NON_TRACKING_FILES_WIP_FAIL',
  err,
});

export const getCSCurrentWeekSpills = (obj) => ({
  type: 'GET_CS_CURRENT_WEEK_SPILLS',
  obj,
});

export const getCSCurrentWeekSpillsSuccess = (data) => ({
  type: 'GET_CS_CURRENT_WEEK_SPILLS_SUCCESS',
  data,
});

export const getCSCurrentWeekSpillsFail = (err) => ({
  type: 'GET_CS_CURRENT_WEEK_SPILLS_FAIL',
  err,
});

export const getCSCurrentWeekBusinessHours = (obj) => ({
  type: 'GET_CS_CURRENT_WEEK_BUSINESS_HOURS',
  obj,
});

export const getCSCurrentWeekBusinessHoursSuccess = (data) => ({
  type: 'GET_CS_CURRENT_WEEK_BUSINESS_HOURS_SUCCESS',
  data,
});

export const getCSCurrentWeekBusinessHoursFail = (err) => ({
  type: 'GET_CS_CURRENT_WEEK_BUSINESS_HOURS_FAIL',
  err,
});

export const getCSCurrentWeekAfterHours = (obj) => ({
  type: 'GET_CS_CURRENT_WEEK_AFTER_HOURS',
  obj,
});

export const getCSCurrentWeekAfterHoursSuccess = (data) => ({
  type: 'GET_CS_CURRENT_WEEK_AFTER_HOURS_SUCCESS',
  data,
});

export const getCSCurrentWeekAfterHoursFail = (err) => ({
  type: 'GET_CS_CURRENT_WEEK_AFTER_HOURS_FAIL',
  err,
});

export const getCSCurrentWeekBroughtIntoOffice = (obj) => ({
  type: 'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE',
  obj,
});

export const getCSCurrentWeekBroughtIntoOfficeSuccess = (data) => ({
  type: 'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE_SUCCESS',
  data,
});

export const getCSCurrentWeekBroughtIntoOfficeFail = (err) => ({
  type: 'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE_FAIL',
  err,
});

export const getCSCurrentWeekExcavation = (obj) => ({
  type: 'GET_CS_CURRENT_WEEK_EXCAVATION',
  obj,
});

export const getCSCurrentWeekExcavationSuccess = (data) => ({
  type: 'GET_CS_CURRENT_WEEK_EXCAVATION_SUCCESS',
  data,
});

export const getCSCurrentWeekExcavationFail = (err) => ({
  type: 'GET_CS_CURRENT_WEEK_EXCAVATION_FAIL',
  err,
});

export const getCSCurrentWeekIncidentsTakenHome = (obj) => ({
  type: 'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME',
  obj,
});

export const getCSCurrentWeekIncidentsTakenHomeSuccess = (data) => ({
  type: 'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME_SUCCESS',
  data,
});

export const getCSCurrentWeekIncidentsTakenHomeFail = (err) => ({
  type: 'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME_FAIL',
  err,
});

export const getCSIncidentsTakenHome = (obj) => ({
  type: 'GET_CS_INCIDENTS_TAKEN_HOME',
  obj,
});

export const getCSIncidentsTakenHomeSuccess = (data) => ({
  type: 'GET_CS_INCIDENTS_TAKEN_HOME_SUCCESS',
  data,
});

export const getCSIncidentsTakenHomeFail = (err) => ({
  type: 'GET_CS_INCIDENTS_TAKEN_HOME_FAIL',
  err,
});

export const getDailyWeeklyGisMap = (obj) => ({
  type: 'GET_DAILY_WEEKLY_GIS_MAP',
  obj,
});

export const getDailyWeeklyGisMapSuccess = (data) => ({
  type: 'GET_DAILY_WEEKLY_GIS_MAP_SUCCESS',
  data,
});

export const getDailyWeeklyGisMapFail = (err) => ({
  type: 'GET_DAILY_WEEKLY_GIS_MAP_FAIL',
  err,
});
