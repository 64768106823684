export const download = (obj) => ({
  type: 'DOWNLOAD',
  obj,
});

export const downloadSuccess = (data) => ({
  type: 'DOWNLOAD_SUCCESS',
  data,
});

export const downloadFailure = (err) => ({
  type: 'DOWNLOAD_FAILURE',
  err,
});

export const clearDownload = () => ({
  type: 'CLEAR_DOWNLOAD',
});

export const getSignedUrls = (data) => ({
  type: 'GET_SIGNED_URL',
  data,
});

export const getSignedUrlsSuccess = (data) => ({
  type: 'GET_SIGNED_URL_SUCCESS',
  data,
});

export const getSignedUrlsFail = (data) => ({
  type: 'GET_SIGNED_URL_FAILURE',
  data,
});

export const downloadFileTemp = (data) => ({
  type: 'DOWNLOAD_FILE_TEMP',
  data,
});
export const downloadFile = (data) => ({
  type: 'DOWNLOAD_FILE',
  data,
});

export const downloadFileSuccess = (data) => ({
  type: 'DOWNLOAD_FILE_SUCCESS',
  data,
});

export const downloadFileFail = (err) => ({
  type: 'DOWNLOAD_FILE_FAIL',
  error: err,
});

export const downloadAll = (data) => ({
  type: 'DOWNLOAD_ALL',
  data,
});

export const downloadAdminFiles = (data) => ({
  type: 'DOWNLOAD_ADMIN_FILES',
  data,
});
