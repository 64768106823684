const initialState = {
  success: false,
  loading: false,
  error: null,
  currentAgency: {
    data: null,
    loading: false,
    error: null,
  },
  agencies: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
};

const agencyReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case "RESET_AGENCY":
      return initialState;

    case "GET_AGENCIES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_AGENCIES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        agencies: {
          ...state.agencies,
          data: data.data,
          pagination: { ...data.pagination },
        },
      };

    case "GET_AGENCIES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CREATE_NEW_AGENCY":
      return {
        ...state,
        type,
        loading: true,
        error: null,
      };

    case "CREATE_NEW_AGENCY_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        type,
        payload,
        agencies: {
          ...state.agencies,
          data: [data, ...state.agencies.data],
        },
      };

    case "CREATE_NEW_AGENCY_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "EDIT_AGENCY":
      return {
        ...state,
        type,
        loading: true,
      };

    case "EDIT_AGENCY_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        agencies: {
          ...state.agencies,
          data: state.agencies.data.map((agencyToUpdate) => {
            if (agencyToUpdate.id === data.id) {
              agencyToUpdate = data;
            }
            return agencyToUpdate;
          }),
        },
      };

    case "EDIT_AGENCY_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    default:
      return state;
  }
};

export default agencyReducer;
