export const getClients = (obj) => ({
  type: "GET_CLIENTS",
  obj,
});

export const getClientsSuccess = (data) => ({
  type: "GET_CLIENTS_SUCCESS",
  data,
});

export const getClientsFailure = (err) => ({
  type: "GET_CLIENTS_FAILURE",
  err,
});

export const getParents = (obj) => ({
  type: "GET_PARENTS",
  obj,
});

export const getParentsSuccess = (data) => ({
  type: "GET_PARENTS_SUCCESS",
  data,
});

export const getParentsFailure = (err) => ({
  type: "GET_PARENTS_FAILURE",
  err,
});

export const getChildren = (obj) => ({
  type: "GET_CHILDREN",
  obj,
});

export const getChildrenSuccess = (data) => ({
  type: "GET_CHILDREN_SUCCESS",
  data,
});

export const getChildrenFailure = (err) => ({
  type: "GET_CHILDREN_FAILURE",
  err,
});

export const createNewClient = (obj) => ({
  type: "CREATE_NEW_CLIENT",
  obj,
});

export const createNewClientSuccess = (data) => ({
  type: "CREATE_NEW_CLIENT_SUCCESS",
  data,
});

export const createNewClientFailure = (error) => ({
  type: "CREATE_NEW_CLIENT_FAILURE",
  error,
});

export const editClient = (obj) => ({
  type: "EDIT_CLIENT",
  obj,
});

export const editClientSuccess = (data) => ({
  type: "EDIT_CLIENT_SUCCESS",
  data,
});

export const editClientFailure = (error) => ({
  type: "EDIT_CLIENT_FAILURE",
  error,
});

export const getOrganizationNames = (obj) => ({
  type: "GET_ORGANIZATION_NAMES",
  obj,
});

export const getOrganizationNamesSuccess = (data) => ({
  type: "GET_ORGANIZATION_NAMES_SUCCESS",
  data,
});

export const getOrganizationNamesFailure = (obj) => ({
  type: "GET_ORGANIZATION_NAMES_FAILURE",
  obj,
});

export const getOrganization = (obj) => ({
  type: "GET_ORGANIZATION",
  obj,
});

export const getOrganizationSuccess = (data) => ({
  type: "GET_ORGANIZATION_SUCCESS",
  data,
});

export const getOrganizationFailure = (obj) => ({
  type: "GET_ORGANIZATION_FAILURE",
  obj,
});
export const getFacility = (obj) => ({
  type: "GET_FACILITY",
  obj,
});
export const getFacilityFailure = (obj) => ({
  type: "GET_FACILITY_FAILURE",
  obj,
});
export const getFacilitySuccess = (data) => ({
  type: "GET_FACILITY_SUCCESS",
  data,
});
export const getDisableContaractor = (obj) => ({
  type: "GET_DISABLE_CONTRACTOR",
  obj,
});
export const getDisableContaractorSuccess = (data) => ({
  type: "GET_DISABLE_CONTRACTOR_SUCCESS",
  data,
});
export const getDisableContaractorFailure = (obj) => ({
  type: "GET_DISABLE_CONTRACTOR_FAILURE",
  obj,
});

export const editFacility = (obj) => ({
  type: "EDIT_FACILITY",
  obj,
});

export const editFacilitySuccess = (data) => ({
  type: "EDIT_FACILITY_SUCCESS",
  data,
});

export const editFacilityFailure = (error) => ({
  type: "EDIT_FACILITY_FAILURE",
  error,
});

export const deleteFacility = (obj) => ({
  type: "DELETE_FACILITY",
  obj,
});

export const deleteFacilitySuccess = (data) => ({
  type: "DELETE_FACILITY_SUCCESS",
  data,
});

export const deleteFacilityFailure = (error) => ({
  type: "DELETE_FACILITY_FAILURE",
  error,
});


export const getIcsTerminal = (obj) => ({
  type: "GET_ICS_TERMINAL",
  obj,
});

export const getIcsTerminalSuccess = (data) => ({
  type: "GET_ICS_TERMINAL_SUCCESS",
  data,
});

export const getIcsTerminalFailure = (error) => ({
  type: "GET_ICS_TERMINAL_FAILURE",
  error,
});

export const getIcsTeamTerminal = (obj) => ({
  type: "GET_ICS_TEAM_TERMINAL",
  obj,
});

export const getIcsTeamTerminalSuccess = (data) => ({
  type: "GET_ICS_TEAM_TERMINAL_SUCCESS",
  data,
});

export const getIcsTeamTerminalFailure = (error) => ({
  type: "GET_ICS_TEAM_TERMINAL_FAILURE",
  error,
});