import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import circle from "../../../../../assets/circle.png";
import rect from "../../../../../assets/rect.png";

const AreaCoveredModal = ({
  handleClose,
  releaseCoverages,
  SetReleaseCoverages,
}) => {
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [resultRect, setResultRect] = useState(0);
  const [resultCircle, setResultCircle] = useState(0);
  const [value, setValue] = useState("rectangle");
  const [permeable, setPermeable] = React.useState("impermeable");
  const [currentSection, setCurrentSection] = useState(1);
  const [diameter, setDiameter] = useState("");
  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [diameterError, setDiameterError] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);   
    handleValidationError();
  };
  const FieldHandleChange = (event) => {
    setPermeable(event.target.value);
  };
  const handleNext = () => {
    setCurrentSection(currentSection === 1 ? 2 : 1);
  };

  React.useEffect(() => {
    if (currentSection === 2) {
      if (value === "rectangle" && height && width) {
        calculateRectangleArea();
      } else if (value === "circle" && diameter) {
        calculateCircleArea();
      }
    }
  }, [currentSection, value, height, width, diameter]);
  // Function to handle the calculation
  const calculateRectangleArea = () => {
    const lengthValue = parseFloat(height);
    const widthValue = parseFloat(width);

    if (!isNaN(lengthValue) && !isNaN(widthValue)) {
      const area = lengthValue * widthValue;
      setResultRect(area.toFixed(2));
    } else {
      setResultRect("Invalid input");
    }
  };

  // Function to handle the calculation
  const calculateCircleArea = () => {
    const diameterValue = parseFloat(diameter);
    if (!isNaN(diameterValue)) {
      const area = (Math.PI * Math.pow(diameterValue, 2)) / 4;
      setResultCircle(area.toFixed(2));
    } else {
      setResultCircle("Invalid input");
    }
  };

  const gatherDataForPayload = () => {
    let rectData = {};
    let circleData = {};
    if (value === "rectangle" && height && width) {
      rectData = {
        height: height || null,
        width: width || null,
        covered_area: resultRect || null,
      };
    } else if (value === "circle" && diameter) {
      circleData = {
        diameter: diameter || null,
        covered_area: resultCircle || null,
      };
    }
    if (
      Object.keys(rectData).length > 0 ||
      Object.keys(circleData).length > 0
    ) {
      const data = {
        ...rectData,
        ...circleData,
        footprint_type: value || null,
        surface: permeable || null,
      };
      SetReleaseCoverages([...releaseCoverages, data]);
    }
    setHeight("");
    setWidth("");
    setDiameter("");
    setResultCircle(0);
    setResultRect(0);
    setPermeable("impermeable");
    setValue("rectangle");
    setHeightError("");
    setDiameterError("");
    setWidthError("");
  };
  const handleBack = () => {
    const hasError = handleValidationError();
    if (!hasError) {
      gatherDataForPayload();
      setCurrentSection(1);
    }
  };
  const handleLocationComplete = () => {
    const hasError = handleValidationError();
    if (!hasError) {
      gatherDataForPayload();
      handleClose();
    }
  };

  const handleValidationError = () => {
    let error = false;
    if (value === "rectangle") {
      if (!height) {
        setHeightError("Length should not be blank");
        error = true;
      } else if (parseFloat(height) <= 0) {
        setHeightError("Length should be greater than 0");
        error = true;
      } else {
        setHeightError("");
      }

      if (!width) {
        setWidthError("Width should not be blank");
        error = true;
      } else if (parseFloat(width) <= 0) {
        setWidthError("Width should be greater than 0");
        error = true;
      } else {
        setWidthError("");
      }
    }

    if (value === "circle") {
      if (!diameter) {
        setDiameterError("Diameter should not be blank");
        error = true;
      } else if (parseFloat(diameter) <= 0) {
        setDiameterError("Diameter should be greater than 0");
        error = true;
      } else {
        setDiameterError("");
      }
    }

    return error;
  };

  return (
    <>
      {currentSection === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <FormControl
              component="fieldset"
              className="area-covered-radio-btn"
            >
              <RadioGroup
                aria-label="gender"
                name="footprintradio"
                value={value}
                onChange={handleChange}
                checked
              >
                <div style={{ textAlign: "center" }}>
                  <p className="rectangle"> </p>
                  <FormControlLabel
                    value="rectangle"
                    handleClose
                    control={<Radio />}
                    label="Rectangle"
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <p className="circle" />
                  <FormControlLabel
                    value="circle"
                    control={<Radio />}
                    label="Circle"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              onClick={handleNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}

      {currentSection === 2 && (
        <>
          <form className="footprint-form" autoComplete="off">
            {value === "rectangle" && (
              <>
                <Grid
                  container
                  spacing={3}
                  className="area-covered-validations"
                >
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", marginTop: "20px" }}
                  >
                    <img src={rect}></img>
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Length"
                      fullWidth
                      value={height}
                      type="number"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        if (/^\d*\.?\d{0,4}$/.test(inputValue)) {
                          setHeight(inputValue);
                          setHeightError('');
                        }
                      }}
                      helperText={heightError}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Width"
                      fullWidth
                      value={width}
                      type="number"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        if (/^\d*\.?\d{0,4}$/.test(inputValue)) {
                          setWidth(inputValue);
                          setWidthError('');
                        }
                      }}
                      helperText={widthError}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="fieldRadio"
                        name="fieldRadio"
                        value={permeable}
                        onChange={FieldHandleChange}
                      >
                        <FormControlLabel
                          value="permeable"
                          control={<Radio />}
                          label="Permeable surfaces Soil, Sand Etc"
                        />
                        <FormControlLabel
                          value="impermeable"
                          control={<Radio />}
                          label="Impermeable surfaces asphalt, concrete, steel Etc"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="calculation-row">
                    <div>
                      <TextField
                        label="Result"
                        value={height && width ? resultRect : ""}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <span className="sqft-txt">Sq. Feet</span>
                    </div>
                  </div>
                </Grid>
              </>
            )}
            {value === "circle" && (
              <>
                <Grid
                  container
                  spacing={3}
                  className="area-covered-validations"
                >
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", marginTop: "20px" }}
                  >
                    <img src={circle}></img>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Diameter In feet :"
                      fullWidth
                      type="number"
                      value={diameter}
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        if (/^\d*\.?\d{0,4}$/.test(inputValue)) {
                          setDiameter(inputValue);
                          setDiameterError('');
                        }
                      }}
                      helperText={diameterError}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="fieldRadio"
                        name="fieldRadio"
                        value={permeable}
                        onChange={FieldHandleChange}
                      >
                        <FormControlLabel
                          value="permeable"
                          control={<Radio />}
                          label="Permeable surfaces Soil, Sand Etc"
                        />
                        <FormControlLabel
                          value="impermeable"
                          control={<Radio />}
                          label="Impermeable surfaces asphalt, concrete, steel Etc"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="calculation-row">
                    <div>
                      <TextField
                        label="Result"
                        value={resultCircle}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <span className="sqft-txt">Sq. Feet</span>
                    </div>
                  </div>
                </Grid>
              </>
            )}

            <Grid container>
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", marginTop: "25px" }}
              >
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={handleBack}
                >
                  Enter Another Location
                </Button>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={handleLocationComplete}
                >
                  Location Complete
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default AreaCoveredModal;