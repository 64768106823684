const initialState = {
  success: false,
  loading: false,
  error: null,
  currentContractor: {
    data: null,
    loading: false,
    error: null,
  },
  contractors: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  contractorsWithAddress: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  contractorsLocation: {
    data: null,
    loading: false,
    error: null,
  },
  contractorERailClassICertifications: {
    data: [],
    error: null,
    loading: false,
    success: false,
  },
};

const contractorReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case 'RESET_CONTRACTOR':
      return initialState;

    case 'GET_CONTRACTORS_LOCATION':
      return {
        ...initialState,
        type,
        loading: true,
        contractorsLocation: {
          ...state.contractorsLocation,
          loading: true,
        },
      };

    case 'GET_CONTRACTORS_LOCATION_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        contractorsLocation: {
          ...state.contractorsLocation,
          data,
          loading: false,
        },
      };

    case 'GET_CONTRACTORS_LOCATION_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        contractorsLocation: {
          ...state.contractorsLocation,
          loading: false,
        },
      };

    case 'GET_CONTRACTORS':
      return {
        ...initialState,
        type,
        loading: true,
      };

    case 'GET_CONTRACTORS_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        contractors: {
          ...state.contractors,
          data: data.data,
          pagination: { ...data.pagination },
        },
      };

    case 'GET_CONTRACTORS_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS':
      return {
        ...initialState,
        type,
        contractorERailClassICertifications: {
          ...state.contractorERailClassICertifications,
          loading: true,
        },
      };

    case 'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS_SUCCESS':
      return {
        ...state,
        type,
        payload,
        contractorERailClassICertifications: {
          ...state.contractorERailClassICertifications,
          loading: false,
          data: data,
          success: true,
        },
      };

    case 'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS_FAIL':
      return {
        ...state,
        type,
        contractorERailClassICertifications: {
          ...state.contractorERailClassICertifications,
          error,
          loading: false,
          success: false,
        },
      };

    case 'CREATE_NEW_CONTRACTOR':
      return {
        ...state,
        type,
        loading: true,
        error: null,
      };

    case 'CREATE_NEW_CONTRACTOR_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        type,
        error: null,
        payload,
        contractors: {
          ...state.contractors,
          data: [data, ...state.contractors.data],
        },
      };

    case 'CREATE_NEW_CONTRACTOR_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'EDIT_CONTRACTOR':
      return {
        ...state,
        type,
        loading: true,
        error: null,
      };

    case 'EDIT_CONTRACTOR_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        type,
        payload,
        contractors: {
          ...state.contractors,
          data: state.contractors.data.map((contractorToUpdate) => {
            if (contractorToUpdate.id === data.id) {
              contractorToUpdate = data;
            }
            return contractorToUpdate;
          }),
        },
      };

    case 'EDIT_CONTRACTOR_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };
      case 'DELETE_CONTRACTOR_CERTIFICATE':
        return {
          ...state,
          type,
          loading: true,
          error: null,
        };

    case 'DELETE_CONTRACTOR_CERTIFICATE_SUCCESS':
      return{
        ...state,
        loading: false,
        success: true,
        error: null,
        type,
        payload,
        contractors: {
          ...state.contractors,
          deleteCertificate: true
        },
      }

    case 'DELETE_CONTRACTOR_CERTIFICATE_FAIL':
        return {
          ...state,
          error,
          success: false,
          loading: false,
          type,
        };
    
    case 'GET_CONTRACTORS_WITH_ADDRESS':
      return {
        ...initialState,
        type,
        loading: true,
      };

    case 'GET_CONTRACTORS_WITH_ADDRESS_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        contractorsWithAddress: {
          ...state.contractorsWithAddress,
          data: data,
        },
      };

    case 'GET_CONTRACTORS_WITH_ADDRESS_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'UPLOAD_CERTIFICATE':
      return {
        ...state,
        error,
        success: false,
        loading: true,
      };
    case 'UPLOAD_CERTIFICATE_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        type,
        payload,
        contractors: {
          ...state.contractors,
          data: state.contractors.data.map((contractorToUpdate) => {
            if (contractorToUpdate.id == data.contractorID) {
              contractorToUpdate.GenericAttachedDocuments = data.data;
            }
            return contractorToUpdate;
          }),
        },
      };

    case 'UPLOAD_CERTIFICATE_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
      };

    case 'CONTRACTOR_DO_NOT_USE':
      return {
        ...state,
        type,
        loading: true,
        error: null,
      };

    case 'CONTRACTOR_DO_NOT_USE_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        type,
        error: null,
        payload,
        contractors: {
          ...state.contractors,
          data: [data, ...state.contractors.data],
        },
      };

    case 'CONTRACTOR_DO_NOT_USE_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };
  
    default:
      return state;
  }
};

export default contractorReducer;
