const initialState = {
  success: false,
  loading: false,
  error: null,
  searchType: null,
  searchText: "",
  searching: false,
  searchPagination: {},
  searchedData: {
    data: [],
    loading: false,
    error: null,
  },
};

const searchReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case "SEARCH":
      return {
        ...initialState,
        searchText: obj.searchText,
        type,
        loading: true,
      };

    case "SEARCH_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        searching: true,
        searchType: data.type,
        searchPagination: { ...data.pagination },
        searchedData: {
          ...state.searchedData,
          data: data.data,
        },
      };

    case "SEARCH_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CLEAR_SEARCH":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        searching: false,
        searchText: "",
        searchType: null,
        searchedData: {
          data: [],
          loading: false,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default searchReducer;
