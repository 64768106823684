import React from 'react';
import { DetailLvl1 } from '.';

const TabDetail = ({ detailObj }) => {
  return (
    <>
      {detailObj.map((block) => (
        <DetailLvl1
          heading={block.heading}
          subValues={block.subValues}
          // allowCloseSpill={allowCloseSpill}
        />
      ))}
    </>
  );
};

export default TabDetail;
