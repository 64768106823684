import { call, put, all, takeLatest } from 'redux-saga/effects';

import {
  getContractorsSuccess,
  getContractorsFail,
  createNewContractorSuccess,
  createNewContractorFail,
  editContractorSuccess,
  editContractorFail,
  getContractorsWithAddressSuccess,
  getContractorsWithAddressFail,
  getContractorsLocationSuccess,
  getContractorsLocationFail,
  getContractorERailClassICertificationsSuccess,
  getContractorERailClassICertificationsFail,
  uploadCertificateSuccess,
  uploadCertificateFail,
  deleteContractorCertificateSuccess,
  deleteContractorCertificateFail,
  contractorDoNotUseSuccess,
  contractorDoNotUseFail,
} from '../actionCreators/Contractor';

import { doGet, doPost } from '../utils/request';

function* getContractors({ obj }) {
  try {
    const response = yield doGet(`/contractor/contractors?page=${obj.page}`);

    yield put(getContractorsSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getContractorsFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getContractorsFail());
  }
}

function* createNewContractor({ obj }) {
  try {
    const response = yield doPost('/contractor/createNew', obj);

    yield put(createNewContractorSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      createNewContractorFail(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
    yield put(createNewContractorFail());
  }
}

function* editContractor({ obj }) {
  try {
    const response = yield doPost('/contractor/editContractor', obj);

    yield put(editContractorSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editContractorFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editContractorFail());
  }
}

function* getContractorsWithAddress({obj}) {
  try {
    const response =  yield doGet(`/contractors/withAddress${obj ? '?' + new URLSearchParams(obj).toString(): ''}`);
    yield put(getContractorsWithAddressSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getContractorsWithAddressFail(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
    yield put(getContractorsWithAddressFail());
  }
}

function* getContractorsLocation() {
  try {
    const response = yield doGet(`/contractors/locations`, [], 'ANALYTICS');

    yield put(getContractorsLocationSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getContractorsLocationFail(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
    yield put(getContractorsLocationFail());
  }
}

function* getContractorERailClassICertifications() {
  try {
    const response = yield doGet(
      `/contractor/contractorERailClassICertifications`
    );

    yield put(getContractorERailClassICertificationsSuccess(response.data));
  } catch (err) {
    yield put(
      getContractorERailClassICertificationsFail(
        err?.response?.data?.message || err.message
      )
    );
  }
}
function* uploadCertificate({ obj }) {
  try {
    const response = yield doPost('/contractor/contractorCertficates', obj);

    yield put(uploadCertificateSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(uploadCertificateFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(uploadCertificateFail());
  }
}

function* deleteContractorCertificate({ obj }) {
  try {
    const response = yield doPost(
      '/contractor/deleteContractorCertficates',
      obj
    );

    yield put(deleteContractorCertificateSuccess(response?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      deleteContractorCertificateFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield call(delay, 2000);
    yield put(deleteContractorCertificateFail());
  }
}

function* contractorDoNotUse({ obj }) {
  try {
    const response = yield doPost('/contractor/do-not-use', obj);

    yield put(contractorDoNotUseSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      contractorDoNotUseFail(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
    yield put(contractorDoNotUseFail());
  }
}


export default function* watcher() {
  yield all([takeLatest('UPLOAD_CERTIFICATE', uploadCertificate)]);
  yield all([takeLatest('GET_CONTRACTORS', getContractors)]);
  yield all([takeLatest('CREATE_NEW_CONTRACTOR', createNewContractor)]);
  yield all([takeLatest('EDIT_CONTRACTOR', editContractor)]);
  yield all([
    takeLatest('GET_CONTRACTORS_WITH_ADDRESS', getContractorsWithAddress),
  ]);
  yield all([takeLatest('GET_CONTRACTORS_LOCATION', getContractorsLocation)]);
  yield all([
    takeLatest(
      'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS',
      getContractorERailClassICertifications
    ),
  ]);
  yield all([
    takeLatest('DELETE_CONTRACTOR_CERTIFICATE', deleteContractorCertificate),
  ]);
  yield all([
    takeLatest('CONTRACTOR_DO_NOT_USE', contractorDoNotUse),
  ]);
}
