import { Typography } from "@material-ui/core";
import React from "react";
import { isObject } from "../../utils/dataProcessors";

const DetailLvl2 = ({ value }) => {
  return value ? (
    isObject(value) ? (
      <Typography variant={"subtitle2"}>
        {value.heading}
        {value.heading[value.heading.length - 1] !== "?" && ":"}{" "}
        {value.value || "Not added"}
      </Typography>
    ) : (
      value && (
        <Typography variant={"subtitle2"}>
          {Array.isArray(value)
            ? value
                .map((subVal) => subVal || "")
                .filter((e) => e)
                .join(", ")
            : value}
        </Typography>
      )
    )
  ) : (
    <Typography variant={"subtitle2"}>Not Added</Typography>
  );
};

export default DetailLvl2;
