import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import AgencyImage from "../../../../assets/agency-image.jpg";
import ContractorsImage from "../../../../assets/contractor-image.jpg";
import ClientImage from "../../../../assets/clients-image.png";
import UsersImage from "../../../../assets/users-image.jpg";
import ServicesImage from "../../../../assets/Services-image.jpg";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 245,
  },
});

const Component = ({ history }) => {
  const classes = useStyles();
  document.title = "Admin";

  return (
    <Grid container spacing={4}>
      <Grid item xs={24} md={4}>
        <Card
          className={classes.root}
          onClick={() => history.push("/dashboard/agencies")}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Agencies"
              height="140"
              image={AgencyImage}
              title="Agencies"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Agencies
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Premium Environmental Services have multiple agencies on panel,
                You can view, edit their details, also can download the CSV file
                of all list of agencies along with their details.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={24} md={4}>
        <Card
          className={classes.root}
          onClick={() => history.push("/dashboard/contractors")}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Contractors"
              height="140"
              image={ContractorsImage}
              title="Contractors"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Contractors
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Premium Environmental Services have multiple Contractors on
                panel, You can view, edit their details, also can download the
                CSV file of all list along with their details.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={24} md={4}>
        {" "}
        <Card
          className={classes.root}
          onClick={() => history.push("/dashboard/clients")}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Clients"
              height="140"
              image={ClientImage}
              title="Clients"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Clients
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Premium Environmental Services have multiple Clients on panel,
                You can view, edit their details, also can download the CSV file
                of all list of Clients along with their details.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={24} md={4}>
        {" "}
        <Card
          className={classes.root}
          onClick={() => history.push("/dashboard/users")}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Users"
              height="140"
              image={UsersImage}
              title="Users"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Users
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Premium Environmental Services have multiple Users on panel, You
                can view, edit their details, also can download the CSV file of
                all list of Users along with their details.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={24} md={4}>
        {" "}
        <Card
          className={classes.root}
          onClick={() => history.push("/dashboard/services")}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Services"
              height="140"
              image={ServicesImage}
              title="Services"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Services
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Premium Environmental Services provide multiple Services on
                panel, You can view, edit their details. These services are used
                by client organizations during incidents
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withRouter(Component);
