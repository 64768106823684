import axios from 'axios';
import { UNAUTHORIZED } from 'http-status-codes';

const baseURL = process.env.REACT_APP_API_URL;
const downloadURL = process.env.REACT_APP_API_URL_DOWNLOAD;
const uploadURL = process.env.REACT_APP_API_URL_UPLOAD;
const analyticsURL = process.env.REACT_APP_API_URL_ANALYTICS;
const icsURL = process.env.REACT_APP_API_URL_ICS;

const TYPE = {
  NORMAL: baseURL,
  DOWNLOAD: downloadURL,
  UPLOAD: uploadURL,
  ANALYTICS: analyticsURL,
  ICS: icsURL
};

export const doGet = (path, options, type) =>
  new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL: type ? TYPE[type] : baseURL,
      method: 'GET',
      withCredentials: true,
      headers: {
        'access-token': JSON.parse(localStorage.getItem('access-token')),
      },
      ...options,
    })
      .then((result) => resolve(result))
      .catch((err) => {
        if (String(path).toLowerCase() !== '/user/me') {
          try {
            if (err.response?.status === UNAUTHORIZED) {
              window.location.reload();
            }
          } catch (error) {
            console.log(error);
          }
          reject(err);
        } else {
          reject(err);
        }
      })
  );

export const doPost = (path, data, options, type) => {
  return new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL: type ? TYPE[type] : baseURL,
      method: 'POST',
      withCredentials: true,
      data,
      headers: {
        'access-token': JSON.parse(localStorage.getItem('access-token')),
      },
      ...options,
    })
      .then((result) => resolve(result))
      .catch((err) => {
        if (String(path).toLowerCase() !== '/user/me') {
          if (err.response?.status === UNAUTHORIZED) {
            window.location.reload();
          }
          reject(err);
        }
      })
  );
};

export const doPatch = (path, data, type) => {
  return new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL: type ? TYPE[type] : baseURL,
      method: 'PATCH',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
      .then((result) => resolve(result))
      .catch((err) => {
        if (String(path).toLowerCase() !== '/user/me') {
          if (err.response?.status === UNAUTHORIZED) {
            window.location.reload();
          }
          reject(err);
        }
      })
  );
};

export const doDelete = (path, data, type) => 
  new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL: type ? TYPE[type] : baseURL,
      method: 'DELETE',
      withCredentials: true,
      data
    })
      .then((result) => resolve(result))
      .catch((err) => {
        if (String(path).toLowerCase() !== '/user/me') {
          if (err.response?.status === UNAUTHORIZED) {
            window.location.reload();
          }

          reject(err);
        }
      })
  );

export const doGetAll = (paths, options, type) => {
  const promisesArray = [];
  paths.forEach((item) => {
    promisesArray.push(
      new Promise((resolve, reject) =>
        axios({
          url: item,
          baseURL: type ? TYPE[type] : baseURL,
          method: 'GET',
          withCredentials: true,
          headers: {
            'access-token': JSON.parse(localStorage.getItem('access-token')),
          },
          ...options,
        })
          .then((result) => resolve(result))
          .catch((err) => {
            if (String(item).toLowerCase() !== '/user/me') {
              try {
                if (err.response?.status === UNAUTHORIZED) {
                  window.location.reload();
                }
              } catch (err) {
                console.log(err);
              }
              reject(err);
            } else {
              reject(err);
            }
          })
      )
    );
  });
  return Promise.all(promisesArray);
};
