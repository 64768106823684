import { call, all, put, takeLatest, take } from 'redux-saga/effects';

import {
  logInSuccess,
  logInFail,
  logOutSuccess,
  logOutFail,
  signUpSuccess,
  signUpFail,
  getCurrentUserSuccess,
  getCurrentUserFail,
  editUserProfileSuccess,
  editUserProfileFail,
  editUserEmailSuccess,
  editUserEmailFail,
  editUserPasswordSuccess,
  editUserPasswordFail,
  createNewUserSuccess,
  createNewUserFailure,
  editUserSuccess,
  editUserFailure,
  getUsersSuccess,
  getUsersFailure,
  getUsersByOrgIdSuccess,
  getUsersByOrgIdFailure,
  getAdminsSuccess,
  getAdminsFailure,
  getSalePersonsSuccess,
  getSalePersonsFailure,
  getOrganizationAdminsSuccess,
  getOrganizationAdminsFailure,
  getUserRolesSuccess,
  getUserRolesFailure,
  searchUserSuccess,
  searchUserFailure,
  setNotificationPreferenceSuccess,
  setNotificationPreferenceFailure,
  getAssociatedServicesSuccess,
  getAssociatedServicesFailure,
  checkInSpillsSuccess,
  checkInSpillsFailure,
  getUsersForEmailSuccess,
  getUsersForEmailFailure,
  updateEulaSuccess,
  updateEulaFailure,
  showErrorSnackbar,
  editUserLicenseSuccess,
  editUserLicenseFail,
  fetchAttachmentTypesWithExpiryFail,
  fetchAttachmentTypesWithExpirySuccess,
  updateUserAttachmentExpirySuccess,
  updateUserAttachmentExpiryFail,
  fetchPacketReviewersSuccess,
  fetchPacketReviewersFail,
} from '../actionCreators/User';
import { doGet, doPost } from '../utils/request';

function* dosignUp({ obj, history }) {
  try {
    const response = yield doPost('/users/signup', obj);
    yield put(signUpSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(signUpFail(err.response.data.message));
    yield call(delay, 2000);
    yield put(signUpFail());
  }
}

function* doLogIn({
  obj,
  history,
  redirectUrl,
  becomeUser = false,
  userToBecomeId = '',
}) {
  try {
    const endpoint = becomeUser
      ? `user/become_user/${userToBecomeId}`
      : '/user/login';
    const response = yield doPost(endpoint, obj);

    yield put(logInSuccess(response.data));

    if (response?.data && becomeUser) {
      localStorage.setItem('isDefaultButtonVisible', 'true');
    }

    if (redirectUrl && response?.data?.eula_accepted === true) {
      history.push(redirectUrl);
    } else {
      if (response?.data?.eula_accepted === true) {
        history.push('/dashboard');
      }
    }
  } catch (err) {
    if (err?.response?.data && err?.response?.data?.expired) {
      const delay = (time) =>
        new Promise((resolve) => setTimeout(resolve, time));
      yield put(logInFail(err.response.data.message));
      yield call(delay, 9000);
      yield put(logInFail());
    } else {
      const delay = (time) =>
        new Promise((resolve) => setTimeout(resolve, time));
      yield put(logInFail(err.response.data.message));
      yield call(delay, 2000);
      yield put(logInFail());
    }
  }
}

function* doBecomeDefault({ redirectUrl, history, isDefaultButtonVisible }) {
  try {
    const endpoint = '/user/become_default';
    const response = yield doPost(endpoint, {});

    yield put(logInSuccess(response.data));

    if (response?.data) {
      localStorage.setItem('isDefaultButtonVisible', 'false');
      isDefaultButtonVisible(false);
    }

    if (redirectUrl && response?.data?.eula_accepted === true) {
      history.push(redirectUrl);
    } else {
      if (response?.data?.eula_accepted === true) {
        history.push(redirectUrl);
      }
    }
  } catch (err) {
    if (err?.response?.data && err?.response?.data?.expired) {
      const delay = (time) =>
        new Promise((resolve) => setTimeout(resolve, time));
      yield put(logInFail(err.response.data.message));
      yield call(delay, 9000);
      yield put(logInFail());
    } else {
      const delay = (time) =>
        new Promise((resolve) => setTimeout(resolve, time));
      yield put(logInFail(err.response.data.message));
      yield call(delay, 2000);
      yield put(logInFail());
    }
  }
}

function* doLogOut({ history }) {
  try {
    yield doPost('/user/logout');

    yield put(logOutSuccess());
    localStorage.clear();

    history.push('/');
  } catch (err) {
    yield put(logOutFail(err.response.data.message));
  }
}

function* getCurrentUser({ history, redirectUrl }) {
  try {
    const response = yield doGet('/user/me');

    const { active } = response.data;
    if (!active) {
      yield put(
        showErrorSnackbar({
          saverity: 'error',
          open: true,
          message:
            'Your account has been disabled. Please contact Administrator for further assistance',
        })
      );

      yield doPost('/user/logout');

      yield put(logOutSuccess());
      history.push('/');
    } else {
      yield put(getCurrentUserSuccess(response.data));

      if (redirectUrl && redirectUrl !== '/') {
        const { location } = history;
        if (location.search) {
          history.push(`${redirectUrl}${location.search}`);
        } else {
          history.push(redirectUrl);
        }
      }
    }
  } catch (err) {
    localStorage.clear();
    yield put(getCurrentUserFail(err.message));
  }
}

function* doEditUserProfile({ obj }) {
  try {
    const response = yield doPost(`/user/editProfile?id=${obj.id}`, obj);
    yield put(editUserProfileSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editUserProfileFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editUserProfileFail());
  }
}

function* doEditUserEmail({ obj }) {
  try {
    const response = yield doPost('/user/editEmail', obj);
    yield put(editUserEmailSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editUserEmailFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editUserEmailFail());
  }
}

function* editUserLicense({ obj }) {
  try {
    const response = yield doPost('/user/editUserLicense', obj);
    yield put(editUserLicenseSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editUserLicenseFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editUserLicenseFail());
  }
}

function* doEditUserPassword({ obj }) {
  try {
    const response = yield doPost('/user/changePassword', obj);
    yield put(editUserPasswordSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editUserPasswordFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editUserPasswordFail());
  }
}

function* doUpdateEula({ obj }) {
  try {
    const response = yield doPost('/user/updateEula', obj);
    yield put(updateEulaSuccess(response.body));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(updateEulaFailure(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(updateEulaFailure());
  }
}

function* checkInSpills({ obj }) {
  try {
    const response = yield doGet(`/user/checkInSpills?userId=${obj.userId}`);

    yield put(checkInSpillsSuccess(response.data));
  } catch (err) {
    yield put(checkInSpillsFailure(err.response.data.message || err.message));
    yield put(checkInSpillsFailure());
  }
}

function* getUsers({ obj }) {
  try {
    const response = yield doGet(
      `/user/all?page=${obj.page || obj.page === 0 ? obj.page : 'all'}`
    );

    yield put(getUsersSuccess(response.data));
  } catch (err) {
    yield put(getUsersFailure(err.response.data.message || err.message));
    yield put(getUsersFailure());
  }
}

function* createNewUser({ obj }) {
  try {
    const response = yield doPost('/user/add', obj);

    yield put(createNewUserSuccess(response.data.user));
  } catch (err) {
    yield put(createNewUserFailure(err.response.data.message || err.message));
    yield put(createNewUserFailure());
  }
}

function* editUser({ obj }) {
  try {
    const response = yield doPost('/user/edit', obj);

    yield put(editUserSuccess(response.data.data));
  } catch (err) {
    yield put(editUserFailure(err.response.data.message || err.message));
    yield put(editUserFailure());
  }
}

function* getUsersByOrgId({ obj }) {
  try {
    const response = yield doPost('/user/orgId', obj);

    yield put(getUsersByOrgIdSuccess(response.data.data));
  } catch (err) {
    yield put(getUsersByOrgIdFailure(err.response.data.message || err.message));
    yield put(getUsersByOrgIdFailure());
  }
}

function* getAdmins(param) {
  try {
    const temp = param.obj.filter && param.obj.filter === 'all';
    const response = yield doGet(
      `/user/list?all=${temp}&type=${param?.obj?.scenario || 'Other'}`
    );

    yield put(getAdminsSuccess(response?.data?.data));
  } catch (err) {
    yield put(getAdminsFailure(err.response?.data.message || err.message));
    yield put(getAdminsFailure());
  }
}

function* getSalePersons() {
  try {
    const response = yield doGet('/user/salePersons');

    yield put(getSalePersonsSuccess(response.data.data));
  } catch (err) {
    yield put(getSalePersonsFailure(err.response.data.message || err.message));
    yield put(getSalePersonsFailure());
  }
}

function* getUsersForEmail() {
  try {
    const response = yield doGet('/user/getUsersForEmail');

    yield put(getUsersForEmailSuccess(response.data.data));
  } catch (err) {
    yield put(
      getUsersForEmailFailure(err.response.data.message || err.message)
    );
    yield put(getUsersForEmailFailure());
  }
}
function* getOrganizationAdmins() {
  try {
    const response = yield doGet('/user/organizationAdmins');

    yield put(getOrganizationAdminsSuccess(response.data.data));
  } catch (err) {
    yield put(
      getOrganizationAdminsFailure(err.response.data.message || err.message)
    );
    yield put(getOrganizationAdminsFailure());
  }
}

function* getUserRoles() {
  try {
    const response = yield doGet('/user/roles');

    yield put(getUserRolesSuccess(response.data.data));
  } catch (err) {
    yield put(getUserRolesFailure(err.response.data.message || err.message));
    yield put(getUserRolesFailure());
  }
}

function* searchUser({ obj }) {
  try {
    const response = yield doPost('/user/search', obj);

    yield put(searchUserSuccess(response.data.data));
  } catch (err) {
    yield put(searchUserFailure(err.response.data.message || err.message));
    yield put(searchUserFailure());
  }
}

function* setNotificationPreference({ obj }) {
  try {
    const response = yield doPost('/user/notification', obj);

    yield put(setNotificationPreferenceSuccess(response.data.data));
  } catch (err) {
    yield put(
      setNotificationPreferenceFailure(err.response.data.message || err.message)
    );
    yield put(setNotificationPreferenceFailure());
  }
}

function* getAssociatedServices({ obj }) {
  try {
    const response = yield doPost('/user/associatedServices', obj);

    yield put(getAssociatedServicesSuccess(response.data));
  } catch (err) {
    yield put(
      getAssociatedServicesFailure(err.response.data.message || err.message)
    );
    yield put(getAssociatedServicesFailure());
  }
}

function* fetchAttachmentTypesWithExpiry({ obj }) {
  try {
    const response = yield doGet(
      `/user/fetchAttachmentTypes?userId=${obj.userId}`
    );

    yield put(fetchAttachmentTypesWithExpirySuccess(response.data));
  } catch (err) {
    yield put(
      fetchAttachmentTypesWithExpiryFail(
        err.response.data.message || err.message
      )
    );
  }
}

function* updateUserAttachmentExpiry({ obj }) {
  try {
    const response = yield doPost('/user/updateUserAttachmentExpiry', obj);

    yield put(updateUserAttachmentExpirySuccess(response.data));
  } catch (err) {
    yield put(
      updateUserAttachmentExpiryFail(err.response.data.message || err.message)
    );
  }
}

function* fetchPacketReviewers() {
  try {
    const response = yield doGet('/user/getPacketReviewers');
    yield put(fetchPacketReviewersSuccess(response?.data));
  } catch (err) {
    yield put(
      fetchPacketReviewersFail(err.response.data.message || err.message)
    );
  }
}

export default function* watcher() {
  yield all([
    takeLatest('SIGNUP', dosignUp),
    takeLatest('LOGIN', doLogIn),
    takeLatest('BECOME_DEFAULT', doBecomeDefault),
    takeLatest('LOGOUT', doLogOut),
    takeLatest('GET_CURRENT_USER', getCurrentUser),
    takeLatest('EDIT_USER_PROFILE', doEditUserProfile),
    takeLatest('EDIT_USER_EMAIL', doEditUserEmail),
    takeLatest('EDIT_USER_LICENSE', editUserLicense),
    takeLatest('EDIT_USER_PASSWORD', doEditUserPassword),
    takeLatest('CHECK_IN_SPILLS', checkInSpills),
    takeLatest('GET_USERS', getUsers),
    takeLatest('CREATE_NEW_USER', createNewUser),
    takeLatest('EDIT_USER', editUser),
    takeLatest('GET_USERS_BY_ORG_ID', getUsersByOrgId),
    takeLatest('GET_ADMINS', getAdmins),
    takeLatest('GET_SALE_PERSONS', getSalePersons),
    takeLatest('GET_ORGANIZATION_ADMINS', getOrganizationAdmins),
    takeLatest('GET_ROLES', getUserRoles),
    takeLatest('SEARCH_USER', searchUser),
    takeLatest('SET_NOTIFICATION_PREFERENCE', setNotificationPreference),
    takeLatest('GET_ASSOCIATED_SERVICES', getAssociatedServices),
    takeLatest('GET_USERS_FOR_EMAIL', getUsersForEmail),
    takeLatest('UPDATE_EULA', doUpdateEula),
    takeLatest(
      'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY',
      fetchAttachmentTypesWithExpiry
    ),
    takeLatest('UPDATE_USER_ATTACHMENT_EXPIRY', updateUserAttachmentExpiry),
    takeLatest('FETCH_PACKET_REVIEWERS', fetchPacketReviewers),
  ]);
}
