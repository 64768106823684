import React, { useState } from 'react';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    marginBottom: 10,
  },
  questionContainer: {
    marginBottom: 10,
  },
  button: {
    transition: 'all .4s',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '90%',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
  titleAllignment: {
    'text-align': 'left',
  },
  containerWidth: {
    width: '590px',
  },
}));

const Prompt = ({
  response,
  question,
  acceptText,
  rejectText,
  headingText,
  isClintOrg,
  isSpillVoid,
}) => {
  const classes = useStyles();
  const [buttonDisable, setButtonDisable] = useState(false)
  return (
    <Grid
      container
      className={`${(isClintOrg || isSpillVoid) && classes.containerWidth}`}
    >
      <Grid
        item
        xs={12}
        className={`${classes.headingContainer}  ${
          (isClintOrg || isSpillVoid) && classes.titleAllignment
        }`}
      >
        <Typography variant={'h6'}>
          {isClintOrg || isSpillVoid ? <b>{headingText}</b> : headingText}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.questionContainer}>
        <Typography variant={'body1'}>
          {isClintOrg || isSpillVoid ? question?.question : question}
        </Typography>
        {isClintOrg || isSpillVoid ? <b>{question?.note}</b> : null}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8} />
          <Grid item xs={2}>
            <Button
              variant={'outlined'}
              color={'primary'}
              disabled={buttonDisable}
              onClick={() => {
                setButtonDisable(true)
                response(false)
              }}
              className={classes.button}
            >
              {rejectText ?? 'Cancel'}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant={'outlined'}
              color={'primary'}
              disabled={buttonDisable}
              onClick={() => {
                setButtonDisable(true)
                response(true)
              }}
              className={classes.button}
            >
              {acceptText ?? 'OK'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Prompt;
