import React ,{ useState, useEffect }from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const CustomSnackBar = ({
  open,
  autoHideDuration = 5000,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  severity,
  snackBarMessage,
  onClose = () => {},
}) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  const [snackbarOpen, setSnackbarOpen] = useState(open);

  useEffect(() => {
    setSnackbarOpen(open);
  }, [open]);

  useEffect(() => {
    let timer;
    if (snackbarOpen) {
      timer = setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [snackbarOpen, autoHideDuration]);
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      open={snackbarOpen}
      anchorOrigin={anchorOrigin}
      className="error_snackbar" 
      onClose={() => {
        setSnackbarOpen(false);
        onClose();
      }}
    >
      <Alert severity={severity}>{snackBarMessage}</Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;
