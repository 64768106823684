import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as serviceActions from '../../../../../actionCreators/Service';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    borderRadius: '10px',
  },
  headerColor: {
    color: 'green',
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  defaultCheckMargin: {
    textTransform: 'capitalize',
    marginRight: '5px',
  },
  errorText:{
    textAlign: 'left',
    marginTop: '0',
    color: '#f44336',
    fontSize:'0.75rem',
    marginBottom:'0'
  },
}));

const NewService = ({
  createNewService,
  closeAddService,
  totalServices,
  currentService,
  editService,
  currentUser,
  getCategories,
  service,
}) => {
  const classes = useStyles();

  const [serviceOptions, setServiceOptions] = React.useState({
    report_number: {
      value: false,
      label: 'Report number',
      name: 'report_number',
    },
    ip_address_identifier: {
      value: false,
      label: 'IP address identifier',
      name: 'ip_address_identifier',
    },
    owner: { value: false, label: 'Owner', name: 'owner' },
    salvage_containers: {
      value: false,
      label: 'Salvage containers',
      name: 'salvage_containers',
    },
    samples: { value: false, label: 'Samples', name: 'samples' },
    cost: { value: false, label: 'Cost', name: 'cost' },
    establish_lane_closure: {
      value: false,
      label: 'Lane Closure Established',
      name: 'establish_lane_closure',
    },
    excavation_begun: {
      value: false,
      label: 'Excavation begun',
      name: 'excavation_begun',
    },
    excavation_completed: {
      value: false,
      label: 'Excavation completed',
      name: 'excavation_completed',
    },
    task_associated_relevance: {
      value: false,
      label: 'Task associated relevance',
      name: 'task_associated_relevance',
    },
    time: { value: false, label: 'Time', name: 'time' },
    date: { value: false, label: 'Date', name: 'date' },
    upload_attachment: {
      value: false,
      label: 'Upload attachment',
      name: 'upload_attachment',
    },
    projected_eta: {
      value: false,
      label: 'Projected ETA',
      name: 'projected_eta',
    },
    actual_eta: {
      value: false,
      label: 'Actual ETA',
      name: 'actual_eta',
    },
    national_incident_no: {
      value: false,
      label: 'National Incident Number',
      name: 'national_incident_no',
    },
    state_incident_no: {
      value: false,
      label: 'State Incident Number',
      name: 'state_incident_no',
    },
    no_release: {
      value: true,
      label: 'No Release',
      name: 'no_release',
    },
  });

  const [defaultService, setDefaultService] = React.useState({
    value: false,
    label: 'Set As Default',
    name: 'is_default',
  });

  React.useEffect(() => {
    getCategories();
    if (currentService) {
      setServiceData((val) => {
        return {
          ...val,
          name: currentService.name,
          emailNotification: currentService.email_notifications,
          category_id: currentService.category_id,
        };
      });

      setDefaultService({
        value: currentService.is_default,
        label: defaultService.label,
        name: defaultService.name,
      });

      let serviceOptionsObj = {};
      Object.keys(currentService).map((currentServiceKey) => {
        Object.values(serviceOptions).map((currentOptionsKey) => {
          if (currentServiceKey === currentOptionsKey.name) {
            serviceOptionsObj[currentOptionsKey.name] = {
              value: currentService[currentOptionsKey.name],
              label: currentOptionsKey.label,
              name: currentOptionsKey.name,
            };
          }
        });
      });

      let updatedServicesObj = {};

      if (
        currentService?.name ===
        'Regulatory Notification Completed To State Agency'
      ) {
        delete serviceOptionsObj?.national_incident_no;
        updatedServicesObj = { ...serviceOptionsObj };
      } else if (
        currentService?.name ===
        'Regulatory Notification Completed To National Response Center'
      ) {
        delete serviceOptionsObj?.state_incident_no;
        updatedServicesObj = { ...serviceOptionsObj };
      } else {
        updatedServicesObj = { ...serviceOptionsObj };
      }

      setServiceOptions(updatedServicesObj);
    }
  }, []);

  const [emailNotification, setEmailNotificationValue] = React.useState(false);
  const [serviceData, setServiceData] = React.useState({
    name: '',
    emailNotification: '',
    category_id: '',
  });

  const handleChangeServiceOptionCheckbox = (event) => {
    setServiceOptions({
      ...serviceOptions,
      [event.target.name]: {
        value: event.target.checked,
        label: serviceOptions[event.target.name].label,
        name: [event.target.name],
      },
    });
  };

  const handleChangeDefaultCheckbox = (event) => {
    setDefaultService({
      value: event.target.checked,
      label: defaultService.label,
      name: [event.target.name],
    });
  };
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleServiceChange = (key) => (event) => {
    if (key === 'emailNotification') {
      setEmailNotificationValue(true);
    }
    setServiceData((val) => ({ ...val, [key]: event.target.value }));
    setEmailNotificationValue(event.target.value);
  };

  const handleSubmit = () => {
    let optionsData = {};

    if (serviceData.name === '' || !serviceData?.name?.trim()) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return setErrorMessage('Name is required.');
    }
    else{
       setName('');
       setErrorMessage('');
    }

    if (!serviceData.emailNotification) {
      setEmailError('Please Select Email');
    } else {
      setEmailError('');      
      setServiceData({ emailNotification: true });
    }
    for (let option in serviceOptions) {
      optionsData = {
        ...optionsData,
        [option]: serviceOptions[option].value,
      };
    }

    if (currentService) {
      const completeService = {
        ...serviceData,
        ...optionsData,
        name: serviceData?.name?.trim(),
        order: currentService.order,
        id: currentService.id,
        is_default: defaultService.value,
        active: currentService.active ?? false,
      };

      editService(completeService);
      closeAddService();
      return;
    }
    if (emailNotification) {
      if (serviceData.name === '' || serviceData.emailNotification === '') {
        
        return;
      }
      const completeService = {
        ...serviceData,
        ...optionsData,
        order: totalServices + 1,
        is_default: defaultService.value,
      };

      createNewService(completeService);
      closeAddService();
    }
  };

  return (
    <React.Fragment>
      <Container>
        <div style={{ paddingTop: 10 }} />
        <Typography variant='h6' gutterBottom>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={classes.headerColor}>
              {currentService ? 'Edit Service' : 'Create Service'}
            </span>
          </div>
        </Typography>
        <form style={{ width: '100%' }}>
          <Grid container spacing={3}>
            {currentUser &&
            currentUser.data?.role?.permission?.edit_services ? (
              <Grid item xs={12}>
                <TextField
                  id='name'
                  name='name'
                  label='Name'
                  fullWidth
                  autoComplete='name'
                  required
                  inputProps={{
                    value: serviceData.name,
                  }}
                  onChange={handleServiceChange('name') }
                />
                      {errorMessage && <p className={classes.errorText}>{errorMessage}</p>}

              </Grid>
            ) : null}

            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <InputLabel id='emailNotificationSelectLabel'>
                Email Trigger *
              </InputLabel>
              <Select
                labelId='emailNotificationSelectLabel'
                id='emailNotificationSelect'
                inputProps={{
                  value: serviceData.emailNotification,
                }}
                onChange={handleServiceChange('emailNotification')}
                style={{ width: '100%' }}
                
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
              {emailError && <p className={classes.errorText}>{emailError}</p>}

            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <InputLabel id='categoryLabel'>Category</InputLabel>
              <Select
                labelId='categoryLabel'
                id='categorySelect'
                inputProps={{
                  value: serviceData.category_id,
                }}
                onChange={handleServiceChange('category_id')}
                style={{ width: '100%' }}
              >
                <MenuItem value=''>
                  <em>Select Category</em>
                </MenuItem>
                {service?.categories?.data?.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            {currentUser && currentUser.data?.role?.permission?.edit_services
              ? Object.values(serviceOptions).map((key, index) => {
                  return (
                    <Grid item xs={4}>
                      <div style={{ textAlign: 'left' }}>
                        <FormControlLabel
                          className={classes.textCapital}
                          control={
                            <Checkbox
                              checked={key.value}
                              onChange={handleChangeServiceOptionCheckbox}
                              name={key.name}
                            />
                          }
                          label={key.label}
                        />
                      </div>
                    </Grid>
                  );
                })
              : null}

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  color: 'grey',
                  width: '50%',
                }}
              >
                <Typography
                  variant='subtitle2'
                  style={{
                    textAlign: 'justify',
                    margin: '0 5px',
                  }}
                >
                  The below checkbox is to configure default service -- If
                  enabled this service will be shown in related organization by
                  default.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <FormControlLabel
                className={classes.defaultCheckMargin}
                control={
                  <Checkbox
                    checked={defaultService.value}
                    onChange={handleChangeDefaultCheckbox}
                    name={defaultService.name}
                  />
                }
                label={defaultService.label}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                {currentService ? 'Update' : 'Create'}
              </Button>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                onClick={() => closeAddService()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ service, user: { currentUser } }) => ({
  service,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createNewService: bindActionCreators(
    serviceActions.createNewService,
    dispatch
  ),
  editService: bindActionCreators(serviceActions.editService, dispatch),
  getCategories: bindActionCreators(serviceActions.getCategories, dispatch),
});

NewService.propTypes = {
  service: PropTypes.object.isRequired,
  createNewService: PropTypes.func.isRequired,
  closeAddService: PropTypes.func.isRequired,
  totalServices: PropTypes.number.isRequired,
  currentService: PropTypes.object.isRequired,
  editService: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewService)
);
