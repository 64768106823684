import React from "react";

import PDFICON from "../../../../assets/file-icons/pdf.png";
import TIFICON from "../../../../assets/file-icons/tif.png";
import XLSICON from "../../../../assets/file-icons/xls.png";
import DOCICON from "../../../../assets/file-icons/doc.png";
import PNGICON from "../../../../assets/file-icons/png.png";
import VIDEOICON from "../../../../assets/file-icons/video.png";

import CircularProgressWithLabel from "../../../ProgressBar";
import { Chip } from "@material-ui/core";

const FileCard = ({ fileName, fileType, fileSize = 35.5, uploadProgress }) => (
  <div className="file-upload-card">
    <div className="left">
      <img alt="" src={fileIconsByType[fileType]} />
      <div>
        <h5>{fileName}</h5>
        <p className="subtle-text">{`${fileSize} Mbs`}</p>
      </div>
    </div>
    <div className="right">
      {uploadProgress === null ? (
        <Chip size="small" label="Queued" color="secondary" />
      ) : (
        <CircularProgressWithLabel
          value={uploadProgress}
          style={{ color: "green" }}
        />
      )}
    </div>
  </div>
);

const fileIconsByType = {
  pdf: PDFICON,
  tif: TIFICON,
  xls: XLSICON,
  doc: DOCICON,
  png: PNGICON,
  mp4: VIDEOICON,
};

export default FileCard;
