import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import NewContractor from './NewContractor';
import EditContractor from './EditContractor';
import Search from '../../../../../Components/Search';
import DataTable from '../../../../../Components/DataTable';
import DownloadCSV from '../../../../../Components/DownloadCSV';
import DownloadFullCSV from '../../../../../Components/DownloadFullCSV';

import * as searchActions from '../../../../../actionCreators/Search';
import * as contractorActions from '../../../../../actionCreators/Contractor';
import { CustomProgressLoader } from '../../../../../Components';
import { ROLES } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
  menuButtons: {
    fontSize: '16px',
    padding: '5px 5px 7px 5px',
  },
  listAllButton: {
    fontSize: '16px',
    padding: '5px 5px 7px 5px',
  },
}));

const ContractorsMenu = ({
  user,
  search,
  loading,
  history,
  searching,
  searchText,
  searchType,
  contractor,
  searchSuccess,
  clearSearch,
  searchedData,
  getContractors,
  searchPagination,
  getContractorERailClassICertifications,
  contractorDoNotUse,
  updateDoNotUseContactors,
}) => {
  document.title = 'Contractors';
  const classes = useStyles();

  const location = history.location;
  const searchParam = location.search;
  const urlParams = new URLSearchParams(searchParam);

  const { currentUser } = user;

  const [rowData, setRowData] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openAddNewContractor, setOpenAddNewContractor] = useState(false);
  const [openEditContractor, setOpenEditContractor] = useState(false);
  const [searchedTableData, setSearchTableData] = useState([]);
  const [searchedTableDataToDownload, setSearchTableDataToDownload] =
    useState();
  const [searchPage, setSearchPage] = useState(0);
  let [dataForSearch, setDataForSearch] = useState({});
  const [userIsHazardous, setUserIsHazardous] = useState(false);
  const [userLicenseNumber, setUserLicenseNumber] = useState('');
  const [userLicenseNumberExpiry, setUserLicenseNumberExpiry] = useState(
    new Date()
  );
  const [dialogState, setDialogState] = useState({
    open: false,
    row: {},
    doNotUse: false
  });
  const handleClose = useCallback(() => {
    setDialogState(() => ({
      open: false,
      doNotUse: false,
      row: {}
    }));
  }, []);
  const [tableHeaders, setTableHeaders] = useState([
    { title: 'Contractor', key: 'name' },
    { title: 'E-Mail', key: 'email' },
    { title: 'Voice Phone', key: 'phone' },
  ]);
  const [dialogMsg, setDialogMsg] = useState('');

  const filterRecords = useCallback(
    (records) => {
      return records?.map((data) => {
        if (data?.id === dialogState?.row?.id) {
          return { ...data, do_not_use: !dialogState?.row?.do_not_use };
        }
        return data;
      });
    },
    [dialogState]
  );

  const handleCallDoNotuseAPI = useCallback(() => {
    // change do not use status
    const newArray = filterRecords(tableData);
    const filteredSearchRecord = filterRecords(searchedTableData);
    setTableData(newArray);

    // Set record for without search contractors 
    const data = {
      data: newArray,
      pagination: contractor.contractors.pagination
    };

    // set record for with search contractor
    const searchData = {
      data: filteredSearchRecord,
      pagination: searchPagination,
      type: searchType
    };
    updateDoNotUseContactors(data); // update contractor records
    searchSuccess(searchData); // update search contractor records

    const obj = {
      contractor_id: dialogState?.row?.id,
      do_not_use: !dialogState?.row?.do_not_use
    };
    contractorDoNotUse(obj);
    handleClose();
  }, [tableData, dialogState, contractorDoNotUse, handleClose]);

  const handleDoNotUseChange = useCallback((row, checked) => {
    if (checked) {
      setDialogMsg('All addresses of contractor will marked as "Do not use" for all clients. Are you sure you want mark contractor as "Do not use"?');
    } else {
      setDialogMsg('All addresses of contractor will be removed from "Do not use" for all clients. Are you sure you want change value of "Do not use"?');
    }
    // open dialoge
    setDialogState((prevState) => ({
      ...prevState,
      open: true,
      doNotUse: !checked,
      row
    }));
  }, []);

  useEffect(() => {
    let page = pagination.page + 1;

    if (page) {
      urlParams.set('page', parseInt(pagination.page) + 1);

      history.push({
        pathname: `/dashboard/contractors`,
        search: urlParams.toString(),
      });
    }
  }, [pagination]);

  useEffect(() => {
    loadContractor();
  }, []);

  useEffect(() => {
    if (contractor?.type === 'CONTRACTOR_DO_NOT_USE_SUCCESS') {
      callGetContractors();
    }
    if (contractor?.type === 'CREATE_NEW_CONTRACTOR_SUCCESS') {
      getContractors({ page: 0 });
    }
  }, [contractor?.type])

  const callGetContractors = () => {
    const pageNumber = urlParams.get('page');
    const text = urlParams.get('search');
    if (text) {
      loadContractor();
    } else {
      getContractors({page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0});
    }
  }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const loadContractor = () => {
    const pageNumber = urlParams.get('page');
    const text = urlParams.get('search');
    if (text) {
      const dataWithParamsObject = {
        role: currentUser.data?.role?.role,
        searchText: text,
        type: 'Contractor',
      };

      setDataForSearch(dataWithParamsObject);

      const data = {
        page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
        searchText: text,
        type: 'Contractor',
        role: currentUser.data?.role?.role,
      };
      search(data);
    } else {
      clearSearch();
    }
  }

  useEffect(() => {
    if (dataForSearch.searchText) {
      urlParams.set('search', dataForSearch.searchText);

      history.replace({
        pathname: `/dashboard/contractors`,
        search: urlParams.toString(),
      });
    }
  }, [dataForSearch]);

  useEffect(() => {
    if (contractor && contractor.contractors) {
      if (!searching || contractor?.type === 'GET_CONTRACTORS_SUCCESS') {
        setPagination(contractor.contractors.pagination);
        setTableData(contractor.contractors.data);
      }
    }
  }, [contractor]);

  useEffect(() => {
    if (searchedData) {
      if (searchType === 'Contractor') {
        setPagination(searchPagination);
        setSearchTableData(searchedData.data);
      } else {
        setPagination(contractor.contractors.pagination);
        setSearchTableData([]);
      }
    }
  }, [searchedData, searchType]);

  useEffect(() => {
    if (searchedTableData) {
      const searchedUsersData = searchedTableData.map((item) => {
        const tableItems = [
          item?.name,
          item?.email,
          item?.address?.replace(/,/g, ' '),
          item?.phone,
          item?.fax,
          item?.zip_code,
          item?.city,
          item?.state,
          item?.country,
        ];
        return tableItems;
      });

      setSearchTableDataToDownload(searchedUsersData);
    }
  }, [searchedTableData]);

  useEffect(() => {
    setShowForm(false);
    setOpenAddNewContractor(false);
    setOpenEditContractor(false);
  }, [location.key]);

  useEffect(() => {
    if (currentUser.data?.role?.role === ROLES.SUPER_USER) {
      setTableHeaders([...tableHeaders, {
        title: 'Do Not Use',
        key: 'do_not_use',
        component: {
          type: 'checkbox',
          checked: (row) => row.do_not_use,
          onChange: (checked, row) => handleDoNotUseChange(row, checked),
        },
      }]);
    }
  }, [currentUser.data?.role?.role])

  const csvHeaders = [
    { title: 'Contractor Name', key: 'name' },
    { title: 'E-Mail', key: 'email' },
    { title: 'Address', key: 'address' },
    { title: 'Phone', key: 'phone' },
    { title: 'Fax', key: 'fax' },
    { title: 'Zip Code', key: 'zip_code' },
    { title: 'City', key: 'city' },
    { title: 'State', key: 'state' },
    { title: 'Country', key: 'country' },
  ];

  const handleAllContractorsClick = () => {
    setShowForm(false);
    setOpenAddNewContractor(false);
    setOpenEditContractor(false);
  };

  const handleOpenEditModalWithDataTable = async (data) => {
    setOpenEditContractor(true);
    setUserIsHazardous(data?.haz_waste_hauler);
    setUserLicenseNumber(data?.license_number);
    setUserLicenseNumberExpiry(data?.license_number_expiry);
    setRowData(data);
    handleOpenEditContractorModal(true);
  };

  const handleOpenNewContractorModal = () => {
    setShowForm(true);
    setOpenAddNewContractor(true);
    setOpenEditContractor(false);
  };

  const handleCloseNewContractorModal = () => {
    setShowForm(false);
    setOpenAddNewContractor(false);
  };

  const handleOpenEditContractorModal = () => {
    setShowForm(true);
    setOpenEditContractor(true);
  };

  const handleCloseEditContractorModal = (isRefreshTable) => {
    setShowForm(false);
    setOpenEditContractor(false);
    if (isRefreshTable) {
      callGetContractors();
    } else {
      searchText && search(dataForSearch);
    }
  };

  const handleOpenEditForm = () => {
    setOpenEditContractor(true);
    setOpenAddNewContractor(false);
  };

  const handleTablePage = (data) => {
    if (data.type === 'search') {
      setSearchPage(data.newPage);

      dataForSearch = {
        ...dataForSearch,
        page: data.newPage,
      };

      search(dataForSearch);
    } else {
      getContractors({ page: data.newPage });

      getContractorERailClassICertifications();
    }
  };

  const handleContractorSearch = (data) => {
    setDataForSearch(data);
    data = {
      ...data,
      page: searchPage,
    };

    search(data);
    getContractorERailClassICertifications();
  };

  const handlePageReset = () => {
    const pageNumber = urlParams.get('page');
    setSearchPage(0);
    getContractors({page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
    });
    history.push({
      pathname: `/dashboard/contractors`,
    });
    getContractorERailClassICertifications();
  };

  useEffect(() => {
    if (location?.search) {
      const urlPage = location?.search.split('=');
      const urlPageNumber = parseInt(urlPage.pop(), 10);
      if (urlPageNumber) setCurrentPage(urlPageNumber);
    }
  }, [location]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent style={{ paddingBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant='h5' style={{ color: '#619a63' }}>
                    Contractors
                  </Typography>
                </div>
                <div>
                  <BuildIcon fontSize='large' style={{ color: '#619a63' }} />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: 'flex',
                  direction: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  {showForm ? (
                    <Button
                      className={classes.listAllButton}
                      color='primary'
                      onClick={handleAllContractorsClick}
                    >
                      All Contractors
                    </Button>
                  ) : currentUser &&
                    currentUser.data?.role?.permission?.create_contractors ? (
                    <Button
                      className={classes.menuButtons}
                      color='primary'
                      onClick={handleOpenNewContractorModal}
                    >
                      Add New
                    </Button>
                  ) : null}

                  <div>
                    <DownloadFullCSV
                      type={'Contractor'}
                      dataForSearch={
                        searchedTableData.length > 0
                          ? dataForSearch.searchText
                          : null
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Search
                    type={'Contractor'}
                    userRole={currentUser.data?.role?.role}
                    searchData={searchType === 'Contractor' ? searchText : ''}
                    isSearching={
                      searching && searchType === 'Contractor' ? true : false
                    }
                    handleSearch={(data) => handleContractorSearch(data)}
                    resetPage={() => handlePageReset()}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          <div
            style={{
              width: '100%',
              height: '25px',
              textAlign: 'right',
              paddingRight: '15px',
            }}
          >
            {searchedTableData.length > 0 ? (
              <div style={{ paddingTop: '5px' }}>
                Results found ({pagination.count})
              </div>
            ) : null}
          </div>
          {loading ? (
            <CustomProgressLoader show={true} />
          ) : (
            <div style={{ marginTop: 10 }}>
              {searching && searchType === 'Contractor'
                ? !openAddNewContractor &&
                  !openEditContractor && (
                    <div>
                      {searchedTableData.length > 0 ? (
                        <DataTable
                          tableHeader={tableHeaders}
                          tableBody={searchedTableData}
                          editTable={
                            currentUser.data?.role?.permission?.edit_contractors
                          }
                          pagination={pagination}
                          onRowSelect={handleOpenEditModalWithDataTable}
                          pageChange={handleTablePage}
                          type={'search'}
                          openEditForm={handleOpenEditForm}
                          loading={loading}
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          setPageRows={() => {}}
                        />
                      ) : (
                        <p>No contractor found.</p>
                      )}
                    </div>
                  )
                : !openAddNewContractor &&
                  !openEditContractor && (
                    <div>
                      {contractor ? (
                        <DataTable
                          tableHeader={tableHeaders}
                          tableBody={tableData}
                          pagination={pagination}
                          editTable={
                            currentUser.data?.role?.permission?.edit_contractors
                          }
                          onRowSelect={handleOpenEditModalWithDataTable}
                          pageChange={handleTablePage}
                          openEditForm={handleOpenEditForm}
                          loading={contractor.loading}
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          setPageRows={() => {}}
                        />
                      ) : (
                        <p>No data available.</p>
                      )}
                    </div>
                  )}
            </div>
          )}
        </Grid>
      </Grid>
      {openAddNewContractor && (
        <div>
          <Fade in={openAddNewContractor}>
            <div className={classes.paper}>
              <NewContractor
                closeAddContractorModal={handleCloseNewContractorModal}
              />
            </div>
          </Fade>
        </div>
      )}

      {openEditContractor && (
        <Fade in={openEditContractor}>
          <div className={classes.paper}>
            <EditContractor
              contractorData={rowData}
              userIsHazardous={userIsHazardous}
              userLicenseNumber={userLicenseNumber}
              setUserIsHazardous={setUserIsHazardous}
              setUserLicenseNumber={setUserLicenseNumber}
              closeEditModal={handleCloseEditContractorModal}
              userLicenseNumberExpiry={userLicenseNumberExpiry}
              setUserLicenseNumberExpiry={setUserLicenseNumberExpiry}
            />
          </div>
        </Fade>
      )}
      <div>
        <Dialog
          open={dialogState?.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCallDoNotuseAPI}
              autoFocus
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  contractor,
  search: {
    searchedData,
    searchType,
    searching,
    searchText,
    searchPagination,
    loading,
  },
}) => ({
  user,
  contractor,
  searchedData,
  searchType,
  searching,
  searchText,
  searchPagination,
  loading: loading || user.loading || contractor.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getContractors: bindActionCreators(
    contractorActions.getContractors,
    dispatch
  ),
  getContractorERailClassICertifications: bindActionCreators(
    contractorActions.getContractorERailClassICertifications,
    dispatch
  ),
  search: bindActionCreators(searchActions.search, dispatch),
  searchSuccess: bindActionCreators(searchActions.searchSuccess, dispatch),
  clearSearch: bindActionCreators(searchActions.clearSearch, dispatch),
  contractorDoNotUse:bindActionCreators(
    contractorActions.contractorDoNotUse,
    dispatch
  ),
  updateDoNotUseContactors: bindActionCreators(contractorActions.getContractorsSuccess, dispatch),
});

ContractorsMenu.propTypes = {
  user: PropTypes.object.isRequired,
  contractor: PropTypes.object.isRequired,
  getContractors: PropTypes.func.isRequired,
  searchedData: PropTypes.object.isRequired,
  contractorDoNotUse: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractorsMenu)
);
