import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  states,
  countries,
  addressTiers,
  orderAlphabatically,
  orderAlphabaticallyByKey,
  RESPONSE_COUNT_TYPE,
} from '../../utils';
import ResponseCount from './ResponseCount';

const EditNewAddress = ({ addressData, index, setAddressData }) => {
  const handleChange = (key, event) => {
    let tempAddressData = [...addressData];
    tempAddressData[index][key] = event.target.value;
    setAddressData(tempAddressData);
  };

  return (
    <div>
      <React.Fragment>
        <div style={{ paddingTop: 10 }} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label='Address'
              fullWidth
              value={addressData[index]?.address || ''}
              autoComplete='address'
              onChange={(event) => handleChange('address', event)}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel id='country-select-label'>Country</InputLabel>
            <Select
              labelId='country-select-label'
              value={addressData[index].country}
              style={{ width: '100%' }}
              onChange={(event) => handleChange('country', event)}
            >
              {orderAlphabatically(countries).map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel id='state-select-label'>State</InputLabel>
            <Select
              labelId='state-select-label'
              value={addressData[index].state}
              style={{ width: '100%' }}
              onChange={(event) => handleChange('state', event)}
            >
              {orderAlphabaticallyByKey(states, 'country', 'name').map(
                (item, index) => {
                  return (
                    <MenuItem key={index} value={item.key}>
                      {`${item.name} (${item.country})`}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='City'
              fullWidth
              value={addressData[index]?.city || ''}
              autoComplete='city'
              onChange={(event) => handleChange('city', event)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label='Zip Code'
              fullWidth
              value={addressData[index]?.zip_code || ''}
              autoComplete='zip_code'
              onChange={(event) => handleChange('zip_code', event)}
            />
          </Grid>

          <Grid item xs={4} style={{ textAlign: 'left' }}>
            <InputLabel id='tier_select_label'>Tier</InputLabel>
            <Select
              id='tier_select'
              name='tier'
              value={addressData[index].tier_level}
              style={{ width: '100%' }}
              onChange={(event) => handleChange('tier_level', event)}
            >
              {addressTiers &&
                orderAlphabaticallyByKey(addressTiers, 'level').map((e) => {
                  return (
                    <MenuItem key={e.level} value={e.level}>
                      {e.level}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label='Phone'
              fullWidth
              value={addressData[index]?.phone || ''}
              autoComplete='phone'
              onChange={(event) => handleChange('phone', event)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Contact'
              fullWidth
              value={addressData[index]?.contact || ''}
              autoComplete='contact'
              onChange={(event) => handleChange('contact', event)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Fax'
              fullWidth
              value={addressData[index]?.fax || ''}
              autoComplete='fax'
              onChange={(event) => handleChange('fax', event)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
};

EditNewAddress.propTypes = {
  addressData: PropTypes.array.isRequired,
  setAddressData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  entity_id: PropTypes.number.isRequired,
  entity_type: PropTypes.string.isRequired,
};

export default withRouter(EditNewAddress);
