export const getContractorLabel = (data, contractors, isNotRequired) => {
  const notRequiredLabel = 'Not Required';
  if (!contractors || !contractors.length || !data) {
    return '';
  }
  let tempContractor = contractors.find(
    (contractor) =>
      (contractor.contractor_id === data.contractor_id &&
        (!data.contractor_address_id ||
          contractor.addressId === data.contractor_address_id)) ||
      (contractor.contractor_id === data.contractorId &&
        (!data.contractorAddressId ||
          contractor.addressId === data.contractorAddressId))
  );
  if (tempContractor) {
    if (data.isRemoved || data.is_removed) {
      return `${tempContractor.label} - (Inactive)${
        isNotRequired ? ` - (${notRequiredLabel})` : ''
      }`;
    }
    return `${tempContractor.label}${
      isNotRequired ? ` - (${notRequiredLabel})` : ''
    }`;
  }

  return '';
};

export const compareContractor = (admin, contractor) => {
  const toReturn =
    (admin.contractor_id === contractor.contractor_id &&
      ((!admin.addressId && !contractor.addressId) ||
        admin.addressId === contractor.addressId)) ||
    (admin.contractorId === contractor.contractor_id &&
      ((!admin.contractorAddressId && !contractor.addressId) ||
        admin.contractorAddressId === contractor.addressId));
  return toReturn;
};

export const generateContractorLabel = (contractor) => {
  return `${
    contractor.name
      ? contractor.tier_level
        ? ` - ${contractor.tier_level}`
        : ''
      : contractor.label
  } (${contractor.city},${contractor.state})`;
};

export const getContractorOptions = (contractorNames) => {
  let contractorOptionsTemp = [];
  contractorNames?.forEach((contractor) => {
    const value = {
      value: `${contractor.id} ${contractor.addressId || ''}`,
      label: `${contractor.name}${
        contractor.tier_level ? ` - ${contractor.tier_level}` : ''
      } (${contractor.city},${contractor.state})`,
      addressId: contractor.addressId || '',
      contractor_id: contractor.id,
      isCategory: !contractor.entity_id,
      name: contractor.name,
      backgroundColor: 'blue',
      state: contractor.state,
      city: contractor.city,
      tier_level: contractor.tier_level,
      respond_count: contractor.respond_count,
      reject_count: contractor.reject_count,
      contractor_attachments_expiries: contractor?.contractor_attachments_expiries
    };

    contractorOptionsTemp.push(value);
  });
  return contractorOptionsTemp;
};

export const convertToGroupedOptions = (contractorOptions, filterForUser) => {
  const contractorCategories = orderAlphabaticallyByKey(
    contractorOptions.filter((contractor) => contractor.isCategory),
    'name'
  );
  let groupedCategories = [];
  contractorCategories?.forEach((category) => {
    let childs = contractorOptions?.filter(
      (contractor) =>
        contractor.name === category.name && !contractor.isCategory
    );
    childs = orderAlphabaticallyByKey(childs, 'state', 'city');
    filterForUser
      ? childs.length < 1 && groupedCategories.push(category)
      : groupedCategories.push(category);

    groupedCategories = [...groupedCategories, ...childs];
  });

  return groupedCategories;
};

export const contractorColourStyles = (ignorePadding) => {
  return {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        fontWeight: data.isCategory && 'bolder',
        ...styles,
        cursor: isDisabled ? 'not-allowed' : isFocused && 'pointer',
        backgroundColor: isFocused ? '#31bc3687' : 'unset',
        borderBottom: '1px solid white',
        transition: 'all 0.4s',
        paddingLeft: !data.isCategory && !ignorePadding && 40,
        color: isDisabled ? '#aaa' : isSelected && 'black',
      };
    },
  };
};

export const orderAlphabaticallyByKey = (
  list,
  key,
  key2,
  key3,
  desc = false
) => {
  if (key2) {
    list?.sort((a, b) => {
      let textA = a[key ?? 'label'];
      let textB = b[key ?? 'label'];
      return textA?.toUpperCase() < textB?.toUpperCase()
        ? desc
          ? 1
          : -1
        : textA?.toUpperCase() > textB?.toUpperCase()
        ? desc
          ? -1
          : 1
        : a[key2]?.toUpperCase() < b[key2]?.toUpperCase()
        ? -1
        : a[key2]?.toUpperCase() > b[key2]?.toUpperCase()
        ? 1
        : key3
        ? a[key3]?.toUpperCase() < b[key3]?.toUpperCase()
          ? -1
          : a[key3]?.toUpperCase() > b[key3]?.toUpperCase()
          ? 1
          : 0
        : 0;
    });
  } else {
    list?.sort(function (a, b) {
      let textA = a[key ?? 'label']?.toUpperCase();
      let textB = b[key ?? 'label']?.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
  return desc ? list.reverse() : list;
};

// this function is used for every contractor dropdown
export const filterOption = ({ label, value, accepted }, string) => {
  // search from anywhere in the string instead of searching results that starts with
  if (
    label?.toUpperCase().includes(string?.toUpperCase()) ||
    value?.toUpperCase().includes(string?.toUpperCase())
  )
    return true;

  return false;
};

export const orderAlphabatically = (list) => {
  list.sort((a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
  });
  return list;
};

export const findSameParentContractors = (
  parentContractor,
  contractorOptions
) => {
  const children = [];
  contractorOptions?.forEach((contractor) => {
    contractor.name === parentContractor.name && children.push(contractor);
  });
  return children;
};
export const calculateContractors = (contractorList, contractorOptions) => {
  let newList = [];
  contractorList?.forEach((contractor) => {
    if (contractor.isCategory)
      newList = [
        ...newList,
        ...findSameParentContractors(contractor, contractorOptions),
      ];
    else newList.push(contractor);
  });

  const uniqueList = Array.from(new Set(newList.map((a) => a.label))).map(
    (label) => {
      return newList.find((a) => a.label === label);
    }
  );
  return uniqueList;
};
