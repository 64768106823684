const initialState = {
  success: false,
  loading: false,
  error: null,
  services: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  categories: {
    loading: false,
    data: [],
  },
};

const agencyReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case "RESET_SERVICE":
      return initialState;

    case "GET_SERVICES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_SERVICES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        services: {
          ...state.services,
          data: data,
        },
      };

    case "GET_SERVICES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_CATEGORIES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_CATEGORIES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        categories: {
          data: obj,
          loading: false,
        },
      };

    case "GET_CATEGORIES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        categories: {
          ...state.categories,
          loading: false,
        },
      };

    case "CREATE_NEW_SERVICE":
      return {
        ...state,
        type,
        loading: true,
      };

    case "CREATE_NEW_SERVICE_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        services: {
          ...state.services,
          data: [...state.services.data, data],
        },
      };

    case "CREATE_NEW_SERVICE_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "EDIT_SERVICE":
      return {
        ...state,
        type,
        loading: true,
      };

    case "EDIT_SERVICE_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        services: {
          ...state.services,
          data: state.services.data.map((serviceToUpdate) => {
            if (serviceToUpdate.id === data.id) {
              serviceToUpdate = data;
            }
            let updatedService = { ...serviceToUpdate };
            return updatedService;
          }),
        },
      };

    case "EDIT_SERVICE_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "RE_ORDER_SERVICES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "RE_ORDER_SERVICES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        services: {
          ...state.services,
          data: data,
        },
      };

    case "RE_ORDER_SERVICES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CHANGE_SERVICE_STATUS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "CHANGE_SERVICE_STATUS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        services: {
          ...state.services,
          data: state.services.data.map((service) => {
            if (obj.id === service.id) {
              service.active = obj.active;
              return service;
            }
            return service;
          }),
        },
      };

    case "CHANGE_SERVICE_STATUS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    default:
      return state;
  }
};

export default agencyReducer;
