import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  states,
  countries,
  addressTiers,
  orderAlphabatically,
  orderAlphabaticallyByKey,
  RESPONSE_COUNT_TYPE,
} from '../../utils';
import ResponseCount from './ResponseCount';

const MainAddressForm = ({
  address,
  city,
  state,
  zip_code,
  country,
  phone,
  fax,
  contact,
  register,
  isOrg,
  isContractor,
  tier_level,
  reject_count,
  respond_count,
  hideCount,
  showResponsePills,
  mainAddformData,
  onChange,
  mainAddressFormDataFaciliti
}) => {
  const handleChange = (e) => {
    const { name, value } = e?.target;
    onChange((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCountryChange = (value) => {
    onChange((prevFormData) => ({
      ...prevFormData,
      country: value,
    }));
  };

  const handleStateChange = (value) => {
    onChange((prevFormData) => ({
      ...prevFormData,
      state: value,
    }));
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            id="address"
            name="address"
            label="Addess"
            fullWidth
            size="small"
            defaultValue={address || ''}
            value={mainAddformData?.address}
            onChange={handleChange}
            inputRef={register}
            style={{ marginTop: '4px' }}
          />
        </Grid>

        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <InputLabel id='country-select-label'>Country</InputLabel>
          <Select
            labelId='country-select-label'
            id='country'
            name='country'
            defaultValue={country}
            style={{ width: '100%' }}
            value={mainAddformData?.country}
            onChange={(e) => handleCountryChange(e.target.value)}
          >
            <MenuItem value=''>
              <em>Select Country</em>
            </MenuItem>
            {orderAlphabatically(countries).map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <InputLabel id='state-select-label'>State</InputLabel>
          <Select
            labelId='state-select-label'
            id='state'
            name='state'
            defaultValue={state}
            style={{ width: '100%' }}
            inputRef={register}
            value={mainAddformData?.state}
            onChange={(e) => handleStateChange(e.target.value)}
          >
            <MenuItem value=''>
              <em>Select State</em>
            </MenuItem>
            {orderAlphabaticallyByKey(states, 'country', 'name').map(
              (item, index) => {
                return (
                  <MenuItem key={index} value={item.key}>
                    {`${item.name} (${item.country})`}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </Grid>

        {!isContractor && (
          <>
            <Grid item xs={6}>
              <TextField
                id="city"
                name="city"
                label="City"
                fullWidth
                size="small"
                defaultValue={city || ''}
                value={mainAddformData?.city}
                onChange={handleChange}
                inputRef={register} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='zip-code'
                name='zip_code'
                label='Zip Code'
                fullWidth
                size="small"
                defaultValue={zip_code || ''}
                value={mainAddformData?.zip_code}
                onChange={handleChange}
                inputRef={register} />
            </Grid>
          </>
        )}

        {isContractor && (
          <>
            <Grid item xs={4}>
              <TextField
                id="city"
                name="city"
                label="City"
                fullWidth
                size="small"
                defaultValue={city || ''}
                value={mainAddformData?.city}
                onChange={handleChange}
                inputRef={register} />
            </Grid>

            <Grid item xs={4}>
              <TextField
                id='zip-code'
                name='zip_code'
                label='Zip Code'
                fullWidth
                size="small"
                defaultValue={zip_code || ''}
                value={mainAddformData?.zip_code}
                onChange={handleChange}
                inputRef={register} />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'left' }}>
              <InputLabel id='tier_level_select_label'>Tier</InputLabel>
              <Select
                id='tier_level_select'
                name='tier_level'
                defaultValue={tier_level}
                style={{ width: '100%' }}
                inputRef={register}
              >
                <MenuItem value=''>
                  <em>Select Tier</em>
                </MenuItem>
                {addressTiers &&
                  orderAlphabaticallyByKey(addressTiers, 'level').map((e) => {
                    return (
                      <MenuItem key={e.level} value={e.level}>
                        {e.level}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <TextField
            id='phone'
            name='phone'
            label='Phone'
            fullWidth
            size="small"
            defaultValue={phone}
            value={mainAddformData?.phone}
            onChange={handleChange}
            inputRef={register} />
        </Grid>
        {!isOrg && (
          <Grid item xs={6}>
            <TextField
              id='fax'
              name='fax'
              label='Fax'
              fullWidth
              size="small"
              defaultValue={fax}
              value={mainAddformData?.fax}
              onChange={handleChange}
              inputRef={register} />
          </Grid>
        )}
      {!isOrg && (
        <Grid item xs={12}>
          <TextField
              id='contact'
              name='contact'
              label='Contact'
              fullWidth
              size="small"
              defaultValue={contact} 
              value={mainAddformData?.contact}
              onChange={handleChange}
              inputRef={register} />
        </Grid>
      )}
    </Grid>
    </div >
  );
};

MainAddressForm.prototype = {
  register: PropTypes.any,
};

export default MainAddressForm;
