import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  canSeeStampsOrAmounts,
  fileSizeString,
  formatTimeInNote,
  getFormattedTime,
  getPublicIp,
} from '../../../../../utils';
import * as downloadActions from '../../../../../actionCreators/Download';
import { AttachmentsGallery } from '../../../../../Components';

const useStyles = makeStyles((theme) => ({
  smallText: {
    fontSize: '15px',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '10px',
  },
  headerCard: {
    margin: '5px 0',
  },
  upperText: {
    marginBottom: '1rem',
    color: '#295115',
    fontSize: '13px',
  },
  bold: {
    fontWeight: '800',
  },
  inlineButton: {
    maxWidth: '10px',
  },
  rightAlign: {
    textAlign: 'right',
  },
  fileTile: {
    color: '#295115',
    backgroundColor: '#E0E0E0',
    padding: '5px 10px',
    margin: theme.spacing(0.5),
    fontWeight: '800',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    display: 'inline-flex',
    borderRadius: '5px',
  },
  fileNameLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
  },
  rowFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  downloadAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,
  },
  downloadAllBtn: {
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
    borderRadius: '7px',
    color: '#2F7D32',
    transition: 'all 0.4s',
    height: '32px',
    marginTop: '-3px',
    paddingTop: '7px',
    padding: '10px',
    marginLeft: '6px',
    '&:hover': {
      backgroundColor: '#2F7D32',
      color: 'white',
      boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
      borderColor: '#2F7D32',
      cursor: 'pointer',
    },
  },
  toolTipLabel: {
    fontSize: '15px',
    background: 'white',
    boxShadow: ' 0px 0px 3px 0px rgb(0 0 0 / 50%)',
    color: '#497c4b',
    padding: '10px',
  },
}));

const NoteCard = ({
  note,
  onEdit,
  isEditable,
  downloadFile,
  showTimeStamp,
  downloadAll,
  toolTipText,
  isDisabled,
  currentSpill,
  currentUser,
}) => {
  const classes = useStyles();
  const getTime = (createdAt) => {
    const format = showTimeStamp ? 'MM-DD-YYYY h:mm a' : 'MM-DD-YYYY';
    return createdAt
      ? Moment(new Date(createdAt))?.format(format)
      : Moment(new Date())?.format(format);
  };
  const [noteAttachmentsToView, setNoteAttachmentsToView] =
    React.useState(null);

  return (
    <Card className={classes.headerCard}>
      <CardContent className={classes.smallText}>
        <Grid container justify='space-between'>
          <Grid item xs={2} className={classes.rowFlex}>
            <Typography color={'primary'} variant={'body2'}>
              Note Id :
            </Typography>
            <Typography variant={'body2'}>{note.id}</Typography>
          </Grid>

          {canSeeStampsOrAmounts(
            currentUser?.data?.role?.role,
            currentUser?.data?.client_organization?.timestamp_visibility
          ) && (
            <Grid item xs={3}>
              <div className={`${classes.upperText}`}>
                {getFormattedTime(note?.created_at)}
              </div>
            </Grid>
          )}
          <Grid item xs={5}>
            <div className={`${classes.upperText}`}>{note?.service_type}</div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${classes.upperText} ${classes.rightAlign}`}>
              {note?.user?.full_name}
            </div>
          </Grid>
        </Grid>
        <Grid container justify='space-between'>
          {canSeeStampsOrAmounts(
            currentUser?.data?.role?.role,
            currentUser?.data?.client_organization?.monetary_visibility
          ) && (
            <Grid item xs={4}>
              <div className={classes.infoWrapper}>
                <div>
                  {note?.type === 'fixed'
                    ? 'Fixed'
                    : note?.hour
                    ? `${note?.hour} h`
                    : '0.00 h'}
                  @{note?.rate ? ` $${note?.rate}` : ' $0.00'}
                </div>
                <div className={classes.bold}>
                  ${note?.amount ? note?.amount : '0.00'}
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={1} />
          <Grid item xs={6}>
            <div>{formatTimeInNote(note?.description)}</div>
          </Grid>

          <Grid item xs={1}>
            {isEditable &&
              (isDisabled ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={toolTipText}
                  classes={{
                    tooltip: classes.toolTipLabel,
                  }}
                  placement={'left'}
                >
                  <span>
                    <Button
                      className={classes.inlineButton}
                      color='primary'
                      onClick={onEdit}
                      disabled={isDisabled}
                    >
                      Edit
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  className={classes.inlineButton}
                  color='primary'
                  onClick={onEdit}
                  disabled={isDisabled}
                >
                  Edit
                </Button>
              ))}
          </Grid>
        </Grid>

        {note?.note_attachments?.map((attachment, index) => {
          return (
            <div
              key={index}
              className={classes.fileTile}
              onClick={() => {
                downloadFile({ key: attachment.key });
              }}
            >
              <span className={classes.fileNameLabel}>{attachment.name}</span>
              <span>({fileSizeString(attachment.size)})</span>
            </div>
          );
        })}
        <Grid container>
          {note?.note_attachments?.length > 0 && (
            <Grid
              item
              className={classes.downloadAllContainer}
              style={{ marginLeft: 'auto' }}
            >
              <Card
                className={classes.downloadAllBtn}
                onClick={() => setNoteAttachmentsToView(note.note_attachments)}
              >
                <Typography
                  variant='body2'
                  style={{ fontWeight: 800, fontSize: 13 }}
                >
                  View Attachments in Gallery
                </Typography>
              </Card>
            </Grid>
          )}
          {note?.note_attachments?.length > 1 && (
            <Grid item className={classes.downloadAllContainer}>
              <Card
                className={classes.downloadAllBtn}
                onClick={async () => {
                  return downloadAll({
                    id: note.id,
                    type: 'note_all',
                    name: `${note.id}-all-attachments.zip`,
                    spillId: currentSpill.data.spill.id,
                    ipAddress: await getPublicIp(),
                  });
                }}
              >
                <Typography
                  variant='body2'
                  style={{ fontWeight: 800, fontSize: 13 }}
                >
                  Download {note?.note_attachments?.length} attachments (.zip)
                </Typography>
              </Card>
            </Grid>
          )}
        </Grid>
        <AttachmentsGallery
          note={note}
          attachmentsList={noteAttachmentsToView}
          close={() => setNoteAttachmentsToView(null)}
        />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ spill, user }) => ({
  currentSpill: spill.currentSpill,
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
  downloadAll: bindActionCreators(downloadActions.downloadAll, dispatch),
});

NoteCard.propTypes = {
  note: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  downloadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteCard);
