import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  getAgenciesSuccess,
  getAgenciesFail,
  createNewAgencyFail,
  createNewAgencySuccess,
  editAgencySuccess,
  editAgencyFail,
} from "../actionCreators/Agency";
import { doGet, doPost } from "../utils/request";

function* getAgencies({ obj }) {
  try {
    const response = yield doGet(`/agency/agencies?page=${obj?.page}`);
    yield put(getAgenciesSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getAgenciesFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getAgenciesFail());
  }
}

function* createNewAgency({ obj }) {
  try {
    const response = yield doPost("/agency/createNew", obj);
    yield put(createNewAgencySuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(createNewAgencyFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(createNewAgencyFail());
  }
}

function* editAgency({ obj }) {
  try {
    const response = yield doPost("/agency/editAgency", obj);
    yield put(editAgencySuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editAgencyFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editAgencyFail());
  }
}

export default function* watcher() {
  yield all([takeLatest("GET_AGENCIES", getAgencies)]);
  yield all([takeLatest("CREATE_NEW_AGENCY", createNewAgency)]);
  yield all([takeLatest("EDIT_AGENCY", editAgency)]);
}
