import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getAllUploadTypes } from '../../../../../utils/index';

const RejectDialog = ({
  isOpen,
  handleDone,
  handleCancel,
  selectedAttachments,
  attachmentInfo,
}) => {
  const useStyles = makeStyles((theme) => ({
    dialogContent: {
      minWidth: '500px',
    },
    textBox: {
      width: '100%',
    },
    sectionHeading: {
      fontSize: '16px',
      fontWeight: '800',
      marginBottom: '5px',
    },
    fileName: {
      fontSize: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    fileItem: {
      margin: '3px',
    },
    attachmentName: {
      overflow: 'hidden',
      wordWrap: 'break-word',
    },
  }));

  const classes = useStyles();

  const [values, setValues] = React.useState([]);

  const handleChange = (index, value) => {
    let tempValues = [...values];
    tempValues[index] = value;
    setValues(tempValues);
  };

  const isTypeExist = (type) => {
    for (const selected of selectedAttachments) {
      if (attachmentInfo[selected].type === type) {
        return true;
      }
    }
    return false;
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth='sm'>
      <DialogTitle>Reason for Rejection</DialogTitle>

      <DialogContent>
        {getAllUploadTypes().map((type, index) => {
          if (isTypeExist(type.value)) {
            return (
              <div key={index}>
                <div className={classes.sectionHeading}>{type.label}</div>
                {selectedAttachments.map((attachment, index) => {
                  if (attachmentInfo[attachment].type === type.value) {
                    return (
                      <Grid container key={index} className={classes.fileItem}>
                        <Grid className={classes.fileName} item xs={5}>
                          <div className={classes.attachmentName}>
                            {attachmentInfo[attachment].name}
                          </div>
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            value={values[index]}
                            onChange={(event) =>
                              handleChange(index, event.target.value)
                            }
                            variant='outlined'
                            multiline
                            className={classes.textBox}
                            placeholder={'Add a Comment'}
                            inputProps={{ maxLength: 300 }}
                          ></TextField>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </div>
            );
          } else {
            return null;
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            handleCancel();
            setValues([]);
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            handleDone(values);
            setValues([]);
          }}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectDialog;

RejectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedAttachments: PropTypes.array.isRequired,
  attachmentInfo: PropTypes.array.isRequired,
};
