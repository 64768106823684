const initialState = {
  success: false,
  loading: false,
  error: null,
  actions: {
    data: [],
  },
};

const actionReducer = (state = initialState, { type, error, data }) => {
  switch (type) {
    case "GET_ACTIONS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_ACTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        actions: {
          data: data,
        },
      };

    case "GET_ACTIONS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CREATE_NEW_ACTION":
      return {
        ...state,
        type,
        loading: true,
      };

    case "CREATE_NEW_ACTION_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        actions: {
          data: [...state.actions.data, data],
        },
      };

    case "CREATE_NEW_ACTION_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "EDIT_ACTION":
      return {
        ...state,
        type,
        loading: true,
      };

    case "EDIT_ACTION_SUCCESS":
      let tempActions = state.actions.data;
      let updatedIndex = state.actions.data.findIndex(
        (o) => o.action_id === data.action_id
      );
      if (updatedIndex !== -1) {
        tempActions[updatedIndex] = data;
      }
      return {
        ...state,
        loading: false,
        success: true,
        actions: {
          data: tempActions,
        },
        type,
      };

    case "EDIT_ACTION_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    default:
      return state;
  }
};

export default actionReducer;
