import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  heading: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    fontFamily: '"Times New Roman", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  briefing: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: '"Times New Roman", "Roboto", "Helvetica", "Arial", sans-serif',
    padding: '10px 0px',
  },
  spacing: {
    margin: '0px 10px',
  },
}));

const GeneralEula = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainGrid}>
      <span className={classes.heading}>
        PREMIUM ENVIRONMENTAL SERVICES END USER LICENSE AGREEMENT
      </span>

      <div className={classes.briefing}>
        Thank you for choosing to do business with Premium Environmental
        Services, Inc. (<b>“PES”</b> or <b>“Premium Environmental Services”</b>{' '}
        or <b>“Company”</b> or <b>“us”</b>), visiting our website at
        http://www.PremiumEnvironmentalServices.com (the <b>“Website”</b> or{' '}
        <b>“Site”</b>) and using our Site features, including our online spill
        incident-management tool (collectively, <b>“Services”</b>). You (the{' '}
        <b>“User”</b> or <b>“you”</b>) agree and consent to be bound by the
        terms of this End User License Agreement and all terms incorporated by
        reference, along with any and all applicable laws and regulations
        (collectively the <b>“End User Agreement”</b> or <b>“Agreement”</b>)
        through your accessing, browsing, or using the Site and/or clicking the
        “accept” button upon registration for Services, or using our Services.
        Your continued use and access of the Site and Services following any
        amendments to this Agreement indicates that you accept and agree to such
        changes as may be applicable.
        <br />
      </div>
      <div className={classes.briefing}>
        <b>Please read this End User Agreement carefully.</b> If you do not
        agree to the terms and conditions of this Agreement in its entirety, you
        should leave the Site immediately and cease any further use of any
        materials, data, documents, or information you have obtained from your
        use of our Services.
        <br />
      </div>
      <div className={classes.briefing}>
        This Agreement is entered into between you and PES. You and PES agree
        that the following terms and conditions will apply to access or use of
        the Site, the Services offered on the Site, any services provided under
        or related to this Agreement, and any services provided under the PES’
        Spill & Waste Management Services Agreement entered into between you and
        PES, (the <b>“Services Agreement”</b>) or if no Services Agreement
        exists then the applicable written services agreement provided by you
        and entered into with PES (the <b>“Client Services Agreement”</b>).
      </div>

      <div className={classes.briefing}>
        <b>1. General Scope and Use.</b> This Agreement governs and controls
        your use and access to the Site and provided Services. Your access to
        the Services will be for the period of time described in your Services
        Agreement or Client Services Agreement (the <b>“Initial Term”</b> or{' '}
        <b>“Renewal Term”</b>). During the Initial Term or any Renewal Terms,
        you will receive a non-exclusive, non-assignable, royalty free,
        worldwide right to access and use the Services solely for your internal
        business operations subject to the terms of this End User Agreement. You
        acknowledge that this Agreement involves Services offered within our
        Site and PES will not be delivering copies of the Services software to
        you as part of the Agreement.
      </div>

      <div className={classes.briefing}>
        <b>2. Use of Services.</b> You shall not: (a) redistribute or copy the
        Services provided on the Site, (b) provide access to any person or group
        other than authorized Users, (c) modify or create derivative works based
        on the Services or processes used within the Services, (d) reverse
        engineer, decompile, extract, or otherwise attempt to obtain the source
        code of the software that provides the Services, or (e) use or access
        our Services or Site in an attempt to build a similar or competing
        product. PES shall own all right, title and interest in and to the
        software providing the Services, documentation, and all other services
        provided under this Agreement, including modifications, upgrades,
        updates, and feedback related thereto in addition to all intellectual
        property rights related to the Site and/or Services.
      </div>

      <div className={classes.briefing}>
        <b>3. Use of Site.</b> As a condition of your use of the Site, and any
        Services offered via the Site, you will not utilize the Site, any of its
        content, material, documents, data, information, or any portions thereof
        (collectively, <b>“Content”</b>), for any purpose that is unlawful or
        prohibited by the provisions of this Agreement, or to solicit the
        performance of any illegal activity or any other activity that infringes
        upon the rights of PES or any third party. You may not use the Site, or
        any of its Content in any manner that could damage, disable, overburden
        or impair any PES server, or the network(s) connected to any PES server,
        or interfere with any party’s use and enjoyment of the Site or any of
        its Content. You may not attempt to gain unauthorized access to the
        Site, any of its Content, other accounts, computer systems or networks
        connected to the PES server through hacking, password mining or any
        other means. You may not obtain or attempt to obtain any Content through
        any means not initially made available by or through the Site. You may
        not probe, scan or test the vulnerability of the Site or to any network
        to which the Site is connected. You may not breach the security or the
        authentication measures on the Site or any network to which the Site is
        connected. You agree not to use any device, software or routine to
        interfere or attempt to interfere with the proper working of the Site,
        or with any other person’s use of the Site or any of its products or
        services. We reserve the right, in our sole and unfettered discretion,
        to limit or terminate your access to or use of the Site at any time with
        or without notice. Termination of your access or use will not waive or
        affect any other right or relief to which we may be entitled at law or
        in equity.
      </div>

      <div className={classes.briefing}>
        <b>4. Responsibilities.</b>
        <div className={classes.spacing}>
          <br />
          <div>
            <b>4.1 PES Responsibilities.</b> PES shall respond to your support
            inquiries within a reasonable time, provided that you supply all
            details or information requested by PES in order to understand,
            replicate, diagnose, or correct any errors or issues reported
            regarding your use of the Services or Site. PES may access your
            account as required in order to identify or resolve any technical
            problems. PES reserves the right to monitor your use of the Site and
            Services to determine your compliance with this Agreement.
          </div>
          <br />
          <div>
            <b>4.2 User Responsibilities.</b> You shall provide all reasonable
            requested information and assistance to PES in order for PES to
            deliver the Services. As the User, you acknowledge that the Services
            delivered by PES depend on your prompt delivery of certain
            information regarding incidents, including but not limited to all
            relevant documentation regarding a spill incident, safety data
            sheets, bills of lading, and all contact information. It is your
            responsibility to download and export any data stored on our Site or
            used in providing Services before the termination of your Services
            Agreement or Client Services Agreement. Please see Section 6 for
            more information regarding our data retention policy. You will keep
            all account credentials up to date, use reasonable precautions to
            protect your account information, data, passwords, and all other
            login credentials. You shall provide immediate notification of any
            unauthorized access of the Site or Services or any other suspected
            data security breach.
          </div>
        </div>
      </div>

      <div className={classes.briefing}>
        <b> 5. Use of Data. </b> PES shall use all data collected under this
        Agreement for purposes of providing Services. You acknowledge and agree
        that PES’ performance under this Agreement requires the uploading,
        processing, transmitting, and storing of sensitive personal data, Site
        login information, business data, environmental incident metrics and
        details, technical identification information such as your IP address
        and browser cookie information, and the personal data of your employees
        and affiliates (collectively, “Sensitive Data”). By submitting Sensitive
        data to PES, you agree that PES may process, transmit, and store all
        Sensitive Data to the extent necessary for, and for the sole purpose of,
        enabling PES to perform its obligations under this Agreement. You also
        agree to obtain all necessary consents and make all necessary
        disclosures before including any Sensitive Data in information or
        documents uploaded or provided to PES. You acknowledge that you are
        solely responsible for any Sensitive Data that may be contained in any
        of the content or information provided to PES for use on the Site or
        delivery of Services. You are also solely responsible for providing
        notice to, and obtaining consents from, any individuals or groups
        regarding the collection, processing, transfer, or storage of their data
        through your use of our Services or Site. You agree to inform PES about
        any special categories of data contained in Sensitive Data and
        acknowledge that you are solely responsible for ensuring PES meets any
        restrictions or special requirements when processing such data. You
        further agree to defend, indemnify and hold harmless PES for any claims
        brought by a third-party related to the your actions and/or omissions
        related to the Sensitive Data provided by you.
      </div>

      <div className={classes.briefing}>
        <b>6. Data Retention.</b> You are solely responsible for downloading and
        retaining all information, data, and Sensitive Data uploaded and used in
        providing Services to you (<b>“Client Records”</b>) before the
        expiration of your Services Agreement or Client Services Agreement. If
        you do not download your Client Records before the expiration of your
        Services Agreement or Client Services Agreement, Client Records may be
        accessed and downloaded for a period of one (1) year following the
        expiration of your Services Agreement or Client Services Agreement as
        may be applicable by e-mailing your access request to:{' '}
        <b>"____@____.com."</b> Upon expiration of the one (1) year period, all
        Client Records shall be destroyed and will no longer be accessible.
      </div>

      <div className={classes.briefing}>
        <b>7. Intellectual Property Rights.</b> You retain ownership in and to
        any information provided to PES required to allow PES to provide the
        Services under this Agreement including any intellectual property rights
        associated with such information provided by you. PES retains ownership
        to the Site, the Services, the software, any enhancements thereto and
        anything developed by PES under this Agreement in addition to any and
        all intellectual property rights associated therewith. PES also retains
        ownership in and may use or incorporate into our Site or Services any
        suggestions, feature requests, recommendations, or other feedback
        provided by you related to the Site or the Services
      </div>

      <div className={classes.briefing}>
        <b> 8. Non-Payment Actions.</b> PES reserves the right to suspend access
        to the Site and delivery of the Services if you fail to timely pay any
        amounts due and owing to PES under this Agreement or the Services
        Agreement. Suspension of access to the Site or delivery of the Services
        does not release you of any obligations under this Agreement or the
        Services Agreement, and you agree that PES shall not be liable to you or
        any other third party for liabilities, claims, issues, or expenses
        arising from or related to any suspension of access to the Site or
        delivery of the Services stemming from your nonpayment.
      </div>

      <div className={classes.briefing}>
        <b>9. Limited Warranty.</b> PES represents and warrants that it will
        provide access to the Site and delivery of Services in a professional
        manner consistent with generally accepted industry standards. For any
        breach of a warranty, your exclusive remedy shall be as provided in
        Section 11, Limitation of Liability.
      </div>

      <div className={classes.briefing}>
        <b>10. Disclaimer of Warranties.</b> PES DOES NOT GUARANTEE THAT THE
        SERVICES OR SITE WILL BE OFFERED ERROR-FREE, VIRUS-FREE OR
        UNINTERRUPTED, OR THAT PES WILL CORRECT ALL ERRORS ARISING FROM YOUR USE
        OF THE SITE OR FROM THE DELIVERY OF SERVICES. PES SHALL NOT BE LIABLE
        FOR UNAUTHORIZED ALTERATION, MODIFICATION, SECURITY BREACHES, OR
        DESTRUCTION OF YOUR DATA OR CONTENT. YOU ACKNOWLEDGE THAT PES DOES NOT
        CONTROL THE TRANSFER OF DATA OVER THE INTERNET AND ACCESS TO AND USE OF
        THE SITE AND ITS SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND
        ISSUES. NOTWITHSTANDING ANYTHING IN THE AGREEMENT TO THE CONTRARY, YOU
        ACKNOWLEDGE AND AGREE THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS”
        BASIS AND “WITH ALL FAULTS.” PES RESERVES THE RIGHT, IN OUR SOLE AND
        UNFETTERED DISCRETION, TO RESTRICT OR TERMINATE YOUR ACCESS TO THE SITE
        OR ANY FEATURE THEREOF. YOU ACKNOWLEDGE AND AGREE WE EXPRESSLY DISCLAIM
        ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING ANY
        IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS OR
        SUITABILITY FOR A PARTICULAR PURPOSE OR USE. YOU RECOGNIZE THAT ACCESS
        TO THE SITE WILL NOT BE SECURE, UNINTERRUPTED OR ERROR-FREE. YOUR USE OF
        THE SITE IS AT YOUR SOLE RISK AND WE MAKE NO WARRANTY THAT THE SITE OR
        THE SERVER WILL BE FREE FROM MALWARE, VIRUSES, TROJAN HORSES, WORMS, OR
        OTHER HARMFUL COMPONENTS. THE DISCLAIMERS STATED HEREIN SHALL APPLY TO
        THE FULLEST EXTENT PERMITTED BY LAW. DEPENDING ON YOUR JURISDICTION, THE
        LAW MAY NOT PERMIT THE DISCLAIMER OF WARRANTIES, SO THE ABOVE DISCLAIMER
        MAY NOT APPLY TO YOU.
      </div>

      <div className={classes.briefing}>
        <b> 11. Limitation of Liability.</b> Neither party will be liable
        for indirect, incidental, exemplary, special or consequential damages;
        loss or corruption of data or interruption or loss of business; or loss
        of revenues, profits, goodwill or anticipated sales or savings.
        Notwithstanding anything herein to the contrary, PES’ total liability to
        you for any and all injuries, (including without limitation disability
        or death), claims, actions, demands, attorneys’ and expert fees,
        damages, losses, costs, expenses and/or liabilities (collectively{' '}
        <b>“claims”</b>), whether such arises based on negligence, strict
        liability or by any statutory or common law theories arising out of, in
        connection with, or in any way related to the Site and/or PES’ Services
        contemplated in this Agreement, shall be limited to an amount not to
        exceed PES’ applicable limits of insurance. Notwithstanding anything
        herein to the contrary, PES shall not have any liability to you for
        claims arising out of or relating to your sole negligence or willful
        misconduct. You hereby agree to defend, indemnify and hold harmless PES
        for damages or costs incurred by PES including attorneys’ for any and
        all claims brought against PES by third parties caused by or related to
        your actions or omissions. All spill and waste materials handled or
        disposed of by PES pursuant to this Agreement will remain owned by you
        and/or the generator of such materials. Nothing herein shall be
        construed to transfer ownership of such materials to PES.
      </div>

      <div className={classes.briefing}>
        <b>12. Confidentiality. </b>Information deemed confidential (
        <b>“Confidential Information”</b>) means (a) any information or data
        disclosed by one party (the <b>“Disclosing Party”</b>) to the receiving
        party (the <b>“Receiving Party”</b>) in connection with this Agreement
        which has been marked as confidential or identified at the time of
        disclosure as confidential and subsequently confirmed as confidential in
        writing or (b) reasonably appears to be confidential due to the
        circumstances of disclosure or the nature of the information.
      </div>

      <div className={classes.briefing}>
        <b>13. Non-exclusivity.</b>You acknowledge that access to and use of the
        Site and Services are provided to you on a non-exclusive basis. Nothing
        shall be deemed to prevent or restrict PES in providing Services or
        access to the Site to other parties.
      </div>

      <div className={classes.briefing}>
        <b>14. Termination Effects.</b> PES reserves the right to immediately
        discontinue access to the Site and delivery of the Services, including
        all usage rights granted under this Agreement, upon the termination or
        expiration of this Agreement, the Services Agreement, or the Client
        Services Agreement. Upon the termination of this Agreement and
        subsequent written request by the Disclosing Party, the Receiving Party
        of any Confidential Information shall immediately return or destroy any
        such Confidential Information and provide written certification of such
        destruction.
      </div>

      <div className={classes.briefing}>
        <b>15. Dispute Resolution. </b> Any and all disputes, complaints,
        controversies, claims and grievances arising under, out of, in
        connection with, or in any manner related to this Agreement, the Site or
        the Services or the relationship of parties hereunder that are not first
        resolved by good faith negotiations shall be settled by binding
        arbitration in accordance with the Commercial Arbitration Rules of the
        American Arbitration Association (<b>“Rules”</b>). Any decision and
        award of the arbitrator shall be final, binding and conclusive upon all
        of the parties hereto and said decision and award may be entered as a
        final judgment in any court of competent jurisdiction. Notwithstanding
        said Rules, any arbitration hearing to take place hereunder shall be
        conducted in Evansville, Indiana, before one (1) arbitrator who shall be
        an attorney who has substantial experience in business and commercial
        law issues. Each party hereby consents to a single, consolidated
        arbitration proceeding of multiple claims, or claims involving two (2)
        or more parties. The arbitrator shall be required to make written
        findings of fact and conclusions of law to support its award. Except as
        may be required by law, neither a party nor an arbitrator(s) may
        disclose the existence, content, or results of any arbitration hereunder
        without the prior written consent of both parties. By execution of this
        Agreement, the parties consent to the jurisdiction of the AAA and waive
        any objection which either party may have to any proceeding so commenced
        based upon improper venue or forum non coveniens. In the event of any
        arbitration between the parties hereto involving this Agreement or the
        respective rights of the parties hereunder, the party who does not
        prevail in such arbitration shall pay to the prevailing party reasonable
        attorneys’ fees, costs and expenses of such arbitration incurred by the
        prevailing party. As used herein the term “prevailing party” shall
        include, but not be limited to, a party who obtains legal counsel or
        brings an action against the other by reason of the other’s breach or
        default and obtains substantially the relief sought whether by
        compromise, settlement, or judgment.
      </div>

      <div className={classes.briefing}>
        <b>16. Miscellaneous.</b> This Agreement shall be governed by and
        construed in accordance with the laws of the State of Indiana, not
        including the choice of law rules thereof. In the event that any of the
        provisions of this Agreement shall be held by a court or other tribunal
        of competent jurisdiction to be unenforceable, such provision shall be
        enforced to the fullest extent permissible and the remaining portion of
        this Agreement shall remain in full force and effect. In the event of a
        conflict between the terms of this End User Agreement and the terms of
        the Services Agreement, the terms of the Services Agreement shall
        govern. In the event of a conflict between the terms of this End User
        Agreement and the terms of a Client Services Agreement, the terms of
        this End User Agreement shall govern. The parties have read this
        Agreement completely and have had the opportunity to seek the advice and
        assistance of competent legal counsel. In the event that ambiguity
        exists or is deemed to exist in any provisions of this Agreement, said
        ambiguity is not to be construed by reference to any doctrine calling
        for such ambiguity to be construed against the drafter of this
        Agreement. No statement, action, or omission of either of the parties
        hereto shall be considered to be a waiver of any right, including, but
        not by way of limitation, any failure of either party to insist upon the
        strict performance of any agreement, term or condition hereof, or to
        exercise any right or remedy consequent upon a breach thereof, during
        the continuation of any such breach shall constitute a waiver of any
        such breach or any such agreement, term or condition. No remedy or
        election hereunder shall be deemed exclusive, but shall, whenever
        possible, be cumulative with all other remedies at law or in equity. All
        headings set forth herein are included for the convenience of reference
        only and shall not affect the interpretation hereof, nor shall any
        weight or value be given to the relative position of any part or
        provision hereof in relation to any other provision in determining such
        construction. The recitals set forth in the above preamble are
        incorporated herein by this reference and made a part of this Agreement.
        As used in this Agreement, the plural shall be substituted for the
        singular, and the singular for the plural, where appropriate; and words
        and pronouns of any gender shall include any other gender. Except as
        stated herein otherwise, this instrument is the final agreement,
        contains the entire, complete and exclusive agreement between the
        parties concerning this subject, and supersedes all prior oral or
        written understandings, agreements or contracts, formal or informal,
        between the parties.
      </div>
    </div>
  );
};

export default GeneralEula;
