const initialState = {
  success: false,
  loading: false,
  error: null,
  mostLargestSpills: {
    data: null,
    loading: false,
    error: null,
  },
  businessHoursSpills: {
    data: null,
    loading: false,
    error: null,
  },
  currentDailyTotal: {
    data: null,
    loading: false,
    error: null,
  },
  currentYearSpills: {
    data: null,
    loading: false,
    error: null,
  },
  previousYearSpills: {
    data: null,
    loading: false,
    error: null,
  },
  currentVsPreviousYearSpills: {
    data: null,
    loading: false,
    error: null,
  },
  daysToPaySpills: {
    data: null,
    loading: false,
    error: null
  },
  spillStatusCount: {
    data: {
      statusCount: null,
      openSpillsCount: null,
      closedSpillsCount: null,
      averageDaysToCloseSpill: null,
      previousYearOpenedSpills: null,
    },
    loading: false,
    error: null,
  },
  allMonthSpillsCountInYear: {
    data: {
      values: null,
    },
    loading: false,
    error: null,
  },
  spillsOnEachWorkDay: {
    data: {
      values: null,
    },
    loading: false,
    error: null,
  },
  spillsOnEachWeek: {
    data: {
      values: null,
    },
    loading: false,
    error: null,
  },
  currentMonthSpillProjection: {
    data: null,
    loading: false,
    error: null,
  },
  averagePerDayPerWeek: {
    data: null,
    loading: false,
    error: null,
  },

  filteredSpillsStatusCount: {
    data: null,
    loading: false,
    error: null,
  },
  currentVsPreviousMonthSpillAtMonth: {
    data: null,
    loading: false,
    error: null,
  },
  nonHazardousIncidents: {
    data: null,
    loading: false,
    error: null,
  },
  hazardousIncidents: {
    data: null,
    loading: false,
    error: null,
  },
  nonEmergencyIncidents: {
    data: null,
    loading: false,
    error: null,
  },
  emergencyIncidents: {
    data: null,
    loading: false,
    error: null,
  },
  incidentsAtLocationTypes: {
    data: null,
    loading: false,
    error: null,
  },
  forms5800Completed: {
    data: null,
    loading: false,
    error: null,
  },
  evacuationIncidentsNumber: {
    data: null,
    loading: false,
    error: null,
  },
  disposalIncidentsNumber: {
    data: null,
    loading: false,
    error: null,
  },
  avgTimeForExcavation: {
    data: null,
    loading: false,
    error: null,
  },
  formDetails5800: {
    data: null,
    loading: false,
    error: null,
  },
  fetchSpillsStatusesCountRoleBased: {
    data: null,
    loading: false,
    error: null,
  },
  turnedUsDownForContractor: {
    data: null,
    loading: false,
    error: null,
  },
  hazIncidentsClient: {
    data: null,
    loading: false,
    error: null,
  },
  incidentsReportedClient: {
    data: null,
    loading: false,
    error: null,
  },
  vehicleAccidents: {
    data: null,
    loading: false,
    error: null,
  },
  completed5800Client: {
    data: null,
    loading: false,
    error: null,
  },
  averageInvoicesClient: {
    data: null,
    loading: false,
    error: null,
  },
  savingsClient: {
    data: null,
    loading: false,
    error: null,
  },
  incidentCompletionTypeStatuses: {
    data: null,
    loading: false,
    error: null,
  },
  averageEta: {
    data: null,
    loading: false,
    error: null,
  },
  averageIncidentSameContractor: {
    data: null,
    loading: false,
    error: null,
  },
  allContractorTurnUsDown: {
    data: null,
    loading: false,
    error: null,
  },
  responsePercentageContractors: {
    data: null,
    loading: false,
    error: null,
  },
  particularContractorTurnedUsDown: {
    data: null,
    loading: false,
    error: null,
  },
  currentlyOpenSpills: {
    data: null,
    loading: false,
    error: null,
  },
  currentOpenedSpillsInCurrentYear: {
    data: null,
    loading: false,
    error: null,
  },
  currentOpenedSpillsInPreviousYear: {
    data: null,
    loading: false,
    error: null,
  },
  closedSpillsYTD: {
    data: null,
    loading: false,
    error: null,
  },
  currentlyClosedSpillsCurrentYear: {
    data: null,
    loading: false,
    error: null,
  },
  avgDaysToCloseSpill: {
    data: null,
    loading: false,
    error: null,
  },
  weeklySpillsForAMonth: {
    data: [],
    loading: false,
    error: null,
  },
  afterHoursSpills: {
    data: null,
    loading: false,
    error: null,
  },
  allMonthSpillsCountInCurrentVSPreviousYear: {
    data: null,
    loading: false,
    error: null,
  },
  workInProgress: {
    data: null,
    loading: false,
    error: null,
  },
  csIncidentsSince8: {
    data: null,
    loading: false,
    error: null,
  },
  csDailyAfterHours: {
    data: null,
    loading: false,
    error: null,
  },
  csDailyBusinessHours: {
    data: null,
    loading: false,
    error: null,
  },
  pmList: {
    data: null,
    loading: false,
    error: null,
  },
  currentProjectCount: {
    data: null,
    loading: false,
    error: null,
  },
  trackingFileIncidents: {
    data: null,
    loading: false,
    error: null,
  },
  spillsBroughtIntoOffice: {
    data: null,
    loading: false,
    error: null,
  },
  digsScheduledToday: {
    data: null,
    loading: false,
    error: null,
  },
  nonTrackingWithWorkInProgress: {
    data: null,
    loading: false,
    error: null,
  },
  pmOpenIncidents: {
    data: null,
    loading: false,
    error: null,
  },
  nonTrackingFilesWIP: {
    data: null,
    loading: false,
    error: null,
  },
  dailyWeeklyGisMap: {
    data: null,
    loading: false,
    error: null,
  },
  csCurrentWeekSpills: {
    data: null,
    loading: false,
    error: null,
  },
  csCurrentWeekBusinessHours: {
    data: null,
    loading: false,
    error: null,
  },
  csCurrentWeekAfterHours: {
    data: null,
    loading: false,
    error: null,
  },
  csCurrentWeekBroughtIntoOffice: {
    data: null,
    loading: false,
    error: null,
  },
  csCurrentWeekExcavation: {
    data: null,
    loading: false,
    error: null,
  },
  csCurrentWeekIncidentsTakenHome: {
    data: null,
    loading: false,
    error: null,
  },
  csIncidentsTakenHome: {
    data: null,
    loading: false,
    error: null,
  },

};

const analyticsReducer = (
  state = initialState,
  { obj, type, error, payload, data }
) => {
  switch (type) {
    case 'RESET_AGENCY':
      return initialState;

    case 'GET_MOST_LARGEST_SPILLS':
      return {
        ...state,
        mostLargestSpills: {
          ...state.mostLargestSpills,
          loading: true,
        },
      };

    case 'GET_MOST_LARGEST_SPILLS_SUCCESS':
      return {
        ...state,
        mostLargestSpills: {
          ...state.mostLargestSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_MOST_LARGEST_SPILLS_FAIL':
      return {
        ...state,
        mostLargestSpills: {
          ...state.mostLargestSpills,
          error,
          loading: false,
        },
      };

    case 'GET_WORK_IN_PROGRESS':
      return {
        ...state,
        workInProgress: {
          ...state.workInProgress,
          loading: true,
        },
      };

    case 'GET_WORK_IN_PROGRESS_SUCCESS':
      return {
        ...state,
        workInProgress: {
          ...state.workInProgress,
          data: data,
          loading: false,
        },
      };

    case 'GET_WORK_IN_PROGRESS_FAIL':
      return {
        ...state,
        workInProgress: {
          ...state.workInProgress,
          error,
          loading: false,
        },
      };

    case 'GET_PM_LIST':
      return {
        ...state,
        pmList: {
          ...state.pmList,
          loading: true,
        },
      };

    case 'GET_PM_LIST_SUCCESS':
      return {
        ...state,
        pmList: {
          ...state.pmList,
          data: data,
          loading: false,
        },
      };

    case 'GET_PM_LIST_FAIL':
      return {
        ...state,
        pmList: {
          ...state.pmList,
          error,
          loading: false,
        },
      };

    case 'GET_CURRENT_PROJECT_COUNT':
      return {
        ...state,
        currentProjectCount: {
          ...state.currentProjectCount,
          loading: true,
        },
      };

    case 'GET_CURRENT_PROJECT_COUNT_SUCCESS':
      return {
        ...state,
        currentProjectCount: {
          ...state.currentProjectCount,
          data: data,
          loading: false,
        },
      };

    case 'GET_CURRENT_PROJECT_COUNT_FAIL':
      return {
        ...state,
        currentProjectCount: {
          ...state.currentProjectCount,
          error,
          loading: false,
        },
      };

    case 'GET_PM_OPEN_INCIDENTS':
      return {
        ...state,
        pmOpenIncidents: {
          ...state.pmOpenIncidents,
          loading: true,
        },
      };

    case 'GET_PM_OPEN_INCIDENTS_SUCCESS':
      return {
        ...state,
        pmOpenIncidents: {
          ...state.pmOpenIncidents,
          data: data,
          loading: false,
        },
      };

    case 'GET_PM_OPEN_INCIDENTS_FAIL':
      return {
        ...state,
        pmOpenIncidents: {
          ...state.pmOpenIncidents,
          error,
          loading: false,
        },
      };

    case 'GET_CS_DAILY_AFTER_HOURS':
      return {
        ...state,
        csDailyAfterHours: {
          ...state.csDailyAfterHours,
          loading: true,
        },
      };

    case 'GET_CS_DAILY_AFTER_HOURS_SUCCESS':
      return {
        ...state,
        csDailyAfterHours: {
          ...state.csDailyAfterHours,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_DAILY_AFTER_HOURS_FAIL':
      return {
        ...state,
        csDailyAfterHours: {
          ...state.csDailyAfterHours,
          error,
          loading: false,
        },
      };

    case 'GET_CS_DAILY_BUSINESS_HOURS':
      return {
        ...state,
        csDailyBusinessHours: {
          ...state.csDailyBusinessHours,
          loading: true,
        },
      };

    case 'GET_CS_DAILY_BUSINESS_HOURS_SUCCESS':
      return {
        ...state,
        csDailyBusinessHours: {
          ...state.csDailyBusinessHours,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_DAILY_BUSINESS_HOURS_FAIL':
      return {
        ...state,
        csDailyBusinessHours: {
          ...state.csDailyBusinessHours,
          error,
          loading: false,
        },
      };

    case 'GET_CS_INCIDENTS_TAKEN_HOME':
      return {
        ...state,
        csIncidentsTakenHome: {
          ...state.csIncidentsTakenHome,
          loading: true,
        },
      };

    case 'GET_CS_INCIDENTS_TAKEN_HOME_SUCCESS':
      return {
        ...state,
        csIncidentsTakenHome: {
          ...state.csIncidentsTakenHome,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_INCIDENTS_TAKEN_HOME_FAIL':
      return {
        ...state,
        csIncidentsTakenHome: {
          ...state.csIncidentsTakenHome,
          error,
          loading: false,
        },
      };

    case 'GET_DAILY_WEEKLY_GIS_MAP':
      return {
        ...state,
        dailyWeeklyGisMap: {
          ...state.dailyWeeklyGisMap,
          loading: true,
        },
      };

    case 'GET_DAILY_WEEKLY_GIS_MAP_SUCCESS':
      return {
        ...state,
        dailyWeeklyGisMap: {
          ...state.dailyWeeklyGisMap,
          data: data,
          loading: false,
        },
      };

    case 'GET_DAILY_WEEKLY_GIS_MAP_FAIL':
      return {
        ...state,
        dailyWeeklyGisMap: {
          ...state.dailyWeeklyGisMap,
          error,
          loading: false,
        },
      };

    case 'GET_CS_INCIDENTS_SINCE_8':
      return {
        ...state,
        csIncidentsSince8: {
          ...state.csIncidentsSince8,
          loading: true,
        },
      };

    case 'GET_CS_INCIDENTS_SINCE_8_SUCCESS':
      return {
        ...state,
        csIncidentsSince8: {
          ...state.csIncidentsSince8,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_INCIDENTS_SINCE_8_FAIL':
      return {
        ...state,
        csIncidentsSince8: {
          ...state.csIncidentsSince8,
          error,
          loading: false,
        },
      };

    case 'GET_BUSINESS_HOURS_SPILLS':
      return {
        ...state,
        businessHoursSpills: {
          ...state.businessHoursSpills,
          loading: true,
        },
      };

    case 'GET_BUSINESS_HOURS_SPILLS_SUCCESS':
      return {
        ...state,
        businessHoursSpills: {
          ...state.businessHoursSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_BUSINESS_HOURS_SPILLS_FAIL':
      return {
        ...state,
        businessHoursSpills: {
          ...state.businessHoursSpills,
          error,
          loading: false,
        },
      };

    case 'GET_NON_TRACKING_FILES_WIP':
      return {
        ...state,
        nonTrackingFilesWIP: {
          ...state.nonTrackingFilesWIP,
          loading: true,
        },
      };

    case 'GET_NON_TRACKING_FILES_WIP_SUCCESS':
      return {
        ...state,
        nonTrackingFilesWIP: {
          ...state.nonTrackingFilesWIP,
          data: data,
          loading: false,
        },
      };

    case 'GET_NON_TRACKING_FILES_WIP_FAIL':
      return {
        ...state,
        nonTrackingFilesWIP: {
          ...state.nonTrackingFilesWIP,
          error,
          loading: false,
        },
      };

    case 'GET_CURRENT_DAILY_TOTAL':
      return {
        ...state,
        currentDailyTotal: {
          ...state.currentDailyTotal,
          loading: true,
        },
      };

    case 'GET_CURRENT_DAILY_TOTAL_SUCCESS':
      return {
        ...state,
        currentDailyTotal: {
          ...state.currentDailyTotal,
          data: data,
          loading: false,
        },
      };

    case 'GET_CURRENT_DAILY_TOTAL_FAIL':
      return {
        ...state,
        currentDailyTotal: {
          ...state.currentDailyTotal,
          error,
          loading: false,
        },
      };

    case 'GET_CURRENT_YEAR_SPILLS':
      return {
        ...state,
        currentYearSpills: {
          ...state.currentYearSpills,
          loading: true,
        },
      };

    case 'GET_CURRENT_YEAR_SPILLS_SUCCESS':
      return {
        ...state,
        currentYearSpills: {
          ...state.currentYearSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_CURRENT_YEAR_SPILLS_FAIL':
      return {
        ...state,
        currentYearSpills: {
          ...state.currentYearSpills,
          error,
          loading: false,
        },
      };

    case 'GET_PREVIOUS_YEAR_SPILLS':
      return {
        ...state,
        previousYearSpills: {
          ...state.previousYearSpills,
          loading: true,
        },
      };

    case 'GET_PREVIOUS_YEAR_SPILLS_SUCCESS':
      return {
        ...state,
        previousYearSpills: {
          ...state.previousYearSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_PREVIOUS_YEAR_SPILLS_FAIL':
      return {
        ...state,
        previousYearSpills: {
          ...state.previousYearSpills,
          error,
          loading: false,
        },
      };

    case 'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS':
      return {
        ...state,
        currentVsPreviousYearSpills: {
          ...state.currentVsPreviousYearSpills,
          loading: true,
        },
      };

    case 'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS_SUCCESS':
      return {
        ...state,
        currentVsPreviousYearSpills: {
          ...state.currentVsPreviousYearSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_CURRENT_VS_PREVIOUS_YEAR_SPILLS_FAIL':
      return {
        ...state,
        currentVsPreviousYearSpills: {
          ...state.currentVsPreviousYearSpills,
          error,
          loading: false,
        },
      };

      case 'GET_DAYS_TO_PAY_SPILLS':
        return {
          ...state,
          daysToPaySpills: {
            ...state.daysToPaySpills,
            loading: true,
          },
        };
  
      case 'GET_DAYS_TO_PAY_SPILLS_SUCCESS':
        return {
          ...state,
          daysToPaySpills: {
            ...state.daysToPaySpills,
            data: data,
            loading: false,
          },
        };
  
      case 'GET_DAYS_TO_PAY_SPILLS_FAIL':
        return {
          ...state,
          daysToPaySpills: {
            ...state.daysToPaySpills,
            error,
            loading: false,
          },
        };

    case 'GET_SPILL_STATUS_COUNT':
      return {
        ...state,
        spillStatusCount: {
          ...state.spillStatusCount,
          loading: true,
        },
      };

    case 'GET_SPILL_STATUS_COUNT_SUCCESS':
      return {
        ...state,
        spillStatusCount: {
          ...state.spillStatusCount,
          loading: false,
          data: {
            ...state.spillStatusCount.data,
            statusCount: data.data
              .filter(
                (spill) =>
                  ![
                    'Open Spills',
                    'Closed Spills',
                    'Average days to close a spill',
                    'Previous year opened spills',
                  ].includes(spill.status)
              )
              ?.map((item) => ({
                ...item,
                count: item?.count || item?.average,
              })),
            openSpillsCount: data.data.find(
              (spill) => spill.status === 'Open Spills'
            ),
            closedSpillsCount: data.data.find(
              (spill) => spill.status === 'Closed Spills'
            ),
            averageDaysToCloseSpill: data.data.find(
              (spill) => spill.status === 'Average days to close a spill'
            ),
            previousYearOpenedSpills: data.data.find(
              (spill) => spill.status === 'Previous year opened spills'
            ),
          },
        },
      };

    case 'GET_SPILL_STATUS_COUNT_FAIL':
      return {
        ...state,
        spillStatusCount: {
          ...state.spillStatusCount,
          loading: false,
          error,
        },
      };
    case 'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR':
      return {
        ...state,
        allMonthSpillsCountInYear: {
          ...state.allMonthSpillsCountInYear,
          loading: true,
        },
      };
    case 'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR_SUCCESS':
      return {
        ...state,
        allMonthSpillsCountInYear: {
          ...state.allMonthSpillsCountInYea,
          loading: false,
          data: {
            values: data.map((item) => {
              return {
                month: item?.id,
                count: item?.count,
                value: item?.count,
                percentage: item?.percentage ? Math.floor(item?.percentage) : 0,
              };
            }),
          },
        },
      };
    case 'GET_ALL_MONTH_SPILLS_COUNT_IN_YEAR_FAIL':
      return {
        ...state,
        allMonthSpillsCountInYear: {
          ...state.allMonthSpillsCountInYear,
          loading: false,
          error,
        },
      };

    case 'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR':
      return {
        ...state,
        allMonthSpillsCountInCurrentVSPreviousYear: {
          ...state.allMonthSpillsCountInCurrentVSPreviousYear,
          loading: true,
        },
      };
    case 'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR_SUCCESS':
      return {
        ...state,
        allMonthSpillsCountInCurrentVSPreviousYear: {
          ...data,
          loading: false,
          error: false,
        },
      };
    case 'GET_ALL_MONTH_SPILLS_COUNT_IN_CURRENT_VS_PREVIOUS_YEAR_FAIL':
      return {
        ...state,
        allMonthSpillsCountInCurrentVSPreviousYear: {
          ...state.allMonthSpillsCountInCurrentVSPreviousYear,
          loading: false,
          error: true,
        },
      };
    case 'GET_SPILLS_ON_EACH_WORKDAY':
      return {
        ...state,
        spillsOnEachWorkDay: {
          ...state.spillsOnEachWorkDay,
          loading: true,
        },
      };

    case 'GET_SPILLS_ON_EACH_WORKDAY_SUCCESS':
      return {
        ...state,
        spillsOnEachWorkDay: {
          ...state.spillsOnEachWorkDay,
          loading: false,
          data: {
            values: data.map((item, index) => {
              if (data.length - 1 === index && item?.day === 'Mon') {
                data[data.length - 1].day = 'Mon ';
              }
              return {
                day: item?.day,
                count: item?.count,
                value: item?.count,
              };
            }),
          },
        },
      };

    case 'GET_SPILLS_ON_EACH_WORKDAY_FAIL':
      return {
        ...state,
        spillsOnEachWorkDay: {
          ...state.spillsOnEachWorkDay,
          loading: false,
          error,
        },
      };
    case 'GET_SPILLS_ON_EACH_WEEK':
      return {
        ...state,
        spillsOnEachWeek: {
          ...state.spillsOnEachWeek,
          loading: true,
        },
      };

    case 'GET_SPILLS_ON_EACH_WEEK_SUCCESS':
      return {
        ...state,
        spillsOnEachWeek: {
          ...state.spillsOnEachWeek,
          loading: false,
          data: {
            values: data.map((item) => {
              return {
                week: `${item?.Week}`,
                value: item?.count,
                count: item?.count,
              };
            }),
          },
        },
      };
    case 'GET_SPILLS_ON_EACH_WEEK_FAIL':
      return {
        ...state,
        spillsOnEachWeek: {
          ...state.spillsOnEachWeek,
          loading: false,
          error,
        },
      };
    case 'GET_CURRENT_MONTH_SPILLS_PROJECTION':
      return {
        ...state,
        currentMonthSpillProjection: {
          ...state.currentMonthSpillProjection,
          loading: true,
        },
      };
    case 'GET_CURRENT_MONTH_SPILLS_PROJECTION_SUCCESS':
      return {
        ...state,
        currentMonthSpillProjection: {
          ...state.currentMonthSpillProjection,
          loading: false,
          data: data?.projectedSpills,
        },
      };
    case 'GET_CURRENT_MONTH_SPILLS_PROJECTION_FAIL':
      return {
        ...state,
        currentMonthSpillProjection: {
          ...state.currentMonthSpillProjection,
          loading: false,
          error,
        },
      };

    case 'GET_AVERAGE_PER_DAY_PER_WEEK':
      return {
        ...state,
        averagePerDayPerWeek: {
          ...state.averagePerDayPerWeek,
          loading: true,
        },
      };
    case 'GET_AVERAGE_PER_DAY_PER_WEEK_SUCCESS':
      return {
        ...state,
        averagePerDayPerWeek: {
          ...state.averagePerDayPerWeek,
          loading: false,
          data: data,
        },
      };
    case 'GET_AVERAGE_PER_DAY_PER_WEEK_FAIL':
      return {
        ...state,
        averagePerDayPerWeek: {
          ...state.averagePerDayPerWeek,
          loading: false,
          error,
        },
      };

    case 'GET_FILTERED_SPILLS_STATUS_COUNT':
      return {
        ...state,
        filteredSpillsStatusCount: {
          ...state.filteredSpillsStatusCount,
          loading: true,
        },
      };
    case 'GET_FILTERED_SPILLS_STATUS_COUNT_SUCCESS':
      return {
        ...state,
        filteredSpillsStatusCount: {
          ...state.filteredSpillsStatusCount,
          loading: false,
          data: data?.data,
        },
      };
    case 'GET_FILTERED_SPILLS_STATUS_COUNT_FAIL':
      return {
        ...state,
        filteredSpillsStatusCount: {
          ...state.filteredSpillsStatusCount,
          loading: false,
          error,
        },
      };
    case 'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH':
      return {
        ...state,
        currentVsPreviousMonthSpillAtMonth: {
          ...state.currentVsPreviousMonthSpillAtMonth,
          loading: true,
        },
      };
    case 'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH_SUCCESS':
      return {
        ...state,
        currentVsPreviousMonthSpillAtMonth: {
          ...state.currentVsPreviousMonthSpillAtMonth,
          loading: false,
          data: data,
        },
      };
    case 'GET_CURRENT_VS_PREVIOUS_MONTH_SPILL_AT_MONTH_FAIL':
      return {
        ...state,
        currentVsPreviousMonthSpillAtMonth: {
          ...state.currentVsPreviousMonthSpillAtMonth,
          loading: false,
          error,
        },
      };
    case 'GET_NON_HAZARDOUS_INCIDENTS':
      return {
        ...state,
        nonHazardousIncidents: {
          ...state.nonHazardousIncidents,
          loading: true,
          error,
        },
      };
    case 'GET_NON_HAZARDOUS_INCIDENTS_SUCCESS':
      return {
        ...state,
        nonHazardousIncidents: {
          ...state.nonHazardousIncidents,
          loading: false,
          data: data,
        },
      };
    case 'GET_NON_HAZARDOUS_INCIDENTS_FAIL':
      return {
        ...state,
        nonHazardousIncidents: {
          ...state.nonHazardousIncidents,
          loading: false,
          error: error,
        },
      };
    case 'GET_HAZARDOUS_INCIDENTS':
      return {
        ...state,
        hazardousIncidents: {
          ...state.hazardousIncidents,
          loading: true,
          error,
        },
      };
    case 'GET_HAZARDOUS_INCIDENTS_SUCCESS':
      return {
        ...state,
        hazardousIncidents: {
          ...state.hazardousIncidents,
          loading: false,
          data: data,
        },
      };
    case 'GET_HAZARDOUS_INCIDENTS_FAIL':
      return {
        ...state,
        hazardousIncidents: {
          ...state.hazardousIncidents,
          loading: false,
          error: error,
        },
      };
    case 'GET_EMERGENCY_INCIDENTS':
      return {
        ...state,
        emergencyIncidents: {
          ...state.emergencyIncidents,
          loading: true,
          error,
        },
      };
    case 'GET_EMERGENCY_INCIDENTS_SUCCESS':
      return {
        ...state,
        emergencyIncidents: {
          ...state.emergencyIncidents,
          loading: false,
          data: data,
        },
      };
    case 'GET_EMERGENCY_INCIDENTS_FAIL':
      return {
        ...state,
        emergencyIncidents: {
          ...state.emergencyIncidents,
          loading: false,
          error: error,
        },
      };
    case 'GET_NON_EMERGENCY_INCIDENTS':
      return {
        ...state,
        nonEmergencyIncidents: {
          ...state.nonEmergencyIncidents,
          loading: true,
          error,
        },
      };
    case 'GET_NON_EMERGENCY_INCIDENTS_SUCCESS':
      return {
        ...state,
        nonEmergencyIncidents: {
          ...state.nonEmergencyIncidents,
          loading: false,
          data: data,
        },
      };
    case 'GET_NON_EMERGENCY_INCIDENTS_FAIL':
      return {
        ...state,
        nonEmergencyIncidents: {
          ...state.nonEmergencyIncidents,
          loading: false,
          error: error,
        },
      };
    case 'GET_INCIDENTS_AT_LOCATION_TYPES':
      return {
        ...state,
        incidentsAtLocationTypes: {
          ...state.incidentsAtLocationTypes,
          loading: true,
          error,
        },
      };
    case 'GET_INCIDENTS_AT_LOCATION_TYPES_SUCCESS':
      const names = ['Client', 'Customer', 'Roadway', 'Unspecified'];
      data = data?.map((item, index) => ({
        ...item,
        value: item?.count,
        name: names[index],
      }));
      data.push({
        name: 'Unspecified',
        value: 0,
        count: 0,
      });
      return {
        ...state,
        incidentsAtLocationTypes: {
          ...state.incidentsAtLocationTypes,
          loading: false,
          data: data,
        },
      };
    case 'GET_INCIDENTS_AT_LOCATION_TYPES_FAIL':
      return {
        ...state,
        incidentsAtLocationTypes: {
          ...state.incidentsAtLocationTypes,
          loading: false,
          error,
        },
      };
    case 'GET_EVACUATION_INCIDENTS_NUMBER':
      return {
        ...state,
        evacuationIncidentsNumber: {
          ...state.evacuationIncidentsNumber,
          loading: true,
          error,
        },
      };
    case 'GET_EVACUATION_INCIDENTS_NUMBER_SUCCESS':
      return {
        ...state,
        evacuationIncidentsNumber: {
          ...state.evacuationIncidentsNumber,
          loading: false,
          data: data,
        },
      };
    case 'GET_EVACUATION_INCIDENTS_NUMBER_FAIL':
      return {
        ...state,
        evacuationIncidentsNumber: {
          ...state.evacuationIncidentsNumber,
          loading: false,
          error,
        },
      };
    case 'GET_DISPOSAL_INCIDENTS_NUMBER':
      return {
        ...state,
        disposalIncidentsNumber: {
          ...state.disposalIncidentsNumber,
          loading: true,
          error,
        },
      };
    case 'GET_DISPOSAL_INCIDENTS_NUMBER_SUCCESS':
      return {
        ...state,
        disposalIncidentsNumber: {
          ...state.disposalIncidentsNumber,
          loading: false,
          data: data,
        },
      };
    case 'GET_DISPOSAL_INCIDENTS_NUMBER_FAIL':
      return {
        ...state,
        disposalIncidentsNumber: {
          ...state.disposalIncidentsNumber,
          loading: false,
          error,
        },
      };
    case 'GET_AVG_TIME_FOR_EXCAVATION':
      return {
        ...state,
        avgTimeForExcavation: {
          ...state.avgTimeForExcavation,
          loading: true,
          error,
        },
      };
    case 'GET_AVG_TIME_FOR_EXCAVATION_SUCCESS':
      return {
        ...state,
        avgTimeForExcavation: {
          ...state.avgTimeForExcavation,
          loading: false,
          data: data,
        },
      };
    case 'GET_AVG_TIME_FOR_EXCAVATION_FAIL':
      return {
        ...state,
        avgTimeForExcavation: {
          ...state.avgTimeForExcavation,
          loading: false,
          error,
        },
      };
    case 'GET_5800_FORMS_DETAILS':
      return {
        ...state,
        formDetails5800: {
          ...state.formDetails5800,
          loading: true,
          error,
        },
      };
    case 'GET_5800_FORMS_DETAILS_SUCCESS':
      const types = ['total', 'completed', 'range', 'opened'];
      let formattedData = {};
      data?.forEach((item, index) => {
        formattedData[types[index]] = { ...item };
      });
      return {
        ...state,
        formDetails5800: {
          ...state.formDetails5800,
          loading: false,
          data: formattedData,
        },
      };
    case 'GET_5800_FORMS_DETAILS_FAIL':
      return {
        ...state,
        formDetails5800: {
          ...state.formDetails5800,
          loading: false,
          error,
        },
      };

    case 'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED':
      return {
        ...state,
        fetchSpillsStatusesCountRoleBased: {
          ...state.fetchSpillsStatusesCountRoleBased,
          loading: true,
          error,
        },
      };
    case 'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED_SUCCESS':
      return {
        ...state,
        fetchSpillsStatusesCountRoleBased: {
          ...state.fetchSpillsStatusesCountRoleBased,
          loading: false,
          data: data,
        },
      };
    case 'GET_FETCH_SPILLS_STATUSES_COUNT_ROLE_BASED_FAIL':
      return {
        ...state,
        fetchSpillsStatusesCountRoleBased: {
          ...state.fetchSpillsStatusesCountRoleBased,
          loading: false,
          error,
        },
      };
    case 'GET_TURNED_US_DOWN_FOR_CONTRACTOR':
      return {
        ...state,
        turnedUsDownForContractor: {
          ...state.turnedUsDownForContractor,
          loading: true,
          error,
        },
      };
    case 'GET_TURNED_US_DOWN_FOR_CONTRACTOR_SUCCESS':
      return {
        ...state,
        turnedUsDownForContractor: {
          ...state.turnedUsDownForContractor,
          loading: false,
          data: data,
        },
      };
    case 'GET_TURNED_US_DOWN_FOR_CONTRACTOR_FAIL':
      return {
        ...state,
        turnedUsDownForContractor: {
          ...state.turnedUsDownForContractor,
          loading: false,
          error,
        },
      };
    case 'GET_HAZ_INCIDENTS_CLIENT':
      return {
        ...state,
        hazIncidentsClient: {
          ...state.hazIncidentsClient,
          loading: true,
          error,
        },
      };
    case 'GET_HAZ_INCIDENTS_CLIENT_SUCCESS':
      return {
        ...state,
        hazIncidentsClient: {
          ...state.hazIncidentsClient,
          loading: false,
          data: data,
        },
      };
    case 'GET_HAZ_INCIDENTS_CLIENT_FAIL':
      return {
        ...state,
        hazIncidentsClient: {
          ...state.hazIncidentsClient,
          loading: false,
          error,
        },
      };

    case 'GET_INCIDENTS_REPORTS_CLIENT':
      return {
        ...state,
        incidentsReportedClient: {
          ...state.incidentsReportedClient,
          loading: true,
          error,
        },
      };
    case 'GET_INCIDENTS_REPORTS_CLIENT_SUCCESS':
      return {
        ...state,
        incidentsReportedClient: {
          ...state.incidentsReportedClient,
          loading: false,
          data: data,
        },
      };
    case 'GET_INCIDENTS_REPORTS_CLIENT_FAIL':
      return {
        ...state,
        incidentsReportedClient: {
          ...state.incidentsReportedClient,
          loading: false,
          error,
        },
      };

    case 'GET_VEHICLE_INCIDENTS':
      return {
        ...state,
        vehicleAccidents: {
          ...state.vehicleAccidents,
          loading: true,
          error,
        },
      };
    case 'GET_VEHICLE_INCIDENTS_SUCCESS':
      return {
        ...state,
        vehicleAccidents: {
          ...state.vehicleAccidents,
          loading: false,
          data: data,
        },
      };
    case 'GET_VEHICLE_INCIDENTS_FAIL':
      return {
        ...state,
        vehicleAccidents: {
          ...state.vehicleAccidents,
          loading: false,
          error,
        },
      };
    case 'GET_5800_COMPLETED_CLIENT':
      return {
        ...state,
        completed5800Client: {
          ...state.completed5800Client,
          loading: true,
          error,
        },
      };
    case 'GET_5800_COMPLETED_CLIENT_SUCCESS':
      return {
        ...state,
        completed5800Client: {
          ...state.completed5800Client,
          loading: false,
          data: data,
        },
      };
    case 'GET_5800_COMPLETED_CLIENT_FAIL':
      return {
        ...state,
        completed5800Client: {
          ...state.completed5800Client,
          loading: false,
          error,
        },
      };
    case 'GET_AVERAGE_INVOICE_CLIENT':
      return {
        ...state,
        averageInvoicesClient: {
          ...state.averageInvoicesClient,
          loading: true,
          error,
        },
      };
    case 'GET_AVERAGE_INVOICE_CLIENT_SUCCESS':
      return {
        ...state,
        averageInvoicesClient: {
          ...state.averageInvoicesClient,
          loading: false,
          data: data,
        },
      };
    case 'GET_AVERAGE_INVOICE_CLIENT_FAIL':
      return {
        ...state,
        averageInvoicesClient: {
          ...state.averageInvoicesClient,
          loading: false,
          error,
        },
      };
    case 'GET_SAVINGS_CLIENT':
      return {
        ...state,
        savingsClient: {
          ...state.savingsClient,
          loading: true,
          error,
        },
      };
    case 'GET_SAVINGS_CLIENT_SUCCESS':
      return {
        ...state,
        savingsClient: {
          ...state.savingsClient,
          loading: false,
          data: data,
        },
      };
    case 'GET_SAVINGS_CLIENT_FAIL':
      return {
        ...state,
        savingsClient: {
          ...state.savingsClient,
          loading: false,
          error,
        },
      };
    case 'GET_INCIDENTS_COMPLETION_TYPE_STATUSES':
      return {
        ...state,
        incidentCompletionTypeStatuses: {
          ...state.incidentCompletionTypeStatuses,
          loading: true,
          error,
        },
      };
    case 'GET_INCIDENTS_COMPLETION_TYPE_STATUSES_SUCCESS':
      const arrayLabels = [
        'Avg Time(hrs) To Schedule Excavations',
        'Avg Time(hrs) To Complete Excavations',
        'Avg Time(hrs) To Schedule Disposal',
        'Avg Time(hrs) To Schedule Haz Disposal',
        '     Avg Time(hrs) To Schedule Non Haz Disposal',
        'Avg Time(hrs) To Complete Disposal',
        'Avg Time(hrs) To Complete Haz Disposal',
        '     Avg Time(hrs) To Complete Non Haz Disposal',
      ];
      let formattedDataStatuses = {};
      formattedDataStatuses = data?.map((item, index) => ({
        count: Math.round(item?.data?.avg),
        status: arrayLabels[index],
      }));
      return {
        ...state,
        incidentCompletionTypeStatuses: {
          ...state.incidentCompletionTypeStatuses,
          loading: false,
          data: formattedDataStatuses,
        },
      };
    case 'GET_INCIDENTS_COMPLETION_TYPE_STATUSES_FAIL':
      return {
        ...state,
        incidentCompletionTypeStatuses: {
          ...state.incidentCompletionTypeStatuses,
          loading: false,
          error,
        },
      };
    case 'GET_AVERAGE_ETA':
      return {
        ...state,
        averageEta: {
          ...state.averageEta,
          loading: true,
          error,
        },
      };
    case 'GET_AVERAGE_ETA_SUCCESS':
      return {
        ...state,
        averageEta: {
          ...state.averageEta,
          loading: false,
          data: data,
        },
      };
    case 'GET_AVERAGE_ETA_FAIL':
      return {
        ...state,
        averageEta: {
          ...state.averageEta,
          loading: false,
          error,
        },
      };
    case 'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR':
      return {
        ...state,
        averageIncidentSameContractor: {
          ...state.averageIncidentSameContractor,
          loading: true,
          error,
        },
      };
    case 'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR_SUCCESS':
      return {
        ...state,
        averageIncidentSameContractor: {
          ...state.averageIncidentSameContractor,
          loading: false,
          data: data,
        },
      };
    case 'GET_AVERAGE_INCIDENTS_SAME_CONTRACTOR_FAIL':
      return {
        ...state,
        averageIncidentSameContractor: {
          ...state.averageIncidentSameContractor,
          loading: false,
          error,
        },
      };
    case 'GET_ALL_CONTRACTOR_TURN_US_DOWN':
      return {
        ...state,
        allContractorTurnUsDown: {
          ...state.allContractorTurnUsDown,
          loading: true,
          error,
        },
      };
    case 'GET_ALL_CONTRACTOR_TURN_US_DOWN_SUCCESS':
      return {
        ...state,
        allContractorTurnUsDown: {
          ...state.allContractorTurnUsDown,
          loading: false,
          data: data,
        },
      };
    case 'GET_ALL_CONTRACTOR_TURN_US_DOWN_FAIL':
      return {
        ...state,
        allContractorTurnUsDown: {
          ...state.allContractorTurnUsDown,
          loading: false,
          error,
        },
      };
    case 'GET_RESPONSE_PERCENTAGE_CONTRACTORS':
      return {
        ...state,
        responsePercentageContractors: {
          ...state.responsePercentageContractors,
          loading: true,
          error,
        },
      };
    case 'GET_RESPONSE_PERCENTAGE_CONTRACTORS_SUCCESS':
      return {
        ...state,
        responsePercentageContractors: {
          ...state.responsePercentageContractors,
          loading: false,
          data: data,
        },
      };
    case 'GET_RESPONSE_PERCENTAGE_CONTRACTORS_FAIL':
      return {
        ...state,
        responsePercentageContractors: {
          ...state.responsePercentageContractors,
          loading: false,
          error,
        },
      };
    case 'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN':
      return {
        ...state,
        particularContractorTurnedUsDown: {
          ...state.particularContractorTurnedUsDown,
          loading: true,
          error,
        },
      };
    case 'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN_SUCCESS':
      return {
        ...state,
        particularContractorTurnedUsDown: {
          ...state.particularContractorTurnedUsDown,
          loading: false,
          data: data,
        },
      };
    case 'GET_PARTICULAR_CONTRACTOR_TURNED_US_DOWN_FAIL':
      return {
        ...state,
        particularContractorTurnedUsDown: {
          ...state.particularContractorTurnedUsDown,
          loading: false,
          error,
        },
      };
    case 'GET_CURRENTLY_OPEN_SPILLS':
      return {
        ...state,
        currentlyOpenSpills: {
          ...state.currentlyOpenSpills,
          loading: true,
        },
      };
    case 'GET_CURRENTLY_OPEN_SPILLS_SUCCESS':
      return {
        ...state,
        currentlyOpenSpills: {
          ...state.currentlyOpenSpills,
          loading: false,
          data: data,
        },
      };
    case 'GET_CURRENTLY_OPEN_SPILLS_FAIL':
      return {
        ...state,
        currentlyOpenSpills: {
          ...state.currentlyOpenSpills,
          loading: false,
          error,
        },
      };
    case 'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR':
      return {
        ...state,
        currentOpenedSpillsInCurrentYear: {
          ...state.currentOpenedSpillsInCurrentYear,
          loading: true,
        },
      };
    case 'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR_SUCCESS':
      return {
        ...state,
        currentOpenedSpillsInCurrentYear: {
          ...state.currentOpenedSpillsInCurrentYear,
          loading: false,
          data: data,
        },
      };
    case 'GET_CURRENTLY_OPENED_SPILLS_IN_CURRENT_YEAR_FAIL':
      return {
        ...state,
        currentOpenedSpillsInCurrentYear: {
          ...state.currentOpenedSpillsInCurrentYear,
          loading: false,
          error,
        },
      };
    case 'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR':
      return {
        ...state,
        currentOpenedSpillsInPreviousYear: {
          ...state.currentOpenedSpillsInPreviousYear,
          loading: true,
        },
      };
    case 'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR_SUCCESS':
      return {
        ...state,
        currentOpenedSpillsInPreviousYear: {
          ...state.currentOpenedSpillsInPreviousYear,
          loading: false,
          data: data,
        },
      };
    case 'GET_CURRENTLY_OPENED_SPILLS_IN_PREVIOUS_YEAR_FAIL':
      return {
        ...state,
        currentOpenedSpillsInPreviousYear: {
          ...state.currentOpenedSpillsInPreviousYear,
          loading: false,
          error,
        },
      };
    case 'GET_SPILLS_CLOSED_YTD':
      return {
        ...state,
        closedSpillsYTD: {
          ...state.closedSpillsYTD,
          loading: true,
        },
      };
    case 'GET_SPILLS_CLOSED_YTD_SUCCESS':
      return {
        ...state,
        closedSpillsYTD: {
          ...state.closedSpillsYTD,
          loading: false,
          data: data,
        },
      };
    case 'GET_SPILLS_CLOSED_YTD_FAIL':
      return {
        ...state,
        closedSpillsYTD: {
          ...state.closedSpillsYTD,
          loading: false,
          error,
        },
      };
    case 'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR':
      return {
        ...state,
        currentlyClosedSpillsCurrentYear: {
          ...state.currentlyClosedSpillsCurrentYear,
          loading: true,
        },
      };
    case 'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR_SUCCESS':
      return {
        ...state,
        currentlyClosedSpillsCurrentYear: {
          ...state.currentlyClosedSpillsCurrentYear,
          loading: false,
          data: data,
        },
      };
    case 'GET_CURRENTLY_CLOSED_SPILLS_CURRENT_YEAR_FAIL':
      return {
        ...state,
        currentlyClosedSpillsCurrentYear: {
          ...state.currentlyClosedSpillsCurrentYear,
          loading: false,
          error,
        },
      };
    case 'GET_AVG_DAYS_TO_CLOSE_SPILL':
      return {
        ...state,
        avgDaysToCloseSpill: {
          ...state.avgDaysToCloseSpill,
          loading: true,
        },
      };
    case 'GET_AVG_DAYS_TO_CLOSE_SPILL_SUCCESS':
      return {
        ...state,
        avgDaysToCloseSpill: {
          ...state.avgDaysToCloseSpill,
          loading: false,
          data: data,
        },
      };
    case 'GET_AVG_DAYS_TO_CLOSE_SPILL_FAIL':
      return {
        ...state,
        avgDaysToCloseSpill: {
          ...state.avgDaysToCloseSpill,
          loading: false,
          error,
        },
      };
    case 'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH':
      return {
        ...state,
        weeklySpillsForAMonth: {
          ...state.weeklySpillsForAMonth,
          loading: true,
        },
      };
    case 'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH_SUCCESS':
      return {
        ...state,
        weeklySpillsForAMonth: {
          ...state.weeklySpillsForAMonth,
          loading: false,
          data: {
            values: data.map((item) => {
              return {
                week: item?.Week,
                count: item?.count,
                value: item?.count,
              };
            }),
          },
        },
      };
    case 'GET_WEEKLY_SPILL_COUNT_FOR_A_MONTH_FAIL':
      return {
        ...state,
        weeklySpillsForAMonth: {
          ...state.weeklySpillsForAMonth,
          loading: false,
          error,
        },
      };

    case 'GET_AFTER_HOURS_SPILLS':
      return {
        ...state,
        afterHoursSpills: {
          ...state.afterHoursSpills,
          loading: true,
        },
      };

    case 'GET_AFTER_HOURS_SPILLS_SUCCESS':
      return {
        ...state,
        afterHoursSpills: {
          ...state.afterHoursSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_AFTER_HOURS_SPILLS_FAIL':
      return {
        ...state,
        afterHoursSpills: {
          ...state.afterHoursSpills,
          error,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_SPILLS':
      return {
        ...state,
        csCurrentWeekSpills: {
          ...state.csCurrentWeekSpills,
          loading: true,
        },
      };

    case 'GET_CS_CURRENT_WEEK_SPILLS_SUCCESS':
      return {
        ...state,
        csCurrentWeekSpills: {
          ...state.csCurrentWeekSpills,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_SPILLS_FAIL':
      return {
        ...state,
        csCurrentWeekSpills: {
          ...state.csCurrentWeekSpills,
          error,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_BUSINESS_HOURS':
      return {
        ...state,
        csCurrentWeekBusinessHours: {
          ...state.csCurrentWeekBusinessHours,
          loading: true,
        },
      };

    case 'GET_CS_CURRENT_WEEK_BUSINESS_HOURS_SUCCESS':
      return {
        ...state,
        csCurrentWeekBusinessHours: {
          ...state.csCurrentWeekBusinessHours,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_BUSINESS_HOURS_FAIL':
      return {
        ...state,
        csCurrentWeekBusinessHours: {
          ...state.csCurrentWeekBusinessHours,
          error,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_AFTER_HOURS':
      return {
        ...state,
        csCurrentWeekAfterHours: {
          ...state.csCurrentWeekAfterHours,
          loading: true,
        },
      };

    case 'GET_CS_CURRENT_WEEK_AFTER_HOURS_SUCCESS':
      return {
        ...state,
        csCurrentWeekAfterHours: {
          ...state.csCurrentWeekAfterHours,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_AFTER_HOURS_FAIL':
      return {
        ...state,
        csCurrentWeekAfterHours: {
          ...state.csCurrentWeekAfterHours,
          error,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE':
      return {
        ...state,
        csCurrentWeekBroughtIntoOffice: {
          ...state.csCurrentWeekBroughtIntoOffice,
          loading: true,
        },
      };

    case 'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE_SUCCESS':
      return {
        ...state,
        csCurrentWeekBroughtIntoOffice: {
          ...state.csCurrentWeekBroughtIntoOffice,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_BROUGHT_INTO_OFFICE_FAIL':
      return {
        ...state,
        csCurrentWeekBroughtIntoOffice: {
          ...state.csCurrentWeekBroughtIntoOffice,
          error,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_EXCAVATION':
      return {
        ...state,
        csCurrentWeekExcavation: {
          ...state.csCurrentWeekExcavation,
          loading: true,
        },
      };

    case 'GET_CS_CURRENT_WEEK_EXCAVATION_SUCCESS':
      return {
        ...state,
        csCurrentWeekExcavation: {
          ...state.csCurrentWeekExcavation,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_EXCAVATION_FAIL':
      return {
        ...state,
        csCurrentWeekExcavation: {
          ...state.csCurrentWeekExcavation,
          error,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME':
      return {
        ...state,
        csCurrentWeekIncidentsTakenHome: {
          ...state.csCurrentWeekIncidentsTakenHome,
          loading: true,
        },
      };

    case 'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME_SUCCESS':
      return {
        ...state,
        csCurrentWeekIncidentsTakenHome: {
          ...state.csCurrentWeekIncidentsTakenHome,
          data: data,
          loading: false,
        },
      };

    case 'GET_CS_CURRENT_WEEK_INCIDENTS_TAKEN_HOME_FAIL':
      return {
        ...state,
        csCurrentWeekIncidentsTakenHome: {
          ...state.csCurrentWeekIncidentsTakenHome,
          error,
          loading: false,
        },
      };

    case 'GET_TRACKING_FILES_INCIDENTS':
      return {
        ...state,
        trackingFileIncidents: {
          ...state.trackingFileIncidents,
          loading: true,
        },
      };

    case 'GET_TRACKING_FILES_INCIDENTS_SUCCESS':
      return {
        ...state,
        trackingFileIncidents: {
          ...state.trackingFileIncidents,
          data: data,
          loading: false,
        },
      };

    case 'GET_TRACKING_FILES_INCIDENTS_FAIL':
      return {
        ...state,
        trackingFileIncidents: {
          ...state.trackingFileIncidents,
          error,
          loading: false,
        },
      };

    case 'GET_SPILLS_BROUGHT_INTO_OFFICE':
      return {
        ...state,
        spillsBroughtIntoOffice: {
          ...state.spillsBroughtIntoOffice,
          loading: true,
        },
      };

    case 'GET_SPILLS_BROUGHT_INTO_OFFICE_SUCCESS':
      return {
        ...state,
        spillsBroughtIntoOffice: {
          ...state.spillsBroughtIntoOffice,
          data: data,
          loading: false,
        },
      };

    case 'GET_SPILLS_BROUGHT_INTO_OFFICE_FAIL':
      return {
        ...state,
        spillsBroughtIntoOffice: {
          ...state.spillsBroughtIntoOffice,
          error,
          loading: false,
        },
      };

    case 'GET_DIGS_SCHEDULED_TODAY':
      return {
        ...state,
        digsScheduledToday: {
          ...state.digsScheduledToday,
          loading: true,
        },
      };

    case 'GET_DIGS_SCHEDULED_TODAY_SUCCESS':
      return {
        ...state,
        digsScheduledToday: {
          ...state.digsScheduledToday,
          data: data,
          loading: false,
        },
      };

    case 'GET_DIGS_SCHEDULED_TODAY_FAIL':
      return {
        ...state,
        digsScheduledToday: {
          ...state.digsScheduledToday,
          error,
          loading: false,
        },
      };

    case 'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS':
      return {
        ...state,
        nonTrackingWithWorkInProgress: {
          ...state.nonTrackingWithWorkInProgress,
          loading: true,
        },
      };

    case 'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS_SUCCESS':
      return {
        ...state,
        nonTrackingWithWorkInProgress: {
          ...state.nonTrackingWithWorkInProgress,
          data: data,
          loading: false,
        },
      };

    case 'GET_NON_TRACKING_WITH_WORK_IN_PROGRESS_FAIL':
      return {
        ...state,
        nonTrackingWithWorkInProgress: {
          ...state.nonTrackingWithWorkInProgress,
          error,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default analyticsReducer;
