import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import EmailIcon from "@material-ui/icons/Email";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import * as userActions from "../../../../actionCreators/User";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function EditEmail({ user, userInformation, editUserEmail, closeModal }) {
  const classes = useStyles();

  const { userEmail, userSecondaryEmail } = userInformation;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      email: e.target["primary-email"]?.value?.trim(),
      secondary_email: e.target["secondary-email"].value?.trim(),
    };

    await editUserEmail(obj);

    await closeModal();
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        <div style={{ display: "flex" }}>
          <Avatar className={classes.avatar}>
            <AlternateEmailIcon />
          </Avatar>
          <span style={{ paddingLeft: "20px" }}>My Email</span>
        </div>
      </Typography>
      <Typography variant="subtitle2">
        NOTE! Your email address is your username -- this is what you use to log
        in to the system.
      </Typography>

      <div style={{ paddingTop: 30 }} />

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="primary-email"
              name="primary-email"
              label="Email"
              fullWidth
              defaultValue={userEmail}
              autoComplete="primary-email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="secondary-email"
              name="secondary-email"
              label="Email 2"
              fullWidth
              defaultValue={userSecondaryEmail}
              autoComplete="secondary-email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              Update
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  editUserEmail: bindActionCreators(userActions.editUserEmail, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditEmail)
);
