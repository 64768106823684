import React, { useEffect,useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import "./styles/App.css";
import * as spillActions from "./actionCreators/Spill";
import * as userActions from './actionCreators/User';
import HeaderComponent from './Components/HeaderComponent';
import FooterComponent from './Components/FooterComponent';
import AreaReminderPopup from "./views/Dashboard/components/content/Spills/AreaReminderPopup";
import moment from "moment";

function App({ children, getCurrentUser, user, getReminder, spill }) {
  const [updatedRemindersArray, setUpdatedRemindersArray] = useState([]);

  React.useEffect(() => {
    getCurrentUser();
  }, []);


  const checkReminderPop = (reminders) => {
    if (reminders?.length) {
      const updatedReminders = reminders.map((item) => {
        const currentTimestamp = moment().unix()
        const updateItem = item;
        if (item?.formattedNoteTime <= currentTimestamp) {
          updateItem.open = true;
        }
        return updateItem;
      });
      setUpdatedRemindersArray(updatedReminders);
    }
  }

  useEffect(() => {
    setInterval(() => {
      const getNoteData = localStorage.getItem('note_data');
      if (getNoteData) {
        checkReminderPop(JSON.parse(getNoteData));
      }
    }, 15000);
  }, [])

  useEffect(() => {
    if (spill?.newNoteCreation?.success || user?.type === "LOGIN_SUCCESS" || user?.type === "GET_CURRENT_USER_SUCCESS") {
      getReminder();
    }
  }, [spill?.newNoteCreation?.success, user?.type])

  useEffect(() => {
    if (spill?.type === "GET_REMINDER_SUCCESS") {
      const getNoteData = localStorage.getItem('note_data');
      const getParsedNoteData = getNoteData ? JSON.parse(getNoteData) : [];
      const updatedReminders = spill?.reminder?.map((item) => {
        const noteData = getParsedNoteData?.find((note) => note.note_id === item.note_id && note.job_no === item.job_no);
        if (noteData) {
          return {
            ...item,
            formattedNoteTime: noteData?.formattedNoteTime,
            open: noteData?.open,
          };
        }
        return {
          ...item,
          formattedNoteTime: moment().add(15, 'minutes').unix(),
          open: false,
        };
      });
      updatedReminders && localStorage.setItem('note_data', JSON.stringify(updatedReminders));
    }
    if (spill?.type === 'EDIT_NOTES_SUCCESS') {
      getReminder();
    }
  }, [spill?.type, spill?.reminder])

  const handleClose = (index) => {
    const getNoteData = localStorage.getItem('note_data')
    const getParsedNoteData = getNoteData ? JSON.parse(getNoteData) : [];
    if (getParsedNoteData && getParsedNoteData.length) {
      getParsedNoteData[index].open = false;
      getParsedNoteData[index].formattedNoteTime = moment().add(15, 'minutes').unix();
      localStorage.setItem('note_data', JSON.stringify(getParsedNoteData));
      checkReminderPop(getParsedNoteData);
    }
  };


  return (
    <div className="App">
      {updatedRemindersArray.map((item, index) => (
        <AreaReminderPopup
          key={index}
          reminder={item}
          open={item.open}
          handleClose={() => handleClose(index)}
        />
      ))}
      <HeaderComponent />
      <main style={{ backgroundColor: '#fff' }}>{children}</main>
      <FooterComponent />
    </div>
  );
}

const mapStateToProps = ({ user, spill }) => ({ user, spill });

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: bindActionCreators(userActions.getCurrentUser, dispatch),
  getReminder: bindActionCreators(spillActions.getReminder, dispatch),
});

App.propTypes = {
  user: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getReminder: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));