import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    overflow: "scroll",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    borderRadius: "10px",
  },
  headerCard: {
    backgroundColor: "#f7f7f7",
  },
  menuButtons: {
    fontSize: "16px",
    padding: "5px 5px 0px 5px",
  },
  listAllButton: {
    fontSize: "16px",
    padding: "5px 5px 7px 5px",
  },
  headingCard: {
    marginBottom: "25px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    borderRadius: "10px",
  },
  textField: {
    minWidth: 500,
    margin: 10,
  },
}));

const RejectDialog = ({ showReject, onDialogAccept, onDialogCancel }) => {
  const classes = useStyles();
  const [message, setMessage] = useState("");

  return (
    <Dialog open={showReject}>
      <DialogTitle>Reason For Rejection</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Reason For Rejection"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          className={classes.textField}
          multiline
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onDialogCancel()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onDialogAccept(message)}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectDialog;
