import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteOutlineIcon from "@material-ui/icons/Delete";
import {
  states,
  countries,
  orderAlphabatically,
  orderAlphabaticallyByKey,
  COUNTRIES,
} from "../../../../../utils";
import FileUpload from "./FileUpload";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: "left",
    paddingRight: "10px",
    paddingLeft: "10px",
    marginTop: "10px",
  },
  customLable: {
    color: "#444444",
    fontSize: "15px",
    marginTop: "16px",
    marginBottom: "0px",
    display: "inline-block",
  },
  header: {
    color: "green",
    textAlign: "left",
  },
}));

const Facilities = ({
  facilitiesFormData,
  onChange,
  onEditChange,
  editMode,
  onUpdateButtonClick,
  onDeleteFacilityClick,
  file,
  setFile,
  open,
  setOpen,
  isFacilityLoading,
  getAllfacilities,
  totalFacility,
  setTotalFacility,
  downloadFacilities
}) => {
  const classes = useStyles();
  const [facilitiesCount, setFacilitiesCount] = useState(0);
  const [newFacilitiesIds, setNewFacilitesIds] = useState([]);
  const [hiddenAccordions, setHiddenAccordions] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const [getIndex, setGetIndex] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const customScrollbarRef = useRef(null);

  const handleAddFacility = () => {
    const customScrollbarDiv = customScrollbarRef.current;
    if (customScrollbarDiv) {
      customScrollbarDiv.scrollTop = customScrollbarDiv.scrollHeight;
    }
    setFacilitiesCount(facilitiesCount + 1);
    onChange((prevFacilities) => [
      ...prevFacilities,
      { id: facilitiesCount + 1, country: COUNTRIES?.USA, },
    ]);
    setNewFacilitesIds([...newFacilitiesIds, facilitiesCount + 1]);
  };

  const handleChange = (facilityIndex, field, value, id = null) => {
    onChange((prevFormData) => {
      const updatedFacilities = [...prevFormData];
      updatedFacilities[facilityIndex] = {
        ...updatedFacilities[facilityIndex],
        [field]: value,
      };
      return updatedFacilities;
    });
    if (editMode && newFacilitiesIds.includes(id)) {
      onEditChange((prevFormData) => {
        const updatedFacilities = [...prevFormData];
        updatedFacilities[facilityIndex] = {
          ...updatedFacilities[facilityIndex],
          [field]: value,
        };
        return updatedFacilities;
      });
    }
  };

  const [selectedIndexToDelete, setSelectedIndexToDelete] = React.useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      org_id: null,
      facilityId: null,
      index: null,
    }
  );
  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleRemoveaccordion = (index, event) => {
    event.stopPropagation();

    setHiddenAccordions((prevHiddenAccordions) => [
      ...prevHiddenAccordions,
      index,
    ]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const editFacility = (index) => {
    setGetIndex(index);
  }

  const showField = (index) => {
    if (getIndex === index || (!facilitiesFormData[index]?.id) || (facilitiesFormData[index]?.id && typeof facilitiesFormData[index]?.id === 'number')) {
      return true;
    }
    return false;
  }

  const filteredStates = (selectedCountry) => {
    if (selectedCountry === COUNTRIES?.USA) {
      return states.filter((state) => state.country === "USA");
    } else if (selectedCountry === COUNTRIES?.CANADA) {
      return states.filter((state) => state.country === "CAN");
    } else {
      return [];
    }
  };


  return (
    <>
      <Grid container spacing={2} className={classes.mainContainer}>
        <FileUpload file={file} setFile={setFile} editMode={editMode} getAllfacilities={getAllfacilities}  totalFacility ={totalFacility} setTotalFacility={setTotalFacility} downloadFacilities={downloadFacilities}></FileUpload>
        {isFacilityLoading ? (
          <div style={{ textAlign: "center", width: "100%", margin: "20px 0" }}>
            Loading...
          </div>
        ) : (
          <>
            <Grid
              item
              md={12}
              style={{
                maxHeight: "700px",
                overflowY: "auto",
                margin: "20px 0",
              }}
              className="CustomScrollbar"
              ref={customScrollbarRef}
            >
              {facilitiesFormData.map((facility, index) => {
                const isNewFacility = newFacilitiesIds.includes(facility.id);

                if (hiddenAccordions.includes(index)) {
                  return null;
                }
                return (
                  <>
                    <Accordion
                      key={facility.id}
                      className="custom-accordion"
                      expanded={expanded === index}
                      onChange={handleAccordionChange(index)}
                    >
                      <AccordionSummary
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <SettingsIcon
                              className="facilities-icon"
                              style={{ alignSelf: "center" }}
                            />
                            <Typography style={{ alignSelf: "center" }}>
                              {facilitiesFormData[index]?.name ||
                                "Facility Name"}
                            </Typography>
                          </div>
                          {editMode && (
                            <div
                              onFocus={(event) => event.stopPropagation()}
                              onClick={(event) => {
                                setSelectedIndexToDelete({
                                  org_id: facilitiesFormData[index]?.org_id,
                                  facilityId: facilitiesFormData[index]?.id,
                                  index,
                                });
                                handleClickOpen(event);
                              }}
                              style={{
                                alignSelf: "center",
                                paddingTop: "6px",
                              }}
                            >
                              <DeleteOutlineIcon className="delete-icon" />
                            </div>
                          )}
                          {!editMode && (
                            <div
                              onClick={(event) =>
                                handleRemoveaccordion(index, event)
                              }
                            >
                              <DeleteOutlineIcon className="delete-icon" />
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={expanded === index ? 'show' : 'hidden'}>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            paddingLeft: 30,
                            paddingRight: 30,
                          }}
                        >
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <TextField
                              id="name"
                              name="name"
                              label="Name"
                              fullWidth
                              size="small"
                              defaultValue={facilitiesFormData[index]?.name}
                              value={facilitiesFormData[index]?.name}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "name",
                                  e.target.value,
                                  facility.id
                                )
                              }
                              className={classes.customFormcontrol}
                            />
                            ) : (
                              <div><strong>Name: </strong> {facilitiesFormData[index]?.name}</div>
                            )}
                          </Grid>
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <TextField
                              id="internal_id"
                              name="internal_id"
                              label="Internal ID#"
                              fullWidth
                              size="small"
                              value={facilitiesFormData[index]?.internal_id}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "internal_id",
                                  e.target.value,
                                  facility.id
                                )
                              }
                              className={classes.customFormcontrol}
                            />
                            ) : (
                              <div><strong>Internal ID#: </strong> {facilitiesFormData[index]?.internal_id}</div>
                            )}
                            
                          </Grid>
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <FormControl style={{ width: "100%" }}>
                                <InputLabel id="select-label">
                                  Generator Status
                                </InputLabel>
                                <Select
                                  labelId="select-label"
                                  id="select"
                                  defaultValue={
                                    facilitiesFormData[index]?.generator_status
                                  }
                                  value={
                                    facilitiesFormData[index]?.generator_status
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "generator_status",
                                      e.target.value,
                                      facility.id
                                    )
                                  }
                                >
                                  <MenuItem value="VSQG">VSQG</MenuItem>
                                  <MenuItem value="SQG">SQG</MenuItem>
                                  <MenuItem value="LQG">LQG</MenuItem>
                                </Select>
                              </FormControl>
                            ) : (
                              <div>
                                <strong>Generator Status: </strong> {facilitiesFormData[index]?.generator_status}
                              </div>
                            )}
                          </Grid>

                          <Grid item sm={6}>
                            {showField(index) ? (
                              <TextField
                                id="epa_id"
                                name="epa_id"
                                label="EPA ID#"
                                fullWidth
                                size="small"
                                defaultValue={facilitiesFormData[index]?.epa_id}
                                value={facilitiesFormData[index]?.epa_id}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "epa_id",
                                    e.target.value,
                                    facility.id
                                  )
                                }
                                className={classes.customFormcontrol}
                              />
                            ) : (
                              <div>
                                <strong>EPA ID#: </strong> {facilitiesFormData[index]?.epa_id}
                              </div>
                            )}
                          </Grid>
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <TextField
                                id="phone_number"
                                name="phone_number"
                                label="Phone Number"
                                fullWidth
                                size="small"
                                defaultValue={
                                  facilitiesFormData[index]?.phone_number
                                }
                                value={facilitiesFormData[index]?.phone_number}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "phone_number",
                                    e.target.value,
                                    facility.id
                                  )
                                }
                                className={classes.customFormcontrol}
                              />
                            ) : (
                              <div>
                                <strong>Phone Number: </strong> {facilitiesFormData[index]?.phone_number}
                              </div>
                            )}
                          </Grid>
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <TextField
                                id="address"
                                name="address"
                                label="Address"
                                fullWidth
                                multiline
                                rowsMax={3}
                                size="small"
                                defaultValue={facilitiesFormData[index]?.address}
                                value={facilitiesFormData[index]?.address}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "address",
                                    e.target.value,
                                    facility.id
                                  )
                                }
                                className={classes.customFormcontrol}
                              />
                            ) : (
                              <div>
                                <strong>Address: </strong> {facilitiesFormData[index]?.address}
                              </div>
                            )}
                          </Grid>
                          <Grid item sm={6}>
                          {showField(index) ? (
                            <TextField
                              id="region"
                              name="region"
                              label="Region"
                              fullWidth
                              size="small"
                              defaultValue={facilitiesFormData[index]?.region}
                              value={facilitiesFormData[index]?.region}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "region",
                                  e.target.value,
                                  facility.id
                                )
                              }
                              className={classes.customFormcontrol}
                            />
                            ) : (
                              <div>
                                <strong>Region: </strong> {facilitiesFormData[index]?.region}
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                          {showField(index) ? (
                            <>
                              <InputLabel id="country-select-label">
                                Country
                              </InputLabel>
                              <Select
                                labelId="country-select-label"
                                id="country"
                                name="country"
                                style={{ width: "100%" }}
                                defaultValue={facilitiesFormData[index]?.country}
                                value={facilitiesFormData[index]?.country}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "country",
                                    e.target.value,
                                    facility.id
                                  )
                                }
                              >
                                {orderAlphabatically(countries).map(
                                  (item, index) => {
                                    return (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </>
                            ) : (
                              <div>
                                <strong>Country: </strong> {facilitiesFormData[index]?.country}
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            {showField(index) ? (
                              <>
                                <InputLabel id="state-select-label">
                                  State
                                </InputLabel>
                                <Select
                                  labelId="state-select-label"
                                  id="state"
                                  name="state"
                                  style={{ width: "100%" }}
                                  defaultValue={facilitiesFormData[index]?.state}
                                  value={facilitiesFormData[index]?.state}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "state",
                                      e.target.value,
                                      facility.id
                                    )
                                  }
                                >
                                  <MenuItem value="">
                                    <em>Select State</em>
                                  </MenuItem>
                                  {orderAlphabaticallyByKey(
                                    filteredStates(facilitiesFormData[index]?.country),
                                    "key",
                                    "name"
                                  ).map((item, index) => (
                                    <MenuItem key={index} value={item.key}>
                                      {`${item.name}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                                </>
                              ) : (
                                <div>
                                  <strong>State: </strong> {facilitiesFormData[index]?.state}
                                </div>
                              )}
                            </Grid>
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <TextField
                                id="city"
                                name="city"
                                label="City"
                                fullWidth
                                size="small"
                                defaultValue={facilitiesFormData[index]?.city}
                                value={facilitiesFormData[index]?.city}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "city",
                                    e.target.value,
                                    facility.id
                                  )
                                }
                              />
                              ) : (
                                <div>
                                  <strong>City: </strong> {facilitiesFormData[index]?.city}
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            {showField(index) ? (
                              <TextField
                                id="zip-code"
                                name="zip_code"
                                label="Zip Code"
                                fullWidth
                                size="small"
                                defaultValue={facilitiesFormData[index]?.zip_code}
                                value={facilitiesFormData[index]?.zip_code}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "zip_code",
                                    e.target.value,
                                    facility.id
                                  )
                                }
                              />
                            ) : (
                              <div>
                                <strong>Zip Code: </strong> {facilitiesFormData[index]?.zip_code}
                              </div>
                            )}
                          </Grid>
                          <Grid item sm={6}>
                            {showField(index) ? (
                              <FormControl style={{ width: "100%" }}>
                                <InputLabel id="select-label">Active</InputLabel>
                                <Select
                                  labelId="select-label"
                                  id="select"
                                  defaultValue={
                                    facilitiesFormData[index]?.is_active
                                  }
                                  value={facilitiesFormData[index]?.is_active}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "is_active",
                                      e.target.value
                                    )
                                  }
                                >
                                  <MenuItem value="true">Yes</MenuItem>
                                  <MenuItem value="false">No</MenuItem>
                                </Select>
                              </FormControl>
                              ) : (
                                <div>
                                  <strong>Active: </strong> {facilitiesFormData[index]?.is_active ? 'Yes': 'No'}
                                </div>
                              )}
                          </Grid>
                          {editMode &&
                            facilitiesFormData[index]?.id &&
                            !isNewFacility && (
                              <Grid item xs={12} style={{ textAlign: "right" }}>
                                <Button
                                  className={classes.button}
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    {
                                      if (getIndex === index) {
                                        onUpdateButtonClick(
                                          facilitiesFormData[index]?.id
                                        )
                                      } else {
                                        editFacility(index)
                                      }
                                    }
                                  }
                                >
                                  {getIndex === index ? 'Update' : 'Edit'}
                                </Button>
                              </Grid>
                            )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure want to delete Facility?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setGetIndex(null);
                        onDeleteFacilityClick(
                          selectedIndexToDelete?.facilityId,
                          selectedIndexToDelete?.org_id,
                          selectedIndexToDelete?.index
                        )
                      }}
                      autoFocus
                      variant="contained"
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="primary"
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleAddFacility}
              >
                Add Another Facilities
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default React.memo(Facilities);
