/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { CustomProgressLoader } from '../../../Components';

import * as userActions from '../../../actionCreators/User';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const colorTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[800],
    },
  },
});

const trimWhiteSpaces = (value) => {
  return value?.trim();
};

function SignIn({ user, history, logIn, loading, location }) {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorToShow, setErrorToShow] = React.useState('');

  const { search } = location;

  const urlParams = new URLSearchParams(search);

  let externalUrl = urlParams.get('redirectUrl');
  const redirectAfterAuth = urlParams.get('redirectAfterAuth');

  externalUrl = externalUrl ? `/rfs.htm${externalUrl}` : null;
  const redirectUrl =
    location.state && location.state.redirectUrl
      ? location.state && location.state.redirectUrl
      : externalUrl ?? redirectAfterAuth;

  const handleSubmit = (email, password) => {
    email = trimWhiteSpaces(email);
    password = trimWhiteSpaces(password);
    const obj = {
      email,
      password,
    };

    logIn(obj, history, redirectUrl);
  };
  React.useEffect(() => {
    user.error && setErrorToShow(user.error);
  }, [user.error]);
  return (
    <div className={classes.paper}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={user?.snackBar?.open}
        autoHideDuration={6000}
        aria-describedby='client-snackbar'>
        <Alert severity={user?.snackBar?.saverity || 'error'}>
          {user?.snackBar?.snackbarMessage || ''}
        </Alert>
      </Snackbar>

      {user.loading ? (
        <CustomProgressLoader show={true} />
      ) : (
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(email, password);
          }}>
          <ThemeProvider theme={colorTheme}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='User (Email)'
              name='email'
              autoComplete='email'
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </ThemeProvider>
          <ThemeProvider theme={colorTheme}>
            {<p style={{ color: 'red' }}>{errorToShow}</p>}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}>
              Sign In
            </Button>
          </ThemeProvider>
        </form>
      )}
      <Typography variant='subtitle2' gutterBottom>
        NOTE: if you are looking for the{' '}
        <ThemeProvider theme={colorTheme}>
          <Link href='https://ics.premiumenvironmentalservices.com/login'>
            WASTE login, try here
          </Link>
        </ThemeProvider>
        (it is now called ICS).
      </Typography>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  logIn: bindActionCreators(userActions.logIn, dispatch),
});

SignIn.propTypes = {
  user: PropTypes.object.isRequired,
  logIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectUrl: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
