import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';

import * as contractorActions from '../../../../../actionCreators/Contractor';
import { CustomSnackBar, AddressAccordian } from '../../../../../Components';
import { FormControlLabel, InputAdornment, Switch } from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ClassIRailRoadsAccordion from '../../../../../Components/ERailCertificate/ClassIRailRoadsAccordion/ClassIRailRoadsAccordion';
import {
  additionalAttachmentTypes,
  renameAttachmentDuplicates,
} from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boxTitle: {
    textAlign: 'left',
    marginTop: '10px',
    fontWeight: 800,
    marginBottom: 0,
  },
}));

const NewContractor = ({
  createNewContractor,
  closeAddContractorModal,
  contractor,
}) => {
  const classes = useStyles();
  const [addresses, setAddresses] = useState([]);
  const [reqSubmitted, setReqSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [userIsHazardous, setUserIsHazardous] = useState(false);
  const [userLicenseNumber, setUserLicenseNumber] = useState('');
  const [userLicenseNumberExpiry, setUserLicenseNumberExpiry] = useState(
    new Date()
  );
  const [classIERailCertifications, setClassIERailCertifications] = useState(
    []
  );
  const [certificationClasses, setCertificationClasses] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');


  useEffect(() => {
    if (!contractor.loading && reqSubmitted) {
      if (!contractor.error) {
        closeAddContractorModal();
      } else {
        setErrorMessage(contractor.error);
      }
      setReqSubmitted(false);
    }

    if (
      contractor?.contractorERailClassICertifications?.data?.data?.length > 0
    ) {
      setClassIERailCertifications(
        contractor?.contractorERailClassICertifications?.data?.data
      );
    }
  }, [contractor.loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!e.target.name.value?.trim()) {
      return setErrorMessage("Name cannot be empty")
    }

    const obj = {
      name: e.target.name.value ? e.target.name.value?.trim() : '',
      contact: e.target.contact.value ? e.target.contact.value : '',
      address: e.target.address.value ? e.target.address.value : '',
      city: e.target.city.value ? e.target.city.value : '',
      state: e.target.state.value ? e.target.state.value : '',
      zip_code: e.target['zip-code'].value ? e.target['zip-code'].value : '',
      country: e.target.country.value ? e.target.country.value : '',
      email: e.target.email.value ? e.target.email.value : '',
      phone: e.target.phone.value ? e.target.phone.value : '',
      fax: e.target.fax.value ? e.target.fax.value : '',
      tier_level: e.target.tier_level.value ? e.target.tier_level.value : '',
      haz_waste_hauler: userIsHazardous,
      license_number: userLicenseNumber,
      license_number_expiry: userLicenseNumberExpiry,
      certification_classes: JSON.stringify(certificationClasses),
    };

    createNewContractor({ ...obj, addresses });
    setReqSubmitted(true);
  };

  const handlerToggleHazWasteHauler = () => {
    setUserIsHazardous(!userIsHazardous);
  };

  const handleLicenseNumberExpiryChange = (newValue) => {
    setUserLicenseNumberExpiry(newValue);
  };

  const handleLicenseNumberChange = (e) => {
    setUserLicenseNumber(e?.target?.value);
  };

  const onFileChangeHandler = (event, index, type) => {
    let updatedFilesList = [];
    let tempSelected = [...selectedFiles];

    for (let file of event.target.files) {
      tempSelected[index] = [
        ...tempSelected[index],
        {
          file: file,
          size: file.size,
          type: type,
        },
      ];
    }

    const currentFilesNameToBeUpdated = tempSelected[index];

    let updatedAttachmentList = renameAttachmentDuplicates(
      currentFilesNameToBeUpdated
    );

    currentFilesNameToBeUpdated?.map((fileItem, index) => {
      fileItem = {
        ...fileItem,
        file: new File([fileItem?.file], updatedAttachmentList[index], {
          type: fileItem?.file?.type,
        }),
      };
      updatedFilesList.push(fileItem);
    });
    tempSelected[index] = updatedFilesList;
    setSelectedFiles(tempSelected);
    event.target.value = '';
  };

  const onFileCancelHandler = (index, fileIndex) => {
    let tempFile = [...selectedFiles];
    tempFile[index].splice(fileIndex, 1);
    setSelectedFiles(tempFile);
  };

  const updateAttachmentType = () => {};

  const handleChangeEmail = (event) => {
    setEmail(event.target.value.trim());
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  return (
    <>
      <div style={{ display: contractor.loading ? 'none' : 'unset' }}>
        <CustomSnackBar
          open={!!errorMessage}
          severity='error'
          snackBarMessage={errorMessage}
        />
        <Typography variant='h5' gutterBottom>
          <div style={{ display: 'flex' }}>
            <BuildIcon style={{ color: '#2F7D32', fontSize: 40 }} />
            <span style={{ paddingLeft: '20px' }}>New Contractor</span>
          </div>
        </Typography>

        <div style={{ paddingTop: 30 }} />

        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id='name'
                name='name'
                label='Name'
                fullWidth
                autoComplete='name'
                value={name}
                onChange={handleChangeName}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id='email'
                name='email'
                label='E-mail'
                fullWidth
                autoComplete='email'
                onChange={handleChangeEmail}
                value={email}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                <FormControlLabel
                  control={
                    <Switch
                      labelId='haz-waster-hauler'
                      id='haz-waster-hauler'
                      checked={userIsHazardous}
                      onChange={handlerToggleHazWasteHauler}
                      name='userIsHazardous'
                      color='primary'
                    />
                  }
                  label='Haz Waste Hauler'
                />
              </span>
            </Grid>

            {userIsHazardous && (
              <>
                <Grid item xs={6}>
                  <TextField
                    id='license_number'
                    name='license_number'
                    label='License Number'
                    fullWidth
                    defaultValue={userLicenseNumber}
                    onChange={handleLicenseNumberChange}
                    autoComplete='license_number'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'></InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      style={{
                        margin: '0px 10px',
                        width: '-webkit-fill-available',
                      }}
                      variant='inline'
                      format='MM-DD-YYYY hh:mm a'
                      id='license_number_expiry'
                      label='License Expiration Date'
                      showTodayButton
                      ampmInClock={true}
                      clearable={true}
                      value={userLicenseNumberExpiry}
                      onChange={handleLicenseNumberExpiryChange}
                      KeyboardButtonProps={{
                        aria_label: 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                paragraph
                className={classes.boxTitle}
              >
                E-Rail Certifications
              </Typography>
            </Grid>
            <ClassIRailRoadsAccordion
              certificationClasses={certificationClasses}
              setCertificationClasses={setCertificationClasses}
              classIERailCertifications={classIERailCertifications}
            />

            <br />

            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                paragraph
                className={classes.boxTitle}
              >
                Addresses
              </Typography>
            </Grid>
            <AddressAccordian
              addresses={addresses}
              setAddresses={setAddresses}
              mainAddress={{}}
              isContractor={true}
              hideCount={true}
            />

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                type='submit'
              >
                Create
              </Button>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                onClick={() => closeAddContractorModal()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = ({ contractor }) => ({ contractor });

const mapDispatchToProps = (dispatch) => ({
  createNewContractor: bindActionCreators(
    contractorActions.createNewContractor,
    dispatch
  ),
});

NewContractor.propTypes = {
  contractor: PropTypes.object.isRequired,
  createNewContractor: PropTypes.func.isRequired,
  closeAddContractorModal: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewContractor)
);
