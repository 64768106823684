import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import * as agencyActions from "../../../../../actionCreators/Agency";
import ReportingRequirements from "./ReportingRequirements";
import { ROLES, objectsHaveSameKeysAndValues, KEYS_TO_CHECK } from "../../../../../utils";
import moment from "moment";
import {
  CustomProgressLoader,
  CustomSnackBar,
  MainAddressForm,
} from "../../../../../Components";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    display: "flex",
    overflow: "scroll",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    borderRadius: "10px",
    marginTop: "10px",
  },
  header: {
    color: "green",
    textAlign: "left",
  },
  nameFieldWrapper: {
    marginBottom: "1rem",
  },
}));

const EditExistingAgency = ({
  closeEditModal,
  agencyData,
  editAgency,
  agency,
  userRole,
}) => {
  const classes = useStyles();
  const { register } = useForm();

  const {
    id,
    name,
    address,
    city,
    state,
    zip_code,
    country,
    phone,
    fax,
    contact,
    jurisdiction,
    reportingRequirements,
  } = agencyData;

  const [jurisdictionValue, setJurisdictionValue] =
    React.useState(jurisdiction);
  const [reqSubmitted, setReqSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [reportingRequirementsFormData, setReportingRequirementsFormData] =
    useState({
      petroleum_release_amount:
        reportingRequirements?.petroleum_release_amount || null,
      petroleum_release: reportingRequirements?.petroleum_release || null,
      hazardous_material_amount:
        reportingRequirements?.hazardous_material_amount || null,
      hazardous_material: reportingRequirements?.hazardous_material || null,
      water_impact_amount: reportingRequirements?.water_impact_amount || null,
      water_impact: reportingRequirements?.water_impact || null,
      soil_impact_amount: reportingRequirements?.soil_impact_amount || null,
      soil_impact: reportingRequirements?.soil_impact || null
    });
  const lastRevisionDate = reportingRequirements?.revision_date ? moment(reportingRequirements?.revision_date)?.format("MM-DD-YYYY") : null;
  const [mainAddressFormData, setMainAddressFormData] = useState({
    address: address || "",
    city: city || "",
    state: state || "",
    zip_code: zip_code || "",
    country: country || "",
    phone: phone || "",
    fax: fax || "",
    contact: contact || "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [nameValue, setNameValue] = useState(name);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    moment()?.format("MM-DD-YYYY")
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const hideError = () => {
    setErrorMessage('');
  };
  const validateForm = () => {
    const errors = {};

    if (!nameValue || nameValue.trim() === "") {
      errors.name = "- Agency Name should not be blank.";
    }

    if (!jurisdictionValue) {
      errors.jurisdiction = "- Please select a jurisdiction.";
    }

    const combinedMessage = [errors.name || "", errors.jurisdiction || ""]
      .filter((message) => message !== "")
      .join("\n");

      setErrorMessage(combinedMessage);
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      let dataUpdated = false;
      const modifiedData = {
        ...reportingRequirementsFormData,
      }
      delete modifiedData?.revision_date; 
      const hasValue = Object.values(modifiedData).filter((item) => item);
      if (hasValue.length && (reportingRequirementsFormData?.revision_date || !objectsHaveSameKeysAndValues(reportingRequirements, reportingRequirementsFormData, KEYS_TO_CHECK.AGENCY))) {
        dataUpdated = true;
      }
      const obj = {
        id,
        name: nameValue,
        jurisdiction: jurisdictionValue,
        ...reportingRequirementsFormData,
        ...mainAddressFormData,
        revision_date: dataUpdated ? moment(selectedDate)?.format("YYYY-MM-DD") : null
      };
      editAgency(obj);
      setReqSubmitted(true);
    }
  };
  React.useEffect(() => {
    if (!agency.loading && reqSubmitted) {
      if (!agency.error) {
        closeEditModal(true);
      } else {
        setErrorMessage(agency.error);
      }
      setReqSubmitted(false);
    }
  }, [agency.loading]);

  return (
    <>
      <CustomProgressLoader show={agency.loading} />
      <div style={{ display: agency.loading ? "none" : "unset" }}>
        <CustomSnackBar
          open={!!errorMessage}
          severity="error"
          snackBarMessage={errorMessage}
          className="error_snackbar"
          onClose={hideError}
        />
        <AppBar position="static" color="primary">
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Overview" />
            {userRole == ROLES.SUPER_USER && (
              <Tab label="Reporting Requirements" />
            )}
          </Tabs>
        </AppBar>
        {/* Tab panels */}
        <div className={classes.paper}>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {activeTab === 0 && (
              <>
                <div
                  style={{ paddingTop: 10, paddingLeft: 30, paddingRight: 30 }}
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.header}
                >
                  Edit Agency
                </Typography>
                <Grid
                  className={classes.nameFieldWrapper}
                  container
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      fullWidth
                      defaultValue={nameValue}
                      autoComplete="name"
                      inputRef={register}
                      onChange={(e) => setNameValue(e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{ textAlign: "left", marginBottom: "12px" }}
                >
                  <InputLabel id="jurisdiction-select-label">
                    Jurisdiction
                  </InputLabel>
                  <Select
                    labelId="jurisdiction-select-label"
                    id="jurisdiction-select"
                    value={jurisdictionValue}
                    style={{ width: "300px" }}
                    onChange={(e) => setJurisdictionValue(e.target.value)}
                    required
                  >
                    <MenuItem value={"National"}>National</MenuItem>
                    <MenuItem value={"State"}>State</MenuItem>
                    <MenuItem value={"Local"}>Local</MenuItem>
                  </Select>
                </Grid>
                <MainAddressForm
                  register={register}
                  isOrg={false}
                  showResponsePills={false}
                  mainAddformData={mainAddressFormData}
                  onChange={setMainAddressFormData}
                ></MainAddressForm>
              </>
            )}
            {activeTab === 1 && (
              <ReportingRequirements
                formData={reportingRequirementsFormData}
                onChange={setReportingRequirementsFormData}
                selectedDate={selectedDate}
                onDateChange={handleDateChange}
                editMode={true}
                lastRevisionDate={lastRevisionDate}
              />
            )}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
              >
                Update
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => closeEditModal()}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ agency }) => ({ agency });

const mapDispatchToProps = (dispatch) => ({
  editAgency: bindActionCreators(agencyActions.editAgency, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditExistingAgency)
);
