import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Moment from 'moment';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';

import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';

import * as clientActions from '../actionCreators/Client';

const useStylesDataTable = makeStyles({
  table: {
    minWidth: 500,
  },
  tableHeader: {
    color: 'white',
    fontWeight: 900,
  },
  inlineButton: {
    cursor: 'pointer',
    '&:hover': {
      color: '#6ea16c',
    },
    fontSize: '14px',
    color: '#424242',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    msUserSelect: 'none',
  },
  rejectionText: {
    fontSize: '14px',
    color: '#424242',
    margin: '10px',
  },
  noPaddingCell: {
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
  },
  statusPending: {
    color: 'orange',
  },
  statusAccepted: {
    color: 'green',
  },
  statusRejected: {
    color: 'red',
  },
});

const DataRow = ({
  row,
  classes,
  isAdmin,
  editTable,
  onAccept,
  onReject,
  getOrgName,
  handleEditClick,
  handleViewClick,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow key={row.id}>
        {row.status === 'Accepted' ? (
          <TableCell>
            {/* Hidden button to keep the UI consistent */}
            <Button disabled>&nbsp;</Button>
          </TableCell>
        ) : (
          <TableCell>
            {isAdmin ? (
              row.status === 'Pending' ? (
                <div className='flex'>
                  {editTable && (
                    <>
                      <Button color='primary' onClick={() => onAccept(row)}>
                        Accept
                      </Button>
                      <Button color='primary' onClick={() => onReject(row)}>
                        Reject
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => handleViewClick(row)}
                      >
                        View
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <Button disabled>&nbsp;</Button>
              )
            ) : (
              <div className='flex'>
                {editTable && row.status === 'Rejected' ? (
                  <Button color='primary' onClick={() => handleEditClick(row)}>
                    Edit
                  </Button>
                ) : (
                  <Button disabled>&nbsp;</Button>
                )}
              </div>
            )}
          </TableCell>
        )}

        <TableCell>{row.type.split('_').join(' ')}</TableCell>
        <TableCell>{row.manager?.full_name}</TableCell>
        <TableCell>{row.user?.full_name}</TableCell>
        <TableCell>
          {Moment(new Date(row.updated_at))?.format('MM-DD-YYYY h:mm a')}
        </TableCell>
        <TableCell>
          <span className={classes[`status${row.status}`]}>{row.status}</span>
        </TableCell>
        <TableCell>
          {row.status === 'Rejected' && (
            <div
              onClick={() => setOpen(!open)}
              className={classes.inlineButton}
              aria-label='expand row'
            >
              {open ? 'Hide' : 'View'}
            </div>
          )}
        </TableCell>
        {/* <TableCell>{row.data.status}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPaddingCell} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <div className={classes.rejectionText}>
              Rejection Note: &nbsp;
              <span>{row.notes || 'None'}</span>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const DataTable = ({
  editTable,
  tableBody,
  onRowSelect,
  openEditForm,
  openView,
  isAdmin,
  onAccept,
  onReject,
  clients,
  getClients,
}) => {
  const classes = useStylesDataTable();
  const rows = tableBody;
  const data = clients?.data;

  React.useEffect(() => {
    getClients({ page: 0 });
  }, []);

  const handleEditClick = (row) => {
    openEditForm(row);
  };

  const handleViewClick = (row) => {
    openView(row);
  };

  const getOrgName = (id) => {
    const org = data.find((org) => org.id === id);
    if (org) {
      return org.name || '';
    }
  };

  const tableHeader = [
    'Action',
    'Manager',
    'Probationary PM',
    'Last Updated',
    'Status',
    'Notes',
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='custom pagination table'>
        <TableHead style={{ backgroundColor: '#2f7d32c2' }}>
          <TableRow>
            <TableCell className={classes.buttonMaxWidth}></TableCell>
            {tableHeader.map((header, i) => (
              <TableCell key={i} className={classes.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <DataRow
              row={row}
              onAccept={onAccept}
              onReject={onReject}
              handleEditClick={handleEditClick}
              getOrgName={getOrgName}
              editTable={editTable}
              classes={classes}
              isAdmin={isAdmin}
              handleViewClick={handleViewClick}
            ></DataRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = ({ client: { clients } }) => ({
  clients,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getClients: bindActionCreators(clientActions.getClients, dispatch),
  };
};

DataTable.propTypes = {
  clients: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
