import React from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import SideBarLogin from "./SidebarLogin";

const useStyles = makeStyles((theme) => ({
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarHeadingBox: {
    padding: 6,
    backgroundColor: "#d8d8d8",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  sidebarLoginHeadingBox: {
    borderRadius: 0,
    padding: 6,
    backgroundColor: "#d8d8d8",
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
}));

function Sidebar({ user, description, title }) {
  const classes = useStyles();

  const { success, currentUser } = user;

  const now = Moment(new Date())?.format("dddd, MMMM Do YYYY, h:mm:ss a");

  return (
    <Grid item xs={12} md={4}>
      {success && currentUser.data ? (
        <div>
          <Paper elevation={0} className={classes.sidebarHeadingBox}>
            <Typography variant="h6" gutterBottom>
              Welcome, {currentUser?.data?.full_name}
            </Typography>
          </Paper>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography>{now}</Typography>
          </Paper>
        </div>
      ) : (
        <div>
          <Paper elevation={0} className={classes.sidebarHeadingBox}>
            {" "}
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Paper>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography>{description}</Typography>
          </Paper>
          <Paper elevation={0} className={classes.sidebarLoginHeadingBox}>
            {" "}
            <Typography variant="h6" gutterBottom>
              Login
            </Typography>
            <Paper elevation={0} className={classes.sidebarAboutBox}>
              <SideBarLogin />
            </Paper>
          </Paper>
        </div>
      )}
    </Grid>
  );
}

const mapStateToProps = ({ user }) => ({ user });

Sidebar.propTypes = {
  archives: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(Sidebar));
