export const getAgencies = (obj) => ({
  type: "GET_AGENCIES",
  obj,
});

export const getAgenciesSuccess = (data) => ({
  type: "GET_AGENCIES_SUCCESS",
  data,
});

export const getAgenciesFail = (error) => ({
  type: "GET_AGENCIES_FAIL",
  error,
});

export const createNewAgency = (obj) => ({
  type: "CREATE_NEW_AGENCY",
  obj,
});

export const createNewAgencySuccess = (data) => ({
  type: "CREATE_NEW_AGENCY_SUCCESS",
  data,
});

export const createNewAgencyFail = (error) => ({
  type: "CREATE_NEW_AGENCY_FAIL",
  error,
});

export const editAgency = (obj) => ({
  type: "EDIT_AGENCY",
  obj,
});

export const editAgencySuccess = (data) => ({
  type: "EDIT_AGENCY_SUCCESS",
  data,
});

export const editAgencyFail = (error) => ({
  type: "EDIT_AGENCY_FAIL",
  error,
});