// routeAccessConfig.js
import { isPESUser_4, isSUPERUSER } from '../utils';

export const routeAccessConfig = {
  "/dashboard/reports/invoice-download": isPESUser_4,
  "/dashboard/reports/contractor-rejection": isPESUser_4,
  "/dashboard/reports/shipper": isPESUser_4,
  "/dashboard/reports/pendingdisposal": isSUPERUSER,
};

export default routeAccessConfig;
