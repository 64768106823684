import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import { Homepage, LoginPage, Dashboard } from "./views";

const Routes = () => {
  const [destinationPath, setDestinationPath] = React.useState(
    window.location.pathname
  );

  return (
    <Switch>
      <Route
        exact
        path="/"
        component={(props) => (
          <Homepage destinationPath={destinationPath} {...props} />
        )}
      />
        <Route exact path="/dashboard/spills/edit/:id?"    component={(props) => {
        return ( 
          <Dashboard   isEdit {...props} />
        )}} />
      <Route exact path="/dashboard/spills/view/:id?"    component={(props) => {
        return ( 
          <Dashboard   isView {...props} />
        )}} />
      {/* -- ProtectedRoute for /dashboard/reports -- */}
      <ProtectedRoute
        exact
        path="/dashboard/reports/pendingdisposal"
        component={(props) => <Dashboard {...props} />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/reports/invoice-download"
        component={(props) => <Dashboard {...props} />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/reports/contractor-rejection"
        component={(props) => <Dashboard {...props} />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/reports/shipper"
        component={(props) => <Dashboard {...props} />}
      />
      {/* -- ProtectedRoute for /dashboard/reports -- */}
      <Route
        exact
        path="/dashboard/:tab?/:id?"
        component={(props) => <Dashboard {...props} />}
      />
      <Route
        exact
        path="/login"
        component={(props) => (
          <Homepage destinationPath={"/dashboard"} {...props} />
        )}
      />
      {/* Redirect to dashboard page if user tries to access unauthorized routes */}
      <Route path="/unauthorized" render={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default Routes;
