import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  Fade,
  Grid,
  Card,
  Button,
  makeStyles,
  Typography,
  CardContent,
} from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import NewAgency from './NewAgency';
import EditExistingAgency from './EditExistingAgency';
import Search from '../../../../../Components/Search';
import DataTable from '../../../../../Components/DataTable';
import DownloadCSV from '../../../../../Components/DownloadCSV';
import DownloadFullCSV from '../../../../../Components/DownloadFullCSV';
import ReportingRequirements from './ReportingRequirements';
import * as searchActions from '../../../../../actionCreators/Search';
import * as agencyActions from '../../../../../actionCreators/Agency';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    borderRadius: '10px',
  },
  headerCard: {
    backgroundColor: '#f7f7f7',
  },
  menuButtons: {
    fontSize: '16px',
    padding: '5px 5px 0px 5px',
  },
  listAllButton: {
    fontSize: '16px',
    padding: '5px 5px 7px 5px',
  },
}));

function AgenciesMenu({
  agency,
  searchType,
  currentUser,
  getAgencies,
  searchedData,
  searching,
  loading,
  searchText,
  history,
  search,
  searchPagination,
  clearSearch,
}) {
  document.title = 'Agencies';
  const classes = useStyles();

  const location = history.location;
  const searchParam = location.search;
  const urlParams = new URLSearchParams(searchParam);

  const [rowData, setRowData] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [openEditAgency, setOpenEditAgency] = useState(false);
  const [openAddNewAgency, setOpenAddNewAgency] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [searchedTableData, setSearchTableData] = useState([]);
  const [searchedTableDataToDownload, setSearchTableDataToDownload] =
    useState();
  const [searchPage, setSearchPage] = useState(0);
  let [dataForSearch, setDataForSearch] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    let page = pagination.page + 1;

    if (page) {
      urlParams.set('page', parseInt(pagination.page) + 1);

      history.push({
        pathname: `/dashboard/agencies`,
        search: urlParams.toString(),
      });
    }
  }, [pagination]);

  const loadAgency = () => {
    const pageNumber = urlParams.get('page');
    const text = urlParams.get('search');

    if (text) {
      const dataWithParamsObject = {
        role: currentUser.data?.role?.role,
        searchText: text,
        type: 'Agency',
      };

      setDataForSearch(dataWithParamsObject);

      const data = {
        page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
        searchText: text,
        type: 'Agency',
        role: currentUser.data?.role?.role,
      };

      search(data);
    } else {
      clearSearch();
      getAgencies({
        page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
      });
    }
  }
  useEffect(() => {
    loadAgency();
  }, []);

  useEffect(() => {
    if (dataForSearch.searchText) {
      urlParams.set('search', dataForSearch.searchText);

      history.replace({
        pathname: `/dashboard/agencies`,
        search: urlParams.toString(),
      });
    }
  }, [dataForSearch]);

  useEffect(() => {
    if (agency && agency.agencies) {
      if (!searching) {
        setPagination(agency.agencies.pagination);
        setTableData(agency.agencies.data);
      }
    }
  }, [agency]);

  useEffect(() => {
    if (agency && agency.type === 'CREATE_NEW_AGENCY_SUCCESS') {
      getAgencies({ page: 0 });
    }
  }, [agency]);

  useEffect(() => {
    if (searchedData) {
      if (searchType === 'Agency') {
        setPagination(searchPagination);
        setSearchTableData(searchedData.data);
      } else {
        setPagination(agency.agencies.pagination);
        setSearchTableData([]);
      }
    }
  }, [searchedData, searchType]);

  useEffect(() => {
    if (searchedTableData) {
      const searchedAgenciesData = searchedTableData.map((item) => {
        const tableItems = [
          item?.name,
          item?.contact,
          item?.jurisdiction,
          item?.phone,
          item?.fax,
          item?.address?.replace(/,/g, ' '),
          item?.city,
          item?.state,
          item?.country,
        ];
        return tableItems;
      });

      setSearchTableDataToDownload(searchedAgenciesData);
    }
  }, [searchedTableData]);

  useEffect(() => {
    setShowNewForm(false);
    setShowEditForm(false);
    setShowForm(false);
  }, [location.key]);

  const tableHeaders = [
    { title: 'Agency', key: 'name' },
    { title: 'Voice Phone', key: 'phone' },
    { title: 'Contact', key: 'contact' },
    { title: 'Jurisdiction', key: 'jurisdiction' },
  ];

  const csvHeaders = [
    { title: 'Agency Name', key: 'name' },
    { title: 'Contact', key: 'contact' },
    { title: 'Jurisdiction', key: 'jurisdiction' },
    { title: 'Phone', key: 'phone' },
    { title: 'Fax', key: 'fax' },
    { title: 'Address', key: 'address' },
    { title: 'City', key: 'city' },
    { title: 'State', key: 'state' },
    { title: 'Country', key: 'country' },
  ];

  const handleAllAgenciesClick = () => {
    setShowNewForm(false);
    setShowEditForm(false);
    setShowForm(false);
  };

  const handleOpenEditModalWithDataTable = async (data) => {
    setShowEditForm(true);
    setRowData(data);
    handleOpenEditAgencyModal(true);
  };
  const handleOpenNewAgencyForm = () => {
    setShowForm(true);
    setShowNewForm(true);
    setShowEditForm(false);
  };
  const handleCloseNewAgency = () => {
    setShowForm(false);
    setShowNewForm(false);
  };
  const handleOpenEditAgencyModal = () => {
    setShowForm(true);
    setOpenEditAgency(true);
  };
  const handleOpenEditForm = () => {
    setShowEditForm(true);
    setShowNewForm(false);
  };
  const handleCloseEditAgency = (isrefreshDatatable) => {
    if (isrefreshDatatable) {
      loadAgency();
    } else {
      setShowForm(false);
      setShowEditForm(false);
    }
  };
  const handleOpenAddressModal = () => {
    setOpenAddressModal(true);
  };
  const handleCloseAddressModal = () => {
    setOpenAddressModal(false);
  };

  const handleTablePage = (data) => {
    if (data.type === 'search') {
      setSearchPage(data.newPage);

      dataForSearch = {
        ...dataForSearch,
        page: data.newPage,
      };

      search(dataForSearch);
    } else {
      getAgencies({ page: data.newPage });
    }
  };

  const handleAgencySearch = (data) => {
    setDataForSearch(data);
    data = {
      ...data,
      page: searchPage,
    };

    search(data);
  };

  const handlePageReset = () => {
    setSearchPage(0);

    // getAgencies({ page: 0 });

    history.push({
      pathname: `/dashboard/agencies`,
    });
  };

  useEffect(() => {
    if (location?.search) {
      const urlPage = location?.search.split('=');
      const urlPageNumber = parseInt(urlPage.pop(), 10);
      if (urlPageNumber) setCurrentPage(urlPageNumber);
    }
  }, [location]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={24} md={12}>
          <Card className={classes.headerCard}>
            <CardContent style={{ paddingBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant='h5' style={{ color: '#619a63' }}>
                    Agencies
                  </Typography>
                </div>
                <div>
                  <BusinessCenterIcon
                    fontSize='large'
                    style={{ color: '#619a63' }}
                  />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: 'flex',
                  direction: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  {showForm ? (
                    <Button
                      className={classes.listAllButton}
                      color='primary'
                      onClick={handleAllAgenciesClick}
                    >
                      All Agencies
                    </Button>
                  ) : (
                    <div>
                      <Button
                        className={classes.menuButtons}
                        color='primary'
                        onClick={handleOpenNewAgencyForm}
                      >
                        Add New
                      </Button>
                    </div>
                  )}

                  <div>
                    <DownloadFullCSV
                      type={'Agency'}
                      dataForSearch={
                        searchedTableData.length > 0
                          ? dataForSearch.searchText
                          : null
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Search
                    type={'Agency'}
                    userRole={currentUser.data?.role?.role}
                    searchData={searchType === 'Agency' ? searchText : ''}
                    isSearching={
                      searching && searchType === 'Agency' ? true : false
                    }
                    handleSearch={(data) => handleAgencySearch(data)}
                    resetPage={() => handlePageReset()}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          <div
            style={{
              width: '100%',
              height: '25px',
              textAlign: 'right',
              paddingRight: '15px',
            }}
          >
            {searchedTableData.length > 0 ? (
              <div style={{ paddingTop: '5px' }}>
                Results found ({pagination.count})
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: 10 }}>
            {searching && searchType === 'Agency'
              ? showNewForm === false &&
              showEditForm === false && (
                <div>
                  {searchedTableData.length > 0 ? (
                    <DataTable
                      tableHeader={tableHeaders}
                      tableBody={searchedTableData}
                      editTable={true}
                      onRowSelect={handleOpenEditModalWithDataTable}
                      pageChange={handleTablePage}
                      openEditForm={handleOpenEditForm}
                      pagination={pagination}
                      loading={loading}
                      type={'search'}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setPageRows={() => { }}
                    />
                  ) : (
                    <p>No agency found.</p>
                  )}
                </div>
              )
              : showNewForm === false &&
              showEditForm === false && (
                <DataTable
                  tableHeader={tableHeaders}
                  tableBody={tableData}
                  editTable={true}
                  pagination={pagination}
                  onRowSelect={handleOpenEditModalWithDataTable}
                  pageChange={handleTablePage}
                  openEditForm={handleOpenEditForm}
                  loading={agency.loading}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setPageRows={() => { }}
                />
              )}
          </div>
          <div style={{ marginTop: 20 }}>
            {showNewForm && (
              <Fade in={showNewForm}>
                <div className={classes.paper}>
                  <NewAgency
                    userRole={currentUser.data?.role?.role}
                    closeAddAgency={handleCloseNewAgency}
                    openAddressModal={handleOpenAddressModal}
                  />
                </div>
              </Fade>
            )}
          </div>
          <div style={{ marginTop: 20 }}>
            {showEditForm && (
              <Fade in={showEditForm}>
                <div className={classes.paper}>
                  <EditExistingAgency
                    userRole={currentUser.data?.role?.role}
                    closeEditModal={handleCloseEditAgency}
                    agencyData={rowData}
                  />
                </div>
              </Fade>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = ({
  agency,
  user: { currentUser },
  search: {
    searchedData,
    searchType,
    searching,
    searchText,
    searchPagination,
    loading,
  },
}) => ({
  currentUser,
  agency,
  searching,
  loading,
  searchedData,
  searchType,
  searchText,
  searchPagination,
});

const mapDispatchToProps = (dispatch) => ({
  getAgencies: bindActionCreators(agencyActions.getAgencies, dispatch),
  search: bindActionCreators(searchActions.search, dispatch),
  clearSearch: bindActionCreators(searchActions.clearSearch, dispatch),
});

AgenciesMenu.prototype = {
  agency: PropTypes.object.isRequired,
  getAgencies: PropTypes.func.isRequired,
  searchedData: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgenciesMenu)
);
