import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100vh',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '7px',
    boxShadow: theme.shadows[5],
    padding: '20px 20px 10px 20px',
    border: 'none',
    outline: 'none',
    maxHeight: '80%',
    maxWidth: '80%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(47 125 50 / 36%)',
      borderRadius: 30,
    },
  },
}));

export default function WholeScreenModal({
  show,
  hide,
  minWidth,
  minHeight,
  maxHeight,
  children,
  disableBackdropClick,
  disableEscapeKeyDown,
}) {
  const classes = useStyles();

  const handleClose = () => {
    hide();
  };

  return (
    <Modal
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={show}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={show}>
        <div
          className={classes.paper}
          style={{
            minWidth: minWidth || 'initial',
            minHeight: minHeight || 'initial',
            maxHeight: maxHeight || 'initial',
          }}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  );
}
