import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import CardContent from '@material-ui/core/CardContent';

import NewClient from './NewClient';
import EditClient from './EditClient';
import Search from '../../../../../Components/Search';
import DataTable from '../../../../../Components/DataTable';
import DownloadCSV from '../../../../../Components/DownloadCSV';
import DownloadFullCSV from '../../../../../Components/DownloadFullCSV';

import * as clientActions from '../../../../../actionCreators/Client';
import * as userActions from '../../../../../actionCreators/User';
import * as servicesActions from '../../../../../actionCreators/Service';
import * as searchActions from '../../../../../actionCreators/Search';
import { CustomSnackBar } from '../../../../../Components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    borderRadius: '10px',
  },
  menuButtons: {
    fontSize: '16px',
    padding: '5px 5px 0px 5px',
  },
  listAllButton: {
    fontSize: '16px',
    padding: '5px 5px 7px 5px',
  },
}));

const ClientsMenu = ({
  client,
  getClients,
  searchType,
  currentUser,
  searchedData,
  searching,
  searchText,
  service,
  salePersons,
  getSalePersons,
  getServices,
  history,
  search,
  searchPagination,
  clearSearch,
  loading,
}) => {
  document.title = 'Clients';
  const classes = useStyles();
  const { success } = client;

  const location = history.location;
  const searchParam = location.search;
  const urlParams = new URLSearchParams(searchParam);

  const [rowId, setRowId] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [openAddNewClient, setOpenAddNewClient] = useState(false);
  const [openEditClient, setOpenEditClient] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchedTableData, setSearchTableData] = useState([]);
  const [searchedTableDataToDownload, setSearchTableDataToDownload] =
    useState();
  const [searchPage, setSearchPage] = useState(0);
  let [dataForSearch, setDataForSearch] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('error');

  useEffect(() => {
    if (currentUser) {
      getServices();
      getSalePersons();
    }
  }, [currentUser]);

  useEffect(() => {
    let page = pagination.page + 1;

    if (page) {
      urlParams.set('page', parseInt(pagination.page) + 1);

      history.push({
        pathname: `/dashboard/clients`,
        search: urlParams.toString(),
      });
    }
  }, [pagination]);

  useEffect(() => {
    const pageNumber = urlParams.get('page');
    const text = urlParams.get('search');

    if (text) {
      const dataWithParamsObject = {
        role: currentUser.data?.role?.role,
        searchText: text,
        type: 'Client',
      };

      setDataForSearch(dataWithParamsObject);

      const data = {
        page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
        searchText: text,
        type: 'Client',
        role: currentUser.data?.role?.role,
      };

      search(data);
    } else {
      clearSearch();
      // getClients({
      //   page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
      // });
    }
  }, []);

  useEffect(() => {
    if (dataForSearch.searchText) {
      urlParams.set('search', dataForSearch.searchText);

      history.replace({
        pathname: `/dashboard/clients`,
        search: urlParams.toString(),
      });
    }
  }, [dataForSearch]);

  useEffect(() => {
    if (success && client.clients.data) {
      if (!searching) {
        setPagination(client.clients.pagination);
        setTableData(
          client.clients.data.sort((x, y) => {
            return x.active ? -1 : 1;
          })
        );
      }
    }
  }, [success, client.clients.data]);

  useEffect(() => {
    const pageNumber = urlParams.get('page');

    if (client && client.type === 'CREATE_NEW_CLIENT_SUCCESS') {
      getClients({
        page: pageNumber ? (pageNumber === '0' ? 0 : pageNumber - 1) : 0,
      });    }
  }, [client]);

  useEffect(() => {
    if (searchedData) {
      if (searchType === 'Client') {
        setPagination(searchPagination);
        setSearchTableData(
          searchedData.data.sort((x, y) => {
            return x.active ? -1 : 1;
          })
        );
      } else {
        setPagination(client.clients.pagination);
        setSearchTableData([]);
      }
    }
  }, [searchedData, searchType]);

  useEffect(() => {
    if (searchedTableData) {
      const searchedUsersData = searchedTableData.map((item) => {
        const tableItems = [
          item?.name,
          item?.code,
          item?.rate_type,
          item?.rate,
          item?.sales_person,
          item?.tax_id,
          item?.phone,
          item?.address?.replace(/,/g, ' '),
          item?.city,
          item?.state,
          item?.country,
        ];

        return tableItems;
      });

      setSearchTableDataToDownload(searchedUsersData);
    }
  }, [searchedTableData]);

  useEffect(() => {
    setShowForm(false);
    setOpenAddNewClient(false);
    setOpenEditClient(false);
  }, [location.key]);

  const tableHeaders = [
    { title: 'Code', key: 'code' },
    { title: 'Client Organization', key: 'name' },
    { title: 'Phone', key: 'phone' },
    { title: 'Active', key: 'active' },
  ];

  const handleOpenEditModalWithDataTable = async (value) => {
    setRowId(value.id);
    handleOpenEditClientModal(true);
  };

  const handleCloseNewClientModal = (showMsg) => {
    if (showMsg) {
      setSnackBarSeverity('success');
      setErrorMessage('Facility/Terminals data are added in client, once data is updated you will get a confirmation mail.');
    }
    setOpenAddNewClient(false);
    setShowForm(false);
    setOpenEditClient(false);
  };

  const handleOpenEditClientModal = () => {
    setOpenEditClient(true);
    setShowForm(true);
  };

  const handleCloseEditClientModal = (showMsg) => {
    if (showMsg) {
      setSnackBarSeverity('success');
      setErrorMessage('Facility/Terminals data are added in client, once data is updated you will get a confirmation mail.');
    }
    setOpenEditClient(false);
    setShowForm(false);
    setOpenEditClient(false);
    searchText && search(dataForSearch);
  };

  const handleOpenNewAgencyForm = () => {
    setShowForm(true);
    setOpenAddNewClient(true);
    setOpenEditClient(false);
  };

  const handleAllAgenciesClick = () => {
    // getClients({ page: 0 });
    setShowForm(false);
    setOpenAddNewClient(false);
    setOpenEditClient(false);
  };

  const handleTablePage = (data) => {
    if (data.type === 'search') {
      setSearchPage(data.newPage);

      dataForSearch = {
        ...dataForSearch,
        page: data.newPage,
      };

      search(dataForSearch);
    } else {
      getClients({ page: data.newPage });
    }
  };

  const handleClientSearch = (data) => {
    setDataForSearch(data);
    data = {
      ...data,
      page: searchPage,
    };

    search(data);
  };

  const handlePageReset = () => {
    setSearchPage(0);

    getClients({ page: 0 });

    history.push({
      pathname: `/dashboard/clients`,
    });
  };

  useEffect(() => {
    if (location?.search) {
      const urlPage = location?.search.split('=');
      const urlPageNumber = parseInt(urlPage.pop(), 10);
      if (urlPageNumber) setCurrentPage(urlPageNumber);
    }
  }, [location]);

  return (
    <div>
      <CustomSnackBar
        open={!!errorMessage}
        severity={snackBarSeverity}
        snackBarMessage={errorMessage}
        onClose={() => setErrorMessage('')} 
      />
      <Grid container spacing={4}>
        <Grid item xs={24} md={12}>
          <Card>
            <CardContent style={{ paddingBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant='h5' style={{ color: '#619a63' }}>
                    Client Organizations
                  </Typography>
                </div>
                <div>
                  <BusinessIcon fontSize='large' style={{ color: '#619a63' }} />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: 'flex',
                  direction: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div>
                    {showForm ? (
                      <Button
                        className={classes.listAllButton}
                        color='primary'
                        onClick={handleAllAgenciesClick}
                      >
                        All Clients
                      </Button>
                    ) : (
                      <div>
                        <Button
                          className={classes.menuButtons}
                          color='primary'
                          onClick={handleOpenNewAgencyForm}
                        >
                          Add New
                        </Button>
                      </div>
                    )}
                  </div>

                  <div>
                    <DownloadFullCSV
                      type={'Client'}
                      dataForSearch={
                        searchedTableData.length > 0
                          ? dataForSearch.searchText
                          : null
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Search
                    type={'Client'}
                    userRole={currentUser.data?.role?.role}
                    searchData={searchType === 'Client' ? searchText : ''}
                    isSearching={
                      searching && searchType === 'Client' ? true : false
                    }
                    handleSearch={(data) => handleClientSearch(data)}
                    resetPage={() => handlePageReset()}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          <div
            style={{
              width: '100%',
              height: '25px',
              textAlign: 'right',
              paddingRight: '15px',
            }}
          >
            {searchedTableData.length > 0 ? (
              <div style={{ paddingTop: '5px' }}>
                Results found ({pagination.count})
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: 10 }}>
            {searching && searchType === 'Client'
              ? openAddNewClient === false &&
                openEditClient === false && (
                  <div>
                    {searchedTableData.length > 0 ? (
                      <DataTable
                        tableHeader={tableHeaders}
                        editTable={true}
                        tableBody={searchedTableData}
                        onRowSelect={handleOpenEditModalWithDataTable}
                        pageChange={handleTablePage}
                        pagination={pagination}
                        type={'search'}
                        loading={loading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setPageRows={() => {}}
                      />
                    ) : (
                      <p>No client organization found.</p>
                    )}
                  </div>
                )
              : openAddNewClient === false &&
                openEditClient === false && (
                  <div>
                    {client ? (
                      <DataTable
                        tableHeader={tableHeaders}
                        editTable={true}
                        pagination={pagination}
                        tableBody={tableData}
                        onRowSelect={handleOpenEditModalWithDataTable}
                        pageChange={handleTablePage}
                        loading={loading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setPageRows={() => {}}
                      />
                    ) : (
                      <p>No data found.</p>
                    )}
                  </div>
                )}
            <div style={{ marginTop: 10 }}>
              {openAddNewClient && (
                <Fade in={openAddNewClient}>
                  <div className={classes.paper}>
                    <NewClient
                      closeAddClientModal={handleCloseNewClientModal}
                      servicesData={service}
                      salePersonsData={salePersons}
                      userRole={currentUser.data?.role?.role}
                    />
                  </div>
                </Fade>
              )}
            </div>
            <div style={{ marginTop: 10 }}>
              {openEditClient && (
                <Fade in={openEditClient}>
                  <div className={classes.paper}>
                    <EditClient
                      closeEditModal={handleCloseEditClientModal}
                      clientId={rowId}
                      clients={tableData}
                      clientdata={tableData}
                      servicesData={service}
                      salePersonsData={salePersons}
                      userRole={currentUser.data?.role?.role}
                    />
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  client,
  user: { currentUser, admins, salePersons },
  search: { searchedData, searchType, searching, searchText, searchPagination },
  user,
  search,
  service,
}) => ({
  client,
  searchedData,
  searchType,
  currentUser,
  searching,
  searchText,
  service,
  admins,
  salePersons,
  searchPagination,
  loading: client.loading || user.loading || search.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getClients: bindActionCreators(clientActions.getClients, dispatch),
  getServices: bindActionCreators(servicesActions.getServices, dispatch),
  getSalePersons: bindActionCreators(userActions.getSalePersons, dispatch),
  search: bindActionCreators(searchActions.search, dispatch),
  clearSearch: bindActionCreators(searchActions.clearSearch, dispatch),
});

ClientsMenu.propTypes = {
  user: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  getClients: PropTypes.func.isRequired,
  searchedData: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsMenu)
);
