import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import "./styles/index.css";
import App from "./App";
import Routes from "./routes";
import store from "./Config/Store";

// sentry io
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  maxBreadcrumbs: 2,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_API_URL, process.env.REACT_APP_API_URL_ANALYTICS, process.env.REACT_APP_API_URL_DOWNLOAD, process.env.REACT_APP_API_URL_UPLOAD],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App>
          <Routes />
        </App>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
