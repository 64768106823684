import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { isPESUser } from '../../../../../src/utils';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Avatar from '@material-ui/core/Avatar';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import * as userActions from '../../../../actionCreators/User';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const EditProfilePopup = ({
  userInformation,
  editUserProfile,
  user,
  closeModal,
}) => {
  const classes = useStyles();

  const {
    userName,
    userPhone,
    userActive,
    userRole,
    userOrganization,
    userPacketReviewer,
  } = userInformation;
  const [phone, setPhone] = React.useState(userPhone);
  const [packetReviewer, setPacketReviewer] =
    React.useState(userPacketReviewer);
  const [errorFlag, setErrorFlag] = React.useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const obj = {
      id: user.currentUser.data.id,
      full_name: e.target.name.value?.trim(),
      phone: e.target.phone?.value,
      is_packet_reviewer: e.target.packetReviewer?.checked,
    };

    await editUserProfile(obj);

    await closeModal();
  };

  const validateContact = (phone) => {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone);
  };

  React.useEffect(() => {
    if (!validateContact(phone)) setErrorFlag(true);
    else setErrorFlag(false);
  }, [phone]);

  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom>
        <div style={{ display: 'flex' }}>
          <Avatar className={classes.avatar}>
            <AssignmentIcon />
          </Avatar>
          <span style={{ paddingLeft: '20px' }}>Edit Profile</span>
        </div>
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id='name'
              name='name'
              label='Full Name'
              fullWidth
              defaultValue={userName}
              autoComplete='given-name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errorFlag}
              value={phone}
              onChange={(x) => setPhone(x.target.value)}
              id='phone'
              name='phone'
              label='Phone'
              fullWidth
              defaultValue={userPhone}
              autoComplete='given-number'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneIphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='organization'
              name='organization'
              label='organization'
              fullWidth
              autoComplete='organization'
              disabled
              defaultValue={userOrganization}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <BusinessIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='role'
              name='role'
              label='role'
              fullWidth
              autoComplete='role'
              defaultValue={userRole}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SupervisorAccountIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {isPESUser(user?.currentUser?.data?.role?.role) && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    onChange={(x) => setPacketReviewer(x.target?.checked)}
                    name='packetReviewer'
                    defaultValue={userPacketReviewer}
                    checked={packetReviewer}
                  />
                }
                label='Packet Reviewer'
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  name='active'
                  value='yes'
                  checked={userActive}
                  disabled
                />
              }
              label='Active'
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              disabled={errorFlag}
              className={classes.button}
              variant='contained'
              color='primary'
              type='submit'
            >
              Update
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  editUserProfile: bindActionCreators(userActions.editUserProfile, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProfilePopup)
);
