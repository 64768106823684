import { Grid, Table, TableBody, TableCell,Link, TableContainer,Container, TableHead, TableRow, Typography, makeStyles, Button } from '@material-ui/core';
import React from 'react'
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: theme.palette.background.paper,
    // borderRadius: '7px',
    boxShadow: theme.shadows[2],
    // border: 'none',
    // outline: 'none',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(47 125 50 / 36%)',
      borderRadius: 30,
    },
    overflowX:"hidden"
  },
  typography: {
    display:"flex",
    justifyContent:"center",
    padding:"10px 0"
  },
  noDataFoundMessage: {
    display: "flex", 
    justifyContent: "center", 
    margin:"10px 0"
  },
  tableHeader: {
    color: theme.palette.primary.main,
    // backgroundColor: grey[400],
    backgroundColor: '#E0E0E0',
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    "z-index": 100,
    fontWeight:"bold"
  },
  link: {
    textDecoration: 'underline', 
    fontWeight:"bold",
    cursor:"pointer"
  },
  tableHeading: {
    fontWeight:"bold"
  }
}))

// generic progressive billing table that requires the data as a props
const ProgressiveBillingTable = ({partialBillingDownloadData,handleAttachmentDownload, downloadDisabled}) => {
  const classes = useStyles();
  return (
    <Grid style={{ display: "flex", alignItems: "center", padding: "0px 30px" }}>
      <Grid container justifyContent="center" >
        <TableContainer style={{maxHeight:"30vh"}} className={classes.paper} >
          {partialBillingDownloadData?.length ?
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell><Typography className={`${classes.typography} ${classes.tableHeading}`} color='primary'>Downloaded By</Typography></TableCell>
                  <TableCell><Typography className={`${classes.typography} ${classes.tableHeading}`} color='primary'>Report Start Date</Typography></TableCell>
                  <TableCell><Typography className={`${classes.typography} ${classes.tableHeading}`} color='primary'>Report End Date</Typography></TableCell>
                  <TableCell><Typography className={`${classes.typography} ${classes.tableHeading}`} color='primary'>Final Invoice</Typography></TableCell>
                  <TableCell><Typography className={`${classes.typography} ${classes.tableHeading}`} color='primary'>Action</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partialBillingDownloadData?.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell><Typography className={classes.typography} >{item?.user?.full_name}</Typography></TableCell>
                      <TableCell><Typography className={classes.typography}> {moment(item?.report_start_date)?.format("MM-DD-YYYY h:mm a")}</Typography></TableCell>
                      <TableCell><Typography className={classes.typography}> {moment(item?.report_end_date)?.format("MM-DD-YYYY h:mm a")}</Typography></TableCell>
                      <TableCell><Typography className={classes.typography}> {item?.final_invoice ? moment(item?.final_invoice)?.format("MM-DD-YYYY h:mm a") : '-'}</Typography></TableCell>
                      <TableCell>
                        <Typography className={classes.typography} style={{ fontWeight: "normal" }}>
                        <Button disabled={downloadDisabled}>
                          <Link
                            onClick={(event) => {
                              event.preventDefault(); // Prevent default link behavior
                              handleAttachmentDownload(item);
                            }}
                            className={classes.link}
                          >
                            Download
                          </Link>
                        </Button>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          : <Container className={classes.noDataFoundMessage}>No Partial Billing History Found For This Admin</Container>}
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ProgressiveBillingTable