import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootLoader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    height: "90vh",
  },
}));

const CustomProgressLoader = ({ show }) => {
  const classes = useStyles();

  return (
    show && (
      <div className={classes.rootLoader}>
        <CircularProgress
          style={{ width: "50px", height: "50px", color: "#2F7D32" }}
        />
      </div>
    )
  );
};

export default CustomProgressLoader;
