import { all, put, takeLatest } from "redux-saga/effects";

import {
  getServicesFail,
  getServicesSuccess,
  createNewServiceSuccess,
  createNewServiceFail,
  editServiceFail,
  editServiceSuccess,
  reorderServicesSuccess,
  reorderServicesFail,
  changeServiceStatusSuccess,
  changeServiceStatusFail,
  getCategoriesSuccess,
  getCategoriesFail,
} from "../actionCreators/Service";
import { doGet, doPost } from "../utils/request";

function* getServices({ obj }) {
  try {
    const response = yield doGet(`/services/all`);

    yield put(getServicesSuccess(response.data.data));
  } catch (err) {
    yield put(getServicesFail(err.response.data.message || err.message));
  }
}

function* createNewService({ obj }) {
  try {
    const response = yield doPost("/services/createNew", obj);

    yield put(createNewServiceSuccess(response.data.data));
  } catch (err) {
    yield put(createNewServiceFail(err.response.data.message || err.message));
  }
}

function* editService({ obj }) {
  try {
    const response = yield doPost("/services/update", obj);

    yield put(editServiceSuccess(response.data.data));
  } catch (err) {
    yield put(editServiceFail(err.response.data.message || err.message));
  }
}

function* getCategories({ obj }) {
  try {
    const response = yield doGet("/services/getCategories");
    yield put(getCategoriesSuccess(response.data.data));
  } catch (err) {
    yield put(getCategoriesFail(err.response.error.message || err.message));
  }
}

function* reorderService({ obj }) {
  try {
    const response = yield doPost("/services/reorder", obj);

    yield put(reorderServicesSuccess(response.data.data));
  } catch (err) {
    yield put(reorderServicesFail(err.response.data.message || err.message));
  }
}

function* changeServiceStatus({ obj }) {
  try {
    yield doPost("/services/changeStatus", obj);

    yield put(changeServiceStatusSuccess(obj));
  } catch (err) {
    yield put(changeServiceStatusFail(err.message));
  }
}

export default function* watcher() {
  yield all([takeLatest("GET_SERVICES", getServices)]);
  yield all([takeLatest("CREATE_NEW_SERVICE", createNewService)]);
  yield all([takeLatest("EDIT_SERVICE", editService)]);
  yield all([takeLatest("RE_ORDER_SERVICES", reorderService)]);
  yield all([takeLatest("CHANGE_SERVICE_STATUS", changeServiceStatus)]);
  yield all([takeLatest("GET_CATEGORIES", getCategories)]);
}
