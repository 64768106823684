import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AssignmentTurnedInIcon from "@material-ui/icons/AssessmentOutlined";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";

import ServiceForm from "./ServiceForm";
import * as servicesActions from "../../../../../actionCreators/Service";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { CustomProgressLoader } from "../../../../../Components";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    borderRadius: "10px",
  },
  headerCard: {
    backgroundColor: "#f7f7f7",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  itemsAlign: {
    textAlign: "left",
  },
  centerAlign: {
    textAlign: "center",
  },
  activeButton: {
    padding: 0,
    color: "#437d33 !important",
  },
}));

const ServicesMenu = ({
  service,
  getServices,
  reorderServices,
  currentUser,
  changeServiceStatus,
  loading,
}) => {
  document.title = "Services";
  const classes = useStyles();

  const [rowData, setRowData] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const [showForm, setShowForm] = React.useState(false);
  const [showNewForm, setShowNewForm] = React.useState(false);
  const [noOfServices, setNoOfServices] = React.useState(0);
  React.useEffect(() => {
    getServices({ page: 0 });
  }, []);

  React.useEffect(() => {
    if (service.success) {
      setTableData(service.services.data);
      if (service.services.data) {
        setNoOfServices(service.services.data.length);
      }
    }
  }, [service.services?.data]);

  const handleAllServicesClick = () => {
    setShowNewForm(false);
  };

  const handleOpenNewServiceForm = () => {
    setRowData(null);
    setShowForm(true);
    setShowNewForm(true);
  };

  const handleCloseNewService = () => {
    setShowForm(false);
    setShowNewForm(false);
  };

  const handleEditClick = (service) => {
    handleOpenNewServiceForm();
    setRowData(service);
  };

  const dataSorter = (sortedData) => {
    let updatedData = [];

    sortedData.map((data, i) => {
      let dataToUpdate = {
        ...data,
        order: i,
      };

      updatedData.push(dataToUpdate);
    });

    setTableData(updatedData);
  };

  const saveOrderedServices = (services) => {
    const reorderedServicesData = {
      reorderedServices: services,
    };
    reorderServices(reorderedServicesData);
  };

  const ChangeServiceStatus = (id, status) => {
    changeServiceStatus({ id, active: status ? false : true });
  };

  return (
    <div>
      <Grid container spacing={4} style={{ fontSize: "13px" }}>
        <Grid item xs={12} md={12}>
          <Card className={classes.headerCard}>
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="h5">Services</Typography>
                </div>
                <div>
                  <AssignmentTurnedInIcon fontSize="large" />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  direction: "row",
                }}
              >
                <div style={{ textAlign: "justify", color: "grey" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "justify", margin: "0 5px" }}
                  >
                    The entries here determine the popup menu for adding or
                    editing Notes to a spill-incident. Drag-and-drop them to
                    reorder the menu. If a new note should auto-generate an
                    email notification, turn the "email" checkbox on.
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {showForm ? (
                    <Button
                      color="primary"
                      onClick={() => setShowNewForm(false)}
                    >
                      All Services
                    </Button>
                  ) : (
                    <Typography variant="h5"></Typography>
                  )}
                </div>
                <div>
                  {currentUser &&
                  currentUser.data?.role?.permission?.create_services ? (
                    <Button color="primary" onClick={handleOpenNewServiceForm}>
                      Create new Service
                    </Button>
                  ) : null}
                </div>
              </div>
            </CardContent>
          </Card>

          <div style={{ marginTop: 20 }}>
            {showNewForm === false && (
              <div>
                <Card
                  className={classes.headerCard}
                  style={{ backgroundColor: "white" }}
                >
                  <Card
                    className={classes.headerCard}
                    style={{ margin: "10px 15px" }}
                  >
                    <CardContent
                      style={{
                        padding: "10px",
                        backgroundColor: "#e6e6e6d6",
                        fontWeight: 600,
                        color: "#6aa16c",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      ></div>
                      <Grid container spacing={0}>
                        <Grid item xs={1}>
                          <div className={classes.itemsAlign}>Actions</div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.itemsAlign}>
                            Service Description
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className={classes.centerAlign}>
                            Email Notifications
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div className={classes.centerAlign}>Active</div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  {loading ? (
                    <CustomProgressLoader show={true} />
                  ) : (
                    <ReactSortable
                      list={tableData}
                      setList={dataSorter}
                      animation={200}
                      delayOnTouchStart={true}
                      delay={2}
                    >
                      {tableData &&
                        tableData.map((service, i) => (
                          <Card
                            className={classes.headerCard}
                            style={{
                              margin: "10px 15px",
                              backgroundColor: service.active
                                ? null
                                : "#bfbfbf",
                            }}
                          >
                            <CardContent style={{ padding: "10px" }}>
                              <Grid container spacing={0}>
                                <Grid item xs={1}>
                                  <div className={classes.itemsAlign}>
                                    <a
                                      href
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEditClick(service)}
                                    >
                                      Edit
                                    </a>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className={classes.itemsAlign}>
                                    {service.name}
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div className={classes.centerAlign}>
                                    {service.email_notifications === true
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className={classes.centerAlign}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          className={classes.activeButton}
                                          checked={service.active}
                                          onChange={(e) => {
                                            ChangeServiceStatus(
                                              service.id,
                                              service.active
                                            );
                                          }}
                                          name={"true"}
                                        />
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                    </ReactSortable>
                  )}
                </Card>
                <div style={{ textAlign: "right", marginTop: "5px" }}>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => saveOrderedServices(tableData)}
                  >
                    Save Order
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: 20 }}>
            {showNewForm && (
              <Fade in={showNewForm}>
                <div className={classes.paper}>
                  <ServiceForm
                    closeAddService={handleCloseNewService}
                    totalServices={noOfServices}
                    currentService={rowData}
                  />
                </div>
              </Fade>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ service, user }) => ({
  currentUser: user.currentUser,
  service,
  loading: service.loading || user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getServices: bindActionCreators(servicesActions.getServices, dispatch),
  changeServiceStatus: bindActionCreators(
    servicesActions.changeServiceStatus,
    dispatch
  ),
  reorderServices: bindActionCreators(
    servicesActions.reorderServices,
    dispatch
  ),
});

ServicesMenu.propTypes = {
  getServices: PropTypes.func.isRequired,
  changeServiceStatus: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  reorderServices: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServicesMenu)
);
