import { all, put, takeLatest } from "redux-saga/effects";

import { searchSuccess, searchFailure } from "../actionCreators/Search";
import { doPost } from "../utils/request";

function* search({ obj }) {
  try {
    const response = yield doPost("/search/data", obj);

    yield put(searchSuccess(response.data));
  } catch (err) {
    yield put(searchFailure(err.response.data.message || err.message));
    yield put(searchFailure());
  }
}

export default function* watcher() {
  yield all([takeLatest("SEARCH", search)]);
}
