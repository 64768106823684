import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";

import { CustomProgressLoader } from "../../../../../../Components";

import { HistoryText as Text } from "../../../../../../utils/language/english";
import { getFormattedTime } from "../../../../../../utils";

const useStylesDataTable = makeStyles({
  tableHeading: {
    color: "white",
    fontWeight: 900,
    backgroundColor: "#2f7d32",
  },
  automationIcon: {
    color: "#2f7d32",
    fontWeight: 20,
  },
  automationCol: {
    display: "table-cell",
    border: "none",
  },
});

const columns = [
  {
    id: "assigned_to",
    label: "Assigned To",
    minWidth: 100,
    align: "left",
  },
  { id: "assigned_at", label: "Assigned At", minWidth: 100, align: "left" },
  { id: "unassigned_at", label: "Unassigned At", minWidth: 100, align: "left" },
];

const PacketAssignementHistoryTabPanel = ({ packetAssignmentHistory }) => {
  const classes = useStylesDataTable();

  const [packetAssignmentHistoryState, setPacketAssignmentHistoryState] =
    useState({
      rows: [],
      success: false,
      loading: false,
    });
  const [noPacketAssignmentFound, setNoPacketAssignmentFound] = useState(false);

  useEffect(() => {
    if (packetAssignmentHistory?.data?.data?.length === 0) {
      setNoPacketAssignmentFound(true);
    }

    if (packetAssignmentHistory) {
      setPacketAssignmentHistoryState({
        rows: packetAssignmentHistory?.data?.data,
        success: packetAssignmentHistory?.success,
        loading: packetAssignmentHistory?.loading,
      });
    }
  }, [packetAssignmentHistory]);

  return (
    <div>
      <Typography variant="h5" color="primary" align="center">
        {/* {noPacketAssignmentFound
          ? Text.noHistoryFound
          : Text.spillPacketAssignmentHistory} */}
      </Typography>
      {!noPacketAssignmentFound && (
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          {packetAssignmentHistoryState?.loading ? (
            <CustomProgressLoader
              show={packetAssignmentHistoryState?.loading}
            />
          ) : (
            <TableContainer style={{ maxHeight: 500, overflowY: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeading}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packetAssignmentHistoryState?.rows?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>
                          {row?.packet_reviewer_assigned || "N/A"}
                        </TableCell>
                        <TableCell>
                          {row?.assigned_at
                            ? getFormattedTime(row?.assigned_at)
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row?.unassigned_at
                            ? getFormattedTime(row?.unassigned_at)
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}
    </div>
  );
};

export default PacketAssignementHistoryTabPanel;
