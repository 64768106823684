import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import EditAddressForm from './EditAddressForm';
import MainAddressForm from './MainAddressForm';
import ResponseCount from './ResponseCount';
import { RESPONSE_COUNT_TYPE } from '../../utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
  header: {
    color: 'green',
    textAlign: 'left',
  },
  nameFieldWrapper: {
    marginBottom: '1rem',
  },
  accordian: {
    marginBottom: '7px',
    borderRadius: '10px',
  },
  addressAccordian: {
    backgroundColor: '#dedcdc',
    borderRadius: '10px',
  },
  addressAccordianHeader: {
    display: 'block',
    width: '100%',
    padding: '0px 15px 0px 0px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addressLocation: {
    display: 'flex',
    paddingRight: '5px',
    alignItems: 'center',
    gap: 5,
  },
  addressIcon: {
    marginRight: '5px',
    color: 'rgb(199 169 13)',
  },
}));

const AddressAccordian = ({
  isOrg,
  register,
  addresses,
  hideCount,
  entity_id,
  entity_type,
  mainAddress,
  isContractor,
  setAddresses,
}) => {
  const [expanded, setExpanded] = useState(0);
  const [mainAddressFormData, setMainAddressFormData] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    fax:"",
    contact: ""
  });


  const handleNewAddress = () => {
    let tempAddresses = [...addresses];
    tempAddresses = [
      ...tempAddresses,
      {
        entity_id: entity_id,
        entity_type: entity_type,
      },
    ];
    setAddresses(tempAddresses);
  };

  const handleExpandChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);
  };

  const classes = useStyles();

  return (
    <div>
      <ExpansionPanel
        expanded={expanded === 0}
        className={classes.accordian}
        onChange={handleExpandChange(0)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.addressAccordian}
        >
          <div style={{ display: 'contents' }}>
            <div className={classes.addressAccordianHeader}>
              <div className={classes.addressLocation}>
                <LocationCityIcon
                  className={classes.addressIcon}
                  fontSize='small'
                />
                <Typography className={classes.heading}>
                  Main Address
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ width: 'max-content', fontSize: '15px' }}>
                    {mainAddress.tier_level
                      ? `( ${mainAddress.tier_level} )`
                      : null}
                  </span>
                </div>
              </div>
              <div className={classes.addressLocation}>
                <ResponseCount
                  hide={hideCount}
                  contractor={mainAddress}
                  type={RESPONSE_COUNT_TYPE.WHOLE_COUNT}
                  addresses={addresses}
                />
                <ResponseCount
                  hide={hideCount}
                  contractor={mainAddress}
                  type={RESPONSE_COUNT_TYPE.INDV_WHOLE_COUNT}
                />
                <ResponseCount
                  hide={hideCount}
                  contractor={mainAddress}
                  type={RESPONSE_COUNT_TYPE.INDV_SEPARATE_COUNT}
                />
              </div>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <MainAddressForm
            {...mainAddress}
            register={register}
            isOrg={isOrg}
            isContractor={isContractor}
            hideCount={hideCount}
            showResponsePills={true}
            onChange={setMainAddressFormData}
          ></MainAddressForm>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {addresses.map((address, index) => {
        return (
          <ExpansionPanel
            key={index}
            className={classes.accordian}
            expanded={expanded === index + 1}
            onChange={handleExpandChange(index + 1)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.addressAccordian}
            >
              <div style={{ display: 'contents' }}>
                <div className={classes.addressAccordianHeader}>
                  <div className={classes.addressLocation}>
                    <LocationCityIcon
                      className={classes.addressIcon}
                      fontSize='small'
                    />
                    <Typography className={classes.heading}>
                      {!address.city || !address.state
                        ? 'New Location'
                        : `${address.city} , ${address.state} `}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ width: 'max-content', fontSize: '15px' }}>
                        {address.tier_level
                          ? `( ${address.tier_level} )`
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className={classes.addressLocation}>
                    <ResponseCount
                      hide={hideCount}
                      contractor={address}
                      type={RESPONSE_COUNT_TYPE.INDV_WHOLE_COUNT}
                    />

                    <ResponseCount
                      contractor={addresses[index]}
                      type={RESPONSE_COUNT_TYPE.INDV_SEPARATE_COUNT}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <EditAddressForm
                addressData={addresses}
                setAddressData={setAddresses}
                setAddress
                isOrg={isOrg}
                index={index}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
      <Grid item xs={12} lg={12}>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={() => handleNewAddress()}
        >
          Add another location
        </Button>
      </Grid>
    </div>
  );
};

AddressAccordian.prototype = {
  addresses: PropTypes.array.isRequired,
  setAddresses: PropTypes.func.isRequired,
  mainAddress: PropTypes.object,
  entity_id: PropTypes.string,
  entity_type: PropTypes.string,
  register: PropTypes.any,
};

export default AddressAccordian;
