import { all, put, takeLatest } from "redux-saga/effects";

import {
  getActionsSuccess,
  getActionsFail,
  createNewActionFail,
  createNewActionSuccess,
  editActionSuccess,
  editActionFail,
} from "../actionCreators/Action";
import { doGet, doPost, doPatch } from "../utils/request";

function* getActions() {
  try {
    const response = yield doGet(`/actions/actions`);
    yield put(getActionsSuccess(response.data.data));
  } catch (err) {
    yield put(getActionsFail());
  }
}

function* createNewAction({ obj }) {
  try {
    const response = yield doPost("/actions/action", obj);
    yield put(createNewActionSuccess(response.data.data));
  } catch (err) {
    yield put(createNewActionFail());
  }
}

function* editAction({ obj }) {
  try {
    const response = yield doPatch(`/actions/action/${obj.id}`, obj);
    yield put(editActionSuccess(response.data.data));
  } catch (err) {
    yield put(editActionFail());
  }
}

export default function* watcher() {
  yield all([takeLatest("GET_ACTIONS", getActions)]);
  yield all([takeLatest("CREATE_NEW_ACTION", createNewAction)]);
  yield all([takeLatest("EDIT_ACTION", editAction)]);
}
