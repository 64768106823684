import React from "react";

import * as downloadActions from "../actionCreators/Download";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles, Typography, Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { getPublicIp } from "../utils/dataProcessors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
    transition: "all 0.5s",
    borderRadius: "7px",
    border: "1px solid #0000000d",
    "&:hover": {
      transform: "scale(1.04)",
    },
  },
  image: {
    height: "80%",
    objectFit: "scale-down",
  },
  topBarOnHover: {
    visibility: "hidden",
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: "7px",
    transition: "all 0.5s",
    "&:hover": {
      background: "#0000004a",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hoverOverlay: {
    width: "32%",
    height: "25.5%",
    position: "absolute",
    borderRadius: "7px",
    transition: "all 0.5s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadButton: {
    color: "white",
    height: "50px",
    borderRadius: "7px",
    transition: "all 0.5s",
    border: "0.5px solid #ffffff78",
    "&:hover": {
      background: "#15151545",
    },
    zIndex: 2000,
  },
}));

const AttachmentThumnail = ({ note, attachment, downloadFile, currentSpill }) => {
  const classes = useStyles();
  const [hovered, setHovered] = React.useState(false);

  const slicer = (str) => {
    return str.length > 20 ? str.slice(0, 10) + "..." + str.slice(-4) : str;
  };
  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
      className={ hovered ? classes.hoverOverlay: classes.overlay}
      >
        {hovered && (
          <Button
            className={classes.downloadButton}
            title={`Download ${attachment.name} (${(attachment.size / 1024 >
            1024
              ? attachment.size / 1024 / 1024
              : attachment.size / 1024
            ).toFixed(2)} ${attachment.size / 1024 > 1024 ? "mb" : "kb"})`}
            startIcon={<CloudDownloadIcon />}
            onClick={async () =>
              downloadFile({
                key: attachment.key,
                spillId: currentSpill.data.spill.id,
                ipAddress: await getPublicIp(),
                note_service_id: note.service_id,
                attachment_id: attachment.id,
                attachment_created_at: attachment.created_at,
              })
            }
          >
            {slicer(attachment.name)}
          </Button>
        )}
      </div>
      <div className={` ${hovered && classes.topBarOnHover}`}>
        <Typography variant={"body2"}>{slicer(attachment.name)}</Typography>
      </div>
      <img
        className={classes.image}
        src={
          attachment.name.slice(-4) === ".pdf"
            ? "/pdfIcon.png"
            : attachment.signedUrl
        }
        alt={attachment?.name}
      />
    </div>
  );
};

const mapStateToProps = ({ download, spill }) => ({
  download,
  currentSpill: spill.currentSpill,
});

const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentThumnail);
