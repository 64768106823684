import MomentUtils from "@date-io/moment";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Alert from "@material-ui/lab/Alert";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { bindActionCreators } from 'redux';
import { TextBox } from "../../../../../Components";
import { CustomSelect } from "../../../../../Components/MultiLvlSelect";
import NoteAttachmentTable from "../../../../../Components/NoteAttachmentTable";
import * as spillActions from "../../../../../actionCreators/Spill";
import {
  Constants,
  convertServicesObj,
  getAttachmentTypes,
  getAttachmentsTypeCount,
  getTotalExcavationTime,
  orderAlphabatically,
  photoTypes,
  roundOff,
  timeConvertHHMMtoMins,
} from "../../../../../utils";
import AreaCoveredModal from "./AreaCoveredModal";
import SalvageContainer from "./SalvageContainer";
import FileUploader from "../../../../../Components/FileUploader";
import { EditNoteText as Text } from "../../../../../utils/language/english";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  alignRight: {
    textAlign: "right",
    paddingRight: "20px",
  },
  topMargin: {
    marginTop: "0px",
  },
  formControl: {
    minWidth: "150px",
    margin: "10px 5px 0 5px",
  },
  tableHead: {
    backgroundColor: "#eaeaea",
  },
  tableHeadText: {
    color: "#6ea16c",
    fontWeight: 800,
  },
}));

const CreateNote = ({
  closeNoteFrom,
  submitNote,
  Notes,
  currentUser,
  spillRate,
  currentSpill,
  setSelectedVideoFiles,
  checkSelectedServiceHandler,
  setOpenAreaCoverModal,
  openAreaCoverModal,
  newNoteCreation,
  noteWorkTimeValidation,
  noteWorkStartTimeValidation,
  executeScroll,
  noteWorkTimeValidationReset
}) => {
  const classes = useStyles();
  const [note, setNote] = useState({
    userId: currentUser?.data?.id,
    noteId: true,
    serviceId: "",
    serviceType: "",
    rate: "",
    hour: "",
    amount: "",
    description: "",
    reportNo: "",
    ipAddressIdentifier: "",
    owner: "",
    samples: "",
    cost: "",
    establishLaneClosure: "",
    excavationBegun: "",
    excavationCompleted: "",
    date: null,
    time: "",
    taskAssociatedRelevance: "",
    type: "",
    projected_eta: null,
    actual_eta: null,
    spill_id: null,
    id: null,
  });
  const [containers, setContainers] = useState([]);
  const [attachmentTypesCount, setAttachmentTypesCount] = useState(null);
  const [selectedService, setSelectedService] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [customPhotoType, setCustomPhotoType] = useState("");
  const [filesList, setFilesList] = useState([]);
  const [fileType, setFileType] = useState("");
  const [miscType, setMiscType] = useState("");
  const [photoType, setPhotoType] = useState("");
  const [releaseCoverages, SetReleaseCoverages] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [isNoteSubmit, setIsNoteSubmit] = useState(true)
  const [startTimeCopy, setStartTimeCopy] = useState(null);
  const [isButtonDisable, setIsButtonDisabled] = useState(false)
  const isNoteTimeWorkValidation = currentSpill?.data?.spill?.client_organization?.note_work_time_validation ?? false

  useEffect(() => {
    if (note?.serviceId) {
      const [service] = Notes.filter(
        (service) => service.id === note.serviceId
      );
      const serviceObject = {
        ...service,
        rate: service.type ? service.rate || spillRate : spillRate,
        type: service.type || "hourly",
      };
      checkSelectedServiceHandler(serviceObject);
      setSelectedService(serviceObject);
    }
  }, [note?.serviceId]);

  useEffect(() => {
    setIsButtonDisabled(noteWorkStartTimeValidation?.loading ?? false)
  }, [noteWorkStartTimeValidation?.loading])

  useEffect(() => {
    const attachedFilesNameList = [];
    currentSpill?.data?.spill?.spill_notes?.map((spillNote) =>
      spillNote?.note_attachments?.map((attachment) => {
        attachedFilesNameList.push(attachment.name);
      })
    );
    setFilesList([...attachedFilesNameList]);
    setAttachmentTypesCount(getAttachmentsTypeCount(currentSpill));
  }, [currentSpill, note?.hour]);

  useEffect(() => {
    setIsNoteSubmit(true);
    setErrorMessage(false);
    if (noteWorkStartTimeValidation?.error?.jobNo && selectedService?.type === "hourly") {
      const errorMesageToAppend = noteWorkStartTimeValidation?.error?.work_conflict ? "Please enter a later work start time in order to create the note." : "Please enter a lesser hour entry in order to create the note.";
      setIsNoteSubmit(false);
      setErrorMessage(`The selected time conflicts with job number ${noteWorkStartTimeValidation?.error?.jobNo}  and note ID ${noteWorkStartTimeValidation?.error?.noteId}, ${errorMesageToAppend}`);
    }
  }, [noteWorkStartTimeValidation?.error, selectedService]);
  
  
  const handleRemoveLocationData = (index) => {
    let tempContainers = [...releaseCoverages];
    tempContainers.splice(index, 1);
    for (index in tempContainers) {
      tempContainers[index].sc_no = parseInt(index) + 1;
    }
    SetReleaseCoverages(tempContainers);
  };
  const handleAreaClickOpen = () => {
    setOpenAreaCoverModal(true);
  };
  const handleAreaClose = () => {
    setOpenAreaCoverModal(false);
  };

  const StartDateTime = async (value) => {
    setErrorMessage(false)
    const selectedDateTime = value?._d?.getTime();
    // if the calender work start time is set to empty consider the system geenrated time based on hour
    if(!selectedDateTime) {
      const currentTime = new Date();
      const systemGenerateTime = new Date(currentTime.getTime() - note?.hour * 60 * 60 * 1000);
      setStartTime(null)
      setStartTimeCopy(moment(systemGenerateTime))
    } else if (selectedDateTime <= new Date().getTime()) {
      setStartTime(value);
      setStartTimeCopy(value)
    }
  };

  const handleNoteChange = (key) => (event) => {
    let fieldVal = event?.target?.value;

    if (key === "serviceId") {
      const service = Notes.find((obj) => obj.id === fieldVal);
      const rate = service?.type ? service.rate || spillRate : spillRate;
      const type = service?.type || "hourly";
      setNote((val) => ({
        ...val,
        rate: roundOff(rate, 2),
        type,
        amount: roundOff(type === "fixed" ? rate : rate * val.hour, 2),
        hour: roundOff(type === "fixed" ? 0 : val.hour || 0, 2),
      }));
    }
    if (
      key === "rate" ||
      key === "amount" ||
      key === "hour" ||
      key === "samples" ||
      key === "cost"
    ) {
      if (key === "hour" && fieldVal === ".") fieldVal = 0 + ".";
      if (isNaN(fieldVal)) return;
    }
    setNote((val) => ({ ...val, [key]: fieldVal }));

    if (key === "rate" && note?.hour) {
      setNote((val) => ({
        ...val,
        amount: roundOff(fieldVal * (note.type === "fixed" ? 1 : note.hour), 2),
        rate: roundOff(fieldVal, 2),
      }));
    }

    if (key === "hour" && note?.rate) {
      setNote((val) => ({
        ...val,
        amount: roundOff(note.rate * (+fieldVal).toFixed(2), 2),
        hour: roundOff(fieldVal, 2),
      }));
    }
  };

  const callvalidateNoteTime = (workStartDateTime) => {
    if (!validationCheck(workStartDateTime)) {
      const objectPayload = {
        work_start_time: moment(workStartDateTime)?.utc()?.format() || null,
        hour: note?.hour,
        user_id: currentSpill?.data?.spill?.user_id || null,
        org_id: currentSpill?.data?.spill?.org_id || null,
        note_id: null
      };
      noteWorkTimeValidation(objectPayload);
      setIsNoteSubmit(true);
    }
  }

  function validationCheck(workStartTime) {
    setErrorMessage(false);
    const spillOpenedOn = currentSpill?.data?.spill?.opened_on;
    const noteStartTime = moment(workStartTime)?.format();
    if (noteStartTime < spillOpenedOn) {
      setErrorMessage("You can not select note's start time before the spill's open time.");
      setIsNoteSubmit(false);
      setIsButtonDisabled(false);
      return true;
    }
    const expected_end_time = moment(workStartTime).add(note?.hour * 60, "minutes")?.format();
    if (new Date(expected_end_time) > new Date()) {
      setErrorMessage(`The end time you picked is in the future. Please enter a lesser hour entry in order to create the note.`);
      setIsNoteSubmit(false);
      setIsButtonDisabled(false);
      return true;
    }
    return false;
  }

  useEffect(() => {
    if(!isNoteSubmit) {
      executeScroll()
    } else if (isNoteSubmit && noteWorkStartTimeValidation?.success) {
      handleSubmitNote();
    }
  },[isNoteSubmit, noteWorkStartTimeValidation?.success])

  const handleSubmitNote = () => {
    if (selectedService?.name === Constants.EXCAVATION_COMPLETED_SERVICE_NAME) {
      note.excavation_time = getTotalExcavationTime(currentSpill?.data?.spill);
    }
    const videoAttachmentTypes = selectedFiles?.filter((file) =>
      file?.type?.includes("video")
    );
    setSelectedVideoFiles([...videoAttachmentTypes]);
    if (currentUser?.data?.role?.permission?.edit_own_notes) {
      if (note.description === "") {
        return;
      }
      const completeNote = {
        ...note,
        attachments: selectedFiles,
        type: "Custom Note",
      };
      submitNote(completeNote, selectedService?.email_notifications);
      noteWorkTimeValidationReset();
      closeNoteFrom(selectedService);
    } else {
      if (
        note.serviceId === "" ||
        note.rate === "" ||
        note.amount === "" ||
        note.description === "" ||
        !note.type
      ) {
        return;
      }

      let startTimeIfNull = null;

      // Cross check if start time is null
      if(!startTimeCopy) {
        const timeWhenCreateNote = new Date();
        const systemGeneratedTime = new Date(timeWhenCreateNote.getTime() - note?.hour * 60 * 60 * 1000);
        startTimeIfNull = systemGeneratedTime;
      }

      const completeNote = {
        ...note,
        containers: containers,
        serviceType: selectedService?.name,
        attachments: selectedFiles,
        projected_eta: selectedService?.projected_eta
          ? timeConvertHHMMtoMins(note?.projected_eta)
          : null,
        actual_eta: null,
        coverageAreaData: releaseCoverages,
        work_start_time: moment(startTimeCopy ?? startTimeIfNull)?.utc()?.format() || null,
      };
      submitNote(completeNote, selectedService?.email_notifications);
      noteWorkTimeValidationReset()
      closeNoteFrom(selectedService);
    }
  }

  const handleSubmit = () => {
    // calculate the system generated time again when creating note that will be consider as a actual work start time
    if(note?.date && moment(!note?.date)?._isValid) {
      return;
    }
    let systemGeneratedTime = null;
    if(!startTime || !startTimeCopy) {
      const timeWhenCreateNote = new Date();
      systemGeneratedTime = new Date(timeWhenCreateNote?.getTime() - note?.hour * 60 * 60 * 1000);
      setStartTimeCopy(systemGeneratedTime);
    }

    // call validate note time function if the client has selected validate note time checked
    if (isNoteTimeWorkValidation && selectedService && selectedService?.type === "hourly") {
      callvalidateNoteTime(startTime ?? systemGeneratedTime);
    } else {
      handleSubmitNote();
    }
  };

  const updateFileType = (type) => {
    switch (type) {
      case "Photo":
        return photoType;
      case "Custom":
        return customPhotoType;
      default:
        return type;
    }
  };

  const onFileChangeHandler = (event) => {
    let tempFiles = [...selectedFiles];
    let type = "";
    let fileName = "";

    const includeIncidentNo = [
      "Response Authorization",
      "Response Authorization Email",
      "Invoice & Report Package",
    ];

    const uniqueAttachment = [
      "Situational Analysis",
      "Invoice & Report Package",
    ];

    const tempFilesList = filesList;
    let totalFiles = [];
    for (let file of event?.target?.files) {
      type = updateFileType(fileType);

      attachmentTypesCount[type] = attachmentTypesCount[type] + 1;

      let ext = file.name.split(".");
      ext = ext[ext.length - 1];

      fileName = `${
        includeIncidentNo.includes(type)
          ? `${currentSpill.data.spill.job_no} `
          : ""
      }${type}${
        uniqueAttachment.includes(type)
          ? `-${attachmentTypesCount[type]}`
          : `-${attachmentTypesCount[type]}`
      }.${ext}`;

      let updatedFileName;
      if (
        (miscType && miscType !== "") ||
        (customPhotoType && customPhotoType !== "")
      ) {
        const parts = file?.name?.split(".");

        let newFileName = `${miscType || customPhotoType}.${
          parts[parts.length - 1]
        }`;

        newFileName = newFileName?.split(".")[0];

        if (tempFilesList.find((file) => file === newFileName)) {
          totalFiles = tempFilesList.filter((file) =>
            file.includes(miscType || customPhotoType)
          );

          newFileName = `${newFileName}-${totalFiles.length + 1}`;

          updatedFileName = `${newFileName}.${parts[parts.length - 1]}`;
          tempFilesList.push(updatedFileName);
        } else {
          updatedFileName = `${newFileName}.${parts[parts.length - 1]}`;
          tempFilesList.push(newFileName);
        }

        fileName = updatedFileName;
      }
      Object.defineProperty(file, "name", {
        writable: true,
        value: fileName,
      });
      tempFiles = [...tempFiles, file];
    }

    setFilesList(tempFilesList);

    setAttachmentTypesCount(attachmentTypesCount);
    setSelectedFiles(tempFiles);

    setMiscType("");
    setCustomPhotoType("");

    event.target.value = "";
  };

  const onFileCancelHandler = (index) => {
    let tempFile = [...selectedFiles];
    tempFile.splice(index, 1);
    setSelectedFiles(tempFile);
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            {errorMessage && (
              <Alert severity="error" className="error_snackbar" style={{minWidth:"100%"}}>
                {errorMessage}
              </Alert>
            )}
          </Grid>
        </Grid>
        <form
          style={{ width: "100%", margin: "30px, 20px" }}
        >
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            {/* {currentUser?.data?.role?.permission.edit_own_notes && ( */}
            <>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <InputLabel id="services_select_label">Service</InputLabel>
                <CustomSelect
                  options={convertServicesObj(Notes)}
                  onChange={handleNoteChange("serviceId")}
                  value={note?.serviceId ? note?.serviceId : ''}
                />
              </Grid>
              <>
                {isNoteTimeWorkValidation ? <Grid item xs={5} textAlign={"center"}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    maxDate={new Date()}
                    value={startTime}
                    onChange={StartDateTime}
                    label={"Work Start Time"}
                    format="MM-DD-YYYY hh:mm a"
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
              </Grid> : null }
                <Grid item xs={isNoteTimeWorkValidation ? 2 : 4}>
                  <TextField
                    id="rate"
                    name="rate"
                    label="Rate"
                    fullWidth
                    autoComplete="rate"
                    required
                    disabled={note.type === "fixed"}
                    inputProps={{
                      value: note?.rate,
                      min: 0,
                      step: 0.01,
                    }}
                    type={"number"}
                    onChange={handleNoteChange("rate")}
                  />
                </Grid>
                <Grid item xs={isNoteTimeWorkValidation ? 2 : 4}>
                  <TextField
                    id="hour"
                    name="hour"
                    label="Hour"
                    fullWidth
                    autoComplete="hour"
                    required
                    inputProps={{
                      value: note?.hour,
                      min: 0,
                      step: 0.01,
                    }}
                    type={"number"}
                    onChange={handleNoteChange("hour")}
                  />
                </Grid>
                <Grid item xs={isNoteTimeWorkValidation ? 3 : 4}>
                  <TextField
                    id="amount"
                    name="amount"
                    label="Amount"
                    fullWidth
                    autoComplete="amount"
                    required
                    inputProps={{
                      value: note?.amount,
                      min: 0,
                      step: 0.01,
                    }}
                    type={"number"}
                    onChange={handleNoteChange("amount")}
                    min={0}
                  />
                </Grid>
              </>
              {/* )} */}

              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  autoComplete="description"
                  required
                  multiline
                  inputProps={{
                    value: note?.description,
                  }}
                  onChange={handleNoteChange("description")}
                />
              </Grid>
              {selectedService && selectedService.report_number && (
                <Grid item xs={6}>
                  <TextField
                    id="reportNo"
                    name="reportNo"
                    label="Report #"
                    fullWidth
                    autoComplete="reportNo"
                    required
                    inputProps={{
                      value: note?.reportNo,
                    }}
                    onChange={handleNoteChange("reportNo")}
                  />
                </Grid>
              )}
              {selectedService && selectedService.state_incident_no && (
                <Grid item xs={6}>
                  <TextField
                    id="state_incident_no"
                    name="state_incident_no"
                    label="State Incident No"
                    fullWidth
                    autoComplete="state_incident_no"
                    required
                    inputProps={{
                      value: note.state_incident_no,
                    }}
                    onChange={handleNoteChange("state_incident_no")}
                  />
                </Grid>
              )}
              {selectedService && selectedService.national_incident_no && (
                <Grid item xs={6}>
                  <TextField
                    id="incident_no"
                    name="incident_no"
                    label="National Incident No"
                    fullWidth
                    autoComplete="incident_no"
                    required
                    inputProps={{
                      value: note?.incident_no,
                    }}
                    onChange={handleNoteChange("incident_no")}
                  />
                </Grid>
              )}
              {selectedService && selectedService.ip_address_identifier && (
                <Grid item xs={6}>
                  <TextField
                    id="ipAddressIdentifier"
                    name="ipAddressIdentifier"
                    label="IP Address Identifier"
                    fullWidth
                    autoComplete="ipAddressIdentifier"
                    required
                    inputProps={{
                      value: note?.ipAddressIdentifier,
                    }}
                    onChange={handleNoteChange("ipAddressIdentifier")}
                  />
                </Grid>
              )}
              {selectedService && selectedService.owner && (
                <Grid item xs={6}>
                  <TextField
                    id="owner"
                    name="owner"
                    label="Owner"
                    fullWidth
                    autoComplete="owner"
                    required
                    inputProps={{
                      value: note?.owner,
                    }}
                    onChange={handleNoteChange("owner")}
                  />
                </Grid>
              )}
              {selectedService && selectedService.salvage_containers && (
                <Grid item xs={12}>
                  <SalvageContainer
                    containers={containers}
                    setContainers={setContainers}
                  />
                </Grid>
              )}
              {selectedService && selectedService.samples && (
                <Grid item xs={6}>
                  <TextField
                    id="samples"
                    name="samples"
                    label="No of Samples"
                    fullWidth
                    autoComplete="samples"
                    required
                    inputProps={{
                      value: note?.samples,
                    }}
                    onChange={handleNoteChange("samples")}
                  />
                </Grid>
              )}
              {selectedService &&
                selectedService.cost &&
                (selectedService.name === "Schedule Excavation" ? (
                  <Grid item xs={6}>
                    <TextField
                      id="cost"
                      name="cost"
                      label="Project Cost"
                      fullWidth
                      autoComplete="cost"
                      required
                      inputProps={{
                        value: note?.cost,
                      }}
                      onChange={handleNoteChange("cost")}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <TextField
                      id="cost"
                      name="cost"
                      label="Estimated Cost"
                      fullWidth
                      autoComplete="cost"
                      required
                      inputProps={{
                        value: note?.cost,
                      }}
                      onChange={handleNoteChange("cost")}
                    />
                  </Grid>
                ))}
              {selectedService && selectedService.establish_lane_closure && (
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <InputLabel id="establishLaneClosure_select_label">
                    Lane Closure Established
                  </InputLabel>
                  <Select
                    labelId="establishLaneClosure_select_label"
                    id="establishLaneClosure_select"
                    name="establishLaneClosure"
                    style={{ width: "100%" }}
                    inputProps={{
                      value: note?.establishLaneClosure,
                    }}
                    onChange={handleNoteChange("establishLaneClosure")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </Grid>
              )}
              {selectedService?.name ===
                Constants.EXCAVATION_COMPLETED_SERVICE_NAME && (
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <TextBox
                    text={getTotalExcavationTime(currentSpill?.data?.spill)}
                    heading="Total Time"
                  />
                </Grid>
              )}
              {selectedService && selectedService.excavation_begun && (
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <InputLabel id="excavationBegun_select_label">
                    Excavation Begun
                  </InputLabel>
                  <Select
                    labelId="excavationBegun_select_label"
                    id="excavationBegun_select"
                    name="excavationBegun"
                    style={{ width: "100%" }}
                    inputProps={{
                      value: note?.excavationBegun,
                    }}
                    onChange={handleNoteChange("excavationBegun")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </Grid>
              )}
              {selectedService && selectedService.excavation_completed && (
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <InputLabel id="excavationCompleted_select_label">
                    Excavation Completed
                  </InputLabel>
                  <Select
                    labelId="excavationCompleted_select_label"
                    id="excavationCompleted_select"
                    name="excavationCompleted"
                    style={{ width: "100%" }}
                    inputProps={{
                      value: note?.excavationCompleted,
                    }}
                    onChange={handleNoteChange("excavationCompleted")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </Grid>
              )}
              {selectedService && selectedService.task_associated_relevance && (
                <Grid item xs={12}>
                  <TextField
                    id="taskAssociatedRelevance"
                    name="taskAssociatedRelevance"
                    label="Task Associated Relevance"
                    fullWidth
                    autoComplete="taskAssociatedRelevance"
                    required
                    inputProps={{
                      value: note?.taskAssociatedRelevance,
                    }}
                    onChange={handleNoteChange("taskAssociatedRelevance")}
                  />
                </Grid>
              )}
              {selectedService && selectedService.time && (
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      variant="inline"
                      margin="normal"
                      id="time_picker_inline"
                      label="Time"
                      inputProps={{
                        value: note?.time,
                      }}
                      onChange={(time) =>
                        setNote((val) => ({
                          ...val,
                          ["time"]: time?.format("hh:mmA"),
                        }))
                      }
                      KeyboardButtonProps={{
                        aria_label: "change time",
                      }}
                    />
                    <Button
                      style={{ textAlign: "center" }}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setNote((val) => ({ ...val, ["time"]: "" }))
                      }
                    >
                      Clear
                    </Button>
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              {selectedService && selectedService.date && (
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM-DD-YYYY"
                        margin="normal"
                        id="date_picker_inline"
                        // label="Scheduled Date"
                        label={selectedService?.name === "Schedule Excavation" ? Text.scheduleDateLabel : Text.dateLabel}
                        className={classes.topMargin}
                        onChange={(date) => {
                          setNote((val) => ({ ...val, date: date }));
                        }}
                        value={note?.date || null}
                        KeyboardButtonProps={{
                          aria_label: "change date",
                        }}
                      />
                    <Button
                      style={{ textAlign: "center" }}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setNote((val) => ({ ...val, ["date"]: "" }))
                      }
                    >
                      Clear
                    </Button>
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            </>
            {/* )} */}
            {((selectedService && selectedService?.upload_attachment) ||
              currentUser?.data?.role?.permission?.edit_own_notes) && (
              <Grid container>
                <Grid item xs={12}>
                  <NoteAttachmentTable
                    files={[]}
                    preview={selectedFiles}
                    handleCancel={onFileCancelHandler}
                  ></NoteAttachmentTable>
                </Grid>
                {selectedService?.no_release == false ? (
                  <>
                    <Grid
                      item
                      xs={6}
                      className={classes.alignLeft}
                      style={{ alignSelf: "end" }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.button}
                        onClick={handleAreaClickOpen}
                      >
                        Area Covered By Release
                      </Button>
                    </Grid>
                    {releaseCoverages?.length > 0 && (
                      <>
                      <TableContainer
                        className={classes.bottomSpacing}
                        component={Paper}
                        style={{ margin: "30px 0" }}
                      >
                        <Table>
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              <TableCell className={classes.tableHeadText}>
                               Type
                              </TableCell>
                              <TableCell className={classes.tableHeadText}>
                                Surface
                              </TableCell>
                              <TableCell className={classes.tableHeadText}>
                                Length
                              </TableCell>
                              <TableCell className={classes.tableHeadText}>
                                Width
                              </TableCell>
                              <TableCell className={classes.tableHeadText}>
                                Diameter
                              </TableCell>
                              <TableCell className={classes.tableHeadText}>
                                Result
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {releaseCoverages.map((item, index) => (
                              <>
                              <TableRow key={index}>
                                <TableCell className="table-td-head" >{item.footprint_type}</TableCell>
                                <TableCell className="table-td-head">{item.surface}</TableCell>
                                <TableCell>
                                  {item?.height || "-"}
                                </TableCell>
                                <TableCell>{item.width || "-"}</TableCell>
                                <TableCell>{item.diameter || "-"}</TableCell>
                                <TableCell>{item.covered_area || "-"}</TableCell>
                                <TableCell>
                                  <CloseIcon
                                    onClick={() =>
                                      handleRemoveLocationData(index)
                                    }
                                    width="10px"
                                    style={{ cursor: "pointer" }}
                                  />
                                </TableCell>
                              </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      </>
                    )}
                  </>
                ) : null}
                <Grid item xs={12} className={classes.alignRight}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="form-control-file-type">
                      Attachment Type
                    </InputLabel>
                    <Select
                      labelId="select-file-type-label"
                      id="select-file-type"
                      value={fileType}
                      onChange={(event) => setFileType(event?.target?.value)}
                    >
                      <MenuItem value="">
                        <em>Select Attachment Type</em>
                      </MenuItem>
                      {orderAlphabatically(
                        getAttachmentTypes(selectedService?.name)
                      ).map((val, index) => (
                        <MenuItem key={index} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {fileType === "Misc" ? (
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="miscType"
                        name="miscType"
                        label="Misc Type"
                        autoComplete="miscType"
                        onChange={(event) =>
                          setMiscType(`${event.target.value}`)
                        }
                      />
                    </FormControl>
                  ) : null}

                  {fileType === "Photo" ? (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="form-control-file-type">
                        Photo Type
                      </InputLabel>
                      <Select
                        labelId="select-file-type-label"
                        id="select-photo-type"
                        value={photoType}
                        disabled={fileType !== "Photo"}
                        onChange={(event) => setPhotoType(event.target.value)}
                      >
                        <MenuItem value="">
                          <em>Select Photo Type</em>
                        </MenuItem>
                        {orderAlphabatically(photoTypes).map((val, index) => (
                          <MenuItem key={index} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                  {fileType === "Photo" && photoType === "Custom" ? (
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="customPhotoType"
                        name="customPhotoType"
                        label="Custom Photo Type"
                        autoComplete="customPhotoType"
                        onChange={(event) =>
                          setCustomPhotoType(`${event.target.value}`)
                        }
                      />
                    </FormControl>
                  ) : null}
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                      className={classes.button}
                      disabled={
                        !fileType || (fileType === "Photo" && !photoType)
                      }
                    >
                      + Select Files
                      <input
                        type="file"
                        accept={
                          fileType === "Video"
                          ? "video/mp4, video/x-m4v, video/*"
                          : ".jpg, .png, .jpeg, .TIF, .pdf, .doc, .docx, .xls, .xlsx"
                        }
                        onChange={onFileChangeHandler}
                        hidden
                        multiple
                      />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
            {selectedService &&
              (selectedService.projected_eta || selectedService.actual_eta) && (
                <Grid item xs={12} textAlign={"center"}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      ampm={false}
                      keyboardIcon={null}
                      disableIgnoringDatePartForTimeValidation
                      disableOpenPicker
                      disablePast={
                        selectedService?.projected_eta ? true : false
                      }
                      disabled={selectedService?.actual_eta}
                      error={false}
                      helperText={
                        selectedService?.actual_eta &&
                        (note?.actual_eta === null ||
                          note?.actual_eta === undefined)
                          ? "Actual ETA will be calculated once the contractor has arrived on site"
                          : null
                      }
                      variant="inline"
                      placeholder="HH:MM"
                      label={
                        selectedService?.projected_eta
                          ? "Projected ETA"
                          : "Actual ETA"
                      }
                      mask="__:__"
                      views={["hours", "minutes"]}
                      format="HH:mm"
                      value={
                        selectedService?.projected_eta
                          ? note?.projected_eta
                          : note?.actual_eta
                      }
                      onChange={(time) =>
                        setNote((val) => ({
                          ...val,
                          [selectedService?.projected_eta
                            ? "projected_eta"
                            : "actual_eta"]: time && Object.entries(time)[1][1],
                        }))
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="button"
                onClick={() => handleSubmit()}
                disabled={isButtonDisable}
              >
                Create Note
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  closeNoteFrom(true);
                  noteWorkTimeValidationReset()
                  setErrorMessage(false);
                }}
                >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>

      <Dialog
        open={openAreaCoverModal}
        onClose={handleAreaClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="area-covered-modal "
      >
        <div className="area-covered-modal-header">
          <div>
            <DialogTitle id="alert-dialog-title">
              Basic Footprint Type
            </DialogTitle>
          </div>
          <div onClick={handleAreaClose} className="close-icon">
            <CloseIcon></CloseIcon>
          </div>
        </div>
        <DialogContent className="CustomScrollbar">
          <AreaCoveredModal
            handleClose={handleAreaClose}
            SetReleaseCoverages={SetReleaseCoverages}
            releaseCoverages={releaseCoverages}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  spill: { currentSpill, newNoteCreation, noteWorkStartTimeValidation },
  user,
}) => ({
  currentUser: user.currentUser,
  currentSpill,
  newNoteCreation,
  noteWorkStartTimeValidation
});

const mapDispatchToProps = (dispatch) => ({
  noteWorkTimeValidation: bindActionCreators(spillActions.noteWorkTimeValidation, dispatch),
  noteWorkTimeValidationReset: bindActionCreators(spillActions.noteWorkTimeValidationReset, dispatch),
});

CreateNote.proptype = {
  closeNoteFrom: PropTypes.func.isRequired,
  submitNote: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentSpill: PropTypes.object.isRequired,
  newNoteCreation:PropTypes.object.isRequired,
  noteWorkTimeValidationReset: PropTypes?.func?.isRequired,
};


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateNote)
);
