import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { green } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import CardContent from "@material-ui/core/CardContent";
import {
  AppBar,
  Divider,
  List,
  ListItem,
  Tabs,
  Tab,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { CustomSnackBar, MainAddressForm } from "../../../../../Components";
import * as clientActions from "../../../../../actionCreators/Client";
import { COUNTRIES, orderAlphabaticallyByKey, ROLES } from "../../../../../utils";
import Facilities from "./Facilities";
import ContractorClient from "./ContractorClient";
import SpecialInstruction from "./SpecialInstruction";
import Alert from "@material-ui/lab/Alert/Alert";
import TeamTerminal from "./TeamTerminal";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  leftAlign: {
    textAlign: "left",
  },
  serviceLabel: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    textTransform: "capitalize",
    padding: 0,
  },
  labelColor: {
    color: "#7F7F7F",
  },
}));

const NewClient = ({
  userRole,
  createNewClient,
  closeAddClientModal,
  clients,
  servicesData,
  salePersonsData,
  getOrganizationNames,
  clientObj,
  deleteFacility,
  getIcsTerminal,
}) => {
  const classes = useStyles();

  const [rate, setRate] = React.useState("");
  const [associatedClients, setAssociatedClients] = React.useState([]);
  const [associatedServices, setAssociatedServices] = React.useState([]);
  const [salePersons, setSalePersons] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [errorText, setErrorText] = React.useState("");
  const [associatedClientOptions, setAssociatedClientOptions] = React.useState(
    []
  );
  const [canSeeTimestamp, setCanSeeTimestamp] = React.useState(true);
  const [canSeeMonetary, setCanSeeMonetary] = React.useState(true);
  const [reqSubmitted, setReqSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [checked, setIcsChecked] = useState();
  const [validateNoteTimeChecked, setValidateNoteTimeChecked] = useState(false)
  const [inputValues, setInputValues] = useState({});
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [specialInstructionsData, setSpecialInstructionsData] = useState({
    hazardous_waste: null,
    non_hazardous_waste: null,
    reporting_instruction: null,
    instruction_5800: null,
    miscellaneous: null,
    revision_date: null,
    reporting: null,
    report_5800: null,
  });
  const [facilities, setSelectedFacilitiesData] = useState([
    {
      name: null,
      internal_id: null,
      generator_status: null,
      epa_id: null,
      address: null,
      city: null,
      state: null,
      country: COUNTRIES?.USA,
      // country: null,
      zip_code: null,
      phone_number: null,
      region: null,
    },
  ]);
  const [mainAddressFormData, setMainAddressFormData] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
  });
  const [selectedDate, setSelectedDate] = useState(
    moment()?.format("YYYY-MM-DD")
  );
  const [updatedContractorData, setUpdatedContractorData] = useState([]);
  const [file, setFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [activeeSelect, setActiveSelect] = useState(true);
  const [fileUpload, setFileUpload] = useState(true);
  const [isNotICS, setIsNotICS] = useState(false);
  const [totalTerminals, setTotalTerminals] = React.useState(0);
  const [isButtonDisable, setIsButtonDisabled] = useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const dispatch = useDispatch();
  const TeamData = useSelector(
    (state) => {
      if (clientObj?.clientTerminal?.data && !clientObj?.clientTerminal?.data.hasOwnProperty('is_ics_client')) {
          return clientObj?.clientTerminal?.data;
      } else {
        return {};
      }
    }
  );
  React.useEffect(() => {
    if (clientObj?.type === 'GET_ICS_TERMINAL_SUCCESS' && selectedTab === 1 && checked && clientObj?.clientTerminal?.data?.is_ics_client === false && inputValues?.name) {
      setErrorMessage(clientObj?.clientTerminal?.data?.message);
      setFileUpload(false);
      setFile('');
    }
    if(clientObj?.type === 'GET_ICS_TERMINAL_FAILURE' && selectedTab === 1 && checked)
    {
      setErrorMessage(clientObj?.error);
      setIsNotICS(true)
    }
  }, [clientObj?.type, clientObj?.clientTerminal?.data?.is_ics_client, checked , selectedTab, inputValues?.name]);

  React.useEffect(() => {
    if (checked && inputValues.name && selectedTab == 1) {
      setErrorMessage ('');
      setFileUpload(true);
      getIcsTerminal({ client_name: inputValues?.name });
      setTotalTerminals();
    }
  }, [checked, inputValues?.name, selectedTab]);

  React.useEffect(() => {
    getOrganizationNames();
  }, []);

  React.useEffect(() => {
    if (servicesData?.services?.data) {
      const data = servicesData?.services?.data;

      setServices(data.filter((x) => x.active));
    }
  }, [servicesData]);

  React.useEffect(() => {
    if (salePersonsData?.data) {
      const data = salePersonsData?.data;

      setSalePersons(data);
    }
  }, [salePersonsData]);

  React.useEffect(() => {
    if (services) {
      let data = [];

      services.map((service) => {
        if (service.is_default) {
          const value = {
            value: service.id,
            label: service.name,
            type: "",
            rate: "",
          };
          data.push(value);
        }
      });

      setAssociatedServices(data);
    }
  }, [services]);

  React.useEffect(() => {
    let temp = clients?.data?.map((client) => {
      const value = {
        value: client.id,
        label: client.name,
        active: client.active,
      };
      const allOptions = { ...value };
      return allOptions;
    });
    setAssociatedClientOptions(temp.filter((x) => x.active));
  }, [clients]);

  const clientServicesOptions = services.map((service) => {
    const value = {
      value: service.id,
      label: service.name,
      type: "",
      rate: "",
    };
    const allOptions = { ...value };

    return allOptions;
  });

  const handleSalesPersonChange = (event) => {
    setSelectedSalesPerson(event.target.value);
  };

  const handleActiveChange = (event) => {
    setActiveSelect(event.target.value);
  };

  const validate = () => {
    if (
      (inputValues?.paymentTerms > 1 && inputValues?.paymentTerms < 100) ||
      inputValues?.paymentTerms === ""
    ) {
      return [true];
    } else {
      return [false, "Value must be between 2 and 99"];
    }
  };

  const handleServicesSelect = async (data) => {
    setAssociatedServices(data);
  };

  const handleServiceChange = (id, key) => (event) => {
    let newAssociatedServices = [...associatedServices];

    let data = newAssociatedServices.findIndex((obj) => obj.value === id);

    newAssociatedServices[data][key] = event.target.value;

    if (key === "type") {
      if (event?.target?.value === "hourly") {
        newAssociatedServices[data]["rate"] =
          rate || newAssociatedServices[data]["rate"];
      } else {
        newAssociatedServices[data]["rate"] =
          newAssociatedServices[data]["rate"] || rate;
      }
    }

    newAssociatedServices[data]["type"] =
      newAssociatedServices[data]["type"] || "hourly";
    setAssociatedServices(newAssociatedServices);
  };

  const handleRateChange = (event) => {
    setRate(event?.target?.value);
    setAssociatedServices((val) =>
      val?.map((service) => {
        return {
          ...service,
          rate: service?.type ? service?.rate : event?.target?.value,
        };
      })
    );
  };

  const handleIcsChange = (event) => {
    setIcsChecked(event.target.checked);
    setFile("");
  };

 
  // Function to hide the error message
  const hideError = () => {
    setErrorMessage("");
  };

  const validateForm = () => {
    const errors = {};
    if (!inputValues?.name || inputValues?.name?.trim() === "") {
      errors.name = "- Client Name should not be blank.";
    }
    if (!inputValues.tax_id || inputValues.tax_id?.trim() === "") {
      errors.taxId = "- Tax Id should not be blank.";
    }
    if (!inputValues.code || inputValues.code?.trim() === "") {
      errors.code = "- Code should not be blank.";
    }
    if (inputValues?.code) {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (inputValues?.code?.trim()?.match(regEx) === null) {
        errors.code = "- Code: Please enter letters and numbers only.";
      }
    }
    if (!rate || rate?.trim() === "") {
      errors.rate = "- Rate should not be blank.";
    }
    if (!inputValues.paymentTerms || inputValues.paymentTerms?.trim() === "") {
      errors.paymentTerms = "- Payment Terms should not be blank.";
    }
    if (!selectedSalesPerson) {
      errors.selectedSalesPerson = "- Salesperson should not be blank.";
    }
    const facilityName = facilities?.find(
      (item) =>
        !item?.name?.trim() &&
        (item?.internal_id ||
          item?.generator_status ||
          item?.epa_id ||
          item?.phone_number ||
          item?.address ||
          item?.region ||
          item?.state ||
          item?.city ||
          item?.zip_code ||
          item?.is_active)
    );
    const facilityAddress = facilities?.find((item) => item?.name && !item?.address?.trim());
    if (facilityAddress) {
      errors.facilityAddress = "- Facility Address should not be blank.";
    }
    const facilityCity = facilities?.find((item) => item?.name && !item?.city?.trim());
    if (facilityCity) {
      errors.facilityCity = "- Facility City should not be blank.";
    }
    const facilityZipCode = facilities?.find((item) => item?.name && !item?.zip_code?.trim());
    if (facilityZipCode) {
      errors.facilityZipCode = "- Facility ZipCode should not be blank.";
    }
    if (facilityName) {
      errors.facilityName = "- Facility Name should not be blank.";
    }
    const combinedMessage = [
      errors.name || "",
      errors.taxId || "",
      errors.code || "",
      errors.selectedSalesPerson || "",
      errors.rate || "",
      errors.paymentTerms || "",
      errors?.facilityName || "",
      errors?.facilityAddress || "",
      errors?.facilityCity || "",
      errors?.facilityZipCode || "",
    ]
      .filter((message) => message !== "")
      .join("\n");
    if (combinedMessage) {
      return combinedMessage;
    }
    const result = validate();
    if (!result[0]) {
      return "- Payment Terms Value must be between 2 and 99";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors) {
      setErrorMessage(errors);
    } else {
      setIsButtonDisabled(true);
      const updatedContractorsToSend = updatedContractorData.map(
        (contractor) => ({
          contractor_id: contractor?.contractor_id,
          address_id: contractor?.addressId || null,
        })
      );
      const modifiedData = {
        hazardous_waste: specialInstructionsData?.hazardous_waste,
        non_hazardous_waste: specialInstructionsData?.non_hazardous_waste,
      }
      const hasValue = Object.values(modifiedData).filter((item) => item);
      let obj = {
        name: inputValues.name && inputValues?.name?.trim() !== "" ? inputValues.name.trim() : "",
        is_ics_client: checked ? true : false,
        note_work_time_validation: validateNoteTimeChecked ?? false,
        sales_person: selectedSalesPerson ? selectedSalesPerson : null,
        tax_id: inputValues.tax_id ? inputValues.tax_id : "",
        code: inputValues.code ? inputValues.code?.trim() : "",
        active: activeeSelect ? activeeSelect : false,
        rate: rate,
        paymentTerms: inputValues?.paymentTerms || "",
        associatedClients,
        associatedServices: associatedServices.map((service) => ({
          ...service,
          type: service.type || "hourly",
          rate: service.rate || rate,
        })),
        timestampVisibility: canSeeTimestamp,
        monetaryVisibility: canSeeMonetary,
        ...specialInstructionsData,
        facilities,
        contractors: updatedContractorsToSend,
        ...mainAddressFormData,
        revision_date: hasValue.length ? moment(selectedDate)?.format("YYYY-MM-DD") : null
      };
      const formData = new FormData();
      if (file) {
        formData.append("file", file, `client_facility#${file.name}`);
      }
      formData.append("clientRequest", JSON.stringify(obj));
      createNewClient(formData);
      setReqSubmitted(true);
    }
  };

  React.useEffect(() => {
    if (!clientObj.loading && reqSubmitted) {
      if (!clientObj.error) {
        closeAddClientModal(file && file.name);
      } else {
        setErrorMessage(clientObj.error);
      }
      setReqSubmitted(false);
      setIsButtonDisabled(false);
    }
  }, [clientObj.loading]);

  return (
    <React.Fragment>
      <div>
        <CustomSnackBar
          open={!!errorMessage}
          severity="error"
          snackBarMessage={errorMessage}
          onClose={hideError}
        />
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setIsSnackbarOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            severity="error"
            onClose={() => setIsSnackbarOpen(false)}
            className="errorFacilityMessage"
          >
            Facilities names should not be same.
          </Alert>
        </Snackbar>
        <AppBar position="static" color="primary">
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="New Client" />
            {userRole == ROLES.SUPER_USER && (
              <Tab label="Facilities" />
            )}
            {userRole == ROLES.SUPER_USER && <Tab label="Contractor" />}
            {userRole == ROLES.SUPER_USER && (
              <Tab label="Special Instructions" />
            )}
          </Tabs>
        </AppBar>
        <div className={classes.paper}>
          <form onSubmit={handleSubmit} style={{
            width: "100%",
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
          }}>
            <div className={selectedTab === 0 ? "" : "hidden"}>
              <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid
                        className={classes.nameFieldWrapper}
                        container
                        spacing={3}
                      >
                        <Grid item xs={5}>
                          <TextField
                            id="name"
                            name="name"
                            label="Name *"
                            fullWidth
                            autoComplete="name"
                            onChange={handleInputChange}
                            value={inputValues.name || ""}
                          />
                        </Grid>
                        {userRole === ROLES.SUPER_USER ? (
                        <>
                          <Grid item xs={3} className={classes.leftAlign}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="is_ics_client"
                                  name="is_ics_client"
                                  checked={checked}
                                  onChange={handleIcsChange}
                                  className={classes.labelColor}
                                />
                              }
                              label="is ICS Client?"
                            />
                          </Grid>
                          <Grid item xs={4} className={classes.leftAlign}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="note_work_time_validation"
                                  name="note_work_time_validation"
                                  checked={validateNoteTimeChecked}
                                  onChange={(event) => setValidateNoteTimeChecked(event?.target?.checked)}
                                  className={classes.labelColor}
                                />
                              }
                              label="Validate Note Time"
                            />
                          </Grid>
                        </>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      <Typography
                        variant="subtitle"
                        gutterBottom
                        className={classes.labelColor}
                      >
                        Child Organizations
                      </Typography>
                      <ReactSelect
                        value={associatedClients}
                        isMulti
                        onChange={setAssociatedClients}
                        options={orderAlphabaticallyByKey(
                          associatedClientOptions
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="taxId"
                        name="tax_id"
                        label="Tax Id *"
                        fullWidth
                        autoComplete="tax_id"
                        onChange={handleInputChange}
                        value={inputValues.tax_id || ""}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="code"
                        name="code"
                        label="Code *"
                        fullWidth
                        autoComplete="code"
                        onChange={handleInputChange}
                        value={inputValues.code || ""}
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.leftAlign}>
                      <InputLabel
                        id="salesPerson-select-label"
                        className={classes.labelColor}
                      >
                        Salesperson
                      </InputLabel>
                      <Select
                        labelId="salesPerson-select-label"
                        id="salesPerson-select"
                        name="sales_person"
                        style={{ width: "100%" }}
                        onChange={(event) => {
                          handleSalesPersonChange(event);
                        }}
                        value={selectedSalesPerson}
                      >
                        {salePersons &&
                          orderAlphabaticallyByKey(
                            salePersons,
                            "full_name"
                          ).map((e) => {
                            return (
                              <MenuItem
                                value={e.id}
                              >{`${e.full_name} (${e.client_organization.name}: ${e.role.role})`}</MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="rate"
                        name="rate"
                        label="Rate"
                        fullWidth
                        autoComplete="rate"
                        onChange={handleRateChange}
                        value={rate}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="paymentTerms"
                        name="paymentTerms"
                        label="Payment Terms"
                        onChange={handleInputChange}
                        value={inputValues.paymentTerms || ""}
                        onBlur={() => {
                          const result = validate();
                          if (result[0]) {
                            setErrorText("");
                          } else {
                            setErrorText(result[1]);
                          }
                        }}
                        error={errorText ? true : false}
                        helperText={errorText || ""}
                        fullWidth
                        autoComplete="paymentTerms"
                      />
                    </Grid>

                    {userRole === ROLES.SUPER_USER ? (
                      <>
                        <Grid item xs={4} className={classes.leftAlign}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={canSeeMonetary}
                                onChange={(event) =>
                                  setCanSeeMonetary(event.target.checked)
                                }
                                name="monetaryAmountsVisibility"
                                color="primary"
                              />
                            }
                            label="View Monetary Amounts"
                          />
                        </Grid>
                        <Grid item xs={8} className={classes.leftAlign}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={canSeeTimestamp}
                                onChange={(event) =>
                                  setCanSeeTimestamp(event.target.checked)
                                }
                                name="timeStampVisibile"
                                color="primary"
                              />
                            }
                            label="View Timestamps"
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.leftAlign}>
                          <Typography
                            variant="subtitle"
                            gutterBottom
                            className={classes.labelColor}
                          >
                            Services
                          </Typography>
                          <ReactSelect
                            value={associatedServices}
                            isMulti
                            onChange={(data) => handleServicesSelect(data)}
                            options={orderAlphabaticallyByKey(
                              clientServicesOptions
                            )}
                          />
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={12}>
                      {associatedServices?.length > 0 ? (
                        <div>
                          <div>
                            <Card
                              className={classes.headerCard}
                              style={{
                                margin: "15px 0px 0px 0px",
                                width: "100%",
                              }}
                            >
                              <CardContent
                                style={{
                                  padding: "10px",
                                  backgroundColor: "#e6e6e6d6",
                                  fontWeight: 600,
                                  color: "#6aa16c",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                ></div>
                                <Grid container spacing={3}>
                                  <Grid item xs={6}>
                                    <div className={classes.leftAlign}>
                                      Service Name
                                    </div>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <div className={classes.leftAlign}>
                                      Service Type
                                    </div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div className={classes.leftAlign}>
                                      Rate
                                    </div>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                            {associatedServices &&
                              associatedServices.map((service, i) => (
                                <List
                                  component="nav"
                                  className={classes.root}
                                  aria-label="mailbox folders"
                                >
                                  <ListItem>
                                    <Grid container spacing={3}>
                                      <Grid
                                        item
                                        xs={6}
                                        className={classes.serviceLabel}
                                      >
                                        <div className={classes.leftAlign}>
                                          <span>{service.label}</span>
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className={classes.leftAlign}>
                                          {
                                            <Select
                                              labelId="rate-type-select-label"
                                              id="rate-type-select"
                                              name="rateType"
                                              style={{ width: "100%" }}
                                              defaultValue={
                                                service?.type || "hourly"
                                              }
                                              onChange={handleServiceChange(
                                                service.value,
                                                "type"
                                              )}
                                            >
                                              <MenuItem value={"fixed"}>
                                                Fixed
                                              </MenuItem>
                                              <MenuItem value={"hourly"}>
                                                Hourly
                                              </MenuItem>
                                            </Select>
                                          }
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className={classes.leftAlign}>
                                          {
                                            <TextField
                                              id="rate"
                                              name="rate"
                                              type="number"
                                              fullWidth
                                              autoComplete="rate"
                                              value={
                                                associatedServices[i].type
                                                  ? associatedServices[i].rate
                                                  : rate
                                              }
                                              onChange={handleServiceChange(
                                                service.value,
                                                "rate"
                                              )}
                                            />
                                          }
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                  <Divider />
                                </List>
                              ))}
                          </div>
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={6} className={classes.leftAlign}>
                      <InputLabel
                        id="active-select-label"
                        className={classes.labelColor}
                      >
                        Active *
                      </InputLabel>
                      <Select
                        labelId="active-select-label"
                        id="active-select"
                        name="active"
                        style={{ width: "100%" }}
                        defaultValue={true}
                        onChange={(event) => {
                          handleActiveChange(event);
                        }}
                        value={activeeSelect}
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </Grid>

                    <Grid item xs={12}>
                      <MainAddressForm
                        isOrg={true}
                        showResponsePills={true}
                        mainAddformData={mainAddressFormData}
                        onChange={setMainAddressFormData}
                      ></MainAddressForm>
                    </Grid>
                  </Grid>
              </>
            </div>
            <div className={selectedTab === 1 ? "" : "hidden"}>
              {checked ? (
                <TeamTerminal
                  file={file}
                  setFile={setFile}
                  TeamData={TeamData}
                  fileUpload={fileUpload}
                  isNotICS={isNotICS}
                  totalTerminals={totalTerminals}
                  setTotalTerminals={setTotalTerminals}
                />
              ) : (
                <Facilities
                  setOpen={setOpen}
                  open={open}
                  file={file}
                  setFile={setFile}
                  facilitiesFormData={facilities}
                  editMode={false}
                  onChange={setSelectedFacilitiesData}
                />
              )}
            </div>
            <div className={selectedTab === 2 ? "" : "hidden"}>
              <ContractorClient
                updatedContractorData={updatedContractorData}
                setUpdatedContractorData={setUpdatedContractorData}
                onChange={setUpdatedContractorData}
              />
            </div>
            <div className={selectedTab === 3 ? "" : "hidden"}>
              <SpecialInstruction
                formData={specialInstructionsData}
                onChange={setSpecialInstructionsData}
                selectedDate={selectedDate}
                editMode={false}
                onDateChange={handleDateChange}
              />
            </div>

            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isButtonDisable}
              >
                Create
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => closeAddClientModal()}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ client }) => ({
  clients: client.clientOrganizationNames,
  clientObj: client,
});

const mapDispatchToProps = (dispatch) => ({
  createNewClient: bindActionCreators(clientActions.createNewClient, dispatch),
  getIcsTerminal: bindActionCreators(clientActions.getIcsTerminal, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  deleteFacility: bindActionCreators(clientActions.deleteFacility, dispatch),
});

NewClient.prototype = {
  client: PropTypes.object.isRequired,
  createNewClient: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  deleteFacility: PropTypes.object.isRequired,
  getIcsTerminal: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewClient)
);
