import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "moment";
import { bindActionCreators } from "redux";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Collapse,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  uploadTypes,
  getStatusLabel,
  fileSizeString,
  getAllUploadTypes,
  uptoDecimalPlaces,
  extractFileExtensionFromString,
} from "../utils";

import * as downloadActions from "../actionCreators/Download";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  imageGrid: {
    marginTop: "2rem",
  },
  inilineDisplay: {
    display: "flex",
    justifyContent: "space-between",
  },
  blockDisplay: {
    display: "block",
  },
  attachmentImage: {
    border: "0.5px solid black",
    position: "relative",
    margin: "2px",
  },
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    cursor: "pointer",
  },
  tableHead: {
    backgroundColor: "#eaeaea",
  },
  tableHeadCell: {
    color: "#6ea16c",
    fontWeight: "800",
  },
  noBorderCell: {
    border: "none",
    alignItems: "center",
  },
  button: {
    margin: "8px",
    fontSize: "12px",
  },
  inlineLink: {
    cursor: "pointer",
    "&:hover": {
      color: "#6ea16c",
      wordBreak: "break-all",
    },
    fontSize: "14px",
    color: "#424242",
    margin: "10px",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    userSelect: "none",
    msUserSelect: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    width: "200px",
  },
  inlineButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  inlineButton: {
    cursor: "pointer",
    "&:hover": {
      color: "#6ea16c",
    },
    fontSize: "14px",
    color: "#424242",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    userSelect: "none",
    msUserSelect: "none",
    margin: "0px 4px",
  },
  clickableIcon: {
    cursor: "pointer",
  },
  labelWrapper: {
    display: "flex",
  },
  noPaddingCell: {
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
  },
  labelText: {
    fontSize: "14px",
    fontWeight: "800",
  },
  sectionHeading: {
    backgroundColor: "#EEEEEE",
  },
  alignCenter: {
    textAlign: "center",
  },
  rejectionTextHeading: {
    fontWeight: "bold",
  },
  rejectionText: {
    fontSize: "14px",
    color: "#424242",
    borderBottom: "none",
  },
  rejectionNoteWrapper: {
    margin: "0px 10px",
    width: "100%",
  },
  rejectionNote: {
    display: "inline",
    "&:hover": {
      wordBreak: "break-all",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
    color: "#424242",
    borderBottom: "none",
  },
  sizeUnavailable: {
    margin: "15px",
  },
  fileToUpateWrapper: {
    padding: "10px 10px 0px 10px",
    backgroundColor: "seashell",
  },
  fileToUpdateTextHeading: {
    fontWeight: "bold",
  },
  fileToUpdateText: {
    fontSize: "14px",
    color: "#424242",
    margin: "10px",
    width: "100%",
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  approve: {
    color: "green",
  },
  reject: {
    color: "red",
  },
  rejectionDivider: {
    color: "black",
    borderRadius: "1px solid black",
  },
  rootLoader: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  adminActionMessage: {
    color: "red",
    fontSize: "smaller",
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 0,
  },
}));

const Row = ({
  row,
  index,
  classes,
  preview,
  handleEdit,
  adminIndex,
  isEditable,
  isComplete,
  isInactive,
  isUserAdmin,
  contractorInv,
  handleDownload,
  contractorInvNo,
  isCorporateUser,
  attachmentHistory,
  selectedAttachments,
  handleSelectAttachment,
  updateRejectedAttachment,
  viewAdminAttachmentHistory,
  isNotRequired,
}) => {
  const [open, setOpen] = useState(false);
  const [statusState, setStatusState] = useState({
    fetchedStatus: "",
    isFetched: false,
  });
  const [uploadedFileState, setUploadedFileState] = useState({
    isUploaded: false,
    file: {
      name: "",
      size: 0,
      type: "",
    },
  });
  const uploadInvoiceAlert = "Add Contractor Invoice";

  const onViewAttachmentHandler = (attachmentId) => {
    viewAdminAttachmentHistory(attachmentId);
  };

  const fileUpdateHandler = (event) => {
    let filesList = [];
    let fileName = "";
    let fileExt = "";
    let contractorInvFileName = "";
    const contractorInvAttachmentType = row?.type === "contractorInv";
    if (contractorInvAttachmentType) {
      fileName = row?.name?.split(row?.name[row?.name?.lastIndexOf(".")])[0];
      fileExt = extractFileExtensionFromString(row?.name);

      let splittedFileName = fileName?.split("-");
      let updatedFileName;
      if (splittedFileName?.length > 1) {
        splittedFileName[splittedFileName?.length - 1] = contractorInvNo;

        updatedFileName = splittedFileName?.join("-");
        contractorInvFileName = `${updatedFileName}.${fileExt}`;
      } else {
        updatedFileName = splittedFileName;
        contractorInvFileName = `${updatedFileName}.${fileExt}`;
      }
    }

    for (let file of event?.target?.files) {
      setUploadedFileState({
        isUploaded: true,
        file: {
          name: contractorInvAttachmentType
            ? contractorInvFileName
            : file?.name,
          size: file?.size,
          type: file?.type,
        },
      });

      let updatedFileName = new File(
        [file],
        contractorInvAttachmentType ? contractorInvFileName : file?.name,
        {
          type: file?.type,
        }
      );
      file = updatedFileName;
      filesList.push(file);
    }
    updateRejectedAttachment(filesList, index, adminIndex, row, contractorInv);
    setStatusState({ ...statusState, isFetched: true });
  };

  const orderRejectionHistory = (history) => {
    let toBeReversed = [...history];
    const reversedHistory = toBeReversed.reverse();
    return reversedHistory;
  };

  useEffect(() => {
    if (
      attachmentHistory?.data?.length > 0 &&
      attachmentHistory?.data[0]?.spill_attachment_id === row?.id
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [attachmentHistory?.data]);

  useEffect(() => {
    const fetchedStatus = getStatusLabel(
      statusState?.isFetched ? "uploaded" : row?.status,
      isComplete,
      row?.rejected_count
    );
    setStatusState({ ...statusState, fetchedStatus });
  }, [isComplete, row?.rejected_count, row?.status, statusState?.isFetched]);

  return (
    <React.Fragment>
      <TableRow key={index} className={classes.root}>
        {isUserAdmin && (
          <TableCell colSpan={1}>
            {isEditable && (
              <Checkbox
                disabled={isNotRequired}
                name="checkedB"
                color="primary"
                onChange={(event) =>
                  handleSelectAttachment(index, event.target.checked)
                }
                checked={selectedAttachments.includes(index)}
              />
            )}
          </TableCell>
        )}
        <TableCell scope="row">
          <span style={{ display: "grid" }}>
            <span
              className={classes.inlineLink}
              onClick={() => {
                handleDownload(row.key);
              }}
            >
              {row?.name === "Final Contractor Invoice"
                ? row?.name
                : `${row?.name} ${
                    row?.rejected_count === 0
                      ? ""
                      : `- Modified(${row?.rejected_count})`
                  }`}
            </span>
            {row?.inv_amount >= 0 && row?.type === "contractorInv" && (
              <span style={{ margin: "0px 10px" }}>
                <b>Invoice Amount: </b>${uptoDecimalPlaces(row?.inv_amount, 2)}
              </span>
            )}
          </span>
        </TableCell>
        <TableCell colSpan={1}>{row.user?.full_name}</TableCell>
        <TableCell colSpan={1}>
          {Moment(row?.updated_at)?.format("MM-DD-YYYY h:mm a")}
        </TableCell>
        <TableCell colSpan={1}>{fileSizeString(row.size)}</TableCell>
        <TableCell colSpan={1} className={classes[row.status]}>
          {statusState?.fetchedStatus}
        </TableCell>
        <TableCell colSpan={3}>
          <div className={classes.inlineButtonContainer}>
            {row?.rejected_count > 0 ? (
              <Button
                disabled={isNotRequired}
                variant="contained"
                component="label"
                color="primary"
                onClick={() =>
                  open ? setOpen(!open) : onViewAttachmentHandler(row?.id)
                }
                className={classes.button}
                aria-label="expand row"
                loading={attachmentHistory?.loading}
              >
                {open ? "Hide" : "View"}
              </Button>
            ) : null}

            {row.status === "reject" && !isUserAdmin && (
              <div>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  className={classes.button}
                  disabled={
                    isInactive ||
                    (row?.type === "contractorInv" &&
                      (isNaN(contractorInv) || contractorInv === null))
                  }
                >
                  Update
                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg, .TIF, .pdf, .doc, .docx, .xls, .xlsx"
                    onChange={(event) => fileUpdateHandler(event)}
                    hidden
                    multiple
                    name="adminFiles"
                    id="adminFiles"
                  />
                </Button>
                <div>
                  {row?.type === "contractorInv" &&
                    (isNaN(contractorInv) || contractorInv === null) && (
                      <p className={classes.adminActionMessage}>
                        {uploadInvoiceAlert}
                      </p>
                    )}
                </div>
              </div>
            )}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          {/* {isCorporateUser &&
          preview?.length > 0 &&
          !isNotRequired &&
          !preview[index] === index ? (
            <CloseIcon
              className={classes.clickableIcon}
              onClick={() => handleEdit('isDelete', true, index)}
            />
          ) : isUserAdmin && isEditable && !isComplete && !isNotRequired ? ( */}
          {isEditable && !isComplete && !isNotRequired && (
            <CloseIcon
              className={classes.clickableIcon}
              onClick={() => handleEdit("isDelete", true, index)}
            />
          )}
          {/* ) : (
            <></>
          )} */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPaddingCell} colSpan={9}>
          <Collapse
            in={uploadedFileState?.isUploaded}
            timeout="auto"
            unmountOnExit
          >
            <div className={classes.fileToUpateWrapper}>
              <div className={classes.fileToUpdateTextHeading}>
                Uploaded File:
              </div>

              <div className={classes.inilineDisplay}>
                <div className={classes.fileToUpdateText}>
                  <b>Name:</b> {uploadedFileState?.file?.name}
                  {contractorInv ? (
                    <>
                      <b>Invoice Amount:</b> $
                      {uptoDecimalPlaces(contractorInv, 2)}
                    </>
                  ) : null}
                </div>
                <div className={classes.fileToUpdateText}>
                  <b>Size:</b> {fileSizeString(uploadedFileState?.file?.size)}
                </div>
                <div className={classes.fileToUpdateText}>
                  <b>Type:</b> {uploadedFileState?.file?.type}
                </div>
              </div>
            </div>
          </Collapse>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableRow>
              <TableCell colSpan={1}>
                <div className={classes.rejectionTextHeading}>
                  Rejection History:
                </div>
              </TableCell>
              <TableCell colSpan={8}></TableCell>
            </TableRow>

            {orderRejectionHistory(attachmentHistory?.data)?.map(
              (historyItem, index, history) => {
                const downloadableFile = `${
                  historyItem?.url_link?.split("/")[4]
                }/${historyItem?.url_link?.split("/")[5]}/${
                  historyItem?.url_link?.split("/")[6]
                }`;

                const lastHistoryItem = index === history?.length - 1;

                const splittedHistoryAttachmentName =
                  historyItem?.key?.split("/");
                const historyAttachmentName =
                  splittedHistoryAttachmentName[
                    splittedHistoryAttachmentName?.length - 1
                  ];
                let historyFileNameForDisplay = "";
                if (row?.type === "contractorInv") {
                  historyFileNameForDisplay = historyAttachmentName;
                } else {
                  historyFileNameForDisplay = row?.name;
                }

                return (
                  <>
                    <TableRow>
                      <TableCell scope="row" className={classes.rejectionText}>
                        <span
                          className={classes.inlineLink}
                          onClick={() => {
                            handleDownload(historyItem.key || downloadableFile);
                          }}
                        >
                          {lastHistoryItem
                            ? `${historyFileNameForDisplay}`
                            : `${historyFileNameForDisplay} - Modified(${
                                history?.length - 1 - index
                              })`}
                        </span>
                      </TableCell>
                      <TableCell colSpan={1} className={classes.noBorderCell}>
                        {historyItem?.uploaded_by}
                      </TableCell>
                      <TableCell colSpan={1} className={classes.noBorderCell}>
                        {Moment(row?.created_at)?.format("MM-DD-YYYY h:mm a")}
                      </TableCell>
                      <TableCell colSpan={1} className={classes.noBorderCell}>
                        {fileSizeString(historyItem?.size) || (
                          <div className={classes.sizeUnavailable}>N/A</div>
                        )}
                      </TableCell>
                      <TableCell
                        colSpan={isUserAdmin ? 1 : 4}
                        className={classes.noBorderCell}
                      >
                        <p className={classes.reject}>Rejected</p>
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        className={classes.noBorderCell}
                        style={{
                          minWidth: isUserAdmin ? "120px" : "200px",
                        }}
                      ></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={9} className={classes.noBorderCell}>
                        <span style={{ display: "grid" }}>
                          {historyItem?.inv_amount &&
                          historyItem?.inv_amount >= 0 ? (
                            <div
                              style={{ display: "inline", margin: "0px 10px" }}
                            >
                              <p>
                                <b>Invoice Amount:</b> $
                                {uptoDecimalPlaces(historyItem?.inv_amount, 2)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className={classes.rejectionNoteWrapper}>
                            <b>Rejection Note:</b>{" "}
                            <p className={classes.rejectionNote}>
                              {historyItem?.note}
                            </p>
                          </div>
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={9} className={classes.noBorderCell}>
                        {!lastHistoryItem && (
                          <Divider className={classes.rejectionDivider} />
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              }
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const AttachmentGrid = ({
  files,
  preview,
  userName,
  adminIndex,
  handleEdit,
  isInactive,
  isComplete,
  isEditable,
  isUserAdmin,
  downloadFile,
  handleCancel,
  contractorInv,
  contractorInvNo,
  isCorporateUser,
  attachmentHistory,
  selectedAttachments,
  setSelectedAttachments,
  // specificAttachmentTypes,
  updateRejectedAttachment,
  viewAdminAttachmentHistory,
  isNotRequired,
}) => {
  const classes = useStyles();

  const [activeTypes, setActiveTypes] = useState([]);

  const handleDownload = (key) => {
    downloadFile({ key });
  };

  useEffect(() => {
    let tempTypes = [];
    let attachments = [];
    let tempUploadTypes = [];
    if (files?.length) {
      attachments = [...files];
    }
    if (preview?.length) {
      attachments = [...attachments, ...preview];
    }
    for (const att of attachments) {
      !att.isDelete && tempTypes.push(att.type);
    }
    for (const item of getAllUploadTypes()) {
      tempTypes.includes(item.value) && tempUploadTypes.push(item);
    }
    setActiveTypes(tempUploadTypes);
  }, [JSON.stringify(preview), JSON.stringify(files)]);

  const handleSelectAttachment = (fileIndex, value) => {
    const arrIndex = selectedAttachments.indexOf(fileIndex);
    if (value) {
      if (arrIndex === -1) {
        let temp = [...selectedAttachments];
        temp = [...temp, fileIndex];
        setSelectedAttachments(temp);
      }
      return;
    }
    if (arrIndex !== -1) {
      let temp = [...selectedAttachments];
      temp.splice(arrIndex, 1);
      setSelectedAttachments(temp);
    }
  };

  const handleSelectCategory = (type, value) => {
    let indexes = [];
    for (const itemIndex in files) {
      if (files[itemIndex].type === type) indexes.push(parseInt(itemIndex));
    }
    if (indexes.length) {
      if (value) {
        let tempSelected = [...selectedAttachments];
        for (const fileIndex of indexes) {
          !tempSelected.includes(fileIndex) && tempSelected.push(fileIndex);
        }
        setSelectedAttachments(tempSelected);
      } else {
        let tempSelected = [...selectedAttachments];
        for (const fileIndex of indexes) {
          const tempIndex = tempSelected.indexOf(parseInt(fileIndex));
          if (tempIndex !== -1) {
            tempSelected.splice(tempIndex, 1);
          }
        }
        setSelectedAttachments(tempSelected);
      }
    }
  };

  return (
    <div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {isUserAdmin && (
                <TableCell
                  colSpan={1}
                  className={classes.tableHeadCell}
                ></TableCell>
              )}
              <TableCell colSpan={1} className={classes.tableHeadCell}>
                File Name
              </TableCell>
              <TableCell colSpan={1} className={classes.tableHeadCell}>
                Uploaded By
              </TableCell>
              <TableCell colSpan={1} className={classes.tableHeadCell}>
                Uploaded At
                {/* /
                <br /> Expiration Date */}
              </TableCell>
              <TableCell colSpan={1} className={classes.tableHeadCell}>
                Size
              </TableCell>
              <TableCell colSpan={1} className={classes.tableHeadCell}>
                Status
              </TableCell>
              <TableCell colSpan={2} className={classes.tableHeadCell}>
                Actions
              </TableCell>
              <TableCell
                colSpan={2}
                className={classes.tableHeadCell}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!activeTypes || activeTypes.length < 1 ? (
              <TableCell colSpan={8}>
                <div className={classes.alignCenter}>
                  No Attachments Added Yet
                </div>
              </TableCell>
            ) : (
              activeTypes.map((type) => {
                return (
                  <>
                    <TableRow className={classes.sectionHeading}>
                      {isUserAdmin && (
                        <TableCell colSpan={1}>
                          {isEditable && (
                            <Checkbox
                              disabled={isNotRequired}
                              color="primary"
                              onChange={(event) => {
                                handleSelectCategory(
                                  type.value,
                                  event.target.checked
                                );
                              }}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell colSpan={1}>
                        <div className={classes.labelText}>{type.label}</div>
                      </TableCell>
                      <TableCell colSpan={8}>{/* <hr /> */}</TableCell>
                    </TableRow>
                    {preview?.map((row, index) => {
                      if (row.type === type.value) {
                        return (
                          <>
                            <TableRow key={index}>
                              {isUserAdmin && isEditable && (
                                <TableCell colSpan={1}></TableCell>
                              )}
                              <TableCell colSpan={1}>
                                <span className={classes.inlineLink}>
                                  {row.file.name}
                                </span>
                              </TableCell>
                              <TableCell colSpan={1}>{userName}</TableCell>
                              <TableCell colSpan={1}>
                                {Moment(new Date())?.format("MM-DD-YYYY h:mm a")}{" "}
                                {/* /
                                <br />
                                {specificAttachmentTypes?.includes(
                                  row?.type
                                ) ? (
                                  <span style={{ color: 'red' }}>
                                    {Moment(new Date(row?.expiry_date))?.format(
                                      'MM-DD-YYYY h:mm a'
                                    )}
                                  </span>
                                ) : (
                                  'No Expiry Date'
                                )} */}
                              </TableCell>
                              <TableCell colSpan={1}>
                                {fileSizeString(row.size)}
                              </TableCell>
                              <TableCell colSpan={1}>{row.status}</TableCell>
                              <TableCell colSpan={1}></TableCell>
                              <TableCell colSpan={1}>
                                {!isComplete && (
                                  <CloseIcon
                                    className={classes.clickableIcon}
                                    onClick={() => handleCancel(index)}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}

                    {files?.map((row, index) => {
                      return (
                        <>
                          {!row?.isDelete && row?.type === type?.value && (
                            <Row
                              row={row}
                              key={index}
                              index={index}
                              classes={classes}
                              preview={preview}
                              adminIndex={adminIndex}
                              handleEdit={handleEdit}
                              isInactive={isInactive}
                              isEditable={isEditable}
                              isComplete={isComplete}
                              isUserAdmin={isUserAdmin}
                              contractorInv={contractorInv}
                              handleDownload={handleDownload}
                              contractorInvNo={contractorInvNo}
                              isCorporateUser={isCorporateUser}
                              attachmentHistory={attachmentHistory}
                              selectedAttachments={selectedAttachments}
                              handleSelectAttachment={handleSelectAttachment}
                              updateRejectedAttachment={
                                updateRejectedAttachment
                              }
                              viewAdminAttachmentHistory={
                                viewAdminAttachmentHistory
                              }
                              isNotRequired={isNotRequired}
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
});

AttachmentGrid.propTypes = {
  files: PropTypes.array,
  preview: PropTypes.array,
  userName: PropTypes.string,
  handleEdit: PropTypes.func,
  isEditable: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isCorporateUser: PropTypes.bool,
  selectedAttachments: PropTypes.array,
  setSelectedAttachments: PropTypes.func,
  isComplete: PropTypes.func,
  downloadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentGrid);
