import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, label } from "@material-ui/core";
import SampleFile from "../../../../../assets/sampleFile/facility-terminal-example.xlsx";
import { CustomSnackBar, DownloadFullCSV } from "../../../../../Components";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: "left",
    paddingRight: "10px",
    paddingLeft: "10px",
    marginTop: "10px",
  },
  customLable: {
    color: "#444444",
    fontSize: "15px",
    marginTop: "16px",
    marginBottom: "0px",
    display: "inline-block",
  },
  header: {
    color: "green",
    textAlign: "left",
  },
}));
const FileUpload = ({
  file,
  setFile,
  editMode,
  getAllfacilities,
  TeamData,
  isNotICS,
  totalFacility,
  setTotalFacility,
  totalTerminals,
  setTotalTerminals,
  downloadFacilities
}) => {
  const [errorMessage, setErrorMessage] = React.useState("false");
  const classes = useStyles();
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      if (
        uploadedFile.type === "text/csv" ||
        uploadedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setFile(uploadedFile);
      } else {
        setErrorMessage("Please upload a CSV or XLSX file only.");
      }
    }
  };

  useEffect(() => {
    let calculatedTotalTerminals = 0;
    if (TeamData) {
      for (const key in TeamData) {
        const terminals = TeamData[key];
        calculatedTotalTerminals += terminals?.length || 0;
      }
      setTotalTerminals(calculatedTotalTerminals);
    }
    if (getAllfacilities?.data?.length) {
      const totalfacilityCount = getAllfacilities?.data?.length;
      setTotalFacility(totalfacilityCount);
    }
  }, [TeamData, getAllfacilities?.data]);

  return (
    <>
      <CustomSnackBar
        open={!!errorMessage}
        severity="error"
        snackBarMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              lineHeight: "1.75",
            }}
          >
            <div style={{ alignSelf: "center" }}>
              {getAllfacilities?.data?.length > 0 && (
                <>
                  <span style={{ color: "#7c7c7c" }}>No of Facilities: </span>
                  <b>{totalFacility}</b>
                </>
              )}
              {TeamData && Object.keys(TeamData)?.length > 0 && (
                <>
                  <span style={{ color: "#7c7c7c" }}>No of Terminals: </span>
                  <b style={{ color: "#2F7D32" }}>{totalTerminals}</b>
                </>
              )}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div>
                <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: "none" }}
                  id="file-input"
                  type="file"
                  onChange={handleFileChange}
                  disabled={isNotICS}
                />
                <label
                  htmlFor="file-input"
                  style={{ cursor: isNotICS ? "not-allowed" : "auto" }}
                >
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={isNotICS}
                  >
                    Upload File
                  </Button>
                </label>
              </div>
              <div className="samplefile">
                <a
                  href={SampleFile}
                  download
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  Sample File
                </a>
              </div>
              {editMode && (
                <div className="facilityDownload">
                  {downloadFacilities?.length > 0 && (
                    <DownloadFullCSV
                      type={"Facility"}
                      getAllfacilities={downloadFacilities}
                    />
                  )}
                  {TeamData && Object.keys(TeamData)?.length > 0 && (
                    <DownloadFullCSV
                      type={"Terminal"}
                      TeamData={TeamData}
                      isNotICS={isNotICS}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            {file && (
              <p className="uploaded-file">
                <strong>File uploaded:</strong> {file.name}
              </p>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default FileUpload;