import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { makeStyles, Tab, Tabs } from "@material-ui/core";

import Moment from "moment";

import * as spillActions from "../../../../../../actionCreators/Spill";

import TabPanel from "../../../TabPanel";
import StatusHistory from "./StatusHistory";
import NoteHistoryTabPanel from "./NoteHistoryTabPanel";
import ContractoryHistoryTabPanel from "./ContractorHistoryTabPanel";
import PacketAssignementHistoryTabPanel from "./PacketAssignementHistoryTabPanel";

const useStyles = makeStyles(() => ({
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  tabLabel: {
    fontSize: "16px",
  },
}));

const HistoryTabPanel = ({
  notes,
  spillId,
  getNoteHistory,
  spillStatusHistory,
  currentNoteHistory,
  getContractorHistory,
  getSpillStatusHistory,
  packetAssignmentHistory,
  currentContractorHistory,
  getSpillPacketAssignmentHistory,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  useEffect(() => {
    getContractorHistory({ spillId });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      getNoteHistory({
        spillId,
      });
    }

    if (newValue === 1) {
      getContractorHistory({ spillId });
    }

    if (newValue === 2) {
      getSpillStatusHistory({ spillId });
    }

    if (newValue === 3) {
      getSpillPacketAssignmentHistory({ spillId });
    }
  };

  return (
    <div sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{
          flexContainer: classes.tabsContainer,
        }}
        textColor="primary"
      >
        <Tab label="Notes History" className={classes.tabLabel} value={0} />
        <Tab
          label="Contractor Response History"
          className={classes.tabLabel}
          value={1}
        />
        <Tab label="Status History" className={classes.tabLabel} value={2} />
        {/* <Tab
          label="Packet Assignment History"
          className={classes.tabLabel}
          value={3}
        /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <NoteHistoryTabPanel
          notes={notes}
          currentNoteHistory={currentNoteHistory}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContractoryHistoryTabPanel
          currentContractorHistory={{
            data: currentContractorHistory?.data?.map((item) => ({
              ...item,
              created_at: Moment(new Date(item.created_at))?.format(
                "MM-DD-YYYY h:mm a"
              ),
            })),
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StatusHistory spillStatusHistory={spillStatusHistory} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PacketAssignementHistoryTabPanel
          packetAssignmentHistory={packetAssignmentHistory}
        />
      </TabPanel>
    </div>
  );
};

const mapStateToProps = ({
  spill: {
    currentNoteHistory,
    currentContractorHistory,
    spillStatusHistory,
    packetAssignmentHistory,
  },
}) => ({
  spillStatusHistory,
  currentNoteHistory,
  packetAssignmentHistory,
  currentContractorHistory,
});

const mapDispatchToProps = (dispatch) => ({
  getContractorHistory: bindActionCreators(
    spillActions.getContractorHistory,
    dispatch
  ),
  getSpillStatusHistory: bindActionCreators(
    spillActions.getSpillStatusHistory,
    dispatch
  ),
  getSpillPacketAssignmentHistory: bindActionCreators(
    spillActions.getSpillPacketAssignmentHistory,
    dispatch
  ),
  getNoteHistory: bindActionCreators(spillActions.getNoteHistory, dispatch),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HistoryTabPanel)
);
