import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import {
  isPESUser,
  statusVisibility
} from '../../../../../utils';

import * as spillActions from '../../../../../actionCreators/Spill';
import * as userActions from '../../../../../actionCreators/User';

import { EditSpill, ViewSpill } from '.';
import { Prompt, WholeScreenModal } from '../../../../../Components';
import PacketAssignmentDataTable from '../../../../../Components/PacketAssignmentDataTable';
import { EditNoteText as Text } from '../../../../../utils/language/english';

const useStyles = makeStyles((theme) => ({
  checked: {},
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: 'none',
  },
}));

const PacketAssignment = ({
  admins,
  history,
  service,
  currentUser,
  packetReviewers,
  assignPacketReviewer,
  fetchPacketReviewers,
  packetReviewerAssignment,
  getDocumentationInReviewSpills,
  documentationInReviewSpillsData,
}) => {

  const [selectedId, setSelectedId] = useState(0);
  const [totalSpills, setTotalSpills] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [viewSpill, setViewSpill] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [fetchedSpillsData, setFetchedSpillsData] = useState({
    ids: [],
    data: [],
    loading: false,
    success: false,
    error: null,
  });
  const [fetchedPacketReviewersData, setFetchedPacketReviewersData] = useState({
    data: [],
    loading: false,
    success: false,
    error: null,
  });
  const [fetchedPacketReviewerAssignment, setFetchedPacketReviewerAssignment] =
    useState({
      data: [],
      loading: false,
      success: false,
      error: null,
    });
  const [selectedPacketReviewer, setSelectedPacketReviewer] = useState(null);
  const [packetReviewerToUpdate, setPacketReviewerToUpdate] = useState(null);

  const [checkedSpills, setCheckedSpills] = useState([]);
  const [showAssignPacketReviewerModal, setShowAssignPacketReviewerModal] =
    useState(false);
  const [paginationProps, setPaginationProps] = useState({
    page: 0,
    limit: 10,
  });

  const tableHeaders = [
    { title: 'Job No', key: 'job_no' },
    { title: 'Client Org', key: 'clientOrgName' },
    { title: 'Opened', key: 'opened_on' },
    { title: 'Status', key: 'status' },
    { title: 'Closed', key: 'closed_on' },
    { title: 'Amount', key: 'amount' },
    { title: 'Claim No', key: 'claim_no' },
    { title: 'Total Days', key: 'days' },
  ];

  useEffect(() => {
    getDocumentationInReviewSpills(paginationProps);
    fetchPacketReviewers();
  }, []);

  useEffect(() => {
    if (documentationInReviewSpillsData?.error) {
      setSnackBarSeverity('error');
      setSnackBarMessage(
        'There was some problem while fetching spills data. Please try again later'
      );
      setSnackBarOpen(true);
    }
    if (documentationInReviewSpillsData?.data?.spillsData?.length > 0) {
      setFetchedSpillsData({
        ...fetchedSpillsData,
        ids: documentationInReviewSpillsData?.data?.spillIds,
        data: documentationInReviewSpillsData?.data?.spillsData,
        loading: documentationInReviewSpillsData?.loading,
        success: documentationInReviewSpillsData?.success,
      });

      setTotalSpills(documentationInReviewSpillsData?.data?.count);
    }
  }, [documentationInReviewSpillsData]);

  useEffect(() => {
    if (packetReviewers?.error) {
      setSnackBarSeverity('error');
      setSnackBarMessage(
        'There was some problem while fetching packet reviewers. Please try again later'
      );
      setSnackBarOpen(true);
    }
    if (packetReviewers?.data?.length > 0) {
      setFetchedPacketReviewersData({
        ...fetchedPacketReviewersData,
        data: packetReviewers?.data,
        loading: packetReviewers?.loading,
        success: packetReviewers?.success,
      });
    }
  }, [packetReviewers]);

  useEffect(() => {
    if (packetReviewerAssignment?.error) {
      setSnackBarSeverity('error');
      setSnackBarMessage(
        'There was some problem while packet assignment. Please try again later'
      );
      setSnackBarOpen(true);
    }

    if (
      packetReviewerAssignment?.data?.updated_packet_assignment?.length > 0 &&
      packetReviewerAssignment?.success
    ) {
      setFetchedPacketReviewerAssignment({
        ...fetchedPacketReviewerAssignment,
        data: packetReviewerAssignment?.data?.updated_packet_assignment,
        loading: packetReviewerAssignment?.loading,
        success: packetReviewerAssignment?.success,
      });

      if (checkedSpills?.length) {
        setSnackBarSeverity('success');
        setSnackBarMessage(
          `${checkedSpills?.length} Spills have been assigned successfully`
        );
        setSnackBarOpen(true);
        setCheckedSpills([]);
      }
      setSelectedPacketReviewer(null);
      getDocumentationInReviewSpills(paginationProps);
      fetchPacketReviewers();
    }
  }, [packetReviewerAssignment]);

  const handlePageChange = (data, limit) => {
    setPaginationProps({ page: data, limit });
    getDocumentationInReviewSpills({
      paginationProps: { page: data, limit },
    });
  };

  const handleViewSpill = (id) => {
    setViewSpill(true);
    setSelectedId(id);
    setShowEditForm(false);
    window.history.replaceState({}, null, `/dashboard/spills/view/${id}`);
  };

  const handleEditSpill = (id) => {
    setSelectedId(id);
    setShowEditForm(true);
    setViewSpill(false);
    window.history.replaceState({}, null, `/dashboard/spills/edit/${id}`);
  };

  const handleOpenEditFormWithDataTable = (data) => {
    handleEditSpill(data?.job_no);
  };

  const handleCloseEditSpill = () => {
    setViewSpill(false);
    setShowEditForm(false);
    setSelectedId(0);
    window.history.replaceState(
      {},
      null,
      `/dashboard/spills/packet-assignment`
    );
  };

  const handleMoveToEditSpill = (id) => {
    setSelectedId(id);
    setShowEditForm(true);
    setViewSpill(false);
    history.push(`/dashboard/spills/edit/${id}`);
  };

  const handleCloseViewSpill = () => {
    setViewSpill(false);
    setShowEditForm(false);
    setSelectedId(0);
    window.history.replaceState(
      {},
      null,
      `/dashboard/spills/packet-assignment`
    );
  };

  const changePacketReviewerHandler = (value) => {
    const selectedReviewerData = value.split(' ');

    // packet reviewer count
    const packet_reviewer_count_string =
      selectedReviewerData[selectedReviewerData?.length - 1];
    let packet_reviewer_count_number = packet_reviewer_count_string?.replace(
      '(',
      ' '
    );
    packet_reviewer_count_number = packet_reviewer_count_number?.replace(
      ')',
      ' '
    );
    packet_reviewer_count_number = packet_reviewer_count_number?.replaceAll(
      ' ',
      ''
    );
    packet_reviewer_count_number = +packet_reviewer_count_number;

    // packet reviewer full name
    let packet_reviewer_full_name = selectedReviewerData?.slice(
      0,
      selectedReviewerData?.length - 1
    );
    packet_reviewer_full_name = packet_reviewer_full_name?.filter(
      (x) => x.length > 0
    );
    packet_reviewer_full_name = packet_reviewer_full_name?.join(' ');

    const found_packet_reviewer = fetchedPacketReviewersData?.data?.find(
      (reviewer) =>
        reviewer.full_name === packet_reviewer_full_name &&
        reviewer.packet_reviewer_count === packet_reviewer_count_number
    );
    const packet_reviewer_id = found_packet_reviewer?.id;

    setPacketReviewerToUpdate({
      id: packet_reviewer_id,
      full_name: packet_reviewer_full_name,
      count: packet_reviewer_count_number,
    });
    setSelectedPacketReviewer(value);
  };

  const assignPacketReviewerHandler = () => {
    setShowAssignPacketReviewerModal(true);
  };

  const submissionHandler = () => {
    if (!checkedSpills?.length) {
      setSnackBarSeverity('warning');
      setSnackBarMessage('No spills selected for assignment');
      setSnackBarOpen(true);
      return;
    }

    if (packetReviewerToUpdate === null) {
      setSnackBarSeverity('warning');
      setSnackBarMessage('No packet reviewer selected');
      setSnackBarOpen(true);
      return;
    }

    assignPacketReviewer({
      spills_data: checkedSpills,
      packet_reviewer_data: packetReviewerToUpdate,
    });
  };

  const handleSubmit = async (assignPacketReviewerCheck) => {
    assignPacketReviewerCheck && submissionHandler();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  return (
    <>
      <WholeScreenModal
        show={showAssignPacketReviewerModal}
        hide={() => setShowAssignPacketReviewerModal(false)}
      >
        <Prompt
          response={(response) => {
            setShowAssignPacketReviewerModal(false);
            handleSubmit(response);
          }}
          headingText={Text.packetAssignmentPromptHeading}
          question={Text.packetAssignment + '  ' + checkedSpills?.length}
          acceptText={Text.packetAssignmentPromptAcceptBtn}
          rejectText={Text.packetAssignmentPromptRejectBtn}
        />
      </WholeScreenModal>

      {viewSpill === false && showEditForm === false && (
        <>
          <div style={{ width: '100%', textAlign: 'left' }}>
            <Typography variant='h5' gutterBottom>
              Packet Assignment ({totalSpills})
            </Typography>
          </div>

          {isPESUser(currentUser?.data?.role?.role) && (
            <Grid container>
              <Grid item xs={2} style={{ display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        const spillIds = fetchedSpillsData?.ids?.map(
                          (spill) => spill?.id
                        );
                        setCheckedSpills(
                          event?.target?.checked === true ? [...spillIds] : []
                        );
                        setSelectAll(event?.target?.checked);
                      }}
                    />
                  }
                  labelPlacement='start'
                  label='Select All'
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ width: '120%' }}
                  control={
                    <Select
                      labelId='packet_reviewer_label'
                      id='packet_reviewer_select'
                      name='packet_reviewer'
                      style={{
                        minWidth: '250px',
                        margin: '0px',
                      }}
                      inputProps={{
                        value: selectedPacketReviewer || 'Select a Reviewer',
                      }}
                      onChange={(event) => {
                        changePacketReviewerHandler(event?.target?.value);
                      }}
                      disabled={
                        fetchedPacketReviewersData?.loading === true
                          ? true
                          : false
                      }
                    >
                      {(fetchedPacketReviewersData?.data?.length > 0
                        ? fetchedPacketReviewersData?.data?.map(
                            (reviewer) =>
                              `${reviewer?.full_name} ${' '} (${
                                reviewer?.packet_reviewer_count
                              })`
                          )
                        : []
                      ).map((e) => {
                        return <MenuItem value={e}>{e}</MenuItem>;
                      })}
                    </Select>
                  }
                  labelPlacement='start'
                  label='Select Packet Reviewer'
                />
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={assignPacketReviewerHandler}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          )}

          <PacketAssignmentDataTable
            tableHeader={tableHeaders}
            tableBody={fetchedSpillsData?.data?.map((row) => ({
              ...row,
              status: statusVisibility(
                row?.status,
                currentUser?.data?.role?.role
              ),
            }))}
            editTable={currentUser?.data?.role?.permission?.edit_spills}
            loading={fetchedSpillsData?.loading}
            onRowSelect={handleOpenEditFormWithDataTable}
            pageChange={handlePageChange}
            currentPage={paginationProps?.page}
            setCurrentPage={(val) =>
              setPaginationProps({ ...paginationProps, page: val })
            }
            showViewButton
            handleView={(data) => handleViewSpill(data.job_no)}
            total={totalSpills}
            selectSpill={(id, checked) => {
              if (checked) {
                checkedSpills.push(id);
              } else {
                checkedSpills.splice(checkedSpills?.indexOf(id), 1);
              }

              setCheckedSpills(checkedSpills);
            }}
            selected={checkedSpills}
            selectAll={selectAll}
            setPageRows={() => {}}
          />
        </>
      )}

      <div style={{ marginTop: 10 }}>
        {showEditForm && (
          <EditSpill
            servicesData={service}
            id={selectedId}
            closeEditSpill={handleCloseEditSpill}
            viewSpill={(id) => handleViewSpill(id)}
          />
        )}
      </div>

      <div style={{ marginTop: 10 }}>
        {viewSpill && (
          <ViewSpill
            id={selectedId}
            onSearch={true}
            adminsData={admins}
            servicesData={service}
            currentUser={currentUser}
            closeViewSpill={() => handleCloseViewSpill()}
            updateSpill={(data) => handleMoveToEditSpill(data)}
          />
        )}
      </div>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = ({
  user: { admins, currentUser, packetReviewers },
  service,
  spill: { documentationInReviewSpillsData, packetReviewerAssignment },
}) => ({
  service,
  admins,
  currentUser,
  packetReviewers,
  packetReviewerAssignment,
  documentationInReviewSpillsData,
});

const mapDispatchToProps = (dispatch) => ({
  getDocumentationInReviewSpills: bindActionCreators(
    spillActions.getDocumentationInReviewSpills,
    dispatch
  ),
  fetchPacketReviewers: bindActionCreators(
    userActions.fetchPacketReviewers,
    dispatch
  ),
  assignPacketReviewer: bindActionCreators(
    spillActions.assignPacketReviewer,
    dispatch
  ),
});

PacketAssignment.propTypes = {
  user: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  packetReviewers: PropTypes.object.isRequired,
  packetReviewerAssignment: PropTypes.array.isRequired,
  documentationInReviewSpillsData: PropTypes.array.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PacketAssignment)
);
