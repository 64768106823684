import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const CompleteSubmissionDialog = ({
  isOpen,
  handleDone,
  handleCancel,
}) => {
  const useStyles = makeStyles((theme) => ({
    dialogContent: {
      minWidth: "500px",
    },
    textBox: {
      width: "100%",
    },
    sectionHeading: {
      fontSize: "16px",
      fontWeight: "800",
      marginBottom: "5px",
    },
    fileName: {
      fontSize: "15px",
      display: "flex",
      alignItems: "center",
    },
    fileItem: {
      margin: "3px",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="xs">
      <DialogTitle>Are you sure?</DialogTitle>

      <DialogContent>
        <div>
            After marking as complete you will not be able to add or edit files. 
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleDone()}
        >
          Complete Submission
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompleteSubmissionDialog;

CompleteSubmissionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDone: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
