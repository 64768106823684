import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Divider,
  TextField,
} from '@material-ui/core';

import { CONTRACTOR_PROMPT_MESSAGE_TEXT, RegxList } from '../utils';
import CurrencyFieldInput from './CurrencyFieldInput';

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    marginBottom: 10,
  },
  questionContainer: {
    marginBottom: 10,
  },
  button: {
    transition: 'all .4s',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '90%',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
  acceptContractorButton: {
    transition: 'all .4s',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '165px',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
  disableAcceptContractorButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    width: '165px',
    marginTop: 10,
  },
  titleAllignment: {
    'text-align': 'left',
  },
  divider: {
    margin: '20px 0px',
  },
  gpsFieldInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerWidth: {
    width: '590px',
  },
}));

const UpdateContractorsPrompt = ({
  loading,
  message,
  response,
  acceptText,
  rejectText,
  gpsFieldState,
  addHeadingText,
  descriptionText,
  setGPSFieldState,
  acceptHeadingText,
  addContractorText,
  pendingContractors,
  contractorModalState,
  setContractorModalState,
  areContractorsEmpty,
}) => {
  const classes = useStyles();
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [showGPSForm, setShowGPSForm] = useState(false);
  const [latValue, setLatValue] = useState(false);
  const [longValue, setLongValue] = useState(false);
  const [contractorResponseIsAccepted, setContractorResponseIsAccepted] =
    useState(false);
  const [validations, setValidations] = useState({
    latitude: undefined,
    longitude: undefined,
  });
  const isLatLngInitiallyEmpty = useRef(null);

  useEffect(() => {
    if (validations.latitude === null && validations.longitude === null) {
      setDisableUpdate(false);
      return;
    }
    setDisableUpdate(true);
  }, [validations]);

  let updatedContractors = [];

  const acceptContractorHandler = (accpetedContractor) => {
    updatedContractors = pendingContractors?.map((contractor) => {
      if (contractor?.id === accpetedContractor?.id) {
        contractor = accpetedContractor;
      }
      return contractor;
    });

    setContractorModalState({
      ...contractorModalState,
      pendingContractors: updatedContractors,
    });
  };

  const updateLatitude = (latitude) => {
    setLatValue(true);
    validateLatLng('latitude', latitude);
    setGPSFieldState({ ...gpsFieldState, lat: latitude });
  };

  const updateLongitude = (longitude) => {
    setLongValue(true);
    validateLatLng('longitude', longitude);
    setGPSFieldState({ ...gpsFieldState, long: longitude });
  };

  const validateLatLng = (key, value) => {
    if (value.includes('.') && RegxList.NUMERIC_DECIMAL_DIGIT.test(value)) {
      setValidations((currentValue) => ({ ...currentValue, [key]: null }));
    } else {
      setValidations((currentValue) => ({
        ...currentValue,
        [key]: {
          msg: `This Field Only Recognizes Decimal values. Please Enter a Valid Decimal ${key}`,
        },
      }));
    }
  };

  useEffect(() => {
    if (isLatLngInitiallyEmpty.current === null) {
      isLatLngInitiallyEmpty.current =
        gpsFieldState?.lat || gpsFieldState?.long ? false : true;
    }

    let atleastOneOrAllContractorsResponseIsAccepted = pendingContractors?.some(
      (contractor) => contractor?.accepted === true
    );
    const arePendingContractorsEmpty = pendingContractors.length === 0;
    atleastOneOrAllContractorsResponseIsAccepted =
      !areContractorsEmpty && arePendingContractorsEmpty
        ? true
        : atleastOneOrAllContractorsResponseIsAccepted;
    setContractorResponseIsAccepted(
      atleastOneOrAllContractorsResponseIsAccepted
    );
    const latitudeExists = gpsFieldState?.lat;
    const longitudeExists = gpsFieldState?.long;
    if (
      atleastOneOrAllContractorsResponseIsAccepted || ((latitudeExists && longitudeExists))
    ) {
      setDisableUpdate(false);
    } else {
      setDisableUpdate(true);
    }

    if ((latitudeExists === null || longitudeExists === null) && contractorModalState.showGPSPopup) {
      setShowGPSForm(true);
    }
  }, [contractorModalState]);

  const resetGpsFields = () => {
    if (isLatLngInitiallyEmpty.current) {
      setGPSFieldState((current) => ({
        ...current,
        lat: null,
        long: null,
      }));
    }
    setLatValue(false);
    setLongValue(false);
  };

  // set button to disable once click on update button
  const handleUpdate = () => {
    setDisableUpdate(true)
    response(true, contractorResponseIsAccepted)
  }

  return (
    <Grid container className={classes.containerWidth}>
      {/* omitting contractor headings if we are here just for gps fields */}
      {!contractorModalState?.forGpsFields && (
        <>
          <Grid item xs={12} className={classes.titleAllignment}>
            <Typography variant={'h6'}>
              <b>
                {message === CONTRACTOR_PROMPT_MESSAGE_TEXT?.ADD_CONTACTOR
                  ? addHeadingText
                  : acceptHeadingText}
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.questionContainer}>
            <Typography variant={'body1'}>
              {message === CONTRACTOR_PROMPT_MESSAGE_TEXT?.ADD_CONTACTOR
                ? addContractorText
                : descriptionText}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {/* omitting contractor info if we are here just for gps fields */}
        {!contractorModalState?.forGpsFields && (
          <Grid container>
            <Grid item xs={12}>
              {pendingContractors?.map((contractor) => {
                const isContractorAccepted = contractor?.accepted;
                return (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p>{contractor?.label}</p>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      disabled={isContractorAccepted ? true : false}
                      onClick={() =>
                        acceptContractorHandler({
                          ...contractor,
                          accepted: true,
                        })
                      }
                      className={
                        isContractorAccepted
                          ? classes.disableAcceptContractorButton
                          : classes.acceptContractorButton
                      }
                    >
                      {isContractorAccepted
                        ? `Response Accepted`
                        : `Accept Response`}
                    </Button>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid container>
          {showGPSForm ? (
            <>
              {!contractorModalState?.forGpsFields && (
                <Grid item xs={12} className={classes.divider}>
                  <Divider />
                </Grid>
              )}

              <Grid item xs={12} className={classes.titleAllignment}>
                <Typography variant={'h6'}>
                  <b>Add GPS Fields</b>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.questionContainer}>
                <Typography variant={'body1'}>
                  Update GPS fields in order for the contractor to arrive on
                  site
                </Typography>
              </Grid>
              <Grid
                xs={12}
                spacing={3}
                className={classes.gpsFieldInputContainer}
              >
                <Grid item xs={6} md={5}>
                  <CurrencyFieldInput
                    label='Latitude'
                    name='latitude'
                    decimalScale={9}
                    allowNegative={true}
                    onChange={(e) => updateLatitude(e?.target?.value)}
                    helperText={validations.latitude?.msg || ''}
                    error={validations.latitude}
                  />
                </Grid>

                <Grid item xs={6} md={5}>
                  <CurrencyFieldInput
                    label='longitude'
                    name='longitude'
                    decimalScale={9}
                    allowNegative={true}
                    onChange={(e) => updateLongitude(e?.target?.value)}
                    helperText={validations.longitude?.msg || ''}
                    error={validations.longitude}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
          <Grid
            item
            xs={
              message === CONTRACTOR_PROMPT_MESSAGE_TEXT?.ADD_CONTACTOR
                ? showGPSForm
                  ? 8
                  : 10
                : 8
            }
          />
          <Grid item xs={2}>
            <Button
              disabled={loading}
              variant={'outlined'}
              color={'primary'}
              onClick={() => {
                resetGpsFields();
                response(false, contractorResponseIsAccepted);
              }}
              className={classes.button}
            >
              {rejectText}
            </Button>
          </Grid>
          {message ===
            CONTRACTOR_PROMPT_MESSAGE_TEXT?.ACCEPT_CONTRACTOR_RESPONSE ||
          showGPSForm ? (
            <Grid item xs={2}>
              <Button
                loading={loading}
                disabled={disableUpdate}
                loadingPosition='start'
                variant={'outlined'}
                color={'primary'}
                onClick={handleUpdate}
                className={classes.button}
              >
                {acceptText}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateContractorsPrompt;
