import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import * as contractorActions from '../../../../../actionCreators/Contractor';

import { CustomSnackBar, AddressAccordian } from '../../../../../Components';
import { getTotalCount } from '../../../../../utils';
import {
  FormControlLabel,
  InputAdornment,
  Switch,
  Chip,
} from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import ClassIRailRoadsAccordion from '../../../../../Components/ERailCertificate/ClassIRailRoadsAccordion/ClassIRailRoadsAccordion';
import AdditionalAttachmentsAccordion from '../../../../../Components/AdditionalAttachmentsAccordion/AdditionalAttachmentsAccordion';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boxTitle: {
    textAlign: 'left',
    marginTop: '10px',
    fontWeight: 800,
    marginBottom: 0,
  },
}));

const EditContractor = ({
  contractor,
  closeEditModal,
  contractorData,
  uploadCertificate,
  editContractor,
  userIsHazardous,
  userLicenseNumber,
  setUserIsHazardous,
  setUserLicenseNumber,
  userLicenseNumberExpiry,
  setUserLicenseNumberExpiry,
  deleteContractorCertificate,
}) => {
  const classes = useStyles();
  const { id, name, email, certification_classes } = contractorData;

  const [certificationClasses, setCertificationClasses] = useState(
    JSON.parse(certification_classes) || []
  );
  const [addresses, setAddresses] = useState(
    contractorData ? contractorData.addresses || [] : []
  );
  const [classIERailCertifications, setClassIERailCertifications] = useState(
    []
  );
  const [reqSubmitted, setReqSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [masterRibbonsState, setMasterRibbons] = useState({
    contacted: 0,
    responseAccepted: 0,
    responseRejected: 0,
    rejectedPercentage: 0,
  });
  const [snackbar, setSnackbar] = React.useState({
    message: '',
    severity: '',
    open: false,
  });
  const [emailError, setEmailError] = React.useState(null);
  const [emailContractor, setEmailContractor] = useState(contractorData?.email);
  const [nameContractor, setNameContractor] = useState(contractorData?.name);


  useEffect(() => {
    if (!contractor?.loading) {
      if (contractor?.contractorERailClassICertifications) {
        setClassIERailCertifications(
          contractor?.contractorERailClassICertifications?.data?.data
        );
      }
      if (contractor?.contractors?.deleteCertificate === true) {
        setSnackbar({
          message: `File deleted successfully`,
          severity: 'success',
          open: true,
        });
        contractor.contractors.deleteCertificate = false;
      }

      if (reqSubmitted) {
        if (!contractor.error) {
          closeEditModal(true);
        } else {
          setErrorMessage(contractor.error);
        }
        setReqSubmitted(false);
      }
    }
  }, [contractor.loading]);

  useEffect(() => {
    const allContacted =
      getTotalCount({ ...contractorData }) +
      addresses.reduce(
        (sum, { respond_count, reject_count }) =>
          sum + (respond_count || 0) + (reject_count || 0),
        0
      );

    let allAccepted =
      getTotalCount({ ...contractorData }) +
      addresses?.reduce(
        (sum, { respond_count, reject_count }) =>
          sum + (respond_count || 0) + (reject_count || 0),
        0
      );

    let allRejected =
      contractorData?.reject_count +
      addresses.reduce((sum, { reject_count }) => sum + (reject_count || 0), 0);

    let allRejectedPercentage = ((allRejected / allContacted) * 100)?.toFixed(
      2
    );

    setMasterRibbons({
      contacted: allContacted,
      responseAccepted: allAccepted,
      responseRejected: allRejected,
      rejectedPercentage: allRejectedPercentage,
    });
  }, [addresses, contractorData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const textFieldsData = {
      email: e.target.email.value,
    };

    if (formValidator(textFieldsData)) {
      return;
    }

    if(!e.target.name.value?.trim()) {
      return setErrorMessage("Name cannot be empty")
    }

    const obj = {
      id,
      name: e.target.name.value ? e.target.name.value?.trim() : '',
      contact: e.target.contact.value ? e.target.contact.value : '',
      address: e.target.address.value ? e.target.address.value : '',
      city: e.target.city.value ? e.target.city.value : '',
      state: e.target.state.value ? e.target.state.value : '',
      zip_code: e.target['zip-code'].value ? e.target['zip-code'].value : '',
      country: e.target.country.value ? e.target.country.value : '',
      email: e.target.email.value ? e.target.email.value : '',
      phone: e.target.phone.value ? e.target.phone.value : '',
      fax: e.target.fax.value ? e.target.fax.value : '',
      tier_level: e.target.tier_level.value ? e.target.tier_level.value : '',
      haz_waste_hauler: userIsHazardous,
      license_number: userLicenseNumber,
      license_number_expiry: userLicenseNumberExpiry,
      certification_classes: JSON.stringify(certificationClasses),
    };

    editContractor({ ...obj, addresses });
    setReqSubmitted(true);
  };

  const handlerToggleHazWasteHauler = () => {
    setUserIsHazardous(!userIsHazardous);
  };

  const handleLicenseNumberExpiryChange = (newValue) => {
    setUserLicenseNumberExpiry(newValue);
  };

  const handleLicenseNumberChange = (e) => {
    setUserLicenseNumber(e?.target?.value);
  };

  const updateAttachmentTypes = (selectedFilesData) => {
    const formData = new FormData();

    let selectedFiles = selectedFilesData.filter(
      (file) => file?.attachedFile?.file || file.is_expiry_update
    );

    selectedFiles.forEach((selectedFile, index) => {
      if (selectedFile.attachedFile?.file) {
        formData.append(
          `file_${index}`,
          selectedFile.attachedFile?.file,
          `certificate#${selectedFile?.attachedFile?.fileName}`
        );

        formData.append(
          `fileName_${index}`,
          selectedFile.attachedFile?.fileName
        );

        formData.append(`prevAttId_${index}`, selectedFile?.id);
        formData.append(`attachmentType_${index}`, selectedFile?.value);
        formData.append(`contractorID_${index}`, contractorData?.id);
        formData.append(`expiryDate_${index}`, selectedFile?.expiry_date);
        formData.append(
          `attachmentTypeId_${index}`,
          selectedFile?.attachment_id
        );
      } else {
        formData.append(`attachmentType_${index}`, selectedFile?.value);
        formData.append(`contractorID_${index}`, contractorData?.id);
        formData.append(`expiryDate_${index}`, selectedFile?.expiry_date);
        formData.append(
          `attachmentTypeId_${index}`,
          selectedFile?.attachment_id
        );
      }
    });

    uploadCertificate(formData);
    setReqSubmitted(true);
  };

  const deleteCertificate = async (id) => {
    deleteContractorCertificate(id);
  };

  const handleSnackbarClose = () =>
    setSnackbar((current) => ({
      ...current,
      messsage: '',
      open: false,
    }));

  const formValidator = ({ email }) => {
    let flag = false;

    if (!email || email === '' || email.length === 0 || !email.trim()) {
      setEmailError(true);
      flag = true;
    } else {
      setEmailError(false);
    }

    return flag;
  };
  const handleChangeEmail = (event) => {
    setEmailContractor(event.target.value.trim());
  };

  const handleChangeName = (event) => {
    setNameContractor(event.target.value);
  };

  return (
    <>
      <div style={{ display: contractor.loading ? 'none' : 'unset' }}>
        <CustomSnackBar
          open={!!errorMessage}
          severity='error'
          snackBarMessage={errorMessage}
        />
        <Typography variant='h5' gutterBottom>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <EditIcon style={{ color: '#2F7D32', fontSize: 40 }} />
              <span style={{ paddingLeft: '20px' }}>Edit Contractor</span>
            </div>
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Chip
                label={`All Contacted: ${masterRibbonsState?.contacted || 0}`}
                variant='outlined'
                size='small'
              />
              <Chip
                label={`All Accepted: ${masterRibbonsState?.contacted -
                  masterRibbonsState?.responseRejected || 0
                  }`}
                variant='outlined'
                color='primary'
                size='small'
              />
              <Chip
                label={`All Rejected: ${masterRibbonsState?.responseRejected || 0
                  }`}
                variant='outlined'
                color='secondary'
                size='small'
              />
              <Chip
                label={`All Rejected (%): ${masterRibbonsState?.rejectedPercentage || 0
                  }`}
                variant='outlined'
                size='small'
              />
            </span>
          </div>
        </Typography>
        <div style={{ paddingTop: 30 }} />
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id='name'
                name='name'
                label='Name'
                fullWidth
                defaultValue={name}
                autoComplete='name'
                value={nameContractor}
                onChange={handleChangeName}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                error={emailError}
                id='email'
                name='email'
                label='E-mail'
                fullWidth
                defaultValue={email}
                autoComplete='email'
                onChange={handleChangeEmail}
                value={emailContractor}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <span style={{ display: 'flex', justifyContent: 'end' }}>
                <FormControlLabel
                  control={
                    <Switch
                      labelId='haz-waster-hauler'
                      id='haz-waster-hauler'
                      checked={userIsHazardous}
                      onChange={handlerToggleHazWasteHauler}
                      name='userIsHazardous'
                      color='primary'
                    />
                  }
                  label='Haz Waste Hauler'
                />
              </span>
            </Grid>

            {userIsHazardous && (
              <>
                <Grid item xs={6}>
                  <TextField
                    id='license_number'
                    name='license_number'
                    label='License Number'
                    fullWidth
                    defaultValue={userLicenseNumber}
                    onChange={handleLicenseNumberChange}
                    autoComplete='license_number'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'></InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      style={{
                        margin: '0px 10px',
                        width: '-webkit-fill-available',
                      }}
                      variant='inline'
                      format='MM-DD-YYYY hh:mm a'
                      id='license_number_expiry'
                      label='License Expiration Date'
                      showTodayButton
                      ampmInClock={true}
                      clearable={true}
                      value={userLicenseNumberExpiry}
                      onChange={handleLicenseNumberExpiryChange}
                      KeyboardButtonProps={{
                        aria_label: 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                paragraph
                className={classes.boxTitle}
              >
                Additional Attachments
              </Typography>
            </Grid>
            <AdditionalAttachmentsAccordion
              updateAttachmentTypes={updateAttachmentTypes}
              additionalAttachmentTypes={
                contractorData?.GenericAttachedDocuments
              }
              deleteCertificate={deleteCertificate}
              contractorId={contractorData?.id}
            />

            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                paragraph
                className={classes.boxTitle}
              >
                E-Rail Certifications
              </Typography>
            </Grid>
            <ClassIRailRoadsAccordion
              certificationClasses={certificationClasses}
              setCertificationClasses={setCertificationClasses}
              classIERailCertifications={classIERailCertifications}
            />

            <br />

            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                paragraph
                className={classes.boxTitle}
              >
                Addresses
              </Typography>
            </Grid>
            <AddressAccordian
              addresses={addresses}
              setAddresses={setAddresses}
              entity_id={contractorData.id}
              entity_type={'contractor'}
              isContractor={true}
              mainAddress={{
                ...contractorData,
              }}
            />

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                type='submit'
              >
                Update
              </Button>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                onClick={() => closeEditModal()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>

      <CustomSnackBar
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={handleSnackbarClose}
        snackBarMessage={snackbar.message}
      />
    </>
  );
};

const mapStateToProps = ({ contractor }) => ({ contractor });

const mapDispatchToProps = (dispatch) => ({
  editContractor: bindActionCreators(
    contractorActions.editContractor,
    dispatch
  ),
  uploadCertificate: bindActionCreators(
    contractorActions.uploadCertificate,
    dispatch
  ),
  deleteContractorCertificate: bindActionCreators(
    contractorActions.deleteContractorCertificate,
    dispatch
  ),
});

EditContractor.propTypes = {
  contractorData: PropTypes.object.isRequired,
  editContractor: PropTypes.func.isRequired,
  uploadCertificate: PropTypes.func.isRequired,
  deleteContractorCertificate: PropTypes.func.isRequired,
  closeEditModal: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditContractor)
);
