import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactSelect from 'react-select';

import Moment from 'moment';
import MomentUtils from '@date-io/moment';

import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Switch,
  CardContent,
  Card,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { CustomProgressLoader } from '../../../../../Components';

import * as userActions from '../../../../../actionCreators/User';
import * as clientActions from '../../../../../actionCreators/Client';
import {
  getContractorOptions,
  convertToGroupedOptions,
  contractorColourStyles as colourStyles,
  filterOption,
  orderAlphabaticallyByKey,
  ROLES,
  attachmentToCreateOnContractorRoles,
} from '../../../../../utils';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  alignLeft: {
    textAlign: 'left',
  },
  formControl: {
    width: '100%',
  },
  itemsAlignRight: {
    textAlign: 'right',
  },
  itemsAlignLeft: {
    textAlign: 'left',
  },
  centerAlign: {
    textAlign: 'center',
  },
  labelColor: {
    color: "#7F7F7F",
  },
}));

const NewUser = ({
  user,
  createNewUser,
  closeAddUserModal,
  getOrganizationNames,
  getUserRoles,
  getAll,
  clientOrganizationNames,
  contractorsDataWithAddresses,
  newUser,
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    getOrganizationNames();
    getUserRoles();
  }, []);

  const organizationNames = clientOrganizationNames.data;
  const userRoles = user.roles.data;

  const [selectState, setSelectState] = useState({
    organizationSelect: false,
    activeSelect: true,
    emailNotiSelect: true,
  });

  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setphoneError] = useState(null);
  const [passwordError, setpasswordError] = useState(null);
  const [organizationError, setOrganizationError] = useState(null);
  const [activeError, setActiveError] = useState(null);
  const [notificatonError, setNotificatonError] = useState(null);
  const [contractorError, setContractorError] = useState(false);

  const [selectedContractorWithAddress, setSelectedContractorWithAddress] =
    React.useState(null);
  const [adminContractor, setAdminContractor] = React.useState(false);
  const [userContractor, setUserContractor] = React.useState(false);

  const [contractorOptions, setContractorOptions] = React.useState([]);
  const [contractorAttachmentsWithExpiry, setContractorAttachmentsWithExpiry] =
    React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(false);
  const [okayToEmail, setOkayToEmail] = React.useState(true);
  const [notifyOnDocumentRejection, setNotifyOnDocumentRejection] = React.useState(false)

  React.useEffect(() => {
    let filteredContractors = [];

    const isAdmin = [1, 2, 12].includes(user.currentUser?.data?.role_id);
    if (userContractor) {
      filteredContractors = isAdmin
        ? contractorsDataWithAddresses.data
        : contractorsDataWithAddresses.data.filter(
            (singleContractor) =>
              singleContractor.name === user.currentUser?.data.contractor.name
          );
    } else if (adminContractor) {
      filteredContractors = contractorsDataWithAddresses.data.filter(
        (singleContractor) =>
          !singleContractor.entity_id &&
          (isAdmin
            ? true
            : singleContractor.name === user.currentUser?.data.contractor.name)
      );
    }

    const newContractors = getContractorOptions(filteredContractors);
    setContractorOptions(
      adminContractor
        ? orderAlphabaticallyByKey(newContractors, 'name')
        : convertToGroupedOptions(newContractors)
    );
  }, [
    adminContractor,
    contractorsDataWithAddresses,
    user.currentUser.data.contractor_id,
    user.currentUser.data.role.role,
    userContractor,
  ]);

  React.useEffect(() => {
    if (adminContractor || userContractor) {
      if (attachmentToCreateOnContractorRoles?.length > 0) {
        setContractorAttachmentsWithExpiry(attachmentToCreateOnContractorRoles);
      }
    }
  }, [attachmentToCreateOnContractorRoles, adminContractor, userContractor]);

  const handleSelectChange = (e, key) => {
    if (key === 'roleSelect') {
      setSelectedContractorWithAddress(null);
      e.target.value === 9
        ? setAdminContractor(true)
        : setAdminContractor(false);
      e.target.value === 10
        ? setUserContractor(true)
        : setUserContractor(false);
    }

    let tempObject = selectState;
    tempObject[key] = true;
    setSelectState(tempObject);
  };

  const formValidator = ({ roleId, name, phone, email, password }) => {
    let flag = false;
    Object.keys(selectState).forEach((key) => {
      if (
        !(
          key === 'organizationSelect' &&
          (userContractor || adminContractor)
        ) &&
        selectState[key] === false
      ) {
        flag = true;
      }
    });

    if (roleId == 9 || roleId == 10) {
      if (!selectedContractorWithAddress) {
        setContractorError(true);
        return true;
      }
    }

    if (!name || name === '' || name.length === 0 || !name.trim()) {
      setNameError(true);
      flag = true;
    } else {
      setNameError(false);
    }
    if (!phone || phone === '' || phone.length === 0 || !phone.trim()) {
      setphoneError(true);
      flag = true;
    } else {
      setphoneError(false);
    }
    if (!email || email === '' || email.length === 0 || !email.trim()) {
      setEmailError(true);
      flag = true;
    } else {
      setEmailError(false);
    }
    if (
      !password ||
      password === '' ||
      password.length === 0 ||
      !password.trim()
    ) {
      setpasswordError(true);
      flag = true;
    } else {
      setpasswordError(false);
    }

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const textFieldsData = {
      roleId: e.target.role.value,
      name: e.target.full_name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      phone: e.target.phone.value,
    };

    if (formValidator(textFieldsData)) {
      setOrganizationError(!selectState.organizationSelect);
      return;
    } else {
      let newUserObject = {
        full_name: e.target.full_name.value ? e.target.full_name.value : '',
        phone: e.target.phone.value ? e.target.phone.value : '',
        email: e.target.email.value ? e.target.email.value : '',
        password: e.target.password.value ? e.target.password.value : '',
        active: e.target.active.value ? e.target.active.value : '',
        eula_accepted: false,
        email_notification:
          isDefault ||
          (e.target.email_notification.value
            ? e.target.email_notification.value
            : ''),
        role_id: e.target.role.value ? e.target.role.value : '',
        org_id:
          adminContractor || userContractor
            ? ''
            : e.target.organization.value
            ? e.target.organization.value
            : '',
        selectedContractor: selectedContractorWithAddress
          ? selectedContractorWithAddress
          : '',
        is_default: e.target.is_default.checked,
        attachmentTypesWithExpiries:
          contractorAttachmentsWithExpiry?.length > 0 &&
          contractorAttachmentsWithExpiry,
        notify_on_document_rejection: notifyOnDocumentRejection ?? false
      };

      createNewUser(newUserObject);
      setSubmitted(true);
    }
  };

  React.useEffect(() => {
    if (!user.newUser.loading && submitted) {
      !user.newUser.error
        ? closeAddUserModal()
        : setEmailError(user.newUser.error);
      setSubmitted(false);
    }
  }, [user.newUser.loading]);

  return (
    <>
      <CustomProgressLoader show={user.newUser.loading} />

      <div style={{ display: user.newUser.loading ? 'none' : 'unset' }}>
        <Typography variant='h5' gutterBottom>
          <div style={{ display: 'flex' }}>
            <Avatar className={classes.avatar}>
              <AlternateEmailIcon />
            </Avatar>
            <span style={{ paddingLeft: '20px' }}>New User</span>
          </div>
        </Typography>

        <div style={{ paddingTop: 30 }} />

        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={nameError}
                id='full_name'
                name='full_name'
                label='Name'
                fullWidth
                autoComplete='full_name'
              />
            </Grid>

            <Grid item xs={10} className={classes.alignLeft}>
              <FormControl
                className={classes.formControl}
                error={organizationError}
              >
                <InputLabel id='organization-select-label'>
                  Organization *
                </InputLabel>
                {user?.currentUser?.data?.role?.role ===
                ROLES.CORPORATE_ADMIN ? (
                  <Select
                    labelId='organization-select-label'
                    id='organization-select'
                    name='organization'
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      handleSelectChange(e, 'organizationSelect')
                    }
                    disabled={userContractor || adminContractor}
                  >
                    {orderAlphabaticallyByKey(
                      organizationNames.filter((x) => {
                        return (
                          x.active && x.id === user?.currentUser.data.org_id
                        );
                      }),
                      'name'
                    ).map((orgData, i) => {
                      return (
                        (user?.currentUser?.data?.role?.role !==
                          ROLES.CONTRACTOR_ADMIN ||
                          user?.currentUser?.data?.org_id === orgData.id) && (
                          <MenuItem value={orgData.id} key={i}>
                            {orgData.name}
                          </MenuItem>
                        )
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    labelId='organization-select-label'
                    id='organization-select'
                    name='organization'
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      handleSelectChange(e, 'organizationSelect')
                    }
                    disabled={userContractor || adminContractor}
                  >
                    {orderAlphabaticallyByKey(
                      organizationNames.filter((x) => x.active),
                      'name'
                    ).map((orgData, i) => {
                      return (
                        (user?.currentUser?.data?.role?.role !==
                          ROLES.CONTRACTOR_ADMIN ||
                          user?.currentUser?.data?.org_id === orgData.id) && (
                          <MenuItem value={orgData.id} key={i}>
                            {orgData.name}
                          </MenuItem>
                        )
                      );
                    })}
                  </Select>
                )}
                {organizationError && (
                  <FormHelperText>Please select organization</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={2} className={classes.leftAlign}>
              <InputLabel id='default-user-label'>Default User</InputLabel>

              <Switch
                labelId='default-user-label'
                id='default-switch'
                onChange={(e) => setIsDefault(e.target.checked)}
                defaultChecked={false}
                name='is_default'
                color='primary'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={phoneError}
                id='phone'
                name='phone'
                label='Phone'
                fullWidth
                autoComplete='phone'
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl className={classes.formControl} error={emailError}>
                <TextField
                  error={emailError}
                  id='email'
                  name='email'
                  label='Email'
                  fullWidth
                  autoComplete='email'
                  type='email'
                />
                {emailError && <FormHelperText>{emailError}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={6} className={classes.alignLeft}>
              <FormControl className={classes.formControl}>
                <InputLabel id='role-select-label'>Role *</InputLabel>
                <Select
                  labelId='role-select-label'
                  id='role-select'
                  name='role'
                  defaultValue={8}
                  style={{ width: '100%' }}
                  onChange={(e) => handleSelectChange(e, 'roleSelect')}
                >
                  {orderAlphabaticallyByKey(userRoles, 'role').map(
                    (roleData, i) => {
                      return (
                        ((user?.currentUser?.data?.role?.role !==
                          ROLES.CONTRACTOR_ADMIN &&
                          user?.currentUser?.data?.role?.role !==
                            ROLES.CORPORATE_ADMIN) ||
                          (user?.currentUser?.data?.role?.role ===
                            ROLES.CONTRACTOR_ADMIN &&
                            roleData.role.includes('Contractor')) ||
                          (user?.currentUser?.data?.role?.role ===
                            ROLES.CORPORATE_ADMIN &&
                            roleData.role.includes('Corporate'))) && (
                          <MenuItem value={roleData.id} key={i}>
                            {roleData.role}
                          </MenuItem>
                        )
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>

            {(adminContractor || userContractor) ? <Grid item xs={6} className={classes.alignLeft} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "-20px" }} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifyOnDocumentRejection}
                    onChange={(event) => setNotifyOnDocumentRejection(event?.target?.checked)}
                    name="notify_on_document_rejection"
                    className={classes.labelColor}
                    defaultValue={false}
                  />
                }
                label="Notify on Document Rejection"
              />
            </Grid> : null}

            {userContractor ? (
              <Grid item xs={12} className={classes.alignLeft}>
                <InputLabel
                  id='organization-select-label'
                  style={{ marginBottom: '5px' }}
                >
                  Contractor *
                </InputLabel>
                <FormControl
                  className={classes.formControl}
                  error={contractorError}
                >
                  <ReactSelect
                    value={selectedContractorWithAddress}
                    required
                    onChange={setSelectedContractorWithAddress}
                    options={contractorOptions}
                    filterOption={filterOption}
                    styles={colourStyles()}
                  />
                  {contractorError && (
                    <FormHelperText>Please select contractor</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}

            {adminContractor ? (
              <Grid item xs={12} className={classes.alignLeft}>
                <InputLabel
                  id='organization-select-label'
                  style={{ marginBottom: '5px' }}
                >
                  Contractor *
                </InputLabel>
                <FormControl
                  className={classes.formControl}
                  error={contractorError}
                >
                  <ReactSelect
                    value={selectedContractorWithAddress}
                    required
                    onChange={setSelectedContractorWithAddress}
                    options={contractorOptions}
                    filterOption={filterOption}
                    styles={colourStyles()}
                  />
                  {contractorError && (
                    <FormHelperText>Please select contractor</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={6}>
              <TextField
                error={passwordError}
                id='password'
                name='password'
                label='Password'
                fullWidth
                autoComplete='password'
              />
            </Grid>

            <Grid item xs={6} className={classes.alignLeft}>
              <FormControl className={classes.formControl} error={activeError}>
                <InputLabel id='active-select-label'>Active *</InputLabel>
                <Select
                  labelId='active-select-label'
                  id='active-select'
                  name='active'
                  style={{ width: '100%' }}
                  onChange={(e) => handleSelectChange(e, 'activeSelect')}
                  defaultValue={true}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {activeError && (
                  <FormHelperText>Please select active</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6} className={classes.alignLeft}>
              <FormControl
                className={classes.formControl}
                error={notificatonError}
              >
                <InputLabel id='emailNotification-select-label'>
                  Okay to email *
                </InputLabel>
                <Select
                  labelId='emailNotification-select-label'
                  id='emailNotification-select'
                  name='email_notification'
                  style={{ width: '100%' }}
                  value={isDefault || okayToEmail}
                  disabled={isDefault}
                  onChange={(e) => setOkayToEmail(e.target.value)}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {notificatonError && (
                  <FormHelperText>
                    Please select email preference
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                type='submit'
              >
                Create
              </Button>

              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                onClick={() => closeAddUserModal()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, client: { clientOrganizationNames } }) => ({
  user,
  clientOrganizationNames,
});

const mapDispatchToProps = (dispatch) => ({
  createNewUser: bindActionCreators(userActions.createNewUser, dispatch),
  getUserRoles: bindActionCreators(userActions.getUserRoles, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
});

NewUser.prototype = {
  user: PropTypes.object.isRequired,
  createNewUser: PropTypes.func.isRequired,
  getUserRoles: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
  clientOrganizationNames: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewUser)
);
