import Moment from 'moment';

const getChangesByNotes = (history, noteId) => {
  const changes = [];
  history.forEach(
    (change) => change.note_id === noteId && changes.push(change)
  );
  return changes;
};

const getNoteDescById = (notes, noteId) => {
  for (let i in notes) {
    if (notes[i].id === noteId) return notes[i];
  }
};

export const refactor = (history, notes) => {
  const noteIds = [];
  history.forEach(
    (change) =>
      !noteIds.includes(change.note_id) && noteIds.push(change.note_id)
  );
  const changeObjByNotes = [];
  noteIds.forEach((noteId) => {
    const tempNote = {
      noteDesc: getNoteDescById(notes, noteId)?.description,
      noteId: noteId,
      edits: [],
    };
    const changesByNotes = getChangesByNotes(history, noteId);

    let id = -1;
    let tempEdit = {
      updatedAt: '',
      updatedBy: '',
      changes: [],
    };
    changesByNotes.forEach((change, index) => {
      if (change.note_edit_id !== id) {
        id = change.note_edit_id;
        id !== 0 && tempNote.edits.push({ ...tempEdit });
        tempEdit = {
          updatedAt: Moment(new Date(change.updated_at))?.format(
            'MM-DD-YYYY h:mm a'
          ),
          updatedBy: change.change_owner,
          changes: [],
        };
      }

      tempEdit.changes.push({
        fieldChanged: change.edited_field,
        prevVal: change.prev_val,
        newVal: change.new_val,
      });
      if (index === changesByNotes.length - 1)
        tempNote.edits.push({ ...tempEdit });
    });
    changeObjByNotes.push({ ...tempNote });
  });
  return changeObjByNotes;
};
