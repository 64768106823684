import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  List,
  ListItem,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactSelect from "react-select";
import { bindActionCreators } from "redux";
import { CustomSnackBar, MainAddressForm } from "../../../../../Components";
import * as clientActions from "../../../../../actionCreators/Client";
import {
  COUNTRIES,
  KEYS_TO_CHECK,
  ROLES,
  generateContractorLabel,
  objectsHaveSameKeysAndValues,
  orderAlphabaticallyByKey,
} from '../../../../../utils';
import ContractorClient from './ContractorClient';
import Facilities from './Facilities';
import SpecialInstruction from './SpecialInstruction';
import TeamTerminal from './TeamTerminal';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  leftAlign: {
    textAlign: "left",
  },
  serviceLabel: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    textTransform: "capitalize",
    padding: 0,
  },
  labelColor: {
    color: "#7F7F7F",
  },
  parentNames: {
    fontWeight: "bolder",
    paddingLeft: 10,
  },
}));

const EditClient = ({
  userRole,
  closeEditModal,
  clientId,
  editClient,
  clients,
  getParents,
  client,
  salePersonsData,
  servicesData,
  getOrganization,
  clientLoading,
  getOrganizationNames,
  clientObj,
  editFacility,
  deleteFacility,
  contractorsWithAddress,
  fax,
  contact,
  getIcsTerminal,
  getFacility,
  getAllfacilities
}) => {
  const classes = useStyles();
  const [rate, setRate] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [salePersons, setSalePersons] = React.useState([]);
  const [parentOrgName, setParentOrgName] = React.useState(false);
  const [associatedClients, setAssociatedClients] = React.useState([]);
  const [associatedServices, setAssociatedServices] = React.useState([]);
  const [paymentTerms, setPaymentTerms] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [associatedClientOptions, setAssociatedClientOptions] = React.useState(
    []
  );
  const [canSeeTimestamp, setCanSeeTimestamp] = React.useState(false);
  const [canSeeMonetary, setCanSeeMonetary] = React.useState(false);
  const [reqSubmitted, setReqSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [canSeeParentDefaultUsers, setCanSeeParentDefaultUsers] =
    React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [facilities, setSelectedFacilitiesData] = useState([]);
  const [newFacilities, setNewFacilites] = useState([]);
  const [updatedContractorData, setUpdatedContractorData] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [specialInstructionsData, setSpecialInstructionsData] = useState({
    hazardous_waste: null,
    non_hazardous_waste: null,
    revision_date: null,
    reporting: null,
    reporting_instruction: null,
    report_5800: null,
    instruction_5800: null,
    miscellaneous: null,
  });
  const lastRevisionDate = client?.revision_date ? moment(client?.revision_date)?.format("MM-DD-YYYY") : null;
  const [file, setFile] = useState(null);

  const [selectedDate, setSelectedDate] = useState(
    moment()?.format("MM-DD-YYYY")
  );
  const [mainAddressFormData, setMainAddressFormData] = useState({
    address: client?.address || "",
    city: client?.city || "",
    state: client?.state || "",
    zip_code: client?.zip_code || "",
    country: client?.country || "",
    phone: client?.phone || "",
    fax: fax || "",
    contact: contact || "",
  });
  const [name, setName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [deleteFacilityParams, setDeleteFacilityParams] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [checked, setIcsChecked] = useState();
  const [validateNoteTimeChecked, setValidateNoteTimeChecked] = useState()
  const [fileUpload, setFileUpload] = useState(true);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [isFacilityCalled, setIsFacilityCalled] = useState(false);
  const [isNotICS, setIsNotICS] = useState(false);
  const [totalFacility, setTotalFacility] = React.useState(0);
  const [totalTerminals, setTotalTerminals] = React.useState(0);
  const [updatedFacility, setUpdatedFacility] = useState(null);
  const [downloadFacilities, setDownloadFacilities] = useState([])
  const TeamData = useSelector(    
    (state) => {
      if (clientObj?.clientTerminal?.data && !clientObj?.clientTerminal?.data.hasOwnProperty('is_ics_client')) {
          return clientObj?.clientTerminal?.data;
      } else {
        return {};
      }
    }
  );

  React.useEffect(() => {
    if (selectedTab === 1 && !isFacilityCalled && userRole === ROLES.SUPER_USER) {
      getFacility({ org_id: clientId, type: "ALL" });
      setIsFacilityLoading(true);
      setTotalFacility();
    }
  }, [selectedTab, clientId, isFacilityCalled, userRole, getFacility]);
  
  React.useEffect(() => {
    if (getAllfacilities?.success && Array.isArray(getAllfacilities?.data)) {
      setIsFacilityCalled(true);
      setSelectedFacilitiesData([]);
      if (!getAllfacilities?.data.length) {
        setSelectedFacilitiesData([{
          name: null,
          internal_id: null,
          generator_status: null,
          epa_id: null,
          address: null,
          city: null,
          state: null,
          country: COUNTRIES?.USA,
          zip_code: null,
          phone_number: null,
          region: null,
        }])
      }
      if (getAllfacilities?.data.length) {
        const newArr = getAllfacilities?.data.map(v => ({...v, isEdit: false}))
        setDownloadFacilities(newArr);
        setSelectedFacilitiesData(newArr);
      }
      setIsFacilityLoading(false);
    } else {
      setIsFacilityLoading(false);
    }
  }, [getAllfacilities?.data, getAllfacilities?.success]);

  React.useEffect(() => {
    if (clientObj?.type === 'GET_ICS_TERMINAL_SUCCESS' && selectedTab === 1 && checked && clientObj?.clientTerminal?.data?.is_ics_client === false && name) {
      setErrorMessage(clientObj?.clientTerminal?.data?.message);
      setFileUpload(false);
      setFile('');
    }
    if(clientObj?.type === 'GET_ICS_TERMINAL_FAILURE' && selectedTab === 1 && checked)
    {
      setErrorMessage(clientObj?.error);
      setIsNotICS(true);
    }
  }, [clientObj?.type, clientObj?.clientTerminal?.data?.is_ics_client, checked, selectedTab, name, clientObj?.clientTerminal?.data?.message]);

  React.useEffect(() => {
    if (checked && name && selectedTab === 1) {
      setErrorMessage ('');
      setFileUpload(true);
      getIcsTerminal({ client_name: name });
      setTotalTerminals();
    }
  }, [checked, getIcsTerminal, name, selectedTab]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  React.useEffect(() => {
    const fetchContractorData = async () => {
      const clientOrgContractors =
        client?.client_organization_contractors || [];
      try {
        if (contractorsWithAddress && contractorsWithAddress?.data) {
          const selectedContracts = [];
          const contractors = contractorsWithAddress?.data;
          clientOrgContractors.forEach((contractor) => {
            const contractorIdToCompare = contractor.contractor_id;
            const addressIdToCompare = contractor.address_id;
            const foundContractor = contractors.find((contractor) => {
              if (addressIdToCompare && contractorIdToCompare) {
                return (
                  contractor.id === contractorIdToCompare &&
                  contractor.addressId === addressIdToCompare
                );
              } else {
                return contractor.id === contractorIdToCompare;
              }
            });
            if (foundContractor) {
              selectedContracts.push({
                ...foundContractor,
                value: `${foundContractor.id} ${
                  foundContractor.addressId || ""
                }`,
                label: `${foundContractor?.name} ${generateContractorLabel(
                  foundContractor
                )}`,
                checked: true,
              });
            }
          });
          setSelectedContractors(selectedContracts);
        }
      } catch (error) {
        console.error("Error fetching contractors:", error);
      }
    };
    fetchContractorData();
    if (client?.is_ics_client === true) {
      setIcsChecked(true);
    } else {
      setIcsChecked(false);
    }

    if(client?.note_work_time_validation) {
      setValidateNoteTimeChecked(true);
    } else {
      setValidateNoteTimeChecked(false)
    }

  }, [client, contractorsWithAddress]);

  const handleFormChange = (event) => {
    setName(event.target.value);
  };

  React.useEffect(() => {
    if (client) {
      setName(client?.name || "");
      setCanSeeTimestamp(client.timestamp_visibility);
      setCanSeeMonetary(client.monetary_visibility);
      setRate(client ? client.rate : "");
      setPaymentTerms(client.payment_terms || "");
      setSpecialInstructionsData({
        hazardous_waste: client?.hazardous_waste || null,
        non_hazardous_waste: client?.non_hazardous_waste || null,
        reporting: client?.reporting === null ? null : client?.reporting === true ? "true" : "false",
        reporting_instruction: client?.reporting_instruction || null,
        report_5800: client?.report_5800 === null ? null : client?.report_5800 === true ? "true" : "false",
        instruction_5800: client?.instruction_5800 || null,
        miscellaneous: client?.miscellaneous || null,
      });

      setMainAddressFormData({
        address: client?.address || "",
        city: client?.city || "",
        state: client?.state || "",
        zip_code: client?.zip_code || "",
        country: client?.country || "",
        phone: client?.phone || "",
        fax: fax || "",
        contact: contact || "",
      });
      // const clientFacility = client?.client_organization_facilities;
      // if (clientFacility?.length) {
      //   setSelectedFacilitiesData(clientFacility);
      // }

      setCanSeeParentDefaultUsers(client.is_default_parent_users);
      let temp = [];
      let associatedOrgs = client?.associated_organizations?.map((item) => {
        const filteredOrg = clients?.data.filter(
          (x) => x.id === item.associated_org_id
        );
        let newObject = {
          id: item.id,
          value: item.associated_org_id,
          label: filteredOrg[0]?.name,
        };
        return newObject;
      });
      setAssociatedClients(associatedOrgs);
      if (clientObj.clientParent) {
        try {
          const parentsArray = clientObj.clientParent?.data?.parents;
          if (parentsArray?.length > 0) {
            const org_ids = parentsArray.map((x) => x.org_id);
            const parentOrgName = clients.data.filter((x) =>
              org_ids.includes(x.id)
            );
            setParentOrgName(parentOrgName.map((x) => x.name).join(" , "));
          } else {
            setParentOrgName(null);
          }
        } catch (e) {
          console.log(e);
        }
      }
      clients?.data?.forEach((client) => {
        if (clientId !== client.id) {
          const value = {
            value: client.id,
            label: client.name,
            active: client.active,
          };
          temp.push(value);
        }
      });
      setAssociatedClientOptions(
        orderAlphabaticallyByKey(temp.filter((x) => x.active))
      );
    }
  }, [client, clients, contractorsWithAddress]);

  React.useEffect(() => {
    let associatedServices = client?.client_organization_services?.map(
      (item) => {
        const filteredService = servicesData?.services?.data.filter(
          (x) => x.id === item.service_id
        );
        let newObject = {
          id: item.id,
          value: item.service_id,
          label: filteredService[0]?.name,
          type: item.type,
          rate: item.rate,
        };
        return newObject;
      }
    );
    setAssociatedServices(associatedServices);
  }, [client?.client_organization_services, servicesData?.services?.data]);

  React.useEffect(() => {
    if (servicesData?.services?.data) {
      const data = servicesData?.services?.data;

      setServices(data.filter((x) => x.active));
    }
  }, [servicesData]);

  React.useEffect(() => {
    if (salePersonsData?.data) {
      const data = salePersonsData?.data;

      setSalePersons(data);
    }
  }, [salePersonsData]);

  React.useEffect(() => {
    if (clientId) {
      setIsFacilityCalled(false);
      getOrganization({ id: clientId });
      getParents({ id: clientId });
      getOrganizationNames();
    }
  }, [clientId]);

  const handleIcsChange = (event) => {
    setIcsChecked(event.target.checked);
    setFile('');
  };

  const handleUpdateButtonClick = async (facilityId) => {
    const data = facilities.find(
      (facility) => facility?.id === facilityId && facility?.name
    );

    // Trim each value of an object if the value is string
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            data[key] = typeof data[key] === 'string' ? data[key]?.trim() : data[key];
        }
    }

    if (data) {
      if (!data.address) {
        setErrorMessage("Address is required.");
        return;
      }
      if (!data.city) {
        setErrorMessage("City is required");
        return;
      }
      if (!data.zip_code) {
        setErrorMessage("Zip Code is required.");
        return;
      }
      editFacility(data);
      setUpdatedFacility(data);
    } else {
      showSnackbar("Facility Name cannot be blank.");
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  React.useEffect(() => {
    if (clientObj?.type === "DELETE_FACILITY_SUCCESS") {
      showSnackbar("Facility Deleted successfully!");
      if (deleteFacilityParams?.id && deleteFacilityParams?.org_id) {
        const updatedFacilities = facilities.filter(
          (facility) => facility.id !== deleteFacilityParams?.id
        );
        setNewFacilites([...updatedFacilities]);
        setSelectedFacilitiesData([...updatedFacilities]);
      }
      setOpen(false);
    }
    if (clientObj?.type === "DELETE_FACILITY_FAILURE") {
      showSnackbar(
        "The facility cannot be deleted as it is already being used for a spill."
      );
      setOpen(false);
    }
    if (clientObj?.type === "EDIT_FACILITY_FAILURE") {
      setUpdatedFacility(null)
      showSnackbar("Facility with this name already exists.");
    }
    if (clientObj?.type === "EDIT_FACILITY_SUCCESS") {
      setDownloadFacilities(prev => {
        return prev.map(facility => {
          if(facility?.id === updatedFacility?.id) {
            return updatedFacility;
          }
          return facility;
        })
      })
      showSnackbar("Facility updated successfully!");
    }
  }, [clientObj?.type]);

  const handleDeleteFacilityClick = async (id, org_id, index) => {
    setDeleteFacilityParams({ id, org_id, index });
    if (id && org_id) {
      deleteFacility({ id, org_id });
      setTimeout(() => {
        setOpen(false);
      }, 300);
    } else {
      facilities.splice(index, 1);
      setNewFacilites([...facilities]);
      setSelectedFacilitiesData([...facilities]);
      setOpen(false);
    }
  };

  const validate = () => {
    if ((paymentTerms > 1 && paymentTerms < 100) || paymentTerms === "") {
      return [true];
    } else {
      return [false, "Value must be between 2 and 99"];
    }
  };
  const clientServicesOptions = services?.map((service) => {
    const value = {
      value: service.id,
      label: service.name,
      type: "",
      rate: "",
    };
    const allOptions = { ...value };

    return allOptions;
  });
  const handleServicesSelect = async (data) => {
    await setAssociatedServices([]);
    setAssociatedServices(data);
  };
  const handleServiceChange = (id, key) => (event) => {
    let newAssociatedServices = [...associatedServices];

    let data = newAssociatedServices.findIndex((obj) => obj.value === id);

    newAssociatedServices[data][key] =
      key === "rate" ? +event.target.value : event.target.value;

    if (key === "type") {
      if (event.target.value === "hourly") {
        newAssociatedServices[data]["rate"] = +(
          rate || newAssociatedServices[data]["rate"]
        );
      } else {
        newAssociatedServices[data]["rate"] = +(
          newAssociatedServices[data]["rate"] || rate
        );
      }
    }

    newAssociatedServices[data]["type"] =
      newAssociatedServices[data]["type"] || "hourly";
    setAssociatedServices(newAssociatedServices);
  };
  const handleRateChange = (event) => {
    setRate(event.target.value);
    setAssociatedServices((val) =>
      val?.map((service) => {
        return {
          ...service,
          rate: service.type ? service.rate : event.target.value,
        };
      })
    );
  };
  const validateForm = (e) => {
    const errors = {};
    if (!name || name.trim() === "") {
      errors.name = "- Client Name should not be blank.";
    }
    if (!e.target.tax_id?.value || e.target.tax_id?.value.trim() === "") {
      errors.taxId = "- Tax Id should not be blank.";
    }
    if (!e.target.code?.value || e.target.code?.value.trim() === "") {
      errors.code = "- Code should not be blank.";
    }
    if (e.target.code?.value) {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (e.target.code?.value?.trim()?.match(regEx) === null) {
        errors.code = "- Code: Please enter letters and numbers only.";
      }
    }
    if (!rate || rate.trim() === "") {
      errors.rate = "- Rate should not be blank.";
    }
    if (!e.target.paymentTerms?.value || e.target.paymentTerms?.value?.trim() === "") {
      errors.paymentTerms = "- Payment Terms should not be blank.";
    }
    if (!e.target.sales_person?.value || e.target.sales_person?.value?.trim() === "") {
      errors.salesperson = "- Salesperson should not be blank.";
    }
    const facilityName = facilities?.find(item => !item?.name?.trim() && 
      (item?.internal_id || 
      item?.generator_status ||
      item?.epa_id ||
      item?.phone_number ||
      item?.address ||
      item?.region ||
      item?.state ||
      item?.city ||
      item?.zip_code ||
      item?.is_active));
    if (facilityName) {
      errors.facilityName = "- Facility Name should not be blank.";
    }
    const facilityAddress = facilities?.find((item) => item?.name && !item?.address?.trim());
    if (facilityAddress) {
      errors.facilityAddress = "- Facility Address should not be blank.";
    }
    const facilityCity = facilities?.find((item) => item?.name && !item?.city?.trim());
    if (facilityCity) {
      errors.facilityCity = "- Facility City should not be blank.";
    }
    const facilityZipCode = facilities?.find((item) => item?.name && !item?.zip_code?.trim());
    if (facilityZipCode) {
      errors.facilityZipCode = "- Facility Zip Code should not be blank.";
    }
    const combinedMessage = [
      errors.name || "",
      errors.taxId || "",
      errors.code || "",
      errors.salesperson || "",
      errors.rate || "",
      errors.paymentTerms || "",
      errors?.facilityName || "",
      errors?.facilityAddress || "",
      errors?.facilityCity || "",
      errors?.facilityZipCode || "",
      // errors?.facilityEpaId || "",
      errors?.duplicateFacility || ""
    ].filter((message) => message !== "")
      .join("\n");
    if (combinedMessage) {
      return combinedMessage;
    }
    const result = validate();
    if (!result[0]) {
      return "- Payment Terms Value must be between 2 and 99";
    }
  };
  const hideError = () => {
    setErrorMessage('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(e);
    if (errors) {
      setErrorMessage(errors);
    } else {
      const updatedContractorsToSend = updatedContractorData.map((contractor) => {
        if (contractor.checked && contractor?.contractor_id) {
          return {
            contractor_id: contractor?.contractor_id,
            address_id: contractor?.addressId || null,
          };
        }
      });
      const uncheckedContractorsToSend = updatedContractorData.map(
        (contractor) => {
          if (!contractor?.checked && contractor?.contractor_id) {
            return {
              contractor_id: contractor?.contractor_id,
              address_id: contractor?.addressId || null,
            };
          }
        }
      );
      setSelectedContractors(updatedContractorsToSend);
      let dataUpdated = false;
      const modifiedData = {
        hazardous_waste: specialInstructionsData?.hazardous_waste,
        non_hazardous_waste: specialInstructionsData?.non_hazardous_waste,
      }
      const hasValue = Object.values(modifiedData).filter((item) => item);
      if (hasValue.length && (specialInstructionsData?.revision_date || !objectsHaveSameKeysAndValues(client, specialInstructionsData, KEYS_TO_CHECK.CLIENT))) {
        dataUpdated = true;
      }
      let newFacilities = [];
      for (let index = 0; index < facilities.length; index++) {
        const element = facilities[index];
        if ((element?.id && typeof element?.id === 'number') || !element?.id) {
          newFacilities.push(element);
        }
      }

      // Trim each value of an aray if the value of an object is strings
      newFacilities = newFacilities?.map((eachFacility) => {
        const trimmedObj = {};
        for (const key in eachFacility) {
            if (eachFacility.hasOwnProperty(key)) {
                trimmedObj[key] = typeof eachFacility[key] === 'string' ? eachFacility[key].trim() : eachFacility[key];
            }
        }
        return trimmedObj;
      });

      let obj = {
        id: clientId,
        name: name && name?.trim() !== "" ? name.trim() : '',
        is_ics_client: checked ? true : false,
        note_work_time_validation: validateNoteTimeChecked ?? false,
        sales_person: e.target.sales_person?.value ? e.target.sales_person?.value : null,
        tax_id: e.target.tax_id?.value ? e.target.tax_id?.value : "",
        code: e.target.code?.value ? e.target.code?.value?.trim() : "",
        active: e.target.active?.value ? e.target.active?.value : false,
        rate: rate,
        paymentTerms: e.target.paymentTerms?.value || "",
        associatedClients,
        associatedServices: associatedServices?.map((service) => ({
          ...service,
          type: service.type || "hourly",
          rate: service.type ? service.rate : rate,
        })),
        timestampVisibility: canSeeTimestamp,
        monetaryVisibility: canSeeMonetary,
        defaultParentUsers: canSeeParentDefaultUsers,
        contractors: updatedContractorsToSend,
        uncheckedContractors: uncheckedContractorsToSend,
        ...specialInstructionsData,
        facilities: newFacilities,
        ...mainAddressFormData,
        revision_date: dataUpdated ? moment(selectedDate)?.format("YYYY-MM-DD") : null
      };
      const formData = new FormData();
      if (file) {
        formData.append("file", file, `client_facility#${file.name}`);
      }
      formData.append("clientRequest", JSON.stringify(obj));
      editClient(formData);
      setReqSubmitted(true);
    }
  };

  React.useEffect(() => {
    if (!clientObj.loading && reqSubmitted) {
      if (!clientObj.error) {
        closeEditModal(file && file.name);
      } else {
        setErrorMessage(clientObj.error);
      }
      setReqSubmitted(false);
    }
  }, [clientObj.loading]);

  return (
    <React.Fragment>
      { clientLoading || (clientObj.loading && reqSubmitted) ? (
        <div>Loading....</div>
      ) : (
        <div>
          <CustomSnackBar
            open={!!errorMessage}
            severity="error"
            snackBarMessage={errorMessage}
            onClose={hideError} 
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <SnackbarContent message={snackbarMessage} />
          </Snackbar>
          <AppBar position="static" color="primary">
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Edit Client" />
              {userRole == ROLES.SUPER_USER && <Tab label="Facilities" />}
              {userRole == ROLES.SUPER_USER && <Tab label="Contractor" />}
              {userRole == ROLES.SUPER_USER && <Tab label="Special Instructions" />}
            </Tabs>
          </AppBar>
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              paddingTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <div className={selectedTab === 0 ? "" : "hidden"}>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <TextField
                      id="name"
                      label="Name"
                      fullWidth
                      autoComplete="name"
                      value={name}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  {userRole === ROLES.SUPER_USER ? (
                    <>
                    <Grid item xs={3} className={classes.leftAlign}>   
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleIcsChange}
                            name="myCheckbox"
                            className={classes.labelColor}
                            defaultValue={client ? client.is_ics_client : ""}
                          />
                        }
                        label="is ICS Client?"
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.leftAlign}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={validateNoteTimeChecked}
                            onChange={(event) => setValidateNoteTimeChecked(event?.target?.checked)}
                            name="note_work_time_validation"
                            className={classes.labelColor}
                            defaultValue={client?.note_work_time_validation }
                          />
                        }
                        label="Validate Note Time?"
                      />
                    </Grid>
                    </>
                  ) : null}
                  {parentOrgName && (
                    <Grid item xs={6} className={classes.leftAlign}>
                      <Typography
                        variant="subtitle"
                        gutterBottom
                        className={classes.labelColor}
                      >
                        Parent Organization:
                      </Typography>
                      <span className={classes.parentNames}>{parentOrgName}</span>
                    </Grid>
                  )}
                  {parentOrgName && (
                    <Grid item xs={6} className={classes.leftAlign}>
                      <Switch
                        labelId="default-user-label"
                        id="default-switch-parent-user"
                        checked={canSeeParentDefaultUsers}
                        onChange={(event) =>
                          setCanSeeParentDefaultUsers(event.target.checked)
                        }
                        name="canSeeParentDefaultUsers"
                        color="primary"
                      />
                      <Typography variant="subtitle" gutterBottom>
                        Parent Organization User's as Default
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.leftAlign}>
                    <Typography
                      variant="subtitle"
                      gutterBottom
                      className={classes.labelColor}
                    >
                      Child Organizations
                    </Typography>
                    <ReactSelect
                      value={associatedClients}
                      required
                      isMulti
                      onChange={setAssociatedClients}
                      options={associatedClientOptions}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="taxId"
                      name="tax_id"
                      label="Tax ID"
                      fullWidth
                      autoComplete="tax_id"
                      defaultValue={client ? client.tax_id : ""}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="code"
                      name="code"
                      label="Code"
                      fullWidth
                      autoComplete="code"
                      defaultValue={client ? client.code : ""}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.leftAlign}>
                    <InputLabel
                      id="salesPerson-select-label"
                      className={classes.labelColor}
                    >
                      Salesperson
                    </InputLabel>
                    <Select
                      labelId="salesPerson-select-label"
                      id="salesPerson-select"
                      name="sales_person"
                      style={{ width: "100%" }}
                      defaultValue={client ? client.sales_person_id : ""}
                    >
                      <MenuItem value="">
                        <em>Select Salesperson</em>
                      </MenuItem>
                      {salePersons &&
                        orderAlphabaticallyByKey(salePersons, "full_name")?.map(
                          (e) => {
                            return (
                              <MenuItem
                                value={e.id}
                              >{`${e.full_name} (${e.client_organization.name}: ${e.role.role})`}</MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="rate"
                      name="rate"
                      label="Rate"
                      fullWidth
                      autoComplete="rate"
                      onChange={handleRateChange}
                      value={rate}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="paymentTerms"
                      name="paymentTerms"
                      label="Payment Terms"
                      fullWidth
                      autoComplete="paymentTerms"
                      value={paymentTerms}
                      onChange={(e) => setPaymentTerms(e.target.value)}
                      onBlur={() => {
                        const result = validate();
                        if (result[0]) {
                          setErrorText("");
                        } else {
                          setErrorText(result[1]);
                        }
                      }}
                      error={errorText ? true : false}
                      helperText={errorText || ""}
                    />
                  </Grid>

                  {userRole === ROLES.SUPER_USER ? (
                    <>
                      <Grid item xs={4} className={classes.leftAlign}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={canSeeMonetary}
                              onChange={(event) =>
                                setCanSeeMonetary(event.target.checked)
                              }
                              name="monetaryAmountsVisible"
                              color="primary"
                            />
                          }
                          label="View Monetary Amounts"
                        />
                      </Grid>
                      <Grid item xs={8} className={classes.leftAlign}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={canSeeTimestamp}
                              onChange={(event) =>
                                setCanSeeTimestamp(event.target.checked)
                              }
                              name="timeStampVisibile"
                              color="primary"
                            />
                          }
                          label="View Timestamps"
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.leftAlign}>
                        <Typography
                          variant="subtitle"
                          gutterBottom
                          className={classes.labelColor}
                        >
                          Services
                        </Typography>
                        <ReactSelect
                          value={associatedServices}
                          required
                          isMulti
                          onChange={(data) => handleServicesSelect(data)}
                          options={orderAlphabaticallyByKey(clientServicesOptions)}
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12}>
                    {associatedServices?.length > 0 ? (
                      <div>
                        <div>
                          <Card
                            className={classes.headerCard}
                            style={{ margin: "15px 0px 0px 0px", width: "100%" }}
                          >
                            <CardContent
                              style={{
                                padding: "10px",
                                backgroundColor: "#e6e6e6d6",
                                fontWeight: 600,
                                color: "#6aa16c",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              ></div>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <div className={classes.leftAlign}>
                                    Service Name
                                  </div>
                                </Grid>

                                <Grid item xs={3}>
                                  <div className={classes.leftAlign}>
                                    Service Type
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div className={classes.leftAlign}>Rate</div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          {associatedServices &&
                            associatedServices?.map((service, i) => (
                              <List
                                component="nav"
                                className={classes.root}
                                aria-label="mailbox folders"
                              >
                                <ListItem>
                                  <Grid container spacing={3}>
                                    <Grid
                                      item
                                      xs={6}
                                      className={classes.serviceLabel}
                                    >
                                      <div className={classes.leftAlign}>
                                        <span>{service.label}</span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div className={classes.leftAlign}>
                                        {
                                          <Select
                                            labelId="rate-type-select-label"
                                            id="rate-type-select"
                                            name="rateType"
                                            style={{ width: "100%" }}
                                            defaultValue={service?.type || "hourly"}
                                            onChange={handleServiceChange(
                                              service.value,
                                              "type"
                                            )}
                                            required
                                          >
                                            <MenuItem value={"fixed"}>
                                              Fixed
                                            </MenuItem>
                                            <MenuItem value={"hourly"}>
                                              Hourly
                                            </MenuItem>
                                          </Select>
                                        }
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div className={classes.leftAlign}>
                                        {
                                          <TextField
                                            id="rate"
                                            name="rate"
                                            fullWidth
                                            autoComplete="rate"
                                            required
                                            defaultValue={
                                              associatedServices[i].type
                                                ? associatedServices[i].rate || rate
                                                : rate
                                            }
                                            value={
                                              associatedServices[i].type
                                                ? associatedServices[i].rate
                                                : rate
                                            }
                                            onChange={handleServiceChange(
                                              service.value,
                                              "rate"
                                            )}
                                            type={"number"}
                                          />
                                        }
                                      </div>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                                <Divider />
                              </List>
                            ))}
                        </div>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={6} className={classes.leftAlign}>
                    <InputLabel
                      id="active-select-label"
                      className={classes.labelColor}
                    >
                      Active
                    </InputLabel>
                    <Select
                      labelId="active-select-label"
                      id="active-select"
                      name="active"
                      defaultValue={client ? client.active : ""}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </Grid>

              <Grid item xs={12}>
                <MainAddressForm
                  mainAddformData={mainAddressFormData}
                  onChange={setMainAddressFormData}
                  isOrg={true}
                  showResponsePills={false}
                ></MainAddressForm>
              </Grid>
            </Grid>
          </>
        </div>
        <div className={selectedTab === 1 ? '' : 'hidden'}>
          
          <>
          {checked ? (
                <TeamTerminal 
                file={file}
                setFile={setFile}
                TeamData={TeamData}
                fileUpload={fileUpload}
                editMode={true}
                isNotICS={isNotICS}
                totalTerminals={totalTerminals}
                setTotalTerminals={setTotalTerminals}

                />
              ) : (
                <Facilities
                  setOpen={setOpen}
                  open={open}
                  file={file}
                  setFile={setFile}
                  facilitiesFormData={facilities}
                  onChange={setSelectedFacilitiesData}
                  onEditChange={setNewFacilites}
                  editMode={true}
                  onUpdateButtonClick={handleUpdateButtonClick}
                  onDeleteFacilityClick={handleDeleteFacilityClick}
                  isFacilityLoading={isFacilityLoading}
                  getAllfacilities={getAllfacilities}
                  totalFacility ={totalFacility}
                  setTotalFacility={setTotalFacility}
                  downloadFacilities={downloadFacilities}
                />
              )}
          </>
        </div>
        <div className={selectedTab === 2 ? '' : 'hidden'}>
          <ContractorClient
            updatedContractorData={updatedContractorData}
            setUpdatedContractorData={setUpdatedContractorData}
            contractorsFromEdit={selectedContractors}
          />
        </div>
        <div className={selectedTab === 3 ? '' : 'hidden'}>
          <SpecialInstruction
            formData={specialInstructionsData}
            onChange={setSpecialInstructionsData}
            selectedDate={selectedDate}
            onDateChange={handleDateChange}
            editMode={true}
            lastRevisionDate={lastRevisionDate}
          />
        </div>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            type='submit'
          >
            Update
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => closeEditModal()}
          >
            Cancel
          </Button>
        </Grid>
        </form>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({
  client,
  contractor: { contractorsWithAddress },
}) => {
  return {
    clientObj: client,
    client: client.currentClient.data,
    clientLoading: client.currentClient.loading,
    clients: client.clientOrganizationNames,
    contractorsWithAddress,
    getAllfacilities: client?.facilities
  };
};

const mapDispatchToProps = (dispatch) => ({
  getParents: bindActionCreators(clientActions.getParents, dispatch),
  getOrganization: bindActionCreators(clientActions.getOrganization, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  editFacility: bindActionCreators(clientActions.editFacility, dispatch),
  deleteFacility: bindActionCreators(clientActions.deleteFacility, dispatch),
  editClient: bindActionCreators(clientActions.editClient, dispatch),
  getIcsTerminal: bindActionCreators(clientActions.getIcsTerminal, dispatch),
  getFacility: bindActionCreators(clientActions.getFacility, dispatch),

});

EditClient.prototype = {
  client: PropTypes.object.isRequired,
  getParents: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  editFacility: PropTypes.object.isRequired,
  deleteFacility: PropTypes.object.isRequired,
  editClient: PropTypes.func.isRequired,
  getIcsTerminal: PropTypes.object.isRequired,

};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditClient)
);
