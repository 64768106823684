import { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";

const PaginationButtons = ({
  from,
  to,
  page,
  count,
  rowsPerPage,
  handleChangePage,
  setIsAppendNoteLast,
}) => {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [pageVal, setPageVal] = useState(page + 1);
  const [dataFetch, setDataFetch] = useState(false);

  useEffect(() => {
    setPageVal(page + 1);
    setErrorText(null);
    setError(false);
  }, [page, rowsPerPage]);

  useEffect(() => {
    const currentPage = page + 1;
    const lastPage = Math.ceil(count / rowsPerPage);
    let isToAppendNoteLast = false;

    if (currentPage === lastPage) {
      isToAppendNoteLast = true;
    }

    setIsAppendNoteLast(isToAppendNoteLast);
  }, [page, rowsPerPage, count]);

  const handleOnBlur = (event) => {
    setDataFetch(false);
    const newVal = event.target.value;
    const lastPage = Math.ceil(count / rowsPerPage);
    if (newVal > 0 && newVal <= lastPage) {
      handleChangePage(null, newVal - 1);
      setError(false);
    } else {
      setError(true);
      newVal <= 0 && setErrorText("Should be greater than 0.");
      newVal > lastPage &&
        setErrorText("Should be less than " + (lastPage + 1));
    }
  };

  const handleOnChange = (event) => {
    setPageVal(event.target.value);
    setDataFetch(true);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <Typography>
        {from}-{to} of {count}
      </Typography>
      <TextField
        label="Page"
        defaultValue={page + 1}
        error={error}
        onChange={handleOnChange}
        onBlur={(event) => {
          dataFetch && handleOnBlur(event);
        }}
        onKeyPress={(event) => {
          event.charCode == 13 && dataFetch && handleOnBlur(event);
        }}
        type="number"
        inputProps={{
          value: pageVal,
        }}
        helperText={error && errorText}
        style={{ width: "100px", textAlign: "center", marginLeft: "25px" }}
      />
      <Typography>of {Math.ceil(count / rowsPerPage)}</Typography>
    </div>
  );
};
export default PaginationButtons;
