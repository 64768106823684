import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import user from "./User";
import agency from "./Agency";
import client from "./Client";
import contractor from "./Contractor";
import spill from "./Spill";
import service from "./Service";
import search from "./Search";
import download from "./Download";
import action from "./Action";
import analytics from "./Analytics";

export default combineReducers({
  user,
  agency,
  client,
  contractor,
  spill,
  service,
  search,
  download,
  form,
  action,
  analytics,
});
