export const getContractors = (obj) => ({
  type: 'GET_CONTRACTORS',
  obj,
});

export const getContractorsSuccess = (data) => ({
  type: 'GET_CONTRACTORS_SUCCESS',
  data,
});

export const getContractorsFail = (error) => ({
  type: 'GET_CONTRACTORS_FAIL',
  error,
});

export const getContractorERailClassICertifications = () => ({
  type: 'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS',
});

export const getContractorERailClassICertificationsSuccess = (data) => ({
  type: 'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS_SUCCESS',
  data,
});

export const getContractorERailClassICertificationsFail = (error) => ({
  type: 'GET_CONTRACTORS_ERAIL_CLASS_I_CERTIFICATIONS_FAIL',
  error,
});

export const createNewContractor = (obj) => ({
  type: 'CREATE_NEW_CONTRACTOR',
  obj,
});

export const createNewContractorSuccess = (data) => ({
  type: 'CREATE_NEW_CONTRACTOR_SUCCESS',
  data,
});

export const createNewContractorFail = (error) => ({
  type: 'CREATE_NEW_CONTRACTOR_FAIL',
  error,
});

export const editContractor = (obj) => ({
  type: 'EDIT_CONTRACTOR',
  obj,
});

export const editContractorSuccess = (data) => ({
  type: 'EDIT_CONTRACTOR_SUCCESS',
  data,
});

export const editContractorFail = (error) => ({
  type: 'EDIT_CONTRACTOR_FAIL',
  error,
});

export const getContractorsWithAddress = (obj) => ({
  type: 'GET_CONTRACTORS_WITH_ADDRESS',
  obj,
});

export const getContractorsWithAddressSuccess = (data) => ({
  type: 'GET_CONTRACTORS_WITH_ADDRESS_SUCCESS',
  data,
});

export const getContractorsWithAddressFail = (error) => ({
  type: 'GET_CONTRACTORS_WITH_ADDRESS_FAIL',
  error,
});

export const getContractorsLocation = () => ({
  type: 'GET_CONTRACTORS_LOCATION',
});

export const getContractorsLocationSuccess = (data) => ({
  type: 'GET_CONTRACTORS_LOCATION_SUCCESS',
  data,
});

export const getContractorsLocationFail = (error) => ({
  type: 'GET_CONTRACTORS_LOCATION_FAIL',
  error,
});

export const uploadCertificate = (obj) => ({
  type: 'UPLOAD_CERTIFICATE',
  obj,
});

export const uploadCertificateSuccess = (data) => ({
  type: 'UPLOAD_CERTIFICATE_SUCCESS',
  data,
});

export const uploadCertificateFail = (error) => ({
  type: 'UPLOAD_CERTIFICATE_FAIL',
  error,
});

export const deleteContractorCertificate = (obj) => ({
  type: 'DELETE_CONTRACTOR_CERTIFICATE',
  obj,
});

export const deleteContractorCertificateSuccess = (data) => ({
  type: 'DELETE_CONTRACTOR_CERTIFICATE_SUCCESS',
  data,
});

export const deleteContractorCertificateFail = (error) => ({
  type: 'DELETE_CONTRACTOR_CERTIFICATE_FAIL',
  error,
});

export const contractorDoNotUse = (obj) => ({
  type: 'CONTRACTOR_DO_NOT_USE',
  obj,
});

export const contractorDoNotUseSuccess = (data) => ({
  type: 'CONTRACTOR_DO_NOT_USE_SUCCESS',
  data,
});

export const contractorDoNotUseFail = (error) => ({
  type: 'CONTRACTOR_DO_NOT_USE_FAIL',
  error,
});
