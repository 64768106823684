import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  welcomeText: {
    textAlign: 'center',
  },
  homePageDivider: {
    marginBottom: '2%',
  },
});

export default styles;
