import { all, put, takeLatest } from "redux-saga/effects";

import {
  getSignedUrlsSuccess,
  getSignedUrlsFail,
  downloadSuccess,
  downloadFailure,
  downloadFileSuccess,
  downloadFileFail,
  downloadFileTemp,
} from "../actionCreators/Download";
import { downloadHelper, doPost, doGet } from "../utils";
import { REQUEST_TYPE } from "../utils/index";

function* download({ obj }) {
  try {
    const response = yield doPost(
      "/download/data",
      obj,
      null,
      REQUEST_TYPE.DOWNLOAD
    );
    yield put(downloadSuccess(response.data));
  } catch (err) {
    yield put(downloadFailure(err.response.data.message || err.message));
    yield put(downloadFailure());
  }
}

function* downloadFile({ data }) {
  try {
    yield put(downloadFileTemp({ data }));
    const response = yield doPost(
      data?.url || "download/file",
      { data },
      null,
      REQUEST_TYPE.DOWNLOAD
    );

    var element = document.createElement("a");
    element.setAttribute("href", response?.data?.url);

    element.style.display = "none";

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    yield put(downloadFileSuccess());
  } catch (err) {
    yield put(downloadFileFail(err?.response?.data?.message || err?.message));
    // yield put(downloadFileFail());
  }
}

function* getSignedUrls({ data }) {
  try {
    const response = yield doPost(
      "/download/getUrl",
      data,
      null,
      REQUEST_TYPE.DOWNLOAD
    );

    yield put(getSignedUrlsSuccess(response.data));
  } catch (err) {
    yield put(getSignedUrlsFail());
  }
}

function* downloadAll({ data }) {
  try {
    const response = yield doGet(
      `/download/all?id=${data.id}&type=${data.type}&spillId=${data.spillId}&ipAddress=${data.ipAddress}`,
      {
        responseType: "blob",
      },
      REQUEST_TYPE.DOWNLOAD
    );
    downloadHelper(response.data, data.name, true);
    yield put(downloadFileSuccess());
  } catch (err) {
    yield put(downloadFileFail(err.response.data.message || err.message));
    yield put(downloadFileFail());
  }
}

function* downloadAdminFiles({ data }) {
  try {
    const response = yield doGet(
      `/download/admin?id=${data.id}`,
      {
        responseType: "blob",
      },
      REQUEST_TYPE.DOWNLOAD
    );
    downloadHelper(response.data, data.name, true);
    yield put(downloadFileSuccess());
  } catch (err) {
    yield put(downloadFileFail(err.response.data.message || err.message));
    yield put(downloadFileFail());
  }
}

export default function* watcher() {
  yield all([takeLatest("GET_SIGNED_URL", getSignedUrls)]);
  yield all([takeLatest("DOWNLOAD", download)]);
  yield all([takeLatest("DOWNLOAD_FILE", downloadFile)]);
  yield all([takeLatest("DOWNLOAD_ALL", downloadAll)]);
  yield all([takeLatest("DOWNLOAD_ADMIN_FILES", downloadAdminFiles)]);
}
