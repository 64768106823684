import moment from "moment";
import XlsxPopulate from "xlsx-populate";
import { XLSX_DATATYPE, XLSX_FORMAT } from './keyMappers';

export const csvDownloader = async (data, type) => {
  const processedData = [data[1]];
  for (let index = 2; index < data.length; index++) {
    const itemArray = [];
    const element = data[index];
    for (let i = 0; i < element.length; i++) {
      const item = element[i];

      if (item) {
        if (!isNaN(item) && typeof item !== 'boolean') {
          if (Number.isInteger(Number(item))) {
            itemArray.push(parseInt(item, 10));
          } else {
            itemArray.push(parseFloat(item));
          }
        } else if (moment(item, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
          const formattedDateTime = moment(item).toDate();
          itemArray.push(formattedDateTime);
        } else {
          itemArray.push(item);
        }
      } else {
        itemArray.push(item);
      }
    }
    if (itemArray.length) {
      processedData.push(itemArray);
    }
  }

  const workbook = await XlsxPopulate.fromBlankAsync();

  // Get the first sheet
  const sheet = workbook.sheet(0);

  // Apply styling to the header row
  const headerRow = sheet.row(1);
  headerRow.style({
    bold: true,
    fill: {
      type: "pattern",
      pattern: "darkDown",
      foreground: {
        rgb: "2F7D32",
      },
    },
    fontColor: "000000", // Text color (white)
    border: {
      style: "thin",
      color: "000000",
      size: 1,
    },
  });

  // DEFINE THE FORMAT FOR ALL THE COLUMNS dOF THE XLSX FILE
  const columnDataTypes = data[0];
  for (let i = 0; i < columnDataTypes?.length; i++) {
    const columnIndex = i + 1;
    let columnFormat = false; //DEFAULT FORMAT IS FALSE

    if (columnDataTypes[i]["type"] === XLSX_DATATYPE.DATE) {
      columnFormat = XLSX_FORMAT.DATE_FORMAT;
    }
    if (columnFormat) {
      sheet.column(columnIndex).style({
        numberFormat: columnFormat,
      });
    }
  }

  // Set the data in the sheet
  sheet.cell("A1").value(processedData);

  // Calculate column widths based on content
  const maxColumnWidths = calculateMaxColumnWidths(processedData);
  setColumnWidths(sheet, maxColumnWidths);

  // Generate the Excel file
  const excelData = await workbook.outputAsync();

  // Convert the Excel data to a Blob object
  const blob = new Blob([excelData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  // Create a download link and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${type}-${moment(new Date())?.format("DD-MM-YYYY h:mm:ss")}.xlsx`;
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Calculate maximum content length for each column
const calculateMaxColumnWidths = (processedData) => {
  // go throuth each column of a row and find the max ontent length from the column and set that as a column width
  return processedData[0].map((_, columnIndex) => {
    return Math.max(
      ...processedData.map((col) => {
        const cellValue = col[columnIndex];
        const cellLength = cellValue instanceof Date
          ? moment(cellValue)?.format("MM-DD-YYYY hh:mm:ss").length + 5
          : cellValue ? cellValue.toString().length : 0;
        return cellLength;
      })
    );
  });
};

// Set column widths based on maximum content length
const setColumnWidths = (sheet, maxColumnWidths) => {
  for (let i = 0; i < maxColumnWidths.length; i++) {
    const columnWidth = maxColumnWidths[i] + 2; // Add extra padding
    sheet.column(i + 1).width(columnWidth);
  }
};