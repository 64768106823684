import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import ProgressiveBillingTable from './ProgressiveBillingTable';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  typography: {
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center"
  },

  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 45px",
  },
  popupMessage: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  infoIcon: {
    cursor: "pointer"
  },
  finalInvoiceContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  finalInvoiceBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: "3px",
    marginRight: "5px",
    padding: "7px 0"
  },
  finalInvoiceCheckbox: {
    margin: "0 -8px -10px 0"
  },
  warningNote: {
    color: "#F40303",
    fontSize: "13px",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "-6px",
    marginRight: "20px"
  },
  datePicker: {
    marginRight: '8px',
    boxSizing: "border-box"
  }
}));

const ProgressiveBillingModal = ({headingText, startDateText, endDateText, submitText, progressiveBillingStartDate, setProgressiveBillingStartDate,progressiveBillingEndDate, setProgressiveBillingEndDate,setShowModal, handleGenerateInvRepPkg,  partialBillingDownloadData, index,defaultReportStartTime,finalInvoiceFlag, setFinalInvoiceFlag, handleAttachmentDownload,downloadDisabled}) => {

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("")
  const [partialBillingDownloadFilterData, setPartialBillingDownloadFilterData] = useState(partialBillingDownloadData?.billingData)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    partialBillingDownloadData?.report_start_date? setProgressiveBillingStartDate(partialBillingDownloadData?.report_start_date) : setProgressiveBillingStartDate(defaultReportStartTime)
    setProgressiveBillingEndDate(new Date())
  },[])

  // useEffect to constantly monitor the start date and end date and if it get changes then filter the biling data for admin accordingly

  useEffect(() => {
    setPartialBillingDownloadFilterData(() => {
  
      if (!partialBillingDownloadData) {
        return null; // or an appropriate default value
      }
  
      const filteredData = partialBillingDownloadData?.billingData?.filter(item => {
        const startDateUTC = moment(item?.report_start_date).utc();
        const endDateUTC = moment(item?.report_end_date).utc();
  
        // Convert UTC dates to local dates
        const startDateLocal = startDateUTC.local();
        const endDateLocal = endDateUTC.local();
  
        
        return startDateLocal.isBetween(progressiveBillingStartDate, progressiveBillingEndDate, 'seconds', '()') || endDateLocal.isBetween(progressiveBillingStartDate,progressiveBillingEndDate, 'seconds', '()');
      });
  
      return filteredData;
    });
  }, [progressiveBillingStartDate, progressiveBillingEndDate, partialBillingDownloadData]);

  const changeDate = (date, field) => {

    setDisabled(false)
    setErrorMessage("")

    if(field === startDateText) {
      setProgressiveBillingStartDate(date)
    } else {
      setProgressiveBillingEndDate(date)
    }
  
  }

  const handleSubmit = () => {
    setFinalInvoiceFlag(false)
    setDisabled(true)

    const isStartDateValid =
      progressiveBillingStartDate?._d instanceof Date ||
      moment(progressiveBillingStartDate)?._isValid;
  
    const isEndDateValid =
      progressiveBillingEndDate?._d instanceof Date ||
      moment(progressiveBillingEndDate)?._isValid;
  
    const isStartDateBeforeEndDate =
      moment(progressiveBillingStartDate)?.isBefore(progressiveBillingEndDate);
  
    const isEndDateNotInFuture = moment(progressiveBillingEndDate)?.isSameOrBefore(moment());
  
    if (!isStartDateValid || !isEndDateValid || !isStartDateBeforeEndDate || !isEndDateNotInFuture) {
      if (!isStartDateBeforeEndDate) {
        setErrorMessage("The report start date cannot be later than the report end date.");
      } else if (!isEndDateNotInFuture) {
        setErrorMessage("The end date for the report cannot be set in the future.");
      }
      return;
    }
  
    setShowModal(false);
    handleGenerateInvRepPkg(index, "InvoiceReportPkg",true);
  };

  const handleCheckboxChange = (e) => {
    setDisabled(false)
    setFinalInvoiceFlag(e?.target?.checked);
  };

  return (
    <Box m={"10px 0"}>
      <>
      <Grid spacing={3}>
        <Typography variant={"h5"} color="primary" align='center'>
          <b>{headingText}</b>
        </Typography>
      </Grid>
      <hr></hr>
      <Container style={{display:"flex", justifyContent:"center"}}>
        {errorMessage ? <Alert className={classes.popupMessage} severity="error">{errorMessage}</Alert> : null}
      </Container>
        <Container className={classes.dateContainer}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              maxDate={new Date()}
              value={progressiveBillingStartDate}
              onChange={(date) => changeDate(date, startDateText)}
              label={"Start Date"}
              format="MM-DD-YYYY hh:mm a"
              showTodayButton
              className={classes.datePicker}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              maxDate={new Date()}
              value={progressiveBillingEndDate}
              onChange={(date) => changeDate(date, endDateText)}
              label={"End Date"}
              format="MM-DD-YYYY hh:mm a"
              showTodayButton
              className={classes.datePicker}
            />
          </MuiPickersUtilsProvider>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              style={{alignSelf:"flex-end"}}
              onClick={handleSubmit}
              disabled={disabled}
            >
              {submitText}
            </Button>
        </Container>

        <Container>
          <Typography className={`${classes.typography} ${classes.warningNote}`} >Please take note that the invoice report will be generated between the selected start date and end date.</Typography>
        </Container>

        <Container className={classes.finalInvoiceContainer}>
          <Box className={classes.finalInvoiceBox}>
            <Checkbox className={classes.finalInvoiceCheckbox} onChange={handleCheckboxChange} checked={finalInvoiceFlag}></Checkbox>

            <Typography className={classes.typography} >Final Invoice</Typography>
            <InfoIcon fontSize='5px' titleAccess='On selecting the final invoice, all notes and their attachment will be added to the report and the status will change to "Open: Ready to close".' className={classes.infoIcon} />
          </Box>
        </Container>

      {/* table contains the history of downlaoded partial billing information */}
      <ProgressiveBillingTable
        partialBillingDownloadData={partialBillingDownloadFilterData}
        handleAttachmentDownload={handleAttachmentDownload}
        downloadDisabled={downloadDisabled}
      />
    </> 
    </Box>
  );
};

export default ProgressiveBillingModal;
