import { makeStyles, Typography, ClickAwayListener } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import React from "react";
import { CustomDropDown } from ".";
import { orderAlphabaticallyByKey } from "../../utils/contractorLabel";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
  },
  displayContainer: {
    borderBottom: "1px solid grey",
    transition: "all 0.4s",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 20px",
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
    "&:hover": {
      cursor: "pointer",
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  dropIcon: {
    color: "grey",
  },
  menuContainer: {
    top: 17,
    transition: "all 0.4s",
    opacity: 0,
    borderRadius: "7px",
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    position: "absolute",
    width: "100%",
    background: "white",
    zIndex: -1,
    maxHeight: "60vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "7px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(47 125 50 / 36%)",
      borderRadius: 30,
    },
  },
  menuItem: {
    height: 36,
    transition: "all 0.3s",
    textAlign: "left",
    paddingLeft: 16,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer",
    },
  },
  show: {
    opacity: 1,
    zIndex: 2,
  },
}));

const CustomSelect = ({
  options,
  onChange,
  value = null,
  valueKey = "name",
  idKey = "id",
}) => {
  const findValueById = () => {
    let valToReturn = null;
    options.map((option) => {
      if (option.options.length) {
        option.options.map((subOption) => {
          if (subOption[idKey] === value) valToReturn = subOption[valueKey];
        });
      } else {
        if (option[idKey] === value) valToReturn = option[valueKey];
      }
    });
    return valToReturn;
  };

  const [selected, setSelected] = React.useState(
    value ? findValueById() : null
  );
  const [show, setShow] = React.useState(false);

  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className={classes.mainContainer}>
        <div className={classes.displayContainer} onClick={() => setShow(true)}>
          <Typography variant="body1">{selected}</Typography>
          <KeyboardArrowDownIcon className={classes.dropIcon} />
        </div>

        <div className={`${classes.menuContainer} ${show && classes.show}`}>
          {options.map((value) =>
            value?.options?.length ? (
              <CustomDropDown
                values={{ valueKey, value, idKey }}
                setSelected={(val) => {
                  setSelected(val);
                  setShow(false);
                }}
                selectedOption={selected}
                onChange={onChange}
              />
            ) : (
              <Typography
                className={classes.menuItem}
                onClick={(e) => {
                  setSelected(value[valueKey]);
                  onChange(e);
                  setShow(false);
                }}
                value={value[idKey]}
              >
                {value[valueKey]}
              </Typography>
            )
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CustomSelect;
