import React from 'react';
import { Menu, Button, MenuItem, makeStyles } from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: 15,
    color: '#295115',
    fontWeight: 800,
    padding: 0,
    borderRadius: 0,
    borderBottom: '1px solid #295115',
    '&::after': {
      content: 'v',
    },
  },
}));

const CustomMenu = ({ buttonText, options, handleChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [text, setText] = React.useState(buttonText);

  React.useEffect(() => {
    setText(buttonText);
  }, [buttonText]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.button}
        endIcon={<ArrowDropDownIcon />}
      >
        {text}
      </Button>
      <Menu
        id='basic-menu'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option, index, optionsArr) => (
          <MenuItem
            // disabled={
            //   !allowCloseSpill
            //     ? optionsArr.indexOf(optionsArr[optionsArr?.length - 1]) ===
            //       index
            //     : false
            // }
            onClick={() => {
              handleChange(option);
              handleClose();
            }}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
