import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import ReactSelect from "react-select";
import * as contractorAction from "../../../../../actionCreators/Contractor";
import {
    filterOption,
    compareContractor,
    getContractorOptions,
    convertToGroupedOptions,
    contractorColourStyles as colourStyles,
} from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: 'left',
    paddingRight: '10px',
    paddingLeft: '10px'
  },
  customLable: {
    color: '#444444',
    fontSize: '15px',
    marginTop: '16px',
    marginBottom: '0px',
    display: 'inline-block'
  },
  ptb: {
    paddingBottom: '0 !important',
    paddingTop: '0 !important'
  },
  header: {
    color: 'green',
    textAlign: 'left'
  }
}));

const ContractorClient = ({
  contractorsWithAddress,
  getContractorsWithAddress,
  currentSpill,
  updatedContractorData,
  setUpdatedContractorData,
  contractorsFromEdit
}) => {
  const classes = useStyles();
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [contractorOptions, setContractorOptions] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [rejectedContractors, setRejectedContractors] = useState([]);
  const [dialogState, setDialogState] = useState({
    open: false,
    contractor: {},
    doNotUse: false
  });
  const [dialogMsg, setDialogMsg] = useState('');

  useEffect(() => {
    getContractorsWithAddress({ withDonotUse: false });
  }, []);

  useEffect(() => {
    const newContractors = getContractorOptions(contractorsWithAddress.data);
    setContractorOptions(convertToGroupedOptions(newContractors));
  }, [contractorsWithAddress]);

  useEffect(() => {
    if (contractorOptions.length) {
      if (contractorsFromEdit) {
        handleContractorChange(contractorsFromEdit);
        setUpdatedContractorData(contractorsFromEdit);
      }
    }
  }, [contractorOptions]);

  const handleContractorChange = (values) => {
    let inActiveCount = 0;
    const newlyRejectedCount =
      (values && values.filter((o) => o && o.accepted === false).length) || 0;

    const oldRejectedCount =
      selectedContractors?.filter((o) => o?.accepted === false).length || 0;

    selectedContractors.map((x) => x.is_inactive === true && inActiveCount++);

    if (!values) {
      if (admin && admin.length) {
        let tempAdmin = [];
        for (const index in admin) {
          if (admin[index].isMain) {
            tempAdmin = [...tempAdmin, admin[index]];
          } else if (admin[index].adminId) {
            tempAdmin = [...tempAdmin, { ...admin[index], isRemoved: true }];
          }
        }
        setAdmin(tempAdmin);
      }
    } else if (
      values.length < selectedContractors.length - inActiveCount ||
      newlyRejectedCount !== oldRejectedCount
    ) {
      let removedContractor;
      const updatedContractorsData = values.map((item) => {
        let contractorId = item.id;
        if (item?.contractor_id) {
          contractorId = item.contractor_id;
        }
        return { ...item, contractor_id: contractorId };
      });
      setUpdatedContractorData(updatedContractorsData);

      //Iterate over all prev selected contractors
      for (const contractorIndex in selectedContractors) {
        setSelectedContractors(values ?? []);
        if (
          selectedContractors[contractorIndex].is_inactive !== true &&
          values.findIndex((o) =>
            compareContractor(o, selectedContractors[contractorIndex])
          ) === -1
        ) {
        } else if (selectedContractors[contractorIndex].accepted !== false) {
          // ^ if not rejected

          // Finding index of contractor which was previously not rejected and now rejected
          const index = values.findIndex(
            (o) =>
              compareContractor(o, selectedContractors[contractorIndex]) &&
              o.accepted === false
          );
          //If found previously
          if (index !== -1) {
            // Remove from selected contractors
            const tempContractors = [...selectedContractors];
            tempContractors.splice(contractorIndex, 1);
            setSelectedContractors(tempContractors);

            //Add to rejected contractors
            rejectedContractors.push(values[index]);
            setRejectedContractors(rejectedContractors);
          }
        }
      }
      if (removedContractor) {
        // If some contractor is removed completely delete relative admin
        // oterwise make it inactive
        let tempAdmin = [...admin];
        for (const item in admin) {
          if (compareContractor(admin[item], removedContractor)) {
            if (tempAdmin[item].adminId) {
              //In active
              tempAdmin[item].isRemoved = true;
            } else {
              //Complete Removal
              tempAdmin.splice(item, 1);
            }
          }
        }
        setAdmin(tempAdmin);
      }
    } else {
      //if not removed
      let addedContractor = null;
      let tempContractors = [...selectedContractors];

      //iterate over the updated array of contractors
      for (const contractorIndex in values) {
        //find index of element if it is newly added
        const index = selectedContractors.findIndex((o) =>
          compareContractor(o, values[contractorIndex])
        );
        //Newly Added: set that to addedContractor
        if (index === -1) {
          addedContractor = values[contractorIndex];
          tempContractors = [
            ...tempContractors,
            { ...addedContractor, accepted: null }
          ];
        } else {
          //It was present before: Check if it was inactive then set to active or if it is newly accepted
          if (
            tempContractors[index].is_inactive ||
            (values[contractorIndex].accepted &&
              !tempContractors[index].accepted)
          ) {
            addedContractor = values[contractorIndex];
            tempContractors[index].is_inactive = false;
            tempContractors[index].accepted =
              values[contractorIndex].accepted ?? null;
            tempContractors[index].previouslyInactive =
              values[contractorIndex]?.previouslyInactive === false
                ? false
                : true;
          }
        }
      }
      if (!addedContractor) {
        //No newly added, exit the method
        return;
      }
      //Find the relevant admin if present
      let selection = admin.findIndex((o) =>
        compareContractor(o, addedContractor)
      );
      if (selection !== -1) {
        //Relevant admin found
        let tempAdmins = [...admin];
        tempAdmins[selection].isRemoved = false;
        setAdmin(tempAdmins);
      } else if (addedContractor.accepted) {
        //Check if added contractor is accepted, if not, donot create admin for it
        let tempAdmins = [
          ...admin,
          {
            contractorId: addedContractor.contractor_id,
            contractorAddressId: addedContractor.addressId,
            adminId: null,
            info: '',
            pix: null,
            spillSummary: null,
            contractorInv: null,
            wasteDoc: null,
            contractorInvoice: '',
            invNo: '',
            finalContractorInv: '',
            savings: '',
            pesPaid: null,
            contractorPaid: null,
            transToCt: null,
            payBy: null,
            responseTime: '00:00',
            isRemoved: false,
            isMain: false,
            isComplete: false,
            pesInvNo: null,
            pesInvAmount: null,
            isNotRequired: false,
            isNotRequiredCheck: false
          }
        ];
        setAdmin(tempAdmins);
      }
      setSelectedContractors(tempContractors);
    }
  };

  const disableContractor = (contractorOptions) => {
    if (contractorOptions.tier_level === 'Do Not Use') {
      return true;
    } else if (contractorOptions?.contractor_attachments_expiries?.length) {
      if (
        contractorOptions?.contractor_attachments_expiries[0].expiry_date ===
        null
      ) {
        return false;
      }
      const today = new Date();
      const expDate = new Date(
        contractorOptions?.contractor_attachments_expiries[0]?.expiry_date
      );
      expDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      if (expDate <= today) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleCheckboxChange = useCallback((event, contractor) => {
    if (event.target.checked) {
      setDialogMsg('Selected address of contractor will marked as "Do not use" for this client. Are you sure you want mark contractor as "Do not use"?');
    } else {
      setDialogMsg('Selected address of contractor will be removed from "Do not use" for this client. Are you sure you want remove this contractor from "Do not use"?');
    }
    setDialogState((prevState) => ({
      ...prevState,
      open: true,
      doNotUse: event.target.checked,
      contractor
    }));
  }, []);

  const handleClose = useCallback(() => {
    setDialogState(() => ({
      open: false,
      doNotUse: false,
      contractor: {}
    }));
  }, []);

  const handleCallDoNotuse = useCallback(() => {
    const updatedSelectedContractors = selectedContractors.map((item) => {
      let contractorId = item.id;
      if (item?.contractor_id) {
        contractorId = item.contractor_id;
      }
      if (item.value === dialogState?.contractor?.value) {
        return {
          ...item,
          checked: dialogState.doNotUse,
          contractor_id: contractorId
        };
      } else {
        return { ...item, contractor_id: contractorId };
      }
    });
    setSelectedContractors(updatedSelectedContractors);
    const updatedContractorsData = updatedSelectedContractors.map((item) => {
      let contractorId = item.id;
      if (item?.contractor_id) {
        contractorId = item.contractor_id;
      }
      if (item.value === dialogState?.contractor?.value) {
        return {
          ...item,
          checked: dialogState.doNotUse,
          contractor_id: contractorId
        };
      } else {
        return { ...item, contractor_id: contractorId };
      }
    });
    setUpdatedContractorData(updatedContractorsData);
    handleClose();
  }, [dialogState?.contractor.value, dialogState.doNotUse, handleClose, selectedContractors, setUpdatedContractorData]);

  return (
    <>
      <Grid
        container
        spacing={2}
        className={classes.mainContainer} style={{paddingTop:"20px"}} >
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <InputLabel id='contractos_label'>Contractors</InputLabel>
          <ReactSelect
            value={selectedContractors.filter((o) => !o.is_inactive)}
            isMulti
            onChange={(val) => {
              handleContractorChange(val || []);
            }}
            filterOption={filterOption}
            options={contractorOptions}
            isOptionDisabled={disableContractor}
            styles={colourStyles()}
          />
        </Grid>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table className={classes.table} aria-label='custom pagination table'>
            <TableHead>
              <TableRow>
                <TableCell>Selected Contractors</TableCell>
                <TableCell>Do Not Use</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedContractors.map((contractor) => (
                <TableRow key={contractor.value}>
                  <TableCell>{contractor.label || contractor.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={contractor.checked || false}
                      onChange={(event) =>
                        handleCheckboxChange(event, contractor)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <div>
        <Dialog
          open={dialogState?.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCallDoNotuse}
              autoFocus
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = ({ contractor: { contractorsWithAddress } }) => {
  return {
    contractorsWithAddress
  };
};
const mapDispatchToProps = (dispatch) => ({
  getContractorsWithAddress: bindActionCreators(
    contractorAction.getContractorsWithAddress,
    dispatch
  )
});
ContractorClient.propTypes = {
  getContractorsWithAddress: PropTypes.func.isRequired
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractorClient)
);
