import { makeStyles, Typography } from '@material-ui/core';
import DetailLvl2 from './DetailLvl2';
import React from 'react';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
  detailHeading: {
    color: '#295115',
    fontSize: '15px',
    fontWeight: 800,
  },
  closeStatusWarning: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    fontWeight: 900,
    color: 'darkgray',
  },
}));

const DetailLvl1 = ({ heading, subValues }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant={'subtitle1'} className={classes.detailHeading}>
        {heading}:
      </Typography>
      {subValues.map((value) => (
        <DetailLvl2 value={value} />
      ))}
      {/* {heading === 'Status' && !allowCloseSpill ? (
        <span className={classes.closeStatusWarning}>
          * This incident can not be closed unless all active admins have their
          attachments approved
        </span>
      ) : null} */}
    </>
  );
};
DetailLvl1.prototype = {
  heading: PropTypes.string.isRequired,
  subValues: PropTypes.array.isRequired,
};

export default DetailLvl1;
