/** @format */

const initialState = {
  success: false,
  login: false,
  loading: false,
  error: null,
  logoutLoading: false,
  currentUser: {
    data: null,
    loading: false,
    error: null,
  },
  spillsAssigned: {
    data: { spills: null },
    loading: false,
    error: null,
  },
  users: {
    data: [],
    pagination: {},
    loading: null,
    error: null,
    success: false,
  },
  admins: {
    data: [],
    loading: false,
    error: null,
  },
  salePersons: {
    data: [],
    loading: false,
    error: null,
  },
  organizationAdmins: {
    data: [],
    loading: false,
    error: null,
  },
  roles: {
    data: [],
    loading: false,
    error: null,
  },
  searchedUsers: {
    data: [],
    loading: false,
    error: null,
  },
  newUser: {
    loading: false,
    error: null,
  },
  attachmentTypes: {
    data: [],
    loading: false,
    success: false,
    error: null,
  },
  currentSideBarValue: null,
  editPasswordResponse: null,
  snackBar: {
    saverity: null,
    open: false,
    snackbarMessage: null,
  },
  packetReviewers: {
    data: [],
    loading: false,
    success: false,
    error: null,
  },
};

const userReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case 'CREATE_NEW_USER':
      return {
        ...state,
        type,
        newUser: {
          error: null,
          loading: true,
        },
      };
    case 'CREATE_NEW_USER_SUCCESS':
      return {
        ...state,
        payload,
        type,
        newUser: {
          ...state.newUser,
          loading: false,
        },
        users: {
          ...state.users,
          data: data ? [data, ...state.users.data] : [...state.users.data],
        },
      };
    case 'CREATE_NEW_USER_FAILURE':
      return {
        ...state,
        type,
        newUser: {
          error: error,
          loading: false,
        },
      };
    case 'RESET_USER':
      return initialState;

    case 'SIGNUP':
    case 'LOGIN':
      return {
        ...state,
        ...initialState,
        type,
        loading: true,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        type,
        loading: true,
        logoutLoading: true,
        snackBar: {
          ...state.snackBar,
        },
      };

    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        loading: false,
        signUpSuccess: true,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          error: null,
        },
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        ...initialState,
        type,
        loading: false,
        logoutLoading: false,
        snackBar: {
          ...state.snackBar,
        },
      };

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        login: true,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data,
        },
        snackBar: {
          ...initialState.snackBar,
        },
      };

    case 'LOGIN_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        currentUser: {
          ...state.currentUser,
          loading: false,
          error,
        },
        snackBar: {
          ...initialState.snackBar,
        },
      };

    case 'SIGNUP_FAIL':
    case 'LOGOUT_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        logoutLoading: false,
        snackBar: {
          ...state.snackBar,
        },
      };

    case 'GET_CURRENT_USER':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: true,
          error: null,
          data: null,
        },
      };

    case 'GET_CURRENT_USER_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          error: false,
          data,
        },
      };

    case 'GET_CURRENT_USER_FAIL':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: false,
          error,
        },
      };

    case 'EDIT_USER_PROFILE':
      return {
        ...state,
        // ...initialState,
        type,
        loading: true,
      };

    case 'EDIT_USER_PROFILE_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data: {
            ...state?.currentUser?.data,
            phone: data?.data?.phone,
            full_name: data?.data?.full_name,
            is_packet_reviewer: data?.data?.is_packet_reviewer,
          },
        },
      };

    case 'EDIT_USER_PROFILE_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'EDIT_USER_EMAIL':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'EDIT_USER_EMAIL_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data: {
            ...state.currentUser.data,
            email: data.data.email,
            secondary_email: data.data.secondary_email,
          },
        },
      };

    case 'EDIT_USER_EMAIL_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'EDIT_USER_LICENSE':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'EDIT_USER_LICENSE_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data: {
            ...state.currentUser.data,
            license_number: data.data.license_number,
            license_number_expiry: data.data.license_number_expiry,
            haz_waste_hauler: data.data.haz_waste_hauler,
          },
        },
      };

    case 'EDIT_USER_LICENSE_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'UPDATE_EULA':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'UPDATE_EULA_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data: {
            ...state.currentUser.data,
            eula_accepted: data.data.eula_accepted,
          },
        },
      };

    case 'UPDATE_EULA_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'EDIT_USER_PASSWORD':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'EDIT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        editPasswordResponse: data,
      };

    case 'EDIT_USER_PASSWORD_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };
    case 'EDIT_USER_PASSWORD_RESPONSE':
      return {
        ...state,
        editPasswordResponse: null,
      };
    case 'CHECK_IN_SPILLS':
      return {
        ...state,
        type,
        loading: true,
        spillsAssigned: {
          ...initialState.spillsAssigned,
          loading: true,
        },
      };

    case 'CHECK_IN_SPILLS_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        spillsAssigned: {
          ...state.spillsAssigned,
          loading: false,
          data: data.data,
        },
      };

    case 'CHECK_IN_SPILLS_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        spillsAssigned: {
          ...state.spillsAssigned,
          loading: false,
        },
      };

    case 'GET_USERS':
      return {
        ...state,
        type,
        loading: true,
        users: {
          ...initialState.users,
          loading: true,
        },
      };

    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        users: {
          ...state.users,
          loading: false,
          data: data.data,
          pagination: { ...data.pagination },
        },
      };

    case 'GET_USERS_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        users: {
          ...state.users,
          loading: false,
        },
      };

    case 'EDIT_USER':
      return {
        ...state,
        type,
        loading: true,
        users: {
          ...state.users,
          success: false,
          loading: true,
          error: null,
        },
      };

    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        users: {
          ...state.users,
          success: true,
          loading: false,
          error: null,
          data: state.users.data.map((userToUpdate) => {
            if (userToUpdate.id === data.id) {
              userToUpdate = data;
            }
            let updatedUser = { ...userToUpdate };
            return updatedUser;
          }),
        },
      };

    case 'EDIT_USER_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        users: {
          ...state.users,
          error: error,
          success: false,
          loading: false,
        },
      };

    case 'GET_USERS_BY_ORG_ID':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_USERS_BY_ORG_ID_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        users: {
          ...state.users,
          data: data,
        },
      };

    case 'GET_USERS_BY_ORG_ID_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };
    case 'GET_USERS_FOR_EMAIL':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_USERS_FOR_EMAIL_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        users: {
          ...state.users,
          data: data,
        },
      };

    case 'GET_USERS_FOR_EMAIL_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'GET_ADMINS':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_ADMINS_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        admins: {
          ...state.admins,
          data: data,
        },
      };

    case 'GET_ADMINS_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'GET_SALE_PERSONS':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_SALE_PERSONS_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        salePersons: {
          ...state.salePersons,
          data: data,
        },
      };

    case 'GET_SALE_PERSONS_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'GET_ORGANIZATION_ADMINS':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_ORGANIZATION_ADMINS_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        organizationAdmins: {
          ...state.organizationAdmins,
          data: data,
        },
      };

    case 'GET_ORGANIZATION_ADMINS_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'GET_ROLES':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_ROLES_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        roles: {
          ...state.roles,
          data: data,
        },
      };

    case 'GET_ROLES_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'SEARCH_USER':
      return {
        ...state,
        users: {
          ...initialState.users,
        },
        searchedUsers: {
          ...initialState.searchedUsers,
        },
        loading: true,
      };

    case 'SEARCH_USER_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        searchedUsers: {
          ...state.searchedUsers,
          data: data,
        },
      };

    case 'SEARCH_USER_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'CLEAR_SEARCH':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        searchedUsers: {
          data: [],
          loading: false,
          error: null,
        },
      };

    case 'SET_NOTIFICATION_PREFERENCE':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'SET_NOTIFICATION_PREFERENCE_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          data: {
            ...state.currentUser.data,
            user_services_notifications: data.user_services_notifications,
          },
        },
      };

    case 'SET_NOTIFICATION_PREFERENCE_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'GET_ASSOCIATED_SERVICES':
      return {
        ...state,
        type,
        loading: true,
      };

    case 'GET_ASSOCIATED_SERVICES_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          data: {
            ...state.currentUser.data,
            user_associated_services: data,
          },
        },
      };

    case 'GET_ASSOCIATED_SERVICES_FAILURE':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case 'SET_CURRENT_SIDEBAR_VALUE':
      return {
        ...state,
        currentSideBarValue: data,
      };

    //
    case 'SET_SNACKBAR_SUCCESS':
      return {
        ...state,
        snackBar: {
          saverity: 'success',
          open: true,
          snackbarMessage: data.message,
        },
      };

    case 'SET_SNACKBAR_ERROR':
      return {
        ...state,
        snackBar: {
          saverity: 'error',
          open: true,
          snackbarMessage: data.message,
        },
      };

    case 'SET_SNACKBAR_INFO':
      return {
        ...state,
        snackBar: {
          saverity: 'info',
          open: true,
          snackbarMessage: data.message,
        },
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        snackBar: {
          saverity: null,
          open: false,
          snackbarMessage: null,
        },
      };

    case 'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY':
      return {
        ...state,
        attachmentTypes: {
          ...state.attachmentTypes,
          loading: true,
        },
      };

    case 'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY_SUCCESS':
      return {
        ...state,
        attachmentTypes: {
          ...state.attachmentTypes,
          loading: false,
          success: true,
          data: [...data.data],
        },
      };

    case 'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY_FAIL':
      return {
        ...state,
        attachmentTypes: {
          ...state.attachmentTypes,
          loading: false,
          success: false,
          error,
        },
      };

    case 'UPDATE_USER_ATTACHMENT_EXPIRY':
      return {
        ...state,
        attachmentTypes: {
          ...state.attachmentTypes,
          loading: true,
        },
      };

    case 'UPDATE_USER_ATTACHMENT_EXPIRY_SUCCESS':
      return {
        ...state,
        attachmentTypes: {
          ...state.attachmentTypes,
          success: true,
          loading: false,
          data: [...data.data],
        },
      };

    case 'UPDATE_USER_ATTACHMENT_EXPIRY_FAIL':
      return {
        ...state,
        attachmentTypes: {
          ...state.attachmentTypes,
          success: false,
          loading: false,
          error,
        },
      };

    case 'FETCH_PACKET_REVIEWERS':
      return {
        ...state,
        packetReviewers: {
          ...state.packetReviewers,
          loading: true,
        },
      };

    case 'FETCH_PACKET_REVIEWERS_SUCCESS':
      return {
        ...state,
        packetReviewers: {
          ...state.packetReviewers,
          data: data?.data,
          loading: false,
          success: true,
        },
      };

    case 'FETCH_PACKET_REVIEWERS_FAIL':
      return {
        ...state,
        packetReviewers: {
          ...state.packetReviewers,
          loading: false,
          success: false,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
