import { makeStyles, Typography, Paper } from "@material-ui/core";
import React from "react";

import PropTypes from "prop-types";
const useStyles = makeStyles((theme) => ({
  textBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#295115",
    fontSize: "15px",
    fontWeight: 800,
    padding: "5px 34px",
    gap: 5,
    boxShadow: "0px 2px 4px 0px rgb(9 9 9 / 52%)",
    border: "1px solid #80808042",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 17,
    color: "#2f7d32",
  },
  text: {
    fontSize: 16,
  },
  row: { flexDirection: "row" },
  column: { flexDirection: "column" },
}));

const TextBox = ({ heading, text, direction }) => {
  const classes = useStyles();
  return (
    <Paper
      className={`${classes.textBox} ${
        direction === "row" ? classes.row : classes.column
      }`}
    >
      {heading && (
        <Typography className={classes.heading}>
          {heading} {direction === "row" ? ":" : ""}
        </Typography>
      )}
      {text && <Typography className={classes.text}>{text} </Typography>}
    </Paper>
  );
};
TextBox.prototype = {
  heading: PropTypes.string,
  text: PropTypes.array.isRequired,
  direction: PropTypes.string,
};

export default TextBox;
