import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(6, 0),
  },
  "main-title": {
    color: "#1C5015",
    margin: 0,
    padding: 0,
  },
  "span-title": {
    color: "#000",
    fontSize: "18px",
    margin: 0,
    padding: 0,
  },
}));

const FooterComponent = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          {"Managed by Premium Environmental Services"}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          {new Date().getFullYear()} {"Premium Environmental Services "}
        </Typography>
      </Container>
    </footer>
  );
};

export default FooterComponent;
