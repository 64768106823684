import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { orderAlphabaticallyByKey } from "../../utils/contractorLabel";

const useStyles = makeStyles((theme) => ({
  menuContainer: {},
  dropdown: {
    position: "relative",
    fontSize: "1rem",
    perspective: "1000px",
    zIndex: "100",
    transition: "all 0.3s",
    minHeight: 36,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  dropDownList: {
    maxHeight: 0,
    overflowY: "hidden",
    transition: "max-height 0.5s ease-in-out",
  },

  dropDownListHovered: {
    maxHeight: (props) => `${props.noOfItems * 36}px`,
  },
  headingContainer: {
    padding: "10px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 36,
    borderRadius: 7,
    "&:hover": {
      background: "rgba(0, 0,0,0.04)",
    },
  },
  headingContainerExpanded: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    background: "transparent !important",
  },
  heading: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    fontWeight: "500",
  },

  dropdownItem: {
    height: 36,
    paddingLeft: 23,
    display: "flex",
    alignItems: "center",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    transition: "all 0.3s",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  icon: {
    color: "#2f7d32",
    transition: "all 0.3s",
    marginTop: 1,
  },
  iconTransformed: {
    transform: "rotateX(180deg)",
  },
  expandedItem: {
    borderRadius: 7,
    boxShadow: "0px 2px 7px 0px rgb(0 0 0 / 13%)",
    transform: "scale(0.97)",
  },
}));

const CustomDropDown = ({ values, selectedOption, setSelected, onChange }) => {
  const classes = useStyles({ noOfItems: values.value.options.length });

  const [openDropDown, setOpenDropDown] = React.useState(false);

  return (
    <div
      value={
        values.value?.options.find(
          (option) => option[values.valueKey] === selectedOption
        )
          ? selectedOption
          : null
      }
      className={classes.menuContainer}
    >
      <li
        className={`${classes.dropdown} ${
          openDropDown && classes.expandedItem
        }`}
      >
        <div
          className={`${classes.headingContainer} ${
            openDropDown && classes.headingContainerExpanded
          }`}
          onClick={() => setOpenDropDown(!openDropDown)}
        >
          <Typography variant="body1" className={classes.heading}>
            {values?.value?.name}
          </Typography>
          <KeyboardArrowDownIcon
            className={`${openDropDown && classes.iconTransformed} ${
              classes.icon
            }`}
          />
        </div>
        <ul
          className={`${classes.dropDownList} ${
            openDropDown && classes.dropDownListHovered
          }`}
        >
          {values?.value?.options.map((option) => (
            <li
              className={classes.dropdownItem}
              onClick={(e) => {
                setSelected(option[values.valueKey]);
                onChange(e);
              }}
              value={option[values.idKey]}
            >
              {option[values.valueKey]}
            </li>
          ))}
        </ul>
      </li>
    </div>
  );
};

export default CustomDropDown;
