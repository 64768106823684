import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { bindActionCreators } from 'redux';
import { useHistory, withRouter } from 'react-router-dom';

import Moment from 'moment';
import MomentUtils from '@date-io/moment';

import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Snackbar,
  TextField,
  Avatar,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormControl,
  FormHelperText,
  CircularProgress,
  Switch,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { green } from '@material-ui/core/colors';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import * as userActions from '../../../../../actionCreators/User';
import * as clientActions from '../../../../../actionCreators/Client';
import {
  getContractorOptions,
  convertToGroupedOptions,
  contractorColourStyles as colourStyles,
  filterOption,
  orderAlphabaticallyByKey,
} from '../../../../../utils/contractorLabel';
import { ROLES } from '../../../../../utils/keyMappers';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CustomProgressLoader } from '../../../../../Components';
import { forceToCST } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  alignLeft: {
    textAlign: 'left',
  },
  formControl: {
    width: '100%',
  },
  itemsAlignRight: {
    textAlign: 'right',
  },
  itemsAlignLeft: {
    textAlign: 'left',
  },
  centerAlign: {
    textAlign: 'center',
  },
  labelColor: {
    color: "#7F7F7F",
  },
}));

const EditUser = ({
  user,
  logIn,
  userData,
  editUser,
  getUserRoles,
  checkInSpills,
  closeEditModal,
  getOrganizationNames,
  clientOrganizationNames,
  updateUserAttachmentExpiry,
  contractorsDataWithAddresses,
  fetchAttachmentTypesWithExpiry,
}) => {
  const classes = useStyles();

  const {
    id,
    full_name,
    phone,
    email,
    password,
    active,
    org_id,
    email_notification,
    role,
    contractor,
    address,
    is_default,
  } = userData;

  const [selectedContractor, setSelectedContractor] = React.useState(null);
  const [adminContractor, setAdminContractor] = React.useState(
    ROLES.CONTRACTOR_ADMIN === role.role
  );
  const [userContractor, setUserContractor] = React.useState(
    ROLES.CONTRACTOR_USER === role.role
  );
  const history = useHistory();
  const [contractorOptions, setContractorOptions] = React.useState([]);

  const [nameError, setNameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [phoneError, setphoneError] = React.useState(null);
  const [contractorError, setContractorError] = React.useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = React.useState('');
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [activeValue, setActiveValue] = React.useState(active);
  const [editCalled, setEditCalled] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(is_default);
  const [okayToEmail, setOkayToEmail] = React.useState(email_notification);
  const [notifyOnDocumentRejection, setNotifyOnDocumentRejection] = React.useState(userData?.notify_on_document_rejection ?? false)

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  React.useEffect(() => {
    getUserRoles();
    getOrganizationNames();
    checkInSpills({ userId: id });
    fetchAttachmentTypesWithExpiry({ userId: id });
  }, []);

  React.useEffect(() => {
    if (contractor) {
      let contractorData = {};
      const value = {
        value: `${contractor.id} ${address?.id || ''}`,
        label: `${contractor.name} - ${
          address ? address.tier_level : contractor.tier_level
        } - (${address ? address.city : contractor?.city},${
          address ? address.state : contractor?.state
        })`,
        addressId: address?.id || '',
        contractor_id: contractor.id,
      };
      contractorData = value;
      updateRole(role.id);
      setSelectedContractor(contractorData);
    }
  }, [address, contractor, role.id]);

  React.useEffect(() => {
    let filteredContractors = [];
    const isAdmin = [1, 2].includes(user.currentUser?.data?.role_id);
    if (userContractor) {
      filteredContractors = isAdmin
        ? contractorsDataWithAddresses.data
        : contractorsDataWithAddresses.data.filter(
            (singleContractor) => singleContractor.name === contractor.name
          );
    } else if (adminContractor) {
      filteredContractors = contractorsDataWithAddresses.data.filter(
        (singleContractor) =>
          !singleContractor.entity_id &&
          (isAdmin ? true : singleContractor.name === contractor.name)
      );
    }

    const newContractors = getContractorOptions(filteredContractors);

    setContractorOptions(
      adminContractor
        ? orderAlphabaticallyByKey(newContractors, 'name')
        : convertToGroupedOptions(newContractors)
    );
  }, [
    adminContractor,
    contractorsDataWithAddresses,
    user.currentUser.data.contractor_id,
    user.currentUser.data.role.role,
    userContractor,
  ]);

  const updateRole = (roleId) => {
    setAdminContractor(roleId === 9);
    setUserContractor(roleId === 10);
  };

  const userRoles = user.roles.data;
  const organizationNames = clientOrganizationNames.data;

  const handleSelectChange = (e, key) => {
    if (key === 'roleSelect') {
      setSelectedContractor(null);
      updateRole(e.target.value);
    } else if (key === 'activeSelect') {
      if (user.spillsAssigned.data?.spills?.length > 0 && !e.target.value) {
        setActiveValue(true);
        setSnackBarOpen(true);
        setSnackBarMessage(
          `${userData.full_name} is assigned as PM on ${user.spillsAssigned.data.spills.length} "Open" spill(s). Kindly re-assign spills to another PM before deactivating user.`
        );
        setSnackBarSeverity('error');
      } else setActiveValue(e.target.value);
    }
  };

  const formValidator = ({ roleId, name, phone, email }) => {
    let flag = false;

    if (roleId === 9 || roleId === 10) {
      if (!selectedContractor) {
        setContractorError(true);
        return true;
      }
    }

    if (!name || name === '' || name.length === 0 || !name.trim()) {
      setNameError(true);
      flag = true;
    } else {
      setNameError(false);
    }
    if (!phone || phone === '' || phone.length === 0 || !phone.trim()) {
      setphoneError(true);
      flag = true;
    } else {
      setphoneError(false);
    }
    if (!email || email === '' || email.length === 0 || !email.trim()) {
      setEmailError(true);
      flag = true;
    } else {
      setEmailError(false);
    }

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ((userContractor || adminContractor) && !selectedContractor) {
      setSnackBar('Contractor must be selected.');
      return;
    } else if (
      +e.target.organization.value === 0 &&
      !(userContractor || adminContractor)
    ) {
      setSnackBar('Organization must be selected.');
      return;
    }

    const textFieldsData = {
      roleId: e.target.role.value,
      name: e.target.full_name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
    };

    if (formValidator(textFieldsData)) {
      return;
    } else {
      const editUserObject = {
        id,
        full_name: e.target.full_name.value ? e.target.full_name.value : '',
        phone: e.target.phone.value ? e.target.phone.value : '',
        email: e.target.email.value ? e.target.email.value : '',
        active: e.target.active.value || false,
        email_notification:
          isDefault ||
          (e.target.email_notification.value
            ? e.target.email_notification.value
            : ''),
        role_id: e.target.role.value ? e.target.role.value : '',
        org_id:
          userContractor || adminContractor
            ? ''
            : e.target.organization.value
            ? e.target.organization.value
            : '',
        selectedContractor: selectedContractor ? selectedContractor : '',
        is_default: e.target.is_default.checked || false,
        notify_on_document_rejection: notifyOnDocumentRejection ?? false
      };

      if (e.target.password.value) {
        editUserObject.password = e.target.password.value?.trim();
      }

      editUserObject.role_id = parseInt(editUserObject.role_id);
      editUser(editUserObject);
      setEditCalled(true);
    }
  };
  const setSnackBar = (message, type = 'error') => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSnackBarSeverity(type);
  };

  React.useEffect(() => {
    if (!user.users?.loading && editCalled) {
      if (!user.users?.error) {
        closeEditModal();
      } else {
        setSnackBar(user.users.error);
      }
      setEditCalled(false);
    }
  }, [user.users?.loading]);

  const handleBecomeUserToggle = (event) => {
    if (!event.target.checked) {
      return;
    }
    // if checked
    try {
      const userToBecomeId = id;
      const redirectUrl = '/dashboard/home';
      // using login api to handle role change
      logIn({}, history, redirectUrl, true, userToBecomeId);
    } catch (err) {
      console.error(err);
    }
  };
  const currentRole = user?.currentUser?.data?.role?.role;
  const allowedToBecomeOtherUser = ROLES.SUPER_USER === currentRole;
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Avatar className={classes.avatar}>
            <AlternateEmailIcon />
          </Avatar>
          <Typography variant='h5' gutterBottom>
            <span style={{ paddingLeft: '20px' }}>Edit User</span>
          </Typography>
        </div>
        {allowedToBecomeOtherUser && (
          <div>
            <InputLabel>Become this User</InputLabel>
            <Switch onChange={handleBecomeUserToggle} color='primary' />
          </div>
        )}
      </div>

      <div style={{ paddingTop: 30 }} />
      {user.users?.loading === true && <CircularProgress />}
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          display: user.users?.loading ? 'none' : 'unset',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              error={nameError}
              id='full_name'
              name='full_name'
              label='Name'
              fullWidth
              defaultValue={full_name}
              autoComplete='full_name'
            />
          </Grid>

          <Grid item xs={10} className={classes.alignLeft}>
            <InputLabel id='organization-select-label'>
              Organization *
            </InputLabel>
            {user?.currentUser?.data?.role?.role === ROLES.CORPORATE_ADMIN ? (
              <Select
                labelId='organization-select-label'
                id='organization-select'
                name='organization'
                style={{ width: '100%' }}
                defaultValue={org_id}
                disabled={userContractor || adminContractor}
              >
                {orderAlphabaticallyByKey(
                  organizationNames.filter((x) => {
                    return x.active && x.id === user?.currentUser.data.org_id;
                  }),
                  'name'
                ).map((orgData, i) => {
                  if (
                    user?.currentUser?.data?.role?.role !==
                      ROLES.CONTRACTOR_ADMIN ||
                    user?.currentUser?.data?.org_id === orgData.id
                  ) {
                    return (
                      <MenuItem value={orgData.id} key={i}>
                        {orgData.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            ) : (
              <Select
                labelId='organization-select-label'
                id='organization-select'
                name='organization'
                style={{ width: '100%' }}
                defaultValue={org_id}
                disabled={userContractor || adminContractor}
              >
                {orderAlphabaticallyByKey(
                  organizationNames.filter((x) => x.active),
                  'name'
                ).map((orgData, i) => {
                  if (
                    user?.currentUser?.data?.role?.role !==
                      ROLES.CONTRACTOR_ADMIN ||
                    user?.currentUser?.data?.org_id === orgData.id
                  ) {
                    return (
                      <MenuItem value={orgData.id} key={i}>
                        {orgData.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            )}
          </Grid>
          <Grid item xs={2} className={classes.leftAlign}>
            <InputLabel id='default-user-label'>Default User</InputLabel>

            <Switch
              labelId='default-parent-user-label'
              id='default-switch'
              defaultChecked={is_default}
              onChange={(event) => setIsDefault(event.target.checked)}
              name='is_default'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={phoneError}
              id='phone'
              name='phone'
              label='Phone'
              fullWidth
              autoComplete='phone'
              defaultValue={phone}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              error={emailError}
              id='email'
              name='email'
              label='Email'
              fullWidth
              autoComplete='email'
              defaultValue={email}
              type='email'
            />
          </Grid>

          <Grid item xs={6} className={classes.alignLeft}>
            <InputLabel id='role-select-label'>Role</InputLabel>
            <Select
              labelId='role-select-label'
              id='role-select'
              name='role'
              defaultValue={role?.id}
              style={{ width: '100%' }}
              onChange={(e) => handleSelectChange(e, 'roleSelect')}
            >
              {orderAlphabaticallyByKey(userRoles, 'role').map(
                (roleData, i) => {
                  return (
                    ((user?.currentUser?.data?.role?.role !==
                      ROLES.CONTRACTOR_ADMIN &&
                      user?.currentUser?.data?.role?.role !==
                        ROLES.CORPORATE_ADMIN) ||
                      (user?.currentUser?.data?.role?.role ===
                        ROLES.CONTRACTOR_ADMIN &&
                        roleData.role.includes('Contractor')) ||
                      (user?.currentUser?.data?.role?.role ===
                        ROLES.CORPORATE_ADMIN &&
                        roleData.role.includes('Corporate'))) && (
                      <MenuItem value={roleData.id} key={i}>
                        {roleData.role}
                      </MenuItem>
                    )
                  );
                }
              )}
            </Select>
          </Grid>

          {(adminContractor || userContractor) ? <Grid item xs={6} className={classes.alignLeft} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "-20px" }} >
            <FormControlLabel
              control={
                <Checkbox
                  checked={notifyOnDocumentRejection}
                  onChange={(event) => setNotifyOnDocumentRejection(event?.target?.checked)}
                  name="notify_on_document_rejection"
                  className={classes.labelColor}
                  defaultValue={userData?.notify_on_document_rejection ?? false}
                />
              }
              label="Notify on Document Rejection"
            />
          </Grid> : null}

          {userContractor ? (
            <Grid item xs={12} className={classes.alignLeft}>
              <InputLabel
                id='organization-select-label'
                style={{ marginBottom: '5px' }}
              >
                Contractor *
              </InputLabel>
              <FormControl
                className={classes.formControl}
                error={contractorError}
              >
                <ReactSelect
                  value={selectedContractor}
                  required
                  onChange={setSelectedContractor}
                  options={contractorOptions}
                  filterOption={filterOption}
                  styles={colourStyles()}
                />
                {contractorError && (
                  <FormHelperText>Please select contractor</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : (
            <div></div>
          )}

          {adminContractor ? (
            <Grid item xs={12} className={classes.alignLeft}>
              <InputLabel
                id='organization-select-label'
                style={{ marginBottom: '5px' }}
              >
                Contractor *
              </InputLabel>
              <FormControl
                className={classes.formControl}
                error={contractorError}
              >
                <ReactSelect
                  value={selectedContractor}
                  required
                  onChange={setSelectedContractor}
                  options={contractorOptions}
                  filterOption={filterOption}
                  styles={colourStyles()}
                />
                {contractorError && (
                  <FormHelperText>Please select contractor</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : (
            <div></div>
          )}

          <Grid item xs={6}>
            <TextField
              id='password'
              name='password'
              label='Password'
              fullWidth
              autoComplete='password'
            />
          </Grid>

          <Grid item xs={6} className={classes.alignLeft}>
            <InputLabel id='active-select-label'>Active</InputLabel>
            <Select
              labelId='active-select-label'
              id='active-select'
              name='active'
              value={activeValue}
              style={{ width: '100%' }}
              onChange={(e) => handleSelectChange(e, 'activeSelect')}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={6} className={classes.alignLeft}>
            <InputLabel id='emailNotification-select-label'>
              Okay to email
            </InputLabel>
            <Select
              labelId='emailNotification-select-label'
              id='emailNotification-select'
              name='email_notification'
              defaultValue={email_notification}
              disabled={isDefault}
              value={isDefault || okayToEmail}
              style={{ width: '100%' }}
              onChange={(e) => setOkayToEmail(e.target.value)}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              type='submit'
            >
              Update
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => closeEditModal()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity={snackBarSeverity}>{snackBarMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, client: { clientOrganizationNames } }) => ({
  user,
  clientOrganizationNames,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: bindActionCreators(userActions.logIn, dispatch),
  editUser: bindActionCreators(userActions.editUser, dispatch),
  getUserRoles: bindActionCreators(userActions.getUserRoles, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  checkInSpills: bindActionCreators(userActions.checkInSpills, dispatch),
  fetchAttachmentTypesWithExpiry: bindActionCreators(
    userActions.fetchAttachmentTypesWithExpiry,
    dispatch
  ),
  updateUserAttachmentExpiry: bindActionCreators(
    userActions.updateUserAttachmentExpiry,
    dispatch
  ),
});

EditUser.prototype = {
  user: PropTypes.object.isRequired,
  getUserRoles: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUser)
);
