import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { green } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { ROLES } from "../../../../../utils";
import moment from "moment";
import * as agencyActions from "../../../../../actionCreators/Agency";
import {
  CustomProgressLoader,
  CustomSnackBar,
  MainAddressForm,
} from "../../../../../Components";
import ReportingRequirements from "./ReportingRequirements";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  modal: {
    display: "flex",
    overflow: "scroll",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
  headerColor: {
    color: "green",
  },
  nameFieldWrapper: {
    marginBottom: "1rem",
  },
}));

function NewAgency({ userRole, agency, createNewAgency, closeAddAgency }) {
  const classes = useStyles();
  const { register } = useForm();

  const [jurisdictionValue, setJurisdictionValue] = useState("");
  const [reqSubmitted, setReqSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [inputValues, setInputValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const [reportingRequirementsFormData, setReportingRequirementsFormData] =
    useState({
      petroleum_release_amount: null,
      petroleum_release: null,
      hazardous_material_amount: null,
      hazardous_material: null,
      water_impact_amount: null,
      water_impact: null,
      soil_impact_amount: null,
      soil_impact: null,
      revision_date: null,
    });
  const [mainAddressFormData, setMainAddressFormData] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    fax: "",
    contact: "",
  });
  const [selectedDate, setSelectedDate] = useState(
    moment()?.format("MM-DD-YYYY")
  );

  const hideError = () => {
    setErrorMessage('');
  };

  const validateForm = () => {
    const errors = {};

    if (!inputValues.name || inputValues.name.trim() === "") {
      errors.name = "- Agency Name should not be blank.";
    }

    if (!jurisdictionValue) {
      errors.jurisdiction = "- Please select a jurisdiction.";
    }

    const combinedMessage = [errors.name || "", errors.jurisdiction || ""]
      .filter((message) => message !== "")
      .join("\n");

    setErrorMessage(combinedMessage);
    return errors;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleJurisdictionChange = (event) => {
    const selectedJurisdiction = event.target.value;
    setJurisdictionValue(selectedJurisdiction);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const modifiedData = {
        ...reportingRequirementsFormData,
      }
      delete modifiedData?.revision_date; 
      const hasValue = Object.values(modifiedData).filter((item) => item);
      const obj = {
        name: inputValues.name ? inputValues.name : "",
        jurisdiction: jurisdictionValue,
        ...reportingRequirementsFormData,
        ...mainAddressFormData,
        revision_date: hasValue.length ? moment(selectedDate)?.format("YYYY-MM-DD") : null
      };
      createNewAgency(obj);
      setReqSubmitted(true);
    }
  };

  React.useEffect(() => {
    if (!agency.loading && reqSubmitted) {
      if (!agency.error) {
        closeAddAgency();
      } else {
        setErrorMessage(agency.error);
      }
      setReqSubmitted(false);
    }
  }, [agency.loading]);

  return (
    <>
      <CustomProgressLoader show={agency.loading} />
      <div style={{ display: agency.loading ? "none" : "unset" }}>
        <CustomSnackBar
          open={!!errorMessage}
          severity="error"
          snackBarMessage={errorMessage}
          onClose={hideError} 
        />
           
        <div style={{ paddingTop: 10 }} />
        <AppBar position="static" color="primary">
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="New Agency" />
            {userRole === ROLES.SUPER_USER && (
              <Tab label="Reporting Requirements" />
            )}
          </Tabs>
        </AppBar>
        {/* Tab panels */}
        <div className={classes.paper}>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {selectedTab === 0 && (
              <>
                <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                  <Grid
                    className={classes.nameFieldWrapper}
                    container
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        inputRef={register}
                        autoComplete="name"
                        required
                        onChange={handleInputChange}
                        value={inputValues?.name || ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6} style={{ textAlign: "left" }}>
                    <InputLabel id="jurisdiction-select-label">
                      Jurisdiction *
                    </InputLabel>
                    <Select
                      labelId="jurisdiction-select-label"
                      id="jurisdiction-select"
                      inputProps={{
                        inputRef: (ref) => {
                          if (!ref) return;
                          setJurisdictionValue(ref.value);
                        },
                      }}
                      style={{ width: "300px" }}
                      value={jurisdictionValue}
                      onChange={handleJurisdictionChange}
                      required
                    >
                      <MenuItem value={"National"}>National</MenuItem>
                      <MenuItem value={"State"}>State</MenuItem>
                      <MenuItem value={"Local"}>Local</MenuItem>
                    </Select>
                  </Grid>
                  <MainAddressForm
                    register={register}
                    isOrg={false}
                    showResponsePills={false}
                    mainAddformData={mainAddressFormData}
                    onChange={setMainAddressFormData}
                  ></MainAddressForm>
                </div>
              </>
            )}

            {selectedTab === 1 && (
              <ReportingRequirements
                formData={reportingRequirementsFormData}
                onChange={setReportingRequirementsFormData}
                onDateChange={handleDateChange}
                selectedDate={selectedDate}
              />
            )}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
              >
                Create
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => closeAddAgency()}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ agency }) => ({ agency });

const mapDispatchToProps = (dispatch) => ({
  createNewAgency: bindActionCreators(agencyActions.createNewAgency, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewAgency)
);
