import React, { useState, useEffect, useRef } from "react";

import { green } from "@material-ui/core/colors";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useDispatch } from "react-redux";
import { downloadFile } from "../../actionCreators/Download";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Moment from "moment";
import MomentUtils from "@date-io/moment";
import { Prompt, WholeScreenModal } from "../../../src/Components";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "inherit",
  },
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    display: "flex",
    float: "right",
  },
  modal: {
    display: "flex",
    overflow: "scroll",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
  header: {
    color: "green",
    textAlign: "left",
  },
  nameFieldWrapper: {
    marginBottom: "1rem",
  },
  accordian: {
    marginBottom: "7px",
    borderRadius: "10px",
  },
  additionalAttachmentTypesAccordion: {
    backgroundColor: "#dedcdc",
    borderRadius: "10px",
  },
  additionalAttachmentTypesAccordionHeader: {
    display: "flex",
    width: "100%",
    padding: "0px 15px 0px 0px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  additionalAttachmentTypesAccordionAttachment: {
    display: "flex",
    paddingRight: "5px",
    alignItems: "center",
    gap: 5,
  },
  additionalAttachmentIcon: {
    marginRight: "5px",
    color: "rgb(199 169 13)",
  },
  additionalAttachmentIconSection: {
    display: "block",
  },
  clickableIcon: {
    cursor: "pointer",
  },
  datePicker: {
    display: "flex",
    marginLeft: "15px",
  },
}));

const AdditionalAttachmentsAccordion = ({
  deleteCertificate,
  updateAttachmentTypes,
  additionalAttachmentTypes,
  contractorId
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const dispatch = useDispatch();

  const handleExpandChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);
  };

  const [selectedFiles, setSelectedFiles] = useState(additionalAttachmentTypes);
  const [showFileDeleteModal, setShowFileDeleteModal] = React.useState(false);
  const [fileIndex, setFileIndex] = React.useState(false);
  const [attachmentID, setAttachmentID] = React.useState(false);
  const [dateToDisable, setDateToDisable] = React.useState(false);
  const isMounted = useRef(false);
  const [isUpdateAttachmentBtnEnabled, setIsUpdateAttachmentBtnEnabled] =
    useState(false);

  useEffect(() => {
    let currentDate = Moment(new Date()).add(1, "days");
    setDateToDisable(currentDate);
  }, []);

  useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true;
      return;
    }
    setIsUpdateAttachmentBtnEnabled(true);
  }, [selectedFiles]);

  const handleUpdateAttachmentTypes = () => {
    updateAttachmentTypes(selectedFiles);
  };

  const onFileChangeHandler = (event, index, type) => {
    setSelectedFiles((selectedFiles) => {
      let tempSelected = [...selectedFiles];
      tempSelected[index].attachedFile = {
        fileName: event?.target?.files[0]?.name,
        file: event?.target?.files[0],
        key: "",
      };

      return tempSelected;
    });
  };

  const onFileCancelHandler = (fileIndex, id) => {
    setShowFileDeleteModal(true);
    setFileIndex(fileIndex);
    setAttachmentID(id);
  };

  const handleDownload = (key) => {
    dispatch(downloadFile({ key, isCertificate: true }));
    downloadFile({ key, isCertificate: true });
  };

  const handleSubmit = async (response) => {
    if (response) {
      setIsUpdateAttachmentBtnEnabled((current) => false);

      setSelectedFiles((current) => {
        let currentSelectedFilesCopy = [...current];
        currentSelectedFilesCopy[fileIndex].key = null;
        currentSelectedFilesCopy[fileIndex].url_link = null;
        currentSelectedFilesCopy[fileIndex].name = null;
        currentSelectedFilesCopy[fileIndex].expiry_date = null;
        return currentSelectedFilesCopy;
      });
      if (selectedFiles[fileIndex].attachedFile) {
        setSelectedFiles((current) => {
          let currentSelectedFilesCopy = [...current];
          delete currentSelectedFilesCopy[fileIndex].attachedFile
          return currentSelectedFilesCopy;
        });
      }
      else{
        deleteCertificate({ id: attachmentID , contractorId : contractorId , attachmentIdInExpiray: fileIndex + 1 });
      }
    }
  };
  const handleAttachmentExpirationDate = (newValue, attachment_id, index) => {
    let tempFile = [...selectedFiles];

    tempFile[index].expiry_date = Moment(newValue)?.format(
      "YYYY-MM-DDTHH:mm:ss.SSSSZ"
    );
    tempFile[index].attachment_id = attachment_id;
    tempFile[index].is_expiry_update = true;

    setSelectedFiles([...tempFile]);
  };

  return (
    <>
      <WholeScreenModal
        show={showFileDeleteModal}
        hide={() => setShowFileDeleteModal(false)}
      >
        <Prompt
          response={(response) => {
            setShowFileDeleteModal(false);
            handleSubmit(response);
          }}
          headingText={"Delete Certificate"}
          question={"Are you sure you want to delete this certificate"}
          acceptText={"Yes"}
          rejectText={"No"}
        />
      </WholeScreenModal>
      <div className={classes.wrapper}>
        {selectedFiles?.map((attachmentType, index) => {
          let attachmentNameFound =
            attachmentType?.attachedFile?.fileName || attachmentType?.name;
          return (
            <ExpansionPanel
              expanded={expanded === index}
              className={classes.accordian}
              onChange={handleExpandChange(index)}
              key={attachmentType.id}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.additionalAttachmentTypesAccordion}
              >
                <div
                  className={classes.additionalAttachmentTypesAccordionHeader}
                >
                  <div
                    className={
                      classes.additionalAttachmentTypesAccordionAttachment
                    }
                  >
                    <AttachmentIcon
                      className={classes.additionalAttachmentIcon}
                      fontSize="small"
                    />
                    <Typography className={classes.heading}>
                      {attachmentType?.label}
                    </Typography>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                className={classes.additionalAttachmentIconSection}
              >
                <List component="nav" aria-label="mailbox folders">
                  <>
                    <ListItem style={{ justifyContent: "space-between" }}>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: 10,
                          alignItems: "center",
                        }}
                      >
                        <ListItemText
                          primary={
                            attachmentNameFound || "No Attachment Uploaded Yet"
                          }
                          onClick={() => {
                            attachmentType?.key &&
                              handleDownload(attachmentType?.key);
                          }}
                        />
                        {attachmentNameFound && (
                          <CloseIcon
                            className={classes.clickableIcon}
                            onClick={() =>
                              onFileCancelHandler(index, attachmentType?.id)
                            }
                          />
                        )}
                      </div>
                      <Button
                        className={classes.button}
                        variant="contained"
                        component="label"
                        color="primary"
                      >
                        + Select File
                        <input
                          type='file'
                          accept='.jpg, .png, .jpeg, .TIF, .pdf, .doc, .docx, .xls, .xlsx, .txt'
                          onChange={(event) =>
                            onFileChangeHandler(
                              event,
                              index,
                              attachmentType?.value
                            )
                          }
                          hidden
                          name="adminFiles"
                          id="adminFiles"
                        />
                      </Button>
                    </ListItem>
                    {attachmentNameFound && (
                      <div className={classes.datePicker}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDateTimePicker
                            minDate={dateToDisable}
                            variant="inline"
                            error={false}
                            helperText={null}
                            format="MM-DD-YYYY hh:mm a"
                            id="expiration_date"
                            label="Expiration Date"
                            showTodayButton
                            ampmInClock={true}
                            clearable={true}
                            value={attachmentType?.expiry_date ?? null}
                            onChange={(value) =>
                              handleAttachmentExpirationDate(
                                value,
                                attachmentType?.attachment_id,
                                index
                              )
                            }
                            KeyboardButtonProps={{
                              aria_label: "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  </>
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={!isUpdateAttachmentBtnEnabled}
          onClick={handleUpdateAttachmentTypes}
        >
          Update Additional Attachments
        </Button>
      </div>
    </>
  );
};

export default AdditionalAttachmentsAccordion;
